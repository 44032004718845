import React, { useCallback } from "react";
import "twin.macro";
import { Popconfirm } from "antd";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { Button } from "../../../components/rxLibrary/buttons";
import { IconTrash } from "../../../components/rxLibrary/icons";
import {
  AuthDeletePasswordPayload,
  SupplierAccountRecordView,
} from "../EnhancedSupplierAccount.constants";
import { EnhancedSupplierAccountPassword } from "../EnhancedSupplierAccountPassword";
import { EnhancedSupplierAccountUsername } from "../EnhancedSupplierAccountUsername";
import { dispatch } from "../EnhancedSupplierAccount.utils";

export function EnhancedSupplierAccountSettingsViewAccount({
  account,
  setEditMode,
  togglePassword,
}: {
  account: SupplierAccountRecordView;
  setEditMode: (accountId: string) => void;
  togglePassword: (account: SupplierAccountRecordView) => void;
}) {
  const { id, userName, supplier } = account;

  const handleEditClick = useCallback(() => {
    setEditMode(id);
  }, [id]);

  const setDeleteMode = useCallback(() => {
    if (!userName) return;
    const payload: AuthDeletePasswordPayload = { supplier, username: userName };
    dispatch("requestDeleteSupplierCredentials", payload);
  }, [userName, supplier]);

  return (
    <tr>
      <td tw="p-2">
        <EnhancedSupplierAccountUsername account={account} />
      </td>

      <td tw="p-2">
        <EnhancedSupplierAccountPassword
          account={account}
          togglePassword={togglePassword}
        />
      </td>

      <td tw="p-2">
        <div tw="flex justify-start items-center space-x-4">
          <Button
            variant="secondary"
            icon={<EditOutlined rev={undefined} />}
            onClick={handleEditClick}
          >
            Edit
          </Button>

          <Popconfirm
            title="Are you sure you want to delete this password?"
            okText="Yes, Delete Password"
            cancelText="No, Cancel"
            onConfirm={setDeleteMode}
          >
            <Button icon={<IconTrash />} variant="secondary">
              Delete
            </Button>
          </Popconfirm>
        </div>
      </td>
    </tr>
  );
}
