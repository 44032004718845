import type { Idable } from "../types";

/**
 * @deprecated use lodash/keyBy instead, example keyBy(value, "id")
 */
export function groupById<T extends Idable>(arg: T[]): Record<number, T> {
  const result: Record<number, T> = {};
  arg.forEach((t) => {
    result[t.id] = t;
  });
  return result;
}
