import { Text } from "../../../../components/rxLibrary/typography";

export function InformativeText({ children }: { children: React.ReactNode }) {
  return (
    <div tw="flex-1 mt-4 bg-white p-[25px 100px 25px 15px]">
      <Text size="medium">
        {children}
      </Text>
    </div>
  );
}
