import React, { useCallback, useMemo } from "react";
import tw, { css, styled } from "twin.macro";
import get from "lodash/get";
import { Text } from "../../components/rxLibrary/typography";
import { Dropdown, MenuProps } from "../../components/rxLibrary/dropdown";
import { BLUE_1, GREY_2 } from "../../components/rxLibrary/colors";
import { useManufacturerReport } from "./useManufacturerReport/useManufacturerReport";

const EllipsisOverflowText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const LabelButton = styled.button(() => [
  tw`px-2 bg-white border border-black rounded-md flex items-center w-[675px] h-[38px]`,
  css`
    border-color: ${GREY_2};

    :hover {
      border-color: ${BLUE_1};
    }
  `,
]);

export function ManufacturerReportSelector() {
  const { report, reports, setReport } = useManufacturerReport();

  const label = useMemo(() => {
    const reportKey = report.key;
    const [, newLabel] = reportKey.split(".").reduce(
      ([prefixKey, tmpLabel], suffixKey) => {
        const key = prefixKey + suffixKey;
        const tmpReport = get(reports, key);
        const tmpLabelReport = tmpReport.label;
        const newLabel = !tmpLabel
          ? tmpLabelReport
          : `${tmpLabel}: ${tmpLabelReport}`;
        return [key, newLabel];
      },
      ["", ""]
    );
    return newLabel;
  }, [report, reports]);

  const onClick: NonNullable<MenuProps["onClick"]> = useCallback(
    (menuInfo) => {
      const { key } = menuInfo;
      const newReport = get(reports, key);
      setReport(newReport);
    },
    [reports]
  );

  return (
    <div tw="flex items-center space-x-2">
      <Text size="medium">Report:</Text>

      <Dropdown
        menu={{
          items: reports,
          selectable: true,
          defaultSelectedKeys: [report.key],
          onClick,
        }}
        trigger={["click"]}
      >
        <LabelButton>
          <EllipsisOverflowText>{label}</EllipsisOverflowText>
        </LabelButton>
      </Dropdown>
    </div>
  );
}
