import React from "react";
import { AllMedicationsDetailedTable } from "./AllMedicationsTable/AllMedicationsTable";
import type { AllMedicationsDataType } from "./AllMedications.types";

export function AllMedications({
  data,
}: {
  data?: AllMedicationsDataType;
}) {
  if (!data) return;
  
  const {
    allMedicationTable: { data: allMedicationTableData }
  } = data;

  return (
    <>
      <div tw="flex-1 h-[calc(100% - 50px)] bg-white">
        <AllMedicationsDetailedTable data={allMedicationTableData} />
      </div>
    </>
  );
}
