import React, { useContext } from "react";
import "twin.macro";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ParsedSearchTypeEnum, parseSearch } from "../../utilities/search";
import TimerContext from "../../contexts/TimerContext";
import { NDCInput } from "../NDCInput";

export function InlineNdcSearch({
  ndc,
  disabled = false,
  shopping = false,
  autoFocus,
  callBack,
}: {
  ndc: string;
  disabled?: boolean;
  shopping?: boolean;
  autoFocus?: boolean | undefined;
  callBack?: (value: string) => void;
}) {
  const history = useHistory();
  const form = useForm({ defaultValues: { ndc } });
  const { refresh } = useContext(TimerContext);

  const onSubmit = form.handleSubmit(({ ndc: newNdc }: { ndc: string }) => {
    const parsedSearch = parseSearch(newNdc);
    if (shopping) {
      callBack && callBack(parsedSearch.value);
      return;
    }

    if (parsedSearch.type === ParsedSearchTypeEnum.NDC) {
      history.push(`/compare?ndc=${parsedSearch.value}`);
    } else {
      history.push(`/selectDrug?search=${parsedSearch.value}`);
    }

    if (refresh === true) {
      const oldURL = window.location.href;
      window.location.href = oldURL;
    }
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit} tw="flex items-center">
        {shopping && (
          <p className="text-lg font-500 mb-2">Add Inventory Item</p>
        )}
        <NDCInput
          autoFocus={autoFocus}
          disabled={disabled}
          name="ndc"
          required
        />
      </form>
    </FormProvider>
  );
}
