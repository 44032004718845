import React, { useCallback, useContext, useMemo } from "react";
import "twin.macro";
import "core-js/actual/set-immediate";
import { useHistory } from "react-router-dom";
import { Text } from "../../../rxLibrary/typography";
import { Select } from "../../../rxLibrary/selects/select";
import { toSortedOptionData } from "../../../../utilities/helpers";
import PharmaciesContext from "../../../../contexts/PharmaciesContext";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";

export function AccountMenuPharmacySelector({
  className,
}: {
  className?: string;
}) {
  const history = useHistory();
  const { pharmacies } = useContext(PharmaciesContext);
  const { currentBuyingPharmacyId, setCurrentBuyingPharmacyId } = useContext(
    BuyingPharmacyContext
  );

  const options = useMemo(() => {
    return toSortedOptionData(pharmacies);
  }, [pharmacies]);

  const value = useMemo(() => {
    return options.find((option) => option.value === currentBuyingPharmacyId);
  }, [options, currentBuyingPharmacyId]);

  const handleChange = useCallback(
    (id: any) => {
      setCurrentBuyingPharmacyId(id);
      // redirect after the pharmacy has been updated
      setImmediate(() => history.push("/"));
    },
    [setCurrentBuyingPharmacyId]
  );

  if (!pharmacies?.length || pharmacies.length < 2) return null;
  return (
    <div className={className}>
      <Text tw="mb-[8px]" block>
        Buying for (Pick one):
      </Text>
      <Select
        tw="w-full h-[38px]"
        value={value}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
}
