import { useCallback, useMemo } from "react";
import { getLocalStorageValue } from "../../../../../utilities/localStorage/getLocalStorageValue";
import { setLocalStorageValue } from "../../../../../utilities/localStorage/setLocalStorageValue";
import { removeLocalStorageValue } from "../../../../../utilities/localStorage/removeLocalStorageValue";
import { ManufacturerStoreItem } from "../Catalog.constants";

type LocalStorageCartCatalogItem = {
  ndc: string;
  numPackages: number;
};

type LocalStorageCartCatalog = {
  items: LocalStorageCartCatalogItem[];
  updatedAt: string;
};

export function useLocalStorageCartCatalog({
  supplierId,
  pharmacyId,
}: {
  supplierId: number;
  pharmacyId: number;
}) {
  const localStorageCartCatalogKey = useMemo(() => {
    return `manufacturer-store/catalog/${pharmacyId}/${supplierId}`;
  }, [supplierId, pharmacyId]);

  const initialLocalStorageCartCatalog = useMemo(() => {
    return getLocalStorageValue<LocalStorageCartCatalog | undefined>(
      localStorageCartCatalogKey,
      undefined
    );
  }, [localStorageCartCatalogKey]);

  const removeLocalStorageCartCatalog = useCallback(() => {
    removeLocalStorageValue(localStorageCartCatalogKey);
  }, [localStorageCartCatalogKey]);

  const updateLocalStorageCartCatalog = useCallback(
    (items: ManufacturerStoreItem[]) => {
      const localStorageItems = items
        .filter((item) => {
          return item.status === "list";
        })
        .map((item) => {
          const { ndc, numPackages } = item;
          return { ndc, numPackages };
        });

      const newLocalStorageCartCatalog: LocalStorageCartCatalog = {
        items: localStorageItems,
        updatedAt: new Date().toISOString(),
      };

      setLocalStorageValue<LocalStorageCartCatalog>(
        localStorageCartCatalogKey,
        newLocalStorageCartCatalog
      );
    },
    [localStorageCartCatalogKey]
  );

  return {
    initialLocalStorageCartCatalog,
    removeLocalStorageCartCatalog,
    updateLocalStorageCartCatalog,
  };
}
