import React from "react";
import "twin.macro";
import { useCreateForm } from "../../../../../components/antd/form/useCreateForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { createAdminSupplierMappingAndEdi } from "../../../../../services/supplierMappingAndEdi";
import { AdminSupplierMappingAndEdiForm } from "./AdminSupplierMappingAndEdiForm/AdminSupplierMappingAndEdiForm";
import { useSupplierMappingAndEdi } from "./useSupplierMappingAndEdi";

export function CreateAdminSupplierMappingAndEdi() {
  const { supplierId, supplierMappingAndEdiPath } = useSupplierMappingAndEdi();

  const onSubmit = useCreateForm({
    redirectTo: supplierMappingAndEdiPath,
    create: createAdminSupplierMappingAndEdi,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminSupplierMappingAndEdiForm
        initialValues={{ supplierId, isSupplierMappingActive: false }}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
