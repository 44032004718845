import React, { FC, ReactNode } from "react";
import type { DrugInfo } from "../utilities/types";
import { formatDrugDispenseQuantity } from "../utilities/drugInfo/formatDrugDispenseQuantity";

export const pipePrefixed = (description: string) => `| ${description}`;

export function QuantityDescription({
  drug,
  format = (desc) => desc,
  element: Element = ({ children, ...props }) => (
    <span {...props}>{children}</span>
  ),
}: {
  drug: DrugInfo;
  format?: (desc: ReturnType<typeof formatDrugDispenseQuantity>) => string;
  element?: FC<{ children: ReactNode }>;
}) {
  const content = formatDrugDispenseQuantity(drug);
  return content ? <Element>{format(content)}</Element> : null;
}
