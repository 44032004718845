import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import { useShoppingState } from "../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import EnhancedClientConfigContext from "../../../enhanced/EnhancedClientConfigContext";
import { setLocalStoragePrintableCart } from "../../../utilities/localStorage/printableCart/setLocalStoragePrintableCart";

const SHOPPING_LIST_PRINT_URL = "/shoppingListPrint";
const PRINT_URL = window.location.origin + SHOPPING_LIST_PRINT_URL;

export function useShoppingListPrintBtnProps() {
  const history = useHistory();
  const { optimizeCartResponse } = useShoppingState();
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { supplierOrders, unavailable } =
    optimizeCartResponse.data?.selections ?? {};

  const onClick = useCallback(() => {
    setLocalStoragePrintableCart({ unavailable, supplierOrders });
    if (enhancedClientActive) return;

    history.push(SHOPPING_LIST_PRINT_URL);
  }, [unavailable, supplierOrders, enhancedClientActive]);

  return { "data-print-url": PRINT_URL, onClick };
}
