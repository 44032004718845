import { useMemo } from "react";
import { SupplierMappingCustomerAccountId } from "../../../../../../utilities/types";

export function useCustomerAccountIdLengthFormRule(
  supplierMappingCustomerAccountIds?: SupplierMappingCustomerAccountId[]
) {
  const rule = useMemo(() => {
    if (!supplierMappingCustomerAccountIds?.length) return [];

    const lengths = supplierMappingCustomerAccountIds.map(
      (v) => v.customerAccountId.length
    );
    const max = Math.max(...lengths);
    const min = Math.min(...lengths);

    if (min === max) {
      return [
        {
          len: max,
          warningOnly: true,
          message: `Warning: The other account numbers have a length of ${min} characters.`,
        },
      ];
    }

    return [
      {
        min,
        max,
        warningOnly: true,
        message: `Warning: The other account numbers have a length between ${min}-${max} characters.`,
      },
    ];
  }, [supplierMappingCustomerAccountIds]);

  return rule;
}
