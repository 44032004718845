import React, { useContext } from "react";
import { styled } from "twin.macro";
import AppContext from "../../contexts/AppContext";
import { WHITE_1 } from "../rxLibrary/colors";

const UserName = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;
  color: ${WHITE_1};
  max-width: 196px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const CurrentUser = styled(UserName)`
  font-size: 12px;
`;

export function NavBarUserName() {
  const { user } = useContext(AppContext);
  const userName = user?.full_name || "My Account";

  return (
    <div tw="space-y-[2px]">
      <UserName>{userName}</UserName>
      <CurrentUser>CURRENT USER</CurrentUser>
    </div>
  );
}
