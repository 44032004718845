import React from "react";
import "twin.macro";
import DispensedItem from "../../../../../../components/DispensedItem";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { CatalogInfo, DrugInfo } from "../../../../../../utilities/types";
import { Image } from "../../../../../../components/Image";

export function ShoppingListTableRowDrugDetailsCol({
  drug,
  catalogInfo,
}: {
  drug: DrugInfo;
  catalogInfo?: CatalogInfo;
}) {
  return (
    <div tw="flex items-start">
      <Image tw="w-[52px] mr-2 object-contain" src={drug.imageURI} />

      <Text>
        <DispensedItem drug={drug} catalogInfo={catalogInfo} />
      </Text>
    </div>
  );
}
