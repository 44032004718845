import { useCallback, useMemo, useState } from "react";
import type { Dayjs } from "dayjs";
import type { ItemInModal, ItemInCart } from "../../../../../utilities/types";
import { formatDispenseQuantity } from "../../../../../utilities/prescriptions/formatDispenseQuantity";
import { formatPrescriberName } from "../../../../../utilities/prescriptions/formatPrescriberName";
import { getPrescriptionId } from "../../../../../utilities/prescriptions/getPrescriptionId";
import { Box } from "../../../../../components/rxLibrary/box";
import { Text } from "../../../../../components/rxLibrary/typography";
import { ShoppingRxItemPanel } from "./ShoppingRxItemPanel/ShoppingRxItemPanel";
import { ShoppingRxItemHeader } from "./ShoppingRxItemHeader/ShoppingRxItemHeader";
import { ShoppingRxItem } from "./ShoppingRxItem";

export function ShoppingRxPrescriptionItem({
  item,
  isInCart,
  hasItemNote,
  itemNotePopover,
  removeItem,
  updateItemPackSize,
  openItemNotePopover,
  updateItemPurchaseBy,
  updateItemManufacturer,
}: {
  removeItem: () => void;
  updateItemPackSize: (value: boolean) => void;
  updateItemManufacturer: (value: boolean) => void;
} & (
  | {
      item: ItemInCart;
      isInCart: true;
      hasItemNote: boolean;
      itemNotePopover: false | JSX.Element;
      openItemNotePopover: () => void;
      updateItemPurchaseBy: (value?: Dayjs) => void;
    }
  | {
      item: ItemInModal;
      hasItemNote?: undefined;
      itemNotePopover?: undefined;
      openItemNotePopover?: undefined;
      updateItemPurchaseBy?: undefined;
      isInCart?: false;
    }
)) {
  const { prescriber } = item;
  const headerProps = isInCart
    ? {
        item,
        isInCart,
        hasItemNote,
        openItemNotePopover,
        updateItemPurchaseBy,
      }
    : { item };

  const id = useMemo(() => getPrescriptionId(item), [item]);
  const [isRemoving, setIsRemoving] = useState(false);

  const dispensedQuantity = useMemo(() => {
    return formatDispenseQuantity(item);
  }, [item]);

  const prescriberName = useMemo(() => {
    return formatPrescriberName(prescriber);
  }, [prescriber]);

  const handleRemoveItem = useCallback(() => {
    setIsRemoving(true);
    setTimeout(removeItem, 500);
  }, [removeItem]);

  return (
    <ShoppingRxItem id={id} isRemoving={isRemoving}>
      <ShoppingRxItemHeader {...headerProps} removeItem={handleRemoveItem} />

      <ShoppingRxItemPanel
        item={item}
        itemNotePopover={itemNotePopover}
        updateItemPackSize={updateItemPackSize}
        updateItemManufacturer={updateItemManufacturer}
      >
        <Box tw="border-r w-[157px] px-[10px]" borderColor="grey-2">
          <Text weight="bold" block>
            Prescriber: {prescriberName}
          </Text>
          {!!dispensedQuantity && <Text block>{dispensedQuantity}</Text>}
        </Box>
      </ShoppingRxItemPanel>
    </ShoppingRxItem>
  );
}
