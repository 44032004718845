import {
  DrugInfo,
  ListCategoryEnum,
  NDCTaggedItemList,
  Pharmacy,
  TaggedItem,
  Supplier,
} from "../../../../utilities/types";
import { EnhancedSupplierConnectionStatus } from "../../../../enhanced/types";
import {
  EnhancedAdditionalItemsByNDCType,
  EnhancedItemsBySupplierType,
  EnhancedStockValidationAvailability,
  SupplierItemDataType,
} from "../../../../enhanced/EnhancedClientContext";
import { NdcOffering, NdcOfferingSet } from "../../../../enhanced/result_types";
import { disableV1EnhancementsForSupplier } from "../../../../utilities/search";
import { ndcOfferingCostPerDose } from "../NDCResults.utils";

// less than unit price/per dose
function priceEqualOrLowerThan(offering: NdcOffering, priceMatch: number) {
  const itemPrice = ndcOfferingCostPerDose(offering);
  return itemPrice <= priceMatch;
}

function sortPrimary(arr: NdcOfferingSet[]): NdcOfferingSet[] {
  return arr.sort((a, b) => {
    if (!b.featured) return -1;
    if (!a.featured) return 1;

    const costA = ndcOfferingCostPerDose(a.featured);
    const costB = ndcOfferingCostPerDose(b.featured);
    if (costA < costB) {
      return -1;
    } else if (costA > costB) {
      return 1;
    } else {
      return 0;
    }
  });
}

function recategorizeBasedOn(card: NdcOfferingSet, topCard?: NdcOfferingSet) {
  const featured = card.featured;
  const tcFeatured = topCard?.featured;
  if (!tcFeatured || !featured) return;

  const isSameManufacturer =
    tcFeatured.item.drugInfo.manufacturer ===
    featured.item.drugInfo.manufacturer;
  const isSameUnitQuantity =
    tcFeatured.item.drugInfo.unitQuantity ===
    featured.item.drugInfo.unitQuantity;
  const isSameUnitSize =
    tcFeatured.item.drugInfo.unitSize === featured.item.drugInfo.unitSize;

  if (!isSameManufacturer && isSameUnitQuantity && isSameUnitSize) {
    card.category = ListCategoryEnum.DIFFERENT_MANUFACTURERS;
    featured.item.tag = ListCategoryEnum.DIFFERENT_MANUFACTURERS;
    featured.item.suggestedReason = undefined;
    card.alternatives.forEach((alt) => {
      alt.item.tag = ListCategoryEnum.DIFFERENT_MANUFACTURERS;
      alt.item.suggestedReason = undefined;
    });
  } else if (isSameManufacturer && (!isSameUnitQuantity || !isSameUnitSize)) {
    card.category = ListCategoryEnum.DIFFERENT_QUANTITIES;
    featured.item.tag = ListCategoryEnum.DIFFERENT_QUANTITIES;
    featured.item.suggestedReason = undefined;
    card.alternatives.forEach((alt) => {
      alt.item.tag = ListCategoryEnum.DIFFERENT_QUANTITIES;
      alt.item.suggestedReason = undefined;
    });
  } else if (!isSameManufacturer && (!isSameUnitQuantity || !isSameUnitSize)) {
    card.category = ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
    featured.item.tag = ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
    featured.item.suggestedReason = undefined;
    card.alternatives.forEach((alt) => {
      alt.item.tag = ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
      alt.item.suggestedReason = undefined;
    });
  }
}

function newNdcOffering(item: TaggedItem, supplierData?: SupplierItemDataType) {
  const offering: NdcOffering = { item, price: item.catalogInfo.price };
  if (supplierData) {
    offering.supplierData = supplierData;
    offering.price = supplierData.price || 0;
    offering.priceNote = supplierData.priceNote;
  }
  return offering;
}

function getDisplayName(
  supplierName: string,
  currentBuyingPharmacy: Pharmacy | null
) {
  if (!currentBuyingPharmacy) {
    return supplierName;
  }
  const match = currentBuyingPharmacy.suppliers.find(
    (s) => s.name === supplierName
  );
  if (!match) {
    return supplierName;
  }
  return match.displayName;
}

// TODO add supplierId to SupplierItemDataType somehow so that we don't
// TODO need to pass in getSupplierByName in multiple places here
function newProxyItem(
  templateItem: TaggedItem,
  supplierData: SupplierItemDataType,
  currentBuyingPharmacy: Pharmacy | null,
  getSupplierByName: (name: string) => Supplier | undefined
): TaggedItem {
  return {
    tag: templateItem.tag,
    alternatePackaging: templateItem.alternatePackaging,
    suggestedReason: templateItem.suggestedReason,
    buyingScore: templateItem.buyingScore,
    drugInfo: templateItem.drugInfo,
    historicalOrderingInfo: templateItem.historicalOrderingInfo,
    historicalDispensationInfo: templateItem.historicalDispensationInfo,
    catalogInfo: {
      id: templateItem.catalogInfo.id,
      supplierId: getSupplierByName(supplierData.supplier)?.id || -1,
      price: supplierData.price || 0,
      isRebatedItem: supplierData.isRebatedItem || false,
      supplier: supplierData.supplier,
      supplierDisplayName: getDisplayName(
        supplierData.supplier,
        currentBuyingPharmacy
      ),
      directDeal: null,
    },
  };
}

function categoryFrom(
  relatedDrug: DrugInfo,
  suggestedOffering: NdcOfferingSet | null
): ListCategoryEnum {
  if (!suggestedOffering || !suggestedOffering.featured)
    return ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
  let diffQuantity = false;
  let diffManufac = false;
  if (
    relatedDrug.unitQuantity !==
    suggestedOffering.featured.item.drugInfo.unitQuantity
  ) {
    diffQuantity = true;
  }
  if (
    relatedDrug.manufacturer !==
    suggestedOffering.featured.item.drugInfo.manufacturer
  ) {
    diffManufac = true;
  }
  if (!diffQuantity && diffManufac) {
    return ListCategoryEnum.DIFFERENT_MANUFACTURERS;
  }
  if (!diffManufac && diffQuantity) {
    return ListCategoryEnum.DIFFERENT_QUANTITIES;
  }
  return ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY;
}

function chooseFeaturedAndAlts(
  enhancements: Record<string, SupplierItemDataType>
) {
  const list = Object.values(enhancements);
  list.sort((e1, e2) => {
    if (e2.availability !== EnhancedStockValidationAvailability.AVAILABLE) {
      return -1;
    }
    if (e1.availability !== EnhancedStockValidationAvailability.AVAILABLE) {
      return 1;
    }
    if (e2.price == 0 || e2.price === undefined) {
      return -1;
    }
    if (e1.price == 0 || e1.price === undefined) {
      return 1;
    }
    return e1.price - e2.price;
  });
  const featured = list.pop() as SupplierItemDataType;
  return {
    featured: featured,
    alts: list,
  };
}

export function splitCards(
  offeringsForCards: NdcOfferingSet[],
  suggestedDrug?: DrugInfo
) {
  const primaryArr: NdcOfferingSet[] = [];
  const topCardsCandidates: NdcOfferingSet[] = [];
  const bottomCardsCandidates: NdcOfferingSet[] = [];
  const seenCategories = new Set<ListCategoryEnum>();

  const topCard = offeringsForCards.reduce<NdcOfferingSet | undefined>(
    (prev, curr) => {
      if (!prev || !prev.featured) {
        return curr;
      } else if (!curr.featured) {
        return prev;
      } else {
        if (
          curr.category === ListCategoryEnum.NDC_MATCH ||
          (curr.category === ListCategoryEnum.SUGGESTED_NDC &&
            (curr.featured.item.suggestedReason === "order_frequency" ||
              curr.featured.item.suggestedReason === "prescription_frequency"))
        ) {
          return curr;
        }
        if (
          !(prev.category === ListCategoryEnum.NDC_MATCH) &&
          !(
            prev.category === ListCategoryEnum.SUGGESTED_NDC &&
            (prev.featured.item.suggestedReason === "order_frequency" ||
              prev.featured.item.suggestedReason === "prescription_frequency")
          )
        ) {
          if (
            curr.featured &&
            (!suggestedDrug ||
              curr.featured.item.drugInfo.name === suggestedDrug.name) &&
            curr.featured.price < prev.featured.price
          ) {
            return curr;
          }
        }
      }
      return prev;
    },
    undefined
  );

  const bestOffering = topCard?.featured;
  if (bestOffering) {
    if (
      topCard.category !== ListCategoryEnum.SUGGESTED_NDC &&
      topCard.category !== ListCategoryEnum.NDC_MATCH
    ) {
      topCard.category = ListCategoryEnum.SUGGESTED_NDC;
      topCard.featured.item.tag = ListCategoryEnum.SUGGESTED_NDC;
      topCard.featured.item.suggestedReason = "lowest_price";
      topCard.alternatives.forEach((alt) => {
        alt.item.tag = ListCategoryEnum.SUGGESTED_NDC;
        alt.item.suggestedReason = "lowest_price";
      });
    }
    topCardsCandidates.push(topCard);
  }

  const bestOfferingPrice = bestOffering
    ? ndcOfferingCostPerDose(bestOffering)
    : 0;
  offeringsForCards
    .sort((a, b) => {
      if (!b.featured) return -1;
      if (!a.featured) return 1;
      return (
        ndcOfferingCostPerDose(a.featured) - ndcOfferingCostPerDose(b.featured)
      );
    })
    .forEach((cardOfferings) => {
      if (cardOfferings === topCard) return;
      recategorizeBasedOn(cardOfferings, topCard);

      if (!cardOfferings.category) return;

      if (
        !!cardOfferings.featured &&
        !seenCategories.has(cardOfferings.category) &&
        priceEqualOrLowerThan(cardOfferings.featured, bestOfferingPrice)
      ) {
        primaryArr.push(cardOfferings);
        seenCategories.add(cardOfferings.category);
      } else {
        bottomCardsCandidates.push(cardOfferings);
      }
    });

  if (primaryArr.length) {
    topCardsCandidates.push(...sortPrimary(primaryArr));
  }

  const topCards = topCardsCandidates.filter((set) => !!set.featured);
  const bottomCards = bottomCardsCandidates.filter((set) => !!set.featured);
  const mainNdcOffering = topCards?.[0]?.featured || undefined;

  return { topCards, bottomCards, mainNdcOffering };
}

export function appendAdditionalEnhancedItems(
  currentBuyingPharmacy: Pharmacy | null,
  existingOfferingSets: Record<string, NdcOfferingSet>,
  enhancedClientActive: boolean,
  enhancedAdditionalItemsByNDC: EnhancedAdditionalItemsByNDCType,
  getSupplierByName: (name: string) => Supplier | undefined,
  relatedDrugsByNDC?: Record<string, DrugInfo>
): NdcOfferingSet[] {
  const offeringSetList = Object.values(existingOfferingSets);

  if (
    !enhancedClientActive ||
    !relatedDrugsByNDC ||
    offeringSetList.length === 0
  ) {
    return offeringSetList;
  }

  const suggestedOffering = offeringSetList.find((offeringSet) => {
    return (
      (offeringSet.category === ListCategoryEnum.NDC_MATCH ||
        offeringSet.category === ListCategoryEnum.SUGGESTED_NDC) &&
      offeringSet.featured
    );
  });
  if (!suggestedOffering?.featured) return offeringSetList;

  Object.keys(enhancedAdditionalItemsByNDC).forEach((ndc) => {
    const relatedDrug = relatedDrugsByNDC[ndc];
    if (!relatedDrug) {
      return;
    }

    const enhancements = enhancedAdditionalItemsByNDC[ndc];
    if (!enhancements || Object.keys(enhancements).length === 0) {
      return;
    }

    // console.log("Evaluating dynamic card", enhancements);

    const { featured, alts } = chooseFeaturedAndAlts(enhancements);
    if (featured.price === 0) {
      console.log("Price 0, not adding card", featured);
      return;
    }

    const category = categoryFrom(relatedDrug, suggestedOffering);
    let alternatePackaging = false;
    const leadDrugInfo = suggestedOffering?.featured?.item.drugInfo;
    if (
      leadDrugInfo?.unitDoseDescription !== relatedDrug.unitDoseDescription &&
      (leadDrugInfo?.unitDoseDescription === "Unit-Dose Packaging" ||
        relatedDrug.unitDoseDescription === "Unit-Dose Packaging")
    ) {
      alternatePackaging = true;
    }
    const newDynamicOffering: NdcOfferingSet = {
      ndc: ndc,
      category: category,
      featured: {
        item: newProxyItem(
          {
            tag: category,
            alternatePackaging,
            buyingScore: 0,
            drugInfo: relatedDrug,
            historicalOrderingInfo:
              suggestedOffering.featured.item.historicalOrderingInfo,
            historicalDispensationInfo:
              suggestedOffering.featured.item.historicalDispensationInfo,
            catalogInfo: {
              id: `enhanced-${featured.supplier}-${featured.supplierItemNumber}`,
              supplierId: getSupplierByName(featured.supplier)?.id || -1,
              supplier: featured.supplier,
              price: featured.price || 0,
              isRebatedItem: featured.isRebatedItem || false,
              supplierDisplayName: getDisplayName(
                featured.supplier,
                currentBuyingPharmacy
              ),
              directDeal: null,
            },
          },
          featured,
          currentBuyingPharmacy,
          getSupplierByName
        ),
        price: featured.price || 0,
        supplierData: featured,
      },
      alternatives: alts.map((alt) => {
        return {
          item: newProxyItem(
            {
              tag: category,
              alternatePackaging,
              buyingScore: 0,
              drugInfo: relatedDrug,
              historicalOrderingInfo:
                suggestedOffering.featured.item.historicalOrderingInfo,
              historicalDispensationInfo:
                suggestedOffering.featured.item.historicalDispensationInfo,
              catalogInfo: {
                id: `enhanced-${alt.supplier}-${alt.supplierItemNumber}`,
                supplierId: getSupplierByName(alt.supplier)?.id || -1,
                supplier: alt.supplier,
                price: alt.price || 0,
                isRebatedItem: alt.isRebatedItem || false,
                supplierDisplayName: getDisplayName(
                  alt.supplier,
                  currentBuyingPharmacy
                ),
                directDeal: null,
              },
            },
            alt,
            currentBuyingPharmacy,
            getSupplierByName
          ),
          price: alt.price || 0,
          supplierData: alt,
        };
      }),
    };
    offeringSetList.push(newDynamicOffering);
  });
  return offeringSetList;
}

export function buildOfferingSetForCard(
  items: NDCTaggedItemList,
  enhancedClientActive: boolean,
  supplierConnections: Record<string, EnhancedSupplierConnectionStatus>,
  supplierDataSets: EnhancedItemsBySupplierType,
  currentBuyingPharmacy: Pharmacy | null,
  enhancementExcludedSuppliers: string[] | undefined,
  getSupplierByName: (name: string) => Supplier | undefined
): NdcOfferingSet {
  if (!items.items.length) {
    return {
      ndc: items.ndc,
      category:
        items.category === ListCategoryEnum.NDC_MATCH ||
        items.category === ListCategoryEnum.SUGGESTED_NDC
          ? items.category
          : null,
      featured: null,
      alternatives: [],
    };
  }

  const suppliersChecked = new Set<string>();
  let alternatives: NdcOffering[] = [];
  const ndc = items.items[0].drugInfo.ndc;
  items.items.forEach((item) => {
    if (!enhancedClientActive) {
      alternatives.push(newNdcOffering(item));
      return;
    }

    const supplierData = supplierDataSets[item.catalogInfo.supplier];
    suppliersChecked.add(item.catalogInfo.supplier);
    if (
      supplierConnections[item.catalogInfo.supplier] &&
      supplierData &&
      supplierData[ndc]
    ) {
      alternatives.push(newNdcOffering(item, supplierData[ndc]));
    } else if (
      !supplierConnections[item.catalogInfo.supplier] ||
      supplierConnections[item.catalogInfo.supplier].enhancementsDisabled
    ) {
      //if we don't support enhancing this supplier, just add what we have from the server
      alternatives.push(newNdcOffering(item));
    } else if (
      enhancementExcludedSuppliers &&
      enhancementExcludedSuppliers.includes(item.catalogInfo.supplier)
    ) {
      //if we explicitly skipped enhancing this supplier, just add what we have from the server
      alternatives.push(newNdcOffering(item));
    } else if (disableV1EnhancementsForSupplier(item.catalogInfo.supplier)) {
      // Leave unenhanced items as-is
      alternatives.push(newNdcOffering(item));
    }
    //if we have supplier data but no data for this ndc, don't count this item
  });

  Object.keys(supplierDataSets).forEach((k) => {
    if (!suppliersChecked.has(k)) {
      const supplierData = supplierDataSets[k];
      if (supplierConnections[k] && supplierData && supplierData[ndc]) {
        alternatives.push(
          newNdcOffering(
            newProxyItem(
              items.items[0],
              supplierData[ndc],
              currentBuyingPharmacy,
              getSupplierByName
            ),
            supplierData[ndc]
          )
        );
      }
    }
  });

  alternatives.sort((a, b) => {
    if (
      a.supplierData &&
      a.supplierData.availability !==
        EnhancedStockValidationAvailability.AVAILABLE
    ) {
      if (
        b.supplierData &&
        b.supplierData.availability !==
          EnhancedStockValidationAvailability.AVAILABLE
      ) {
        return 0;
      }
      return 1;
    }

    if (
      b.supplierData &&
      b.supplierData.availability !==
        EnhancedStockValidationAvailability.AVAILABLE
    ) {
      return -1;
    }

    if (a.price === 0) {
      if (b.price === 0) return 0;
      return 1;
    }

    if (b.price === 0) return -1;
    return a.price - b.price;
  });

  // 0 price items will be marked as See Website but we don't want that on the top card
  if (items.category === ListCategoryEnum.NDC_MATCH) {
    alternatives = alternatives.filter((res) => res.price !== 0);
  }

  const featured = alternatives.shift();
  if (featured) {
    return {
      ndc: items.ndc,
      category: items.category as ListCategoryEnum,
      featured,
      alternatives,
    };
  }

  return {
    ndc: items.ndc,
    category:
      items.category === ListCategoryEnum.NDC_MATCH ||
      items.category === ListCategoryEnum.SUGGESTED_NDC
        ? items.category
        : null,
    featured: null,
    alternatives: [],
  };
}
