import { useState } from "react";

const getItem = (key: string) =>
  document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey
      ? decodeURIComponent(storedValue)
      : total;
  }, '');

const setItem = (key: string, value: string, numberOfDays: number) => {
  if (numberOfDays > 0) {
    const now = new Date();
    // set the time to be now + numberOfDays
    now.setTime(now.getTime() + (numberOfDays * 60 * 60 * 24 * 1000));
    document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
  } else {
    document.cookie = `${key}=${value}; path=/`;
  }
};
export const useCookie = (key: string, defaultValue: string): [string, (val: string, numDays: number) => void] => {
  const getCookie = () => getItem(key) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());
  const updateCookie = (value: string, numberOfDays: number) => {
    setCookie(value);
    setItem(key, value, numberOfDays);
  };
  return [cookie, updateCookie];
};
