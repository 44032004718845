import { Dayjs } from "dayjs";
import intersection from "lodash/intersection";
import { insertArray } from "../../../utilities/arrays/insertArray";
import { findAndRemoveFromArray } from "../../../utilities/arrays/findAndRemoveFromArray";
import { formatPurchaseByToString } from "../../../utilities/prescriptions/purchaseBy/formatPurchaseByToString";
import {
  OptimizedCartResponse_All,
  SupplierOrderItemRecommendation_All,
  UnfilledFuturePrescriptionGroup_All
} from "../../../services/utils";

export function getOptimizedCartWithUpdatedBuyLaterItemPurchaseBy({
  date,
  rxNumbers,
  optimizeCartResponseAll,
}: {
  date: Date | Dayjs;
  rxNumbers: string[];
  optimizeCartResponseAll: OptimizedCartResponse_All;
}): OptimizedCartResponse_All | void {
  if (!optimizeCartResponseAll.data || rxNumbers.length === 0) return;

  const purchaseBy = formatPurchaseByToString(date);
  if (!purchaseBy) return;

  const { unfilledFuturePrescriptionGroups: buyLaterItems } =
    optimizeCartResponseAll.data.selections;

  const [filteredBuyLaterItems, buyLaterItem, buyLaterItemIndex] =
    findAndRemoveFromArray(buyLaterItems, (item) => {
      const matchingIds = intersection(item.rxNumbers, rxNumbers);
      return matchingIds.length > 0;
    });
  if (!buyLaterItem) return;

  const newBuyLaterItem = { ...buyLaterItem, purchaseBy };
  // add buy later item in the same position to avoid jumping UI
  const newBuyLaterItems = insertArray(
    filteredBuyLaterItems,
    newBuyLaterItem,
    buyLaterItemIndex
  );

  const newOptimizeCartResponseAll = {
    ...optimizeCartResponseAll,
    data: {
      ...optimizeCartResponseAll.data,
      selections: {
        ...optimizeCartResponseAll.data.selections,
        unfilledFuturePrescriptionGroups: newBuyLaterItems,
      },
    },
  };

  return newOptimizeCartResponseAll;
}

export function getOptimizedCartWithMovedShoppingListItemToBuyLater({
  date,
  supplierId,
  itemToBuyLater,
  optimizeCartResponseAll,
}: {
  date: Date | Dayjs;
  supplierId: number;
  itemToBuyLater: SupplierOrderItemRecommendation_All;
  optimizeCartResponseAll: OptimizedCartResponse_All;
}): OptimizedCartResponse_All | void {
  if (!optimizeCartResponseAll.data) return;

  const purchaseBy = formatPurchaseByToString(date);
  if (!purchaseBy) return;

  const { supplierOrders, unfilledFuturePrescriptionGroups } =
    optimizeCartResponseAll.data.selections;

  const [filteredSupplierOrders, supplierOrder, supplierOrderIndex] =
    findAndRemoveFromArray(
      supplierOrders,
      (so) => so.supplierId === supplierId
    );
  if (!supplierOrder) return;

  let newSupplierOrders = [...filteredSupplierOrders];
  // if the items are 1 or less we don't add the supplier order again
  if (supplierOrder.items.length > 1) {
    const { rxNumbers } = itemToBuyLater;
    // remove item to buy later from the items
    const [newItems] = findAndRemoveFromArray(supplierOrder.items, (item) => {
      const matchingIds = intersection(item.rxNumbers, rxNumbers);
      return matchingIds.length > 0;
    });

    // add updated supplier order with only today items
    const newSupplierOrder = { ...supplierOrder, items: newItems };
    // add the supplier order in the same position to avoid jumping UI
    newSupplierOrders = insertArray(
      filteredSupplierOrders,
      newSupplierOrder,
      supplierOrderIndex
    );
  } else {
    newSupplierOrders = [...filteredSupplierOrders];
  }

  // add the item to move to the alternatives
  const newAlternatives = [itemToBuyLater, ...itemToBuyLater.alternatives].map(
    ({ drug, catalogInfo, referenceData, numPackages }) => ({ drug: {id: drug.id}, catalogInfo: {id: catalogInfo.id}, referenceData, numPackages })
  );
  const newBuyLaterItems: UnfilledFuturePrescriptionGroup_All[] = [
    ...unfilledFuturePrescriptionGroups,
    {
      purchaseBy,
      rxNumbers: itemToBuyLater.rxNumbers,
      drug: {id: itemToBuyLater.drug.id},
      referenceData: itemToBuyLater.referenceData,
      numPackages: itemToBuyLater.numPackages,
      alternatives: newAlternatives,
    },
  ];

  const newOptimizeCartResponseAll: OptimizedCartResponse_All = {
    ...optimizeCartResponseAll,
    data: {
      ...optimizeCartResponseAll.data,
      selections: {
        ...optimizeCartResponseAll.data.selections,
        unfilledFuturePrescriptionGroups: newBuyLaterItems,
        supplierOrders: newSupplierOrders,
      },
    },
  };

  return newOptimizeCartResponseAll;
}

export function getOptimizedCartWithMoveBuyLaterItemToShoppingList({
  newItem,
  supplierId,
  optimizeCartResponseAll,
}: {
  newItem: SupplierOrderItemRecommendation_All;
  supplierId: number;
  optimizeCartResponseAll: OptimizedCartResponse_All;
}): OptimizedCartResponse_All | void {
  if (!optimizeCartResponseAll.data) return;

  const { supplierOrders, unfilledFuturePrescriptionGroups: buyLaterItems } =
    optimizeCartResponseAll.data.selections;

  const [filteredSupplierOrders, supplierOrder, supplierOrderIndex] =
    findAndRemoveFromArray(
      supplierOrders,
      (so) => so.supplierId === supplierId
    );

  const items = supplierOrder?.items ?? [];
  const newSupplierOrder = {
    ...supplierOrder,
    supplierId,
    items: [...items, newItem],
    // Note: This fields will be updated in the next re render by the ShoppingContext
    shippingCost: 0,
    buyingCost: 0,
    itemsTotals: undefined,
  };
  // add supplier order in the position to avoid jumping UI
  const newSupplierOrders = insertArray(
    filteredSupplierOrders,
    newSupplierOrder,
    supplierOrderIndex
  );

  const { rxNumbers } = newItem;
  const [newBuyLaterItems] = findAndRemoveFromArray(buyLaterItems, (item) => {
    const matchingIds = intersection(item.rxNumbers, rxNumbers);
    return matchingIds.length > 0;
  });

  const newOptimizeCartResponseAll: OptimizedCartResponse_All = {
    ...optimizeCartResponseAll,
    data: {
      ...optimizeCartResponseAll.data,
      selections: {
        ...optimizeCartResponseAll.data.selections,
        supplierOrders: newSupplierOrders,
        unfilledFuturePrescriptionGroups: newBuyLaterItems,
      }
    },
  };

  return newOptimizeCartResponseAll;
}
