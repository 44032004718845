import React, { useContext, useEffect } from "react";
import { message } from "antd";
import AppContext from "../../../../contexts/AppContext";
import { TEST_PHARMACY_ID } from "../../../../utilities/config";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";

export function OrderNotSentToSupplierAdminWarning() {
  const [messageApi, contextHolder] = message.useMessage();
  const { user } = useContext(AppContext);
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { id: pharmacyId, name } = currentBuyingPharmacy ?? {};
  const isAdmin = user?.is_admin;

  useEffect(() => {
    if (isAdmin && pharmacyId === TEST_PHARMACY_ID) {
      messageApi.warning(
        `Orders for administrators at ${name} will not be sent to supplier.`
      );
    }
  }, [isAdmin, name, pharmacyId]);

  return <>{contextHolder}</>;
}
