import { useMemo } from "react";
import type { ItemInCart, ItemInModal } from "../types";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { PmsEnum } from "../pms";

function formatBohEoh(value = 0) {
  let newValue = value || 0;
  if (newValue < -100) newValue = Math.round(newValue);
  if (newValue <= -10000) newValue = -9999;
  if (Math.abs(newValue) >= 1000) newValue = Math.ceil(newValue);
  return newValue;
}

export function useItemInventory(
  item: ItemInCart | ItemInModal,
  { omitInventory = false } = {}
) {
  const { currentBuyingPharmacy } = useBuyingPharmacy();
  const { pms, keepsInventory } = currentBuyingPharmacy ?? {};
  const isLiberty = pms === PmsEnum.Liberty;
  const { eoh, boh } = item;

  const inventory = useMemo(() => {
    if (omitInventory || !keepsInventory) return;

    const newBoh = formatBohEoh(boh);
    if (isLiberty) return { boh: newBoh };

    const newEoh = formatBohEoh(eoh);
    return { eoh: newEoh, boh: newBoh };
  }, [eoh, boh, isLiberty, omitInventory, keepsInventory]);

  return inventory;
}
