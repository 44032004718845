import React, { useMemo } from "react";
import type { ItemInCart } from "../../../../utilities/types";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { isInventoryId } from "../../../../utilities/prescriptions/isInventoryId";
import { Text } from "../../../rxLibrary/typography";

export function RxDetailsCol({ prescription }: { prescription: ItemInCart }) {
  const texts = useMemo(() => {
    const id = getPrescriptionId(prescription);
    const isInventory = isInventoryId(id);

    if (isInventory) return ["Inventory"];

    const patientShortName = prescription.patient?.shortName;
    const rxNumber = `Rx # ${id}`;
    if (patientShortName) return [rxNumber, patientShortName];
    return [rxNumber];
  }, [prescription]);

  return (
    <>
      {texts.map((text, i) => (
        <Text key={i} block>
          {text}
        </Text>
      ))}
    </>
  );
}
