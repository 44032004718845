import { useEffect, useState } from "react";
import "twin.macro";
import type { ManufacturerStoreItem } from "../../Catalog.constants";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { Box } from "../../../../../../components/rxLibrary/box";
import { CatalogItem } from "../CatalogItem/CatalogItem";
import { usePagination } from "../../../../../../components/rxLibrary/pagination/usePagination";
import { Pagination } from "../../../../../../components/rxLibrary/pagination/Pagination";

const PAGE_SIZE = 20;

export function CatalogItemsAll({
  shoppingListItems,
}: {
  shoppingListItems: ManufacturerStoreItem[];
}) {
  const [items, setItems] = useState<ManufacturerStoreItem[]>();
  const { pagination, updatePagination } = usePagination();
  const totalRows = shoppingListItems.length;

  useEffect(() => {
    updatePagination({
      currentPage: 1,
      pageSize: PAGE_SIZE,
      totalRows,
      totalPageCount: Math.ceil(totalRows / PAGE_SIZE),
    });
  }, [totalRows]);

  useEffect(() => {
    if (!shoppingListItems?.length || !pagination) {
      setItems(undefined);
      return;
    }

    const { currentPage = 1, pageSize = PAGE_SIZE } = pagination;
    const newItems = shoppingListItems.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    setItems(newItems);
  }, [shoppingListItems, pagination]);

  if (!items?.length) return null;
  return (
    <Box bgColor="white-1" tw="rounded-lg px-[26px] pt-[33px] pb-[21px]">
      <Text tw="mb-[17px]" size={21} block>
        Catalog
      </Text>

      {items?.map((item) => (
        <CatalogItem key={item.ndc} item={item} />
      ))}

      {!!pagination && (
        <div tw="mt-[20px]">
          <Pagination
            sizeOptions={null}
            pagination={pagination}
            updatePagination={updatePagination}
          />
        </div>
      )}
    </Box>
  );
}
