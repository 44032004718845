import React, { useCallback } from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { Checkbox, Form, Input, Popconfirm, Select, message } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
import {
  CreateAdminAccount,
  UpsertAdminAccount,
  UserBusinessRoles,
} from "../../../../../../utilities/types";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { FormSkeleton } from "../../../../../../components/antd/form/FormSkeleton";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../../components/antd/form/useHandleSubmit";
import { AdminManagementHeader } from "../../components/AdminManagementHeader";
import {
  AdminAccountFormBusinessRole,
  formatAdminAccountFormBusinessRolesToArray,
  useAdminAccountOptions,
} from "./useAdminAccountOptions";
import { AuthUserActions } from "./AuthUserActions/AuthUserActions";

type AdminAccountFormValues = Omit<UpsertAdminAccount, "businessRoles"> & {
  businessRoles: AdminAccountFormBusinessRole;
};

export function AdminAccountForm({
  id,
  isUpdateForm,
  initialValues,
  onSubmit,
}: {
  id?: number;
  isUpdateForm?: boolean;
  initialValues?: Partial<AdminAccountFormValues>;
  onSubmit: OnSubmit<UpsertAdminAccount | CreateAdminAccount>;
}) {
  const preOnSubmit: OnSubmit<AdminAccountFormValues> = useCallback(
    async (values) => {
      const newValues = {
        ...values,
        businessRoles: formatAdminAccountFormBusinessRolesToArray(
          values.businessRoles
        ),
      };
      return await onSubmit(newValues);
    },
    [onSubmit]
  );
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit: preOnSubmit,
  });

  const isAdmin = Form.useWatch("isAdmin", form);
  const pharmacyIds = Form.useWatch("pharmacies", form);
  const businessRoles = Form.useWatch("businessRoles", form);
  const { pharmacyOptions, businessRoleOptions, defaultBuyingPharmacyOptions, existingAccounts } =
    useAdminAccountOptions(pharmacyIds);

  const title = isUpdateForm ? `Edit Account ${id}` : "Create Account";
  const submitBtn = isUpdateForm ? "Update Account" : "Create Account";
  const loading = isUpdateForm ? !initialValues : false;
  const hasSelectedPharmacies = !!pharmacyIds?.length;
  const isManufacturing = businessRoles === UserBusinessRoles.MANUFACTURING;

  const onChangePharmacies = useCallback(
    (pharmacies: number[]) => {
      const defaultBuyingPharmacyId = form.getFieldValue(
        "defaultBuyingPharmacyId"
      );
      if (!defaultBuyingPharmacyId) return;

      const isDefaultBuyingPharmacySelected = pharmacies.some((id) => {
        return id === defaultBuyingPharmacyId;
      });
      if (isDefaultBuyingPharmacySelected) return;

      message.warning("Default buying pharmacy was reset");
      form.setFieldValue("defaultBuyingPharmacyId", null);
    },
    [form]
  );

  const onChangeIsAdmin = useCallback(
    (event: CheckboxChangeEvent) => {
      const newIsAdmin = event.target.checked;
      if (!newIsAdmin) {
        form.setFieldValue("isTrialUser", false);
      } else {
        form.setFieldsValue({
          pharmacies: [],
          defaultBuyingPharmacyId: null,
          isTrialUser: false,
          businessRoles: null,
        });
      }
    },
    [form]
  );

  const initialEmail = initialValues?.email;
  const accountTakenValidator = useCallback(
    async (_, email: string) => {
      if(isUpdateForm && initialEmail === email) return Promise.resolve();

      const accountAlreadyAdded = existingAccounts?.find((v) => v.email === email);
      if (accountAlreadyAdded) {
        const linkToEdit = <a tw="font-bold" target="_blank" rel="noreferrer noopener" href={`/admin/account/${accountAlreadyAdded.id}`}>click here</a>;
        return Promise.reject(<>An account already exists with this email. To edit this account {linkToEdit}</>)
      }
    },
  [existingAccounts, isUpdateForm, initialEmail]);

  if (loading) {
    return <FormSkeleton text="Loading Account" />;
  }

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          { title: <Link to="/admin/account">Accounts</Link> },
          { title },
        ]}
      >
        {!!id && (
          <div tw="flex items-center space-x-2">
            <AuthUserActions userId={id} />
          </div>
        )}
      </AdminManagementHeader>

      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        initialValues={initialValues}
        onFinish={handleSubmit}
        disabled={isSubmitting || isManufacturing}
        scrollToFirstError
      >
        <div tw="grid grid-cols-2 gap-x-8">
          <Form.Item label="ID" extra="Autogenerated">
            <Input disabled readOnly value={id} />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { type: "email" },
              { required: true },
              { validator: accountTakenValidator },
            ]}
          >
            <Input allowClear disabled={isManufacturing} />
          </Form.Item>

          <Form.Item
            label="Full Name"
            name="fullName"
            rules={[{ required: true }]}
          >
            <Input allowClear disabled={isManufacturing} />
          </Form.Item>

          <Form.Item name="isAdmin" label="Is Admin" valuePropName="checked">
            <Checkbox disabled={isManufacturing} onChange={onChangeIsAdmin} />
          </Form.Item>

          <Form.Item
            name="businessRoles"
            label="Business Roles"
            help={
              isAdmin
                ? "Not applicable for Admin users"
                : isManufacturing && (
                    <Text color="orange-1">
                      {
                        "This admin page doesn't support Manufacturer Usernames Yet"
                      }
                    </Text>
                  )
            }
            rules={[{ required: !isAdmin }]}
          >
            <Select
              options={businessRoleOptions}
              optionFilterProp="label"
              disabled={isAdmin}
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="isTrialUser"
            label="Is Trial User"
            valuePropName="checked"
            tooltip="Trial Manufacturer Users cannot download CSVs. Not applicable for pharmacy or Admin users"
            help={isAdmin && "Not applicable for Admin users"}
          >
            <Checkbox disabled={isAdmin || isManufacturing} />
          </Form.Item>

          <Form.Item
            name="pharmacies"
            label="Pharmacies"
            help={isAdmin && "Not applicable for Admin users"}
            rules={[{ required: !isAdmin }]}
          >
            <Select
              mode="multiple"
              options={pharmacyOptions}
              optionFilterProp="label"
              disabled={isSubmitting || isManufacturing || isAdmin}
              onChange={onChangePharmacies}
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="defaultBuyingPharmacyId"
            label="Default Buying Pharmacy"
            help={
              isAdmin
                ? "Not applicable for Admin users"
                : !hasSelectedPharmacies &&
                  "To enable select a pharmacy in the pharmacies field"
            }
          >
            <Select
              options={defaultBuyingPharmacyOptions}
              optionFilterProp="label"
              disabled={isAdmin || !hasSelectedPharmacies || isManufacturing}
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="supportContextMenu"
            label="Support Context Menu"
            valuePropName="checked"
            tooltip="Allow debugging mode in Electron with Inspect Element"
          >
            <Checkbox />
          </Form.Item>

          {!isUpdateForm && (
            <Form.Item
              name="sendWelcomeEmail"
              label="Send Welcome Email"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          )}
        </div>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting || isManufacturing}>
            {submitBtn}
          </Button>
        </Popconfirm>
      </Form>
    </>
  );
}
