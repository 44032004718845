import React from "react";
import "twin.macro";
import "core-js/actual/set-immediate";
import type { TableColumnType } from "antd";
import { Button, Input, Space } from "antd";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import get from "lodash/get";
import isNil from "lodash/isNil";
import { BLUE_1 } from "../../rxLibrary/colors";

export function getColumnSearchProps<T extends Record<string, any>>(
  dataIndex: string
): TableColumnType<T> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div tw="p-2" onKeyDown={(e) => e.stopPropagation()}>
        <Input
          tw="mb-2 block"
          placeholder="Filter"
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => confirm()}
          ref={(inputElement) => setImmediate(() => inputElement?.focus())}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined rev={undefined} />}
            size="small"
            style={{ width: 90 }}
          >
            Filter
          </Button>

          <Button
            onClick={() => {
              clearFilters && clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined
        style={{ color: filtered ? BLUE_1 : undefined }}
        rev={undefined}
      />
    ),
    onFilter: (value, record) => {
      const v = get(record, dataIndex);
      if (isNil(v)) return false;
      return v
        .toString()
        .toLowerCase()
        .includes(value.toString().toLowerCase());
    },
  };
}
