import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";
import type { DrugInfo, SearchResult } from "../../../utilities/types";
import { IDrugSearchTableData } from "../DrugSearch.constants";
import { drugInfoHasValidUnits } from "../../../utilities/drugInfo/drugInfo";

export function formatDrugTableData(
  searchResults: SearchResult[]
): IDrugSearchTableData {
  const formattedDrugs = formatDrugs(searchResults);

  const drugTableData = formattedDrugs.map((drugs) => {
    const uniqueDrugsOptions = uniqBy(drugs, "genericProductIdentifier");
    const sortedDrugsOptions = sortDrugOptions(uniqueDrugsOptions);
    const drugsWithSizeOptions = sortedDrugsOptions.map((drug) => {
      if (drugInfoHasValidUnits(drug)) {
        const drugOptions = drugs.filter((d) => {
          const result =
            d.routeOfAdministration === drug.routeOfAdministration &&
            d.strength === drug.strength &&
            d.form === drug.form;
          return result;
        });
        const drugSizeOptions = sortBy(drugOptions, ["unitSize"]).reverse();

        return { ...drug, sizeOptions: drugSizeOptions };
      }

      return drug;
    });

    return drugsWithSizeOptions;
  });

  return drugTableData;
}

function formatDrugs(searchResults: SearchResult[]): DrugInfo[][] {
  const drugsWithScoreGroupedByName = groupBy(
    searchResults.map((drug) => ({
      drug: {
        ...drug.representativeDrugInfo,
        dispensedLast90Days: drug.dispensedLast90Days,
      },
      drugName: drug.representativeDrugInfo.name,
      score: drug.score,
    })),
    "drugName"
  );

  const drugsWithScores = Object.values(drugsWithScoreGroupedByName).map(
    (groupedDrugs) => {
      const drugName = groupedDrugs[0].drugName;
      const scores = groupedDrugs.map((drug) => drug.score);
      const maxScore = Math.max(...scores);
      const drugs = groupedDrugs.map(({ drug }) => drug);

      return { drugs, maxScore, drugName };
    }
  );

  const drugs = sortBy(drugsWithScores, ["maxScore", "drugName"])
    .reverse()
    .map(({ drugs }) => drugs);

  return drugs;
}

function sortDrugOptions(drugs: DrugInfo[]): DrugInfo[] {
  drugs.sort((a, b) => {
    // ------ Form
    // tablet
    // capsule
    // other
    const firstForm = a.form
      ? a.form.toLocaleLowerCase() === "tablet"
        ? "a"
        : a.form.toLocaleLowerCase() === "capsule"
        ? "b"
        : a.form.toLocaleLowerCase()
      : "x";
    const secondForm = b.form
      ? b.form.toLocaleLowerCase() === "tablet"
        ? "a"
        : b.form.toLocaleLowerCase() === "capsule"
        ? "b"
        : b.form.toLocaleLowerCase()
      : "x";
    if (firstForm < secondForm) {
      return -1;
    }
    if (firstForm > secondForm) {
      return 1;
    }

    // ------ Strength Unit
    const firstStrengthUni = a.strength
      ? a.strengthUnit
        ? a.strengthUnit
        : "x"
      : "y";
    const secondStrengthUni = b.strength
      ? b.strengthUnit
        ? b.strengthUnit
        : "x"
      : "y";
    if (firstStrengthUni < secondStrengthUni) {
      return -1;
    }
    if (firstStrengthUni > secondStrengthUni) {
      return 1;
    }

    // ------ Strength Unit
    const firstStrength = a.strength ? a.strength : "x";
    const secondStrength = b.strength ? b.strength : "x";

    if (isNaN(Number(firstStrength)) || isNaN(Number(secondStrength))) {
      const aa = firstStrength.split(/(\d+)/);
      const bb = secondStrength.split(/(\d+)/);

      for (let i = 0; i < Math.max(aa.length, bb.length); i++) {
        if (aa[i] != bb[i]) {
          const cmp1 = isNaN(parseInt(aa[i], 10)) ? aa[i] : parseInt(aa[i], 10);
          const cmp2 = isNaN(parseInt(bb[i], 10)) ? bb[i] : parseInt(bb[i], 10);
          if (cmp1 == undefined || cmp2 == undefined) {
            return aa.length - bb.length;
          } else {
            return cmp1 < cmp2 ? -1 : 1;
          }
        }
      }
    } else {
      if (Number(firstStrength) < Number(secondStrength)) {
        return -1;
      }
      if (Number(firstStrength) > Number(secondStrength)) {
        return 1;
      }
    }

    return 0;
  });

  return drugs;
}
