"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getVersion = getVersion;
exports.loginCallback = loginCallback;
exports.normalizeAuthParams = normalizeAuthParams;
exports.normalizeError = normalizeError;
exports.trimAuthParams = trimAuthParams;
exports.webAuthOverrides = webAuthOverrides;
var _excluded = ["popup"];
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _objectDestructuringEmpty(obj) { if (obj == null) throw new TypeError("Cannot destructure " + obj); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function normalizeError(error, domain) {
  if (!error) {
    return error;
  }

  // TODO: clean this mess, the first checks are for social/popup,
  // then we have some stuff for passwordless and the latter is for
  // db.

  // TODO: the following checks were copied from https://github.com/auth0/lock/blob/0a5abf1957c9bb746b0710b274d0feed9b399958/index.js#L1263-L1288
  // Some of the checks are missing because I couldn't reproduce them and I'm
  // afraid they'll break existent functionality if add them.
  // We need a better error handling story in auth0.js.

  if (error.status === 'User closed the popup window') {
    // {
    //   status: "User closed the popup window",
    //   name: undefined,
    //   code: undefined,
    //   details: {
    //     description: "server error",
    //     code: undefined
    //   }
    // }
    return {
      code: 'lock.popup_closed',
      error: 'lock.popup_closed',
      description: 'Popup window closed.'
    };
  }
  if (error.code === 'unauthorized') {
    // Custom rule error
    //
    // {
    //   "code": "unauthorized",
    //   "details": {
    //     "code": "unauthorized",
    //     "error_description": "user is blocked",
    //     "error": "unauthorized"
    //   },
    //   "name": "unauthorized",
    //   "status": 401
    // }

    // Default "user is blocked" rule error
    //
    // {
    //   "code": "unauthorized",
    //   "details": {
    //     "code": "unauthorized",
    //     "error_description": "user is blocked",
    //     "error": "unauthorized"
    //   },
    //   "name": "unauthorized",
    //   "status": 401
    // }

    // Social cancel permissions.
    //
    // {
    //   code: "unauthorized",
    //   details: {
    //     code: "unauthorized"
    //     error: "unauthorized"
    //     error_description: "access_denied"
    //   },
    //   name: "unauthorized"
    //   status: 401
    // }

    // Social cancel permissions or unknown error
    if (!error.description || error.description === 'access_denied') {
      return {
        code: 'lock.unauthorized',
        error: 'lock.unauthorized',
        description: error.description || 'Permissions were not granted.'
      };
    }

    // Special case for custom rule error
    if (error.description === 'user is blocked') {
      return {
        code: 'blocked_user',
        error: 'blocked_user',
        description: error.description
      };
    }

    // Custom Rule error
    return {
      code: 'rule_error',
      error: 'rule_error',
      description: error.description
    };
  }
  if (window.location.host !== domain && (error.error === 'access_denied' || error.code === 'access_denied')) {
    return {
      code: 'invalid_user_password',
      error: 'invalid_user_password',
      description: error.description
    };
  }
  if (error.code === 'invalid_captcha') {
    var _ref;
    return _ref = {
      code: 'invalid_captcha'
    }, _defineProperty(_ref, "code", 'invalid_captcha'), _defineProperty(_ref, "description", error.description), _ref;
  }
  var result = {
    error: error.code ? error.code : error.statusCode || error.error,
    description: error.description || error.code
  };

  // result is used for passwordless and error for database.
  return result.error === undefined && result.description === undefined ? error : result;
}
function loginCallback(redirect, domain, cb) {
  return redirect ? function (error) {
    return cb(normalizeError(error, domain));
  } : function (error, result) {
    return cb(normalizeError(error, domain), result);
  };
}
function normalizeAuthParams(_ref2) {
  var popup = _ref2.popup,
    authParams = _objectWithoutProperties(_ref2, _excluded);
  return authParams;
}
function webAuthOverrides() {
  var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    __tenant = _ref3.__tenant,
    __token_issuer = _ref3.__token_issuer,
    __jwks_uri = _ref3.__jwks_uri;
  if (__tenant || __token_issuer || __jwks_uri) {
    return {
      __tenant: __tenant,
      __token_issuer: __token_issuer,
      __jwks_uri: __jwks_uri
    };
  }
  return null;
}
function trimAuthParams() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var p = Object.assign({}, (_objectDestructuringEmpty(params), params));
  ['username', 'email', 'phoneNumber', 'mfa_code'].forEach(function (k) {
    if (typeof p[k] === 'string') {
      p[k] = p[k].trim();
    }
  });
  return p;
}
function getVersion() {
  return "12.0.2";
}
