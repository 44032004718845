import { useMemo } from "react";
import type {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import { rxChartTheme } from "../rxChart.theme";
import type { TransactionsBreakdownType } from "./overviewReport.types";
import { numberFormatter } from "../utils/performanceReportFormatters";

function renderTooltip({
  datum,
  xKey,
  yKey,
  yName,
}: AgCartesianSeriesTooltipRendererParams) {
  return {
    title: datum[xKey],
    content: `${yName}: ${numberFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Transaction Breakdown",
  },
  series: [
    {
      type: "bar",
      xKey: "transactionName",
      yKey: "transactionValue",
      yName: "Transactions",
      direction: "horizontal",
      cornerRadius: 10,
      fill: "rgb(55, 176, 103)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "left",
    },
    {
      type: "number",
      position: "bottom",
      title: {
        text: "Transaction Count",
      },
    },
  ],
};

const transactionTypes = [
  { key: "genericRx", title: "Generic Rx" },
  { key: "brandRx", title: "Brand Rx" },
  { key: "otc", title: "OTC" },
  { key: "otherInclCompounds", title: "Other incl. Compounds" },
] as const;

type ReportDataType = {
  transactionName: string;
  transactionValue: number;
};

export function TransactionBreakdownBarChart({
  data,
}: {
  data?: TransactionsBreakdownType;
}) {
  let reportData: ReportDataType[] = [];

  reportData = useMemo(() => {
    if (!data) return [];

    return transactionTypes
      .map(({ key, title }) => ({
        transactionName: title,
        transactionValue: data[key],
      }))
      .filter((v) => v.transactionValue !== null);
  }, [data, transactionTypes]);

  return <AgCharts options={{ data: reportData, ...OPTIONS }} />;
}
