import React from "react";
import { Text } from "../../../../components/rxLibrary/typography";
import { BUTTON_SHADOW_CSS } from "../../../../components/rxLibrary/buttons";
import { Box } from "../../../../components/rxLibrary/box";
import { openChat } from "../../../../utilities/chat/openChat";

const AdChatWithUs = Box.withComponent("button");

export function MarketingAd() {
  return (
    <AdChatWithUs
      tw="flex text-left rounded-lg flex-1 space-x-3 py-[17px] pr-[26px]"
      bgColor="grey-4"
      css={BUTTON_SHADOW_CSS}
      onClick={openChat}
    >
      <img src="/assets/pages/home/purchases-ad.svg" tw="ml-[-10px]" />

      <div>
        <Text tw="mb-1" size="medium" weight="bold" block>
          Streamline your purchases
        </Text>

        <Text tw="mb-2" block>
          Connect all your suppliers, including primary, secondaries &
          manufacturers.
        </Text>

        <Box
          tw="inline-block py-[5px] px-[20px] rounded-full"
          bgColor="yellow-1"
        >
          <Text weight="bold">Interested? Chat with Us</Text>
        </Box>
      </div>
    </AdChatWithUs>
  );
}
