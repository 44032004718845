import React, { createContext } from "react";
import { SupplierItemChangeOperation } from "../utilities/shoppingListTypes";

type EnhancedClientNavBarCommandContext = {
    requestCartChange: (supplier: string, operations: SupplierItemChangeOperation[]) => void,
    requestShoppingListSwitch: (supplier: string) => void,
    requestCancelSupplierLogin: (supplier: string) => void,
    setNavBarHeight: (name: string, height: number) => void,
}

const EnhancedClientNavBarCommandContext = createContext<EnhancedClientNavBarCommandContext>({
    requestCartChange: () => {},
    requestShoppingListSwitch: () => {},
    requestCancelSupplierLogin: () => {},
    setNavBarHeight: () => {},
});
export default EnhancedClientNavBarCommandContext;

type EnhancedClientNavBarCommandContextProviderProps = {
    children?: React.ReactNode;
};

export function EnhancedClientNavBarCommandContextProvider({
                                                  children,
                                              }: EnhancedClientNavBarCommandContextProviderProps): JSX.Element {
    const requestCartChange = (supplier: string, operations: SupplierItemChangeOperation[]) => {
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "requestCartChange",
        payload: {
          supplier: supplier,
          operations: operations,
        }
      });
    }
    const requestShoppingListSwitch = (supplier: string) => {
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "requestShoppingListSwitch",
        payload: {
          supplier: supplier,
        }
      });
    }

    const setNavBarHeight: (name: string, height: number) => void = (name, height) => {
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "setNavBarHeight",
        payload: {
          height: height,
          name: name,
        },
      })
    }

    const requestCancelSupplierLogin = (supplier: string) => {
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "requestCancelSupplierLogin",
        payload: {
          supplier: supplier,
        },
      })
    }

    return (
        <EnhancedClientNavBarCommandContext.Provider value={{
            requestCartChange,
            requestShoppingListSwitch,
            setNavBarHeight,
            requestCancelSupplierLogin,
        }}>{children}</EnhancedClientNavBarCommandContext.Provider>
    );
}
