import pluralize from 'pluralize';
import type {
  DrugInfo,
  OptionConvertable,
  OptionData,
} from './types';

export const numberWithCommas = (n: number): string => {
  const nStrArr = n.toString().split('.');
  if (nStrArr.length > 1) {
    nStrArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return nStrArr.join('.');
  }

  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const uomDescription = (value: number, drugInfo: DrugInfo, isWithPack = false, isUnitOnly = false): string => {
  let measureDisplay = "";
  if (drugInfo.uom === "Each") {
    measureDisplay = ` Unit`;
  } else if (drugInfo.uom === "Milliliter") {
    measureDisplay = "ml";
  } else if (drugInfo.uom === "Gram") {
    measureDisplay = "g";
  }

  // Return only the unit
  if (isUnitOnly) {
    return measureDisplay !== "g" ? pluralize(measureDisplay, value) : measureDisplay;
  }

  // Return the data with pack infomation: E.g. Pack (3 x 100 Units) or Pack (100 Units)
  if (isWithPack) {
    if (drugInfo.uom === 'Each') measureDisplay = ' Units'
    if (drugInfo.unitQuantity === 1) {
      return `Pack (${drugInfo.unitSize}${measureDisplay})`
    } else if (drugInfo.unitSize === 1) {
      return `Pack (${drugInfo.unitQuantity}${measureDisplay})`
    } else {
      return `Pack (${drugInfo.unitQuantity}x${drugInfo.unitSize}${measureDisplay})`
    }
  }

  return `${numberWithCommas(parseFloat(value.toFixed(2)))}${
    measureDisplay !== "g" ? pluralize(measureDisplay, value) : measureDisplay
  }`;
};

export const getTrueForUndefined = (value?: boolean): boolean => {
  // default is true if it's undefined
  if (value === undefined) return true;
  return value as boolean;
};

export function toSortedOptionData(arg: OptionConvertable[]): OptionData[] {
  return arg.map(({ id, name }) => {
    return {
      value: id,
      label: name,
    };
  }).sort((a, b) => {
    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
  });
}

export const sleepMillis = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}