import React, { useCallback, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { ensureArray } from "../../../utilities/arrays/ensureArray";
import { getQueryParams } from "../../../utilities/queryParams/getQueryParams";
import { TableColumns, TableFilters, TableOnChange } from "./table.constants";
import { useReplaceQueryParams } from "../../../utilities/queryParams/useReplaceQueryParams";

function getFilters<T extends Record<string, any>>(
  columns: TableColumns<T>
): TableFilters<T> | undefined {
  const queryParams = getQueryParams();

  const dataIndexes = columns.reduce<string[]>((acc, col) => {
    if (!("dataIndex" in col)) return acc;

    let dataIndex: string | undefined;
    if (typeof col.dataIndex === "string") {
      dataIndex = col.dataIndex;
    } else if (Array.isArray(col.dataIndex)) {
      dataIndex = dataIndexes.join(",");
    }

    if (dataIndex && queryParams[dataIndex]) acc.push(dataIndex);

    return acc;
  }, []);

  const filters = dataIndexes.reduce<Record<string, string[]>>(
    (acc, dataIndex) => {
      const qpValue = queryParams[dataIndex];
      if (!qpValue) return acc;

      acc[dataIndex] = ensureArray(qpValue);
      return acc;
    },
    {}
  );

  if (!isEmpty(filters)) return filters;
}

/**
 * This hook only add the query params filters to the columns that already have a `onFilter` property and the `dataIndex` match a already present query param when the page is loaded.
 */
export function useColumnsWithQueryParamsFilters<T extends Record<string, any>>(
  columns: TableColumns<T>
) {
  const replaceQueryParams = useReplaceQueryParams();
  const [filters, setFilters] = useState(() => getFilters(columns));
  const [columnsWithFilters = columns, setColumnsWithFilters] =
    useState<TableColumns<T>>();

  const onTableChange: TableOnChange<T> = useCallback(
    (_, filters) => {
      setFilters(filters);
      replaceQueryParams({});
    },
    [replaceQueryParams]
  );

  useEffect(() => {
    if (!filters || isEmpty(filters)) {
      setColumnsWithFilters(undefined);
      return;
    }

    const newColumns = columns.map((col) => {
      if (!("dataIndex" in col)) return col;

      const { dataIndex, onFilter } = col;
      if (!dataIndex || !onFilter) return col;
      if (typeof dataIndex !== "string") return col;

      const filteredValue = filters[dataIndex];
      if (!filteredValue?.length) return col;

      return { ...col, filteredValue };
    });

    setColumnsWithFilters(newColumns);
  }, [filters, columns]);

  return { columnsWithFilters, onTableChange };
}
