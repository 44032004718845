import type {
  OfferedDrug,
  ServerState,
  Prescription,
  DrugWithStats,
  PrescriptionGroup,
  PrescriptionGroupsRequestItem,
} from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";
import { ensureArray } from "../utilities/arrays/ensureArray";
import { cleanServerState } from "../utilities/prescriptions/cleanServerState";
import { backendFetch, backendPost } from "./legacy/config/backend-api";

// MAS-TBD: Remove version
type PrescriptionParams = {
  pharmacy_id: string;
  ndc?: string;
  drug_name?: string;
  generic_product_identifier?: string;
  unit_size?: string;
  version?: string;
  use_cache?: string;
};

type ServerStatePayload = {
  data?: ServerState;
  errors?: string[];
  versionId?: number;
};

type ServerCartJsonBlob = {
  createdAt: string;
  data: ServerState;
  updatedAt: string;
  versionId: number;
};

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPrescriptions(
  pharmacyId: number,
  token: string,
  useCache = false
) {
  // MAS-TBD: Remove version
  const params: PrescriptionParams = {
    pharmacy_id: pharmacyId.toString(),
    use_cache: useCache ? "true" : "false",
    version: "2",
  };
  const response = await backendFetch<{ prescriptions: Prescription[] }>(
    `/prescriptions?${new URLSearchParams(params)}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response.data) return response;
  return {};
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPrescriptionGroups(
  pharmacyId: number | null,
  token: string,
  prescriptionCartOptions: PrescriptionGroupsRequestItem[]
): Promise<{
  data?: {
    error?: boolean;
    prescriptionGroups: PrescriptionGroup[];
    offeredDrugs: OfferedDrug[];
  };
  errors?: string[];
}> {
  // MAS-TBD: Remove version
  const dataPayload = { data: { prescriptionCartOptions }, version: "2" };
  const payload = JSON.stringify(dataPayload);
  const response = await backendPost(
    `/prescription-groups?pharmacy_id=${pharmacyId}`,
    payload,
    { headers: { Authorization: "Bearer " + token } }
  );

  if (response) return await response.json();
  return {};
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getSinglePrescription(
  pharmacyId: number,
  token: string,
  ndc: string | null,
  drug_name?: string,
  generic_product_identifier?: string,
  unit_size?: string
) {
  const modifiedNdc = ndc ? ndc.replace(/\D+/g, "") : null;
  const params: PrescriptionParams = { pharmacy_id: pharmacyId.toString() };

  if (modifiedNdc) params.ndc = modifiedNdc;
  if (drug_name) params.drug_name = drug_name;
  if (generic_product_identifier)
    params.generic_product_identifier = generic_product_identifier;
  if (unit_size) params.unit_size = unit_size;

  const response = await backendFetch<{
    error?: boolean;
    drugs: DrugWithStats[];
  }>(`/drugs?${new URLSearchParams(params)}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response?.data) return response.data;
  return { drugs: [] };
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function clearJSONBlob(token: string, pharmacyId: number | null) {
  const dataPayload: ServerStatePayload = { data: cleanServerState() };
  const payload = JSON.stringify(dataPayload);
  const response = await backendPost(
    `/cart?pharmacy_id=${pharmacyId}`,
    payload,
    { headers: { Authorization: "Bearer " + token } }
  );
  return response.status;
}

const blobVersionIdsForPharmacies: Record<number, number> = {};

export function getBlobVersionId(pharmacyId: number) {
  return blobVersionIdsForPharmacies[pharmacyId] || 0;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function pushJSONBlob(
  token: string,
  pharmacyId: number,
  data: ServerState
) {
  const dataPayload: ServerStatePayload = {
    data,
    versionId: getBlobVersionId(pharmacyId),
  };

  const payload = JSON.stringify(dataPayload);
  const response = await backendPost(
    `/cart?pharmacy_id=${pharmacyId}`,
    payload,
    { headers: { Authorization: "Bearer " + token } }
  );

  if (!response) return { ok: false, status: 400, data: null };

  const responseData: { data: ServerCartJsonBlob | null } =
    await response.json();
  if (responseData.data) {
    blobVersionIdsForPharmacies[pharmacyId] = responseData.data.versionId;
  }
  return { ok: response.ok, status: response.status, data: responseData.data };
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getJSONBlob(pharmacyId: number, token: string) {
  const params: PrescriptionParams = {
    pharmacy_id: pharmacyId ? pharmacyId.toString() : "0",
  };

  const response = await backendFetch<ServerCartJsonBlob | undefined>(
    `/cart?${new URLSearchParams(params)}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );
  if (!response?.data) return;

  blobVersionIdsForPharmacies[pharmacyId] = response.data.versionId;
  return response.data;
}

/**
 * *Note:* The request is gonna fail with a 400 error if `rx` is an empty array
 */
export async function getRxes(
  client: RequestClient,
  {
    rx,
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    rx: string | string[];
    pharmacyId: number;
  }>
) {
  return client<{
    prescriptions: Prescription[];
    error?: { message: string; missingRxes: string[] };
  }>({
    ...config,
    url: "/rxes",
    params: { pharmacyId, rx: ensureArray(rx) },
  });
}
