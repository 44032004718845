import React, {useContext, useState} from 'react';
import tw, { styled } from "twin.macro";
import Modal from "react-modal";
import { v4 as uuidv4 } from 'uuid';
import EnhancedClientContext from "./EnhancedClientContext";
import AppContext from "../contexts/AppContext";
import PharmaciesContext from "../contexts/PharmaciesContext";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";

if (Modal.defaultStyles.overlay) {
    Modal.defaultStyles.overlay.backgroundColor = 'rgba(0.5, 0.5, 0.5, 0.75)';
}

/** @deprecated use rx library button */
const Button = styled.button(({ primary = true }: { primary?: boolean }) => [
  tw`py-[14px] px-[21px] rounded font-bold leading-[18px]`,
  primary
    ? tw`bg-primeblue text-white text-base py-[12px] px-[31px] hover:bg-gradient-to-t from-[#4670e3] to-[#3d57cf] hover:text-white`
    : tw`bg-white text-primary`,
]);

export default function EnhancedReportProblemModal():JSX.Element {
    const {
        enhancedOverlayShown,
        enhancedReportProblemModalShown,
        setEnhancedReportProblemModalShown,
        reportProblem,
    } = useContext(EnhancedClientContext)
    const { user } = useContext(AppContext);
    const { pharmacies } = useContext(PharmaciesContext);
    const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);
    const [problemDescription, setProblemDescription] = useState<string>("");

    const currentPharmacy = pharmacies.find(p => p.id === currentBuyingPharmacyId);

    return <Modal
        isOpen={enhancedReportProblemModalShown && !enhancedOverlayShown}
        contentLabel="Report Problem"
        onRequestClose={() => setEnhancedReportProblemModalShown(false)}
        appElement={document.getElementsByName("body"[0])}
        style={{content: {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '620px',
                height: '270px',
            }
        }}
    >
        <div tw="text-xl text-primary mb-4">Please describe the problem:</div>
        <div>
            <textarea
                rows={5}
                cols={80}
                onChange={(evt) => setProblemDescription(evt.target.value)} value={problemDescription}
                tw="bg-accent border border-gray-400 border-solid mb-4"
            />
        </div>
        <div tw="flex justify-end">
            <Button primary={false} tw="border border-primary border-solid" value="Cancel" onClick={() => {
                setProblemDescription("");
                setEnhancedReportProblemModalShown(false);
            }}>Cancel</Button>
            <Button tw="ml-4" value="Send" onClick={() => {
                reportProblem({
                    user,
                    currentPharmacy,
                    problemDescription,
                    reportId: uuidv4(),
                });
                setProblemDescription("");
                setEnhancedReportProblemModalShown(false);
                if (window._chatlio) {
                  window._chatlio.show()
                  window._chatlio.send('Problem reported: ' + problemDescription)
                }
            }}>Send</Button>
        </div>
    </Modal>;
}
