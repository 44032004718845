import {RequestClient} from "./request/requestClient";

let downloadExecuted = false;

export async function downloadClientApp(client: RequestClient) {
  const result = await getDownloadLink(client);
  console.log("downloadClientApp", result);
  if (result?.data?.data?.url && !downloadExecuted) {
    downloadExecuted = true;
    window.location.href = result.data.data.url;
  }
}
export async function getDownloadLink(
  client: RequestClient
) {
  return client<{ url: string }>({
    url: "/app-download",
  });
}
