import { useLocation } from "react-router";

export const useSearchParams = <T extends string>(...names: T[]) => {
    const params = new URLSearchParams(useLocation().search);
    return names.reduce((result, name) => {
        const value = params.get(name);
        if (value) {
            result[name] = value;
        }
        return result;
    }, {} as Record<T, string | undefined>);
}