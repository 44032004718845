import React from "react";
import "twin.macro";
import { useCreateForm } from "../../../../../components/antd/form/useCreateForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { createAdminRefillMapping } from "../../../../../services/refillMappings";
import { AdminRefillMappingForm } from "./AdminRefillMappingForm";

export function CreateAdminRefillMapping() {
  const onSubmit = useCreateForm({
    redirectTo: "/admin/refill-mapping",
    create: createAdminRefillMapping,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminRefillMappingForm onSubmit={onSubmit} />
    </WideContainer>
  );
}
