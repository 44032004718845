import pluralize from "pluralize";
import type { DrugInfo } from "../types";

export function formatDrugDispenseQuantity(
  drug: DrugInfo,
  {
    omitUnits,
  }: {
    omitUnits?: boolean;
  } = {}
): string {
  let quantity = 0;
  let quantityDisplay = "";
  if (drug.uom === "Each") {
    if (drug.unitQuantity === 1) {
      if (drug.unitSize === 1) {
        quantity = 1;
        quantityDisplay = "1";
      } else {
        quantity = drug.unitSize;
        quantityDisplay = `${drug.unitSize}`;
      }
    } else {
      if (drug.unitSize === 1) {
        quantity = drug.unitQuantity;
        quantityDisplay = `${drug.unitQuantity}`;
      } else {
        quantity = drug.unitQuantity;
        quantityDisplay = `${drug.unitQuantity} × ${drug.unitSize}`;
      }
    }
  } else {
    let measureDisplay = "";
    if (drug.uom === "Milliliter") {
      measureDisplay = "ml";
    } else if (drug.uom === "Gram") {
      measureDisplay = "g";
    }
    quantity = drug.unitQuantity;
    if (drug.unitQuantity === 1) {
      quantityDisplay = `${drug.unitSize}${measureDisplay}`;
    } else {
      quantityDisplay = `${drug.unitQuantity} × ${drug.unitSize}${measureDisplay}`;
    }
  }

  if (omitUnits) {
    return quantityDisplay;
  }

  let unit = "";
  if (drug.uom === "Each") {
    unit = drug.form;
  } else if (drug.packageDescription === "Box") {
    if (drug.form === "Granules") {
      unit = "Box";
    } else {
      unit = drug.form;
    }
  } else {
    unit = drug.packageDescription;
  }

  return `${quantityDisplay} ${pluralize(unit, quantity)}`;
}
