import React, { useEffect } from "react";
const downIcon = new URL('svgurl:../../assets/down.svg', import.meta.url).toString();
import "./HorizontalCarousel.css";

export default function HorizontalCarousel({
  id,
  scrollDistance = 96,
  children,
}: {
  id: string;
  // width of an item in px
  scrollDistance?: number;
  children: React.ReactNode;
}): JSX.Element {
  useEffect(() => {
    console.log("-", scrollDistance);
    Carousel(id, scrollDistance);
  }, []);
  return (
    <div
      id={id}
      role="region"
      aria-label="All Suppliers"
      className="horizontal-carousel"
    >
      <div className="horizontal-nav horizontal-nav-left">
        <a
          className="nav-arrow"
          aria-label="scroll content to left"
          href="#"
          tabIndex={-1}
          aria-disabled="true"
        >
          <img src={downIcon} alt="" aria-hidden={true} />
        </a>
      </div>

      <div className="horizontal-carousel-nav">
        <div className="horizontal-carousel-content">{children}</div>
      </div>

      <div className="horizontal-nav horizontal-nav-right">
        <a
          className="nav-arrow"
          aria-label="scroll content to right"
          href="#"
          tabIndex={-1}
          aria-disabled="true"
        >
          <img src={downIcon} alt="" aria-hidden={true} />
        </a>
      </div>
    </div>
  );
}

const Carousel = (id: string, scrollDistance: number) => {
  const elem = document.querySelector(`#${id}`);
  let nav: HTMLElement | null = null;
  let navLeft: HTMLElement | null = null;
  let navRight: HTMLElement | null = null;
  let navContents: HTMLElement | null = null;

  // let scrollPosition = 0;
  let scrollCheck = false;

  const settings = {
    transition: false,
    direction: "",
  };

  const init = () => {
    if (!elem) return;

    nav = elem.querySelector(".horizontal-carousel-nav");
    navContents = elem.querySelector(".horizontal-carousel-content");

    navLeft = elem.querySelector(".horizontal-nav-left");
    navRight = elem.querySelector(".horizontal-nav-right");

    //  determine if overflow is in place
    elem.setAttribute("data-overflow", overflowDirection());

    nav?.addEventListener("scroll", addNavEvents);
    navRight?.querySelector("a")?.addEventListener("click", addNavRightEvents);
    navLeft?.querySelector("a")?.addEventListener("click", addNavLeftEvents);

    navContents?.addEventListener("transitionend", addTransitionEvents, false);
  };

  const overflowDirection = () => {
    const containerMetrics = nav?.getBoundingClientRect();
    const containerMetricsRight = containerMetrics
      ? Math.floor(containerMetrics.right)
      : 0;
    const containerMetricsLeft = containerMetrics
      ? Math.floor(containerMetrics.left)
      : 0;
    const contentMetrics = navContents?.getBoundingClientRect();
    const contentMetricsRight = contentMetrics
      ? Math.floor(contentMetrics.right) - 1
      : 0;
    const contentMetricsLeft = contentMetrics
      ? Math.floor(contentMetrics.left)
      : 0;

    if (navRight && navLeft) {
      if (
        containerMetricsLeft > contentMetricsLeft &&
        containerMetricsRight < contentMetricsRight
      ) {
        a11y(navRight, true);
        a11y(navLeft, true);

        return "both";
      } else if (contentMetricsLeft < containerMetricsLeft) {
        a11y(navRight, false);
        a11y(navLeft, true);

        return "left";
      } else if (contentMetricsRight > containerMetricsRight) {
        a11y(navRight, true);
        a11y(navLeft, false);

        return "right";
      } else {
        a11y(navRight, false);
        a11y(navLeft, false);

        return "none";
      }
    } else {
      return "none";
    }
  };

  const adjustScroll = () => {
    elem?.setAttribute("data-overflow", overflowDirection());
  };

  const addNavEvents = () => {
    // scrollPosition: last_known_scroll_position
    // ticking : scrollCheck
    // scrollPosition = window.scrollY;
    if (!scrollCheck) {
      window.requestAnimationFrame(() => {
        adjustScroll();
        scrollCheck = false;
      });
    }

    scrollCheck = true;
  };

  const addNavLeftEvents = (e: MouseEvent) => {
    if (e) e.preventDefault();
    if (settings.transition === true) return;

    if (overflowDirection() === "left" || overflowDirection() === "both") {
      const availableScrollLeft = nav ? nav.scrollLeft : 0;
      if (navContents) {
        if (availableScrollLeft < scrollDistance * 2) {
          navContents.style.transform = `translateX(${availableScrollLeft}px)`;
        } else {
          navContents.style.transform = `translateX(${scrollDistance}px)`;
        }
        removeClass(navContents, "no-transition");
      }

      // Update settings
      settings.direction = "left";
      settings.transition = true;
    }

    elem?.setAttribute("data-overflow", overflowDirection());
  };
  const addNavRightEvents = (e: MouseEvent) => {
    if (e) e.preventDefault();
    if (settings.transition === true) return;

    if (overflowDirection() === "right" || overflowDirection() === "both") {
      const navBarRightEdge = navContents
        ? navContents.getBoundingClientRect().right
        : 0;
      const navBarScrollerRightEdge = nav
        ? nav.getBoundingClientRect().right
        : 0;

      const availableScrollRight = Math.floor(
        navBarRightEdge - navBarScrollerRightEdge
      );

      if (navContents) {
        if (availableScrollRight < scrollDistance) {
          navContents.style.transform = `translateX(-${availableScrollRight}px)`;
        } else {
          navContents.style.transform = `translateX(-${scrollDistance}px)`;
        }

        removeClass(navContents, "no-transition");
      }

      // Update settings
      settings.direction = "right";
      settings.transition = true;
    }

    elem?.setAttribute("data-overflow", overflowDirection());
  };

  const addTransitionEvents = () => {
    // get the value of the transform, apply that to the current scroll position (so get the scroll pos first) and then remove the transform
    const styleOfTransform: CSSStyleDeclaration | null = navContents
      ? window.getComputedStyle(navContents, null)
      : null;
    const tr =
      styleOfTransform?.getPropertyValue("-webkit-transform") ||
      styleOfTransform?.getPropertyValue("transform");
    // If there is no transition we want to default to 0 and not null
    const amount = tr ? Math.abs(parseInt(tr.split(",")[4]) || 0) : 0;

    if (navContents) {
      navContents.style.transform = "none";
      addClass(navContents, "no-transition");
    }
    // Now lets set the scroll position
    if (settings.direction === "left" && nav) {
      nav.scrollLeft = nav.scrollLeft - amount;
    } else if (nav) {
      nav.scrollLeft = nav.scrollLeft + amount;
    }

    // Update settings
    settings.transition = false;
  };

  const removeClass = (elem: HTMLElement, className: string) => {
    if (elem && elem.classList) {
      if (elem.classList.contains(className)) {
        elem.classList.remove(className);
      }
    }
  };

  const addClass = (elem: HTMLElement, className: string) => {
    if (!elem) return;

    if (Array.isArray(className)) {
      className.forEach(function (cName) {
        checkClassName(elem, cName);
      });
    } else {
      checkClassName(elem, className);
    }
  };

  const checkClassName = (elem: HTMLElement, className: string) => {
    if (elem) {
      if (elem.classList) {
        elem.classList.add(className);
      } else {
        elem.className += " " + className;
      }
    }
  };

  // mode: 1, show
  // mode: 0, show
  const a11y = (nav: HTMLElement, mode: boolean) => {
    const a = nav.querySelector("a");
    if (mode) {
      a?.setAttribute("tabindex", "0");
      a?.setAttribute("aria-disabled", "false");
    } else {
      a?.setAttribute("tabindex", "-1");
      a?.setAttribute("aria-disabled", "true");
    }
  };

  init();
};
