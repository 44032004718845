import { ItemInCart } from "../../../../../../utilities/types";
import { ShoppingListColumnActionsPrescriptionInfo } from "./ShoppingListColumnActionsPrescriptionInfo";
import { ShoppingListColumnActionsDropdown } from "./ShoppingListColumnActionsDropdown";

export function ShoppingListColumnActions({
  prescription,
  updatePrescriptionStatus,
}: {
  prescription: ItemInCart;
  updatePrescriptionStatus: (prescription: ItemInCart, status: string) => void;
}) {
  return (
    <div tw="flex space-x-[10px]">
      <div tw="flex-1 flex flex-col items-center">
        <ShoppingListColumnActionsPrescriptionInfo
          prescription={prescription}
          updatePrescriptionStatus={updatePrescriptionStatus}
        />
      </div>

      <div tw="space-y-[7px]">
        <ShoppingListColumnActionsDropdown prescription={prescription} />
      </div>
    </div>
  );
}
