import React, { useEffect, useState } from "react";
import "twin.macro";
import { Switch } from "../../../../components/rxLibrary/switch";
import { Text } from "../../../../components/rxLibrary/typography";
import { ItemInCartWithVisibility } from "../useShoppingListItems/useShoppingListItems.constants";

export function ShoppingListTableOptimizedSwitchFilter({
  items,
  hideOptimizedItems,
  setHideOptimizedItems,
}: {
  items: ItemInCartWithVisibility[];
  hideOptimizedItems: boolean;
  setHideOptimizedItems: (isHide: boolean) => void;
}) {
  const [optimizedCount, setOptimizedCount] = useState(0);

  useEffect(() => {
    const optimizedPrescriptions = items.filter((item) => {
      return item.prescription.status === "processed";
    });

    setOptimizedCount(optimizedPrescriptions.length);
  }, [items]);

  if (optimizedCount === 0) {
    return null;
  }
  return (
    <div tw="flex space-x-2 items-center">
      <Switch checked={hideOptimizedItems} onChange={setHideOptimizedItems} />
      <Text size="medium">
        Hide Items That Were On Suppliers{"'"} Optimized Lists
      </Text>
    </div>
  );
}
