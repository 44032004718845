import isFinite from "lodash/isFinite";
import isString from "lodash/isString";

export function stringToStringNumbersOrUndefined(
  value: string,
  { removeSeparators }: { removeSeparators?: boolean } = {}
) {
  // Only strings
  if (!isString(value) || value === "") return;

  let str = value;
  if (removeSeparators) {
    // Remove white spaces and dashes
    str = str.replace(/(\s|-)/g, "");
  }

  const isNumber = isFinite(Number(str));
  if (isNumber) return str;
}
