import { useMemo, useEffect, useState } from "react";
import {
  getFFFOvernightShippingEndHourStr,
  shouldDisplayFFFOvernightShippingMsg,
} from "../../../supplierFFF";

export function useFFFOvernightShippingCutOffTime({
  isFFFSupplier,
}: {
  isFFFSupplier: boolean;
}) {
  const [displayMsg, setDisplayMsg] = useState(() => {
    if (!isFFFSupplier) return false;
    return shouldDisplayFFFOvernightShippingMsg();
  });

  const text = useMemo(() => {
    if (!displayMsg) return;
    const endHour = getFFFOvernightShippingEndHourStr();
    return `To ship today (at your expense) call FFF after submitting order (valid till ${endHour})`;
  }, [displayMsg]);

  useEffect(() => {
    if (!isFFFSupplier) return;

    const intervalId = setInterval(() => {
      const newShouldDisplayMsg = shouldDisplayFFFOvernightShippingMsg();
      setDisplayMsg(newShouldDisplayMsg);
    }, 60000); // check every minute

    return () => clearInterval(intervalId);
  }, [isFFFSupplier]);

  return text;
}
