import React from "react";
import "twin.macro";
import { useCreateFormLegacy } from "../../../../../components/antd/form/useCreateForm";
import { createAdminPharmacyOrganization } from "../../../../../services/legacy/pharmacyOrganizations";
import { AdminPharmacyOrganizationForm } from "./AdminPharmacyOrganizationForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";

export function CreateAdminPharmacyOrganization() {
  const onSubmit = useCreateFormLegacy({
    redirectTo: "/admin/pharmacy-organization",
    create: createAdminPharmacyOrganization,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyOrganizationForm onSubmit={onSubmit} />
    </WideContainer>
  );
}
