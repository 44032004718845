import { useCallback } from "react";
import type { Prescription } from "../types";
import { getPrescriptions, getRxes } from "../../services/prescriptions";
import { useRequestClient } from "../../services/request/requestClient";
import { useAuth0 } from "../../contexts/AuthenticationContext";
import { ItemTypeEnum } from "./itemType/itemTypeEnum";

export function useFetchPrescriptions() {
  const client = useRequestClient();
  const { getAccessTokenSilently } = useAuth0();

  const getPmsPrescriptions = useCallback(
    async (pharmacyId: number, useCache: boolean) => {
      const token = await getAccessTokenSilently();
      const { data } = await getPrescriptions(pharmacyId, token, useCache);
      const { prescriptions } = data ?? {};
      if (!prescriptions) return [];

      const prescriptionsWithType = prescriptions.map(
        (item): Prescription => ({
          ...item,
          itemType: ItemTypeEnum.PMS,
        })
      );
      return prescriptionsWithType;
    },
    [getAccessTokenSilently]
  );

  const getPrescriptionsByRxNumber = useCallback(
    async (pharmacyId: number, rxNumbers: string[]) => {
      if (!rxNumbers.length) return [];

      const response = await getRxes(client, {
        rx: rxNumbers,
        pharmacyId,
      });
      const { prescriptions } = response?.data?.data ?? {};
      if (!prescriptions || !Array.isArray(prescriptions)) return [];
      return prescriptions;
    },
    [client]
  );

  const getAllPrescriptions = useCallback(
    async ({
      pharmacyId,
      rxNumbers,
      useCache = false,
    }: {
      pharmacyId: number;
      rxNumbers: string[];
      useCache?: boolean;
    }) => {
      const [pmsPrescriptions, prescriptionByRxNumber] = await Promise.all([
        getPmsPrescriptions(pharmacyId, useCache),
        getPrescriptionsByRxNumber(pharmacyId, rxNumbers),
      ]);

      const pmsRxNumberSet = new Set(pmsPrescriptions.map((i) => i.rxNumber));
      const noPmsPrescriptions = prescriptionByRxNumber
        .filter((item) => !pmsRxNumberSet.has(item.rxNumber))
        .map((item) => ({ ...item, itemType: ItemTypeEnum.NoPMS }));

      const allPrescriptions = [...pmsPrescriptions, ...noPmsPrescriptions];
      return allPrescriptions;
    },
    [getPmsPrescriptions, getPrescriptionsByRxNumber]
  );

  return { getAllPrescriptions, getPrescriptionsByRxNumber };
}
