import React, { useCallback, useState } from "react";
import { css } from "twin.macro";
import { SupplierItemChangeOperation } from "../../../../utilities/shoppingListTypes";
import { GREY_3 } from "../../../../components/rxLibrary/colors";
import { Text } from "../../../../components/rxLibrary/typography";
import { DrugPriceCol } from "../../../../components/shopping/table/columns/DrugPriceCol";
import { DrugDetailsCol } from "../../../../components/shopping/table/columns/DrugDetailsCol";
import { ButtonCol } from "../tables/table/columns/ButtonCol";
import { TableModalTable } from "../tables/table/tableModal/TableModalTable";
import { QuantitySelectorCol } from "../tables/table/columns/QuantitySelectorCol";
import { TableModalTableRow } from "../tables/table/tableModal/TableModalTableRow";
import { SupplierOrderItemRecommendation_All } from "../../../../services/utils";

export function SuppliersModalSuggestedItem({
  suggested,
  supplierName = "-",
  callBack,
}: {
  suggested: SupplierOrderItemRecommendation_All;
  supplierName?: string;
  callBack: (operations?: SupplierItemChangeOperation[]) => void;
}) {
  const [numPackages, setNumPackages] = useState(suggested.numPackages);
  const { drugInfo, catalogInfo } = suggested.referenceData;

  const updateQuantity = useCallback(() => {
    callBack([
      { quantity: numPackages, type: "changeQty", oldItem: suggested },
    ]);
  }, [numPackages, suggested, callBack]);

  return (
    <TableModalTable title="Suggested Purchase" tw="border-white mb-0">
      <TableModalTableRow
        css={css`
          background-color: ${GREY_3};
        `}
      >
        <div tw="flex-1 min-w-[180px] border-r px-[12px] border-white">
          <Text size="large">{supplierName}</Text>
        </div>

        <DrugDetailsCol
          tw="border-white"
          drug={drugInfo}
          catalogInfo={catalogInfo}
        />

        <DrugPriceCol tw="border-white" drug={drugInfo} catalogInfo={catalogInfo} />

        <QuantitySelectorCol
          tw="border-white"
          drug={drugInfo}
          numPackages={numPackages}
          catalogInfo={catalogInfo}
          updateNumPackages={setNumPackages}
        />

        <ButtonCol
          text="Keep"
          quantity={numPackages}
          catalogInfo={catalogInfo}
          onClick={updateQuantity}
        />
      </TableModalTableRow>
    </TableModalTable>
  );
}
