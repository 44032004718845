import React from "react";
import "twin.macro";
import DispensedItem from "../../../../../components/DispensedItem";
import { formatDrugDispenseQuantity } from "../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { SplitNdcWarning } from "../table/warnings/SplitNdcWarning";
import {
  ShoppingItem,
  ShoppingItemSupplierOrderItem,
} from "../../useShoppingList";
import { useBuyingPharmacy } from "../../../../../contexts/BuyingPharmacyContext";
import { OriginalValueWarning } from "../table/warnings/OriginalValueWarning";
import { useUnavailableRxList } from "./useUnavailableRxList";
import { PrescriberData } from "./PrescriberData";

export function RxListTable({
  rxList,
  shoppingItems,
  goToShoppingListTab,
}: {
  rxList: ShoppingItemSupplierOrderItem[];
  shoppingItems: ShoppingItem[];
  goToShoppingListTab: () => void;
}) {
  const unavailableRxList = useUnavailableRxList();
  const { getSupplierById } = useBuyingPharmacy();

  return (
    <table
      cellPadding={0}
      cellSpacing={0}
      className="rx-tbl"
      tw="bg-white w-full"
    >
      <thead>
        <tr>
          <th>
            <div>Rx #</div>
          </th>
          <th>
            <div>Patient</div>
          </th>
          <th>
            <div>Dispensed Quantity</div>
          </th>
          <th>
            <div>Date Filled / Prescriber</div>
          </th>
          <th>
            <div>Item to Buy</div>
          </th>
          <th>
            <div>Pack Size</div>
          </th>
          <th>
            <div>Manufacturer</div>
          </th>
          <th>
            <div>Shopping List</div>
          </th>
        </tr>
      </thead>

      <tbody>
        {rxList.map((item) => {
          const { manufacturerChanged, packSizeChanged } = item;
          const { drugInfo: drug, catalogInfo } = item.referenceData;
          const { ndc } = drug;
          const {
            id: prescriptionId,
            isInventory,
            prescription,
          } = item.prescriptionDetails[0] || {
            id: item.rxNumbers[0],
            isInventory: true,
            prescription: {},
          };

          const supplierId = catalogInfo.supplierId;
          const supplier = getSupplierById(supplierId);

          return (
            <tr
              key={prescriptionId}
              className={isInventory ? "inv-tr" : "rx-tr"}
            >
              {isInventory ? (
                <td
                  colSpan={4}
                  className="is-inv"
                  style={{ verticalAlign: "middle" }}
                >
                  <div tw="px-4">
                    <div className="inventory-item">Inventory Item</div>
                    <SplitNdcWarning ndc={ndc} shoppingItems={shoppingItems} />
                  </div>
                </td>
              ) : (
                <>
                  <td>
                    <div className="font-500">{prescriptionId}</div>
                  </td>
                  <td>
                    <div>{prescription.patient?.shortName ?? "-"}</div>
                  </td>
                  <td>
                    {prescription.patient?.shortName === "See PMS" ? (
                      <p className="text-center">See PMS</p>
                    ) : (
                      <p className="text-center font-500">
                        {prescription.dispensedQuantity}
                      </p>
                    )}
                  </td>
                  <td>
                    {prescription.patient?.shortName === "See PMS" ? (
                      <div className="flex flex-col">
                        <div>See PMS</div>
                        <div>(Old Rx)</div>
                      </div>
                    ) : (
                      <div>
                        <PrescriberData prescription={prescription} />
                      </div>
                    )}
                  </td>
                </>
              )}

              <td>
                <div>
                  <DispensedItem drug={drug} catalogInfo={catalogInfo} />
                  {(manufacturerChanged || packSizeChanged) && (
                    <div className="flex ">
                      <img
                        src="/assets/components/icon-change.svg"
                        alt="change"
                        className="mr-6"
                      />
                      <p className="color-green text-xs">
                        {manufacturerChanged && packSizeChanged
                          ? "Manufacturer & Pack Size Change"
                          : manufacturerChanged
                          ? "Manufacturer Change"
                          : "Pack Size Change"}
                      </p>
                    </div>
                  )}
                </div>
              </td>
              <td>
                <div>
                  <p className="font-500">{formatDrugDispenseQuantity(drug)}</p>
                  <OriginalValueWarning type="packSize" item={item} />
                </div>
              </td>
              <td>
                <div>
                  <p className="font-500">{drug.manufacturer}</p>
                  <OriginalValueWarning type="manufacturer" item={item} />
                </div>
              </td>
              <td>
                <div className="font-500 underline">
                  <a href="#" onClick={goToShoppingListTab}>
                    {supplier?.displayName} - Shopping List
                  </a>
                </div>
              </td>
            </tr>
          );
        })}

        {unavailableRxList.map((item) => {
          const { prescription, prescriptionId, isInventory } = item;
          const { drug, dispensedQuantity, patient } = prescription;

          return (
            <tr
              key={prescriptionId}
              className={isInventory ? "inv-tr" : "rx-tr"}
            >
              {isInventory ? (
                <td
                  colSpan={4}
                  className="is-inv"
                  style={{ verticalAlign: "middle" }}
                >
                  <div className="inventory-item ml-12">Inventory Item</div>
                </td>
              ) : (
                <>
                  <td>
                    <div className="font-500">{prescriptionId}</div>
                  </td>
                  <td>
                    <div>{patient?.shortName ?? "-"}</div>
                  </td>
                  <td>
                    <p className="text-center font-500">{dispensedQuantity}</p>
                  </td>
                  <td>
                    <div>
                      <PrescriberData prescription={prescription} />
                    </div>
                  </td>
                </>
              )}

              <td>
                <div>
                  <DispensedItem drug={drug} />
                </div>
              </td>
              <td>
                <div className="font-500">
                  {formatDrugDispenseQuantity(drug)}
                </div>
              </td>
              <td>
                <div className="font-500">{drug.manufacturer}</div>
              </td>
              <td>
                <div className="font-500 underline">
                  <a href="#" onClick={goToShoppingListTab}>
                    Unavailable Shopping List
                  </a>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
