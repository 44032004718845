import React from "react";
import "twin.macro";
import { Text } from "../../../../../components/rxLibrary/typography";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { IconBell } from "../../../../../components/rxLibrary/icons";

export function CatalogCartOrderConfirmationPopover({
  msg,
  onClose,
  onContinue = async () => {},
}: {
  msg?: string;
  onClose: () => void;
  onContinue?: () => Promise<void>;
}) {
  return (
    <div tw="p-8">
      <div tw="flex justify-center mb-2">
        <IconBell color="red-1" />
      </div>

      <div tw="w-[250px]">
        <Text block center weight="bold" tw="mb-6">
          {msg ?? "Error submitting order"}
        </Text>
      </div>

      <div tw="flex space-x-4 items-center justify-center">
        <Button variant="text-1" onClick={onClose}>
          Cancel
        </Button>

        <Button
          onClick={async () => {
            onClose();
            await onContinue();
          }}
        >
          {"It's OK, Submit Order"}
        </Button>
      </div>
    </div>
  );
}
