import type { CSSProperties, ReactNode } from "react";
import "twin.macro";

export function ErrorMessage({
  children,
  className,
  style,
}: {
  children: ReactNode;
  style?: CSSProperties;
  className?: string;
}) {
  return (
    <div
      tw="bg-white flex h-full w-full justify-center items-center"
      style={style}
      className={className}
    >
      <div tw="w-[400px]">{children}</div>
    </div>
  );
}
