import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminSupplierMappingAndEdi } from "./AdminSupplierMappingAndEdi";
import { CreateAdminSupplierMappingAndEdi } from "./CreateAdminSupplierMappingAndEdi";

const PATH = "/admin/supplier-mapping-and-edi";
const PATH_LIST = `${PATH}/:supplierId`;
const PATH_CREATE = `${PATH}/:supplierId/new`;

export function SupplierMappingAndEdiRoutes() {
  return (
    <Switch>
      <Route
        path={PATH_CREATE}
        component={CreateAdminSupplierMappingAndEdi}
        exact
      />
      <Route
        path={[PATH, PATH_LIST]}
        component={AdminSupplierMappingAndEdi}
        exact
      />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
