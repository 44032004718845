import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {modalSmallStyle} from "../scss/material";
import "../scss/base.scss";
import "../scss/enhNav.scss";
import DotLoader from "../components/loaders/DotLoader/DotLoader";


export default function EnhancedCartWaitingForOperationsModal({
  activateModal,
  onClose,
} : {
  activateModal: boolean;
  onClose: () => void;
}): JSX.Element | null {

  return <Modal
    open={activateModal}
    id="cartOpsSummaryModal"
    onClose={() => {onClose()}}
    >
    <Box sx={modalSmallStyle}>
      <div className="flex justify-center pb-8">
        <h1 className="summaryMsg text-24">Please Wait</h1>
      </div>
      <div className="flex justify-center pb-8">
        <h2 className="text-lg font-bold">Updating your cart</h2>
      </div>
      <div className="flex justify-center flex-col pb-32">
        <DotLoader />
      </div>
    </Box>
  </Modal>
}
