import React, { useMemo } from "react";
import type {
  ItemInCart,
  ItemInModal,
} from "../../../../../../utilities/types";
import { formatNdc } from "../../../../../../utilities/ndc/formatNdc";
import { useItemInventory } from "../../../../../../utilities/prescriptions/useItemInventory";
import { Text } from "../../../../../../components/rxLibrary/typography";

export function ShoppingRxItemPanelInventory({
  item,
  isInventory,
}: {
  item: ItemInCart | ItemInModal;
  isInventory?: boolean;
}) {
  const { drug, noManufacturerPreference } = item;
  const { ndc } = drug;

  const omitInventory = !isInventory || !!noManufacturerPreference;
  const inventory = useItemInventory(item, { omitInventory });

  const ndcFormatted = useMemo(() => {
    if (isInventory && noManufacturerPreference) return null;
    return `NDC: ${formatNdc(ndc)}`;
  }, [ndc, isInventory, noManufacturerPreference]);

  return (
    <>
      {ndcFormatted}

      {!!inventory && (
        <>
          {!!ndcFormatted && " | "}

          <Text>
            <Text color="grey-2">BOH: </Text>
            {inventory.boh}
          </Text>

          {inventory.eoh !== undefined && (
            <Text>
              <Text color="grey-2"> EOH: </Text> {inventory.eoh}
            </Text>
          )}
        </>
      )}
    </>
  );
}
