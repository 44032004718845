import { downloadCsv } from "./downloadCsv";
import { JsonToCsvParams, jsonToCsv } from "./jsonToCsv";

export function downloadJsonLikeCsv({
  filename,
  ...jsonToCsvData
}: JsonToCsvParams & { filename?: string }) {
  const csvData = jsonToCsv(jsonToCsvData);
  downloadCsv(csvData, filename);
}
