/* eslint-disable @typescript-eslint/no-non-null-assertion */
import "./wdyr";
import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { css } from "twin.macro";
import { Global } from "@emotion/react";
import { LicenseManager } from "ag-grid-enterprise";
import { ConfigProvider } from "antd";
import "@fontsource/roboto";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/500.css";
import "@fontsource-variable/archivo";
import "@fontsource-variable/archivo-narrow";
import App from "./App";
import EnhancedApp from "./enhanced/EnhancedApp";
import { AppContextProvider } from "./contexts/AppContext";
import { EnhancedClientInactivityContextProvider } from "./enhanced/EnhancedClientInactivityContext";
import { EnhancedClientConfigContextProvider } from "./enhanced/EnhancedClientConfigContext";
import { EnhancedClientContextProvider } from "./enhanced/EnhancedClientContext";
import { EnhancedClientCommandContextProvider } from "./enhanced/EnhancedClientCommandContext";
import { EnhancedClientNavBarCommandContextProvider } from "./enhanced/EnhancedClientNavBarCommandContext";
import { PharmaciesContextProvider } from "./contexts/PharmaciesContext";
import { BuyingPharmacyContextProvider } from "./contexts/BuyingPharmacyContext";
import { TimerContextProvider } from "./contexts/TimerContext";
import { EnhancedClientOfflinePurchaseContextProvider } from "./enhanced/EnhancedClientOfflinePurchaseContext";
import { ManufacturersContextProvider } from "./contexts/ManufacturersContext";
import { UnexpectedError } from "./components/errors/UnexpectedError";
import { ENVIRONMENT, X_CLIENT_SESSION_ID } from "./utilities/config";
import { BLUE_1, WHITE_1 } from "./components/rxLibrary/colors";
import { AuthenticationContextProvider } from "./contexts/AuthenticationContext";
import { UserInteractionContextProvider } from "./contexts/UserInteractionContext";
import { EnhancedClientEcommerceContextProvider } from "./enhanced/EnhancedClientEcommerceContext";
import GlobalStyles from "./GlobalStyles";

const { REACT_APP_AG_GRID_LICENSE_KEY } = process.env;

const SENTRY_BLACKLISTED_PATHS = [
  "/supplierBrowserBar",
  "/supplierSafetyScreen",
  "/download"
];

if (!SENTRY_BLACKLISTED_PATHS.includes(window.location.pathname)) {
  Sentry.init({
    dsn: "https://c2b27f063cc94d0d890296cfdf09a15a@o4504533440790528.ingest.sentry.io/4504533441576960",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [process.env.REACT_APP_BACKEND_URL as string],
        networkRequestHeaders: ["X-Client-Session-Id", "X-Client-Request-Id"],
      }),
    ],
    tracesSampleRate: 0.1,
    environment: ENVIRONMENT,
    release: ENVIRONMENT,
    normalizeDepth: 5,
    // We will manually initialize Sentry replay to be sure it is in session mode
    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/understanding-sessions/#manually-starting-replay
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  });
  Sentry.setTag("headers.xClientSessionId", X_CLIENT_SESSION_ID);
}
console.log("Environment", ENVIRONMENT);

LicenseManager.setLicenseKey(REACT_APP_AG_GRID_LICENSE_KEY!);

// TEMPORARY FIX FOR ENHANCED CLIENT CONNECTION ISSUES 09/26/2024 -JR
// IF THIS LISTENER IS STILL HERE AFTER 10/26/2024, TELL ME ABOUT IT
window.addEventListener("message", (event: MessageEvent) => {
  if (event.source !== window) {
    return;
  }
  if (event.data.channel === "daylightrxenhanced") {
    if (event.data.msgType === "connected" && !event.data.resend) {
      console.log("Enhanced Client Connected", event.data.payload);
      window.setTimeout(() => {
        window.postMessage({
          resend: true,
          channel: "daylightrxenhanced",
          sender: "app",
          msgType: "connected",
          payload: event.data.payload,
        });
      }, 1000);
      window.setTimeout(() => {
        window.postMessage({
          resend: true,
          channel: "daylightrxenhanced",
          sender: "app",
          msgType: "connected",
          payload: event.data.payload,
        });
      }, 5000);
      window.setTimeout(() => {
        window.postMessage({
          resend: true,
          channel: "daylightrxenhanced",
          sender: "app",
          msgType: "connected",
          payload: event.data.payload,
        });
      }, 15000);
    }
  }
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <Global
      styles={[
        css`
          body {
            min-height: 100dvh;
            font-size: 14px;
            background-color: ${WHITE_1};
          }
          #root {
            height: 100%;
          }
          a:hover {
            color: #324dc7;
          }
          @media print {
            @page {
              size: letter portrait !important;
              margin: 1cm !important;
            }
            ::-webkit-scrollbar {
              display: none !important;
            }
            body,
            html,
            #wrapper {
              width: 100% !important;
            }
            footer,
            #chatlio-widget {
              display: none !important;
            }
            tr {
              page-break-inside: avoid !important;
            }
          }

          /**
           * START: Remove arrow from input type="number"
           * ref: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
           **/
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
          /** END: Remove arrow from input type="number" **/
        `,
      ]}
    />
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: BLUE_1,
          fontFamily: "'Archivo Narrow Variable', sans-serif",
        },
      }}
    >
      <Sentry.ErrorBoundary
        onError={(error: Error, componentStack: string, eventId: string) => {
          console.error(
            "Sentry error encountered",
            error,
            componentStack,
            eventId
          );
        }}
        fallback={UnexpectedError}
      >
        <UserInteractionContextProvider>
          <EnhancedClientConfigContextProvider>
            <AuthenticationContextProvider>
              <AppContextProvider>
                <EnhancedClientInactivityContextProvider>
                  <EnhancedClientOfflinePurchaseContextProvider>
                    <EnhancedClientEcommerceContextProvider>
                      <PharmaciesContextProvider>
                        <ManufacturersContextProvider>
                          <BuyingPharmacyContextProvider>
                            <EnhancedClientCommandContextProvider>
                              <EnhancedClientNavBarCommandContextProvider>
                                <EnhancedClientContextProvider>
                                  <TimerContextProvider>
                                    <App />
                                  </TimerContextProvider>
                                  <EnhancedApp />
                                </EnhancedClientContextProvider>
                              </EnhancedClientNavBarCommandContextProvider>
                            </EnhancedClientCommandContextProvider>
                          </BuyingPharmacyContextProvider>
                        </ManufacturersContextProvider>
                      </PharmaciesContextProvider>
                    </EnhancedClientEcommerceContextProvider>
                  </EnhancedClientOfflinePurchaseContextProvider>
                </EnhancedClientInactivityContextProvider>
              </AppContextProvider>
            </AuthenticationContextProvider>
          </EnhancedClientConfigContextProvider>
        </UserInteractionContextProvider>
      </Sentry.ErrorBoundary>
    </ConfigProvider>
  </React.StrictMode>
);
