import React, { useCallback, useMemo } from "react";
import "twin.macro";
import {
  PurchaseQuantityMethodEnum,
  PrescriptionQuantityItem,
} from "../../../../../utilities/types";
import { uomDescription } from "../../../../../utilities/helpers";

export function PurchaseQuantityManualOption({
  prescription,
  updatePurchaseQuantity,
}: {
  prescription: PrescriptionQuantityItem;
  updatePurchaseQuantity: (
    rxNumbers: string[],
    purchaseQuantityMethod: PurchaseQuantityMethodEnum,
    num?: string,
  ) => void;
}) {
  const { drug, rxNumbers, quantityToBuy, useQuantityInput } = prescription;

  const uom = useMemo(() => {
    return uomDescription(0, drug, false, true);
  }, [drug]);

  const handleManualPurchaseQuantityChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      const newPurchaseQuantity = value === "" ? "0" : value;
      updatePurchaseQuantity(
        rxNumbers,
        PurchaseQuantityMethodEnum.Manual,
        newPurchaseQuantity
      );
    },
    [rxNumbers, updatePurchaseQuantity]
  );

  if (useQuantityInput) {
    return (
      <div className="flex items-center">
        <p className="mr-6 text-left nowrap">Specify # of {uom}</p>
        <input
          value={quantityToBuy}
          onChange={handleManualPurchaseQuantityChange}
          className="input--primary text-center"
        />
      </div>
    );
  }

  return (
    <div>
      <p>Specify # of {uom}</p>
    </div>
  );
}
