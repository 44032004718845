import React, { useCallback, useState } from "react";
import "twin.macro";
import isEqual from "lodash/isEqual";
import { useShoppingUpdater } from "../../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { SupplierCol } from "../../table/columns/SupplierCol/SupplierCol";
import { DrugPriceCol } from "../../../../../../components/shopping/table/columns/DrugPriceCol";
import { DrugDetailsCol } from "../../../../../../components/shopping/table/columns/DrugDetailsCol";
import { normalizePackQuantity } from "../../../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { QuantitySelectorCol } from "../../table/columns/QuantitySelectorCol";
import { ButtonCol } from "../../table/columns/ButtonCol";
import { TableModalTableRow } from "../../table/tableModal/TableModalTableRow";
import { BuyLaterItem } from "../useBuyLaterTableItems";
import { AlternativeWithSupplierData } from "./useBuyLaterTableModal";
import {
  SupplierOrderItemRecommendation_All
} from "../../../../../../services/utils";

export function BuyLaterTableModalRow({
  item,
  alternativeWithSupplierData,
  close,
}: {
  item: BuyLaterItem;
  alternativeWithSupplierData: AlternativeWithSupplierData;
  close: () => void;
}) {
  const { moveBuyLaterItemToShoppingList } = useShoppingUpdater();

  const { rxNumbers, alternatives } = item;
  const { supplier, alternative } = alternativeWithSupplierData;
  const { numPackages: originalNumPackages } = alternative;
  const { drugInfo: drug, catalogInfo} = alternative.referenceData;
  const supplierId = supplier.id;

  const [numPackages, setNumPackages] = useState(() => {
    return normalizePackQuantity(originalNumPackages);
  });

  const addDrugToShoppingList = useCallback(() => {
    const newItem: SupplierOrderItemRecommendation_All = {
      drug: { id: drug.id},
      catalogInfo: {id: catalogInfo.id},
      referenceData: {
        drugInfo: drug,
        catalogInfo: catalogInfo,
      },
      rxNumbers,
      numPackages,
      alternatives: alternatives.filter((a) => !isEqual(a, alternative)),
      buyingReason: "requested",
      originalNumPackages,
      numPackagesIncreased: false,
      numPackagesIncreasedTo: numPackages,
    };

    moveBuyLaterItemToShoppingList({ newItem, supplierId });

    close();
  }, [
    drug,
    rxNumbers,
    supplierId,
    numPackages,
    catalogInfo,
    alternatives,
    originalNumPackages,
    close,
    moveBuyLaterItemToShoppingList,
  ]);

  return (
    <TableModalTableRow>
      <SupplierCol
        drug={drug}
        supplier={supplier}
        catalogInfo={catalogInfo}
        numPackages={numPackages}
      />

      <DrugDetailsCol drug={drug} catalogInfo={catalogInfo} />

      <DrugPriceCol drug={drug} catalogInfo={catalogInfo} />

      <QuantitySelectorCol
        drug={drug}
        numPackages={numPackages}
        catalogInfo={catalogInfo}
        updateNumPackages={setNumPackages}
      />

      <ButtonCol
        text="Buy Today"
        quantity={numPackages}
        catalogInfo={catalogInfo}
        onClick={addDrugToShoppingList}
      />
    </TableModalTableRow>
  );
}
