import { TwStyle, css, styled } from "twin.macro";
import { SerializedStyles } from "@emotion/react";
import { Box } from "./box";

type PrintStyle = SerializedStyles | TwStyle;

const HIDE_ON_PRINT_CSS = css`
  @media print {
    display: none;
  }
`;

// Print style HOC (high order component)
export function printStyleHOC(
  component: any,
  wrapperPrintStyle?: PrintStyle
) {
  return styled(component)(({ printStyle }: { printStyle?: PrintStyle }) => {
    const hideOnPrint = !printStyle && !wrapperPrintStyle;

    return [
      hideOnPrint && HIDE_ON_PRINT_CSS,
      !!wrapperPrintStyle &&
        css`
          @media print {
            ${wrapperPrintStyle}
          }
        `,
      !!printStyle &&
        css`
          @media print {
            ${printStyle}
          }
        `,
    ];
  });
}

// Hides the component on print except if printStyle or wrapperPrintStyle is present
export const PrintWrapper = printStyleHOC(Box);
