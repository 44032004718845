import React, { ReactNode, useCallback, useState } from "react";
import { styled } from "twin.macro";
import { useShoppingUpdater } from "../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { useGetDrugFamilyPrescriptionsInShoppingList } from "../../../../../utilities/prescriptions/drugFamilyPrescriptions/useGetDrugFamilyPrescriptionsInShoppingList";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../components/rxLibrary/popover";
import { getPrescriptionId } from "../../../../../utilities/prescriptions/getPrescriptionId";
import { BLUE_1 } from "../../../../../components/rxLibrary/colors";
import { ItemInCart } from "../../../../../utilities/types";
import { DuplicatedDrugFamily } from "./DuplicatedDrugFamily";

const PopoverOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.74;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const PopoverOverlayItem = styled.div`
  z-index: 101;
  position: relative;
  box-shadow: 0 0 1px 2px ${BLUE_1};
`;

export function useDuplicatedDrugFamilyPopover({
  excludePmsPrescriptions,
}: {
  excludePmsPrescriptions?: boolean;
} = {}) {
  const [selectedPrescription, setSelectedPrescription] =
    useState<ItemInCart>();
  const { updatePrescriptionStatus } = useShoppingUpdater();
  const getDrugFamilyPrescriptionsInShoppingList =
    useGetDrugFamilyPrescriptionsInShoppingList();

  const { isPopoverOpen, openPopover, closePopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  const openDuplicatedDrugFamilyPopover = useCallback(
    (prescription: ItemInCart) => {
      setSelectedPrescription(prescription);
      openPopover();
    },
    [openPopover]
  );

  const updatePrescriptionStatusWithDrugFamilyValidation = useCallback(
    (prescription: ItemInCart, status: string) => {
      if (status !== "list") {
        updatePrescriptionStatus(prescription, status);
        return;
      }

      const prescriptions = getDrugFamilyPrescriptionsInShoppingList({
        drug: prescription.drug,
        prescriptionToExclude: prescription,
        excludePmsPrescriptions,
      });
      const shouldUpdateStatus = prescriptions.length === 0;

      if (shouldUpdateStatus) {
        updatePrescriptionStatus(prescription, "list");
      } else {
        openDuplicatedDrugFamilyPopover(prescription);
      }
    },
    [
      excludePmsPrescriptions,
      updatePrescriptionStatus,
      openDuplicatedDrugFamilyPopover,
      getDrugFamilyPrescriptionsInShoppingList,
    ]
  );

  const renderDuplicatedDrugFamilyPopover = useCallback(
    ({
      content,
      prescriptionId,
    }: {
      content: ReactNode;
      prescriptionId: string;
    }): JSX.Element => {
      if (!isPopoverOpen || !selectedPrescription) return <>{content}</>;

      const popoverPrescriptionId = getPrescriptionId(selectedPrescription);
      if (prescriptionId !== popoverPrescriptionId) return <>{content}</>;

      return (
        <>
          <PopoverOverlay />
          <PopoverOverlayItem>
            <Popover
              open
              showArrow
              onOpenChange={handleOpenPopoverChange}
              content={
                <DuplicatedDrugFamily
                  prescription={selectedPrescription}
                  excludePmsPrescriptions={excludePmsPrescriptions}
                  closePopover={closePopover}
                />
              }
            >
              {content}
            </Popover>
          </PopoverOverlayItem>
        </>
      );
    },
    [selectedPrescription, isPopoverOpen, closePopover, handleOpenPopoverChange]
  );

  return {
    renderDuplicatedDrugFamilyPopover,
    updatePrescriptionStatusWithDrugFamilyValidation,
  };
}
