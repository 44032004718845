import React from "react";
import "twin.macro";
import { useCreateFormLegacy } from "../../../../../components/antd/form/useCreateForm";
import { AdminPharmacyForm } from "./AdminPharmacyForm/AdminPharmacyForm";
import { createAdminPharmacy } from "../../../../../services/pharmacies";
import { WideContainer } from "../../../../../components/containers/WideContainer";

const DEFAULT_INITIAL = {
  isSynced: false,
  usePmsSchema: true,
  syncLibertyInventory: false,
};

export function CreateAdminPharmacy() {
  const onSubmit = useCreateFormLegacy({
    redirectTo: "/admin/pharmacy",
    create: createAdminPharmacy,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyForm initialValues={DEFAULT_INITIAL} onSubmit={onSubmit} />
    </WideContainer>
  );
}
