import React from "react";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { useReport } from "../../utils/useReport/useReport";
import type { AvgPaymentsTableType } from "../summary.types";
import { COLUMN_DEFS } from "./AvgPaymentsTable.constants";
import { AgGridReact } from "ag-grid-react";
import { ReportWrapper } from "../../utils/ReportWrapper";
import { ROW_CLASS_RULES } from "../../utils/reportsConstants";

export function AvgPaymentsTable({ data }: { data?: AvgPaymentsTableType[] }) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
  });

  return (
    <>
      <div tw="flex flex-col h-full pl-4 pt-4 gap-0.5">
        <Text size="x-large" block>
          Avg Payments: Patient, Manufacturer & Third-Party
        </Text>
        <Text size="small" block weight="bold" color="grey-1">
          By Dispensed Drug, Dispensed Quantity, and Outcome
        </Text>
        <ReportWrapper>
          <AgGridReact
            {...report}
            pagination
            rowData={data ?? []}
            rowSelection="multiple"
            rowClassRules={ROW_CLASS_RULES}
          />
        </ReportWrapper>
      </div>
    </>
  );
}
