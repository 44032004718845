import React, { useEffect } from "react";
import "twin.macro";
import { Button } from "antd";
import MessageOutlined from "@ant-design/icons/MessageOutlined";
import { openChat } from "../../utilities/chat/openChat";

export function ManufacturerChatButton() {
  useEffect(() => {
    document.addEventListener("chatlio.collapsed", window._chatlio.hide);

    return () => {
      document.removeEventListener("chatlio.collapsed", window._chatlio.hide);
    };
  }, []);

  return (
    <Button
      size="large"
      shape="round"
      tw="flex items-center"
      icon={<MessageOutlined rev={undefined} />}
      onClick={openChat}
    >
      Chat
    </Button>
  );
}
