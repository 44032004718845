import { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { useStorageState } from "../../../contexts/shoppingContexts/StorageContext";
import { WideContainer } from "../../../components/containers/WideContainer";
import { Title } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { Box } from "../../../components/rxLibrary/box";

export function ShoppingBar({
  loading,
  isInvalidQty,
  setSupplierModalActive,
}: {
  loading: boolean;
  isInvalidQty: boolean;
  setSupplierModalActive: (v: boolean) => void;
}) {
  const history = useHistory();
  const { waitButtonMode } = useStorageState();
  const { initialShoppingPath } = useShoppingCartServerState();
  const { pushBlob } = useShoppingCartServerUpdater();
  const [isCancelling, setIsCancelling] = useState(false);

  const openSupplierModal = useCallback(() => {
    setSupplierModalActive(true);
  }, [setSupplierModalActive]);

  const handleCancel = useCallback(async () => {
    setIsCancelling(true);
    await pushBlob({
      force: true,
      cb: () => history.push(initialShoppingPath),
    });
  }, [history, initialShoppingPath, pushBlob]);

  return (
    <Box tw="shadow-[0_4px_15px_0_rgba(0,0,0,0.31)]" bgColor="white-1">
      <WideContainer tw="flex justify-between items-center relative h-[96px]">
        <Title>Shopping List - Select Quantities</Title>

        <div tw="flex items-center space-x-2">
          <Button
            variant="text-2"
            onClick={handleCancel}
            disabled={isCancelling}
          >
            {isCancelling ? "Going back..." : "Back"}
          </Button>

          {!loading && (
            <Button
              onClick={openSupplierModal}
              disabled={isInvalidQty || waitButtonMode || isCancelling}
            >
              {isInvalidQty ? "Increase Quantities" : "See Suppliers & Pricing"}
            </Button>
          )}
        </div>
      </WideContainer>
    </Box>
  );
}
