import dayjs, { Dayjs } from "dayjs";

export function formatPurchaseByToString(
  dateRaw?: Date | Dayjs
): string | undefined {
  if (!dateRaw) return;

  const date = dayjs(dateRaw);
  if (!date.isValid()) return;

  const isFutureDate = date.isAfter(new Date(), "day");
  if (!isFutureDate) return;

  const dateStr = date.toISOString();
  return dateStr;
}
