import tw, { styled } from "twin.macro";
import { Box } from "../../../../../components/rxLibrary/box";
import { ADD_RX_FADE_OUT_ANIMATION_CSS } from "../../ShoppingRx.utils";
import { useShoppingRx } from "../../useShoppingRx/useShoppingRx";

const Item = styled(Box)`
  content-visibility: auto;
  padding: 5px;
  min-height: 128px;
  border-radius: 8px;
`;

export function ShoppingRxItem({
  id,
  children,
  isRemoving,
  isInventory,
}: {
  id: string;
  children: React.ReactNode;
  isRemoving: boolean;
  isInventory?: boolean;
}) {
  const { selectedItemId } = useShoppingRx();
  const isSelected = id === selectedItemId;

  return (
    <Item
      id={id}
      tw="shadow-[0_0_1px_1px_rgba(0,0,0,0.21)]"
      css={[
        !!isSelected && tw`outline`,
        isRemoving && ADD_RX_FADE_OUT_ANIMATION_CSS,
      ]}
      bgColor={isInventory ? "yellow-3" : "blue-5"}
      outlineColor="red-1"
    >
      {children}
    </Item>
  );
}
