import React, { ButtonHTMLAttributes } from "react";
import { styled } from "twin.macro";
import { BLUE_1 } from "../colors";
import { IconArrowLeft } from "../icons";

const Button = styled.button`
  padding: 5px;
  margin-left: -5px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: ${BLUE_1};

  &:hover {
    color: ${BLUE_1}CC;
  }
`;

export function BackButton({
  children,
  type = "button",
  ...props
}: {
  children: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children">) {
  return (
    <Button type={type} {...props}>
      <IconArrowLeft />
      <span>{children}</span>
    </Button>
  );
}
