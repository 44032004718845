import { GetMainMenuItemsParams, MenuItemDef } from "ag-grid-community";

export function getMainMenuItems(
  params: GetMainMenuItemsParams
): (string | MenuItemDef)[] {
  const { defaultItems, columnApi } = params;
  const newItems = [
    {
      name: "Hide Column",
      action: () => {
        columnApi.setColumnVisible(params.column, false);
      },
    },
    'separator',
    ...defaultItems,
  ];

  return newItems;
}
