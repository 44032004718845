import React, {useCallback, useContext} from "react";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import {message} from "antd";
import {AppError} from "../types";
import "../../scss/base.scss";

function describeTimeframe(numberOfMillis: number): string {
  if (numberOfMillis < 60 * 1000) {
    return "in the last minute";
  } else if (numberOfMillis < 10 * 60 * 1000) {
    return "in the last ten minutes";
  } else if (numberOfMillis < 30 * 60 * 1000) {
    return "in the last 30 minutes";
  } else if (numberOfMillis < 60 * 60 * 1000) {
    return "in the last hour";
  } else if (numberOfMillis < 2 * 60 * 60 * 1000) {
    return "in the last two hours";
  } else if (numberOfMillis < 4 * 60 * 60 * 1000) {
    return "in the last four hours";
  } else if (numberOfMillis < 8 * 60 * 60 * 1000) {
    return "in the last eight hours";
  } else if (numberOfMillis < 24 * 60 * 60 * 1000) {
    return "in the last 24 hours";
  } else {
    return "since the application was started";
  }
}
export function useEnhancedViewUnavailableMessage(): {
  showViewUnavailableMessage: (error: AppError & {type: "view-unavailable"}) => void;
  contextHolder: JSX.Element;
} {
  const { getSupplierByName } = useContext(BuyingPharmacyContext);
  const [messageApi, contextHolder] = message.useMessage({
    prefixCls: "enhanced-message",
  });

  const showViewUnavailableMessage = useCallback((error: AppError) => {
    console.log("showViewUnavailableMessage", error);
    const displayName = error.name === 'daylightrx_ecommerce' ? "DaylightRx eCommerce" : (getSupplierByName(error.name)?.displayName || error.name);
    let content = <div className="text-lg">We&apos;re sorry, but {displayName} is currently unavailable. Please use another supplier, or restart DaylightRx and try again later.</div>;
    if (error.millisToRestart) {
      if (error.crashCount && error.crashCount > 1 && error.timeSinceFirstCrashMillis) {
        content = <div className="text-lg flex-col" style={{width: "768px"}}>
          <div>{displayName} has been problematic {error.crashCount} times {describeTimeframe(error.timeSinceFirstCrashMillis)}.</div>
          <div>If this persists consider restarting your computer.</div>
          <div>We will try to reconnect to {displayName} in {Math.round((error.millisToRestart + 1000) / 1000)} seconds.</div>
        </div>;
      } else {
        content = <div className="text-lg flex-col" style={{width: "768px"}}>
          <div>We&apos;re sorry, but {displayName} is currently unavailable. Please wait a moment.</div>
          <div>We will try to reconnect to {displayName} in {Math.round((error.millisToRestart + 1000) / 1000)} seconds.</div>
        </div>;
      }
    }
    messageApi.open({
      type: "error",
      content,
      style: {
        marginTop: "20%",
      },
      duration: 4,
      icon: <div></div>,
    });

  }, [getSupplierByName, messageApi])

  return {
    showViewUnavailableMessage,
    contextHolder,
  }
}
