import React from "react";
import get from "lodash/get";
import type { ColumnFilterItem } from "antd/es/table/interface";

export const YES_NO_BOOLEAN_FILTERS = [
  { text: "Yes", value: true },
  { text: "No", value: false },
];

export const YES_NO_NONE_BOOLEAN_FILTERS = [
  ...YES_NO_BOOLEAN_FILTERS,
  { text: "None", value: null },
  // hack to avoid typescript error
] as unknown as ColumnFilterItem[];

export function getBooleanFilter(attribute: string) {
  return (value: any, record: any) => get(record, attribute) === value;
}
