import React, { ReactNode, CSSProperties } from "react";
import "twin.macro";

export function TableModalTableRow({
  style,
  children,
  className,
}: {
  style?: CSSProperties;
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      tw="flex flex-1 first:border-t border-b py-[12px]"
      style={style}
      className={className}
    >
      {children}
    </div>
  );
}
