import React from "react";
import "twin.macro";
import { useCreateForm } from "../../../../../components/antd/form/useCreateForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { createAdminAccount } from "../../../../../services/accounts";
import { AdminAccountForm } from "./AdminAccountForm/AdminAccountForm";

const DEFAULT_INITIAL = {
  isAdmin: false,
  businessRoles: null,
  isTrialUser: false,
  pharmacies: [],
  defaultBuyingPharmacyId: null,
  supportContextMenu: false
};

export function CreateAdminAccount() {
  const onSubmit = useCreateForm({
    redirectTo: "/admin/account",
    create: createAdminAccount,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminAccountForm initialValues={DEFAULT_INITIAL} onSubmit={onSubmit} />
    </WideContainer>
  );
}
