import { numberWithCommas } from "./helpers";

function formatUnit(number: number, unit: string): string {
  if (unit === "Gram") {
    return "g";
  } else if (unit === "Milliliter") {
    return "ml";
  } else if (unit === "Each") {
    return number === 1 ? " Unit" : " Units";
  }

  return ` ${unit}`;
}

/** @deprecated */
export function formatNumberWithDecimals(value: number) {
  const formattedNumber = numberWithCommas(parseFloat(value.toFixed(2)));
  return formattedNumber;
}

export function formatWithUnit(number: number, unit: string) {
  const formattedNumber = formatNumberWithDecimals(number);
  const formattedUnit = formatUnit(number, unit);
  const result = `${formattedNumber}${formattedUnit}`;
  return result;
}
