import { useContext, useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import PharmaciesContext from "../../contexts/PharmaciesContext";
import { camelizeObj } from "../../utilities/objects/camelizeObj";
import { paramsSerializer } from "../../utilities/queryParams/paramsSerializer";
import { usePerformance } from "./usePerformance";

const { REACT_APP_DATA_PIPELINE_API_URL: root } = process.env;
const { REACT_APP_BACKEND_EDI_AUTH: authToken } = process.env;

export function useReportRequest() {
  const { pharmacies } = useContext(PharmaciesContext);
  const { toDate, fromDate, reportId, selectedPharmacies } = usePerformance();

  const [data, setData] = useState<any>();
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [pharmacyIds, setPharmacyIds] = useState<number[]>();

  useEffect(() => {
    if (!pharmacies.length || !selectedPharmacies) {
      setPharmacyIds(undefined);
      return;
    }

    const newPharmacyIds =
      selectedPharmacies.length === 0
        ? pharmacies.map((p) => p.id)
        : selectedPharmacies;
    setPharmacyIds((prevPharmacyIds) => {
      if (isEqual(prevPharmacyIds, newPharmacyIds)) return prevPharmacyIds;
      return newPharmacyIds;
    });
  }, [pharmacies, selectedPharmacies]);

  useEffect(() => {
    if (!pharmacyIds?.length) return;

    setError(undefined);
    setData(undefined);
    setIsLoading(true);

    const controller = new AbortController();
    init(controller);

    return () => {
      if (controller) controller.abort();
    };

    async function init(controller: AbortController) {
      const params = {
        start_date: fromDate.format("YYYY-MM-DD"),
        end_date: toDate.format("YYYY-MM-DD"),
        pharmacy_id: pharmacyIds,
      };
      const url = `${root}/report/${reportId}/pharmacy?${paramsSerializer(
        params
      )}`;
      try {
        const response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: { Authorization: `Basic ${authToken}` },
          signal: controller.signal,
        });
        const responseData = await response.json();
        setData(camelizeObj(responseData));
      } catch (error) {
        if (error instanceof DOMException && error.name === "AbortError") {
          return;
        }
        setError("Failed to load report data");
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [reportId, toDate, fromDate, pharmacyIds]);

  return { data, error, isLoading };
}
