import React, { useCallback } from "react";
import "twin.macro";
import {
  PurchaseByButton,
  CalendarFooterWarning,
} from "../../../../../../components/shopping/PurchaseByButton/PurchaseByButton";
import { useShoppingUpdater } from "../../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";

import {
  SupplierOrderItemRecommendation_All
} from "../../../../../../services/utils";

export function ShoppingListTableRowPurchaseByButton({
  item,
  supplierId,
  hasMinShipWarning,
}: {
  item: SupplierOrderItemRecommendation_All;
  supplierId: number;
  hasMinShipWarning: boolean;
}) {
  const { moveShoppingListItemToBuyLater, updatePrescriptionsPurchaseBy } =
    useShoppingUpdater();

  const handleUpdatePurchaseBy = useCallback(
    (date?: Date) => {
      if (date) {
        moveShoppingListItemToBuyLater({
          date,
          item,
          supplierId,
        });
      } else {
        updatePrescriptionsPurchaseBy(item.rxNumbers, date);
      }
    },
    [item, supplierId, moveShoppingListItemToBuyLater]
  );

  return (
    <PurchaseByButton
      changesRequireConfirmation
      onChange={handleUpdatePurchaseBy}
      calendarFooter={
        <CalendarFooterWarning
          text="Today's min. ship will not be met without this item."
          hide={!hasMinShipWarning}
        />
      }
    />
  );
}
