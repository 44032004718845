import React from "react";
import { Redirect } from "react-router-dom";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { useSyncedShoppingCart } from "../../../contexts/shoppingContexts/useSyncedShoppingCart";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { NavBar } from "../../../components/NavBar/NavBar";
import { Text } from "../../../components/rxLibrary/typography";
import { WideContainer } from "../../../components/containers/WideContainer";
import { SortBySelect } from "../../../components/rxLibrary/selects/SortBySelect";
import { Box } from "../../../components/rxLibrary/box";
import { useModal } from "../../../components/rxLibrary/modals";
import { GoToP2Button } from "../../../components/shopping/GoToP2Button";
import { InventoryModal } from "../../../components/shopping/InventoryModal/InventoryModal";
import { ShoppingRxCartPrescriptionItem } from "./shoppingRxItems/ShoppingRxCartPrescriptionItem";
import { ShoppingRxInventoryItem } from "./shoppingRxItems/ShoppingRxInventoryItem";
import { ShoppingRxPrescriptionModal } from "./ShoppingRxPrescriptionModal/ShoppingRxPrescriptionModal";
import { ShoppingRxItemsAddedBanner } from "./ShoppingRxItemsAddedBanner";
import { ShoppingRxPanel } from "./ShoppingRxPanel/ShoppingRxPanel";
import {
  useShoppingRx,
  ShoppingRxContextProvider,
  SHOPPING_RX_SORT_OPTIONS,
} from "./useShoppingRx/useShoppingRx";
import { ShoppingRxSimilarItems } from "./ShoppingRxSimilarItems";

function ShoppingRxContent() {
  const {
    inventorySyncDate,
    isInventoryLoading,
    prescriptionsSyncDate,
    isPrescriptionsLoading,
    syncInventory,
    syncPrescriptions,
  } = useSyncedShoppingCart(true);
  const {
    sort,
    sortDirection,
    inventoryItems,
    prescriptionsItems,
    highlightInventory,
    highlightPrescription,
    setSort,
    setSortDirection,
    addPrescriptionsToCart,
    handleAddInventoryItem,
  } = useShoppingRx();

  const inventoryItemsCounter = inventoryItems.length;
  const prescriptionsItemsCounter = prescriptionsItems.length;
  const itemsCounter = prescriptionsItemsCounter + inventoryItemsCounter;

  const {
    isModalOpen: isPrescriptionModalOpen,
    openModal: openPrescriptionModal,
    closeModal: closePrescriptionModal,
  } = useModal();

  const {
    isModalOpen: isInventoryModalOpen,
    openModal: openInventoryModal,
    closeModal: closeInventoryModal,
  } = useModal();

  return (
    <>
      <Box bgColor="grey-5" tw="flex flex-col h-screen overflow-y-hidden">
        <NavBar />

        <div tw="flex flex-col flex-1 overflow-y-hidden">
          <div>
            <Box bgColor="white-1" tw="relative">
              <WideContainer tw="flex items-center justify-between h-[88px]">
                <div tw="flex items-center h-full space-x-[31px]">
                  <Text color="blue-2" size={28} block>
                    Today’s Shopping List
                  </Text>

                  <ShoppingRxItemsAddedBanner />
                </div>

                <div tw="flex space-x-[37px]">
                  <SortBySelect
                    value={sort}
                    options={SHOPPING_RX_SORT_OPTIONS}
                    sortOrder={sortDirection}
                    selectMinWidth={151}
                    onChange={setSort}
                    onSortOrderChange={setSortDirection}
                  />

                  <GoToP2Button tw="h-[46px]" disabled={itemsCounter === 0}>
                    {itemsCounter} Items - Continue
                  </GoToP2Button>
                </div>
              </WideContainer>

              <Box
                bgColor="white-1"
                tw="absolute bottom-0 w-full translate-y-full h-[61px]"
              />
            </Box>

            <ShoppingRxSimilarItems />
          </div>

          <WideContainer tw="flex-1 content-stretch flex justify-between overflow-y-hidden z-1 space-x-[12px] mb-[20px]">
            <ShoppingRxPanel
              highlight={highlightPrescription}
              isLoading={isPrescriptionsLoading}
              counter={prescriptionsItemsCounter}
              syncDate={prescriptionsSyncDate}
              onSync={syncPrescriptions}
              openItemsModal={openPrescriptionModal}
            >
              {prescriptionsItems.map((item) => (
                <ShoppingRxCartPrescriptionItem
                  key={item.rxNumber}
                  item={item}
                />
              ))}
            </ShoppingRxPanel>

            <ShoppingRxPanel
              counter={inventoryItemsCounter}
              syncDate={inventorySyncDate}
              highlight={highlightInventory}
              isLoading={isInventoryLoading}
              onSync={syncInventory}
              openItemsModal={openInventoryModal}
              inventory
            >
              {inventoryItems.map((item) => (
                <ShoppingRxInventoryItem key={item.key} item={item} />
              ))}
            </ShoppingRxPanel>
          </WideContainer>
        </div>
      </Box>

      {isPrescriptionModalOpen && (
        <ShoppingRxPrescriptionModal
          isPrescriptionsLoading={isPrescriptionsLoading}
          close={closePrescriptionModal}
          addPrescriptionsToCart={addPrescriptionsToCart}
        />
      )}

      {isInventoryModalOpen && (
        <InventoryModal
          close={closeInventoryModal}
          customAddInventoryItem={handleAddInventoryItem}
        />
      )}
    </>
  );
}

export function ShoppingRx() {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  if (!pharmacy) return <FullPageLoader />;

  const { isSynced } = pharmacy;
  if (!isSynced) return <Redirect to="/shoppingList" />;

  return (
    <ShoppingRxContextProvider>
      <ShoppingRxContent />
    </ShoppingRxContextProvider>
  );
}
