import React, { ImgHTMLAttributes, SyntheticEvent } from "react";
import "twin.macro";

type ImageProps = Omit<
  ImgHTMLAttributes<HTMLImageElement>,
  "src" | "onError"
> & { src?: string };

const IMG_PLACEHOLDER = "/assets/image_placeholder.svg";

function handleImageError(event: SyntheticEvent<HTMLImageElement, Event>) {
  event.currentTarget.src = IMG_PLACEHOLDER;
}

export function Image({ src = IMG_PLACEHOLDER, ...props }: ImageProps) {
  return <img {...props} src={src} onError={handleImageError} />;
}
