import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminAccounts } from "./AdminAccounts";
import { CreateAdminAccount } from "./CreateAdminAccount";
import { EditAdminAccount } from "./EditAdminAccount";

const PATH = "/admin/account";
const PATH_UPDATE = `${PATH}/:id`;
const PATH_CREATE = `${PATH}/new`;

export function AccountsRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminAccounts} exact />
      <Route path={PATH_CREATE} component={CreateAdminAccount} exact />
      <Route path={PATH_UPDATE} component={EditAdminAccount} exact />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
