import React from "react";
import { PrescriptionGroupAdjustment } from "../../../utilities/types";

type AdjustmentMatch =
  | {
      matchType: "full";
      matchingAdjustment: PrescriptionGroupAdjustment;
    }
  | {
      matchType: "partial";
      defunctAdjustments: PrescriptionGroupAdjustment[];
    }
  | {
      matchType: "full-and-partial";
      matchingAdjustment: PrescriptionGroupAdjustment;
      defunctAdjustments: PrescriptionGroupAdjustment[];
    }
  | null;

export function findAdjustmentMatch(
  sortedRxNumbers: string[],
  prescriptionGroupAdjustments: PrescriptionGroupAdjustment[]
): AdjustmentMatch {
  let matchingAdjustment: PrescriptionGroupAdjustment | null = null;
  const defunctAdjustments: PrescriptionGroupAdjustment[] = [];

  prescriptionGroupAdjustments.forEach((adjustment) => {
    let anyMatches = false;
    let anyMismatches = adjustment.rxNumbers.length !== sortedRxNumbers.length;
    adjustment.rxNumbers.forEach((rxNumber: string) => {
      if (sortedRxNumbers.includes(rxNumber)) {
        anyMatches = true;
      } else {
        anyMismatches = true;
      }
    });

    if (anyMatches && !anyMismatches) {
      matchingAdjustment = adjustment;
    } else if (anyMatches && anyMismatches) {
      defunctAdjustments.push(adjustment);
    }
  });

  console.log("findAdjustmentMatch", matchingAdjustment, defunctAdjustments);
  if (matchingAdjustment && defunctAdjustments.length > 0) {
    return {
      matchType: "full-and-partial",
      matchingAdjustment,
      defunctAdjustments,
    };
  }

  if (matchingAdjustment) {
    return { matchType: "full", matchingAdjustment };
  }

  if (defunctAdjustments.length > 0) {
    return { matchType: "partial", defunctAdjustments };
  }

  return null;
}
