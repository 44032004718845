export function findAndRemoveFromArray<T>(
  arr: T[],
  findFunction: (item: T) => boolean
): [T[], undefined, undefined] | [T[], T, number] {
  const itemIndex = arr.findIndex(findFunction);
  if (itemIndex === -1) return [arr, undefined, undefined];

  const item = arr[itemIndex];
  const newArr = [...arr];
  newArr.splice(itemIndex, 1);
  return [newArr, item, itemIndex];
}
