import React, { useCallback, useState } from "react";
import "twin.macro";
import { Popconfirm, Tooltip, message } from "antd";
import MailOutlined from "@ant-design/icons/MailOutlined";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { useRequestClient } from "../../../../../services/request/requestClient";
import { adminAccountSendWelcomeEmail } from "../../../../../services/accounts";

export function SendWelcomeEmailButton({
  userId,
  smallWithConfirmation,
  onSuccess,
}: {
  userId: number;
  smallWithConfirmation?: boolean;
  onSuccess?: () => void;
}) {
  const client = useRequestClient();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await adminAccountSendWelcomeEmail(client, { id: userId });
      message.success("Welcome email sent");
      onSuccess?.();
    } catch (error) {
      console.error(`Error sending welcome email to user id: ${userId}`, error);
      message.error("Error sending welcome email");
    }
    setIsLoading(false);
  }, [userId, client]);

  if (smallWithConfirmation) {
    return (
      <Tooltip title="Send Welcome Email">
        <Popconfirm title="Sure to send welcome email?" onConfirm={handleClick}>
          <IconButton disabled={isLoading}>
            <MailOutlined rev={undefined} />
          </IconButton>
        </Popconfirm>
      </Tooltip>
    );
  }

  return (
    <Button
      icon={<MailOutlined rev={undefined} />}
      variant="secondary"
      disabled={isLoading}
      onClick={handleClick}
    >
      Send Welcome Email Now
    </Button>
  );
}
