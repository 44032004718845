import React, { useMemo } from "react";
import tw, { TwStyle, css, styled } from "twin.macro";
import { Supplier } from "../../../../utilities/types";
import { GREY_3, WHITE_1 } from "../../../../components/rxLibrary/colors";
import { SupplierCard } from "./SupplierCard";

const STYLES: Record<number, TwStyle | undefined> = {
  2: tw`grid-cols-2 gap-[26px]`,
  3: tw`grid-cols-3 gap-[16px]`,
  4: tw`grid-cols-2 gap-[18px]`,
  5: tw`grid-cols-6 gap-[18px]`,
  6: tw`grid-cols-3 gap-[18px]`,
  7: tw`grid-cols-8 gap-[18px]`,
  8: tw`grid-cols-4 gap-[18px]`,
};

const Wrapper = styled.div([
  tw`grid col-span-2 rounded-xl p-[26px] h-[402px]`,
  css`
    border: solid 1px ${GREY_3};
    background-color: ${WHITE_1};
    box-shadow: 0 0 10px 0 #0000001e;
  `,
]);

export function SupplierCards({ suppliers }: { suppliers: Supplier[] }) {
  const ecommerceSuppliers = useMemo(() => {
    return suppliers.filter((supplier) => supplier.isEcommerceEnabled);
  }, [suppliers]);
  const cardsCounter = ecommerceSuppliers.length;
  const style = STYLES[cardsCounter];

  return (
    <Wrapper css={style}>
      {ecommerceSuppliers.map((supplier) => (
        <SupplierCard
          key={supplier.id}
          supplier={supplier}
          cardsCounter={cardsCounter}
        />
      ))}
    </Wrapper>
  );
}
