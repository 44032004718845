import React, { useCallback, useMemo } from "react";
import "twin.macro";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { DropDownListQty } from "../../../../../../components/DropDownListQty";
import { QuantityDescriptionCol } from "../../../../../../components/shopping/table/columns/QuantityDescriptionCol";
import { CatalogInfo, DrugInfo } from "../../../../../../utilities/types";
import { normalizePackQuantity } from "../../../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { formatBundleSizeDropdownItems } from "../../../../../../utilities/prescriptions/formatDirectDealDropdownItems.deprecated";

export function QuantitySelectorCol({
  drug,
  className,
  numPackages,
  catalogInfo,
  updateNumPackages,
}: {
  drug: DrugInfo;
  numPackages: number;
  className?: string;
  catalogInfo?: CatalogInfo;
  updateNumPackages: (value: number) => void;
}) {
  const { bundleSize } = catalogInfo?.directDeal ?? {};

  const quantity = useMemo(() => {
    return normalizePackQuantity(numPackages);
  }, [numPackages]);

  const bundleSizeQuantityWarning = !!bundleSize && (quantity % bundleSize !== 0);

  const dropDownItems = useMemo(() => {
    return formatBundleSizeDropdownItems({
      bundleSize,
      multiplesOfBundleSize: true,
    });
  }, [bundleSize]);

  const handleUpdateNumPackages = useCallback(
    (value: string) => {
      const newNumPackages = normalizePackQuantity(value);
      updateNumPackages(newNumPackages);
    },
    [updateNumPackages]
  );

  return (
    <div className={className} tw="flex-1 min-w-[150px] border-r px-[12px]">
      <div tw="flex justify-between">
        <div>
          <QuantityDescriptionCol drug={drug} catalogInfo={catalogInfo} />
        </div>

        <div>
          <DropDownListQty
            label="Qty"
            value={quantity.toString()}
            dropDownItems={dropDownItems}
            callBack={handleUpdateNumPackages}
          />

          {bundleSizeQuantityWarning && <Text block color="red-1">Select quantity</Text>}
        </div>
      </div>
    </div>
  );
}
