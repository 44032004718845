import React, { MouseEvent, useCallback, useMemo, useState } from "react";
import "twin.macro";
import Select, { OnChangeValue, StylesConfig } from "react-select";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import { CheckboxChangeEvent } from "antd/es/checkbox/Checkbox";
const ICON_MANAGE = new URL('svgurl:../../../assets/icon-manage.svg', import.meta.url).toString();
import {
  DropdownOptionType,
  SelectBoxOptionType,
  FutureRefillOptionEnum,
} from "../../../utilities/types";
import { Box } from "../../../components/rxLibrary/box";
import { Button } from "../../../components/rxLibrary/buttons";
import { Text } from "../../../components/rxLibrary/typography";
import { IconCheck } from "../../../components/rxLibrary/icons";
import { Checkbox } from "../../../components/rxLibrary/checkbox";
import { ManageColumnOptionEnum } from "./ShoppingListTwo.constants";

const FUTURE_REFILL_OPTIONS: SelectBoxOptionType[] = [
  { value: FutureRefillOptionEnum.SemiMonthly, label: "15-Day Billing Cycle" },
  { value: FutureRefillOptionEnum.Next30Days, label: "Next 30 Days" },
  { value: FutureRefillOptionEnum.Next60Days, label: "Next 60 Days" },
  { value: FutureRefillOptionEnum.Next90Days, label: "Next 90 Days" },
];

const FUTURE_REFILL_SELECT_STYLE_CONFIG: StylesConfig<
  SelectBoxOptionType,
  false
> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F5F5F5",
    border: "solid 1px #c3c3c3",
    boxShadow: "none",
    "&:hover": {
      border: "solid 1px #c3c3c3",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
  }),
};

const StyledMenu = styled(
  (prop: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...prop}
    />
  ),
  {
    shouldForwardProp: (prop) => prop !== "isShrinked",
    name: "MyThemeComponent",
    slot: "Root",
  }
)(({ theme }) => ({
  "& .MuiPaper-root": {
    border: "solid 1px #979797;",
    borderRadius: 9,
    marginTop: theme.spacing(1),
    minWidth: 251,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.22)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function ShoppingListTwoManageColumnsDropdown({
  manageColumnOptions,
  updateManageColumnOptions,
}: {
  manageColumnOptions: DropdownOptionType[];
  updateManageColumnOptions: (o: DropdownOptionType[]) => Promise<void>;
}) {
  const [options, setOptions] = useState(manageColumnOptions);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const futureRefills = useMemo(() => {
    const refillsOptions = options.find((option) => {
      return option.value === ManageColumnOptionEnum.Refills;
    });
    if (refillsOptions?.checked) return refillsOptions.data;
    return undefined;
  }, [options]);

  const open = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setOptions(manageColumnOptions);
      setAnchorEl(event.currentTarget);
    },
    [manageColumnOptions]
  );

  const close = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleOptionsChange = useCallback(
    (event: CheckboxChangeEvent) => {
      const { checked, name } = event.target;
      const newOptions = options.map((option) => {
        if (option.value === name) return { ...option, checked };
        return option;
      });
      setOptions(newOptions);
    },
    [options]
  );

  const handleFutureRefillsChange = useCallback(
    async (futureRefillsData: OnChangeValue<SelectBoxOptionType, false>) => {
      const newOptions = options.map((option) => {
        if (option.value === ManageColumnOptionEnum.Refills) {
          return { ...option, data: futureRefillsData as SelectBoxOptionType };
        }
        return option;
      });
      setOptions(newOptions);
    },
    [options]
  );

  const onSubmit = useCallback(async () => {
    await updateManageColumnOptions(options);
    close();
  }, [options, close, updateManageColumnOptions]);

  return (
    <div className="shopping-list-dropdown">
      <Button
        variant="text-1"
        icon={<img src={ICON_MANAGE} alt="dropdown-label-start-icon" />}
        onClick={open}
      >
        Manage Columns
      </Button>

      {!!anchorEl && (
        <StyledMenu open anchorEl={anchorEl} onClose={close}>
          <div>
            <Box tw="p-6 space-y-6 border-b">
              <Text weight="bold" block>
                Select columns to show
              </Text>

              {options.map((option) => (
                <div key={option.value} tw="my-1">
                  <Checkbox
                    name={option.value}
                    checked={option.checked}
                    onChange={handleOptionsChange}
                  >
                    <Text>{option.label}</Text>
                  </Checkbox>
                </div>
              ))}

              {!!futureRefills && (
                <div>
                  <Text tw="mt-4 mb-2" weight="bold" block>
                    Show future refills through:
                  </Text>

                  <Select
                    className="future-refill-select"
                    value={futureRefills}
                    options={FUTURE_REFILL_OPTIONS}
                    onChange={handleFutureRefillsChange}
                    menuPortalTarget={document.body}
                    styles={FUTURE_REFILL_SELECT_STYLE_CONFIG}
                    components={{ IndicatorSeparator: () => null }}
                  />
                </div>
              )}
            </Box>

            <div tw="flex justify-center items-center space-x-4 p-2">
              <Button variant="text-2" onClick={close}>
                Cancel
              </Button>

              <Button
                variant="secondary"
                icon={<IconCheck />}
                onClick={onSubmit}
              >
                Apply
              </Button>
            </div>
          </div>
        </StyledMenu>
      )}
    </div>
  );
}
