import { ColDef, ColGroupDef } from "ag-grid-community";
import { manufacturerReportCurrencyFormatter, manufacturerReportNumberFormatter, manufacturerReportPercentFormatter } from "../../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    field: "dispensedDrugName",
    headerName: "Dispensed Drug Name",
    pinned: "left",
  },
  {
    field: "dispensedQuantity",
    headerName: "Dispensed Quantity",
    pinned: "left",
    valueFormatter: (params) => manufacturerReportNumberFormatter(params.value),
  },
  {
    field: "outcome",
    headerName: "Outcome",
  },
  {
    field: "fillCount",
    headerName: "Fill Count",
    valueFormatter: (params) => manufacturerReportNumberFormatter(params.value),
  },
  {
    field: "percentOfFills",
    headerName: "% of Fills",
    valueFormatter: (params) => manufacturerReportPercentFormatter(params.value, 1),
  },
  {
    field: "numberOfCoveredDispenses",
    headerName: "# of Covered Dispenses",
    valueFormatter: (params) => manufacturerReportNumberFormatter(params.value),
  },
  {
    field: "numberOfManufacturerCoveredDispenses",
    headerName: "# of Manuf-Covered Dispenses",
    valueFormatter: (params) => manufacturerReportNumberFormatter(params.value),
  },
  {
    field: "coverageRate",
    headerName: "Coverage Rate",
    valueFormatter: (params) => manufacturerReportPercentFormatter(params.value, 1),
  },
  {
    field: "coverageRateWContributionGreaterThan0",
    headerName: "Coverage Rate w/ Contribution > $0",
    valueFormatter: (params) => manufacturerReportPercentFormatter(params.value, 1),
  },
  {
    field: "avgPatientPay",
    headerName: "Avg. Patient Pay",
    valueFormatter: (params) => manufacturerReportCurrencyFormatter(params.value),
  },
  {
    field: "avgManufPay",
    headerName: "Avg. Manuf. Pay",
    valueFormatter: (params) => manufacturerReportCurrencyFormatter(params.value),
  },
  {
    field: "totalManufPay",
    headerName: "Total Manuf. Pay",
    valueFormatter: (params) => manufacturerReportCurrencyFormatter(params.value),
  },
  {
    field: "avgThirdPartyPayOfCovered",
    headerName: "Avg. Third-Party Pay (Of Covered)",
    valueFormatter: (params) => manufacturerReportCurrencyFormatter(params.value),
  },
  {
    field: "avgTotalRemittance",
    headerName: "Avg. Total Remittance",
    valueFormatter: (params) => manufacturerReportCurrencyFormatter(params.value),
  },
];
