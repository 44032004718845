import React from "react";
import "twin.macro";
import cn from "classnames";
import { Suppliers } from "../suppliers/Suppliers";
import { Box } from "../rxLibrary/box";
import { InlineNdcSearch } from "./InlineNdcSearch";

export function SearchResultsHeaderBar({
  ndc,
  autoFocus,
  searchDisabled,
  textSearch = false,
  dispensed = false,
  dispensedValue, // = 2 Rxs (70 tablets)
  shopping = false,
  callBack,
  onClose,
}: {
  ndc: string;
  autoFocus?: boolean | undefined;
  searchDisabled?: boolean;
  textSearch?: boolean;
  dispensed?: boolean;
  dispensedValue?: string | null;
  shopping?: boolean;
  callBack?: (value: string) => void;
  onClose?: () => void;
}) {
  return (
    <>
      {!shopping && <Suppliers />}

      <Box
        tw="p-[25px]"
        bgColor="grey-4"
        className={cn(
          "text-center mt-2",
          shopping && "flex justify-between bg-white"
        )}
      >
        <div
          className={cn(
            !shopping && "flex font-300 items-center justify-center flex-col",
            shopping && "flex font-300 justify-center flex-col ml-96"
          )}
        >
          <div tw="max-w-[600px] w-full mb-2">
            <InlineNdcSearch
              autoFocus={autoFocus}
              ndc={ndc}
              disabled={searchDisabled}
              shopping={shopping}
              callBack={callBack}
            />
          </div>
          {textSearch ? (
            <>
              {dispensed && dispensedValue && (
                <p tw="text-lg">
                  Dispensed <span tw="font-bold">{dispensedValue}</span> in the
                  last 30 days
                </p>
              )}

              {dispensed && !dispensedValue && (
                <p tw="text-lg">Not dispensed in 30+ days</p>
              )}
            </>
          ) : (
            <>
              {dispensed && dispensedValue && (
                <p tw="text-lg">
                  Dispensed <span tw="font-bold">{dispensedValue}</span> in drug
                  group in last 30 days
                </p>
              )}
              {dispensed && !dispensedValue && (
                <p tw="text-lg">
                  Drug group has not been dispensed in the last 30 days
                </p>
              )}
            </>
          )}
        </div>
        {shopping && (
          <div className="flex items-center mr-96">
            <a
              href="#"
              className="text-lg underline color-gray2"
              onClick={() => {
                onClose && onClose();
              }}
            >
              Cancel
            </a>
          </div>
        )}
      </Box>
    </>
  );
}
