import React, { useMemo } from "react";
import "twin.macro";
import type { Supplier } from "../../../../../../utilities/types";
import { Text } from "../../../../../../components/rxLibrary/typography";
import {
  getMinShipOrderAmount,
  getMinShipDescription,
} from "../../../../../../utilities/shipping/shipping";

export function ShoppingListTableHeaderShippingInfo({
  supplier,
}: {
  supplier: Supplier;
}) {
  const minShip = useMemo(() => {
    const minShipDescription = getMinShipDescription(supplier, {
      ship: true,
      abbrev: true,
      capitalize: true,
    });
    const minShipPrice = getMinShipOrderAmount(supplier.shippingConfig);

    return `${minShipDescription} - ${minShipPrice}`;
  }, [supplier]);

  return (
    <div tw="flex items-center space-x-1">
      <Text weight="bold" size="large">
        $
      </Text>
      <Text>{minShip}</Text>
    </div>
  );
}
