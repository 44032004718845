import React from "react";
import type { AllMedicationsDetailedDataType } from "./AllMedicationsDetailed.types";
import { AllMedicationsDetailedTable } from "./AllMedicationsDetailedTable/AllMedicationsDetailedTable";

export function AllMedicationsDetailed({
  data,
}: {
  data: AllMedicationsDetailedDataType;
}) {
  if (!data) return;
  
  const {
    allMedicationDetailedTable: { data: allMedicationDetailedTableData }
  } = data;

  return (
    <>
      <div tw="flex-1 h-[calc(100% - 50px)] bg-white">
        <AllMedicationsDetailedTable data={allMedicationDetailedTableData} />
      </div>
    </>
  );
}
