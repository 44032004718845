import { useContext, useMemo } from "react";
import AppContext from "../../contexts/AppContext";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import { UserBusinessRoles } from "../types";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";

/**
 * Redirection rules:
 * https://docs.google.com/spreadsheets/d/1JbtwLVwFTb4CXkSVaataCOf5GmRtDWRmA1V8RXaun_o/edit#gid=0
 */
export function useUserHomeUrl() {
  const { user, userHasRole } = useContext(AppContext);
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);

  const userHomeUrl = useMemo(() => {
    if (!user || !userHasRole) return;

    if (userHasRole(UserBusinessRoles.MANUFACTURING)) return "/manufacturer";
    if (!currentBuyingPharmacy) return;

    const { supplierScope, refillMappingBasedBuyingUsersSeeRefillReport } =
      currentBuyingPharmacy;
    if (supplierScope === "ManufacturerOnly") return "/search";

    if (userHasRole(UserBusinessRoles.BUYING)) {
      if (
        !enhancedClientActive &&
        user.business_roles.length === 1 &&
        !refillMappingBasedBuyingUsersSeeRefillReport.length
      ) {
        return "/shoppingList";
      }

      return "/search";
    }

    return "/performance";
  }, [user, enhancedClientActive, currentBuyingPharmacy, userHasRole]);

  return userHomeUrl;
}
