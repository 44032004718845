export type EnhancedLocationHrefLoginCheckBehavior = {
  requireLoggedInUserAfterNavigation: true,
  injectPostLoginRedirect: boolean,
  enforceLoginURL?: string,
} | {
  requireLoggedInUserAfterNavigation?: false,
}

export type EnhancedLocationHrefBehavior = EnhancedLocationHrefLoginCheckBehavior & {
  forceRefresh?: boolean,
}

export type EnhancedNDCSearchBehavior = EnhancedLocationHrefBehavior & {
  type: "locationHref",
}

export type EnhancedSupplierExtraConfig = {
    name: string,
    type: "extra",
    autoLoginDisabled?: boolean,
    preloadConfigOverrides?: {
      login_un_querySelector?: string,
      login_pw_querySelector?: string,
      login_submit_querySelector?: string,
      login_form_querySelector?: string,
      login_form_submit_type?: "LOGIN_ACTION" | "LOGIN_ACTION_CAPTURE" | "FORM_SUBMIT",
      login_remember_user_querySelector?: string,
      login_remember_device_querySelector?: string,
    },
}
export type EnhancedSupplierDynamicConfig = {
    name: string,
    type: "dynamic",
    site_config_url: string,
    login_un_querySelector: string,
    login_pw_querySelector: string,
    login_form_querySelector: string,
    logged_in_presence_querySelector?: string,
    pre_login_form_click_querySelector?: string,
    login_submit_querySelector?: string,
    login_form_submit_type?: "LOGIN_ACTION" | "LOGIN_ACTION_CAPTURE" | "FORM_SUBMIT",
    autoLoginDisabled?: boolean,
    autoLoginOnlyOnUserEvent?: boolean,
    bodyBgColor?: string,
    ndc_search_behavior?: EnhancedNDCSearchBehavior,
}
export type EnhancedSupplierWebConfig = EnhancedSupplierExtraConfig | EnhancedSupplierDynamicConfig;

export type EnhancedSupplierFrameMasterConfig = {
  url: string,
  heightPx: number, //deprecated
  baseHeightPx: number,
  ndcSearchBaseHeightPx: number,
  shoppingListBaseHeightPx: number,
  expandedHeightPx: number,
  ecommerceHeightPx: number,
  topGutterHeightPx: number,
}

export type EnhancedSupplierSafetyScreenMasterConfig = {
  url: string,
}

export type EnhancedSupplierBrowserBarMasterConfig = {
  url: string,
}

type InactivityConfig = {
  maxUpgradeGracePeriodMs: number,
  idleThresholdMs: number,
  localWorkStartHour: number,
  localWorkStartMinute?: number,
  localWorkEndHour: number,
  localWorkEndMinute?: number,
  shutdownBeginWarningTimeMs: number,
  shutdownDisplayWarningTimeMs: number,
  keepAlive: {
    intervalMs: number,
    varianceMsMax: number,
    supplierExclusions: string[],
  }
}

export type EnhancedAppConfig = {
  inactivityLockMs: number,
  inactivityConfig: InactivityConfig,
  userAgent: string,
  autoUpdateFeedURL: string,
  basicSupplierDynamicConfigs: EnhancedSupplierWebConfig[],
  supplierFrameMasterConfig: EnhancedSupplierFrameMasterConfig,
  supplierSafetyScreenMasterConfig: EnhancedSupplierSafetyScreenMasterConfig,
  supplierBrowserBarMasterConfig: EnhancedSupplierBrowserBarMasterConfig,
}

export const SupplierFrameMasterConfig: EnhancedSupplierFrameMasterConfig = {
  url: window.location.origin + "/supplierNavigation",
  heightPx: 126, //deprecated
  baseHeightPx: 60,
  ndcSearchBaseHeightPx: 96,
  shoppingListBaseHeightPx: 126,
  ecommerceHeightPx: 60,
  expandedHeightPx: 420,
  topGutterHeightPx: 0,
}

export const SupplierSafetyScreenMasterConfig: EnhancedSupplierSafetyScreenMasterConfig = {
  url: window.location.origin + "/supplierSafetyScreen",
}

export const SupplierBrowserBarMasterConfig: EnhancedSupplierBrowserBarMasterConfig = {
  url: window.location.origin + "/supplierBrowserBar",
}

export const ActiveConfiguration: EnhancedAppConfig = {
    inactivityLockMs: 25 * 60 * 1000,
    inactivityConfig: {
      maxUpgradeGracePeriodMs: 48 * 60 * 60 * 1000,
      idleThresholdMs: 25 * 60 * 1000,
      localWorkStartHour: 7,
      localWorkEndHour: 20,
      shutdownBeginWarningTimeMs: 25 * 60 * 1000,
      shutdownDisplayWarningTimeMs: 5 * 60 * 1000,
      keepAlive: {
        intervalMs: 30 * 60 * 1000,
        varianceMsMax: 60 * 1000,
        supplierExclusions: ["TOP_RX"],
      }
    },
    userAgent: "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/124.0.0.0 Safari/537.36",
    // autoUpdateFeedURL: "http://ec2-44-197-212-238.compute-1.amazonaws.com/update/win64/:version/:channel/",
    autoUpdateFeedURL: "https://daylightrx-app-updates-cwekdubhioqloph.s3.amazonaws.com/",
    basicSupplierDynamicConfigs: [
        {
          name: "BONITA",
          type: "dynamic",
          site_config_url: "https://orders.bonitapharma.com/#/login",
          login_un_querySelector: "input#username",
          login_pw_querySelector: "input#password",
          login_submit_querySelector: "input#login-signin",
          login_form_querySelector: "#mainContent > div.ng-scope > div > form[role='form']",
          logged_in_presence_querySelector: "#topLinks li.login:not(.ng-hide)",
          pre_login_form_click_querySelector: "#topLinks li.login:not(.ng-hide)",
          login_form_submit_type: "LOGIN_ACTION",
          ndc_search_behavior: {
            type: "locationHref",
            requireLoggedInUserAfterNavigation: true,
            injectPostLoginRedirect: true
          },
        },
        {
          name: "ASTOR",
          type: "dynamic",
          site_config_url: "https://astordrugs.qwikpoint.net/portal/products.aspx",
          login_un_querySelector: "input#login_name",
          login_pw_querySelector: "input#login_password",
          login_submit_querySelector: "input#btnSubmit",
          login_form_querySelector: "form#login",
          logged_in_presence_querySelector: "form[action*=\"cust_not_active\"], input#login_name",
          ndc_search_behavior: {
            type: "locationHref",
            requireLoggedInUserAfterNavigation: true,
            injectPostLoginRedirect: true,
            enforceLoginURL: "https://astordrugs.qwikpoint.net/login_custom.aspx",
          }
        },
        {
          name: "GENETCO", //TODO establish autologin behavior
          type: "dynamic",
          site_config_url: "https://weborders.genetcoinc.com/mvp/Search.aspx",
          login_un_querySelector: "input#MainContent_txtUserName",
          login_pw_querySelector: "input#MainContent_txtPassword",
          login_submit_querySelector: "input#MainContent_btnSubmit",
          login_form_querySelector: "body > form#form2",
          autoLoginOnlyOnUserEvent: false,
        },
        {
          name: "EZRI_RX",
          type: "dynamic",
          site_config_url: "https://app.ezrirx.com/login",
          login_un_querySelector: "input#spree_user_login",
          login_pw_querySelector: "input#spree_user_password",
          login_submit_querySelector: "input#sign_in_button",
          login_form_querySelector: "form.login-form",
          ndc_search_behavior: {
            type: "locationHref",
            requireLoggedInUserAfterNavigation: true,
            injectPostLoginRedirect: true
          },
        },
        {
          name: "GALDERMA_FFF",
          type: "dynamic",
          site_config_url: "https://biosupply.fffenterprises.com/login",
          login_un_querySelector: "input#userId",
          login_pw_querySelector: "input#password",
          login_submit_querySelector: ".LoginPageTemplate form button[type=submit]",
          login_form_querySelector: ".LoginPageTemplate form",
          logged_in_presence_querySelector: ".LoginPageTemplate form, .button-login",
          pre_login_form_click_querySelector: ".button-login",
          ndc_search_behavior: {
            type: "locationHref",
            requireLoggedInUserAfterNavigation: true,
            injectPostLoginRedirect: true
          },
        },
        {
          name: "API",
          type: "dynamic",
          site_config_url: "https://www.apirx.com/c/login",
          login_un_querySelector: "input#userID",
          login_pw_querySelector: "input#password",
          login_submit_querySelector: ".loginBtn",
          login_form_querySelector: ".loginPortlet > form",
          login_form_submit_type: "LOGIN_ACTION",
          logged_in_presence_querySelector: '.loginPortlet > form, a[href="/c/login"]',
          pre_login_form_click_querySelector: 'a[href="/c/login"]',
          ndc_search_behavior: {
            type: "locationHref",
            requireLoggedInUserAfterNavigation: true,
            enforceLoginURL: "https://www.apirx.com/c/login",
            injectPostLoginRedirect: true
          },
        },
        // Remove after 0.3.78 in wide usage
        {
            name: "ABC",
            type: "extra",
            autoLoginDisabled: true,
        },
        {
            name: "ABC2",
            type: "extra",
            autoLoginDisabled: true,
        },
        {
            name: "SMARTSOURCE",
            type: "extra",
            autoLoginDisabled: true,
        },
        // Example of overriding a preloadConfig:
        // {
        //   name: "MCKESSON",
        //   type: "extra",
        //   preloadConfigOverrides: {
        //     login_remember_user_querySelector: '',
        //     login_remember_device_querySelector: '',
        //   }
        // }
    ],
    supplierFrameMasterConfig: SupplierFrameMasterConfig,
    supplierSafetyScreenMasterConfig: SupplierSafetyScreenMasterConfig,
    supplierBrowserBarMasterConfig: SupplierBrowserBarMasterConfig,
}

 /*
(() => {
    let cfg =
        {
            name: "PARAGON",
            site_config_url: "https://www.paragonmeds.com/Login.aspx/",
            login_un_querySelector: "input#ctl00_contentplaceholder1_txtUser",
            login_pw_querySelector: "input#ctl00_contentplaceholder1_txtPassword",
            login_submit_querySelector: "input.login_btn",
            login_form_querySelector: "body > form#aspnetForm"
        }
        const testQss = (cfg) => {
          console.log(document.querySelectorAll(cfg.login_un_querySelector));
          console.log(document.querySelectorAll(cfg.login_pw_querySelector));
          if (cfg.login_submit_querySelector) {
              console.log(document.querySelectorAll(cfg.login_submit_querySelector));
          } else {
              console.log("no submit qs");
          }
          console.log(document.querySelectorAll(cfg.login_form_querySelector));
        };
    testQss(cfg);
})()
 */
