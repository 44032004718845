import React, { ReactNode, CSSProperties } from "react";
import "twin.macro";
import { Text } from "../../../../../../components/rxLibrary/typography";

export function TableModalTable({
  style,
  title,
  header,
  children,
  className,
}: {
  style?: CSSProperties;
  title?: string;
  header?: ReactNode;
  children: ReactNode;
  className?: string;
}) {
  const hasHeader = !!(header || title);

  return (
    <div tw="mb-4" style={style} className={className}>
      {hasHeader && (
        <div tw="mb-2">
          {title && (
            <Text block weight="bold" size="large">
              {title}
            </Text>
          )}

          {header}
        </div>
      )}

      <div>{children}</div>
    </div>
  );
}
