import React, { useCallback, useEffect, useState } from "react";
import { styled } from "twin.macro";
import IconChatBubblesSvg from "../../assets/icon-chat-bubbles.svg";
import { Text } from "../rxLibrary/typography";
import { WHITE_1 } from "../rxLibrary/colors";
import { styleSvgIcon } from "../rxLibrary/icons";

const Button = styled.button`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 10px;
  padding: 8px 0;
  width: 80px;
  height: 53px;
  border-radius: 6px;
  box-shadow: 0 0 6px 0 #0000003b;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${WHITE_1};
  z-index: 40;
`;

const IconChatBubbles = styleSvgIcon(IconChatBubblesSvg);

export function AnonymousUserChatButton() {
  const [isHide, setIsHide] = useState(window._chatlio.isShown);

  const openChat = useCallback(() => {
    window._chatlio.open();
    setIsHide(true);
  }, []);

  const closeChat = useCallback(() => {
    window._chatlio.hide();
    setIsHide(false);
  }, []);

  useEffect(() => {
    document.addEventListener("chatlio.ready", closeChat);
    document.addEventListener("chatlio.collapsed", closeChat);

    return () => {
      document.removeEventListener("chatlio.ready", closeChat);
      document.removeEventListener("chatlio.collapsed", closeChat);
    };
  }, []);

  useEffect(() => {
    return () => {
      window._chatlio.hide();
    }
  }, []);

  if (isHide) return null;
  return (
    <Button onClick={openChat}>
      <IconChatBubbles color="orange-2" />

      <Text color="grey-1" block>
        Chat with us
      </Text>
    </Button>
  );
}
