import {useContext} from "react";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import { NdcOffering } from "../../../../enhanced/result_types";

export function useDetermineEcommerceByOffering(ndcOffering: NdcOffering): boolean {
  const {getSupplierById} = useContext(BuyingPharmacyContext);
  const supplierId = ndcOffering.item.catalogInfo.supplierId;

  const supplier = getSupplierById(supplierId);

  return !!supplier?.isEcommerceEnabled;
}
