import React from "react";
import { styled } from "twin.macro";
import DotLoader from "./DotLoader/DotLoader";
import { Text } from "../rxLibrary/typography";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffcc;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
`;

export function FullPageLoader({ text }: { text?: string }) {
  if (window.location.pathname === '/supplierNavigation') {
    return <div style={{backgroundColor: "white", height: "100%"}} />;
  }
  if (window.location.pathname === '/supplierBrowserBar') {
    return <div style={{backgroundColor: "rgba(77,77,77)", height: "auto"}} />;
  }
  return (
    <Loader>
      <Text weight="bold" size="x-large" block>
        {text ?? "Loading"}
      </Text>
      <div tw="w-full">
        <DotLoader />
      </div>
    </Loader>
  );
}
