import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import dayjs, { Dayjs } from "dayjs";
import isString from "lodash/isString";
import intersection from "lodash/intersection";
import type { Pharmacy } from "../../utilities/types";
import { getQueryParams } from "../../utilities/queryParams/getQueryParams";
import PharmaciesContext from "../../contexts/PharmaciesContext";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { getLocalStorageValue } from "../../utilities/localStorage/getLocalStorageValue";
import { setLocalStorageValue } from "../../utilities/localStorage/setLocalStorageValue";
import { useReplaceQueryParams } from "../../utilities/queryParams/useReplaceQueryParams";
import { removeLocalStorageValue } from "../../utilities/localStorage/removeLocalStorageValue";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { ReportError } from "../../components/errors/ReportError";
import { ErrorMessage } from "../../components/errors/ErrorMessage";
import { EmailLink } from "../../components/rxLibrary/Link";
import {
  PERFORMANCE_REPORTS,
  BasePerformanceReport,
} from "./Performance.constants";

const PerformanceContext = createContext<
  | {
      toDate: Dayjs;
      fromDate: Dayjs;
      reportId: number;
      report?: BasePerformanceReport;
      reports: Pharmacy["reports"];
      selectedPharmacies: number[];
      setToDate: (toDate: Dayjs) => void;
      setFromDate: (fromDate: Dayjs) => void;
      setReportId: (reportId?: number) => void;
      setSelectedPharmacies: (pharmacyIds: number[]) => void;
    }
  | undefined
>(undefined);

function isReportIdInReports(
  reportId: number | undefined,
  reports: Pharmacy["reports"]
) {
  return !!reportId && reports.some((r) => r.id === reportId);
}

function shouldUseAllPharmacies(
  selectedPharmacies: number[] | undefined,
  pharmacies: Pharmacy[]
) {
  if (!selectedPharmacies?.length) return true;
  const result = selectedPharmacies.length >= pharmacies.length;
  return result;
}

export function PerformanceContextProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const [fromDate, setFromDate] = useState(() => dayjs().subtract(7, "day"));
  const [toDate, setToDate] = useState(() => dayjs().subtract(1, "day"));
  const [_reportId, _setReportId] = useState<number>();
  const [selectedPharmacies, _setSelectedPharmacies] = useState<number[]>();

  const replaceQueryParams = useReplaceQueryParams();
  const { pharmacies } = useContext(PharmaciesContext);
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { id: pharmacyId, reports } = pharmacy ?? {};

  const reportId = useMemo(() => {
    return _reportId || reports?.[0]?.id;
  }, [_reportId, reports?.[0]?.id]);

  const report = useMemo(() => {
    if (reportId) return PERFORMANCE_REPORTS[reportId];
  }, [reportId]);

  const setReportId = useCallback(
    (value?: number) => {
      _setReportId(value);
      replaceQueryParams({ report: value?.toString() ?? "" });
      setLocalStorageValue("LastReportKey", value);
    },
    [replaceQueryParams]
  );

  const setSelectedPharmacies = useCallback(
    (pharmacyIds: number[]) => {
      _setSelectedPharmacies(pharmacyIds);

      if (pharmacyIds.length === 1 && pharmacyIds[0] === pharmacyId) {
        removeLocalStorageValue("LastReportPharmacies");
      } else {
        setLocalStorageValue("LastReportPharmacies", pharmacyIds);
      }
    },
    [pharmacyId]
  );

  useEffect(() => {
    if (!reports?.length) return;

    let initialReportId: number | undefined;
    const queryParams = getQueryParams();
    const reportIdQP = queryParams.report;
    if (!!reportIdQP && isString(reportIdQP)) {
      const reportIdNum = parseInt(reportIdQP, 10);
      const isValidReportId = isReportIdInReports(reportIdNum, reports);
      if (isValidReportId) initialReportId = reportIdNum;
    }

    if (!initialReportId) {
      const reportIdLs = getLocalStorageValue<number | undefined>(
        "LastReportKey",
        undefined
      );
      const isValidReportId = isReportIdInReports(reportIdLs, reports);
      if (isValidReportId) initialReportId = reportIdLs;
    }

    _setReportId(initialReportId);
  }, [reports]);

  useEffect(() => {
    if (!pharmacyId || !pharmacies.length) return;

    const selectedPharmaciesLS = getLocalStorageValue<number[] | undefined>(
      "LastReportPharmacies",
      undefined
    );
    if (!selectedPharmaciesLS) {
      _setSelectedPharmacies([pharmacyId]);
      return;
    }

    const useAllPharmacies = shouldUseAllPharmacies(
      selectedPharmaciesLS,
      pharmacies
    );
    if (useAllPharmacies) {
      _setSelectedPharmacies([]);
      return;
    }

    const pharmacyIds = pharmacies.map((p) => p.id);
    const hasPharmacies =
      intersection(selectedPharmaciesLS, pharmacyIds).length ===
      selectedPharmaciesLS.length;
    const initialSelectedPharmacies = hasPharmacies
      ? selectedPharmaciesLS
      : [pharmacyId];
    _setSelectedPharmacies(initialSelectedPharmacies);
  }, [pharmacyId, pharmacies]);

  if (!reports || !pharmacies) {
    return <FullPageLoader text="Loading Reports" />;
  }

  if (reports.length === 0) {
    return (
      <ErrorMessage tw="h-[100dvh]">
        Your account is not set up to show reports. Please contact{" "}
        <EmailLink email="support@daylightrx.com" /> to add reporting to your
        account.
      </ErrorMessage>
    );
  }

  if (!selectedPharmacies) {
    return <FullPageLoader text="Loading Analytics" />;
  }

  if (!reportId) {
    return <ReportError tw="h-[100dvh]" />;
  }

  return (
    <PerformanceContext.Provider
      value={{
        toDate,
        report,
        reports,
        fromDate,
        reportId,
        selectedPharmacies,
        setToDate,
        setFromDate,
        setReportId,
        setSelectedPharmacies,
      }}
    >
      {children}
    </PerformanceContext.Provider>
  );
}

export function usePerformance() {
  const context = useContext(PerformanceContext);
  if (!context) {
    throw new Error(
      "usePerformance must be used within a PerformanceContextProvider"
    );
  }
  return context;
}
