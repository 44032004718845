import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  { field: "pharmacyName", headerName: "Pharmacy Name", pinned: "left" },
  { field: "businessWeek", headerName: "Business Week" },
  {
    field: "transactions",
    headerName: "Transactions",
    valueFormatter: (params) => numberFormatter(params.value),
  },
  {
    field: "patientPaidAmount",
    headerName: "Patient Paid Amount",
    valueFormatter: (params) => currencyFormatter(params.value),
  },
  {
    field: "insuranceRevenue",
    headerName: "Insurance Revenue",
    valueFormatter: (params) => currencyFormatter(params.value),
  },
  {
    field: "totalPricePaid",
    headerName: "Total Price Paid",
    hide: true,
    valueFormatter: (params) => currencyFormatter(params.value),
  },
  {
    field: "newRx",
    headerName: "New Rx",
    hide: true,
    valueFormatter: (params) => numberFormatter(params.value),
  },
  {
    field: "refillRx",
    headerName: "Refill Rx",
    hide: true,
    valueFormatter: (params) => numberFormatter(params.value),
  },
];
