import React, { useMemo } from "react";
import "twin.macro";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { ManufacturerReportError } from "./ManufacturerReportError";
import { getModeUrlReport } from "../../../services/report";
import { useRequest } from "../../../services/request/useRequest";

export function ManufacturerReportModal({
  reportId,
  endDate,
  startDate,
  iframeProps,
  manufacturerId,
}: {
  reportId: number;
  endDate: string;
  startDate: string;
  iframeProps?: Record<string, any>;
  manufacturerId: number;
}) {
  const config = useMemo(() => {
    return { reportId, fromDate: startDate, toDate: endDate, manufacturerId };
  }, [manufacturerId, reportId, startDate, endDate]);

  const {
    error,
    isLoading,
    data: reportUrl,
  } = useRequest({ config, dataKey: "signedUrl", request: getModeUrlReport });

  return (
    <>
      {!!reportUrl && (
        <iframe {...iframeProps} src={reportUrl} tw="w-full h-full" />
      )}
      {!!error && <ManufacturerReportError />}
      {isLoading && <FullPageLoader />}
    </>
  );
}
