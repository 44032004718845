import isNil from "lodash/isNil";

export async function numberValidator(rule: any, value: any) {
  if (isNil(value)) {
    throw new Error("${label} should be a number");
  }

  const numValue = Number(value);
  if (isNaN(numValue)) {
    throw new Error("${label} should be a number");
  }

  const { min, max } = rule;
  if (!isNil(min) && Number.isFinite(min) && numValue < min) {
    throw new Error(`\${label} must be greater than or equal to ${min}`);
  }
  if (!isNil(max) && Number.isFinite(max) && numValue > max) {
    throw new Error(`\${label} must be less than or equal to ${max}`);
  }
}
