import React, {
  ComponentType,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo, useState
} from "react";
import {Auth0Lock} from 'auth0-lock';
import * as Sentry from "@sentry/react";
import {LOGGED_IN_EMAIL_STORAGE_KEY} from "../utilities/localStorage/constants";
import EnhancedClientConfigContext
  from "../enhanced/EnhancedClientConfigContext";
import { FullPageLoader } from "../components/loaders/FullPageLoader";
import UserInteractionContext from "./UserInteractionContext";
import EnhancedScreenLockAppOutOfDate from "../enhanced/EnhancedScreenLockAppOutOfDate";

const {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_AUDIENCE,
} = process.env;

type AuthenticationContextType = {
  getAccessTokenSilently: () => Promise<string>;
  logout: () => void;
  withAuthenticationRequired: <P extends Record<string, unknown>>(ct: ComponentType<P>) => FC<P>;
  token: string | null;
  confirmToken: (token: string) => void;
}

const AuthenticationContext = React.createContext<AuthenticationContextType | undefined>(undefined);

// const base64URLDecode = (str: string) => {
//   return atob(str.replace(/_/g, '/').replace(/-/g, '+'));
// }

// const validLocalStorageToken = (): string | null => {
//   const token = window.localStorage.getItem('id_token');
//   if (!token) {
//     return null;
//   }
//   const parts = token.split('.');
//   if (parts.length !== 3) {
//     return null;
//   }
//   try {
//     const payload = JSON.parse(base64URLDecode(parts[1]));
//     // exp is UTC in seconds, Date.now() returns milliseconds
//     if (payload.exp && payload.exp < Date.now() / 1000) {
//       console.log("expired");
//       return null;
//     }
//     return token;
//   } catch (err) {
//     return null;
//   }
// }
function parseJwt (token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
const isTokenExpired = (token: string) : boolean => {
  const expiry = parseJwt(token).exp;
  const currTimeSeconds = Math.floor((new Date).getTime() / 1000);
  return currTimeSeconds >= expiry;
}
const isTokenExpiringWithinTwelveHours = (token: string) : boolean => {
  const expiry = parseJwt(token).exp;
  const currTimeSeconds = Math.floor((new Date).getTime() / 1000);
  // console.log("isTokenExpiringWithinTwelveHours", expiry, currTimeSeconds, expiry - (12 * 60 * 60), currTimeSeconds - expiry, currTimeSeconds >= expiry - (12 * 60 * 60));
  return currTimeSeconds >= expiry - (12 * 60 * 60);
  // console.log("isTokenExpiringWithinTwelveHours!", expiry, currTimeSeconds, expiry - (86100), currTimeSeconds - expiry, currTimeSeconds >= expiry - (86100));
  // return currTimeSeconds >= expiry - (86100);
}

export const AuthenticationContextProvider: React.FC<React.ReactNode> = ({children}) => {
  const { userIsInteracting } = useContext(UserInteractionContext);
  const { isAppOutOfDate } = useContext(EnhancedClientConfigContext);
  const [gettingToken, setGettingToken] = React.useState<boolean>(false);
  const [subscribers, setSubscribers] = React.useState<((token: string) => void)[]>([]);
  const [token, setToken] = React.useState<string | null>(null);
  const [isUsingLock, setIsUsingLock] = React.useState<boolean>(false);

  const lock = useMemo(() => {
    const lockUi = new Auth0Lock(REACT_APP_AUTH0_CLIENT_ID as string, REACT_APP_AUTH0_DOMAIN as string, {
      auth: {
        responseType: 'token id_token',
        redirectUrl: window.location.origin,
        redirect: false,
        audience: REACT_APP_AUTH0_AUDIENCE,
      },
      languageDictionary: {
        title: window.location.pathname === '/download' ? "Sign in to Download DaylightRx" : "Sign in to DaylightRx",
      },
      theme: {
        logo: 'https://app.daylightrx.com/assets/daylightrx_logo_132.png',
        primaryColor: '#635dff',
      },
      allowShowPassword: true,
      autoclose: true,
      closable: false,
      prefill: {
        email: window.localStorage.getItem(LOGGED_IN_EMAIL_STORAGE_KEY) || undefined,
      }
    });
    lockUi.on('authenticated', function (authResult) {
      // console.log("authenticated", authResult, "origin", window.location.origin);
      setToken(authResult.idToken);
      window.localStorage.setItem('id_token', authResult.idToken);
      setGettingToken(false);
      setIsUsingLock(false);
    });
    return lockUi;
  }, []);

  useEffect(() => {
    if (isUsingLock && !isAppOutOfDate) {
      lock.show();
    } else {
      lock.hide();
    }
  }, [isUsingLock, isAppOutOfDate]);

  const getAccessTokenSilently = useCallback(() => {
    return new Promise<string>((resolve) => {
      // const lst = validLocalStorageToken();
      try {
        if (token && !isTokenExpired(token)) {
          resolve(token);
          return;
        }
      } catch (err) {
        console.log("error checking token", err);
        Sentry.captureMessage("Error checking token expiry", "error");
      }

      setSubscribers((prev) => [...prev, resolve]);
      setGettingToken(true);
    });
  }, [token]);

  useEffect(() => {
    if (gettingToken) {
      if (window.localStorage.getItem('id_token')) {
        // console.log("checking session");
        lock.checkSession({}, function (err, authResult) {
          if (err || !authResult) {
            // console.log("checkSession error; showing lock", err);
            setIsUsingLock(true);
          } else {
            // console.log("checkSession success", authResult, "origin", window.location.origin);
            console.log("checkSession success", authResult, isTokenExpiringWithinTwelveHours(authResult.idToken));
            setToken(authResult.idToken);
            window.localStorage.setItem('id_token', authResult.idToken);
            setGettingToken(false);
            setIsUsingLock(false);
          }
        });
      } else {
        // console.log("showing lock");
        setIsUsingLock(true);
      }
    }
  }, [gettingToken]);

  useEffect(() => {
    if (subscribers.length === 0) {
      return;
    }
    if (token) {
      subscribers.forEach((subscriber) => {
        subscriber(token);
      });
      setSubscribers([]);
    }
  }, [token, subscribers]);

  const logout = useCallback(() => {
    // console.log("logging out");
    setToken(null);
    window.localStorage.removeItem('id_token');
    lock.logout({
      returnTo: window.location.origin,
    });
  }, []);

  const invokeEarlyExpiration = useCallback(() => {
    setToken(null);
    window.localStorage.removeItem('id_token');
    setGettingToken(true);
  }, []);

  const [shouldLogoutCheckTime, setShouldLogoutCheckTime] = useState<number>(Date.now());
  const [prevShouldLogoutCheckTime, setPrevShouldLogoutCheckTime] = useState<number>(Date.now());
  const [shouldPerformLogoutCheck, setShouldPerformLogoutCheck] = useState<boolean>(false);

  const confirmToken = useCallback( (token: string) => {
    if (isTokenExpiringWithinTwelveHours(token)) {
      invokeEarlyExpiration();
    }
  },[token, invokeEarlyExpiration]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      setShouldLogoutCheckTime(Date.now());
    }, 10000);
    return () => {
      window.clearInterval(interval);
    }
  }, []);
  useEffect(() => {
    if (shouldLogoutCheckTime - prevShouldLogoutCheckTime > 10 * 60 * 1000) {
      setShouldPerformLogoutCheck(true);
    }
    setPrevShouldLogoutCheckTime(shouldLogoutCheckTime);
  }, [shouldLogoutCheckTime, prevShouldLogoutCheckTime]);
  useEffect(() => {
    console.log("shouldPerformLogoutCheck", shouldPerformLogoutCheck, "token", token);
    if (token && shouldPerformLogoutCheck) {
      confirmToken(token);
    }
  }, [shouldPerformLogoutCheck, token, confirmToken]);

  useEffect(() => {
    if (window.sessionStorage.getItem("authenticationSession")) {
      console.log("Found authenticationSession");
    }
    if (token && !window.sessionStorage.getItem("authenticationSession")) {
      window.sessionStorage.setItem("authenticationSession", "true");
      console.log("Setting authenticationSession and checking expiry");
      if (window.location.href.indexOf("/manufacturer-store") === -1) {
        confirmToken(token);
      }
    }
  }, [token]);

  useEffect(() => {
    console.log("userIsInteracting", userIsInteracting, "token", token);
    let interval: number | null = null;
    if (token && !userIsInteracting) {
      if (isTokenExpiringWithinTwelveHours(token)) {
        const checkExpiry = () => {
          console.log("Checking token expiry");
          if (window.location.href.indexOf("/shoppingListThree") === -1
            && window.location.href.indexOf("/supplierNavigation") === -1
            && window.location.href.indexOf("/manufacturer-store") === -1
          ) {
            confirmToken(token);
          }
        }
        interval = window.setInterval(checkExpiry, 60000);
        checkExpiry();
      }
    }
    return () => {
      if (interval) {
        window.clearInterval(interval);
      }
    }
  }, [userIsInteracting, token]);

  const memoizedUpdaters = useMemo(() => {
    // console.log("memoizedUpdaters")
    return {
      getAccessTokenSilently,
      logout,
      withAuthenticationRequired,
      token,
      confirmToken,
    };
  }, [getAccessTokenSilently, logout, withAuthenticationRequired, token, confirmToken]);

  if (isAppOutOfDate) {
    return (
      <AuthenticationContext.Provider value={memoizedUpdaters}>
        <EnhancedScreenLockAppOutOfDate>
          {children}
        </EnhancedScreenLockAppOutOfDate>
      </AuthenticationContext.Provider>
    )

  } else {
    return (
      <AuthenticationContext.Provider value={memoizedUpdaters}>
        {children}
      </AuthenticationContext.Provider>
    )
  }
}

export const withAuthenticationRequired = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
): FC<P> => {
  return function WithAuthenticationRequired(props: P) {
    const { token } = useAuth0();

    /**
     * The route is authenticated if the user has valid auth and there are no
     * JWT claim mismatches.
     */
    const routeIsAuthenticated = !!token;

    return routeIsAuthenticated ? <Component {...props} /> : <FullPageLoader />;
  };
}

export const useAuth0 = () => {
  const context = React.useContext(AuthenticationContext);
  if (context === undefined) {
    throw new Error(
      "useAuthentication must be used within an AuthenticationContextProvider"
    );
  }
  return context;
}
