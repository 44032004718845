import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminSupplierMappings } from "./AdminSupplierMappings";
import { CreateAdminSupplierMapping } from "./CreateAdminSupplierMapping";
import { EditAdminSupplierMapping } from "./EditAdminSupplierMapping";

const PATH = "/admin/supplier-mapping";
const PATH_UPDATE = `${PATH}/:id`;
const PATH_CREATE = `${PATH}/new`;

export function SupplierMappingRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminSupplierMappings} exact />
      <Route path={PATH_CREATE} component={CreateAdminSupplierMapping} exact />
      <Route path={PATH_UPDATE} component={EditAdminSupplierMapping} exact />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
