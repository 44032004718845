import React, { useEffect, useState } from "react";
import "twin.macro";
import { Warning } from "../../../../../../components/rxLibrary/warnings";
import { formatSupplierDisplayNames } from "../../../../../../utilities/suppliers/formatSupplierNames";
import { ShoppingItem } from "../../../useShoppingList";

function getSlipNdcMsg(ndc: string, shoppingItems: ShoppingItem[]) {
  const suppliers = shoppingItems.flatMap((shoppingItem) => {
    const { supplierOrder, supplier } = shoppingItem;
    const supplierNames = supplierOrder.items
      .map((item) => {
        if (item.referenceData.drugInfo.ndc === ndc) return supplier.displayName;
      })
      .filter(Boolean);

    return supplierNames as string[];
  });

  if (suppliers.length < 2) return;

  const suppliersNames = formatSupplierDisplayNames(suppliers);
  return `Item is split between ${suppliersNames} for shipping costs optimization`;
}

export function SplitNdcWarning({
  ndc,
  shoppingItems,
}: {
  ndc: string;
  shoppingItems: ShoppingItem[];
}) {
  const [msg, setMsg] = useState<string>();

  useEffect(() => {
    const newMsg = getSlipNdcMsg(ndc, shoppingItems);
    setMsg(newMsg);
  }, [ndc, shoppingItems]);

  return <Warning>{msg}</Warning>;
}
