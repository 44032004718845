import tw, { css, styled } from "twin.macro";
import { GREY_4 } from "../../components/rxLibrary/colors";
import { printStyleHOC } from "../../components/rxLibrary/print";

export const ManufacturerStoreWrapper = printStyleHOC(
  styled.div(() => [
    tw`min-h-screen pt-[24px] px-[80px] pb-[200px]`,
    css`
      background-color: ${GREY_4};
    `,
  ]),
  css`
    background-color: initial;
    padding: 0;
  `
);