import keyBy from "lodash/keyBy";
import type {
  EntityId,
  DrugInfo,
  ItemInCart,
  Prescription,
  ItemPurchaseDetails,
} from "../../utilities/types";
import { isInventoryId } from "../../utilities/prescriptions/isInventoryId";
import { getInitialManufacturerFromDrugInfo } from "../../utilities/prescriptions/getInitialManufacturerFromDrugInfo";

export function mergePrescriptionsAndCartData(
  prescriptions: Prescription[],
  cart: ItemPurchaseDetails[],
  referenceData?: Record<EntityId, DrugInfo>
): ItemInCart[] {
  const seenRxNumbers = new Set<string>();
  const cartItemsById = keyBy(cart, "id");

  const newItems = prescriptions.map<ItemInCart>((item) => {
    seenRxNumbers.add(item.rxNumber);
    const cartObj = cartItemsById[item.rxNumber];
    const eoh = item.eoh || 0;
    const boh = item.boh || 0;

    const newItem: ItemInCart = cartObj
      ? {
          ...item,
          eoh,
          boh,
          addedAt: cartObj.addedAt,
          packSize: cartObj.packSize,
          manufactutrer: cartObj.manufactutrer,
          status: cartObj.status,
          currentFillId: cartObj.currentFillId ? cartObj.currentFillId : "",
          processedAt: cartObj.processedAt,
          supplierId: cartObj.supplierId,
          note: cartObj.note,
          purchaseBy: cartObj.purchaseBy,
        }
      : {
          ...item,
          eoh,
          boh,
          status: "add",
          packSize: true,
          manufactutrer: getInitialManufacturerFromDrugInfo(item.drug),
        };

    return newItem;
  });

  // When loading from a past snapshot, we may have items in the cart that are not in the prescriptions
  // In that case, create a Prescription object that tells the user to see the PMS for more details
  if (referenceData) {
    cart
      .filter((item) => !isInventoryId(item.id))
      .filter((item) => !seenRxNumbers.has(item.id))
      .forEach((item) => {
        const newItem: ItemInCart = {
          drug: referenceData[item.ndc],
          dawCode: null,
          dispensedQuantity: 0,
          fillDate: new Date().toString(),
          patient: { shortName: "See PMS" },
          prescriber: { lastName: "See PMS" },
          rxNumber: item.id,
          eoh: 0,
          boh: 0,
          packSize: item.packSize,
          manufactutrer: item.manufactutrer,
          status: "add",
          currentFillId: item.currentFillId ? item.currentFillId : "",
          processedAt: item.processedAt,
          supplierId: item.supplierId,
          note: item.note,
          purchaseBy: item.purchaseBy,
          addedAt: item.addedAt,
        };
        newItems.push(newItem);
      });
  }

  return newItems;
}
