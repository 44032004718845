import { useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../contexts/AppContext";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { getRecentOptimizations } from "../../services/legacy/optimizations";
import { useAuth0 } from "../../contexts/AuthenticationContext";
import { UserBusinessRoles } from "../types";
import { ROUTES } from "./routing.constants";

/**
 * Redirection rules:
 * https://docs.google.com/spreadsheets/d/1JbtwLVwFTb4CXkSVaataCOf5GmRtDWRmA1V8RXaun_o/edit#gid=0
 */
export function useNavBarMenuRoutes() {
  const { getAccessTokenSilently } = useAuth0();
  const { userHasRole, user } = useContext(AppContext);
  const [recentOptimizationsCount, setRecentOptimizationsCount] = useState(0);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { id: pharmacyId } = pharmacy ?? {};

  const userLinks = useMemo(() => {
    if (!userHasRole || !pharmacy) return;

    if (userHasRole(UserBusinessRoles.MANUFACTURING)) return;

    const {
      reports,
      supplierScope,
      refillMappingBasedBuyingUsersSeeRefillReport,
    } = pharmacy;

    let routes = ROUTES.filter((route) => {
      if (route.to === "/performance") {
        const hasPermissions =
          !!reports.length && userHasRole(UserBusinessRoles.REPORTING);
        return hasPermissions;
      }

      if (route.to === "/refill-opportunities") {
        const hasPermissions =
          !!refillMappingBasedBuyingUsersSeeRefillReport.length;
        return hasPermissions;
      }

      const hasPermissions =
        !route.enhancedClientOnly || enhancedClientActive || user?.is_admin;
      return hasPermissions;
    });

    if (supplierScope === "ManufacturerOnly") {
      return routes.filter(({ to }) => {
        return to === "/refill-opportunities" || to === "/performance";
      });
    }

    if (!userHasRole(UserBusinessRoles.REPORTING)) return routes;

    routes = routes.map((route) => {
      if (route.to === "/previousShoppingLists") {
        const text = `MY LATEST SHOPPING LISTS (${recentOptimizationsCount})`;
        return { ...route, text };
      }
      return route;
    });

    if (userHasRole(UserBusinessRoles.BUYING)) return routes;
  }, [pharmacy, enhancedClientActive, recentOptimizationsCount, userHasRole]);

  useEffect(() => {
    init();

    async function init() {
      if (!pharmacyId) return;
      const token = await getAccessTokenSilently();
      const recentOptimizationsRequest = await getRecentOptimizations(
        token,
        pharmacyId
      );
      setRecentOptimizationsCount(
        recentOptimizationsRequest.cartOptimizations.length
      );
    }
  }, [pharmacyId]);

  return userLinks;
}
