import { useCallback, useState, ReactNode } from "react";
import { styled } from "twin.macro";
import { Modal as BaseModal, ModalProps } from "antd";

export function useModal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return { isModalOpen, openModal, closeModal };
}

const ModalWrapper = styled.div`
  .ant-modal-content {
    padding: 0;
    border-radius: 0;
  }

  .ant-modal-close {
    display: none;
  }
`;

function modalRender(node: ReactNode) {
  return <ModalWrapper>{node}</ModalWrapper>;
}

export function Modal({
  width = 650,
  children,
  ...props
}: Omit<ModalProps, "modalRender" | "footer">) {
  return (
    <BaseModal
      {...props}
      tw="top-[24px]"
      width={width}
      footer={null}
      modalRender={modalRender}
    >
      <div tw="h-[calc(100dvh - 46px)]">{children}</div>
    </BaseModal>
  );
}
