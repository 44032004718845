import React, {Reducer, useEffect, useReducer,} from "react";
import "twin.macro";
import "../scss/base.scss";
import "../scss/enhBrowserBar.scss";
import {IconArrowLeft, IconRefresh} from "../components/rxLibrary/icons";
export type EnhancedSupplierBrowserBarDisplayConfig =
  {
    supplier: string;
    supplierDisplay: string;
    useCase: "default";
  }

type ConfigAdj =
  | {
  type: "setConfig";
  config: EnhancedSupplierBrowserBarDisplayConfig;
}

type OptionsState = {
  canGoBack: boolean;
  canGoForward: boolean;
}

type OptionsAdjustment = {
  canGoBack?: boolean;
  canGoForward?: boolean;
}

export default function EnhancedSupplierBrowserBar(): JSX.Element {

  useEffect(() => {
    const hideChatlio = setInterval(() => {
      if (window._chatlio && window._chatlio.hide) {
        window._chatlio.hide();
      }
    }, 1000);
    return () => {
      clearInterval(hideChatlio);
    };
  }, []);

  const [displayConfig, updateDisplayConfig] = useReducer<
    Reducer<EnhancedSupplierBrowserBarDisplayConfig, ConfigAdj>
  >(
    (state, update): EnhancedSupplierBrowserBarDisplayConfig => {
      console.log("Updating display config", update, "from", state, "");
      switch (update.type) {
        case "setConfig":
          return update.config;
        default:
          return state;
      }
    },
    {
      useCase: "default",
      supplier: "default",
      supplierDisplay: "Default Supplier",
    }
  );

  const [optionsState, updateOptionsState] = useReducer<
    Reducer<OptionsState, OptionsAdjustment>
  >(
    (state, update): OptionsState => {
      console.log("Updating options state", update, "from", state, "");
      return {
        canGoBack: update.canGoBack ?? state.canGoBack,
        canGoForward: update.canGoForward ?? state.canGoForward,
      };
    },
    {
      canGoBack: true,
      canGoForward: true,
    }
  );

  useEffect(() => {
    document.body.style.backgroundColor = "rgba(0,0,0,0)";
    const root = document.getElementById("root");
    if (root) {
      root.style.backgroundColor = "rgba(77,77,77)";
      root.style.height = "auto";
    }
    return () => {
      document.body.style.backgroundColor = "rgba(77,77,77)";
      const root = document.getElementById("root");
      if (root) {
        root.style.backgroundColor = "inherit";
        root.style.height = "100%";
      }
    };
  }, []);

  useEffect(() => {
    const messageListenerCallback = async (event: MessageEvent) => {
      if (event.source !== window) {
        return;
      }
      if (event.data.channel === "daylightrxenhanced") {
        if (event.data.msgType === "browserBarDisplayConfig_default") {
          const payload = event.data.payload as {
            supplier: string;
            supplierDisplay: string;
          };
          updateDisplayConfig({
            type: "setConfig",
            config: {
              supplier: payload.supplier,
              supplierDisplay: payload.supplierDisplay,
              useCase: "default",
            },
          });
        }
        if (event.data.msgType === "browserBarOptionsUpdate") {
          const payload = event.data.payload as OptionsAdjustment;
          updateOptionsState(payload);
        }
      }
    };
    window.addEventListener("message", messageListenerCallback);

    return () => {
      window.removeEventListener("message", messageListenerCallback);
    };
  }, []);

  return (
    <div
      className="enhancedSupplierBrowserBar"
    >
      <div
        className={optionsState.canGoBack ? 'navBarButtonOval back enabled' : 'navBarButtonOval back disabled'}>
        <IconArrowLeft tw="scale-125 cursor-pointer" className="element"
                       onClick={optionsState.canGoBack ? () => {
                         window.postMessage({
                           channel: "daylightrxenhanced",
                           sender: "web",
                           msgType: "browserBarBack",
                           payload: {
                             supplier: displayConfig.supplier,
                           }
                         });
                       } : () => {console.log("disabled")}}/>
      </div>
      <div className={optionsState.canGoForward ? 'navBarButtonOval forward enabled' : 'navBarButtonOval forward disabled'}>
        <IconArrowLeft tw="scale-125 rotate-180 cursor-pointer" className="element"
                       onClick={optionsState.canGoForward ? () => {
                         window.postMessage({
                           channel: "daylightrxenhanced",
                           sender: "web",
                           msgType: "browserBarForward",
                           payload: {
                             supplier: displayConfig.supplier,
                           }
                         });
                       } : () => {console.log("disabled")}}/>
      </div>
        <div tw="cursor-pointer flex items-center" onClick={() => {
          window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "browserBarRefresh",
            payload: {
              supplier: displayConfig.supplier,
            }
          });
        }}>
          <IconRefresh tw="scale-125 mr-2" color="white-1"/>
          <span className='navBarRefreshLink'
            tw="text-white uppercase">Refresh {displayConfig?.supplierDisplay.toLowerCase() === "daylightrx_ecommerce" ? "" : displayConfig?.supplierDisplay}</span>
        </div>
      </div>
      );
      }
