import { useState, useCallback, useEffect } from "react";
import { Popover } from "antd";
import { useModal } from "../../rxLibrary/modals";
import { IconBell } from "../../rxLibrary/icons";
import { Button, SyncButtonAndText } from "../../rxLibrary/buttons";
import { Text } from "../../rxLibrary/typography";
import { useRefillOpportunities } from "../useRefillOpportunities";

function PopoverContent({
  pms,
  closeModal,
}: {
  pms: string;
  closeModal: () => void;
}) {
  return (
    <div tw="flex flex-col items-center text-center p-[10px] space-y-[5px]">
      <IconBell color="red-1" />

      <Text>
        Seeing a refill you recently processed?
        <br />
        Please try refreshing again soon;
        <br />
        {pms} data may take up to 15 minutes to arrive.
        <br />
      </Text>

      <Button variant="text-1" onClick={closeModal}>
        OK
      </Button>
    </div>
  );
}

export function RefillOpportunitiesHeaderSyncButton() {
  const { isModalOpen, openModal, closeModal } = useModal();
  const { isLoading, pharmacy, isRefetching, lastSynchDate, refetch } =
    useRefillOpportunities();

  const { pms } = pharmacy;

  const [
    displaySyncPopupAfterSynDateChanges,
    setDisplaySyncPopupAfterSynDateChanges,
  ] = useState(false);

  const onSync = useCallback(() => {
    closeModal();
    setDisplaySyncPopupAfterSynDateChanges(true);
    refetch();
  }, [refetch, closeModal]);

  useEffect(() => {
    if (isLoading) return;
    if (isRefetching) return;
    if (!lastSynchDate) return;
    if (!displaySyncPopupAfterSynDateChanges) return;

    setDisplaySyncPopupAfterSynDateChanges(false);
    openModal();
  }, [
    isLoading,
    isRefetching,
    lastSynchDate,
    displaySyncPopupAfterSynDateChanges,
    openModal,
  ]);

  return (
    <Popover
      trigger="contextMenu"
      onOpenChange={closeModal}
      open={isModalOpen}
      content={() => <PopoverContent pms={pms} closeModal={closeModal} />}
      destroyTooltipOnHide
    >
      <SyncButtonAndText
        tw="min-w-[240px]"
        text="Refresh page"
        loadingText="Refreshing..."
        syncDatePrefix="Refreshed"
        loading={isLoading}
        syncDate={lastSynchDate}
        onClick={onSync}
      />
    </Popover>
  );
}
