import React, { useMemo } from "react";
import "twin.macro";
import {
  getAdminAccount,
  updateAdminAccount,
} from "../../../../../services/accounts";
import { AdminAccountForm } from "./AdminAccountForm/AdminAccountForm";
import { useEditForm } from "../../../../../components/antd/form/useEditForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { formatAdminAccountFormBusinessRoles } from "./AdminAccountForm/useAdminAccountOptions";

export function EditAdminAccount() {
  const { values, onSubmit } = useEditForm({
    name: "account",
    update: updateAdminAccount,
    fetchValues: getAdminAccount,
  });

  const { id, initialValues } = useMemo(() => {
    if (!values) return {};

    const { id, pharmacies, businessRoles, ...otherValues } = values;
    const pharmacyIds = pharmacies.map(({ id }) => id);
    const formBusinessRoles =
      formatAdminAccountFormBusinessRoles(businessRoles);
    const initialValues = {
      ...otherValues,
      pharmacies: pharmacyIds,
      businessRoles: formBusinessRoles,
    };

    return { id, initialValues };
  }, [values]);

  return (
    <WideContainer tw="pb-[200px]">
      <AdminAccountForm
        isUpdateForm
        id={id}
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
