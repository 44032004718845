import {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  ReactNode,
} from "react";
import type { PrescriptionQuantityItem, OfferedDrug } from "../../utilities/types";

type BlobInterval = {
  id: number | null;
  intervalId: NodeJS.Timeout;
};

type StorageStateContextType = {
  prescriptionData: PrescriptionQuantityItem[];
  offeredDrugs: OfferedDrug[];
  waitButtonMode: boolean;
  blobIntervals: BlobInterval[];
};

type StorageUpdaterContextType = {
  setPrescriptionData: Dispatch<SetStateAction<PrescriptionQuantityItem[]>>;
  setOfferedDrugs: Dispatch<SetStateAction<OfferedDrug[]>>;
  setWaitButtonMode: (mode: boolean) => void;
  addInterval: (obj: BlobInterval) => void;
  resetInterval: () => void;
};

const StorageStateContext = createContext<StorageStateContextType | undefined>(
  undefined
);

const StorageUpdaterContext = createContext<
  StorageUpdaterContextType | undefined
>(undefined);

export function StorageContextProvider({ children }: { children: ReactNode }) {
  const [prescriptionData, setPrescriptionData] = useState<
    PrescriptionQuantityItem[]
  >([]);
  const [offeredDrugs, setOfferedDrugs] = useState<OfferedDrug[]>([]);
  const [waitButtonMode, setWaitButtonMode] = useState(false);
  const [blobIntervals, setBlobIntervals] = useState<BlobInterval[]>([]);

  const resetInterval = useCallback(() => {
    setBlobIntervals((prevState: BlobInterval[]) => {
      prevState.forEach((obj: BlobInterval) => {
        window.clearInterval(obj.intervalId);
      });

      // reset
      return [];
    });
  }, [setBlobIntervals]);

  const addInterval = useCallback((obj: BlobInterval) => {
    setBlobIntervals((prev) => [...prev, obj]);
  }, []);

  return (
    <StorageStateContext.Provider
      value={{
        offeredDrugs,
        blobIntervals,
        waitButtonMode,
        prescriptionData,
      }}
    >
      <StorageUpdaterContext.Provider
        value={{
          addInterval,
          resetInterval,
          setOfferedDrugs,
          setWaitButtonMode,
          setPrescriptionData,
        }}
      >
        {children}
      </StorageUpdaterContext.Provider>
    </StorageStateContext.Provider>
  );
}

export function useStorageState(): StorageStateContextType {
  const context = useContext(StorageStateContext);
  if (context === undefined) {
    throw new Error(
      "useStorageState must be used within a StorageContextProvider"
    );
  }
  return context;
}

export function useStorageUpdater(): StorageUpdaterContextType {
  const context = useContext(StorageUpdaterContext);
  if (context === undefined) {
    throw new Error(
      "useStorageUpdater must be used within a StorageContextProvider"
    );
  }
  return context;
}
