import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { formatNumberWithDecimals } from "../../../utilities/formatWithUnit";

export function DispensedQuantityHistory({
  boh,
  eoh,
}: {
  boh: number;
  eoh?: number;
}) {
  return (
    <Box component="div">
      <Box
        component="div"
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", marginRight: "3px" }}
        >
          BOH:{" "}
        </Typography>
        <Typography variant="body2" noWrap>
          {formatNumberWithDecimals(boh)}
        </Typography>
      </Box>

      {eoh !== undefined && (
        <Box
          component="div"
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginRight: "3px" }}
          >
            EOH:{" "}
          </Typography>
          <Typography variant="body2" noWrap>
            {formatNumberWithDecimals(eoh)}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
