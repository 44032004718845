import React, { memo, useMemo } from "react";
import "twin.macro";
import type { DrugInfo, CatalogInfo } from "../utilities/types";
import { formatNdc } from "../utilities/ndc/formatNdc";
import { formatDrugNameWithDetails } from "../utilities/drugInfo/formatDrugNameWithDetails";
import { dayjsWithDuration } from "../utilities/dates/dayjsWithDuration";
import { formatDrugDispenseQuantity } from "../utilities/drugInfo/formatDrugDispenseQuantity";
import { Text } from "../components/rxLibrary/typography";
import { ExpirationDate } from "./ExpirationDate";

function DispensedItemContentV1({
  alert,
  catalogInfo,
  drugNameWithDetails,
  manufacturerDetails,
  quantityDescription,
  ndcAndLastInvoicedDate,
}: {
  alert?: string;
  drugNameWithDetails: string;
  catalogInfo?: CatalogInfo;
  quantityDescription?: string;
  manufacturerDetails?: string;
  ndcAndLastInvoicedDate?: string;
}) {
  return (
    <>
      <p className="font-700">
        {drugNameWithDetails}

        {!!quantityDescription && ` | ${quantityDescription}`}

        {!!catalogInfo && (
          <>
            {" "}
            <ExpirationDate catalogInfo={catalogInfo} />
          </>
        )}
      </p>

      {!!ndcAndLastInvoicedDate && <p>{ndcAndLastInvoicedDate}</p>}

      {!!manufacturerDetails && <p>{manufacturerDetails}</p>}

      {alert && <p className="color-orange font-700">{alert}</p>}
    </>
  );
}

function DispensedItemContentV2({
  alert,
  catalogInfo,
  drugNameWithDetails,
  manufacturerDetails,
  quantityDescription,
  ndcAndLastInvoicedDate,
}: {
  alert?: string;
  drugNameWithDetails: string;
  catalogInfo?: CatalogInfo;
  quantityDescription?: string;
  manufacturerDetails?: string;
  ndcAndLastInvoicedDate?: string;
}) {
  return (
    <>
      <Text weight="bold" block>
        {drugNameWithDetails}

        {!!catalogInfo && (
          <>
            {" "}
            <ExpirationDate catalogInfo={catalogInfo} />
          </>
        )}
      </Text>

      {!!(quantityDescription || manufacturerDetails) && (
        <Text block>
          {quantityDescription}

          {!!manufacturerDetails && (
            <>
              {!!quantityDescription && " | "}
              {manufacturerDetails}
            </>
          )}
        </Text>
      )}

      {!!ndcAndLastInvoicedDate && <Text block>{ndcAndLastInvoicedDate}</Text>}

      {alert && (
        <Text weight="bold" color="orange-1" block>
          {alert}
        </Text>
      )}
    </>
  );
}

function DispensedItem({
  drug,
  alert,
  className,
  catalogInfo,
  originalInvoiceDate,
  v2 = false,
  ndc: showNdc = true,
  showManufacturer = false,
  showQuantityDescription = false,
  noManufacturerPreference = false,
}: {
  drug: DrugInfo;
  v2?: boolean;
  ndc?: boolean;
  alert?: string;
  className?: string;
  catalogInfo?: CatalogInfo;
  showManufacturer?: boolean;
  originalInvoiceDate?: string;
  showQuantityDescription?: boolean;
  noManufacturerPreference?: boolean;
}) {
  const { ndc, manufacturer } = drug;

  const ndcAndLastInvoicedDate = useMemo(() => {
    if (!showNdc && !originalInvoiceDate) return;
    let text = "";
    if (showNdc) text += `NDC: ${formatNdc(ndc)}`;
    if (showNdc && originalInvoiceDate) text += " | ";
    if (originalInvoiceDate) {
      const date = dayjsWithDuration
        .duration(dayjsWithDuration(originalInvoiceDate).diff(new Date()))
        .humanize(true);
      text += `Last Invoiced ${date}`;
    }
    return text;
  }, [showNdc, ndc, originalInvoiceDate]);

  const drugNameWithDetails = useMemo(() => {
    return formatDrugNameWithDetails(drug);
  }, [drug]);

  const quantityDescription = useMemo(() => {
    if (showQuantityDescription) return formatDrugDispenseQuantity(drug);
  }, [drug]);

  const manufacturerDetails = useMemo(() => {
    if (!showManufacturer) return;
    return noManufacturerPreference ? "Any Manufacturer" : manufacturer;
  }, [showManufacturer, noManufacturerPreference, manufacturer]);

  return (
    <div className={className}>
      {v2 ? (
        <DispensedItemContentV2
          alert={alert}
          catalogInfo={catalogInfo}
          drugNameWithDetails={drugNameWithDetails}
          quantityDescription={quantityDescription}
          manufacturerDetails={manufacturerDetails}
          ndcAndLastInvoicedDate={ndcAndLastInvoicedDate}
        />
      ) : (
        <DispensedItemContentV1
          alert={alert}
          catalogInfo={catalogInfo}
          drugNameWithDetails={drugNameWithDetails}
          quantityDescription={quantityDescription}
          manufacturerDetails={manufacturerDetails}
          ndcAndLastInvoicedDate={ndcAndLastInvoicedDate}
        />
      )}
    </div>
  );
}

export default memo(DispensedItem);
