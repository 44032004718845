"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cancelHRD = cancelHRD;
exports.logIn = logIn;
exports.startHRD = startHRD;
var _index = require("../../store/index");
var _enterprise = require("../enterprise");
var _index2 = require("../../field/index");
var _email = require("../../field/email");
var _actions = require("../../core/actions");
var l = _interopRequireWildcard(require("../../core/index"));
var _captcha = require("../captcha");
var _index4 = require("../database/index");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function startHRD(id, email) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _enterprise.toggleHRD, email);
}
function cancelHRD(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = (0, _enterprise.toggleHRD)(m, false);
    m = (0, _index2.hideInvalidFields)(m);
    return m;
  });
}
function getConnectionScopesFrom(m, connection) {
  var connectionScopes = l.auth.connectionScopes(m);
  return connectionScopes.get(connection.get('name'));
}
function logIn(id) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var email = (0, _index2.getFieldValue)(m, (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username');
  var ssoConnection = (0, _enterprise.matchConnection)(m, email);
  var enterpriseConnection = (0, _enterprise.enterpriseActiveFlowConnection)(m);
  var connectionScopes = getConnectionScopesFrom(m, ssoConnection || enterpriseConnection);
  var usernameField = (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username';
  var fields = [usernameField, 'password'];
  var params = {
    connection_scope: connectionScopes ? connectionScopes.toJS() : undefined
  };
  if (ssoConnection && !(0, _enterprise.isHRDActive)(m)) {
    return logInSSO(id, ssoConnection, params);
  }
  var isCaptchaValid = (0, _captcha.setCaptchaParams)(m, params, false, fields);
  if (!isCaptchaValid && !ssoConnection) {
    return (0, _captcha.showMissingCaptcha)(m, id);
  }
  logInActiveFlow(id, params);
}
function logInActiveFlow(id, params) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var usernameField = (0, _enterprise.isHRDActive)(m) || !(0, _index4.databaseLogInWithEmail)(m) ? 'username' : 'email';
  var originalUsername = (0, _index2.getFieldValue)(m, usernameField);
  var connection = (0, _enterprise.enterpriseActiveFlowConnection)(m);
  var username = l.defaultADUsernameFromEmailPrefix(m) ? (0, _email.emailLocalPart)(originalUsername) : originalUsername;
  (0, _actions.logIn)(id, ['password', usernameField], _objectSpread(_objectSpread({}, params), {}, {
    connection: connection ? connection.get('name') : null,
    username: username,
    password: (0, _index2.getFieldValue)(m, 'password'),
    login_hint: username
  }), function (id, error, fields, next) {
    var wasCaptchaInvalid = error && error.code === 'invalid captcha';
    (0, _captcha.swapCaptcha)(id, false, wasCaptchaInvalid, next);
  });
}
function logInSSO(id, connection, params) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var field = (0, _index4.databaseLogInWithEmail)(m) ? 'email' : 'username';
  l.emitEvent(m, 'sso login', {
    lockID: id,
    connection: connection,
    field: field
  });
  (0, _actions.logIn)(id, [field], _objectSpread(_objectSpread({}, params), {}, {
    connection: connection.get('name'),
    login_hint: (0, _index2.getFieldValue)(m, field)
  }));
}
