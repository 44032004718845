import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  manufacturerReportPercentFormatter,
  expandHereValueGetter,
} from "../utils/manufacturerReportFormatters";
import { getDetailCellRendererParams } from "../utils/getDetailCellRendererParams";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      {field: 'name', headerName: "Pharmacy Name", pinned: "left"},
      {field: 'npi', headerName: "NPI", pinned: "left", hide: true, width: 125},
      {
        field: 'nabp_number',
        headerName: "NABP",
        hide: true,
        pinned: "left",
        width: 125
      },
      {
        field: 'address',
        headerName: "Street Address",
        hide: true,
        pinned: "left"
      },
      {
        field: 'city',
        headerName: "City",
        hide: true,
        width: 150,
        pinned: "left"
      },
      {
        field: 'state',
        headerName: "State",
        hide: true,
        width: 100,
        pinned: "left"
      },
      {
        field: 'postal_code',
        headerName: "Zip",
        hide: true,
        width: 125,
        pinned: "left"
      },
      {
        headerName: "Pharmacy Detail",
        valueGetter: expandHereValueGetter,
        cellRenderer: "agGroupCellRenderer",
        pinned: "left", width: 150,
      },
      // { field: '', headerName: "Phone", hide: true },
      // { field: '', headerName: "Fax", hide: true },
    ],
  },
  {
    headerName: "Market",
    children: [
      {
        field: 'trt_rxs',
        headerName: "# TRT Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'trt_brand_rxs',
        headerName: "# Brand TRT Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'trt_generic_rxs',
        headerName: "# Generic TRT Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'tlando_rxs',
        headerName: "# Tlando Rxs",
        filter: "agNumberColumnFilter",
        width: 125
      },
    ],
  },
  {
    headerName: "Patients",
    children: [
      {
        field: 'trt_patients',
        headerName: "# TRT Patients",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'brand_trt_patients',
        headerName: "# Brand TRT Patients",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'generic_trt_patients',
        headerName: "# Generic TRT Patients",
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'tlando_patients',
        headerName: "# Tlando Patients",
        filter: "agNumberColumnFilter",
        width: 125
      },
    ],
  },
  {
    headerName: "Coverage and Dispense Rate",
    children: [
      {
        field: 'dispensed_trt_fills_avg_coverage',
        headerName: "Dispensed TRT Coverage Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'trt_dispense_rate',
        headerName: "TRT Dispense Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'dispensed_trt_fills',
        headerName: '# Dispensed TRT Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'dispensed_brand_trt_fills_avg_coverage',
        headerName: "Dispensed Brand TRT Coverage Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'brand_trt_dispense_rate',
        headerName: "Brand TRT Dispense Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'dispensed_brand_trt_fills',
        headerName: '# Dispensed Brand TRT Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'dispensed_generic_trt_fills_avg_coverage',
        headerName: "Dispensed Generic TRT Coverage Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'generic_trt_dispense_rate',
        headerName: "Generic TRT Dispense Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'dispensed_generic_trt_fills',
        headerName: '# Dispensed Generic TRT Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'dispensed_tlando_fills_avg_coverage',
        headerName: "Dispensed Tlando Coverage Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'tlando_dispense_rate',
        headerName: "Tlando Dispense Rate",
        filter: "agNumberColumnFilter",
        width: 125,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'dispensed_tlando_fills',
        headerName: '# Dispensed Tlando Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 125
      },
      {
        field: 'abandoned_transferred_trt_fills_avg_coverage',
        headerName: "Abandoned/Transferred TRT Coverage Rate",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'abandoned_transferred_trt_fills',
        headerName: '# Abandoned/Transferred TRT Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200
      },
      {
        field: 'abandoned_transferred_brand_trt_fills_avg_coverage',
        headerName: "Abandoned/Transferred Brand TRT Coverage Rate",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'abandoned_transferred_brand_trt_fills',
        headerName: '# Abandoned/Transferred Brand TRT Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200
      },
      {
        field: 'abandoned_transferred_generic_trt_fills_avg_coverage',
        headerName: "Abandoned/Transferred Generic TRT Coverage Rate",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'abandoned_transferred_generic_trt_fills',
        headerName: '# Abandoned/Transferred Generic TRT Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200
      },
      {
        field: 'abandoned_transferred_tlando_fills_avg_coverage',
        headerName: "Abandoned/Transferred Tlando Coverage Rate",
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200,
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0)
      },
      {
        field: 'abandoned_transferred_tlando_fills',
        headerName: '# Abandoned/Transferred Tlando Fills',
        hide: true,
        filter: "agNumberColumnFilter",
        width: 200
      },
    ],
  },
];

export const DETAIL_CELL_RENDERER_PARAMS = getDetailCellRendererParams({
  detailGridOptions: {
    columnDefs: [
      { field: "Prescribed NDC" },
      { field: "Prescribed Drug Name" },
      { field: 'Strength', headerName: "Prescribed Strength" },
      { field: 'Is Brand', headerName: "Prescribed Drug Is Brand" },
      { field: 'Prescribed Rxs', headerName: "# Rxs", sort: 'desc', filter: "agNumberColumnFilter" },
      { field: 'Prescribed Rxs with a Dispense', headerName: "# Rxs w/ a Dispense", filter: "agNumberColumnFilter" },
      { field: 'Percent TRT Rxs For This NDC', headerName: "% TRT Rxs for this NDC", filter: "agNumberColumnFilter",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0) }
    ],
  },
});

export const CSV_HEADERS = [
];
