import dayjs, { Dayjs } from "dayjs";
import isDate from "lodash/isDate";
import isString from "lodash/isString";
import { SHORT_DATE_FORMAT } from "./dates.constants";

export function formatDateToFormat(
  value?: string | Date | Dayjs,
  format = SHORT_DATE_FORMAT
): string | void {
  let date: Dayjs | undefined;

  if (!!value && isString(value)) {
    date = dayjs(value);
  } else if (isDate(value)) {
    date = dayjs(value);
  } else if (dayjs.isDayjs(value)) {
    date = value;
  }

  if (!!date && date.isValid()) {
    const formattedDate = date.format(format ?? SHORT_DATE_FORMAT);
    return formattedDate;
  }
}
