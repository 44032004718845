import { useEffect, useState } from "react";
import {
  ShoppingItem,
  ShoppingItemSupplierOrderItem,
} from "../../useShoppingList";
import {isInventoryId} from "../../../../../utilities/prescriptions/isInventoryId";

export function useRxList(shoppingItems: ShoppingItem[]) {
  const [rxList, setRxList] = useState<ShoppingItemSupplierOrderItem[]>([]);
  const [rxListSortOrder, setRxListSortOrder] = useState("rx");
  const [rxListFilter, setRxListFilter] = useState("");

  useEffect(() => {
    let newRxList: ShoppingItemSupplierOrderItem[] = shoppingItems.flatMap((si) => si.supplierOrder.items);

    if (rxListFilter) {
      newRxList = newRxList.filter((item) => {
        return item.rxNumbers[0].startsWith(rxListFilter);
      });
    }

    newRxList = newRxList.sort((a, b) => {
      const aPrescriptionDetail = a.prescriptionDetails[0];
      const bPrescriptionDetail = b.prescriptionDetails[0];

      if (rxListSortOrder === "rx") {
        const aValue = isInventoryId(a.rxNumbers[0])
          ? 999999
          : Number(a.rxNumbers[0]);
        const bValue = isInventoryId(b.rxNumbers[0])
          ? 999999
          : Number(b.rxNumbers[0]);
        return aValue - bValue;
      }

      if (rxListSortOrder === "patient") {
        const aValue =
          aPrescriptionDetail?.prescription.patient?.shortName ?? "ZZZ Z";
        const bValue =
          bPrescriptionDetail?.prescription.patient?.shortName ?? "ZZZ Z";

        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      }

      // dispensed item, same as SelectDrug
      const aDrug = a.referenceData.drugInfo;
      const bDrug = b.referenceData.drugInfo;

      // ------ Name
      const firstName = aDrug.name.toLowerCase();
      const secondName = bDrug.name.toLowerCase();
      if (firstName < secondName) return -1;
      if (firstName > secondName) return 1;
      return 0;
    });

    setRxList(newRxList);
  }, [shoppingItems, rxListSortOrder, rxListFilter]);

  return {
    rxList,
    rxListFilter,
    rxListSortOrder,
    setRxListFilter,
    setRxListSortOrder,
  };
}
