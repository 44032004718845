import React from "react";
import { TotalPricePaidBreakdowmBarChart } from "./TotalPricePaidBreakdowmBarChart";
import { DailyRevenueBarChart } from "./DailyRevenueBarChart";
import { DailyTransactionBarChart } from "./DailyTransactionBarChart";
import { BigNumber } from "../BigNumber";
import { TransactionBreakdownBarChart } from "./TransactionBreakdownBarChart";
import { DailyPerformanceTable } from "./DailyPerformanceTable/DailyPerformanceTable";
import { WeeklyPerformanceTable } from "./WeeklyPerformanceTable/WeeklyPerformanceTable";
import {
  currencyFormatter,
  formatOrUndefined,
  numberFormatter,
} from "../utils/performanceReportFormatters";
import type { OverviewDataType } from "./overviewReport.types";

export function OverViewReport({ data }: { data: OverviewDataType }) {
  if (!data) return;

  const {
    bigNumbers: { data: bigNumbersData },
    totalPricePaidBreakdownBarChart: { data: totalPricePaidBreakdownData },
    transactionsBreakdownBarCharts: { data: transactionsBreakdownData },
    dailyRevenueTransactionsBarCharts: { data: dailyRevenueTransactionsData },
    dailyPerformanceTable: { data: dailyPerformanceData },
    weeklyPerformanceTable: { data: weeklyPerformanceData },
  } = data;

  const totalTransactions = formatOrUndefined(bigNumbersData?.totalTransactions, numberFormatter);
  const totalPricePaid = formatOrUndefined(bigNumbersData?.totalPricePaid, currencyFormatter);
  const avgDailyPatientPaid = formatOrUndefined(bigNumbersData?.avgDailyPatientPaid, currencyFormatter);
  const avgDailyInsuranceRevenue = formatOrUndefined(bigNumbersData?.avgDailyInsuranceRevenue, currencyFormatter);
  const avgDailyNewRx = formatOrUndefined(bigNumbersData?.avgDailyNewRx, numberFormatter);
  const avgDailyRefillRx = formatOrUndefined(bigNumbersData?.avgDailyRefillRx, numberFormatter);

  return (
    <>
      <div tw="grid grid-cols-2 gap-[15px]">
        <BigNumber
          title="Total Transactions"
          variant="large"
          value={totalTransactions}
        />
        <BigNumber
          title="Total Price Paid"
          subtitle="Insurance & Patient"
          variant="large"
          value={totalPricePaid}
        />
        <TotalPricePaidBreakdowmBarChart data={totalPricePaidBreakdownData} />
        <TransactionBreakdownBarChart data={transactionsBreakdownData} />
        <DailyRevenueBarChart data={dailyRevenueTransactionsData} />
        <DailyTransactionBarChart data={dailyRevenueTransactionsData} />
      </div>
      <div tw="grid grid-cols-4 gap-[15px]">
        <BigNumber
          title="Patient Paid Amount"
          subtitle="DAILY AVERAGE"
          variant="small"
          value={avgDailyPatientPaid}
        />
        <BigNumber
          title="Insurance Revenue"
          subtitle="DAILY AVERAGE"
          variant="small"
          value={avgDailyInsuranceRevenue}
        />
        <BigNumber
          title="New Rx"
          subtitle="DAILY AVERAGE"
          variant="small"
          value={avgDailyNewRx}
        />
        <BigNumber
          title="Refill Rx"
          subtitle="DAILY AVERAGE"
          variant="small"
          value={avgDailyRefillRx}
        />
      </div>
      <div tw="flex-1 h-[500px] mt-4 bg-white">
        <WeeklyPerformanceTable data={weeklyPerformanceData} />
      </div>
      <div tw="flex-1 h-[500px] mt-4 bg-white">
        <DailyPerformanceTable data={dailyPerformanceData} />
      </div>
    </>
  );
}
