import { RequestClient, RequestClientConfig } from "./request/requestClient";

export function getPdfInvoice(
  client: RequestClient,
  {
    fileIds,
    pharmacyId,
    supplierId,
    ...config
  }: RequestClientConfig<{
    fileIds: string[];
    pharmacyId: number;
    supplierId: number;
  }>
) {
  const fileId = fileIds.length === 1 ? fileIds[0] : fileIds;

  return client<Blob>({
    ...config,
    url: "/invoice-pdf",
    responseType: "blob",
    params: { fileId: fileId, pharmacyId, supplierId },
  });
}
