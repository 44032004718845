import { styled } from "twin.macro";
import { Checkbox as CheckboxBase } from "antd";
import { BLACK_1, BLUE_1, GREY_1, GREY_4, WHITE_1 } from "./colors";
import { DEFAULT_TEXT_CSS } from "./typography";

export const CheckboxGroup = CheckboxBase.Group;

export const Checkbox = styled(CheckboxBase)`
  ${DEFAULT_TEXT_CSS}

  &.ant-checkbox-wrapper {
    .ant-checkbox-inner {
      border-radius: 0;
    }

    .ant-checkbox {
      margin-top: -2px;
    }

    &:not(.ant-checkbox-wrapper-disabled) {
      .ant-checkbox-inner {
        border-color: ${BLACK_1};
        background-color: ${WHITE_1};

        &:after {
          border-color: ${BLACK_1};
        }
      }

      &:hover {
        .ant-checkbox-inner {
          border-color: ${BLUE_1};
          background-color: ${GREY_4};

          &:after {
            border-color: ${GREY_1};
          }
        }

        .ant-checkbox-checked:not(.ant-checkbox-disabled) {
          .ant-checkbox-inner {
            background-color: ${WHITE_1};
            border-color: ${BLUE_1};
          }
        }
      }
    }
  }
`;
