import React, { useContext, useMemo } from "react";
import "twin.macro";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import EnhancedClientConfigContext from "../../../enhanced/EnhancedClientConfigContext";
import { ParsedSearchTypeEnum, parseSearch } from "../../../utilities/search";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { NDCInput } from "../../../components/NDCInput";
import { IconSave } from "../../../components/rxLibrary/icons";
import { isWindowsOS } from "../../../utilities/isWindowsOS";
import { DOWNLOAD_APP_INSTALLER_URL } from "../../../utilities/config";
import { Card } from "./Card";

export function NdcLookupCard() {
  const { user } = useContext(AppContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const form = useForm();
  const history = useHistory();
  const disabled = !enhancedClientActive && !user?.is_admin;
  const isWindows = useMemo(() => isWindowsOS(), []);

  const onSubmit = form.handleSubmit(({ ndc }: { ndc: string }) => {
    const parsedSearch = parseSearch(ndc);
    const path =
      parsedSearch.type === ParsedSearchTypeEnum.NDC
        ? "compare?ndc"
        : "selectDrug?search";
    console.log("history push", `/${path}=${parsedSearch.value}`);
    history.push(`/${path}=${parsedSearch.value}`);
  });

  return (
    <Card>
      <div tw="flex flex-col items-center">
        <img tw="mb-[28px]" src="/assets/pages/home/ndc-lookup.svg" />

        <Text tw="mb-[18px]" weight="bold" size={21}>
          PRICE CHECK
        </Text>

        {disabled ? (
          <>
            <Text tw="w-[295px] mx-auto mb-[24px]" size="medium" center>
              {isWindows
                ? "Download the DaylightRx app for real-time price checks."
                : "Download the DaylightRx app on a Windows computer for real-time price checks."}
            </Text>

            {isWindows && (
              <a href={DOWNLOAD_APP_INSTALLER_URL} download>
                <Button icon={<IconSave />}>Download App</Button>
              </a>
            )}
          </>
        ) : (
          <FormProvider {...form}>
            <form
              tw="flex flex-col items-center w-full space-y-[21px]"
              onSubmit={onSubmit}
            >
              <NDCInput name="ndc" required />
              <Button type="submit">See Best Prices</Button>
            </form>
          </FormProvider>
        )}
      </div>
    </Card>
  );
}
