
import React, { useMemo } from "react";
import "twin.macro";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { ManufacturerReportComponent } from "../Manufacturer.constants";
import { ManufacturerReportError } from "./ManufacturerReportError";
import { useReportRequest } from "./useReportRequest";

export function ManufacturerReportChart({
  reportId,
  startDate,
  endDate,
  manufacturerId,
  Report,
}: {
  reportId: number;
  startDate: string;
  endDate: string;
  manufacturerId: number;
  Report: ManufacturerReportComponent;
}) {
  const config = useMemo(() => {
    return { reportId, manufacturerId, startDate, endDate };
  }, [reportId, manufacturerId, startDate, endDate]);

  const { isLoading, error, data: reportData } = useReportRequest(config);

  return (
    <>
      {!!reportData && <Report data={reportData} />}
      {!!error && <ManufacturerReportError />}
      {isLoading && <FullPageLoader />}
    </>
  );
}
