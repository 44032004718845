import React from "react";
import { styled } from "twin.macro";
import { ShoppingBarCancelModalBtn } from "./ShoppingBarCancelModalBtn";
import { ShoppingBarDoneModalBtn } from "./ShoppingBarDoneModalBtn";
import { WideContainer } from "../../../../components/containers/WideContainer";
import { Title } from "../../../../components/rxLibrary/typography";
import {ShoppingBarCloseModalBtn} from "./ShoppingBarCloseModalBtn";

const Wrapper = styled.div`
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.27);
`;

export function ShoppingBar({useCase}: {useCase: "newlyCreated" | "previouslyCreated"}) {
  return (
    <Wrapper tw="bg-white sticky top-0 z-50">
      <WideContainer tw="flex justify-between items-center relative h-[96px]">
        <Title block>Optimized Shopping Lists</Title>

        <div tw="flex items-center space-x-3">
          {useCase === "newlyCreated" ?
          <>
            <ShoppingBarCancelModalBtn />
            <ShoppingBarDoneModalBtn />
          </> : <ShoppingBarCloseModalBtn />}
        </div>
      </WideContainer>
    </Wrapper>
  );
}
