import { css, styled } from "twin.macro";
import { RxColor, getColorCss } from "../colors";
import IconCheckSvg from "./svg/icon-check.svg";
import Icon3DotsSvg from "./svg/icon-3-dots.svg";
import IconDownSvg from "./svg/icon-down.svg";
import IconFilterSvg from "./svg/icon-filter.svg";
import IconHomeSvg from "./svg/icon-home.svg";
import IconMagnifySvg from "./svg/icon-magnify.svg";
import IconNoteSvg from "./svg/icon-note.svg";
import IconPlusSvg from "./svg/icon-plus.svg";
import IconRefreshSvg from "./svg/icon-refresh.svg";
import IconSortAscendSvg from "./svg/icon-sort-ascend.svg";
import IconSortDescendSvg from "./svg/icon-sort-descend.svg";
import IconXSvg from "./svg/icon-x.svg";
import IconClockSvg from "./svg/icon-clock.svg";
import IconBellSvg from "./svg/icon-bell.svg";
import IconEyeSvg from "./svg/icon-eye.svg";
import IconEyeShutSvg from "./svg/icon-eye-shut.svg";
import IconArrowLeftSvg from "./svg/icon-arrow-left.svg";
import IconTrashSvg from "./svg/icon-trash.svg";
import IconPrintSvg from "./svg/icon-print.svg";
import IconSnowflakeSvg from "./svg/icon-snowflake.svg";
import IconSaveSvg from "./svg/icon-save.svg";
import IconFoamSvg from "./svg/icon-foam.svg";
import IconGearSvg from "./svg/icon-gear.svg";
import IconCalendarSvg from "./svg/icon-calendar.svg";
import IconQuestionSvg from "./svg/icon-question.svg";
import IconCopySvg from "./svg/icon-copy.svg";
import IconDollarSvg from "./svg/icon-dollar.svg";

const SvgStyled = styled.svg(({ color }: { color?: RxColor }) => [
  css`
    path,
    circle,
    rect,
    polygon,
    ellipse,
    text {
      fill: currentColor;
    }
  `,
  !!color && getColorCss(color),
]);

export function styleSvgIcon(component: any) {
  return SvgStyled.withComponent(component);
}

export type Icon = typeof SvgStyled;

export const IconCheck = styleSvgIcon(IconCheckSvg);
export const IconDown = styleSvgIcon(IconDownSvg);
export const IconPlus = styleSvgIcon(IconPlusSvg);
export const Icon3Dots = styleSvgIcon(Icon3DotsSvg);
export const IconX = styleSvgIcon(IconXSvg);
export const IconRefresh = styleSvgIcon(IconRefreshSvg);
export const IconFilter = styleSvgIcon(IconFilterSvg);
export const IconNote = styleSvgIcon(IconNoteSvg);
export const IconSortAscend = styleSvgIcon(IconSortAscendSvg);
export const IconSortDescend = styleSvgIcon(IconSortDescendSvg);
export const IconMagnify = styleSvgIcon(IconMagnifySvg);
export const IconClock = styleSvgIcon(IconClockSvg);
export const IconBell = styleSvgIcon(IconBellSvg);
export const IconEye = styleSvgIcon(IconEyeSvg);
export const IconEyeShut = styleSvgIcon(IconEyeShutSvg);
export const IconArrowLeft = styleSvgIcon(IconArrowLeftSvg);
export const IconTrash = styleSvgIcon(IconTrashSvg);
export const IconPrint = styleSvgIcon(IconPrintSvg);
export const IconHome = styleSvgIcon(IconHomeSvg);
export const IconSnowflake = styleSvgIcon(IconSnowflakeSvg);
export const IconSave = styleSvgIcon(IconSaveSvg);
export const IconCopy = styleSvgIcon(IconCopySvg);
export const IconFoam = styleSvgIcon(IconFoamSvg);
export const IconGear = styleSvgIcon(IconGearSvg);
export const IconQuestion = styleSvgIcon(IconQuestionSvg);
export const IconDollar = styleSvgIcon(IconDollarSvg);
export const IconCalendar = styleSvgIcon(IconCalendarSvg);
