import React, {createContext, useEffect, useState} from "react";

export type SleepMode = "asleep" | "awake" | "waking";
type EnhancedClientInactivityContext = {
  appSleepMode: SleepMode,
  updateShutdownWarnDate: Date | null,
};

const EnhancedClientInactivityContext = createContext<EnhancedClientInactivityContext>({
  appSleepMode: "awake",
  updateShutdownWarnDate: null,
});

export default EnhancedClientInactivityContext;

type EnhancedClientInactivityContextProviderProviderProps = {
    children?: React.ReactNode;
};

export function EnhancedClientInactivityContextProvider({
                                       children,
                                   }: EnhancedClientInactivityContextProviderProviderProps): JSX.Element {
    const [appSleepMode, setAppSleepMode] = useState<SleepMode>("awake");
    const [updateShutdownWarnDate, setUpdateShutdownWarnDate] = useState<Date | null>(null);

    useEffect(() => {
            const messageListenerCallback = async (event: MessageEvent) => {
                if (event.source !== window) {
                    return;
                }
                if (event.data.channel === "daylightrxenhanced") {
                  //TODO remove deprecated lock/unlock screen
                  if (event.data.msgType === "lockScreen") {
                      console.log("enhancedClientContext:lockingScreen", event.data);
                      setAppSleepMode("asleep");
                  }
                  if (event.data.msgType === "unlockScreen") {
                      console.log("enhancedClientContext:unlockingScreen", event.data);
                      setAppSleepMode("awake");
                  }

                  if (event.data.msgType === "warnUpdateShutdown") {
                    console.log("enhancedClientContext:warnUpdateShutdown", window.location.href);
                    setUpdateShutdownWarnDate((prevDate) => {
                      if (prevDate === null) {
                        return new Date();
                      }
                      return prevDate;
                    });
                  }
                  if (event.data.msgType === "cancelUpdateShutdown") {
                    setUpdateShutdownWarnDate(null);
                  }
                  if (event.data.msgType === "requestExecuteUpdateShutdown") {
                    console.log("enhancedClientContext:requestExecuteUpdateShutdown", updateShutdownWarnDate);
                    if (updateShutdownWarnDate === null) {
                      console.log("enhancedClientContext:requestExecuteUpdateShutdown:rejected");
                      window.postMessage({
                        channel: "daylightrxenhanced",
                        sender: "web",
                        msgType: "updateShutdownRejected",
                        data: {},
                      })
                    } else {
                      console.log("enhancedClientContext:requestExecuteUpdateShutdown:accepted");
                      window.postMessage({
                        channel: "daylightrxenhanced",
                        sender: "web",
                        msgType: "updateShutdownAccepted",
                        data: {},
                      })
                    }
                  }
                }
              if (event.data.msgType === "sleepApp") {
                console.log("enhancedClientContext:sleepApp", event.data);
                setAppSleepMode("asleep");
              }
              if (event.data.msgType === "wakeApp") {
                console.log("enhancedClientContext:wakeApp", event.data);
                setAppSleepMode("waking");
                setTimeout(() => {
                  setAppSleepMode("awake");
                }, 20000);
              }

            };
            window.addEventListener("message", messageListenerCallback);

            return () => {
                window.removeEventListener("message", messageListenerCallback);
            }
    }, [updateShutdownWarnDate])

    console.log("Rendering EnhancedClientInactivityContext");
    return (
        <EnhancedClientInactivityContext.Provider
            value={{
                appSleepMode,
                updateShutdownWarnDate,
            }}
        >
            {children}
        </EnhancedClientInactivityContext.Provider>
    );
}
