
export class NotCommonNdcSearchError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = message ?? `The NDC is not a common NDC`;
  }
}

export class TextSearchError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = message ?? "Error searching by text";
  }
}
