import {
  BackendResponse,
  backendFetch,
  backendPost,
} from "./legacy/config/backend-api";
import type {
  AdminPharmacyFull,
  AdminPharmacyOptions,
  DrugInfo,
  Pharmacy,
  PharmacyRefillItem,
  UpsertAdminPharmacy,
} from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getPharmacies(token: string) {
  return backendFetch<{ pharmacies: Pharmacy[] }>("/pharmacies", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacy(token: string, pharmacyId: string) {
  const response = await backendFetch<{ pharmacy: AdminPharmacyFull }>(
    `/admin/pharmacy/${pharmacyId}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (response.error) {
    console.error("Error fetching admin pharmacy", response);
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacies(token: string) {
  const response = await backendFetch<{ pharmacies: AdminPharmacyFull[] }>(
    "/admin/pharmacy",
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );

  if (response.error) {
    console.error("Error fetching admin pharmacies", response);
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacyOptions(token: string) {
  const response = await backendFetch<{
    pharmacyOptions: AdminPharmacyOptions;
  }>("/admin/pharmacy/options", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin pharmacy options", response);
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function createAdminPharmacy(
  token: string,
  data: UpsertAdminPharmacy
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost("/admin/pharmacy", payload, {
    headers: { Authorization: "Bearer " + token },
  });
  const response = (await responseRaw.json()) as BackendResponse<{
    pharmacy: AdminPharmacyFull;
  }>;

  if (response.error) {
    console.error(
      "Error creating admin pharmacy",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateAdminPharmacy(
  token: string,
  pharmacyId: string,
  data: UpsertAdminPharmacy
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy/${pharmacyId}`,
    payload,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    pharmacy: AdminPharmacyFull;
  }>;

  if (response.error) {
    console.error(
      "Error updating admin pharmacy",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

export async function getPharmacyRefillItemsDrugs(
  client: RequestClient,
  {
    supplierId,
    ...config
  }: RequestClientConfig<{
    supplierId: number;
  }>
) {
  return client<{ drugs: DrugInfo[] }>({
    ...config,
    url: `/pharmacy/refill-item/drug/${supplierId}`,
  });
}

export async function getPharmacyRefillItems(
  client: RequestClient,
  { pharmacyId, ...config }: RequestClientConfig<{ pharmacyId: number }>
) {
  return client<{
    refillItemsBySupplierId: Record<string, PharmacyRefillItem[]>;
  }>({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
  });
}

export async function createPharmacyRefillItem(
  client: RequestClient,
  {
    ndc,
    cost,
    supplierId,
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    ndc: string;
    cost: number;
    pharmacyId: number;
    supplierId: number;
  }>
) {
  return client({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
    method: "POST",
    data: { ndc, cost, supplierId },
  });
}

export async function updatePharmacyRefillItem(
  client: RequestClient,
  {
    ndc,
    cost,
    supplierId,
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    ndc: string;
    cost: number;
    pharmacyId: number;
    supplierId: number;
  }>
) {
  return client({
    ...config,
    url: `/pharmacy/refill-item/${pharmacyId}`,
    method: "PUT",
    data: { ndc, cost, supplierId },
  });
}
