import "twin.macro";
import { Form, Input } from "antd";
import type { Rule } from "antd/es/form";
import { CustomerAccountIdInputLabel } from "./AccountIdInputLabel";
import { useSupplierMappingCustomerAccountIds } from "./useSupplierMappingCustomerAccountIds";
import { useCustomerAccountIdLengthFormRule } from "./useCustomerAccountIdLengthRules";

export function CustomerAccountInput({
  name,
  rules = [],
  supplierId,
  supplierName,
}: {
  name: string;
  rules?: Rule[];
  supplierId?: number;
  supplierName?: string;
}) {
  const supplierMappingCustomerAccountIdsResponseData =
    useSupplierMappingCustomerAccountIds(supplierId);

  const customerAccountIdLengthFormRule = useCustomerAccountIdLengthFormRule(
    supplierMappingCustomerAccountIdsResponseData?.data
  );

  return (
    <Form.Item
      name={name}
      label={
        <CustomerAccountIdInputLabel
          supplierName={supplierName}
          supplierMappingCustomerAccountIdsResponseData={
            supplierMappingCustomerAccountIdsResponseData
          }
        />
      }
      tooltip="The supplier's account number for the pharmacy"
      rules={[
        ...rules,
        {
          pattern: /^[^\s]+$/gi,
          message: "${label} should not contain white spaces",
        },
        ...customerAccountIdLengthFormRule,
      ]}
    >
      <Input allowClear />
    </Form.Item>
  );
}
