import React, { useMemo } from "react";
import { PrescriberInfo } from "../../../../../utilities/types";
import { formatPrescriberName } from "../../../../../utilities/prescriptions/formatPrescriberName";
import { formatDateStrToNumericDate } from "../../../../../utilities/dates/formatDateStrToNumericDate";
import { Text } from "../../../../../components/rxLibrary/typography";

export function ShoppingListColumnPrescriber({
  fillDate,
  prescriber,
}: {
  fillDate: string;
  prescriber?: PrescriberInfo;
}) {
  const formattedValues = useMemo(() => {
    if (prescriber)
      return [
        formatPrescriberName(prescriber),
        formatDateStrToNumericDate(fillDate),
      ];
  }, [prescriber, fillDate]);

  if (!formattedValues) {
    return null;
  }

  const [numericDate, prescriberName] = formattedValues;
  return (
    <>
      <Text weight="bold" block>
        {numericDate || ''}
      </Text>
      <Text block>{prescriberName || ''}</Text>
    </>
  );
}
