import React, { useCallback } from "react";
import { useHistory } from "react-router";
import {
  DrugSearch,
  IHandleDrugClick,
  IHandleSearchNdc,
  IHandleSearchResults,
  useDrugSearch,
} from "../components/DrugSearch/DrugSearch";
import { Suppliers } from "../components/suppliers/Suppliers";

export function SelectDrug() {
  const history = useHistory();

  const handleSearchResults: IHandleSearchResults = useCallback(
    ({ searchResults }) => {
      if (searchResults.length === 1) {
        const searchResult = searchResults[0];
        if (searchResult.isAvailableInCatalog) {
          const ndc = searchResult.representativeDrugInfo.ndc;
          history.push(`/compare?ndc=${ndc}`);
          return true;
        }
      }
      return false;
    },
    []
  );

  const handleSearchNdc: IHandleSearchNdc = useCallback((ndc) => {
    history.push(`/compare?ndc=${ndc}`);
    return true;
  }, []);

  const handleDrugClick: IHandleDrugClick = useCallback(
    ({ drug, isSizeOption }) => {
      const { ndc, unitSize, name, genericProductIdentifier } = drug;
      const queryParams: Record<string, string> = {
        ndc,
        generic_product_identifier: genericProductIdentifier,
        drug_name: name,
        from: "selectDrug",
      };

      if (isSizeOption && isFinite(unitSize)) {
        queryParams.unit_size = unitSize.toString();
      }

      history.push({
        pathname: "/compare",
        search: new URLSearchParams(queryParams).toString(),
      });
    },
    []
  );

  const drugSearch = useDrugSearch({ handleSearchResults, handleSearchNdc });
  const { drugSearchValues, drugSearchResults, drugSearchResultsState } =
    drugSearch;

  return (
    <>
      <Suppliers />
      <DrugSearch
        drugSearchValues={drugSearchValues}
        drugSearchResults={drugSearchResults}
        drugSearchResultsState={drugSearchResultsState}
        handleDrugClick={handleDrugClick}
      />
    </>
  );
}
