import { css } from "twin.macro";

export const BLUE_1 = "#324DC7" as const;
export const BLUE_2 = "#0A1E79" as const;
export const BLUE_3 = "#B2F5ED" as const;
export const BLUE_4 = "#D0E9FF" as const;
export const BLUE_5 = "#E8F4FF" as const;
export const BLACK_1 = "#202020" as const;
export const BLACK_2 = "#000000" as const;
export const GREY_1 = "#726C66" as const;
export const GREY_2 = "#C7C7C7" as const;
export const GREY_3 = "#E8E6E6" as const;
export const GREY_4 = "#F5F5F5" as const;
export const GREY_5 = "#F1F4FB" as const;
export const GREEN_1 = "#49A900" as const;
export const GREEN_2 = "#D0EE1F" as const;
export const GREEN_3 = "#DBEECC" as const;
export const GREEN_4 = "#6A8575" as const;
export const GREEN_5 = "#259452" as const;
export const ORANGE_1 = "#FF6600" as const;
export const ORANGE_2 = "#FFA604" as const;
export const RED_1 = "#C73232" as const;
export const WHITE_1 = "#FFFFFF" as const;
export const YELLOW_1 = "#FFC009" as const;
export const YELLOW_2 = "#FFE01C" as const;
export const YELLOW_3 = "#FFF199" as const;

export const COLORS = {
  "blue-1": BLUE_1,
  "blue-2": BLUE_2,
  "blue-3": BLUE_3,
  "blue-4": BLUE_4,
  "blue-5": BLUE_5,
  "black-1": BLACK_1,
  "black-2": BLACK_2,
  "grey-1": GREY_1,
  "grey-2": GREY_2,
  "grey-3": GREY_3,
  "grey-4": GREY_4,
  "grey-5": GREY_5,
  "green-1": GREEN_1,
  "green-2": GREEN_2,
  "green-3": GREEN_3,
  "green-4": GREEN_4,
  "green-5": GREEN_5,
  "orange-1": ORANGE_1,
  "orange-2": ORANGE_2,
  "red-1": RED_1,
  "white-1": WHITE_1,
  "yellow-1": YELLOW_1,
  "yellow-2": YELLOW_2,
  "yellow-3": YELLOW_3,
} as const;

export type RxColor = keyof typeof COLORS;
export type RxColorOpacity = keyof typeof HEX_COLOR_OPACITIES;

export function getColorCss(color?: RxColor) {
  const colorCode = color ? COLORS[color] : undefined;
  return css`
    color: ${colorCode ?? BLACK_1};
  `;
}

export function getBorderColorCss(color?: RxColor) {
  const colorCode = color ? COLORS[color] : undefined;
  if (!colorCode) return;
  return css`
    border-color: ${colorCode};
  `;
}

export function getOutlineColorCss(color?: RxColor) {
  const colorCode = color ? COLORS[color] : undefined;
  if (!colorCode) return;
  return css`
    outline-color: ${colorCode};
  `;
}

export function getBackgroundColorCss(
  color?: RxColor,
  colorOpacity?: RxColorOpacity
) {
  const colorCode = color ? COLORS[color] : undefined;
  if (!colorCode) return;

  const opacity =
    colorOpacity === undefined ? undefined : HEX_COLOR_OPACITIES[colorOpacity];
  return css`
    background-color: ${colorCode}${opacity ?? ""};
  `;
}

// Opacity suffixes for hexadecimal colors from 0% to 100%, ref: https://davidwalsh.name/hex-opacity
const HEX_COLOR_OPACITIES = {
  100: "FF",
  99: "FC",
  98: "FA",
  97: "F7",
  96: "F5",
  95: "F2",
  94: "F0",
  93: "ED",
  92: "EB",
  91: "E8",
  90: "E6",
  89: "E3",
  88: "E0",
  87: "DE",
  86: "DB",
  85: "D9",
  84: "D6",
  83: "D4",
  82: "D1",
  81: "CF",
  80: "CC",
  79: "C9",
  78: "C7",
  77: "C4",
  76: "C2",
  75: "BF",
  74: "BD",
  73: "BA",
  72: "B8",
  71: "B5",
  70: "B3",
  69: "B0",
  68: "AD",
  67: "AB",
  66: "A8",
  65: "A6",
  64: "A3",
  63: "A1",
  62: "9E",
  61: "9C",
  60: "99",
  59: "96",
  58: "94",
  57: "91",
  56: "8F",
  55: "8C",
  54: "8A",
  53: "87",
  52: "85",
  51: "82",
  50: "80",
  49: "7D",
  48: "7A",
  47: "78",
  46: "75",
  45: "73",
  44: "70",
  43: "6E",
  42: "6B",
  41: "69",
  40: "66",
  39: "63",
  38: "61",
  37: "5E",
  36: "5C",
  35: "59",
  34: "57",
  33: "54",
  32: "52",
  31: "4F",
  30: "4D",
  29: "4A",
  28: "47",
  27: "45",
  26: "42",
  25: "40",
  24: "3D",
  23: "3B",
  22: "38",
  21: "36",
  20: "33",
  19: "30",
  18: "2E",
  17: "2B",
  16: "29",
  15: "26",
  14: "24",
  13: "21",
  12: "1F",
  11: "1C",
  10: "1A",
  9: "17",
  8: "14",
  7: "12",
  6: "0F",
  5: "0D",
  4: "0A",
  3: "08",
  2: "05",
  1: "03",
  0: "00",
} as const;
