import tw from "twin.macro";
import { Link } from "react-router-dom";
import type { NavLinkRoute } from "../../../../utilities/routing/routing.constants";
import { Box } from "../../../../components/rxLibrary/box";
import { Text } from "../../../../components/rxLibrary/typography";
import { BUTTON_SHADOW_CSS } from "../../../../components/rxLibrary/buttons";
import { SuppliersAndPasswordsCard } from "../SuppliersAndPasswordsCard";
import { Card } from "../Card";
import { MarketingAd } from "./MarketingAd";

export function ActionCards({
  routes,
  isManufacturerOnly,
}: {
  routes: NavLinkRoute[];
  isManufacturerOnly: boolean;
}) {
  if (!isManufacturerOnly && routes.length === 1) {
    const route = routes[0];
    if (route.to === "/supplierAccounts") return <SuppliersAndPasswordsCard />;
  }

  return (
    <Card>
      <div tw="flex flex-col h-full w-full space-y-[10px]">
        {routes.map(({ text, to, image }) => {
          const isPreviousShoppingLists = to === "/previousShoppingLists";

          return (
            <Link to={to} key={to} tw="flex-1">
              <Box
                tw="flex items-center rounded-lg h-full py-[6px] pr-[11px] pl-[20px] space-x-[14px]"
                css={[
                  BUTTON_SHADOW_CSS,
                  isPreviousShoppingLists && tw`pl-[10px]`,
                ]}
                bgColor="grey-5"
              >
                <img src={image} />
                <Text weight="bold">{text}</Text>
              </Box>
            </Link>
          );
        })}

        {isManufacturerOnly && <MarketingAd />}
      </div>
    </Card>
  );
}
