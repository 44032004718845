import type { PageAlert } from "../utilities/types";

export async function getPageVersion() {
  const ms = Date.now();
  const url = `/version.txt?t=${ms}`;
  const response = await fetch(url, { cache: "no-store" });
  const version = (await response.text()).trim();

  return version;
}

export async function getPageAlerts() {
  const ms = Date.now();
  const url = `/alerts.json?t=${ms}`;
  const response = await fetch(url, { cache: "no-store" });
  const alerts: PageAlert[] = await response.json();
  return alerts;
}