import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
  percentFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    field: "controlledSubstanceCode",
    headerName: "Controlled Substance Code",
    pinned: "left",
    flex: 1
  },
  {
    field: "percentRevenue",
    headerName: "% of Revenue",
    valueFormatter: (params) => percentFormatter(params.value, 2),
    flex: 1,
    minWidth: 100
  },
  {
    field: "percentDispenses",
    headerName: "% of Dispenses",
    valueFormatter: (params) => percentFormatter(params.value, 2),
    flex: 1,
    minWidth: 100
  },
  {
    field: "percentDosageUnits",
    headerName: "% of Dosage Units",
    valueFormatter: (params) => percentFormatter(params.value, 2),
    flex: 1,
    minWidth: 100
  },
  {
    field: "revenue",
    headerName: "Revenue",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "dispenses",
    headerName: "Dispenses",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "dosageUnits",
    headerName: "Dosage Units",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
];
