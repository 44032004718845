import React, { ReactNode } from "react";
import { css } from "twin.macro";
import EyeInvisibleOutlined from "@ant-design/icons/EyeInvisibleOutlined";
import { Row } from "../../../../components/rxLibrary/grid";
import { ShoppingListCol } from "./columns/ShoppingListCol";
import { Text } from "../../../../components/rxLibrary/typography";
import { HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS } from "../useShoppingListItems/useShoppingListItems.constants";

const HIDE_ANIMATION_CSS = css`
  @keyframes row-animation {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  animation-name: row-animation;
  animation-duration: ${HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS}ms;
`;

export function ShoppingListRow({
  visible,
  filtered,
  children,
  prescriptionId,
  renderWrapper,
}: {
  visible: boolean;
  filtered: boolean;
  children: ReactNode;
  prescriptionId: string;
  renderWrapper: ({
    content,
    prescriptionId,
  }: {
    content: ReactNode;
    prescriptionId: string;
  }) => JSX.Element;
}) {
  if (!visible) {
    return null;
  }

  if (filtered) {
    return (
      <Row css={HIDE_ANIMATION_CSS}>
        <ShoppingListCol tw="flex-1">
          <Text
            weight="bold"
            size="medium"
            tw="flex items-center justify-center h-full space-x-1"
          >
            <EyeInvisibleOutlined rev={undefined} />
            <span>Filtering...</span>
          </Text>
        </ShoppingListCol>
      </Row>
    );
  }

  return renderWrapper({ content: <Row>{children}</Row>, prescriptionId });
}
