import { ReactNode } from "react";
import { Global, css } from "@emotion/react";

const PERFORMANCE_REPORT_CLASS_NAME = "rx-report";

const GLOBAL_CSS = css`
  .${PERFORMANCE_REPORT_CLASS_NAME} {
    .chart-tooltip {
      .ag-chart-tooltip-title {
        font-family: "Archivo Narrow Variable", sans-serif;
        font-size: 16px;
      }
      .ag-chart-tooltip-content {
        font-family: "Archivo Narrow Variable", sans-serif;
        font-size: 14px;
      }
    }

    .chart-overlays {
      font-family: "Archivo Narrow Variable", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`;

export function ReportWrapper({ children }: { children: ReactNode }) {
  return (
    <div tw="h-full overflow-y-auto" className={PERFORMANCE_REPORT_CLASS_NAME}>
      {children}
      <Global styles={GLOBAL_CSS} />
    </div>
  );
}
