import {
  useState,
  useEffect,
  ReactNode,
  useContext,
  createContext,
  useMemo,
  useRef,
  useCallback,
} from "react";
import sortBy from "lodash/sortBy";
import isEqual from "lodash/isEqual";
import type { ItemInCart } from "../../../../utilities/types";
import { sorterByDrugName } from "../../../../utilities/prescriptions/sorters/sorterByDrugName";
import { sorterByAddedAt } from "../../../../utilities/prescriptions/sorters/sorterByAddedAt";
import { formatItemPurchaseDetails } from "../../../../utilities/prescriptions/formatItem";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { useShoppingCartServerState } from "../../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { SortDirectionEnum } from "../../../../components/rxLibrary/selects/SortBySelect";
import { useStorageUpdater } from "../../../../contexts/shoppingContexts/StorageContext";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { sequenceActions } from "../ShoppingRx.utils";
import { useSimilarItems } from "./useShoppingRx.useSimilarItems";
import { useSelectedItemId } from "./useShoppingRx.useSelectedItemId";

const ITEM_NAME_SORT_OPTION = {
  value: "itemName",
  label: "Item Name",
  sorter: sorterByDrugName,
};

const ITEM_ADDED_AT_SORT_OPTION = {
  value: "addedAt",
  label: "Date/Time Added",
  sorter: sorterByAddedAt,
};

const DEFAULT_SORT_OPTION = ITEM_ADDED_AT_SORT_OPTION;

export const SHOPPING_RX_SORT_OPTIONS = [
  ITEM_ADDED_AT_SORT_OPTION,
  ITEM_NAME_SORT_OPTION,
  { value: "purchaseBy", label: "Purchase By Date", sorter: sorterByAddedAt },
];

function sortItems(
  items: ItemInCart[],
  sort: typeof SHOPPING_RX_SORT_OPTIONS[number],
  sortDirection: SortDirectionEnum
) {
  const { value, sorter } = sort;

  const sorters = [sorter ?? value];

  if (value !== ITEM_NAME_SORT_OPTION.value) {
    // sort by item name if not already sorted by item name
    sorters.push(ITEM_NAME_SORT_OPTION.sorter);
  }
  // sort by added to avoid random order
  if (value !== ITEM_ADDED_AT_SORT_OPTION.value) {
    sorters.push(ITEM_ADDED_AT_SORT_OPTION.sorter);
  }

  const newItems = sortBy(items, sorters);
  if (sortDirection === SortDirectionEnum.DESC) {
    newItems.reverse();
  }

  return newItems;
}

const ShoppingRxContext = createContext<
  ReturnType<typeof useShoppingRxContext> | undefined
>(undefined);

function useShoppingRxContext() {
  const cancelSequenceRef = useRef<() => void>();
  const [sort, setSort] = useState(DEFAULT_SORT_OPTION.value);
  const [sortDirection, setSortDirection] = useState(SortDirectionEnum.DESC);
  const [inventoryItems, setInventoryItems] = useState<ItemInCart[]>([]);
  const [prescriptionsItems, setPrescriptionsItems] = useState<ItemInCart[]>(
    []
  );

  const { inventory } = useShoppingCartServerState();
  const { prescriptionItemsInCart } = useShoppingState();
  const { addInventoryItem } = useShoppingUpdater();
  const { setWaitButtonMode } = useStorageUpdater();
  const { addItemPurchaseDetailsList } = useShoppingUpdater();
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { similarItems, removeSimilarItem, addPrescriptionsToSimilarItems } =
    useSimilarItems({
      inventoryItems,
      prescriptionsItems,
    });
  const { selectedItemId, selectItemId } = useSelectedItemId();

  const [highlightType, setHighlightType] = useState<
    "inventory" | "prescription"
  >();
  const [itemsAddedBanner, setItemsAddedBanner] =
    useState<["inventory" | "prescription", number]>();

  const highlightInventory = "inventory" === highlightType;
  const highlightPrescription = "prescription" === highlightType;

  const selectedSort = useMemo(() => {
    return (
      SHOPPING_RX_SORT_OPTIONS.find((o) => o.value === sort) ??
      DEFAULT_SORT_OPTION
    );
  }, [sort]);

  const resetSequence = useCallback(() => {
    cancelSequenceRef?.current?.();
    cancelSequenceRef.current = undefined;
    setHighlightType(undefined);
    setItemsAddedBanner(undefined);
  }, []);

  const handleAddInventoryItem = useCallback(
    (item: ItemInCart) => {
      cancelSequenceRef.current = sequenceActions([
        [resetSequence],
        [highlightInventory],
        [addToInventory, 500],
        [updateItemsAddedBanner, 2000],
        [resetSequence, 5000],
      ]);

      function highlightInventory() {
        setHighlightType("inventory");
      }

      function addToInventory() {
        addInventoryItem(item);
        setWaitButtonMode(false);
      }

      function updateItemsAddedBanner() {
        setItemsAddedBanner(["inventory", 1]);
      }
    },
    [resetSequence, addInventoryItem, setWaitButtonMode]
  );

  const addPrescriptionsToCart = useCallback(
    (newPrescriptions: ItemInCart[]) => {
      cancelSequenceRef.current = sequenceActions([
        [resetSequence],
        [highlightPrescriptions],
        [addToCart, 500],
        [updateItemsAddedBanner, 1000],
        [updateSimilarItems, 1000],
        [resetSequence, 5000],
      ]);

      function highlightPrescriptions() {
        setHighlightType("prescription");
      }

      function addToCart() {
        const itemPurchaseDetailsList = newPrescriptions.map(
          formatItemPurchaseDetails
        );
        addItemPurchaseDetailsList(itemPurchaseDetailsList);
      }

      function updateItemsAddedBanner() {
        const counter = newPrescriptions.length;
        setItemsAddedBanner(["prescription", counter]);
      }

      function updateSimilarItems() {
        addPrescriptionsToSimilarItems(newPrescriptions);
      }
    },
    [resetSequence, addItemPurchaseDetailsList, addPrescriptionsToSimilarItems]
  );

  useEffect(() => {
    const filteredItems = inventory.filter((item) => {
      return item.status === "list";
    });

    const newInventoryItems = sortItems(
      filteredItems,
      selectedSort,
      sortDirection
    );
    if (isEqual(inventoryItems, newInventoryItems)) return;

    setInventoryItems(newInventoryItems);
  }, [sortDirection, selectedSort, inventory]);

  useEffect(() => {
    const filteredItems = prescriptionItemsInCart.filter((item) => {
      return item.status === "list";
    });

    const newPrescriptionsItems = sortItems(
      filteredItems,
      selectedSort,
      sortDirection
    );
    if (isEqual(prescriptionsItems, newPrescriptionsItems)) return;

    setPrescriptionsItems(newPrescriptionsItems);
  }, [sortDirection, selectedSort, prescriptionItemsInCart]);

  useEffect(() => {
    resetSequence();
    return cancelSequenceRef?.current;
  }, [pharmacyId]);

  return {
    sort,
    similarItems,
    sortDirection,
    inventoryItems,
    selectedItemId,
    itemsAddedBanner,
    prescriptionsItems,
    highlightInventory,
    highlightPrescription,
    setSort,
    selectItemId,
    setSortDirection,
    removeSimilarItem,
    addPrescriptionsToCart,
    handleAddInventoryItem,
  };
}

export function ShoppingRxContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const context = useShoppingRxContext();
  return (
    <ShoppingRxContext.Provider value={context}>
      {children}
    </ShoppingRxContext.Provider>
  );
}

export function useShoppingRx() {
  const context = useContext(ShoppingRxContext);
  if (!context) {
    throw new Error("useShoppingRx must be used within a ShoppingRxContext");
  }
  return context;
}
