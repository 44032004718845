import type { ItemInCart, ItemPurchaseDetails } from "../../types";
import { getPrescriptionId } from "../getPrescriptionId";
import { isInventoryId } from "../isInventoryId";
import { ItemTypeEnum } from "./itemTypeEnum";

export function getItemType(item: ItemInCart | ItemPurchaseDetails) {
  const { itemType } = item;
  if (itemType) return itemType;

  const id = "id" in item ? item.id : getPrescriptionId(item);
  const isInventory = isInventoryId(id);
  if (isInventory) return ItemTypeEnum.Inventory;
}
