import {
  BackendResponse,
  backendFetch,
  backendPost,
} from "./config/backend-api";
import {
  AdminPharmacyEmailSubscription,
  UpsertAdminPharmacyEmailSubscription,
  AdminPharmacyEmailSubscriptionOptions,
} from "../../utilities/types";

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacyEmailSubscription(
  token: string,
  id: string
) {
  const response = await backendFetch<{
    emailSubscription: AdminPharmacyEmailSubscription;
  }>(`/admin/pharmacy/email/subscription/${id}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin pharmacy email subscription", response);
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacyEmailSubscriptionOptions(token: string) {
  const response = await backendFetch<{
    emailSubscriptionOptions: AdminPharmacyEmailSubscriptionOptions;
  }>("/admin/pharmacy/email/subscription/options", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error(
      "Error fetching admin pharmacy email subscription options",
      response
    );
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmaciesEmailSubscriptions(token: string) {
  const response = await backendFetch<{
    emailSubscriptions: AdminPharmacyEmailSubscription[];
  }>(`/admin/pharmacy/email/subscription`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error(
      "Error fetching admin pharmacies email subscriptions",
      response
    );
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function createAdminPharmacyEmailSubscription(
  token: string,
  data: UpsertAdminPharmacyEmailSubscription
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy/email/subscription`,
    payload,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    emailSubscription: AdminPharmacyEmailSubscription;
  }>;

  if (response.error) {
    console.error(
      "Error creating admin pharmacy email subscription",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateAdminPharmacyEmailSubscription(
  token: string,
  id: string,
  data: UpsertAdminPharmacyEmailSubscription
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy/email/subscription/${id}`,
    payload,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    emailSubscription: AdminPharmacyEmailSubscription;
  }>;

  if (response.error) {
    console.error(
      "Error updating admin pharmacy email subscription",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function deleteAdminPharmacyEmailSubscription(
  token: string,
  id: string
) {
  const responseRaw = await backendFetch(
    `/admin/pharmacy/email/subscription/${id}`,
    {
      method: "DELETE",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await (responseRaw as any).json()) as string;

  if (response !== "OK") {
    console.error(
      "Error deleting admin pharmacy email subscription",
      responseRaw,
      response
    );
  }

  return response;
}
