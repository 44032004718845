import { useEffect, useState, useMemo } from "react";
import { dayjsWithDuration } from "../utilities/dates/dayjsWithDuration";
import { formatDateToStringHour } from "../utilities/dates/formatDateToStringHour";
import { formatStringHourToDate } from "../utilities/dates/formatStringHourToDate";
import { capitalizeFirstLetter } from "../utilities/strings/capitalizeFirstLetter";

function getNextCutoffTime(cutoffTimes: string[]) {
  const nextCutoffTime = cutoffTimes.find((cutoffTime) => {
    const cutoffTimeDate = formatStringHourToDate(cutoffTime);
    const isAfter = cutoffTimeDate?.isAfter(new Date()) ?? false;
    return isAfter;
  });
  return nextCutoffTime;
}

function getTodayCutoffTimes(cutoffTimes?: string | string[][]) {
  if (!cutoffTimes) return;
  if (!Array.isArray(cutoffTimes)) return [cutoffTimes];

  const day = new Date().getDay();
  // the cutoffTimes array starts on monday
  const cutoffTimesDay = day === 0 ? 6 : day - 1;
  return cutoffTimes[cutoffTimesDay];
}

export function useCutOffTime({
  cutoffOnly,
  cutoffTimes,
}: {
  cutoffOnly?: boolean;
  cutoffTimes?: string | string[][];
}) {
  const [nextCutoffTime, setNextCutoffTime] = useState<string>();

  const todayCutoffTimes = useMemo(() => {
    return getTodayCutoffTimes(cutoffTimes);
  }, [cutoffTimes]);

  const text = useMemo(() => {
    if (!todayCutoffTimes) return "";

    const nextCutoffTimeDate = formatStringHourToDate(nextCutoffTime);
    const lastTodayCutoffTime = todayCutoffTimes[todayCutoffTimes.length - 1];
    const lastTodayCutoffTimeDate = formatStringHourToDate(lastTodayCutoffTime);

    if (cutoffOnly) {
      const cutoffTime = nextCutoffTimeDate ?? lastTodayCutoffTimeDate;
      if (!cutoffTime) return "";
      const cutoffTimeHour = cutoffTime.format("hA");
      return `Cut-off time - ${cutoffTimeHour}`;
    }

    if (nextCutoffTimeDate) {
      const timeUntilNextCutoffTime = capitalizeFirstLetter(
        dayjsWithDuration
          .duration(nextCutoffTimeDate.diff(new Date()))
          .humanize()
      );

      const nextCutoffTimeHour = formatDateToStringHour(nextCutoffTimeDate);
      return `${timeUntilNextCutoffTime} to cutoff time (${nextCutoffTimeHour})`;
    }

    if (!lastTodayCutoffTimeDate) return "";

    const cutoffTimeHour = formatDateToStringHour(lastTodayCutoffTimeDate);
    return `Order will be shipped next business day. Missed today's cut-off of ${cutoffTimeHour}`;
  }, [todayCutoffTimes, nextCutoffTime, cutoffOnly]);

  useEffect(() => {
    if (!todayCutoffTimes) {
      setNextCutoffTime(undefined);
      return;
    }

    const shouldStop = updateNextCutoffTime(todayCutoffTimes);
    if (shouldStop) return;

    let interval: NodeJS.Timeout | undefined = setInterval(() => {
      const stopUpdates = !updateNextCutoffTime(todayCutoffTimes);

      if (stopUpdates && interval) {
        clearInterval(interval);
        interval = undefined;
      }
    }, 1000);

    return () => {
      interval && clearInterval(interval);
    };

    function updateNextCutoffTime(todayCutoffTimes: string[]) {
      const newNextCutoffTime = getNextCutoffTime(todayCutoffTimes);
      setNextCutoffTime((oldNextCutoffTime) => {
        if (!newNextCutoffTime) return;
        if (oldNextCutoffTime === newNextCutoffTime) return oldNextCutoffTime;
        return newNextCutoffTime;
      });
      const shouldStop = !newNextCutoffTime;
      return shouldStop;
    }
  }, [todayCutoffTimes]);

  return text;
}

export function CutOffTime(props: Parameters<typeof useCutOffTime>[0]) {
  return useCutOffTime(props);
}
