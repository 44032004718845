import React, { useCallback } from "react";
import "twin.macro";
import { Tooltip } from "antd";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import { IconEyeShut, IconEye } from "../../components/rxLibrary/icons";
import { Text } from "../../components/rxLibrary/typography";
import { PrintWrapper } from "../../components/rxLibrary/print";
import { IconButton } from "../../components/rxLibrary/buttons";
import { copyContent } from "../../utilities/copyContent";
import { SupplierAccountRecordView } from "./EnhancedSupplierAccount.constants";

export function EnhancedSupplierAccountPassword({
  account,
  togglePassword,
}: {
  account: SupplierAccountRecordView;
  togglePassword: (account: SupplierAccountRecordView) => void;
}) {
  const { password, passwordVisible } = account;

  const copyPassword = useCallback(() => {
    copyContent(password, "Password");
  }, [password]);

  const toggle = useCallback(() => {
    togglePassword(account);
  }, [account, togglePassword]);

  return (
    <div key={account.userName} tw="flex justify-between items-center h-[35px]">
      <Text tw="flex-1">
        {account.passwordVisible ? account.password : "************"}
      </Text>

      <PrintWrapper>
        <Tooltip title={passwordVisible ? "Hide Password" : "Show Password"}>
          <IconButton onClick={toggle}>
            {passwordVisible ? <IconEyeShut /> : <IconEye />}
          </IconButton>
        </Tooltip>
      </PrintWrapper>

      <PrintWrapper>
        <Tooltip title="Copy Password">
          <IconButton onClick={copyPassword}>
            <CopyOutlined rev={undefined} />
          </IconButton>
        </Tooltip>
      </PrintWrapper>
    </div>
  );
}
