import { useMemo } from "react";
import { useCutOffTime } from "components/CutOffTime";
import { useCatalog } from "../../useCatalog";
import { useFoamCutOffTime } from "./useFoamCutOffTime";
import { useFFFOvernightShippingCutOffTime } from "./useFFFOvernightShippingCutOffTime";
import { useJourneyRefrigeratedCutOffTime } from "./useJourneyRefrigeratedCutOffTime";

export function useCatalogCartHeaderWarnings() {
  const { supplier, hasFoamItems, isFFFSupplier, isJourneySupplier } =
    useCatalog();
  const cutoffTimes = supplier.shippingConfig?.cutoffTimes;

  const cutOffTimesWarning = useCutOffTime({ cutoffTimes });

  const foamCutOffTimesWarning = useFoamCutOffTime({
    cutoffTimes,
    hasFoamItems,
  });

  const fffOvernightShippingCutOffTime = useFFFOvernightShippingCutOffTime({
    isFFFSupplier,
  });

  const journeyRefrigeratedCutOffTime = useJourneyRefrigeratedCutOffTime({
    cutoffTimes,
    isJourneySupplier,
  });

  const warnings = useMemo(() => {
    const texts = [
      cutOffTimesWarning,
      foamCutOffTimesWarning,
      fffOvernightShippingCutOffTime,
      journeyRefrigeratedCutOffTime,
    ].filter(Boolean) as string[];

    if (texts.length) return texts;
  }, [
    cutOffTimesWarning,
    foamCutOffTimesWarning,
    fffOvernightShippingCutOffTime,
    journeyRefrigeratedCutOffTime,
  ]);

  return warnings;
}
