import React, { useContext, useCallback, useMemo } from "react";
import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";
import { BUTTON_SHADOW_CSS } from "../../../rxLibrary/buttons";
import { BLUE_1, GREY_5 } from "../../../rxLibrary/colors";
import { getTextCss } from "../../../rxLibrary/typography";
import { IconNote } from "../../../rxLibrary/icons";
import EnhancedClientConfigContext from "../../../../enhanced/EnhancedClientConfigContext";
import EnhancedClientContext from "../../../../enhanced/EnhancedClientContext";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import { DOWNLOAD_APP_INSTALLER_URL } from "../../../../utilities/config";
import { useNavBarMenuRoutes } from "../../../../utilities/routing/useNavBarMenuRoutes";
import { isWindowsOS } from "../../../../utilities/isWindowsOS";

const NavIconButton = styled.button`
  ${getTextCss({ weight: "bold", size: 12 })}
  ${BUTTON_SHADOW_CSS}
  border-radius: 8px;
  background-color: ${GREY_5};
  column-gap: 9px;
  display: flex;
  padding: 0 24px;
  align-items: center;
  height: 40px;
  width: 100%;

  &:hover {
    color: ${BLUE_1};
  }

  img {
    height: 25px;
  }
`;

const NavIconInnerLink = NavIconButton.withComponent(Link);

const NavIconExternalLink = NavIconButton.withComponent("a");

export function AccountMenuLinks() {
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { setEnhancedReportProblemModalShown } = useContext(
    EnhancedClientContext
  );

  const routes = useNavBarMenuRoutes();
  const isWindows = useMemo(() => isWindowsOS(), []);
  const hasDownloadAppLink =
    isWindows && currentBuyingPharmacy?.supplierScope === "All";

  const openReportProblemModal = useCallback(() => {
    setEnhancedReportProblemModalShown(true);
  }, [setEnhancedReportProblemModalShown]);

  if (!routes && !enhancedClientActive && !hasDownloadAppLink) return null;
  return (
    <div tw="space-y-[6px] mb-[36px]">
      {routes?.map(({ to, text, image }) => {
        const isPreviousShoppingLists = to === "/previousShoppingLists";

        return (
          <NavIconInnerLink
            key={to}
            to={to}
            css={isPreviousShoppingLists && tw`pl-[19px]`}
          >
            <img src={image} />
            <span>{text}</span>
          </NavIconInnerLink>
        );
      })}

      {hasDownloadAppLink && (
        <NavIconExternalLink href={DOWNLOAD_APP_INSTALLER_URL} download>
          <span tw="flex justify-center w-[23px]">
            <DownloadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          </span>
          <span>DOWNLOAD DAYLIGHTRX APP</span>
        </NavIconExternalLink>
      )}

      {enhancedClientActive && (
        <NavIconButton onClick={openReportProblemModal}>
          <div tw="flex justify-center w-[24px]">
            <IconNote />
          </div>
          <span>REPORT PROBLEM</span>
        </NavIconButton>
      )}
    </div>
  );
}
