import { ColDef, ColGroupDef } from "ag-grid-community";
import cloneDeep from "lodash/cloneDeep";
import { humanizeBoolean } from "../../../../../../utilities/humanizeBoolean";

export function formatColumnDefs(columnDefs?: (ColDef | ColGroupDef)[]) {
  const newColumnDefs = columnDefs?.map((col) => {
    const newCol = cloneDeep(col);

    if ("children" in newCol) {
      const newChildren = newCol.children.map((child) => {
        const newChild = cloneDeep(child);

        if ("children" in newChild) return newChild;

        if (!newChild.valueFormatter) {
          newChild.valueFormatter = (params) => humanizeBoolean(params.value);
        }

        return newChild;
      });
      newCol.children = newChildren;

      return newCol;
    }

    if (!newCol.valueFormatter) {
      newCol.valueFormatter = (params) => humanizeBoolean(params.value);
    }

    return newCol;
  });

  return newColumnDefs;
}
