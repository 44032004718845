import React, { useContext } from "react";
import tw from "twin.macro";
import { useNavBarMenuRoutes } from "../../utilities/routing/useNavBarMenuRoutes";
import { Text } from "../../components/rxLibrary/typography";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { WideContainer } from "../../components/containers/WideContainer";
import { ActionCards } from "./cards/ActionCards/ActionCards";
import { SupplierCards } from "./cards/SupplierCards/SupplierCards";
import { NdcLookupCard } from "./cards/NdcLookupCard";
import { DailyPurchaseCard } from "./cards/DailyPurchaseCard";

const HomeTitle = Text.withComponent("h1");

export function PharmacyHome() {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const routes = useNavBarMenuRoutes();

  if (!currentBuyingPharmacy) return <FullPageLoader />;

  const { suppliers, supplierScope } = currentBuyingPharmacy;
  const isManufacturerOnly = supplierScope === "ManufacturerOnly";
  const hasActionCards = !!routes?.length;

  return (
    <WideContainer tw="pb-[200px]">
      <div tw="my-[44px]">
        <HomeTitle color="blue-2" weight="bold" size={48} block center>
          BUY MEDS. EASY.
        </HomeTitle>
      </div>

      <div
        tw="grid gap-[15px]"
        css={
          hasActionCards
            ? tw`grid-cols-3`
            : tw`grid-cols-2 max-w-[780px] mx-auto`
        }
      >
        {isManufacturerOnly && <SupplierCards suppliers={suppliers} />}

        {!isManufacturerOnly && <DailyPurchaseCard />}

        {!isManufacturerOnly && <NdcLookupCard />}

        {hasActionCards && (
          <ActionCards routes={routes} isManufacturerOnly={isManufacturerOnly} />
        )}
      </div>
    </WideContainer>
  );
}
