import { useMemo } from "react";
import { ItemInCart } from "../../../../../../utilities/types";
import { getPrescriptionId } from "../../../../../../utilities/prescriptions/getPrescriptionId";
import { isInventoryId } from "../../../../../../utilities/prescriptions/isInventoryId";
import { PrescriptionTag } from "../../../../../../components/shopping/PrescriptionTag";
import { PrescriptionPackSizeSubstitution } from "./PrescriptionPackSizeSubstitution";
import { PrescriptionManufacturerSubstitution } from "./PrescriptionManufacturerSubstitution";

export function SubstitutionsCol({
  prescription,
}: {
  prescription: ItemInCart;
}) {
  const { noManufacturerPreference } = prescription;
  const id = useMemo(() => getPrescriptionId(prescription), [prescription]);
  const isInventory = useMemo(() => isInventoryId(id), [id]);
  const displayManufacturer = !(isInventory && noManufacturerPreference);

  return (
    <div tw="space-y-[6px]">
      <PrescriptionPackSizeSubstitution prescription={prescription} />

      {displayManufacturer && (
        <PrescriptionManufacturerSubstitution prescription={prescription} />
      )}

      <PrescriptionTag isInventory={isInventory} prescription={prescription} />
    </div>
  );
}
