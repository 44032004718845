import { useMemo } from "react";
import { useParams } from "react-router-dom";
import isInteger from "lodash/isInteger";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";

export function useManufacturerStoreSupplier() {
  const { getSupplierById } = useBuyingPharmacy();
  const { supplierId: supplierIdRaw } = useParams<{ supplierId: string }>();

  const supplierId = useMemo(() => {
    const value = Number(supplierIdRaw);
    return isInteger(value) ? value : undefined;
  }, [supplierIdRaw]);

  const supplier = useMemo(() => {
    return getSupplierById(supplierId);
  }, [supplierId, getSupplierById]);

  return { supplierId, supplier };
}
