import type {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { rxChartTheme } from "../rxChart.theme";
import { numberFormatter } from "../utils/performanceReportFormatters";
import { MissedOpportunityNDCSubstType } from "./MissedOpportunities.types";
import { AgCharts } from "ag-charts-react";
import { useMemo } from "react";
import { mapValues, keyBy } from "lodash";

function renderTooltip({
  datum,
  yKey,
  yName,
}: AgCartesianSeriesTooltipRendererParams) {
  return {
    title: "NDC Substitution Suggested",
    content: `${yName}: ${numberFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Would a NDC Substitution Have Resulted in Savings?",
    textAlign: "left",
  },
  series: [
    {
      type: "bar",
      xKey: "title",
      yKey: "Yes",
      yName: "Yes",
      direction: "horizontal",
      cornerRadius: 10,
      strokeWidth: 1,
      stroke: "transparent",
      stacked: true,
      fill: "rgb(55, 176, 103)",
      tooltip: {
        renderer: renderTooltip,
      },
    },
    {
      type: "bar",
      xKey: "title",
      yKey: "No",
      yName: "No",
      direction: "horizontal",
      cornerRadius: 10,
      strokeWidth: 1,
      stroke: "transparent",
      stacked: true,
      fill: "#f9d45c",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "left",
    },
    {
      type: "number",
      position: "bottom",
      interval: { step: 1 }
    },
  ],
};

export function NDCSubstitutionBarChart({
  data,
}: {
  data?: MissedOpportunityNDCSubstType[];
}) {
  let reportData = [];

  reportData = useMemo(() => {
    if (!data) return [];

    return [
      {
        title: "NDC Substitution Suggested",
        ...mapValues(keyBy(data, "ndcSubstSuggested"), "count"),
      },
    ];
  }, [data]);

  return <AgCharts options={{ ...OPTIONS, data: reportData }} />;
}
