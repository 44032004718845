import { ColDef, ColGroupDef } from "ag-grid-community";
import { manufacturerReportCurrencyFormatter } from "../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" }, // 1
      { field: "Pharmacy NPI", pinned: "left", hide: true }, // 2
    ],
  },
  {
    headerName: "Prescription",
    children: [
      { field: "DAW Code", hide: true },
      { field: "Rx Number" },
      { field: "Rx Written Date", hide: true }, //5
      { field: "Dispensed Same as Prescribed Drug", hide: true },
      { field: "Dispensed Drug Name" }, //3
      { field: "Dispensed NDC" }, //4
      { field: "Dispensed Status" }, //7
      { field: "Quantity Dispensed", headerName: "Dispensed Quantity" }, //11
      { field: "Prescribed Drug Name", hide: true },
      { field: "Prescribed NDC", hide: true },
      { field: "Fill Adjudication Date" }, //6
      { field: "Sale Date", hide: true },
      { field: "Days Supply", hide: true },
      { field: "Total Refills Prescribed", hide: true },
      { field: "Refill Number" }, //12
      { field: "Refills Remaining" },
    ],
  },
  {
    headerName: "Prescriber",
    children: [
      { field: "Prescriber Name" }, //8
      { field: "Prescriber NPI" }, //9
      { field: "Prescriber Street Address", hide: true },
      { field: "Prescriber City", hide: true },
      { field: "Prescriber State", hide: true },
      { field: "Prescriber Zip" }, //10
    ],
  },
  {
    headerName: "Claim Info",
    children: [
      { field: "Was Coupon Used" },
      { field: "Last OCC", headerName: "OCC" }, //13
      {
        field: "Final Rejection Codes",
        headerName: "3rd Party Primary Payer Final Rejections",
        tooltipField: "Final Rejection Codes",
        hide: true,
      },
      {
        headerName: "Pharmacy Gross Revenue for Dispensed & In Progress",
        field: "Pharmacy Gross Revenue",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      }, // 15
      {
        headerName: "Patient Pay After Coupon for Dispensed & In Progress",
        field: "Patient Pay Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      }, // 15
      {
        headerName: "3rd-Party Contribution for Dispensed & In Progress",
        field: "Third Party Contribution",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      }, // 19
      {
        field: "Manufacturer Contribution",
        headerName: "Manuf. Contribution for Dispensed & In Progress",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      }, // 18
      {
        headerName:
          "Patient Pay Before Coupon for Dispensed & In Progress, for Covered Fills",
        field: "Patient Pay (Before Coupon) for Covered Fills",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 16
      {
        field: "Pharmacy Copay Buydown for Dispensed & In Progress",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 17
      {
        headerName: "Copay Before Coupon for Dispensed & In Progress",
        field: "Copay (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 16
      {
        headerName: "Coinsurance Before Coupon for Dispensed & In Progress",
        field: "Coinsurance (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 16
      {
        headerName:
          "Deductible Applied Before Coupon for Dispensed & In Progress",
        field: "Deductible Applied (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 16
      {
        headerName:
          "Deductible Remaining Before Coupon for Dispensed & In Progress",
        field: "Deductible Remaining (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 16
      {
        headerName:
          "Deductible Accumulated Before Coupon for Dispensed & In Progress",
        field: "Deductible Accumulated (Before Coupon)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      }, // 16
      {
        field: "Patient Coverage Gap (Before Coupon)",
        headerName:
          "Patient Coverage Gap Before Coupon for Dispensed & In Progress",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
      {
        field: "Other Payer Recognized Amount (Before Coupon)",
        headerName:
          "Other Payer Recognized Amount Before Coupon for Dispensed & In Progress",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        hide: true,
      },
    ],
  },
  {
    headerName:
      "Unapplied Claim Info - Third-Party Primary Payer Data for Abandoned & Transferred Fills",
    children: [
      {
        field: "Pharmacy Gross Revenue for Abandoned & Transferred",
        headerName: "Pharmacy Gross Revenue for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Patient Pay - Before Coupon for Abandoned & Transferred",
        headerName:
          "Patient Pay Before Coupon for Abandoned & Transferred, for Covered Fills",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Third Party Contribution for Abandoned & Transferred",
        headerName: "3rd-Party Contribution for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Third Party Contribution > $0 for Abandoned & Transferred",
        headerName:
          "3rd-Party Contribution when >$0 for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
        headerName:
          "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
    ],
  },
  {
    headerName: "Primary Payer",
    children: [
      {
        field: "Primary Payer Name (Pharmacy-Defined)",
      },
      {
        field: "Primary Plan Type (Pharmacy-Defined)",
      },
      { field: "Primary BIN", columnGroupShow: "open" }, //21
      { field: "Primary PCN", columnGroupShow: "open" },
      { field: "Primary Group ID", columnGroupShow: "open" },
    ],
  },
  {
    headerName: "Secondary Payer",
    children: [
      {
        field: "Secondary Payer Name (Pharmacy-Defined)",
      },
      {
        field: "Secondary Plan Type (Pharmacy-Defined)",
      },
      { field: "Secondary BIN", columnGroupShow: "open" }, //22
      { field: "Secondary PCN", columnGroupShow: "open" },
      { field: "Secondary Group ID", columnGroupShow: "open" },
    ],
  },
  {
    headerName: "Tertiary Payer",
    children: [
      {
        field: "Tertiary Payer Name (Pharmacy-Defined)",
      },
      {
        field: "Tertiary Plan Type (Pharmacy-Defined)",
      },
      { field: "Tertiary BIN", columnGroupShow: "open" }, // 23
      { field: "Tertiary PCN", columnGroupShow: "open" },
      { field: "Tertiary Group ID", columnGroupShow: "open" },
    ],
  },
  { field: "Fill ID (PharmacyNPI-RxNumber-Refill)", hide: true },
  { field: "Patient ID", hide: true },
];
