import { useContext, useEffect, useMemo, useState } from "react";
import BuyingPharmacyContext from "../../../../contexts/BuyingPharmacyContext";
import EnhancedClientConfigContext from "../../../../enhanced/EnhancedClientConfigContext";
import EnhancedClientContext from "../../../../enhanced/EnhancedClientContext";
import { NdcOffering, NdcOfferingSet } from "../../../../enhanced/result_types";
import {
  DrugInfo,
  ListCategoryEnum,
  NDCTaggedItemList,
} from "../../../../utilities/types";
import {
  splitCards,
  buildOfferingSetForCard,
  appendAdditionalEnhancedItems,
} from "./useNdcResults.utils";

export type NdcResults = {
  topCards: NdcOfferingSet[];
  origMatch: NDCTaggedItemList;
  bottomCards: NdcOfferingSet[];
  totalCardCount: number;
  mainNdcOffering?: NdcOffering;
};

export function useNdcResults({
  itemsByNDC,
  suggestedDrug,
  relatedDrugsByNDC,
  enhancementExcludedSuppliers,
}: {
  itemsByNDC: NDCTaggedItemList[];
  suggestedDrug?: DrugInfo;
  relatedDrugsByNDC?: Record<string, DrugInfo>;
  enhancementExcludedSuppliers?: string[];
}) {
  const [ndcResults, setNdcResults] = useState<NdcResults>();
  const { currentBuyingPharmacy, getSupplierByName } = useContext(
    BuyingPharmacyContext
  );
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { enhancedSupplierConnectionStatuses, currentEnhancementState } =
    useContext(EnhancedClientContext);

  const isEmpty = useMemo(() => {
    return !itemsByNDC.some((i) => i.items.length);
  }, [itemsByNDC]);

  useEffect(() => {
    if (isEmpty) {
      setNdcResults(undefined);
      return;
    }

    const offeringRecords = itemsByNDC
      .map((item) =>
        buildOfferingSetForCard(
          item,
          enhancedClientActive,
          enhancedSupplierConnectionStatuses,
          currentEnhancementState.enhancedSupplierItems,
          currentBuyingPharmacy,
          enhancementExcludedSuppliers,
          getSupplierByName
        )
      )
      .reduce<Record<string, NdcOfferingSet>>((acc, offeringSet) => {
        const { ndc, category } = offeringSet;
        if (
          !acc[ndc] ||
          category === ListCategoryEnum.NDC_MATCH ||
          category === ListCategoryEnum.ALTERNATIVE_NDC
        ) {
          acc[ndc] = offeringSet;
        }
        return acc;
      }, {});

    const offeringSets = appendAdditionalEnhancedItems(
      currentBuyingPharmacy,
      offeringRecords,
      enhancedClientActive,
      currentEnhancementState.enhancedAdditionalItemsByNDC,
      getSupplierByName,
      relatedDrugsByNDC
    );

    const { topCards, bottomCards, mainNdcOffering } = splitCards(
      offeringSets,
      suggestedDrug
    );
    const origMatch = itemsByNDC[0];
    const totalCardCount = topCards.length + bottomCards.length;

    setNdcResults({
      topCards,
      origMatch,
      bottomCards,
      totalCardCount,
      mainNdcOffering,
    });
  }, [
    isEmpty,
    itemsByNDC,
    suggestedDrug,
    relatedDrugsByNDC,
    enhancedClientActive,
    currentBuyingPharmacy,
    currentEnhancementState,
    enhancementExcludedSuppliers,
    enhancedSupplierConnectionStatuses,
    getSupplierByName,
  ]);

  return { isEmpty, ndcResults };
}
