import { backendFetch, backendPost } from "./config/backend-api";
import { DropdownOptionType } from "../../utilities/types";

type UiSettings = { settings: { manageColumnOptions?: DropdownOptionType[] } };

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getUiSettings({
  token,
  pharmacyId,
}: {
  token: string;
  pharmacyId: number;
}) {
  return backendFetch<UiSettings>(`/ui-settings?pharmacy_id=${pharmacyId}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function setUiSettings({
  token,
  pharmacyId,
  uiSettings: uiSettings,
}: {
  token: string;
  pharmacyId: number;
  uiSettings: UiSettings;
}) {
  const payload = JSON.stringify({ data: uiSettings });
  return backendPost(`/ui-settings?pharmacy_id=${pharmacyId}`, payload, {
    method: "POST",
    headers: { Authorization: "Bearer " + token },
  });
}
