import { usePerformance } from "./usePerformance";
import { PerformanceReportType } from "./Performance.constants";
import { PerformanceReportModal } from "./PerformanceReportModal";
import { PerformanceReportChart } from "./PerformanceReportChart";
import { PerformanceReportError } from "./PerformanceReportError";

export function PerformanceReport() {
  const { reportId, report } = usePerformance();

  if (!report) return null;

  if (report.reportType === PerformanceReportType.MODE) {
    return (
      <PerformanceReportModal key={reportId} iframeProps={report.iframeProps} />
    );
  }

  if (
    report.reportType === PerformanceReportType.AG_CHART &&
    "Report" in report &&
    report.Report
  ) {
    return <PerformanceReportChart key={reportId} Report={report.Report} />;
  }

  return <PerformanceReportError />;
}
