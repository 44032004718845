import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminPharmacyEmailSubscriptions } from "./AdminPharmacyEmailSubscriptions";
import { CreateAdminPharmacyEmailSubscription } from "./CreateAdminPharmacyEmailSubscription";
import { EditAdminPharmacyEmailSubscription } from "./EditAdminPharmacyEmailSubscription";

const PATH = "/admin/pharmacy/email/subscription";
const PATH_UPDATE = `${PATH}/:id`;
const PATH_CREATE = `${PATH}/new`;

export function PharmacyEmailSubscriptionsRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminPharmacyEmailSubscriptions} exact />
      <Route
        path={PATH_CREATE}
        component={CreateAdminPharmacyEmailSubscription}
        exact
      />
      <Route
        path={PATH_UPDATE}
        component={EditAdminPharmacyEmailSubscription}
        exact
      />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
