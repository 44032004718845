import React from "react";
import dayjs from "dayjs";
import { styled } from "twin.macro";
import { WideContainer } from "../../../../components/containers/WideContainer";
import { Text } from "../../../../components/rxLibrary/typography";
import {IconBell} from "../../../../components/rxLibrary/icons";

const Wrapper = styled.div`
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.27);
`;

export function PreviousOptimizationWarningBar({createdAt, createdBy}: {createdAt: Date, createdBy: string}) {
  return (
    <Wrapper tw="sticky top-0 z-50 bg-[#c73232]">
      <WideContainer tw="flex flex-col justify-center items-center relative h-[96px]">
        <div tw="flex items-center"><IconBell color="white-1" /><Text tw="text-white text-lg ml-1">NOTE: This page was created {dayjs(createdAt).fromNow()} by {createdBy}.</Text></div>
        <Text tw="text-white text-lg">(Items may already be purchased or unavailable at suppliers)</Text>

      </WideContainer>
    </Wrapper>
  );
}
