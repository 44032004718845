import React, { useCallback, useMemo } from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Divider,
  Checkbox,
  Popconfirm,
  InputNumber,
} from "antd";
import type { UpsertAdminSupplierMapping } from "utilities/types";
import {
  OnSubmit,
  useHandleSubmit,
} from "components/antd/form/useHandleSubmit";
import { FormSkeleton } from "components/antd/form/FormSkeleton";
import { FormCategory } from "components/antd/form/FormCategory";
import { Button } from "components/rxLibrary/buttons";
import { numberValidator } from "components/antd/form/validators/numberValidator";
import { InputJson } from "components/antd/form/dataEntries/InputJson";
import { jsonValidator } from "components/antd/form/validators/jsonValidator";
import { YES_NO_NONE_OPTIONS } from "components/antd/form/form.constants";
import { noLowercaseLettersValidator } from "components/antd/form/validators/noLowercaseLettersValidator";
import { AdminManagementHeader } from "../../components/AdminManagementHeader";
import { PharmacyLabel } from "../../components/PharmacyLabel";
import { ViewChangeLogNotes } from "../../components/ViewChangeLogNotes";
import { CustomerAccountInput } from "../../components/AccountIdInput/CustomerAccountInput";
import { AdminSupplierMappingFormShippingConfig } from "./AdminSupplierMappingFormShippingConfig/AdminSupplierMappingFormShippingConfig";
import {
  FormShippingConfig,
  formatShippingConfig,
  formatAdminSupplierMappingFormSubmitValues,
  CLEAN_FROM_SHIPPING_CONFIG,
} from "./AdminSupplierMappingForm.utils";
import { useShippingConfigOverrideVisibility } from "./AdminSupplierMappingFormShippingConfig/useShippingConfigOverrideVisibility";
import { AdminSupplierMappingFormPharmacyIsaCodeLabel } from "./labels/AdminSupplierMappingFormPharmacyIsaCodeLabel";
import { AdminSupplierMappingFormSupplierIsaCodeLabel } from "./labels/AdminSupplierMappingFormSupplierIsaCodeLabel";
import { useAdminSupplierMappingOptions } from "./useAdminSupplierMappingOptions";
import { BillToPharmacyInput } from "./BillToPharmacyInput";

function formatRedirectTo(
  redirectTo: string,
  {
    submitData,
  }: {
    submitData: UpsertAdminSupplierMapping;
  }
) {
  const { pharmacyId } = submitData;
  return `${redirectTo}?pharmacy=${pharmacyId}`;
}

export function AdminSupplierMappingForm({
  id,
  isUpdateForm,
  initialValues,
  changeLogNotes,
  catalogSupplierId,
  onSubmit,
}: {
  id?: number;
  isUpdateForm?: boolean;
  initialValues?: Omit<
    UpsertAdminSupplierMapping,
    "shippingConfig" | "supplierId" | "pharmacyId"
  > & {
    supplierId?: UpsertAdminSupplierMapping["supplierId"];
    pharmacyId?: UpsertAdminSupplierMapping["pharmacyId"];
    shippingConfig: FormShippingConfig;
  };
  changeLogNotes?: string[];
  catalogSupplierId?: number | null;
  onSubmit: OnSubmit<UpsertAdminSupplierMapping>;
}) {
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit,
    formatRedirectTo,
    formatSubmitValues: formatAdminSupplierMappingFormSubmitValues,
  });

  const title = isUpdateForm
    ? `Edit Supplier Mapping ${id}`
    : "Create Supplier Mapping";
  const submitBtn = isUpdateForm
    ? "Update Supplier Mapping"
    : "Create Supplier Mapping";

  const initialPharmacyId = initialValues?.pharmacyId;
  const initialSupplierId = initialValues?.supplierId;
  const initialShippingConfig = initialValues?.shippingConfig;
  const initialBillToPharmacyId = initialValues?.billToPharmacyId || undefined;
  const pharmacyId = Form.useWatch("pharmacyId", form);
  const supplierId = Form.useWatch("supplierId", form);
  const shippingConfig = Form.useWatch("shippingConfig", form);
  const billToPharmacyId = Form.useWatch("billToPharmacyId", form);

  const {
    pharmacyOptions,
    supplierOptions,
    selectedPharmacy,
    selectedSupplier,
    supplierOptionsWithIsAdded,
    defaultSupplierShippingConfig,
    supplierAlreadyAddedValidator,
  } = useAdminSupplierMappingOptions({
    pharmacyId,
    supplierId,
    initialSupplierId,
    initialPharmacyId,
  });
  const defaultShippingConfig =
    defaultSupplierShippingConfig?.[supplierId?.toString()];

  const {
    shippingConfigOverrideIsVisible,
    showShippingConfigOverride,
    hideShippingConfigOverride,
  } = useShippingConfigOverrideVisibility(initialShippingConfig);

  const customerAccountRules = useMemo(() => {
    if (billToPharmacyId) return [{ required: true }];
  }, [billToPharmacyId]);

  const supplierIdWarning = useMemo(() => {
    if (!supplierId || !formatShippingConfig(shippingConfig)) return;
    return "Warning: changing supplier will remove the shipping config override";
  }, [supplierId, shippingConfig]);

  const timezoneWarning = useMemo(() => {
    if (!defaultShippingConfig?.convertShippingCutoffsToLocalTz) return;
    if (!selectedPharmacy?.timezone) return;
    return `Warning: Times Automatically Adjusted for Pharmacy Timezone of ${selectedPharmacy.timezone}`;
  }, [defaultShippingConfig, selectedPharmacy]);

  const onValuesChange = useCallback(
    (changedValues: any) => {
      if ("supplierId" in changedValues) {
        hideShippingConfigOverride();
        form.setFieldValue("shippingConfig", CLEAN_FROM_SHIPPING_CONFIG);
      }
      if ("pharmacyId" in changedValues || "supplierId" in changedValues) {
        form.setFieldValue("billToPharmacyId", undefined);
      }
    },
    [form, hideShippingConfigOverride]
  );

  const loading = isUpdateForm ? !initialValues || isSubmitting : false;
  if (loading) return <FormSkeleton text="Loading Supplier Mapping" />;

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          {
            title: <Link to="/admin/supplier-mapping">Supplier Mappings</Link>,
          },
          { title },
        ]}
      >
        <ViewChangeLogNotes changeLogNotes={changeLogNotes} />
      </AdminManagementHeader>

      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        disabled={isSubmitting}
        initialValues={initialValues}
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        scrollToFirstError
      >
        <FormCategory title="General">
          <Form.Item label="ID" extra="Autogenerated">
            <Input disabled readOnly value={id} />
          </Form.Item>

          <Form.Item
            name="pharmacyId"
            label={<PharmacyLabel />}
            rules={[{ required: true }]}
          >
            <Select
              options={pharmacyOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="supplierId"
            label="Supplier"
            rules={[
              { required: true },
              { validator: supplierAlreadyAddedValidator },
            ]}
            extra={supplierIdWarning}
          >
            <Select
              options={supplierOptionsWithIsAdded ?? supplierOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item name="isActive" label="Is Active" valuePropName="checked">
            <Checkbox />
          </Form.Item>

          <CustomerAccountInput
            name="customerAccountId"
            rules={customerAccountRules}
            supplierId={supplierId}
            supplierName={selectedSupplier?.label}
          />

          <BillToPharmacyInput
            selectedPharmacy={selectedPharmacy}
            initialBillToPharmacyId={initialBillToPharmacyId}
          />

          <Form.Item label="Change Log Note" name="changeLogNote">
            <Input allowClear />
          </Form.Item>
        </FormCategory>
        <Divider />
        <FormCategory title="App Settings: Pharmacy Input">
          <Form.Item
            name="suppliesSchedule2"
            valuePropName="checked"
            label="Allow C2s"
            tooltip="Should we filter out controls for this pharmacy-supplier C2"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="suppliesSchedule3"
            valuePropName="checked"
            label="Allow C3s"
            tooltip="Should we filter out controls for this pharmacy-supplier C3"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="suppliesSchedule4"
            valuePropName="checked"
            label="Allow C4s"
            tooltip="Should we filter out controls for this pharmacy-supplier C4"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="suppliesSchedule5"
            valuePropName="checked"
            label="Allow C5s"
            tooltip="Should we filter out controls for this pharmacy-supplier C5"
          >
            <Checkbox />
          </Form.Item>
        </FormCategory>

        <AdminSupplierMappingFormShippingConfig
          supplierId={supplierId}
          timezoneWarning={timezoneWarning}
          defaultShippingConfig={defaultShippingConfig}
          shippingConfigOverrideIsVisible={shippingConfigOverrideIsVisible}
          showShippingConfigOverride={showShippingConfigOverride}
          hideShippingConfigOverride={hideShippingConfigOverride}
        />
        <Divider />
        <FormCategory title="App Settings: Optimization">
          <Form.Item
            name="rebatedItemDiscount"
            label="Generic Item Discount in v2"
            tooltip="% Discount that the optimization should consider. Range of 0-1"
            extra="Use up/down arrow to change by 0.1"
            rules={[{ min: -1, max: 1, validator: numberValidator }]}
          >
            <InputNumber tw="w-full" step={0.1} min={-1} max={1} />
          </Form.Item>

          <Form.Item
            name="brandItemPenalty"
            label="Brand Item Penalty in v2"
            tooltip="% Penalty for a brand"
            extra="Use up/down arrow to change by 0.1"
            rules={[{ min: -1, max: 1, validator: numberValidator }]}
          >
            <InputNumber tw="w-full" step={0.1} min={-1} max={1} />
          </Form.Item>

          <Form.Item
            name="notifyAlternativeSuppliersForBrand"
            label="Notify Alternative Suppliers for Brand"
            valuePropName="checked"
            tooltip="Typically for a primary where there's a GCR. This shows when there are alternatives for brand items on P3. Helpful for primary suppliers where brand purchases can be moved to another supplier"
          >
            <Checkbox />
          </Form.Item>
        </FormCategory>
        <Divider />
        <FormCategory title="App Settings: General">
          <Form.Item
            name="scraperConfig"
            label="Scraper Config"
            tooltip="Should we filter out controls for this pharmacy-supplier C2"
            rules={[{ validator: jsonValidator }]}
          >
            <InputJson />
          </Form.Item>

          <Form.Item
            name="alwaysReturnSeedDrugOverride"
            label="Always Return Seed Drug Override"
            tooltip="Typically true for primary suppliers. This means to look up every NDC in Electron regardless of catalog"
          >
            <Select
              options={YES_NO_NONE_OPTIONS}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="ecommerceEnabled"
            valuePropName="checked"
            label="Ecommmerce Enabled"
            tooltip="Ecommerce vs offline supplier option"
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            name="hasDirectDeal"
            valuePropName="checked"
            label="Has Direct Deal"
            tooltip="Part of bundle-based deal for manufacturer direct"
          >
            <Checkbox />
          </Form.Item>
        </FormCategory>
        <Divider />
        <FormCategory title="EDI">
          <Form.Item
            name="catalogSupplierIdOverride"
            label="Catalog Supplier Override"
            tooltip="Set this if the EDI catalog is actually a different supplier's catalog. Rarely used"
          >
            <Select
              options={supplierOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            label="Catalog Supplier"
            tooltip="Supplier used for EDI catalog"
          >
            <Select
              disabled
              value={catalogSupplierId}
              options={supplierOptions}
              optionFilterProp="label"
              showSearch
              allowClear
            />
          </Form.Item>

          <Form.Item
            name="pharmacyIsaCode"
            label={
              <AdminSupplierMappingFormPharmacyIsaCodeLabel
                pharmacyId={selectedPharmacy?.value}
                pharmacyName={selectedPharmacy?.label}
              />
            }
            rules={[
              {
                validator: noLowercaseLettersValidator,
                message:
                  "ISA codes rarely have lowercase letters. Please confirm",
                warningOnly: true,
              },
              {
                pattern: /^[a-zA-Z0-9]+$/gi,
                message: "ISA Codes should only be letters and numbers",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item
            name="supplierIsaCode"
            label={
              <AdminSupplierMappingFormSupplierIsaCodeLabel
                supplierId={selectedSupplier?.value}
                supplierName={selectedSupplier?.label}
              />
            }
            rules={[
              {
                validator: noLowercaseLettersValidator,
                message:
                  "ISA codes rarely have lowercase letters. Please confirm",
                warningOnly: true,
              },
              {
                pattern: /^[a-zA-Z0-9]+$/g,
                message: "ISA Codes should only be letters and numbers",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        </FormCategory>
        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>{submitBtn}</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
