import React, { memo, useCallback, useMemo } from "react";
import "twin.macro";
import "../../../../scss/purchaseQuantity.scss";
import RadioButtonsGroup from "../../../../components/RadioButtonsGroup";
import {
  PurchaseQuantityMethodEnum,
  PrescriptionQuantityItem,
} from "../../../../utilities/types";
import { ManufacturerDirectDeal } from "../../../../components/shopping/ManufacturerDirectDeal";
import { PurchaseQuantityManualOption } from "./options/PurchaseQuantityManualOption";
import { PurchaseQuantityOptionalOption } from "./options/PurchaseQuantityOptionalOption";

export function PurchaseQuantity({
  prescription,
  updatePurchaseQuantity,
}: {
  prescription: PrescriptionQuantityItem;
  updatePurchaseQuantity: (
    rxNumbers: string[],
    purchaseQuantityMethod: PurchaseQuantityMethodEnum,
    num?: string
  ) => void;
}) {
  const { rxNumbers, directDeals, numPackages, useQuantityInput } =
    prescription;
  const directDeal = directDeals?.[0];
  const hasDirectDeal = !!directDeal;

  const purchaseQuantityMethod =
    hasDirectDeal || !useQuantityInput
      ? PurchaseQuantityMethodEnum.Optional
      : PurchaseQuantityMethodEnum.Manual;

  const handlePurchaseQuantityMethodChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const purchaseQuantityMethod = e.target.value as PurchaseQuantityMethodEnum;
      updatePurchaseQuantity(rxNumbers, purchaseQuantityMethod);
    },
    [updatePurchaseQuantity]
  );

  const options = useMemo(() => {
    const optionalOption = [
      {
        value: PurchaseQuantityMethodEnum.Optional,
        label: (
          <PurchaseQuantityOptionalOption
            prescription={prescription}
            updatePurchaseQuantity={updatePurchaseQuantity}
          />
        ),
      },
    ];

    if (hasDirectDeal) return optionalOption;
    return [
      ...optionalOption,
      {
        value: PurchaseQuantityMethodEnum.Manual,
        label: (
          <PurchaseQuantityManualOption
            prescription={prescription}
            updatePurchaseQuantity={updatePurchaseQuantity}
          />
        ),
      },
    ];
  }, [hasDirectDeal, prescription, updatePurchaseQuantity]);

  return (
    <>
      <div className="purchase-quantity">
        <RadioButtonsGroup
          isVertical
          options={options}
          labelMaxHeight={26}
          selectedValue={purchaseQuantityMethod}
          onChange={handlePurchaseQuantityMethodChange}
        />
      </div>

      {hasDirectDeal && (
        <div tw="w-full mt-[3px]">
          <ManufacturerDirectDeal
            directDeal={directDeal}
            packQuantity={numPackages}
          />
        </div>
      )}
    </>
  );
}

export default memo(PurchaseQuantity);
