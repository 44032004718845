import type { QuoteState } from "./useCompare";
import { Quotes, RequestState } from "../../../utilities/types";
import {
  groupItemsByNdc,
  tagAndGroupQuoteItems,
} from "../../../utilities/search";

export function webFormatter({
  ndc,
  quotes,
}: {
  ndc: string;
  quotes: Quotes;
}): Partial<QuoteState> {
  const { items, relatedDrugs, suggestedNDC } = quotes;

  const suggestedDrug = relatedDrugs.find((d) => d.ndc === suggestedNDC.ndc);

  const taggedItemsGroupedByTag = tagAndGroupQuoteItems(
    ndc,
    items,
    suggestedNDC
  );
  const itemListsByNDC = groupItemsByNdc(taggedItemsGroupedByTag);

  return {
    suggestedDrug,
    itemListsByNDC,
    quoteRequestState: RequestState.COMPLETED,
  };
}
