import React from "react";
import "twin.macro";
import {RequestEnhanceItemParam, SupplierItemDataType} from "./EnhancedClientContext";
import EnhancedShoppingListSearch from "./EnhancedShoppingListSearch";

export default function EnhancedShoppingListSearchTestRoute(): JSX.Element {
    const shoppingList: RequestEnhanceItemParam[] = [
        {
            "ndc": "51991083704",
            "supplier": "KINRAY",
            "drugNameUOMSizeStr": "Cetirizine HCl 1MG/ML Solution",
            "price": 0,
            "supplierItemNumbers": ["12341"],
        },
        // {
        //     ndc: "63304082705",
        //     supplier: "ABC",
        //     price: 7.8,
        //     drugNameUOMSizeStr: "Atorvastatin 10MG Tablet",
        // },
        // {
        //     ndc: "67877051190",
        //     supplier: "SMARTSOURCE",
        //     price: 12.65,
        //     drugNameUOMSizeStr: "Atorvastatin 10MG Tablet",
        // },
        {
            ndc: "60505257808",
            supplier: "KINRAY",
            price: 25.33,
            drugNameUOMSizeStr: "Atorvastatin 10MG Tablet",
            supplierItemNumbers: ["12342"],
        },
        // {
        //     ndc: "0378395005",
        //     supplier: "MCKESSON",
        //     price: 115.42,
        //     drugNameUOMSizeStr: "Atorvastatin 10MG Tablet",
        // },
        //
        // {
        //     ndc: "68382011405",
        //     supplier: "ABC",
        //     price: 29.68,
        //     drugNameUOMSizeStr: "risperiDONE 1MG Tablet",
        // },
        // {
        //     ndc: "68382011405",
        //     supplier: "SMARTSOURCE",
        //     price: 29.68,
        //     drugNameUOMSizeStr: "risperiDONE 1MG Tablet",
        // },
        // {
        //     ndc: "68382011405",
        //     supplier: "KINRAY",
        //     price: 15.95,
        //     drugNameUOMSizeStr: "risperiDONE 1MG Tablet",
        // },
        // {
        //     ndc: "13668003705",
        //     supplier: "MCKESSON",
        //     price: 85.9,
        //     drugNameUOMSizeStr: "risperiDONE 1MG Tablet",
        // },
        // {
        //     ndc: "27241000106",
        //     supplier: "ANDA",
        //     price: 1.66,
        //     drugNameUOMSizeStr: "risperiDONE 1MG Tablet",
        // },
        // {
        //     ndc: "57664060688",
        //     supplier: "ABC",
        //     price: 115.68,
        //     drugNameUOMSizeStr: "Methylphenidate HCl ER (OSM) 18MG ER Tablet",
        // },
        // {
        //     ndc: "65162023109",
        //     supplier: "KINRAY",
        //     price: 64.01,
        //     drugNameUOMSizeStr: "Methylphenidate HCl ER (OSM) 18MG ER Tablet",
        // },
        // {
        //     ndc: "57664060688",
        //     supplier: "MCKESSON",
        //     price: 259.99,
        //     drugNameUOMSizeStr: "Methylphenidate HCl ER (OSM) 18MG ER Tablet",
        // },
        // {
        //     ndc: "50458058501",
        //     supplier: "ANDA",
        //     price: 1264.58,
        //     drugNameUOMSizeStr: "Methylphenidate HCl ER (OSM) 18MG ER Tablet",
        // },
    ];
    const onComplete = (items: SupplierItemDataType[], removedSuppliers: string[]): void => {
        console.log("search onComplete", items, removedSuppliers);
    }

    const onAbort = () => {
        console.log("search onAbort");
    }

    const onCancelAfterCompleted = () => {
        console.log("search onCancelAfterComplete");
    }
    return <EnhancedShoppingListSearch shoppingList={shoppingList} onAbort={onAbort} onComplete={onComplete} onCancelAfterCompleted={onCancelAfterCompleted} />
}
