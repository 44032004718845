"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setCaptchaParams = setCaptchaParams;
exports.showMissingCaptcha = showMissingCaptcha;
exports.swapCaptcha = swapCaptcha;
var l = _interopRequireWildcard(require("../core/index"));
var c = _interopRequireWildcard(require("../field/index"));
var i18n = _interopRequireWildcard(require("../i18n"));
var _index3 = require("../store/index");
var _web_api = _interopRequireDefault(require("../core/web_api"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/**
 * Display the error message of missing captcha in the header of lock.
 *
 * @param {Object} m model
 * @param {Number} id
 * @param {Boolean} isPasswordless Whether the captcha is being rendered in a passwordless flow
 */
function showMissingCaptcha(m, id) {
  var isPasswordless = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  var captchaConfig = isPasswordless ? l.passwordlessCaptcha(m) : l.captcha(m);
  var captchaError = captchaConfig.get('provider') === 'recaptcha_v2' || captchaConfig.get('provider') === 'recaptcha_enterprise' ? 'invalid_recaptcha' : 'invalid_captcha';
  var errorMessage = i18n.html(m, ['error', 'login', captchaError]);
  (0, _index3.swap)(_index3.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false, errorMessage);
    return c.showInvalidField(m, 'captcha');
  });
  return m;
}

/**
 * Set the captcha value in the fields object before sending the request.
 *
 * @param {Object} m model
 * @param {Object} params
 * @param {Boolean} isPasswordless Whether the captcha is being rendered in a passwordless flow
 * @param {Object} fields
 *
 * @returns {Boolean} returns true if is required and missing the response from the user
 */
function setCaptchaParams(m, params, isPasswordless, fields) {
  var captchaConfig = isPasswordless ? l.passwordlessCaptcha(m) : l.captcha(m);
  var isCaptchaRequired = captchaConfig && captchaConfig.get('required');
  if (!isCaptchaRequired) {
    return true;
  }
  var captcha = c.getFieldValue(m, 'captcha');
  //captcha required and missing
  if (!captcha) {
    return false;
  }
  params['captcha'] = captcha;
  fields.push('captcha');
  return true;
}

/**
 * Get a new challenge and display the new captcha image.
 *
 * @param {number} id The id of the Lock instance.
 * @param {Boolean} isPasswordless Whether the captcha is being rendered in a passwordless flow.
 * @param {boolean} wasInvalid A boolean indicating if the previous captcha was invalid.
 * @param {Function} [next] A callback.
 */
function swapCaptcha(id, isPasswordless, wasInvalid, next) {
  if (isPasswordless) {
    return _web_api.default.getPasswordlessChallenge(id, function (err, newCaptcha) {
      if (!err && newCaptcha) {
        (0, _index3.swap)(_index3.updateEntity, 'lock', id, l.setPasswordlessCaptcha, newCaptcha, wasInvalid);
      }
      if (next) {
        next();
      }
    });
  }
  return _web_api.default.getChallenge(id, function (err, newCaptcha) {
    if (!err && newCaptcha) {
      (0, _index3.swap)(_index3.updateEntity, 'lock', id, l.setCaptcha, newCaptcha, wasInvalid);
    }
    if (next) {
      next();
    }
  });
}
