import { manufacturerReportCurrencyFormatter } from "../../utils/manufacturerReportFormatters";
import { ColDef, ColGroupDef } from "ag-grid-community";

export const ATTEMPTED_FILLS_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name" },
      { field: "Pharmacy NPI", hide: true },
      { field: "Program Start Date", hide: true }
    ],
  },
  {
    headerName: "Prescription",
    children: [
      { field: "Rx Number" },
      { field: "Dispensed Drug Name" },
      { field: "Dispensed NDC", hide: true },
      { field: "Dispense Status" },
      { field: "Fill Adjudication Date" },
      // { field: "Rx Written Date", hide: true },
      { field: "Prescribed Drug Name", hide: true },
      { field: "Prescribed NDC", hide: true },
      { field: "Dispensed Quantity" },
      { field: "Refill Number" },
    ],
  },
  {
    headerName: "Prescriber",
    children: [
      { field: "Prescriber Name" },
      { field: "Prescriber NPI" },
      { field: "Prescriber Zip" },
    ],
  },
  {
    headerName: "Claim Info",
    children: [
      { field: "Last OCC", headerName: "OCC" },
      { field: "Was PBM Rejection Resolved" },
      { field: "Rejected due to PA Required" },
      { field: "Rejected due to PA Required; Coupon Applied" },
      {
        field: "Pharmacy Gross Revenue",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Manufacturer Contribution (Fill Level)",
        headerName: "Manufacturer Contribution",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Third Party Contribution (Fill Level)",
        headerName: "3rd-Party Contribution",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Patient Pay (Before Coupon)",
        headerName: "Patient Pay Before Coupon, for Covered Claims",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Patient Pay (Fill Level)",
        headerName: "Patient Pay After Coupon",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName:
      "Unapplied Claim Info - Third-Party Primary Payer Data for Abandoned & Transferred Fills",
    children: [
      {
        field: "Third Party Contribution for Abandoned & Transferred",
        headerName: "3rd-Party Contribution for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Patient Pay - Before Coupon for Abandoned & Transferred",
        headerName:
          "Patient Pay Before Coupon for Abandoned & Transferred, for Covered Fills",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Pharmacy Gross Revenue for Abandoned & Transferred",
        headerName: "Pharmacy Gross Revenue for Abandoned & Transferred",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Payer",
    children: [
      { field: "Primary Plan Type" },
      { field: "Primary Payer Processor", hide: true },
      { field: "Primary Payer Name", hide: true },
      { field: "Primary BIN" },
      { field: "Primary PCN", hide: true },
      { field: "Primary Group ID", hide: true },
      { field: "Primary Payer Plan ID", hide: true },
      { field: "Primary Payer Network Reimbursement ID", hide: true },
      { field: "Secondary Plan Type" },
      { field: "Secondary Payer Processor", hide: true },
      { field: "Secondary Payer Name", hide: true },
      { field: "Secondary BIN" },
      { field: "Secondary PCN", hide: true },
      { field: "Secondary Group ID", hide: true },
      { field: "Secondary Payer Plan ID", hide: true },
      { field: "Secondary Payer Network Reimbursement ID", hide: true },
      { field: "Tertiary Plan Type" },
      { field: "Tertiary Payer Processor", hide: true },
      { field: "Tertiary Payer Name", hide: true },
      { field: "Tertiary BIN" },
      { field: "Tertiary PCN", hide: true },
      { field: "Tertiary Group ID", hide: true },
      { field: "Tertiary Payer Plan ID", hide: true },
      { field: "Tertiary Payer Network Reimbursement ID", hide: true },
    ],
  },
];

export const MISSED_FILLS_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name" },
      { field: "Pharmacy NPI", hide: true },
      { field: "Program Start Date", hide: true }
    ],
  },
  {
    headerName: "Prescription",
    children: [
      // { field: "Rx Number" },
      { field: "Refill Number" },
      { field: "Dispensed Drug Name" },
      { field: "Dispensed NDC", hide: true },
      // { field: "Dispensed Same as Prescribed Drug" },
      { field: "Dispensed Quantity" },
      { field: "Total Refills Prescribed" },
      // { field: "Refills Remaining" },
      // {
      //   field: "Fill Adjudication Date",
      //   headerName: "Adjudication Date of Last Dispense",
      // },
      // { field: "Sale Date of Last Dispense" },
      { field: "Days Supply" },
      // { field: "Refill Due Date" },
      // { field: "# Days Past Refill Due Date", sort: "desc" },
      // { field: "Rx Written Date", hide: true },
      { field: "Prescribed Drug Name", hide: true },
      { field: "Prescribed NDC", hide: true },
      { field: "Fill Count", aggFunc: "sum", hide: true },
      { field: "Fill ID (PharmacyNPI-RxNumber-Refill)", hide: true },
    ],
  },
  {
    headerName: "Claim Info of Most Recent Dispense",
    children: [
      { field: "Last OCC", headerName: "OCC of Last Dispense", sort: "desc" },
      {
        field: "Pharmacy Gross Revenue",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Manufacturer Contribution (Fill Level)",
        headerName: "Manufacturer Contribution",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Third Party Contribution (Fill Level)",
        headerName: "3rd-Party Contribution",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Patient Pay (Before Coupon)",
        headerName: "Patient Pay Before Coupon, for Covered Fills",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Patient Pay (Fill Level)",
        headerName: "Patient Pay After Coupon",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Payer",
    children: [
      { field: "Primary Plan Type" },
      { field: "Primary Payer Processor", hide: true },
      { field: "Primary Payer Name", hide: true },
      { field: "Primary BIN" },
      { field: "Primary PCN", hide: true },
      { field: "Primary Group ID", hide: true },
      { field: "Primary Payer Plan ID", hide: true },
      { field: "Primary Payer Network Reimbursement ID", hide: true },
      { field: "Secondary Plan Type" },
      { field: "Secondary Payer Processor", hide: true },
      { field: "Secondary Payer Name", hide: true },
      { field: "Secondary BIN" },
      { field: "Secondary PCN", hide: true },
      { field: "Secondary Group ID", hide: true },
      { field: "Secondary Payer Plan ID", hide: true },
      { field: "Secondary Payer Network Reimbursement ID", hide: true },
      { field: "Tertiary Plan Type" },
      { field: "Tertiary Payer Processor", hide: true },
      { field: "Tertiary Payer Name", hide: true },
      { field: "Tertiary BIN" },
      { field: "Tertiary PCN", hide: true },
      { field: "Tertiary Group ID", hide: true },
      { field: "Tertiary Payer Plan ID", hide: true },
      { field: "Tertiary Payer Network Reimbursement ID", hide: true },
    ],
  },
  {
    headerName: "Prescriber",
    children: [
      { field: "Prescriber Name" },
      { field: "Prescriber NPI" },
      { field: "Prescriber Zip" },
    ],
  },
];
