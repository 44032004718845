import React, { useCallback } from "react";
import "twin.macro";
import type {
  ManufacturerStoreAddToCart,
  ManufacturerStoreRemoveFromCart,
} from "../../Catalog.constants";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { IconCheck } from "../../../../../../components/rxLibrary/icons";

export function CatalogItemButton({
  ndc,
  disabled,
  isInCart,
  addToCart,
  removeFromCart,
}: {
  ndc: string;
  disabled: boolean;
  isInCart: boolean;
  addToCart: ManufacturerStoreAddToCart;
  removeFromCart: ManufacturerStoreRemoveFromCart;
}) {
  const handleAddToCart = useCallback(() => {
    addToCart(ndc);
  }, [ndc, addToCart]);

  const handleRemoveFromCart = useCallback(() => {
    removeFromCart(ndc);
  }, [ndc, removeFromCart]);

  if (disabled) {
    return (
      <Button variant="secondary" disabled>
        Unavailable
      </Button>
    );
  }

  if (isInCart) {
    return (
      <Button
        icon={<IconCheck />}
        variant="text-1"
        onClick={handleRemoveFromCart}
      >
        In Cart
      </Button>
    );
  }

  return (
    <Button variant="secondary" onClick={handleAddToCart}>
      Add to Cart
    </Button>
  );
}
