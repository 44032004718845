import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminRefillMappings } from "./AdminRefillMappings";
import { CreateAdminRefillMapping } from "./CreateAdminRefillMapping";

const PATH = "/admin/refill-mapping";
const PATH_CREATE = `${PATH}/new`;

export function RefillMappingRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminRefillMappings} exact />
      <Route path={PATH_CREATE} component={CreateAdminRefillMapping} exact />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
