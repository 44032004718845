import dayjs, { Dayjs } from "dayjs";

export function formatPurchaseByToDayjs(dateStr?: string): Dayjs | undefined {
  if (!dateStr) return;

  const date = dayjs(dateStr);
  if (!date.isValid()) return;

  const isFutureDay = date.isAfter(new Date(), "day");
  if (isFutureDay) return date;
}
