import { useRef, useMemo, useEffect, useState } from "react";
import throttle from "lodash/throttle";

export function useScrollOverlay() {
  const ref = useRef<HTMLDivElement>(null);
  const [hasScrollOverlay, setHasScrollOverlay] = useState(false);

  const updateHasScrollOverlay = useMemo(() => {
    return throttle(() => {
      const newHasScrollOverlay = displayScrollOverlay(ref.current);
      setHasScrollOverlay(newHasScrollOverlay);
    }, 100);

    function displayScrollOverlay(itemsWrapper?: HTMLElement | null) {
      if (!itemsWrapper) return false;

      const isScrollable =
        itemsWrapper.scrollHeight > itemsWrapper.clientHeight;
      if (!isScrollable) return false;

      const isAtBottom =
        Math.abs(
          itemsWrapper.scrollHeight -
            itemsWrapper.scrollTop -
            itemsWrapper.clientHeight
        ) < 1;

      return !isAtBottom;
    }
  }, []);

  useEffect(() => {
    const el = ref.current;
    if (!el) {
      console.error("Scroll overlay can't be used without a ref");
      return;
    }

    const observer = new ResizeObserver(updateHasScrollOverlay);
    observer.observe(el);
    el.addEventListener("scroll", updateHasScrollOverlay);

    return () => {
      observer.disconnect();
      el?.removeEventListener("scroll", updateHasScrollOverlay);
    };
  }, [ref.current]);

  return { ref, hasScrollOverlay };
}

export function ScrollOverlay() {
  return (
    <div tw="relative">
      <div
        tw="absolute bottom-0 w-full h-[99px] pointer-events-none"
        style={{
          backgroundImage: "linear-gradient(to bottom, #0000, #00000049)",
        }}
      />
    </div>
  );
}
