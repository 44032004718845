import { useState, useCallback, useEffect } from "react";
import {
  formatShippingConfig,
  FormShippingConfig,
} from "../AdminSupplierMappingForm.utils";

export function useShippingConfigOverrideVisibility(
  initialShippingConfig?: FormShippingConfig
) {
  const [shippingConfigOverrideIsVisible, setShippingConfigOverrideIsVisible] =
    useState(false);

  const showShippingConfigOverride = useCallback(() => {
    setShippingConfigOverrideIsVisible(true);
  }, []);

  const hideShippingConfigOverride = useCallback(() => {
    setShippingConfigOverrideIsVisible(false);
  }, []);

  useEffect(() => {
    if (!formatShippingConfig(initialShippingConfig)) return;
    setShippingConfigOverrideIsVisible(true);
  }, [initialShippingConfig]);

  return {
    shippingConfigOverrideIsVisible,
    showShippingConfigOverride,
    hideShippingConfigOverride,
  };
}
