import { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import { useShoppingCartServerUpdater } from "../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { useStorageState } from "../../contexts/shoppingContexts/StorageContext";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { Button } from "../rxLibrary/buttons";

export function GoToP2Button({
  children,
  disabled,
  className,
}: {
  children: React.ReactNode;
  disabled: boolean;
  className?: string;
}) {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { pushBlob } = useShoppingCartServerUpdater();
  const { waitButtonMode } = useStorageState();

  const tryToGoToShoppingListTwo = useCallback(() => {
    if (!enhancedClientActive && !user?.is_admin) {
      window.alert(
        "Please use the DaylightRx Desktop App to complete your purchases."
      );
    } else {
      pushBlob({ second: true, force: false });
      history.push("/shoppingListTwo");
    }
  }, [user, history, enhancedClientActive, pushBlob]);

  return (
    <Button
      tw="space-x-[7px] h-[54px]"
      icon={
        <img
          src="/assets/components/icon-shopping-list.svg"
          alt="Shopping List"
        />
      }
      onClick={tryToGoToShoppingListTwo}
      disabled={disabled || waitButtonMode}
      className={className}
    >
      {children}
    </Button>
  );
}
