import isNil from "lodash/isNil";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../utilities/numbers/currency";

type FormaterFunctionType = (value: any) => string | undefined;

function isZeroValue(value: any) {
  const valueNum = Number(value);
  if (isNaN(valueNum)) return true;
  const isZero = valueNum === 0;
  return isZero;
}

function isEmptyValue(value: any) {
  if (!value) return true;

  const isZero = isZeroValue(value);
  return isZero;
}

function formatEmptyValue(value: any, zeroValue: string) {
  if (isNil(value)) return "";

  const result = isZeroValue(value) ? zeroValue : "";
  return result;
}

export function manufacturerReportCurrencyFormatter(value: any): string {
  if (isEmptyValue(value)) {
    const result = formatEmptyValue(value, "$0");
    return result;
  }

  const numValue = typeof value === "number" ? value : parseFloat(value);
  return currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(numValue);
}

export function manufacturerReportPercentFormatter(value: number, precision = 2): string {
  if (isEmptyValue(value)) {
    const result = formatEmptyValue(value, "0%");
    return result;
  }

  const result = `${(value * 100.0).toFixed(precision)}%`;
  return result;
}

export function manufacturerReportNumberFormatter(value: any): string {
  if (isEmptyValue(value)) {
    const result = formatEmptyValue(value, "0");
    return result;
  }

  const numValue = typeof value === "number" ? value : parseFloat(value);
  return currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(numValue, true);
}

export function expandHereValueGetter() {
  return "Expand Here";
}

export function manufacturerReportFormatOrUndefined(value: any, formater: FormaterFunctionType): string | undefined {
  if (isNil(value)) return undefined;

  return formater(value);
}
