import type { PrintableCart } from "./printableCart.constants";
import { PRINTABLE_CART_KEY } from "../constants";
import { getLocalStorageValue } from "../getLocalStorageValue";

export function getLocalStoragePrintableCart() {
  const supplierOrders = getLocalStorageValue<PrintableCart | undefined>(
    PRINTABLE_CART_KEY,
    undefined
  );

  return supplierOrders;
}
