import React from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { Card } from "./Card";

export function SuppliersAndPasswordsCard() {
  return (
    <Card>
      <div tw="flex flex-col items-center">
        <img
          tw="mb-[28px]"
          src="/assets/pages/home/suppliers-and-passwords.svg"
        />

        <Text tw="mb-[18px]" weight="bold" size={21}>
          MY SUPPLIERS & PASSWORDS
        </Text>

        <Text tw="max-w-[295px] mx-auto mb-[24px]" color="grey-1" center>
          See profitable refill opportunities, both missed and upcoming.
        </Text>

        <Link to="/supplierAccounts">
          <Button>My Suppliers & Passwords</Button>
        </Link>
      </div>
    </Card>
  );
}
