import React, { ReactNode } from "react";
import "twin.macro";
import { Text } from "../../rxLibrary/typography";

export function FormCategory({
  title,
  children,
}: {
  children: ReactNode;
  title?: string;
}) {
  return (
    <>
      {!!title && (
        <Text tw="mb-4" weight="bold" block>
          {title}
        </Text>
      )}

      <div tw="grid grid-cols-2 gap-x-8">{children}</div>
    </>
  );
}
