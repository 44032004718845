import React from "react";
import "twin.macro";
import { Container } from "../../components/containers/Container";
import DotLoader from "../../components/loaders/DotLoader/DotLoader";
import { RequestState } from "../../utilities/types";
import { getDispensedText } from "../../utilities/search";
import { SearchResultsHeaderBar } from "../../components/SearchResultsHeaderBar/SearchResultsHeaderBar";
import { NDCResults } from "./NDCResults/NDCResults";
import { useCompare } from "./useCompare/useCompare";
import { QuoteStateError } from "./QuoteStateError";

export function WebCompare() {
  const { ndc, from, drugName, quoteState } = useCompare();
  const {
    errorDetail,
    suggestedDrug,
    itemListsByNDC = [],
    quoteRequestState,
  } = quoteState;

  return (
    <>
      <SearchResultsHeaderBar
        dispensed={quoteState.quoteRequestState === RequestState.COMPLETED}
        dispensedValue={
          quoteState.quoteRequestState === RequestState.COMPLETED &&
          itemListsByNDC[0]
            ? getDispensedText(itemListsByNDC[0].items)
            : null
        }
        textSearch={from === "selectDrug"}
        ndc={from === "selectDrug" ? drugName || "" : ndc}
        searchDisabled={quoteRequestState === RequestState.PENDING}
      />

      <div tw="pt-12">
        {quoteRequestState === RequestState.ERROR && (
          <QuoteStateError ndc={ndc} errorDetail={errorDetail} />
        )}

        {quoteRequestState === RequestState.PENDING && (
          <Container tw="text-center text-lg">
            <DotLoader />
            Searching...
          </Container>
        )}

        {quoteRequestState === RequestState.COMPLETED && (
          <NDCResults
            ndc={ndc}
            itemsByNDC={itemListsByNDC}
            suggestedDrug={suggestedDrug}
          />
        )}
      </div>
    </>
  );
}
