export const testPayload = {
  "supplier": {
    "displayName": "Kinray",
    "id": 3,
    "isActive": true,
    "name": "KINRAY",
    "offlinePurchasing": false,
    "scraperConfig": {},
    "shippingConfig": {
      "cutoffTimes": [
        [
          "12:30",
          "20:30"
        ],
        [
          "12:30",
          "20:30"
        ],
        [
          "12:30",
          "20:30"
        ],
        [
          "12:30",
          "20:30"
        ],
        [
          "12:30",
          "18:30"
        ],
        [
          "12:30",
          "20:30"
        ],
        [
          "12:30",
          "20:30"
        ]
      ],
      "minOrder": 250,
      "notes": [],
      "version": 1
    }
  },
  "supplierOrder": {
    "buyingCost": 200.01,
    "items": [
      {
        "alternatives": [
          {
            "catalogInfo": {
              "expirationDate": "2024-08-01T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://www.realvalueproducts.com/card-view?filter=63304082805",
              "onBackOrder": false,
              "price": 8.07,
              "supplier": "REALVALUE_RX",
              "supplierDisplayName": "Real Value",
              "supplierItemNumber": "281980"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-08-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://ndcdrug.com/",
              "onBackOrder": false,
              "price": 8.55,
              "supplier": "NDC_DISTRIBUTORS",
              "supplierDisplayName": "NDC Distributors",
              "supplierItemNumber": "003041"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://integral-rx.com/wps/portal/select/IntegralRx/IntegralRxordering",
              "onBackOrder": false,
              "price": 1.71,
              "supplier": "INTEGRALRX",
              "supplierDisplayName": "Integral Rx",
              "supplierItemNumber": "570408"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082890",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://integral-rx.com/wps/portal/select/IntegralRx/IntegralRxordering",
              "onBackOrder": false,
              "price": 10.38,
              "supplier": "INTEGRALRX",
              "supplierDisplayName": "Integral Rx",
              "supplierItemNumber": "914598"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051205",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.keysourceusa.com/search.aspx?searchterm=63304082805",
              "onBackOrder": false,
              "price": 10.52,
              "supplier": "KEY_SOURCE",
              "supplierDisplayName": "KeySource",
              "supplierItemNumber": "129916"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.qkrx.com/cgi/cgictl.pgm?PN=BSTIT&TC=&LUTY=BYKW&LUSTR=63304082805",
              "onBackOrder": false,
              "price": 10.6,
              "supplier": "QK",
              "supplierDisplayName": "QK",
              "supplierItemNumber": "69687"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=333175",
              "onBackOrder": false,
              "price": 10.64,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "333175"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051205",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-07-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://www.southpointe.us/pedigree",
              "onBackOrder": false,
              "price": 1.8,
              "supplier": "SOUTH_POINTE",
              "supplierDisplayName": "South Pointe",
              "supplierItemNumber": "137740"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Biocon",
              "name": "Atorvastatin Calcium",
              "ndc": "70377002811",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-08-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://blupax.odoo.com/results?search=205264&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
              "onBackOrder": false,
              "price": 10.92,
              "supplier": "BLUPAX",
              "supplierDisplayName": "BluPax",
              "supplierItemNumber": "205264"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-05-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://toprx.com/filterSearch?adv=true&cid=0&mid=0&vid=0&q=63304082805&sid=true&isc=true",
              "onBackOrder": false,
              "price": 11.05,
              "supplier": "TOP_RX",
              "supplierDisplayName": "TopRx",
              "supplierItemNumber": "02-18225"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-12-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://www.southpointe.us/pedigree",
              "onBackOrder": false,
              "price": 1.85,
              "supplier": "SOUTH_POINTE",
              "supplierDisplayName": "South Pointe",
              "supplierItemNumber": "120180"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002390",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 11.3,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10228900"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002305",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 11.31,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10250559"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Lupin",
              "name": "Atorvastatin Calcium",
              "ndc": "68180063602",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2023-07-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://blupax.odoo.com/results?search=113854&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
              "onBackOrder": false,
              "price": 11.79,
              "supplier": "BLUPAX",
              "supplierDisplayName": "BluPax",
              "supplierItemNumber": "113854"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=321591",
              "onBackOrder": false,
              "price": 11.81,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "321591"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=329566",
              "onBackOrder": false,
              "price": 12.06,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "329566"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002305",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2023-03-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://blupax.odoo.com/results?search=205263&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
              "onBackOrder": false,
              "price": 2.08,
              "supplier": "BLUPAX",
              "supplierDisplayName": "BluPax",
              "supplierItemNumber": "205263"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082890",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "expirationDate": "2023-07-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://ndcdrug.com/",
              "onBackOrder": false,
              "price": 12.5,
              "supplier": "NDC_DISTRIBUTORS",
              "supplierDisplayName": "NDC Distributors",
              "supplierItemNumber": "SD0221"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 12.94,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10103252"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-03-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://blupax.odoo.com/results?search=209488&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
              "onBackOrder": false,
              "price": 13.25,
              "supplier": "BLUPAX",
              "supplierDisplayName": "BluPax",
              "supplierItemNumber": "209488"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 13.69,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10272516"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "43598083105",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 13.69,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10228320"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "55111012205",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 13.69,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "5809702"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "43598083105",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=332984",
              "onBackOrder": false,
              "price": 16.02,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "332984"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-07-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://toprx.com/filterSearch?adv=true&cid=0&mid=0&vid=0&q=16729004517&sid=true&isc=true",
              "onBackOrder": false,
              "price": 17.22,
              "supplier": "TOP_RX",
              "supplierDisplayName": "TopRx",
              "supplierItemNumber": "02-13939"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 2.89,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10106273"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Apotex",
              "name": "Atorvastatin Calcium",
              "ndc": "60505257909",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 2.89,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10106273"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Apotex",
              "name": "Atorvastatin Calcium",
              "ndc": "60505257909",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 2.9,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "4540597"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082890",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-07-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://www.southpointe.us/pedigree",
              "onBackOrder": false,
              "price": 17.5,
              "supplier": "SOUTH_POINTE",
              "supplierDisplayName": "South Pointe",
              "supplierItemNumber": "32370"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=320148",
              "onBackOrder": false,
              "price": 3.13,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "320148"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082890",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-02-29T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://www.southpointe.us/pedigree",
              "onBackOrder": false,
              "price": 18.85,
              "supplier": "SOUTH_POINTE",
              "supplierDisplayName": "South Pointe",
              "supplierItemNumber": "139010"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Biocon",
              "name": "Atorvastatin Calcium",
              "ndc": "70377002813",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-03-01T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://www.realvalueproducts.com/card-view?filter=75834025601",
              "onBackOrder": false,
              "price": 19.31,
              "supplier": "REALVALUE_RX",
              "supplierDisplayName": "Real Value",
              "supplierItemNumber": "289929"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=333223",
              "onBackOrder": false,
              "price": 20.45,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "333223"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Biocon",
              "name": "Atorvastatin Calcium",
              "ndc": "70377002813",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 21.88,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "4764270"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Apotex",
              "name": "Atorvastatin Calcium",
              "ndc": "60505257908",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-03-31T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://astordrugs.qwikpoint.net/portal/products.aspx?srch=67877051210",
              "onBackOrder": false,
              "price": 22.27,
              "supplier": "ASTOR",
              "supplierDisplayName": "Astor",
              "supplierItemNumber": "24575"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051210",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2023-11-30T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://blupax.odoo.com/results?search=114113&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
              "onBackOrder": false,
              "price": 22.79,
              "supplier": "BLUPAX",
              "supplierDisplayName": "BluPax",
              "supplierItemNumber": "114113"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Biocon",
              "name": "Atorvastatin Calcium",
              "ndc": "70377002813",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=391963",
              "onBackOrder": false,
              "price": 23.18,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "391963"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.qkrx.com/cgi/cgictl.pgm?PN=BSTIT&TC=&LUTY=BYKW&LUSTR=16729004517",
              "onBackOrder": false,
              "price": 23.5,
              "supplier": "QK",
              "supplierDisplayName": "QK",
              "supplierItemNumber": "70572"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=332515",
              "onBackOrder": false,
              "price": 23.66,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "332515"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002399",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.keysourceusa.com/search.aspx?searchterm=75834025601",
              "onBackOrder": false,
              "price": 24.11,
              "supplier": "KEY_SOURCE",
              "supplierDisplayName": "KeySource",
              "supplierItemNumber": "133930"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.andanet.com/search?q=331366",
              "onBackOrder": false,
              "price": 24.14,
              "supplier": "ANDA",
              "supplierDisplayName": "Anda",
              "supplierItemNumber": "331366"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051210",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://integral-rx.com/wps/portal/select/IntegralRx/IntegralRxordering",
              "onBackOrder": false,
              "price": 24.99,
              "supplier": "INTEGRALRX",
              "supplierDisplayName": "Integral Rx",
              "supplierItemNumber": "914606"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051210",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 25.12,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10259577"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Accord",
              "name": "Atorvastatin Calcium",
              "ndc": "16729004517",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 29.95,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10106769"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Apotex",
              "name": "Atorvastatin Calcium",
              "ndc": "60505257908",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 29.95,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10106769"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Apotex",
              "name": "Atorvastatin Calcium",
              "ndc": "60505257908",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "expirationDate": "2024-06-30T00:00:00+00:00",
              "isRebatedItem": false,
              "link": "https://blupax.odoo.com/results?search=207516&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
              "onBackOrder": false,
              "price": 30.54,
              "supplier": "BLUPAX",
              "supplierDisplayName": "BluPax",
              "supplierItemNumber": "207516"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051210",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 37,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "5605761"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MLB01730.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Micro Laboratories",
              "name": "Atorvastatin Calcium",
              "ndc": "42571017310",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 6.19,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10182444"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/CIP09450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Cipla",
              "name": "Atorvastatin Calcium",
              "ndc": "69097094505",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 6.19,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "5403647"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/CIP09450.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Cipla",
              "name": "Atorvastatin Calcium",
              "ndc": "69097094505",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 6.53,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10112854"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "GREENSTONE",
              "name": "Atorvastatin Calcium",
              "ndc": "59762015601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 6.53,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10112854"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "GREENSTONE",
              "name": "Atorvastatin Calcium",
              "ndc": "59762015601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "link": "https://www.qkrx.com/cgi/cgictl.pgm?PN=BSTIT&TC=&LUTY=BYKW&LUSTR=63304082890",
              "onBackOrder": false,
              "price": 7.5,
              "supplier": "QK",
              "supplierDisplayName": "QK",
              "supplierItemNumber": "60776"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082890",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 7.79,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10273504"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "43598083190",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 7.79,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10121993"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "55111012290",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 7.79,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "4750543"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "55111012290",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 50.45,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10229762"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Biocon",
              "name": "Atorvastatin Calcium",
              "ndc": "70377002813",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 8.7,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "4816674"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "GREENSTONE",
              "name": "Atorvastatin Calcium",
              "ndc": "59762015601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 55.95,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "5743273"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 57.03,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10272516"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "43598083105",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 10.27,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10273504"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Dr Reddy's",
              "name": "Atorvastatin Calcium",
              "ndc": "43598083190",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 10.27,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10229103"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002390",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 61.65,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10112855"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "GREENSTONE",
              "name": "Atorvastatin Calcium",
              "ndc": "59762015602",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 61.65,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10112855"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "GREENSTONE",
              "name": "Atorvastatin Calcium",
              "ndc": "59762015602",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 63.41,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10233481"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MLB01730.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Micro Laboratories",
              "name": "Atorvastatin Calcium",
              "ndc": "42571017310",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 63.41,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10233481"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MLB01730.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Micro Laboratories",
              "name": "Atorvastatin Calcium",
              "ndc": "42571017310",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 16.8,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10118585"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "American Health Packaging",
              "name": "Atorvastatin Calcium",
              "ndc": "68084009801",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 100,
              "uom": "Each"
            },
            "numPackages": 5
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 16.8,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10118585"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "American Health Packaging",
              "name": "Atorvastatin Calcium",
              "ndc": "68084009801",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 100,
              "uom": "Each"
            },
            "numPackages": 5
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 87.7,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10103252"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 87.7,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10228900"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002305",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 92.65,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10150090"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MYN39510.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Mylan",
              "name": "Atorvastatin Calcium",
              "ndc": "00378395105",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 9.78,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10167217"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "AvPAK",
              "name": "Atorvastatin Calcium",
              "ndc": "50268009415",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 50,
              "uom": "Each"
            },
            "numPackages": 10
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 19.64,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10106187"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Major",
              "name": "Atorvastatin Calcium",
              "ndc": "00904629161",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 100,
              "uom": "Each"
            },
            "numPackages": 5
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 10.12,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10236058"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Major",
              "name": "Atorvastatin Calcium",
              "ndc": "00904629106",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 50,
              "uom": "Each"
            },
            "numPackages": 10
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 102.61,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10257682"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 109.14,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10257682"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Nivagen",
              "name": "Atorvastatin Calcium",
              "ndc": "75834025601",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 1000,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": true,
              "link": "https://kinrayweblink.cardinalhealth.com/",
              "onBackOrder": false,
              "price": 21.28,
              "supplier": "KINRAY",
              "supplierDisplayName": "Kinray",
              "supplierItemNumber": "5533682"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Ascend",
              "name": "Atorvastatin Calcium",
              "ndc": "67877051290",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 24.65,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10101937"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082890",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 24.65,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10229103"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Novadoz",
              "name": "Atorvastatin Calcium",
              "ndc": "72205002390",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 31.41,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10131267"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MYN39510.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Mylan",
              "name": "Atorvastatin Calcium",
              "ndc": "51079020920",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 100,
              "uom": "Each"
            },
            "numPackages": 5
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 165.8,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10250559"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Lupin",
              "name": "Atorvastatin Calcium",
              "ndc": "68180063602",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "numPackages": 1
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 29.85,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10249919"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Lupin",
              "name": "Atorvastatin Calcium",
              "ndc": "68180063609",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 31.74,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10127241"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MYN39510.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Mylan",
              "name": "Atorvastatin Calcium",
              "ndc": "00378395177",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 31.74,
              "supplier": "ABC",
              "supplierDisplayName": "ABC",
              "supplierItemNumber": "10249919"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Lupin",
              "name": "Atorvastatin Calcium",
              "ndc": "68180063609",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 90,
              "uom": "Each"
            },
            "numPackages": 6
          },
          {
            "catalogInfo": {
              "isRebatedItem": false,
              "onBackOrder": false,
              "price": 19.99,
              "supplier": "SMARTSOURCE",
              "supplierDisplayName": "ABC SmartSource",
              "supplierItemNumber": "10167217"
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "AvPAK",
              "name": "Atorvastatin Calcium",
              "ndc": "50268009415",
              "packageDescription": "Box",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitDoseDescription": "Unit-Dose Packaging",
              "unitQuantity": 1,
              "unitSize": 50,
              "uom": "Each"
            },
            "numPackages": 10
          }
        ],
        "buyingReason": "requested",
        "catalogInfo": {
          "isRebatedItem": true,
          "link": "https://kinrayweblink.cardinalhealth.com/",
          "onBackOrder": false,
          "price": 11.29,
          "supplier": "KINRAY",
          "supplierDisplayName": "Kinray",
          "supplierItemNumber": "4587556"
        },
        "drug": {
          "form": "Tablet",
          "genericProductIdentifier": "39400010100320",
          "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
          "isBrand": false,
          "isNarrowTherapeuticIndex": false,
          "isRx": true,
          "manufacturer": "Sun Pharma",
          "name": "Atorvastatin Calcium",
          "ndc": "63304082805",
          "packageDescription": "Bottle",
          "routeOfAdministration": "Oral",
          "strength": "20",
          "strengthUnit": "MG",
          "unitQuantity": 1,
          "unitSize": 500,
          "uom": "Each"
        },
        "manufacturerChanged": false,
        "numPackages": 1,
        "numPackagesIncreased": false,
        "originalDrugs": {
          "c1675689525930": {
            "form": "Tablet",
            "genericProductIdentifier": "39400010100320",
            "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
            "isBrand": false,
            "isNarrowTherapeuticIndex": false,
            "isRx": true,
            "manufacturer": "Sun Pharma",
            "name": "Atorvastatin Calcium",
            "ndc": "63304082805",
            "packageDescription": "Bottle",
            "routeOfAdministration": "Oral",
            "strength": "20",
            "strengthUnit": "MG",
            "unitQuantity": 1,
            "unitSize": 500,
            "uom": "Each"
          }
        },
        "originalNumPackages": 1,
        "packSizeChanged": false,
        "rxNumbers": [
          "c1675689525930"
        ],
        "supplierId": 3,
        "rxList": [
          {
            "boh": 552,
            "catalogInfos": [],
            "dispensesStats": {
              "last180Days": {
                "numberOfPrescriptions": 170,
                "numberOfUnits": 9420
              },
              "last30Days": {
                "numberOfPrescriptions": 27,
                "numberOfUnits": 1170
              },
              "last90Days": {
                "numberOfPrescriptions": 77,
                "numberOfUnits": 4110
              }
            },
            "drug": {
              "form": "Tablet",
              "genericProductIdentifier": "39400010100320",
              "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
              "isBrand": false,
              "isNarrowTherapeuticIndex": false,
              "isRx": true,
              "manufacturer": "Sun Pharma",
              "name": "Atorvastatin Calcium",
              "ndc": "63304082805",
              "packageDescription": "Bottle",
              "routeOfAdministration": "Oral",
              "strength": "20",
              "strengthUnit": "MG",
              "unitQuantity": 1,
              "unitSize": 500,
              "uom": "Each"
            },
            "eoh": 552,
            "fillDate": "2023-02-06T13:18:45.930Z",
            "key": "c1675689525930",
            "manufactutrer": true,
            "mostRecentlyDispensedDate": "2023-02-03T17:43:00.310000",
            "noManufacturerPreference": false,
            "packQuantity": 1,
            "packSize": true,
            "processedAt": "2/6/2023, 8:21:47 AM",
            "status": "processed",
            "supplierId": 3
          }
        ]
      },
      {
        "alternatives": [],
        "buyingReason": "meet_min_ship",
        "catalogInfo": {
          "isRebatedItem": true,
          "link": "https://kinrayweblink.cardinalhealth.com/",
          "onBackOrder": false,
          "price": 13.69,
          "supplier": "KINRAY",
          "supplierDisplayName": "Kinray",
          "supplierItemNumber": "5809702"
        },
        "drug": {
          "form": "Tablet",
          "genericProductIdentifier": "39400010100320",
          "isBrand": false,
          "isNarrowTherapeuticIndex": false,
          "isRx": true,
          "manufacturer": "Dr Reddy's",
          "name": "Atorvastatin Calcium",
          "ndc": "43598083105",
          "packageDescription": "Bottle",
          "routeOfAdministration": "Oral",
          "strength": "20",
          "strengthUnit": "MG",
          "unitQuantity": 1,
          "unitSize": 500,
          "uom": "Each"
        },
        "manufacturerChanged": false,
        "numPackages": 3,
        "numPackagesIncreased": true,
        "originalDrugs": {},
        "originalNumPackages": 0,
        "packSizeChanged": false,
        "rxNumbers": [
          "c43598083105"
        ],
        "numPackagesIncreasedTo": 3,
        "supplierId": 3,
        "rxList": []
      },
      {
        "alternatives": [],
        "buyingReason": "meet_min_ship",
        "catalogInfo": {
          "isRebatedItem": true,
          "link": "https://kinrayweblink.cardinalhealth.com/",
          "onBackOrder": false,
          "price": 55.95,
          "supplier": "KINRAY",
          "supplierDisplayName": "Kinray",
          "supplierItemNumber": "5743273"
        },
        "drug": {
          "form": "Tablet",
          "genericProductIdentifier": "39400010100320",
          "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
          "isBrand": false,
          "isNarrowTherapeuticIndex": false,
          "isRx": true,
          "manufacturer": "Nivagen",
          "name": "Atorvastatin Calcium",
          "ndc": "75834025601",
          "packageDescription": "Bottle",
          "routeOfAdministration": "Oral",
          "strength": "20",
          "strengthUnit": "MG",
          "unitQuantity": 1,
          "unitSize": 1000,
          "uom": "Each"
        },
        "manufacturerChanged": false,
        "numPackages": 2,
        "numPackagesIncreased": true,
        "originalDrugs": {},
        "originalNumPackages": 0,
        "packSizeChanged": false,
        "rxNumbers": [
          "c75834025601"
        ],
        "numPackagesIncreasedTo": 2,
        "supplierId": 3,
        "rxList": []
      },
      {
        "alternatives": [],
        "buyingReason": "meet_min_ship",
        "catalogInfo": {
          "isRebatedItem": true,
          "link": "https://kinrayweblink.cardinalhealth.com/",
          "onBackOrder": false,
          "price": 6.19,
          "supplier": "KINRAY",
          "supplierDisplayName": "Kinray",
          "supplierItemNumber": "5403647"
        },
        "drug": {
          "form": "Tablet",
          "genericProductIdentifier": "39400010100320",
          "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/CIP09450.JPG",
          "isBrand": false,
          "isNarrowTherapeuticIndex": false,
          "isRx": true,
          "manufacturer": "Cipla",
          "name": "Atorvastatin Calcium",
          "ndc": "69097094505",
          "packageDescription": "Bottle",
          "routeOfAdministration": "Oral",
          "strength": "20",
          "strengthUnit": "MG",
          "unitQuantity": 1,
          "unitSize": 90,
          "uom": "Each"
        },
        "manufacturerChanged": false,
        "numPackages": 2,
        "numPackagesIncreased": true,
        "originalDrugs": {},
        "originalNumPackages": 0,
        "packSizeChanged": false,
        "rxNumbers": [
          "c69097094505"
        ],
        "numPackagesIncreasedTo": 2,
        "supplierId": 3,
        "rxList": []
      },
      {
        "alternatives": [],
        "buyingReason": "meet_min_ship",
        "catalogInfo": {
          "isRebatedItem": true,
          "link": "https://kinrayweblink.cardinalhealth.com/",
          "onBackOrder": false,
          "price": 7.79,
          "supplier": "KINRAY",
          "supplierDisplayName": "Kinray",
          "supplierItemNumber": "4750543"
        },
        "drug": {
          "form": "Tablet",
          "genericProductIdentifier": "39400010100320",
          "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
          "isBrand": false,
          "isNarrowTherapeuticIndex": false,
          "isRx": true,
          "manufacturer": "Dr Reddy's",
          "name": "Atorvastatin Calcium",
          "ndc": "55111012290",
          "packageDescription": "Bottle",
          "routeOfAdministration": "Oral",
          "strength": "20",
          "strengthUnit": "MG",
          "unitQuantity": 1,
          "unitSize": 90,
          "uom": "Each"
        },
        "manufacturerChanged": false,
        "numPackages": 3,
        "numPackagesIncreased": true,
        "originalDrugs": {},
        "originalNumPackages": 0,
        "packSizeChanged": false,
        "rxNumbers": [
          "c55111012290"
        ],
        "numPackagesIncreasedTo": 3,
        "supplierId": 3,
        "rxList": []
      }
    ],
    "shippingCost": 0,
    "supplierId": 3
  },
  "visited": [],
  "savedForLater": [],
  "optimizeCartResponse": {
    "data": {
      "onBackOrderUnavailableItems": [],
      "supplierOrders": [
        {
          "buyingCost": 200.01,
          "items": [
            {
              "alternatives": [
                {
                  "catalogInfo": {
                    "expirationDate": "2024-08-01T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://www.realvalueproducts.com/card-view?filter=63304082805",
                    "onBackOrder": false,
                    "price": 8.07,
                    "supplier": "REALVALUE_RX",
                    "supplierDisplayName": "Real Value",
                    "supplierItemNumber": "281980"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-08-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://ndcdrug.com/",
                    "onBackOrder": false,
                    "price": 8.55,
                    "supplier": "NDC_DISTRIBUTORS",
                    "supplierDisplayName": "NDC Distributors",
                    "supplierItemNumber": "003041"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://integral-rx.com/wps/portal/select/IntegralRx/IntegralRxordering",
                    "onBackOrder": false,
                    "price": 1.71,
                    "supplier": "INTEGRALRX",
                    "supplierDisplayName": "Integral Rx",
                    "supplierItemNumber": "570408"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082890",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://integral-rx.com/wps/portal/select/IntegralRx/IntegralRxordering",
                    "onBackOrder": false,
                    "price": 10.38,
                    "supplier": "INTEGRALRX",
                    "supplierDisplayName": "Integral Rx",
                    "supplierItemNumber": "914598"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051205",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.keysourceusa.com/search.aspx?searchterm=63304082805",
                    "onBackOrder": false,
                    "price": 10.52,
                    "supplier": "KEY_SOURCE",
                    "supplierDisplayName": "KeySource",
                    "supplierItemNumber": "129916"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.qkrx.com/cgi/cgictl.pgm?PN=BSTIT&TC=&LUTY=BYKW&LUSTR=63304082805",
                    "onBackOrder": false,
                    "price": 10.6,
                    "supplier": "QK",
                    "supplierDisplayName": "QK",
                    "supplierItemNumber": "69687"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=333175",
                    "onBackOrder": false,
                    "price": 10.64,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "333175"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051205",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-07-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://www.southpointe.us/pedigree",
                    "onBackOrder": false,
                    "price": 1.8,
                    "supplier": "SOUTH_POINTE",
                    "supplierDisplayName": "South Pointe",
                    "supplierItemNumber": "137740"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Biocon",
                    "name": "Atorvastatin Calcium",
                    "ndc": "70377002811",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-08-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://blupax.odoo.com/results?search=205264&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
                    "onBackOrder": false,
                    "price": 10.92,
                    "supplier": "BLUPAX",
                    "supplierDisplayName": "BluPax",
                    "supplierItemNumber": "205264"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-05-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://toprx.com/filterSearch?adv=true&cid=0&mid=0&vid=0&q=63304082805&sid=true&isc=true",
                    "onBackOrder": false,
                    "price": 11.05,
                    "supplier": "TOP_RX",
                    "supplierDisplayName": "TopRx",
                    "supplierItemNumber": "02-18225"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-12-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://www.southpointe.us/pedigree",
                    "onBackOrder": false,
                    "price": 1.85,
                    "supplier": "SOUTH_POINTE",
                    "supplierDisplayName": "South Pointe",
                    "supplierItemNumber": "120180"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002390",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 11.3,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10228900"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002305",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 11.31,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10250559"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Lupin",
                    "name": "Atorvastatin Calcium",
                    "ndc": "68180063602",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2023-07-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://blupax.odoo.com/results?search=113854&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
                    "onBackOrder": false,
                    "price": 11.79,
                    "supplier": "BLUPAX",
                    "supplierDisplayName": "BluPax",
                    "supplierItemNumber": "113854"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=321591",
                    "onBackOrder": false,
                    "price": 11.81,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "321591"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=329566",
                    "onBackOrder": false,
                    "price": 12.06,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "329566"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002305",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2023-03-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://blupax.odoo.com/results?search=205263&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
                    "onBackOrder": false,
                    "price": 2.08,
                    "supplier": "BLUPAX",
                    "supplierDisplayName": "BluPax",
                    "supplierItemNumber": "205263"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082890",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2023-07-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://ndcdrug.com/",
                    "onBackOrder": false,
                    "price": 12.5,
                    "supplier": "NDC_DISTRIBUTORS",
                    "supplierDisplayName": "NDC Distributors",
                    "supplierItemNumber": "SD0221"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 12.94,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10103252"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-03-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://blupax.odoo.com/results?search=209488&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
                    "onBackOrder": false,
                    "price": 13.25,
                    "supplier": "BLUPAX",
                    "supplierDisplayName": "BluPax",
                    "supplierItemNumber": "209488"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 13.69,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10272516"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "43598083105",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 13.69,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10228320"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "55111012205",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 13.69,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "5809702"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "43598083105",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=332984",
                    "onBackOrder": false,
                    "price": 16.02,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "332984"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-07-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://toprx.com/filterSearch?adv=true&cid=0&mid=0&vid=0&q=16729004517&sid=true&isc=true",
                    "onBackOrder": false,
                    "price": 17.22,
                    "supplier": "TOP_RX",
                    "supplierDisplayName": "TopRx",
                    "supplierItemNumber": "02-13939"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 2.89,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10106273"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Apotex",
                    "name": "Atorvastatin Calcium",
                    "ndc": "60505257909",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 2.89,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10106273"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Apotex",
                    "name": "Atorvastatin Calcium",
                    "ndc": "60505257909",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 2.9,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "4540597"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082890",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-07-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://www.southpointe.us/pedigree",
                    "onBackOrder": false,
                    "price": 17.5,
                    "supplier": "SOUTH_POINTE",
                    "supplierDisplayName": "South Pointe",
                    "supplierItemNumber": "32370"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=320148",
                    "onBackOrder": false,
                    "price": 3.13,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "320148"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082890",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-02-29T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://www.southpointe.us/pedigree",
                    "onBackOrder": false,
                    "price": 18.85,
                    "supplier": "SOUTH_POINTE",
                    "supplierDisplayName": "South Pointe",
                    "supplierItemNumber": "139010"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Biocon",
                    "name": "Atorvastatin Calcium",
                    "ndc": "70377002813",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-03-01T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://www.realvalueproducts.com/card-view?filter=75834025601",
                    "onBackOrder": false,
                    "price": 19.31,
                    "supplier": "REALVALUE_RX",
                    "supplierDisplayName": "Real Value",
                    "supplierItemNumber": "289929"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=333223",
                    "onBackOrder": false,
                    "price": 20.45,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "333223"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Biocon",
                    "name": "Atorvastatin Calcium",
                    "ndc": "70377002813",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 21.88,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "4764270"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Apotex",
                    "name": "Atorvastatin Calcium",
                    "ndc": "60505257908",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-03-31T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://astordrugs.qwikpoint.net/portal/products.aspx?srch=67877051210",
                    "onBackOrder": false,
                    "price": 22.27,
                    "supplier": "ASTOR",
                    "supplierDisplayName": "Astor",
                    "supplierItemNumber": "24575"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051210",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2023-11-30T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://blupax.odoo.com/results?search=114113&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
                    "onBackOrder": false,
                    "price": 22.79,
                    "supplier": "BLUPAX",
                    "supplierDisplayName": "BluPax",
                    "supplierItemNumber": "114113"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Biocon",
                    "name": "Atorvastatin Calcium",
                    "ndc": "70377002813",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=391963",
                    "onBackOrder": false,
                    "price": 23.18,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "391963"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.qkrx.com/cgi/cgictl.pgm?PN=BSTIT&TC=&LUTY=BYKW&LUSTR=16729004517",
                    "onBackOrder": false,
                    "price": 23.5,
                    "supplier": "QK",
                    "supplierDisplayName": "QK",
                    "supplierItemNumber": "70572"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=332515",
                    "onBackOrder": false,
                    "price": 23.66,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "332515"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002399",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.keysourceusa.com/search.aspx?searchterm=75834025601",
                    "onBackOrder": false,
                    "price": 24.11,
                    "supplier": "KEY_SOURCE",
                    "supplierDisplayName": "KeySource",
                    "supplierItemNumber": "133930"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.andanet.com/search?q=331366",
                    "onBackOrder": false,
                    "price": 24.14,
                    "supplier": "ANDA",
                    "supplierDisplayName": "Anda",
                    "supplierItemNumber": "331366"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051210",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://integral-rx.com/wps/portal/select/IntegralRx/IntegralRxordering",
                    "onBackOrder": false,
                    "price": 24.99,
                    "supplier": "INTEGRALRX",
                    "supplierDisplayName": "Integral Rx",
                    "supplierItemNumber": "914606"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051210",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 25.12,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10259577"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ACC00450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Accord",
                    "name": "Atorvastatin Calcium",
                    "ndc": "16729004517",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 29.95,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10106769"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Apotex",
                    "name": "Atorvastatin Calcium",
                    "ndc": "60505257908",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 29.95,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10106769"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Apotex",
                    "name": "Atorvastatin Calcium",
                    "ndc": "60505257908",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "expirationDate": "2024-06-30T00:00:00+00:00",
                    "isRebatedItem": false,
                    "link": "https://blupax.odoo.com/results?search=207516&page=1&limit=25&sort_field=unit_price&sort_dir=asc",
                    "onBackOrder": false,
                    "price": 30.54,
                    "supplier": "BLUPAX",
                    "supplierDisplayName": "BluPax",
                    "supplierItemNumber": "207516"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051210",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 37,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "5605761"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MLB01730.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Micro Laboratories",
                    "name": "Atorvastatin Calcium",
                    "ndc": "42571017310",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 6.19,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10182444"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/CIP09450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Cipla",
                    "name": "Atorvastatin Calcium",
                    "ndc": "69097094505",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 6.19,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "5403647"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/CIP09450.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Cipla",
                    "name": "Atorvastatin Calcium",
                    "ndc": "69097094505",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 6.53,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10112854"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "GREENSTONE",
                    "name": "Atorvastatin Calcium",
                    "ndc": "59762015601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 6.53,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10112854"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "GREENSTONE",
                    "name": "Atorvastatin Calcium",
                    "ndc": "59762015601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "link": "https://www.qkrx.com/cgi/cgictl.pgm?PN=BSTIT&TC=&LUTY=BYKW&LUSTR=63304082890",
                    "onBackOrder": false,
                    "price": 7.5,
                    "supplier": "QK",
                    "supplierDisplayName": "QK",
                    "supplierItemNumber": "60776"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082890",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 7.79,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10273504"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "43598083190",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 7.79,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10121993"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "55111012290",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 7.79,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "4750543"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "55111012290",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 50.45,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10229762"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/BIO00280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Biocon",
                    "name": "Atorvastatin Calcium",
                    "ndc": "70377002813",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 8.7,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "4816674"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "GREENSTONE",
                    "name": "Atorvastatin Calcium",
                    "ndc": "59762015601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 55.95,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "5743273"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 57.03,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10272516"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "43598083105",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 10.27,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10273504"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Dr Reddy's",
                    "name": "Atorvastatin Calcium",
                    "ndc": "43598083190",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 10.27,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10229103"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002390",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 61.65,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10112855"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "GREENSTONE",
                    "name": "Atorvastatin Calcium",
                    "ndc": "59762015602",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 61.65,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10112855"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/GSO01561.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "GREENSTONE",
                    "name": "Atorvastatin Calcium",
                    "ndc": "59762015602",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 63.41,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10233481"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MLB01730.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Micro Laboratories",
                    "name": "Atorvastatin Calcium",
                    "ndc": "42571017310",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 63.41,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10233481"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MLB01730.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Micro Laboratories",
                    "name": "Atorvastatin Calcium",
                    "ndc": "42571017310",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 16.8,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10118585"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "American Health Packaging",
                    "name": "Atorvastatin Calcium",
                    "ndc": "68084009801",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 100,
                    "uom": "Each"
                  },
                  "numPackages": 5
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 16.8,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10118585"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "American Health Packaging",
                    "name": "Atorvastatin Calcium",
                    "ndc": "68084009801",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 100,
                    "uom": "Each"
                  },
                  "numPackages": 5
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 87.7,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10103252"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 87.7,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10228900"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002305",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 92.65,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10150090"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MYN39510.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Mylan",
                    "name": "Atorvastatin Calcium",
                    "ndc": "00378395105",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 9.78,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10167217"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "AvPAK",
                    "name": "Atorvastatin Calcium",
                    "ndc": "50268009415",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 50,
                    "uom": "Each"
                  },
                  "numPackages": 10
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 19.64,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10106187"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Major",
                    "name": "Atorvastatin Calcium",
                    "ndc": "00904629161",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 100,
                    "uom": "Each"
                  },
                  "numPackages": 5
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 10.12,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10236058"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/ATX25790.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Major",
                    "name": "Atorvastatin Calcium",
                    "ndc": "00904629106",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 50,
                    "uom": "Each"
                  },
                  "numPackages": 10
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 102.61,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10257682"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 109.14,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10257682"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Nivagen",
                    "name": "Atorvastatin Calcium",
                    "ndc": "75834025601",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 1000,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": true,
                    "link": "https://kinrayweblink.cardinalhealth.com/",
                    "onBackOrder": false,
                    "price": 21.28,
                    "supplier": "KINRAY",
                    "supplierDisplayName": "Kinray",
                    "supplierItemNumber": "5533682"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Ascend",
                    "name": "Atorvastatin Calcium",
                    "ndc": "67877051290",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 24.65,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10101937"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082890",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 24.65,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10229103"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NDZ00230.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Novadoz",
                    "name": "Atorvastatin Calcium",
                    "ndc": "72205002390",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 31.41,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10131267"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MYN39510.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Mylan",
                    "name": "Atorvastatin Calcium",
                    "ndc": "51079020920",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 100,
                    "uom": "Each"
                  },
                  "numPackages": 5
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 165.8,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10250559"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Lupin",
                    "name": "Atorvastatin Calcium",
                    "ndc": "68180063602",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "numPackages": 1
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 29.85,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10249919"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Lupin",
                    "name": "Atorvastatin Calcium",
                    "ndc": "68180063609",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 31.74,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10127241"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/MYN39510.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Mylan",
                    "name": "Atorvastatin Calcium",
                    "ndc": "00378395177",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 31.74,
                    "supplier": "ABC",
                    "supplierDisplayName": "ABC",
                    "supplierItemNumber": "10249919"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Lupin",
                    "name": "Atorvastatin Calcium",
                    "ndc": "68180063609",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 90,
                    "uom": "Each"
                  },
                  "numPackages": 6
                },
                {
                  "catalogInfo": {
                    "isRebatedItem": false,
                    "onBackOrder": false,
                    "price": 19.99,
                    "supplier": "SMARTSOURCE",
                    "supplierDisplayName": "ABC SmartSource",
                    "supplierItemNumber": "10167217"
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "AvPAK",
                    "name": "Atorvastatin Calcium",
                    "ndc": "50268009415",
                    "packageDescription": "Box",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitDoseDescription": "Unit-Dose Packaging",
                    "unitQuantity": 1,
                    "unitSize": 50,
                    "uom": "Each"
                  },
                  "numPackages": 10
                }
              ],
              "buyingReason": "requested",
              "catalogInfo": {
                "isRebatedItem": true,
                "link": "https://kinrayweblink.cardinalhealth.com/",
                "onBackOrder": false,
                "price": 11.29,
                "supplier": "KINRAY",
                "supplierDisplayName": "Kinray",
                "supplierItemNumber": "4587556"
              },
              "drug": {
                "form": "Tablet",
                "genericProductIdentifier": "39400010100320",
                "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                "isBrand": false,
                "isNarrowTherapeuticIndex": false,
                "isRx": true,
                "manufacturer": "Sun Pharma",
                "name": "Atorvastatin Calcium",
                "ndc": "63304082805",
                "packageDescription": "Bottle",
                "routeOfAdministration": "Oral",
                "strength": "20",
                "strengthUnit": "MG",
                "unitQuantity": 1,
                "unitSize": 500,
                "uom": "Each"
              },
              "manufacturerChanged": false,
              "numPackages": 1,
              "numPackagesIncreased": false,
              "originalDrugs": {
                "c1675689525930": {
                  "form": "Tablet",
                  "genericProductIdentifier": "39400010100320",
                  "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                  "isBrand": false,
                  "isNarrowTherapeuticIndex": false,
                  "isRx": true,
                  "manufacturer": "Sun Pharma",
                  "name": "Atorvastatin Calcium",
                  "ndc": "63304082805",
                  "packageDescription": "Bottle",
                  "routeOfAdministration": "Oral",
                  "strength": "20",
                  "strengthUnit": "MG",
                  "unitQuantity": 1,
                  "unitSize": 500,
                  "uom": "Each"
                }
              },
              "originalNumPackages": 1,
              "packSizeChanged": false,
              "rxNumbers": [
                "c1675689525930"
              ],
              "supplierId": 3,
              "rxList": [
                {
                  "boh": 552,
                  "catalogInfos": [],
                  "dispensesStats": {
                    "last180Days": {
                      "numberOfPrescriptions": 170,
                      "numberOfUnits": 9420
                    },
                    "last30Days": {
                      "numberOfPrescriptions": 27,
                      "numberOfUnits": 1170
                    },
                    "last90Days": {
                      "numberOfPrescriptions": 77,
                      "numberOfUnits": 4110
                    }
                  },
                  "drug": {
                    "form": "Tablet",
                    "genericProductIdentifier": "39400010100320",
                    "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/RBX08280.JPG",
                    "isBrand": false,
                    "isNarrowTherapeuticIndex": false,
                    "isRx": true,
                    "manufacturer": "Sun Pharma",
                    "name": "Atorvastatin Calcium",
                    "ndc": "63304082805",
                    "packageDescription": "Bottle",
                    "routeOfAdministration": "Oral",
                    "strength": "20",
                    "strengthUnit": "MG",
                    "unitQuantity": 1,
                    "unitSize": 500,
                    "uom": "Each"
                  },
                  "eoh": 552,
                  "fillDate": "2023-02-06T13:18:45.930Z",
                  "key": "c1675689525930",
                  "manufactutrer": true,
                  "mostRecentlyDispensedDate": "2023-02-03T17:43:00.310000",
                  "noManufacturerPreference": false,
                  "packQuantity": 1,
                  "packSize": true,
                  "processedAt": "2/6/2023, 8:21:47 AM",
                  "status": "processed",
                  "supplierId": 3
                }
              ]
            },
            {
              "alternatives": [],
              "buyingReason": "meet_min_ship",
              "catalogInfo": {
                "isRebatedItem": true,
                "link": "https://kinrayweblink.cardinalhealth.com/",
                "onBackOrder": false,
                "price": 13.69,
                "supplier": "KINRAY",
                "supplierDisplayName": "Kinray",
                "supplierItemNumber": "5809702"
              },
              "drug": {
                "form": "Tablet",
                "genericProductIdentifier": "39400010100320",
                "isBrand": false,
                "isNarrowTherapeuticIndex": false,
                "isRx": true,
                "manufacturer": "Dr Reddy's",
                "name": "Atorvastatin Calcium",
                "ndc": "43598083105",
                "packageDescription": "Bottle",
                "routeOfAdministration": "Oral",
                "strength": "20",
                "strengthUnit": "MG",
                "unitQuantity": 1,
                "unitSize": 500,
                "uom": "Each"
              },
              "manufacturerChanged": false,
              "numPackages": 3,
              "numPackagesIncreased": true,
              "originalDrugs": {},
              "originalNumPackages": 0,
              "packSizeChanged": false,
              "rxNumbers": [
                "c43598083105"
              ],
              "numPackagesIncreasedTo": 3,
              "supplierId": 3,
              "rxList": []
            },
            {
              "alternatives": [],
              "buyingReason": "meet_min_ship",
              "catalogInfo": {
                "isRebatedItem": true,
                "link": "https://kinrayweblink.cardinalhealth.com/",
                "onBackOrder": false,
                "price": 55.95,
                "supplier": "KINRAY",
                "supplierDisplayName": "Kinray",
                "supplierItemNumber": "5743273"
              },
              "drug": {
                "form": "Tablet",
                "genericProductIdentifier": "39400010100320",
                "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/NIV02560.JPG",
                "isBrand": false,
                "isNarrowTherapeuticIndex": false,
                "isRx": true,
                "manufacturer": "Nivagen",
                "name": "Atorvastatin Calcium",
                "ndc": "75834025601",
                "packageDescription": "Bottle",
                "routeOfAdministration": "Oral",
                "strength": "20",
                "strengthUnit": "MG",
                "unitQuantity": 1,
                "unitSize": 1000,
                "uom": "Each"
              },
              "manufacturerChanged": false,
              "numPackages": 2,
              "numPackagesIncreased": true,
              "originalDrugs": {},
              "originalNumPackages": 0,
              "packSizeChanged": false,
              "rxNumbers": [
                "c75834025601"
              ],
              "numPackagesIncreasedTo": 2,
              "supplierId": 3,
              "rxList": []
            },
            {
              "alternatives": [],
              "buyingReason": "meet_min_ship",
              "catalogInfo": {
                "isRebatedItem": true,
                "link": "https://kinrayweblink.cardinalhealth.com/",
                "onBackOrder": false,
                "price": 6.19,
                "supplier": "KINRAY",
                "supplierDisplayName": "Kinray",
                "supplierItemNumber": "5403647"
              },
              "drug": {
                "form": "Tablet",
                "genericProductIdentifier": "39400010100320",
                "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/CIP09450.JPG",
                "isBrand": false,
                "isNarrowTherapeuticIndex": false,
                "isRx": true,
                "manufacturer": "Cipla",
                "name": "Atorvastatin Calcium",
                "ndc": "69097094505",
                "packageDescription": "Bottle",
                "routeOfAdministration": "Oral",
                "strength": "20",
                "strengthUnit": "MG",
                "unitQuantity": 1,
                "unitSize": 90,
                "uom": "Each"
              },
              "manufacturerChanged": false,
              "numPackages": 2,
              "numPackagesIncreased": true,
              "originalDrugs": {},
              "originalNumPackages": 0,
              "packSizeChanged": false,
              "rxNumbers": [
                "c69097094505"
              ],
              "numPackagesIncreasedTo": 2,
              "supplierId": 3,
              "rxList": []
            },
            {
              "alternatives": [],
              "buyingReason": "meet_min_ship",
              "catalogInfo": {
                "isRebatedItem": true,
                "link": "https://kinrayweblink.cardinalhealth.com/",
                "onBackOrder": false,
                "price": 7.79,
                "supplier": "KINRAY",
                "supplierDisplayName": "Kinray",
                "supplierItemNumber": "4750543"
              },
              "drug": {
                "form": "Tablet",
                "genericProductIdentifier": "39400010100320",
                "imageURI": "https://d2tmxrnv32ccg3.cloudfront.net/2023-02-01/image_0_0_mo_jpg_2.0_d_20230201/DRR01220.JPG",
                "isBrand": false,
                "isNarrowTherapeuticIndex": false,
                "isRx": true,
                "manufacturer": "Dr Reddy's",
                "name": "Atorvastatin Calcium",
                "ndc": "55111012290",
                "packageDescription": "Bottle",
                "routeOfAdministration": "Oral",
                "strength": "20",
                "strengthUnit": "MG",
                "unitQuantity": 1,
                "unitSize": 90,
                "uom": "Each"
              },
              "manufacturerChanged": false,
              "numPackages": 3,
              "numPackagesIncreased": true,
              "originalDrugs": {},
              "originalNumPackages": 0,
              "packSizeChanged": false,
              "rxNumbers": [
                "c55111012290"
              ],
              "numPackagesIncreasedTo": 3,
              "supplierId": 3,
              "rxList": []
            }
          ],
          "shippingCost": 0,
          "supplierId": 3
        }
      ],
      "unavailableDrugs": []
    }
  }
}
