import type { Cell } from "react-table";
import type { PaginationConfig } from "../components/rxLibrary/pagination/pagination.utils";
import type { ItemTypeEnum } from "./prescriptions/itemType/itemTypeEnum";
import { PmsEnum } from "./pms";

// Convert nullables attributes to optionals, example: { a: number, b: string | null } to { a: number, b?: string | null }
type MakeNullablesOptional<T> = {
  [K in keyof T as null extends T[K] ? K : never]?: T[K];
} &
  {
    [K in keyof T as null extends T[K] ? never : K]: T[K];
  };

export type ContractIdentifierStr = string;
export type EntityId = string;

// Enums
export enum ListCategoryEnum {
  NDC_MATCH = "ndc match", //NDC Match
  DIFFERENT_MANUFACTURERS = "other manufacturer", //Different Manufacturer
  DIFFERENT_QUANTITIES = "larger quantity", //Different Quantity
  DIFFERENT_MANUFACTURER_AND_QUANTITY = "other manufacturer larger quantity", //Different Manufacturer & Quantity

  SUGGESTED_NDC = "lowest item price", //NEW: Suggested NDC
  LAST_PURCHASED = "last purchased", //NEW: Last Purchased NDC
  LAST_DISPENSED = "last dispensed", //NEW: Last Dispensed NDC

  ALTERNATIVE_NDC = "alternative ndc", //NEW: Alternative NDC
  ALTERNATIVE_PACKAGING = "alternative packaging", //NEW: Alternate Packaging
  OTHERS = "others",
}

export enum DrugType {
  GENERIC = "generic",
  BRAND = "brand",
  ALL = "all",
}

export enum SVGType {
  Calendar = "Calendar",
  Savings = "Savings",
}

export enum PurchaseQuantityMethodEnum {
  Optional = "Optional",
  Manual = "Manual",
}

export enum RequestState {
  UNINITIATED,
  PENDING,
  COMPLETED,
  ERROR,
}

export enum UserBusinessRoles {
  BUYING = "BUYING",
  REPORTING = "REPORTING",
  MANUFACTURING = "MANUFACTURING",
}

// TBD: prescription-group missing
// strength, strengthUnit
export type DrugInfo = {
  id: string;
  form: string; // but later enum?
  manufacturer: string;
  name: string;
  ndc: string;
  packageDescription: string;
  strength: string;
  strengthUnit: string; // but later enum?
  unitQuantity: number;
  unitSize: number;
  uom: string;
  routeOfAdministration: string;
  genericProductIdentifier: string;
  unitDoseDescription?: string;
  imageURI?: string;
  sizeOptions?: DrugInfo[];
  isNarrowTherapeuticIndex?: boolean;
  isBrand?: boolean;
  controlledSubstanceCode?: string;
  isRx?: boolean;
  dispensedLast90Days?: number;
};

export type EntityReference = {
  id: string;
};

export type DrugInfoReference = {
  id: string;
  referenceData: { drugInfo: DrugInfo };
};

export type HistoricalDispensationInfo = {
  numberOfPrescriptionsLast30Days: number;
  numberOfUnitsLast30Days: number;
  avgGrossProfitLast30Days: number;
};

export type HistoricalOrderingInfo = {
  lastOrdered: string;
  lastSupplier: string;
  lastInvoicePrice: number;
};

export type CatalogInfo = {
  id: string;
  supplierId: number;
  supplier: string;
  supplierDisplayName: string;
  directDeal: DirectDeal | null;
  shippingConfig?: ShippingConfig;
  price: number;
  link?: string;
  supplierItemNumber?: string;
  isRebatedItem: boolean;
  expirationDate?: string;
  onBackOrder?: boolean;
};

// MAS
export type SuggestedNDC = {
  ndc: string;
  reason:
    | "default_none_available"
    | "order_frequency"
    | "prescription_frequency"
    | "lowest_price"
    | "ndc_match";
};

export type BaseTaggedItem = {
  buyingScore: number;
  catalogInfo: CatalogInfo;
  drugInfo: DrugInfo;
  historicalDispensationInfo: HistoricalDispensationInfo;
  historicalOrderingInfo?: HistoricalOrderingInfo;
};

export type TaggedItem = BaseTaggedItem & {
  tag: ListCategoryEnum;
  alternatePackaging?: boolean;
  suggestedReason?:
    | "default_none_available"
    | "order_frequency"
    | "prescription_frequency"
    | "lowest_price";
};

export type TaggedItemList = {
  items: TaggedItem[];
  category?: ListCategoryEnum;
};

export type NDCTaggedItemList = TaggedItemList & {
  ndc: string;
};

export type Quotes = {
  items: BaseTaggedItem[];
  suggestedNDC: SuggestedNDC;
  relatedDrugs: DrugInfo[];
};

export type ShippingConfig = {
  fee?: number;
  minOrder?: number;
  minGenericOrder?: number;
  minBrandRxOrder?: number;
  minGenericRxOrder?: number;
  cutoffTimes?: string | string[][];
  convertShippingCutoffsToLocalTz?: boolean;
  notes?: string | string[];
  version?: number;
};

export type ScraperConfig = {
  newSite?: boolean;
  enhancementsDisabled?: boolean;
  ignoreRebates?: boolean;
  contractDiscounts?: Record<ContractIdentifierStr, number>;
};

export type Supplier = {
  id: number;
  name: string;
  displayName: string;
  customerAccountId: string | null;
  isEcommerceEnabled: boolean;
  logisticsProvider?: string;
  customerServiceEmail?: string;
  pharmacyBillingAddressFormatted?: string;
  pharmacyShippingAddressFormatted?: string;
  shippingConfig?: ShippingConfig;
  offlinePurchasing?: boolean;
  isActive?: boolean;
  defaultShippingMethod?: string;
  notifyForAlternativeSuppliersForBrand: boolean;
  scraperConfig?: ScraperConfig;
};

export type Pharmacy = {
  id: number;
  name: string;
  suppliers: Supplier[];
  primarySupplier?: Supplier;
  secondarySupplier?: Supplier;
  cartNotificationsChannel: string;
  isSynced: boolean;
  pms: PmsEnum;
  keepsInventory: boolean;
  refillMappingBasedBuyingUsersSeeRefillReport: {
    supplierId: number;
    manufacturerId: number;
    manufacturerName: string;
    isActiveSupplier: boolean;
  }[];
  isDefaultForUser: boolean;
  supplierScope: "All" | "ManufacturerOnly";
  uiSettings: { addRx?: boolean };
  reports: { id: number; title: string; displaySequenceNumber: number }[];
};

export type AdminAccount = {
  id: number;
  email: string;
  fullName: string;
  isAdmin: boolean;
  businessRoles: string[];
  isTrialUser: boolean;
  supportContextMenu: boolean;
  pharmacies: AdminPharmacy[];
  defaultBuyingPharmacyId: number | null;
  welcomeEmailSentAt: string | null;
};

export type UpsertAdminAccount = {
  id: number;
  email: string;
  fullName: string;
  isAdmin: boolean;
  businessRoles: string[];
  isTrialUser: boolean;
  pharmacies: number[];
  supportContextMenu?: boolean;
  defaultBuyingPharmacyId?: number | null;
};

export type CreateAdminAccount = UpsertAdminAccount & {
  sendWelcomeEmail?: boolean;
};

export type AccountOptions = {
  pharmacy: {
    pharmacyId: number;
    pharmacyName: string;
    pharmacyOrganizationId: number;
    pharmacyOrganizationName: string;
  }[];
};

export type AdminPharmacy = {
  id: number;
  createdAt: string;
  updatedAt: string;
  pharmacyOrganizationId: number;
  name: string;
  pms: string;
  isSynced: boolean;
  timezone: string;
  usePmsSchema: boolean;
  supplierScope: string;
  changeLogNotes: string[];
  syncLibertyInventory: boolean;
  primarySupplierId: number | null;
  secondarySupplierId: number | null;
  deaCode: string | null;
  ediName: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  postalCode: string | null;
  npi: string | null;
  license: string | null;
  nabpNumber: string | null;
  legalPharmacyName: string | null;
};

export type AdminPharmacyFull = AdminPharmacy & {
  pharmacyOrganization: AdminPharmacyOrganization;
  primarySupplier: AdminPharmacySupplier | null;
  secondarySupplier: AdminPharmacySupplier | null;
};

export type UpsertAdminPharmacy = {
  changeLogNote?: string | null;
} & Omit<MakeNullablesOptional<AdminPharmacyFull>, "id" | "changeLogNotes">;

export type AdminPharmacyOptions = {
  pms: string[];
  timezone: string[];
  supplierScope: string[];
  state: { code: string; name: string }[];
  supplier: { id: number; name: string }[];
  pharmacyOrganization: { id: number; name: string }[];
};

export type PharmacyRefillItem = {
  drug: string;
  ndc: string;
  cost: string;
};

export type AdminPharmacyEmailSubscription = {
  id: number;
  pharmacyId: number;
  pharmacy: AdminPharmacyFull;
  email: string;
  name: "invoice";
  createdAt: string;
  updatedAt: string;
};

export type UpsertAdminPharmacyEmailSubscription = {
  email: string;
  pharmacyId: number;
};

export type AdminPharmacyEmailSubscriptionOptions = {
  pharmacy: { id: string; name: string }[];
};

export type AdminPharmacySupplier = {
  id: number;
  name: string;
};

export type AdminPharmacyOrganization = {
  id: number;
  name: string;
  pharmacies: AdminPharmacyFull[];
};

export type UpsertAdminPharmacyOrganization = Omit<
  MakeNullablesOptional<AdminPharmacyOrganization>,
  "id" | "pharmacies"
>;

export type AdminRefillMapping = {
  id: number;
  pharmacyId: number;
  manufacturerId: number;
  reportStartDate: string | null;
};

export type UpsertAdminRefillMapping = Omit<
  MakeNullablesOptional<AdminRefillMapping>,
  "id"
>;

export type AdminRefillMappingOptions = {
  pharmacy: { id: number; name: string }[];
  manufacturer: { id: number; name: string }[];
};

export type AdminSupplierMapping = {
  id: number;
  supplierId: number;
  pharmacyId: number;
  supplier: AdminSupplierMappingSupplier;
  brandItemPenalty: number;
  ecommerceEnabled: boolean;
  hasDirectDeal: boolean;
  isActive: boolean;
  notifyAlternativeSuppliersForBrand: boolean;
  rebatedItemDiscount: number;
  scraperConfig: Record<string, any>;
  shippingConfig: ShippingConfig;
  suppliesSchedule2: boolean;
  suppliesSchedule3: boolean;
  suppliesSchedule4: boolean;
  suppliesSchedule5: boolean;
  alwaysReturnSeedDrugOverride: boolean | null;
  catalogSupplierId: number | null;
  catalogSupplierIdOverride: number | null;
  customerAccountId: string | null;
  pharmacyIsaCode: string | null;
  supplierIsaCode: string | null;
  changeLogNotes: string[];
  billToPharmacyId: number | null;
  lastUpdatedById: number | null;
  lastUpdatedByEmail: string | null;
  createdAt: string;
  updatedAt: string;
};

type AdminSupplierMappingSupplier = {
  id: number;
  name: string;
};

export type UpsertAdminSupplierMapping = Omit<
  MakeNullablesOptional<AdminSupplierMapping>,
  | "id"
  | "supplier"
  | "changeLogNotes"
  | "catalogSupplierId"
  | "lastUpdatedById"
  | "lastUpdatedByEmail"
  | "createdAt"
  | "updatedAt"
> & {
  changeLogNote?: string | null;
};

export type AdminSupplierMappingOptions = {
  supplier: {
    id: number;
    name: string;
  }[];
  pharmacy: {
    id: number;
    name: string;
    timezone: string;
    pharmacyOrganizationId: number | null;
  }[];
  defaultSupplierShippingConfig: Record<string, ShippingConfig>;
};

export type AdminSupplierMappingBillPharmacyInfo = {
  pharmacyId: number;
  pharmacyName: string;
  customerAccountId: string | null;
};

export type SupplierMappingIsaCodesBySupplier = {
  customerAccountId: string | null;
  pharmacyIsaCode: string | null;
  supplierIsaCode: string | null;
  pharmacyName: string;
};

export type SupplierMappingIsaCodesByPharmacy = {
  customerAccountId: string | null;
  pharmacyIsaCode: string | null;
  supplierIsaCode: string | null;
  supplierName: string;
};

export type SupplierMappingCustomerAccountId = {
  pharmacyName: string;
  customerAccountId: string;
};

export type AdminSupplierMappingAndEdi = {
  supplierId: number;
  pharmacyId: number;
  pharmacyIsaCode: string;
  accountId: string;
  isSupplierMappingActive: boolean;
  billToPharmacyId: number;
};

export type UpsertAdminSupplierMappingAndEdi =
  MakeNullablesOptional<AdminSupplierMappingAndEdi>;

export type SupplierMappingAndEdiOptions = {
  pharmacy: { id: number; name: string; pharmacyOrganizationId: number }[];
  supplier: { id: number; name: string }[];
};

export type SupplierMappingAndEdiOptionsBySupplier = {
  all: number[];
  config: number[];
  catalog: number[];
  entityMetadata: number[];
  supplierMapping: number[];
};

export type Manufacturer = {
  id: number;
  name: string;
  reports: { id: number; title: string }[];
};

export type Idable = {
  id: number;
};

export type User = {
  id: number;
  full_name: string;
  email: string;
  business_roles: UserBusinessRoles[];
  is_admin: boolean;
  is_trial: boolean;
  client_app_permissions: {
    supportContextMenu?: boolean;
  };
};

// Chatlio
export type ChatlioData = {
  name: string | undefined;
  email: string | undefined;
  version: string;
};
export type ChatlioFunc = {
  (id: string | undefined, data: ChatlioData): void;
};
interface Chatlio {
  identify: ChatlioFunc;
  hide: () => void;
  reset: () => void;
  show: (options?: { expanded?: boolean }) => void;
  send: (message: string) => void;
  open: () => void;
  isExpanded: () => boolean;
  isShown: () => boolean;
  vsUuid: () => string;
}
// extend window object
declare global {
  interface Window {
    _chatlio: Chatlio;
    _user: User;
    dataLayer?: {
      push: (v: any) => void;
    };
  }
}

// Shopping Cart Flow
export type PrescriberInfo = {
  firstName?: string | null;
  lastName?: string | null;
  prefix?: string | null;
  suffix?: string | null;
};

export type Patient = {
  shortName?: string;
};

export type PrescriptionCatalogInfo = {
  isRebatedItem: boolean;
  supplierId: number;
};
export type DispensesStatsData = {
  numberOfPrescriptions: number;
  numberOfUnits: number;
};

export type DispensesStats = {
  last30Days: DispensesStatsData;
  last90Days: DispensesStatsData;
  last180Days: DispensesStatsData;
};

export type DirectDeal = {
  supplierId: number;
  ndc: string;
  discountPercent: number;
  bundleSize: number;
  notes: string;
};

export type DrugWithStats = {
  drug: DrugInfo;
  eoh: number;
  boh: number;
  directDeals?: DirectDeal[];
  dispensesStats?: DispensesStats;
  mostRecentlyDispensedDate?: string;
  catalogInfos?: PrescriptionCatalogInfo[];
};

export type Prescription = {
  rxNumber: string;
  currentFillId?: string;
  fillDate: string;
  dispensedQuantity: number;
  boh?: number;
  eoh?: number;
  prescriber?: PrescriberInfo;
  patient: Patient;
  drug: DrugInfo;
  dawCode: number | null;
  itemType?: ItemTypeEnum;
};

export type ItemInModal = Prescription & {
  eoh: 0;
  boh: 0;
  status: "list";
  addedAt: string;
  packSize: boolean;
  itemType: ItemTypeEnum.NoPMS;
  manufactutrer: boolean;
  noManufacturerPreference?: undefined;
};

export type ItemInCart = DrugWithStats & {
  currentFillId?: string;
  dispensedQuantity?: number | string;
  fillDate: string;
  rxNumber?: string;
  manufactutrer: boolean;
  packSize: boolean;
  prescriber?: PrescriberInfo;
  patient?: Patient;
  key?: string;
  status?: string;
  packQuantity?: number;
  processedAt?: string;
  note?: ItemNote;
  purchaseBy?: string;
  dawCode: number | null;
  addedAt?: string;
  itemType?: ItemTypeEnum;
  /*
   * @deprecated
   */
  supplierId?: number;
  noManufacturerPreference?: boolean;
  suppliersIdList?: number[];
};

export type ItemNote = { message: string; createdAt: Date; updatedAt: Date };

export type ItemPurchaseDetails = {
  id: string;
  ndc: string;
  status: string;
  manufactutrer: boolean;
  packSize: boolean;
  currentFillId?: string;
  packQuantity?: number;
  processedAt?: string;
  note?: ItemNote;
  purchaseBy?: string;
  addedAt?: string;
  itemType?: ItemTypeEnum;
  /*
   * @deprecated
   */
  supplierId?: number;
  noManufacturerPreference?: boolean;
};

export type Fills = {
  numFills: number;
  quantity: number;
};

export type FutureFills = {
  equivalent: Fills;
  exact: Fills;
};

export type PrescriptionQuantitySubItem = {
  packQuantity?: number;
  dispensedQuantity?: number;
  fillDate?: string;
  prescriber?: PrescriberInfo;
};

export enum FutureRefillOptionEnum {
  SemiMonthly = "endOfBillingStatement",
  Next30Days = "days30",
  Next60Days = "days60",
  Next90Days = "days90",
}

export type PrescriptionGroup = {
  rxNumbers: string[];
  allowPackSizeSubstitution: boolean;
  allowManufacturerSubstitution: boolean;
  noManufacturerPreference?: boolean;
  numPackages: number;
  dispensedQuantity: number;
  quantityToBuy: number;
  quantityWanted: number;
  directDeals: DirectDeal[];
  boh: number;
  eoh: number;
  futureFills: Record<FutureRefillOptionEnum, FutureFills>;
  drug: DrugInfo;
  useQuantityInput: boolean;
  purchaseBy?: string;
};

export type PrescriptionGroupAdjustment = {
  rxNumbers: string[];
  allowPackSizeSubstitution?: boolean;
  allowManufacturerSubstitution?: boolean;
  useQuantityInput?: boolean;
  quantityToBuy?: number;
  numPackages?: number;
};

export type PrescriptionQuantityItem = PrescriptionGroup & {
  rxList: ItemInCart[];
  inv?: boolean;
  fillDate?: string;
  adjustmentRemoved?: boolean;
};

export type PrescriptionGroupForOptimization = {
  rxNumbers: string[];
  allowPackSizeSubstitution: boolean;
  allowManufacturerSubstitution: boolean;
  ndc: string;
  numPackages?: number;
  quantity?: number;
  purchaseBy?: string;
};

export type OfferedDrug = {
  drug: DrugInfo;
  supplierId: number;
  supplierItemNumbers: string[];
};

export type CatalogOverride = {
  ndc: string;
  supplierId: number;
  price: number;
  supplierItemNumber: string;
  isAvailable: boolean;
  numPackagesAvailable?: number;
  isRebatedItem?: boolean;
  link?: string;
  expirationDate?: string;
};

export type ShoppingSupplierCheck = {
  id: number;
  check: boolean;
};

export type RadioButtonLabelType = {
  value: string | number;
  label: string | JSX.Element;
  disabled?: boolean;
};

export type DropdownOptionType = {
  label: string;
  value: string;
  checked: boolean;
  data?: SelectBoxOptionType;
};
export type SelectBoxOptionType = {
  label: string;
  value: string;
};

export type TableCellPropsType<T, R = void> = Record<
  string,
  Cell<Record<string, R>, T>
>;

export type OptionData = {
  value: number;
  label: string;
};

export type OptionConvertable = Idable & {
  name: string;
};

export type SavingsTableDataItemType = {
  itemDesc: {
    drug: DrugInfo;
    numPackages: number;
  };
  originalItem: OriginalItemType;
  suggestedItem: SuggestedItemType;
  savings: number;
  savingsReason: string;
};

export type SavingsTableDataType = SavingsTableDataItemType[];

export type ComparisonType = {
  displayedSavings: number | null;
  isNonComparable: boolean;
  minShipManagement: number;
  originalCost?: number;
  originalNDC: string;
  originalSupplierId?: number;
  savings: number | null;
  savingsReason: string;
};

export type OriginalItemType = {
  comparison: ComparisonType;
  supplierName: string;
};

export type SuggestedItemType = {
  ndc: string;
  price: number;
  supplierName: string;
};

export type ComparisonSummaryType = {
  displayedSavingsTotal: number;
  minShipManagementAndShippingTotal: number;
  savingsGrandTotal: number;
};

export type PrescriptionDetail = {
  id: string;
  isInventory: boolean;
  prescription: ItemInCart;
};

export type CatalogItem = {
  catalogInfo: CatalogInfo;
  drug: DrugInfo;
  // TODO: Remove when we implement the BE refrigerated items
  refrigerated?: boolean;
};

export type CatalogItemsMostRecentPurchase = {
  supplierItemNumber: string;
  originalInvoiceDate: string;
};

export type CatalogItemsTopSellingItem = {
  supplierItemNumber: string;
  displaySequenceNumber: number;
};

export type CatalogItemsResponse = {
  items: CatalogItem[];
  topSellingItems: CatalogItemsTopSellingItem[];
  mostRecentPurchases: CatalogItemsMostRecentPurchase[];
};

export type PurchaseOrder = {
  purchaseOrderNumber: string;
  purchaseOrderNumbers?: string[];
};

export type SearchResult = {
  dispensedLast90Days: number;
  isAvailableInCatalog: boolean;
  representativeDrugInfo: DrugInfo;
  score: number;
  orderScore: number;
  relevanceScore: number;
  isBrand: boolean;
};

export type InvoiceDetailsItem = {
  ndc: string | null;
  quantity: number | null;
  unitPrice: number | null;
  description: string;
  supplierItemNumber: string | null;
  discountedUnitPrice: number | null;
  discountedTotalPrice: number;
};

export type InvoiceDetails = {
  billToAddress1: string | null;
  billToAddress2: string | null;
  billToCity: string | null;
  billToCountry: string | null;
  billToName: string | null;
  billToPostalCode: string | null;
  billToState: string | null;
  invoiceNumber: string;
  invoiceTimestampUtc: string;
  items: InvoiceDetailsItem[];
  paymentDueDate: string;
  purchaseOrderNumber: string;
  shipToAddress1: string | null;
  shipToAddress2: string | null;
  shipToCity: string | null;
  shipToCountry: string | null;
  shipToName: string | null;
  shipToPostalCode: string | null;
  shipToState: string | null;
  shippingCost: number | null;
  supplierAddress1: string | null;
  supplierAddress2: string | null;
  supplierCity: string | null;
  supplierFax: string | null;
  supplierName: string;
  supplierPhone: string | null;
  supplierPostalCode: string | null;
  supplierState: string | null;
  timezone: string;
  totalDiscountedPrice: number;
};

export type OrderHistoryOrderSummary = {
  shipDate: string;
  estimatedDeliveryDate: string;
  shippingMethod: string;
  shippingId: string;
  trackingNumber: string;
  items: {
    description: string | null;
    drugDescription: string;
    supplierItemNumber: string | null;
    ndc: string;
    quantity: number;
    originalUnitPrice: number;
    discountedUnitPrice: number;
    totalDiscountedPrice: number;
    errorMessage: string | null;
  }[];
};

export type OrderHistoryOrder = {
  fileIds: string[];
  fullName: string;
  invoiceNumbers: string[];
  itemSummaries: OrderHistoryOrderSummary[];
  orderInfo: string;
  purchaseDateMissingUsingShipDate: boolean;
  purchaseOrderNumber: string;
  purchaseStatus: string;
  purchasedAt: string;
  shippingCost: number;
  totalPrice: number;
};

export type InvoiceHistorySortColumn =
  | "invoice_date"
  | "supplier_name"
  | "total_items"
  | "total_amount";

export type InvoiceHistoryHistoryItem = {
  itemName: string | null;
  ndc: string | null;
  upc: string | null;
  quantity: string | null;
  unitPrice: string | null;
  totalPrice: string;
  purchaseOrderNumber: string | null;
  invoiceNumber: string;
};

export type InvoiceHistoryHistory = {
  invoiceDate: string;
  invoiceNumbers: string[];
  items: InvoiceHistoryHistoryItem[];
  supplierId: number;
  supplierName: string;
  totalAmount: string;
  totalItems: number;
};

export type InvoiceHistoryHistoryConfigOption = {
  supplierId: number;
  supplierName: string;
  configuredInvoice: boolean;
  configuredInvoiceGlobal: boolean;
};

export type InvoiceHistory = {
  totalRows: number;
  totalPageCount: number;
  configOptions: InvoiceHistoryHistoryConfigOption[];
  histories: InvoiceHistoryHistory[];
};

export type ServerState = {
  cart: ItemPurchaseDetails[];
  inventory: ItemInCart[];
  prescriptionGroupAdjustments: PrescriptionGroupAdjustment[];
  second?: boolean;
};

export type PrescriptionGroupsRequestItem = {
  rxNumber?: string;
  currentFillId?: string;
  isCustom?: boolean;
  ndc?: string;
  allowPackSizeSubstitution?: boolean;
  allowManufacturerSubstitution?: boolean;
  numPackages?: number;
  noManufacturerPreference?: boolean;
  purchaseBy?: string;
};

type RefillOpportunity = {
  lastDispensedManufacturer: string;
  lastDispensedDrugName: string;
  lastDispensedQuantity: number;
  rxNumber: string;
  rxStatus: string | null;
  fillStatus: string | null;
  patient: string;
  estimatedProfit: number;
  estimatedRevenue: number;
  estimatedCost: number;
  autoRefillOn: string;
  lastPatientPaidAmt: number;
  lastSoldDate: string;
  daysSupply: number;
  daysSupplyEnds: string | null;
  refillDueDate: string;
  dateWritten: string;
  lastDateFilled: string | null;
  refillsRemaining: number;
  primaryPayer: string | null;
  secondaryPayer: string | null;
  coveredByPrimaryWNonzeroRemit: string | null;
  daw: string;
  isCycleFill: string | null;
  isPrn: string | null;
  prescriberName: string | null;
  prescriberAddress: string | null;
  prescriberCity: string | null;
  prescriberState: string | null;
  prescriberZip: string | null;
  prescriberNpi: string | null;
  isPaidOutreach: boolean;
  totalEstimatedProfit: number;
  coveredTotalEstimatedProfit: number;
};

export type ReportRefillOpportunity = {
  totalEstimatedProfit: number;
  coveredTotalEstimatedProfit: number;
  pharmacyIsInPaidOutreachProgram: boolean;
  totalOutreachOpportunityAmount: number;
  records: RefillOpportunity[];
};

export type ReportRefillOpportunityWithPagination = PaginationConfig &
  ReportRefillOpportunity;

export type ManufacturerAgGridReport = {
  errors: null;
  response: {
    data: never[];
    query_1_data?: never[];
    query_2_data?: never[];
  };
};

export type PageAlert = {
  enabled: boolean;
  message: string;
  pages?: string[];
  blackListedPages?: string[];
  pharmacyIds?: number[];
  // electron notifications config
  electronOnly?: boolean;
  electronMaxVersion?: string;
};
