import React, { ReactNode } from "react";
import "twin.macro";
import { Modal } from "antd";
import { Title } from "../../../../../../components/rxLibrary/typography";

export function TableModal({
  open,
  title,
  header,
  children,
  width = 1100,
  onClose,
}: {
  open: boolean;
  title?: string;
  header?: ReactNode;
  children: ReactNode;
  width?: number;
  onClose: () => void;
}) {
  const hasHeader = !!(header || title);

  if (!open) return null;
  return (
    <Modal open footer={null} width={width} onCancel={onClose}>
      <div tw="max-h-[calc(100vh - 180px)] min-h-[400px] flex flex-col">
        {hasHeader && (
          <div tw="mb-4 space-y-2">
            {!!title && (
              <Title block>
                {title}
              </Title>
            )}

            {!!header && <div>{header}</div>}
          </div>
        )}

        <div tw="overflow-y-auto flex-1">{children}</div>
      </div>
    </Modal>
  );
}
