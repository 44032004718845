import React, {
  createContext,
  useEffect,
  useState
} from "react";

type UserInteractionContext = {
  userIsInteracting: boolean;
};

const UserInteractionContext = createContext<UserInteractionContext>({
  userIsInteracting: false,
});

export default UserInteractionContext;

type UserInteractionContextProviderProps = {
  children?: React.ReactNode;
};

type InteractionState = {
  watchingForMouseMove: boolean,
  userIsInteracting: boolean,
}

const MINUTES_GRANTED_PER_MOUSEMOVE = 10;
const MINUTES_TO_WAIT_FOR_INACTIVITY = 20;

const notifyEnhancedAppOfUserInteraction = () => {
  console.log("notifyEnhancedAppOfUserInteraction");
  window.postMessage({
    channel: "daylightrxenhanced",
    sender: "web",
    msgType: "userInteraction",
  });
}

export function UserInteractionContextProvider({
  children,
}: UserInteractionContextProviderProps): JSX.Element {
  const [interactionState, setInteractionState] = useState<InteractionState>({watchingForMouseMove: true, userIsInteracting: false});

  useEffect(() => {
    const mouseMoveListener = (e: MouseEvent) => {
      if (e.movementY === 0 && e.movementX === 0) {
        console.log("Mouse move detected; but no movement");
        return;
      }
      console.log("Mouse move detected; userIsInteracting", e);
      notifyEnhancedAppOfUserInteraction();
      setInteractionState({
          watchingForMouseMove: false,
          userIsInteracting: true,
      })
    };
    let timeout1: NodeJS.Timeout | null = null;
    let timeout2: NodeJS.Timeout | null = null;
    if (interactionState.watchingForMouseMove) {
      console.log("Reactivating mouseMove listener");
      document.addEventListener('mousemove', mouseMoveListener);
      timeout1 = setTimeout(() => {
        setInteractionState({
          watchingForMouseMove: true,
          userIsInteracting: false,
        })
      }, MINUTES_TO_WAIT_FOR_INACTIVITY * 60 * 1000);
    } else {
      if (interactionState.userIsInteracting) {
        timeout2 = setTimeout(() => {
          setInteractionState({
            watchingForMouseMove: true,
            userIsInteracting: true,
          })
        }, MINUTES_GRANTED_PER_MOUSEMOVE * 60 * 1000);
      }
    }

    return () => {
      document.removeEventListener('mousemove', mouseMoveListener);
      if (timeout1) {
        clearTimeout(timeout1);
      }
      if (timeout2) {
        clearTimeout(timeout2);
      }
    }
  }, [interactionState]);

  return (
    <UserInteractionContext.Provider
      value={{
        userIsInteracting: interactionState.userIsInteracting,
      }}
    >
      {children}
    </UserInteractionContext.Provider>
  );
}
