import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminPharmacies } from "./AdminPharmacies";
import { CreateAdminPharmacy } from "./CreateAdminPharmacy";
import { EditAdminPharmacy } from "./EditAdminPharmacy";

const PATH = "/admin/pharmacy";
const PATH_UPDATE = `${PATH}/:id`;
const PATH_CREATE = `${PATH}/new`;

export function PharmaciesRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminPharmacies} exact />
      <Route path={PATH_CREATE} component={CreateAdminPharmacy} exact />
      <Route path={PATH_UPDATE} component={EditAdminPharmacy} exact />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
