import React, { ComponentProps, useCallback, useState } from "react";
import "twin.macro";
import "core-js/actual/set-immediate";
import { Popover as BasePopover } from "antd";
import { Global, css } from "@emotion/react";
import { GREY_2 } from "./colors";

const RX_POPOVER_OVERLAY_CLASS_NAME = "rx-popover-overlay";

export function Popover(
  props: Omit<ComponentProps<typeof BasePopover>, "overlayClassName">
) {
  return (
    <>
      <BasePopover
        showArrow={false}
        trigger="click"
        overlayClassName={RX_POPOVER_OVERLAY_CLASS_NAME}
        destroyTooltipOnHide
        {...props}
      />
      <Global styles={GLOBAL_CSS} />
    </>
  );
}

const GLOBAL_CSS = css`
  .${RX_POPOVER_OVERLAY_CLASS_NAME} {
    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-inner {
      padding: 0;
      border-radius: 9px;
      overflow: hidden;
      box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.22);
      border: 1px solid ${GREY_2};
    }
  }
`;

export function useIsPopoverOpen() {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const closePopover = useCallback(() => {
    setIsPopoverOpen(false);
  }, []);

  const openPopover = useCallback(() => {
    setImmediate(() => setIsPopoverOpen(true));
  }, []);

  const handleOpenPopoverChange = useCallback((newIsOpen: boolean) => {
    setIsPopoverOpen(newIsOpen);
  }, []);

  return {
    isPopoverOpen,
    openPopover,
    closePopover,
    handleOpenPopoverChange,
  };
}
