import React, { useMemo } from "react";
import { DrugInfo } from "../../../../../utilities/types";
import { formatNdc } from "../../../../../utilities/ndc/formatNdc";
import { formatDrugNameWithDetails } from "../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { Text } from "../../../../../components/rxLibrary/typography";

export function ShoppingListColumnDispensedItem({
  drug,
  displayNdc = false,
}: {
  drug: DrugInfo;
  displayNdc?: boolean;
}) {
  const formattedNdc = useMemo(() => {
    if (displayNdc) return `NDC: ${formatNdc(drug.ndc)}`;
  }, [displayNdc, drug.ndc]);

  const drugDetails = useMemo(() => {
    return formatDrugNameWithDetails(drug);
  }, [drug]);

  return (
    <>
      <Text weight="bold" block>
        {drugDetails}
      </Text>
      {!!formattedNdc && <Text block>{formattedNdc}</Text>}
    </>
  );
}
