import { ensureArray } from "../arrays/ensureArray";
import { getQueryParams } from "./getQueryParams";

export function getQueryParamLikeArray(field: string ) {
  const queryParams = getQueryParams();
  const value = queryParams[field];

  if (!value) return [];
  if (Array.isArray(value)) return value;
  const values = ensureArray(value);
  return values;
}