import { ErrorMessage } from "./ErrorMessage";

export function ReportError({ className }: { className?: string }) {
  return (
    <ErrorMessage className={className}>
      There seems to be a problem with which reports you`re set up to be able to
      see. Very sorry! we have been notified and will look into this right away.
    </ErrorMessage>
  );
}
