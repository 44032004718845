export const RX_LIST_TABLE_FILTER_OPTIONS = [
  {
    key: "rx",
    label: "Rx#",
  },
  {
    key: "name",
    label: "Item Name",
  },
  {
    key: "patient",
    label: "Patient",
  },
];
