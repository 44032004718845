import React, { useCallback, useMemo } from "react";
import { styled } from "twin.macro";
import { Form } from "antd";
import { GREY_2 } from "../colors";
import { Button } from "../buttons";
import { IconCheck, IconFilter } from "../icons";
import { Popover, useIsPopoverOpen } from "../popover";
import { Text } from "../typography";
import { Checkbox, CheckboxGroup } from "../checkbox";
import { ClosableTag } from "../tags";

type SetFilters<T> = (filters: T[]) => void;

export type MultiSelectCheckboxesOption<T extends string | number> = {
  label: string;
  value: T;
};

const ContentTop = styled.div`
  padding: 37px 31px 0;
`;

const ContentBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  border-top: 1px solid ${GREY_2};
`;

function MultiSelectCheckboxesPopover<T extends string | number>({
  title,
  filters,
  options = [],
  close,
  setFilters,
}: {
  filters: T[];
  title?: string;
  options?: MultiSelectCheckboxesOption<T>[];
  close: () => void;
  setFilters: SetFilters<T>;
}) {
  const handleOnFinish = useCallback(
    (values: { filters: T[] }) => {
      setFilters(values.filters);
      close();
    },
    [close, setFilters]
  );

  return (
    <div tw="w-[240px]">
      <Form
        layout="vertical"
        initialValues={{ filters }}
        onFinish={handleOnFinish}
      >
        <ContentTop>
          {!!title && <Text size="medium">{title}</Text>}

          <Form.Item name="filters" tw="mt-[20px] mb-[17px]">
            <CheckboxGroup>
              <div>
                {options.map(({ label, value }) => (
                  <div key={value} tw="mb-[18px]">
                    <Checkbox value={value}>{label}</Checkbox>
                  </div>
                ))}
              </div>
            </CheckboxGroup>
          </Form.Item>
        </ContentTop>

        <ContentBottom>
          <Button variant="text-1" onClick={close}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" icon={<IconCheck />}>
            Apply
          </Button>
        </ContentBottom>
      </Form>
    </div>
  );
}

export function MultiSelectCheckboxesSelectedTags<T extends string | number>({
  filters,
  options,
  setFilters,
}: {
  filters: T[];
  options?: MultiSelectCheckboxesOption<T>[];
  setFilters: SetFilters<T>;
}) {
  const removeFilter = useCallback(
    (filter: T) => {
      const newFilters = filters.filter((f) => f !== filter);
      setFilters(newFilters);
    },
    [filters, setFilters]
  );

  const optionsByValue = useMemo(() => {
    return options?.reduce((acc, { label, value }) => {
      acc[value] = label;
      return acc;
    }, {} as Record<T, string>);
  }, [options]);

  const isHidden = filters.length === 0 || !options?.length;

  if (isHidden) return null;
  return (
    <div tw="flex items-center space-x-[10px]">
      <Text size="medium" block>Applied</Text>

      {filters.map((filter) => (
        <ClosableTag key={filter} onClick={() => removeFilter(filter)}>
          {optionsByValue?.[filter] ?? filter}
        </ClosableTag>
      ))}
    </div>
  );
}

export function MultiSelectCheckboxes<T extends string | number>({
  title,
  filters,
  options,
  setFilters,
}: {
  filters: T[];
  title?: string;
  options?: MultiSelectCheckboxesOption<T>[];
  setFilters: SetFilters<T>;
}) {
  const { isPopoverOpen, closePopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  return (
    <Popover
      placement="bottomLeft"
      open={isPopoverOpen}
      onOpenChange={handleOpenPopoverChange}
      content={() => (
        <MultiSelectCheckboxesPopover
          title={title}
          filters={filters}
          options={options}
          setFilters={setFilters}
          close={closePopover}
        />
      )}
    >
      <Button icon={<IconFilter />} variant="secondary">
        Apply Filters
      </Button>
    </Popover>
  );
}
