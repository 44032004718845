import type { SearchResult } from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";
import { backendFetch } from "./legacy/config/backend-api";

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getSearch_deprecated(
  text: string,
  pharmacyId: number,
  token: string
) {
  return backendFetch<{ searchResults: SearchResult[] }>(
    `/search?${new URLSearchParams({
      text: text,
      pharmacy_id: pharmacyId.toString(),
      version: "2",
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
}

export async function getSearch(
  client: RequestClient,
  {
    text,
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    text: string;
    pharmacyId: number;
  }>
) {
  return client<{ searchResults: SearchResult[] }>({
    ...config,
    url: "/search",
    params: { text, pharmacyId, version: "2" },
  });
}
