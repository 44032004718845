import React, { useCallback } from "react";
import "twin.macro";
import { Input } from "antd";
import SaveOutlined from "@ant-design/icons/SaveOutlined";
import { Button } from "../../../components/rxLibrary/buttons";
import {
  AuthSaveCredentialsPayload,
  SupplierAccountsSummaryView,
} from "../EnhancedSupplierAccount.constants";
import { dispatch } from "../EnhancedSupplierAccount.utils";

export function EnhancedSupplierAccountSettingsCreateAccount({
  supplierAccount,
  setViewMode,
  returnToSummary,
}: {
  supplierAccount: SupplierAccountsSummaryView;
  setViewMode: () => void;
  returnToSummary: () => void;
}) {
  const wouldBeFirstCredential = supplierAccount.accounts.length === 0;

  const cancelAccountCreation = useCallback(() => {
    if (wouldBeFirstCredential) returnToSummary();
    else setViewMode();
  }, [wouldBeFirstCredential, setViewMode, returnToSummary]);

  const createAccount = useCallback(() => {
    const username = document.getElementById(
      "new-credential-username"
    ) as HTMLInputElement;
    const password = document.getElementById(
      "new-credential-password"
    ) as HTMLInputElement;
    if (!username || !password) return;

    if (
      supplierAccount.accounts.find((account) => {
        return account.userName === username.value;
      })
    ) {
      alert(`Username ${username.value} already exists for this supplier`);
      return;
    }

    const payload: AuthSaveCredentialsPayload = {
      supplier: supplierAccount.supplier,
      username: username.value,
      password: password.value,
    };
    console.log("saving payload", payload);
    dispatch("requestSaveSupplierCredentials", payload);
    setViewMode();
  }, [supplierAccount, setViewMode]);

  return (
    <tr>
      <td tw="p-2">
        <Input
          type="text"
          placeholder="Username"
          id="new-credential-username"
        />
      </td>

      <td tw="p-2">
        <Input
          type="text"
          placeholder="Password"
          id="new-credential-password"
        />
      </td>

      <td tw="p-2">
        <div tw="flex justify-start space-x-4">
          <Button
            icon={<SaveOutlined rev={undefined} />}
            disabled={!supplierAccount.supplier}
            onClick={createAccount}
          >
            Save
          </Button>

          <Button variant="secondary" onClick={cancelAccountCreation}>
            Cancel
          </Button>
        </div>
      </td>
    </tr>
  );
}
