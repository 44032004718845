import React from "react";
import "twin.macro";
import { IconBell } from "../../../../../components/rxLibrary/icons";
import { ExpandableTable } from "../../../../../components/rxLibrary/tables/ExpandableTable/ExpandableTable";
import { TableHeader } from "../table/tableHeader/TableHeader";
import { UnavailableTableRow } from "./UnavailableTableRow";
import { useUnavailableItems } from "./useUnavailableItems";

export function UnavailableTable() {
  const unavailableItems = useUnavailableItems();
  const itemsCount = unavailableItems.length;

  if (itemsCount === 0) return null;
  return (
    <ExpandableTable
      Row={UnavailableTableRow}
      items={unavailableItems}
      header={
        <TableHeader
          Icon={IconBell}
          title="Unavailable Items List"
          text="Not found in any of your chosen suppliers"
          itemsCount={itemsCount}
        />
      }
    />
  );
}
