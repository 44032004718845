import React, { useContext } from "react";
import "twin.macro";
import type { SupplierAccountsSummaryView } from "../EnhancedSupplierAccount.constants";
import EnhancedClientCommandContext from "../../EnhancedClientCommandContext";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";

export function EnhancedSupplierAccountSummaryEcommerceSuppliers({
  suppliers,
}: {
  suppliers: SupplierAccountsSummaryView[];
}) {
  const { showSupplierWithOptions } = useContext(EnhancedClientCommandContext);

  if (suppliers.length === 0) return null;
  return (
    <div>
      <div tw="border-b py-[27px]">
        <Text size="x-large" weight="bold">
          Ecommerce-Enabled Suppliers
        </Text>
      </div>

      {suppliers.map((row) => {
        const { supplier, supplierId } = row;

        return (
          <div key={supplier} tw="border-b py-[20px] px-[10px]">
            <Button
              variant="text-1"
              onClick={() =>
                showSupplierWithOptions(supplier, {
                  isEcommerce: true,
                  eCommerceSiteName: supplier,
                  eCommerceSiteLink: `${window.location.origin}/manufacturer-store/${supplierId}`,
                })
              }
            >
              {row.supplierDisplay}
            </Button>
          </div>
        );
      })}
    </div>
  );
}
