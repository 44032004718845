import type { DrugInfo } from "../../../utilities/types";
import DispensedItem from "../../DispensedItem";
import { Image } from "../../Image";

export function DrugDetailsCol({ drug }: { drug: DrugInfo }) {
  return (
    <div tw="flex">
      <Image tw="w-[70px] mr-2 object-contain" src={drug.imageURI} />
      <DispensedItem drug={drug} />
    </div>
  );
}
