import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
  percentFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  { field: "supplier", headerName: "Supplier", pinned: "left", flex: 1 },
  {
    field: "percentPacks",
    headerName: "% Packs",
    valueFormatter: (params) => percentFormatter(params.value, 2),
    flex: 1,
    minWidth: 100
  },
  {
    field: "percentSpend",
    headerName: "% Spend",
    valueFormatter: (params) => percentFormatter(params.value, 2),
    flex: 1,
    minWidth: 100
  },
  {
    field: "percentDosageUnits",
    headerName: "% Dosage Units",
    valueFormatter: (params) => percentFormatter(params.value, 2),
    flex: 1,
    minWidth: 100
  },
  {
    field: "numPacks",
    headerName: "# Packs",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "spendAmount",
    headerName: "$ Spend",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "numDosageUnits",
    headerName: "# Dosage Units",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "totalNumPacksPurchased",
    headerName: "Total # Packs Purchased",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "totalSpendAmount",
    headerName: "Total $ Spend",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
  {
    field: "totalNumDosageUnits",
    headerName: "Total # Dosage Units",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 100
  },
];
