import React, { useContext } from "react";
import { useHistory } from "react-router";
import semver from "semver";
import tw from "twin.macro";
import type { Supplier } from "../../utilities/types";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import EnhancedClientContext from "../../enhanced/EnhancedClientContext";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import EnhancedClientCommandContext from "../../enhanced/EnhancedClientCommandContext";
import EnhancedClientOfflinePurchaseContext from "../../enhanced/EnhancedClientOfflinePurchaseContext";
import {supplierIsDisabledInEnhancedClient} from "../../enhanced/util";
import { Container } from "../containers/Container";
import { Box } from "../rxLibrary/box";

export function Suppliers({allowAccountManagement}: {allowAccountManagement?: boolean}): JSX.Element {
  const history = useHistory();
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { enhancedClientActive, enhancedClientVersion } = useContext(EnhancedClientConfigContext);
  const { enhancedSupplierConnectionStatuses, currentEnhancementState, eCommerceAvailable } =
    useContext(EnhancedClientContext);
  const {
    setEnhancedOfflineSupplierModalShown,
    setEnhancedOfflineSupplierName,
  } = useContext(EnhancedClientOfflinePurchaseContext);
  const { showSupplier, showSupplierWithOptions } = useContext(EnhancedClientCommandContext);

  const handleSupplierClick = (s: Supplier, e: React.MouseEvent<HTMLElement>) => {
    if (enhancedClientVersion && semver.gte(enhancedClientVersion, '0.3.26')) {
      if (s.offlinePurchasing && !s.isEcommerceEnabled) {
        setEnhancedOfflineSupplierName(s.name);
        setEnhancedOfflineSupplierModalShown(true);
      } else {
        showSupplierWithOptions(s.name, {
          preventActivation: e.ctrlKey,
          isEcommerce: s.isEcommerceEnabled,
          eCommerceSiteName: s.isEcommerceEnabled ? s.name : undefined,
          eCommerceSiteLink: s.isEcommerceEnabled ? `${window.location.origin}/manufacturer-store/${s.id}` : undefined,
        })
      }
    } else {
      if (s.offlinePurchasing) {
        setEnhancedOfflineSupplierName(s.name);
        setEnhancedOfflineSupplierModalShown(true);
      } else {
        if (e.ctrlKey) {
          showSupplierWithOptions(s.name, {preventActivation: true});
        } else {
          showSupplier(s.name);
        }
      }
    }
  };

  const handlePasswordManagement = () => {
    history.push("/supplierAccounts");
  }

  const sortSuppliers = (items: Supplier[]) => {
    return items.sort((a: Supplier, b: Supplier) =>
      a.displayName.localeCompare(b.displayName)
    );
  };

  const problemSuppliers = sortSuppliers(
    currentBuyingPharmacy?.suppliers || []
  ).filter((s) => {
    return (
      enhancedClientActive &&
      currentEnhancementState.supplierProblems[s.name]?.isProblem
    );
  });

  return (
    <Box tw="pb-[12px] mb-1" bgColor="grey-4">
      <Container>
        <div tw="flex items-center justify-center flex-col">
          <div
            tw="flex flex-wrap justify-center font-normal text-base leading-[18px] text-[#726c66]"
          >
            <span tw="pr-2 mr-2 border-r-2 whitespace-nowrap mt-[12px]">
              Your suppliers:
            </span>
            {currentBuyingPharmacy &&
              <>
                {sortSuppliers(currentBuyingPharmacy.suppliers).filter((s) => {
                  return !supplierIsDisabledInEnhancedClient(s.name, enhancedClientVersion)
                }).map((s, index) => {
                  return enhancedClientActive &&
                  ((enhancedSupplierConnectionStatuses[s.name] || s.isEcommerceEnabled && eCommerceAvailable) || s.offlinePurchasing) ? (
                    <span
                      tw="pr-2 mr-2 border-r-2 whitespace-nowrap mt-[12px] cursor-pointer"
                      css={[
                        index >= currentBuyingPharmacy.suppliers.length - 1 &&
                        tw`border-0  w-[3px]`,
                        problemSuppliers.find((p) => s.name === p.name) &&
                        tw`text-red`,
                      ]}
                      key={s.id}
                    >
                    <a onClick={(e) => handleSupplierClick(s, e)}>
                      {s.displayName}
                    </a>
                  </span>
                  ) : (
                    <span
                      tw="pr-2 mr-2 border-r-2 whitespace-nowrap mt-[12px]"
                      css={
                        index >= currentBuyingPharmacy.suppliers.length - 1 &&
                        tw`border-0  w-[3px]`
                      }
                      key={s.id}
                    >
                    {s.displayName}
                  </span>
                  );
                })}
              </>
            }
          </div>
          {problemSuppliers.map((s) => {
            return (
              <div
                key={s.name}
                tw="font-bold text-base leading-[18px] text-red mt-[4px]"
              >
                {s.displayName} may be unavailable. Click above to view and
                close any popups. If necessary refresh or lastly reset the
                connection.
              </div>
            );
          })}
          {(allowAccountManagement && enhancedClientVersion && semver.gte(enhancedClientVersion, '0.2.181')) ? (
            <div tw="font-normal text-base text-[#726c66] leading-[18px] mt-[16px]">
              <span
                tw="pr-2 mr-2 border-r-2 whitespace-nowrap mt-[12px] cursor-pointer"
                css={[tw`border-0  w-[3px]`,]}
              ><a onClick={() => handlePasswordManagement()}>Workstation Stored Passwords</a></span>
            </div>
            ): null
          }

        </div>
      </Container>
    </Box>
  );
}
