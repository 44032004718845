import React from "react";
import "twin.macro";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { Link } from "react-router-dom";
import { AdminPharmacyOrganization } from "../../../../../utilities/types";
import { getAdminPharmacyOrganizations } from "../../../../../services/legacy/pharmacyOrganizations";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { getDateSorter } from "../../../../../components/antd/table/getDateSorter";
import { renderDate } from "../../../../../components/antd/table/renderDate";
import { getAdminPharmacies } from "../../../../../services/pharmacies";
import { renderAdminPharmacies } from "../../../../../components/antd/table/renderAdminPharmacies";
import { useListTable } from "../../../../../components/antd/table/useListTable";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { useTableColumnsWithOptions } from "../../../../../components/antd/table/useTableColumnsWithOptions";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus } from "../../../../../components/rxLibrary/icons";
import { getColumnSearchProps } from "../../../../../components/antd/table/getColumnSearchProps";

const BREADCRUMBS = [{ title: "Pharmacy Organizations" }];

const COLUMNS: TableColumns<AdminPharmacyOrganization> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 50,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "name",
    title: "Name",
    width: 150,
    fixed: "left",
    ...getColumnSearchProps("name"),
    sorter: getTextSorter("name"),
  },
  {
    dataIndex: "pharmacies",
    title: "Pharmacies",
    width: 800,
    render: renderAdminPharmacies,
  },
  {
    dataIndex: "createdAt",
    title: "Created At",
    width: 110,
    sorter: getDateSorter("createdAt"),
    render: renderDate,
  },
  {
    dataIndex: "updatedAt",
    title: "Update At",
    width: 110,
    sorter: getDateSorter("updateAt"),
    render: renderDate,
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 80,
    fixed: "right",
    render: (id) => (
      <div>
        <Link to={`/admin/pharmacy-organization/${id}`}>
          <IconButton>
            <EditOutlined rev={undefined} />
          </IconButton>
        </Link>
      </div>
    ),
  },
];

async function fetchOptions(token: string) {
  const response = await getAdminPharmacies(token);
  const pharmacies = response.data?.pharmacies;
  if (!pharmacies) throw new Error("Error fetching no admin pharmacies");
  return { data: { filters: { pharmacies } } };
}

function formatColumn(
  dataIndex: string,
  options: Awaited<ReturnType<typeof fetchOptions>>["data"]["filters"]
) {
  if (dataIndex === "pharmacies") {
    const filtersRaw = options[dataIndex];
    const filters = filtersRaw.map((v) => ({ text: v.name, value: v.id }));
    const onFilter = (value: string, r: any) => {
      return r.pharmacies?.map((p: any) => p.id).includes(value);
    };
    return { filters, onFilter, filterSearch: true };
  }
}

export function AdminPharmacyOrganizations() {
  const { list, isLoading } = useListTable({
    name: "pharmacyOrganizations",
    fetchList: getAdminPharmacyOrganizations,
  });

  const columns = useTableColumnsWithOptions({
    name: "filters",
    columns: COLUMNS,
    fetchOptions,
    formatColumn,
  });

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/pharmacy-organization/new">
          <Button icon={<IconPlus />}>Add Pharmacy Organization</Button>
        </Link>
      </AdminManagementHeader>

      <AdminManagementTable
        columns={columns}
        dataSource={list}
        loading={isLoading}
      />
    </>
  );
}
