import { OrderHistoryOrder } from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";

export function getOrderHistory(
  client: RequestClient,
  {
    pharmacyId,
    supplierId,
    ...config
  }: RequestClientConfig<{
    pharmacyId: number;
    supplierId: number;
  }>
) {
  return client<{ orders: OrderHistoryOrder[] }>({
    ...config,
    url: "/order-history",
    params: { pharmacyId, supplierId },
  });
}
