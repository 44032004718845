import React from "react";
import { CatalogInfo } from "../utilities/types";
import { formatDrugExpirationDate } from "../utilities/dates/formatDrugExpirationDate";
import {SupplierItemDataType} from "../enhanced/EnhancedClientContext";

export function ExpirationDate({
  catalogInfo,
  supplierData,
  textOnly,
}: {
  catalogInfo?: CatalogInfo;
  textOnly?: boolean;
  supplierData?: SupplierItemDataType;
}) {
  let expirationDate = formatDrugExpirationDate(supplierData);
  if (!expirationDate) {
    expirationDate = formatDrugExpirationDate(catalogInfo);
  }

  if (!expirationDate) return null;
  return (
    <span className="color-green">
      {textOnly ? `Exp: ${expirationDate}` : `(Exp: ${expirationDate})`}
    </span>
  );
}
