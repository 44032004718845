import type { DrugInfo } from "../types";

export function formatDrugNameWithDetails(drug: DrugInfo) {
  const { name, unitDoseDescription, controlledSubstanceCode } = drug;
  let drugName = name;

  if (unitDoseDescription === "Unit-Dose Packaging") {
    drugName += " (UD)";
  }
  if (!!controlledSubstanceCode && controlledSubstanceCode !== "U") {
    drugName += ` (C${controlledSubstanceCode})`;
  }
  if (drug.strength && drug.strengthUnit) {
    drugName += ` ${`${drug.strength}${drug.strengthUnit}`.toUpperCase()}`;
  }

  return drugName;
}
