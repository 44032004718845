import "twin.macro";
import { AgGridReact } from "ag-grid-react";
import { COLUMN_DEFS } from "./NonAdherentRefillOutreachReport.constants";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { NonAdherentRefillOutreachReportDetailsCellRendered } from "./NonAdherentRefillOutreachReportDetailsCellRendered/NonAdherentRefillOutreachReportDetailsCellRendered";
import { GridOptions } from "ag-grid-community";
import React, { useMemo } from "react";

const getRowStyle: GridOptions["getRowStyle"] = (params) => {
  if (params?.data?.pinnedRow) return { "font-weight": "bold" };
};

function getSumAtKey(data: any[], key: string) {
  const sum = data.map((v) => v[key]).reduce((acc, v) => acc + v, 0);
  return sum;
}

function getPinnedRowData(data: any[]) {
  if (!data.length) return;

  const nonAdherentRefillOpportunitiesSum = getSumAtKey(
    data,
    "Non-Adherent Refill Opportunities"
  );
  const attemptedOutreachesSum = getSumAtKey(
    data,
    "Attempted Outreaches (of Non-Adherent Refill Opportunity)"
  );

  const dispensedRefillsSum = getSumAtKey(data, "Dispensed Refills");

  const percentageAttemptedSum =
    nonAdherentRefillOpportunitiesSum === 0
      ? 0
      : attemptedOutreachesSum / nonAdherentRefillOpportunitiesSum;

  const percentageDispensedOfAttempted =
    attemptedOutreachesSum === 0
      ? 0
      : dispensedRefillsSum / attemptedOutreachesSum;

  const abandonedTransferredRefillsSum = getSumAtKey(data, "Abandoned/Transferred Refills");
  const inProgressRefillsSum = getSumAtKey(data, "In-Progress Refills");

  return [
    {
      pivot: false,
      pinnedRow: true,
      "Non-Adherent Refill Opportunities": nonAdherentRefillOpportunitiesSum,
      "Attempted Outreaches (of Non-Adherent Refill Opportunity)":
        attemptedOutreachesSum,
      "% Attempted (of Non-Adherent Refill Opportunity)":
        percentageAttemptedSum,
      "Dispensed Refills": dispensedRefillsSum,
      "% Dispensed of Attempted": percentageDispensedOfAttempted,
      "Abandoned/Transferred Refills": abandonedTransferredRefillsSum,
      "In-Progress Refills": inProgressRefillsSum,
    },
  ];
}

export function NonAdherentRefillOutreachReport({ data }: { data: never[] }) {
  const pinnedRowData = useMemo(() => getPinnedRowData(data), [data]);
  const report = useReport({
    columnDefs: COLUMN_DEFS,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        detailRowHeight={400}
        detailCellRenderer={NonAdherentRefillOutreachReportDetailsCellRendered}
        rowGroupPanelShow="always"
        getRowStyle={getRowStyle}
        pinnedTopRowData={pinnedRowData}
      />
    </ReportWrapper>
  );
}
