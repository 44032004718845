import React, {useEffect, useMemo, useState} from "react";
import { v4 as uuidv4 } from "uuid";
import "twin.macro";
import { useHistory } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import type {
  Pharmacy,
  OfferedDrug,
  CatalogOverride,
  ShoppingSupplierCheck,
  PrescriptionQuantityItem,
} from "../../../utilities/types";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { useAuth0 } from "../../../contexts/AuthenticationContext";
import {
  useShoppingState,
  useShoppingUpdater,
} from "../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import {useShoppingCartServerUpdater} from "../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import EnhancedShoppingListSearch from "../../../enhanced/EnhancedShoppingListSearch";
import {
  EnhancedStockValidationAvailability,
  RequestEnhanceItemParam,
} from "../../../enhanced/EnhancedClientContext";
import { cleanUpPurchaseByString } from "../../../utilities/prescriptions/purchaseBy/cleanUpPurchaseByString";
import { drugInfoToDrugNameUOMSizeStr } from "../../../utilities/drugInfo/drugInfoToDrugNameUOMSizeStr";
import { SupplierModal } from "./SupplierModal";
import {optimizeCart} from "../../../services/legacy/optimizations";
import {OptimizedCartResponse_All} from "../../../services/types";

const MODAL_STYLE = {
  position: "absolute",
  width: "836px",
  top: "80px",
  left: "calc(50% - 418px)",
  right: "40px",
  bottom: "160px",
  border: "1px solid #ccc",
  background: "#fff",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "9px",
  outline: "none",
} as const;

function supplierIdToNameLookupsFor(
  pharmacy: Pharmacy | null
): Record<number, string> {
  const supplierIdsToNames: Record<number, string> = {};
  pharmacy?.suppliers.forEach((s) => {
    supplierIdsToNames[s.id] = s.name;
  });
  return supplierIdsToNames;
}

function supplierNameToIdLookupsFor(
  pharmacy: Pharmacy | null
): Record<string, number> {
  const supplierNamesToIds: Record<string, number> = {};
  pharmacy?.suppliers.forEach((s) => {
    supplierNamesToIds[s.name] = s.id;
  });
  return supplierNamesToIds;
}

function coerceStringifiedNumber(
  value: number | string | undefined
): number | undefined {
  if (typeof value === "number" || value === undefined) {
    return value;
  }
  return parseInt(value) || 0;
}

const makeShoppingList = (
  filteredSuppliersList: ShoppingSupplierCheck[],
  offeredDrugs: OfferedDrug[],
  currentBuyingPharmacy: Pharmacy | null
): RequestEnhanceItemParam[] => {
  const supplierIdsToNames = supplierIdToNameLookupsFor(currentBuyingPharmacy);

  return offeredDrugs
    .filter((offer) => {
      return (
        filteredSuppliersList.find((supplier) => {
          return supplier.id === offer.supplierId;
        })?.check && supplierIdsToNames[offer.supplierId]
      );
    })
    .map((offer) => {
      return {
        ndc: offer.drug.ndc,
        supplier: supplierIdsToNames[offer.supplierId],
        drugNameUOMSizeStr: drugInfoToDrugNameUOMSizeStr(offer.drug),
        price: 0,
        supplierItemNumbers: offer.supplierItemNumbers,
      } as RequestEnhanceItemParam;
    });
};

export function SelectSupplierModal({
  reLoad = false,
  prescriptionData,
  offeredDrugs,
  modalActive,
  selectedSuppliers,
  onClose,
  onOptimizationError,
}: {
  reLoad?: boolean;
  prescriptionData: PrescriptionQuantityItem[];
  offeredDrugs: OfferedDrug[];
  modalActive: boolean;
  selectedSuppliers?: ShoppingSupplierCheck[];
  onClose: (mode: boolean) => void;
  onOptimizationError?: (error?: string) => void;
}) {
  const history = useHistory();
  const { currentBuyingPharmacyId, currentBuyingPharmacy } =
    useBuyingPharmacy();
  const { getAccessTokenSilently } = useAuth0();
  const { clearMutationUndoStack } = useShoppingCartServerUpdater();
  const { addOptimizeCartResponse } = useShoppingState();
  const { setHasPricesChanged } = useShoppingUpdater();
  const [supplierModalActive, setSupplierModalActive] = useState(false);
  const [filteredSuppliersList, setFilteredSuppliersList] = useState<
    ShoppingSupplierCheck[]
  >([]);
  const [catalogOverrides, setCatalogOverrides] = useState<{traceId: string, overrides: CatalogOverride[]} | null>(null);
  const [catalogOverrideProcessComplete, setCatalogOverrideProcessComplete] =
    useState(false);
  const [completedOptimization, setCompletedOptimization] = useState<{traceId: string, optimizeCartResponseAll: OptimizedCartResponse_All} | null>(null);
  const [cancelledOptimizationTraceIds, setCancelledOptimizationTraceIds] = useState<Record<string, boolean>>({});
  const [searchModalConfig, setSearchModalConfig] = useState<{active: false} | {active: true, traceId: string}>({active: false});

  useEffect(() => {
    (async () => {
      if (!catalogOverrideProcessComplete) return;
      if (!catalogOverrides) return;

      const token = await getAccessTokenSilently();
      const allowedSupplierIds = filteredSuppliersList
        .filter((supplier) => supplier.check)
        .map((supplier) => supplier.id);

      try {
        console.log("Optimizing cart...", catalogOverrides, catalogOverrideProcessComplete);
        setCatalogOverrideProcessComplete(false);
        setCatalogOverrides(null);
        const optimizeCartResponseAll = await optimizeCart(
          currentBuyingPharmacyId,
          token,
          prescriptionData.map((item) => {
            const purchaseBy = cleanUpPurchaseByString(item.purchaseBy);

            return {
              ndc: item.drug.ndc,
              allowManufacturerSubstitution:
                item.allowManufacturerSubstitution || false,
              allowPackSizeSubstitution:
                item.allowPackSizeSubstitution || false,
              rxNumbers: item.rxNumbers,
              ...(!item.useQuantityInput && { numPackages: item.numPackages }),
              ...(item.useQuantityInput && { quantity: item.quantityToBuy }),
              ...(purchaseBy && { purchaseBy }),
            };
          }),
          allowedSupplierIds,
          false,
          catalogOverrides.overrides,
          offeredDrugs,
        );

        setCompletedOptimization({traceId: catalogOverrides.traceId, optimizeCartResponseAll});
      } catch (e) {
        console.error(e);
        setSearchModalConfig({active: false});
        setHasPricesChanged(false);
        onOptimizationError && onOptimizationError();
      }
    })();
  }, [
    catalogOverrideProcessComplete,
    currentBuyingPharmacyId,
    prescriptionData,
    offeredDrugs,
    catalogOverrides,
    filteredSuppliersList,
  ]);

  useEffect(() => {
    if (completedOptimization) {
      if (cancelledOptimizationTraceIds[completedOptimization.traceId]) {
        console.log("Optimization cancelled; discarding", completedOptimization.traceId);
        setCompletedOptimization(null);
        setCancelledOptimizationTraceIds((prev) => {
          delete prev[completedOptimization.traceId];
          return prev;
        });
        return;
      }
      console.log("Optimization completed; continuing", completedOptimization.traceId);
      addOptimizeCartResponse(completedOptimization.optimizeCartResponseAll);
      setCompletedOptimization(null);
      setSearchModalConfig({active: false});
      setHasPricesChanged(false);
      // move from page 2 to 3
      if (!reLoad) {
        history.push("/shoppingListThree");
      }
    }
  }, [completedOptimization, cancelledOptimizationTraceIds]);

  useEffect(() => {
    setSupplierModalActive(modalActive);
  }, [modalActive]);

  const shoppingList = useMemo(() => {
    return makeShoppingList(
      filteredSuppliersList,
      offeredDrugs,
      currentBuyingPharmacy
    );
  }, [filteredSuppliersList, offeredDrugs, currentBuyingPharmacy]);

  return (
    <>
      <Modal
        id="supplierModal"
        open={supplierModalActive}
        onClose={() => onClose && onClose(false)}
      >
        <SupplierModal
          selectedSuppliers={
            filteredSuppliersList.length === 0
              ? selectedSuppliers
              : filteredSuppliersList
          }
          onClose={() => onClose && onClose(false)}
          onContinue={async (suppliersList) => {
            // Only if we go from p2 to p3
            if (!selectedSuppliers) {
              clearMutationUndoStack();
            }
            setSupplierModalActive(false);
            setFilteredSuppliersList(suppliersList);
            setSearchModalConfig({active: true, traceId: uuidv4()});
          }}
        />
      </Modal>

      <Modal
        id="searchModal"
        open={searchModalConfig.active}
        disableEscapeKeyDown
        onClose={(_event: Event, reason: string) => {
          if (reason && reason == "backdropClick") {
            return;
          }
          onClose && onClose(false);
        }}
      >
        <Box sx={MODAL_STYLE}>
          <div style={{ padding: "64px 48px 24px 48px" }}>
            {searchModalConfig.active && (
              <EnhancedShoppingListSearch
                key={searchModalConfig.traceId}
                traceId={searchModalConfig.traceId}
                shoppingList={shoppingList}
                onComplete={(items, removedSuppliers, traceId) => {
                  if (removedSuppliers.length > 0) {
                    const lookups = supplierIdToNameLookupsFor(
                      currentBuyingPharmacy
                    );
                    const newFilteredSuppliersList: ShoppingSupplierCheck[] =
                      [];
                    filteredSuppliersList.forEach((supplier) => {
                      const name = lookups[supplier.id];
                      if (!name) {
                        return;
                      }
                      if (removedSuppliers.indexOf(name) >= 0) {
                        newFilteredSuppliersList.push({
                          id: supplier.id,
                          check: false,
                        });
                        return;
                      }
                      newFilteredSuppliersList.push(supplier);
                    });
                    setFilteredSuppliersList(newFilteredSuppliersList);
                  }
                  if (items.length > 0) {
                    const lookups = supplierNameToIdLookupsFor(
                      currentBuyingPharmacy
                    );
                    const newCatalogOverrides: CatalogOverride[] = items.map(
                      (item) => {
                        return {
                          ndc: item.ndc,
                          supplierId: lookups[item.supplier],
                          price: (item.price as number) || 999999,
                          supplierItemNumber: String(
                            (item.supplierItemNumber as string) ||
                              `${lookups[item.supplier]}_${item.ndc}`
                          ),
                          isAvailable:
                            !!item.price &&
                            item.availability ===
                              EnhancedStockValidationAvailability.AVAILABLE,
                          numPackagesAvailable: coerceStringifiedNumber(
                            item.quantityAvailable
                          ),
                          isRebatedItem: item.isRebatedItem,
                          link: item.deeplink,
                          expirationDate: item.expirationDate,
                        };
                      }
                    );
                    setCatalogOverrides({traceId, overrides: newCatalogOverrides});
                  } else {
                    setCatalogOverrides({traceId, overrides: []});
                  }
                  setCatalogOverrideProcessComplete(true);
                }}
                onCancelAfterCompleted={(traceId: string) => {
                  console.log("cancelling optimization", traceId);
                  setCancelledOptimizationTraceIds((prev) => {
                    return {...prev, [traceId]: true};
                  });
                  setSearchModalConfig({active: false});
                  setSupplierModalActive(true);
                }}
                onAbort={() => {
                  setSearchModalConfig({active: false});
                  setSupplierModalActive(true);
                }}
              />
            )}
          </div>
        </Box>
      </Modal>
    </>
  );
}
