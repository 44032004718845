/**
 * Join sentences in a human-readable way.
 * Example:  ["a", "b", "c", "d"] is formatted like "a, b, c, and d"
 */
export function joinReadableSentences(sentences: string[]) {
  const count = sentences.length;
  const phrase = sentences.reduce((acc, sentence, index) => {
    if (index === 0) return sentence;
    if (index === count - 1) return `${acc} and ${sentence}`;
    return `${acc}, ${sentence}`;
  }, "");

  return phrase;
}
