import { useState, useEffect } from "react";
import type { Dayjs } from "dayjs";
import { dayjsWithDuration } from "../../utilities/dates/dayjsWithDuration";
import { capitalizeFirstLetter } from "../../utilities/strings/capitalizeFirstLetter";

export function HumanizedTimeAgo({
  date,
  capitalize,
}: {
  date?: Dayjs;
  capitalize?: boolean;
}) {
  const [timeAgoStr, setTimeAgoStr] = useState<string>();

  useEffect(() => {
    if (!date) {
      setTimeAgoStr(undefined);
      return;
    }

    updateTimeAgoStr();
    const intervalId = setInterval(updateTimeAgoStr, 60000); // 1 minute
    return () => {
      clearInterval(intervalId);
    };

    function updateTimeAgoStr() {
      if (!date) {
        setTimeAgoStr(undefined);
        return;
      }

      let newTimeAgoStr = dayjsWithDuration
        .duration(date.diff(new Date()))
        .humanize(true);
      if (capitalize) newTimeAgoStr = capitalizeFirstLetter(newTimeAgoStr);
      setTimeAgoStr(newTimeAgoStr);
    }
  }, [date, capitalize]);

  if (!timeAgoStr) return null;
  return timeAgoStr;
}
