import React, { useContext } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import PharmaciesContext from "../contexts/PharmaciesContext";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import EnhancedClientOfflinePurchaseContext from "./EnhancedClientOfflinePurchaseContext";

const modalGrayStyle = {
  position: "absolute",
  width: "1100px",
  top: "40px",
  left: "calc(50% - 550px)",
  right: "40px",
  bottom: "40px",
  border: "1px solid #ccc",
  background: "#F5F5F5",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "9px",
  outline: "none",
  padding: "64px 48px",
};

export default function EnhancedOfflineSupplierModal(): JSX.Element | null{
  const {
    enhancedOfflineSupplierModalShown,
    setEnhancedOfflineSupplierModalShown,
    enhancedOfflineSupplierName,
  } = useContext(EnhancedClientOfflinePurchaseContext);

  const { pharmacies } = useContext(PharmaciesContext);
  const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);

  const currentPharmacy = pharmacies.find(p => p.id === currentBuyingPharmacyId);
  const supplier = currentPharmacy?.suppliers.find(s => s.name === enhancedOfflineSupplierName);

  const hasNotes = supplier?.shippingConfig?.notes && supplier.shippingConfig.notes.length > 0;
  return <Modal
    open={enhancedOfflineSupplierModalShown && !!currentPharmacy && !!supplier}
    id="Offline Supplier Modal"
    onClose={() => setEnhancedOfflineSupplierModalShown(false)}
  >
    <Box sx={modalGrayStyle}>
    {(enhancedOfflineSupplierModalShown && currentPharmacy && supplier) ? (
      <div className="pt-48 pl-48">
        <div className="flex justify-between items-center">
          <h1 className="text-xl">Offline Purchase Required - {supplier.displayName}</h1>
          <a
            href="#"
            className="mr-32 underline"
            onClick={() => {
              setEnhancedOfflineSupplierModalShown(false);
            }}
          >
            Cancel
          </a>
        </div>
        <p className="pt-8">{supplier.displayName} does not yet support online purchases. Please
          purchase through your traditional offline method (phone / email / text).
        </p>
        {hasNotes && (
          <p className="pt-8">
            <strong>Note for {supplier.displayName}:</strong> {supplier.shippingConfig?.notes}
          </p>
        )}
        </div>
      ) : <></>}
    </Box>
  </Modal>
}
