import React, { ChangeEvent } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { RadioButtonLabelType } from '../utilities/types';

interface IRadioButtonsGroupProps {
  id?: string;
  groupLabel?: string;
  selectedValue: string | number;
  options: RadioButtonLabelType[];
  isVertical?: boolean;
  labelMaxHeight?: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonsGroup = (props: IRadioButtonsGroupProps): JSX.Element => {
  const {
    id,
    groupLabel,
    selectedValue,
    options,
    isVertical = false,
    labelMaxHeight,
    onChange,
  } = props;

  return (
    <FormControl
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      fullWidth
      key={`form-control-${id}`}
      className="radio-button-group"
    >
      {/* TODO: Add Material UI Theme/Palette to control the FormLabel color directly using color props without forcing color important */}
      {groupLabel && (
        <FormLabel
          id="radio-buttons-group-label"
          sx={{ fontWeight: 'bold', mr: isVertical ? 0 : 2, color: 'black !important', fontFamily: 'Roboto Condensed, sans-serif' }}
        >
          {groupLabel}
        </FormLabel>
      )}
      <RadioGroup
        row={!isVertical}
        aria-labelledby="radio-buttons-group-label"
        name="radio-buttons-group"
        value={selectedValue}
        onChange={onChange}
        sx={{ flexWrap: 'nowrap', '.MuiButtonBase-root' : { paddingRight: '4px' } }}
        color="error"
      >
        {options.length > 0 &&
          options.map((label: RadioButtonLabelType, index) => (
            <FormControlLabel
              key={`form-control-label-${id}-${index}`}
              value={label.value}
              control={<Radio />}
              label={label.label}
              disabled={label.disabled || false}
              sx={{
                ...(isVertical && { marginRight: 0 }),
                maxHeight: labelMaxHeight,
                '& .MuiFormControlLabel-label': {
                  fontSize: 14,
                  width: '100%',
                },
                display: 'flex',
                width: 'fit-content',
                '& .MuiSvgIcon-root': {
                  fontSize: 20,
                  color: 'black',
                },
              }}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonsGroup;
