import tw from "twin.macro";
import { Link } from "react-router-dom";
import { useBuyingPharmacy } from "../../../contexts/BuyingPharmacyContext";
import { Text } from "../../../components/rxLibrary/typography";
import { Button } from "../../../components/rxLibrary/buttons";
import { SimpleLink } from "../../../components/rxLibrary/Link";
import { Box } from "../../../components/rxLibrary/box";
import { Card } from "./Card";

export function DailyPurchaseCard() {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const hasLinkToAddRx = !!pharmacy?.uiSettings?.addRx;

  return (
    <Card>
      <div tw="flex flex-col items-center">
        <img tw="mb-[28px]" src="/assets/pages/home/daily-purchase.svg" />

        <Text tw="mb-[18px]" weight="bold" size={21}>
          DAILY PURCHASE
        </Text>

        <Text
          tw="w-[295px] mx-auto"
          css={hasLinkToAddRx ? tw`mb-[9px]` : tw`mb-[24px]`}
          size="medium"
          center
        >
          Add to inventory items throughout the day and make your consolidated,
          end-of-day orders.
        </Text>

        <Link to="/shoppingList">
          <Button>Go to List</Button>
        </Link>
      </div>

      {hasLinkToAddRx && (
        <Box
          tw="border-t border-dashed mt-[12px] mx-[-24px] p-[2px]"
          borderColor="grey-2"
        >
          <Box
            tw="text-center rounded-b-lg py-[10px]"
            bgColor="green-3"
            bgColorOpacity={40}
          >
            <Link to="/shopping-list-add-rx">
              <SimpleLink size="medium">
                Try the new beta version of Daily Purchase
              </SimpleLink>
            </Link>
          </Box>
        </Box>
      )}
    </Card>
  );
}
