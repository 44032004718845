import "twin.macro";
import { Text } from "components/rxLibrary/typography";

export function OptionLabelWithWarning({
  label,
  warning,
}: {
  label: string;
  warning?: string;
}) {
  return (
    <div tw="flex justify-between items-center space-x-2">
      <span>{label}</span>
      <Text color="grey-2">{warning}</Text>
    </div>
  );
}
