import { useState, useEffect } from "react";
import keyBy from "lodash/keyBy";
import uniq from "lodash/uniq";
import type { ItemInCart } from "../../../../../utilities/types";
import { isInventoryId } from "../../../../../utilities/prescriptions/isInventoryId";
import { useShoppingState } from "../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { useShoppingCartServerState } from "../../../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";

type UnavailableRxItem = {
  isInventory: boolean;
  prescription: ItemInCart;
  prescriptionId: string;
};

export function useUnavailableRxList() {
  const [unavailableRxList, setUnavailableRxList] = useState<
    UnavailableRxItem[]
  >([]);
  const { inventory } = useShoppingCartServerState();
  const { optimizeCartResponse, prescriptionItemsInCart } = useShoppingState();

  const { unavailable } = optimizeCartResponse?.data?.selections ?? {};

  useEffect(() => {
    if (!unavailable) {
      setUnavailableRxList([]);
      return;
    }

    const itemsById = {
      ...keyBy(inventory, "key"),
      ...keyBy(prescriptionItemsInCart, "rxNumber"),
    };
    const prescriptionIds = uniq(unavailable.flatMap((item) => item.rxNumbers));

    const newUnavailableRxList = prescriptionIds
      .map((prescriptionId) => {
        const isInventory = isInventoryId(prescriptionId);
        const prescription = itemsById[prescriptionId];
        if (!prescription) return;
        return { prescription, prescriptionId, isInventory };
      })
      .filter(Boolean) as UnavailableRxItem[];

    setUnavailableRxList(newUnavailableRxList);
  }, [
    inventory,
    unavailable,
    optimizeCartResponse,
    prescriptionItemsInCart,
  ]);

  return unavailableRxList;
}
