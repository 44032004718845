import React from "react";
import "twin.macro";
import { usePrescriptionNote } from "../../../../../../components/PrescriptionNote/usePrescriptionNote";
import { useShoppingUpdater } from "../../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import {
  ItemNote,
  ItemInCart,
  PrescriptionDetail,
} from "../../../../../../utilities/types";
import { Text } from "../../../../../../components/rxLibrary/typography";

function PrescriptionWithNote({
  prescriptionDetail,
  updatePrescriptionNote,
}: {
  prescriptionDetail: PrescriptionDetail;
  updatePrescriptionNote: (prescription: ItemInCart, note?: ItemNote) => void;
}) {
  const { id, prescription, isInventory } = prescriptionDetail;
  const { prescriptionNotePopover } = usePrescriptionNote({
    prescription,
    updatePrescriptionNote,
  });

  return (
    <div key={id} tw="flex justify-between items-center">
      <Text>{isInventory ? "Inventory" : `Rx ${id}`}</Text>
      {prescriptionNotePopover}
    </div>
  );
}

export function BuyingForCol({
  prescriptionDetails,
}: {
  prescriptionDetails: PrescriptionDetail[];
}) {
  const { updatePrescriptionNote } = useShoppingUpdater();

  return (
    <>
      <Text block weight="bold">
        Buying for:
      </Text>

      {prescriptionDetails.map((prescriptionDetail) => (
        <PrescriptionWithNote
          key={prescriptionDetail.id}
          prescriptionDetail={prescriptionDetail}
          updatePrescriptionNote={updatePrescriptionNote}
        />
      ))}
    </>
  );
}
