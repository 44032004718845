import dayjs from "dayjs";
import type { ServerState, ItemInCart, ItemPurchaseDetails } from "../types";
import { formatItemInCart, formatItemPurchaseDetails } from "./formatItem";
import { isInventoryId } from "./isInventoryId";

const MAX_ITEM_AGE_HOURS = 48;

function isOldProcessedItem(item: ItemInCart | ItemPurchaseDetails) {
  const { processedAt } = item;
  if (!processedAt) return false;

  const processedAtDate = dayjs(processedAt);
  const ageHours = dayjs().diff(processedAtDate, "hours", true);
  const isOld = ageHours >= MAX_ITEM_AGE_HOURS;
  return isOld;
}

function cleanInventory(inventory: ItemInCart[]): ItemInCart[] {
  const newInventory = inventory
    .map(formatItemInCart)
    .filter((item) => !isOldProcessedItem(item));
  return newInventory;
}

function cleanCart(cart: ItemPurchaseDetails[], cleanForPush = false) {
  const newCart = cart.map(formatItemPurchaseDetails).filter((item) => {
    const { id, note, status, purchaseBy } = item;

    if (!id) return false;
    // this is leftover from page 3
    if (id.indexOf("_") >= 0) return false;
    if (isInventoryId(id)) return false;
    if (isOldProcessedItem(item)) return false;

    if (cleanForPush) return status !== "add" || note || purchaseBy;

    return true;
  });

  return newCart;
}

export function cleanServerState(
  data?: ServerState,
  { cleanForPush }: { cleanForPush?: boolean } = {}
): ServerState {
  const newServerState: ServerState = data
    ? {
        cart: cleanCart(data.cart, cleanForPush),
        inventory: cleanInventory(data.inventory),
        prescriptionGroupAdjustments: data.prescriptionGroupAdjustments || [],
      }
    : { cart: [], inventory: [], prescriptionGroupAdjustments: [] };

  return newServerState;
}
