import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  expandHereValueGetter,
  manufacturerReportPercentFormatter,
} from "../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Fill Details",
    valueGetter: (params) => {
      if (params?.data?.pinnedRow) return "Total";
      return expandHereValueGetter();
    },
    cellRenderer: "agGroupCellRenderer",
    pinned: "left",
  },
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" },
      { field: "Pharmacy NPI", pinned: "left", hide: true },
    ],
  },
  {
    headerName: "Pharmacy Summary",
    children: [
      { field: "Program Start Date" },
      // "Refill Opportunities" and "% Non-Adherent" cols commented out
      // 2024-05-29 but left in query in case we want them again
      // { field: "Refill Opportunities", headerName: "Refill Opportunities" },
      {
        field: "Non-Adherent Refill Opportunities",
        headerName: "Non-Adherent Refill Opportunities",
        sort: "desc",
      },
      // {
      //   field: "% Non-Adherent",
      //   headerName: "% Non-Adherent",
      //   valueFormatter: (params) =>
      //     manufacturerReportPercentFormatter(params.value, 0),
      // },
      {
        field: "Attempted Outreaches (of Non-Adherent Refill Opportunity)",
        headerName: "Attempted Outreaches (of Non-Adherent Refill Opportunity)",
      },
      {
        field: "% Attempted (of Non-Adherent Refill Opportunity)",
        headerName: "% Attempted (of Non-Adherent Refill Opportunity)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "Abandoned/Transferred Refills",
        headerName: "Abandoned/Transferred Refills",
        hide: true,
      },
      {
        field: "In-Progress Refills",
        headerName: "In-Progress Refills",
        hide: true,
      },
      {
        field: "Dispensed Refills",
        headerName: "Dispensed Refills",
        aggFunc: "sum",
      },
      {
        field: "% Dispensed of Attempted",
        headerName: "% Dispensed of Attempted",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
    ],
  },
];
