import React, { useCallback, useMemo } from "react";
import "twin.macro";
import type { ItemInCart } from "../../../../../utilities/types";
import { useShoppingUpdater } from "../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { getPrescriptionId } from "../../../../../utilities/prescriptions/getPrescriptionId";
import { formatManufacturer } from "../../../../../utilities/prescriptions/formatManufacturer";
import { formatPackQuantity } from "../../../../../utilities/prescriptions/packQuantity/formatPackQuantity";
import { formatDrugDispenseQuantity } from "../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { formatBuyByText } from "../../../../../utilities/prescriptions/purchaseBy/formatBuyByText";
import { useDrugFamilyPrescriptionsInShoppingList } from "../../../../../utilities/prescriptions/drugFamilyPrescriptions/useDrugFamilyPrescriptionsInShoppingList";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { Col, Row } from "../../../../../components/rxLibrary/grid";
import { Text } from "../../../../../components/rxLibrary/typography";
import { IconCheck } from "../../../../../components/rxLibrary/icons";
import DispensedItem from "../../../../../components/DispensedItem";
import { ShoppingListColumnDispensedQuantity } from "../columns/ShoppingListColumnDispensedQuantity";
import { FillDateCol } from "../../../../../components/shopping/table/columns/FillDateCol";
import { RxDetailsCol } from "../../../../../components/shopping/table/columns/RxDetailsCol";
import { DuplicatedDrugFamilyWarning } from "../../../../../components/shopping/DuplicatedDrugFamilyWarning";

export function DuplicatedDrugFamily({
  prescription,
  excludePmsPrescriptions,
  closePopover,
}: {
  prescription: ItemInCart;
  excludePmsPrescriptions?: boolean;
  closePopover: () => void;
}) {
  const { updatePrescriptionStatus } = useShoppingUpdater();

  const drugFamilyPrescriptions = useDrugFamilyPrescriptionsInShoppingList({
    drug: prescription.drug,
    prescriptionToExclude: prescription,
    excludePmsPrescriptions,
  });

  const tableItems = useMemo(() => {
    return drugFamilyPrescriptions.map((prescription) => {
      const { drug, status, purchaseBy } = prescription;
      const id = getPrescriptionId(prescription);
      const quantityDescription = formatDrugDispenseQuantity(drug);
      const manufacturer = formatManufacturer(prescription);
      const packQuantity = formatPackQuantity(prescription);
      const prescriptionStatus = status === "list" ? "In List" : status;
      const buyByText = formatBuyByText(purchaseBy);

      return {
        id,
        drug,
        buyByText,
        manufacturer,
        packQuantity,
        prescription,
        prescriptionStatus,
        quantityDescription,
      };
    });
  }, [drugFamilyPrescriptions]);

  const setListPrescriptionStatus = useCallback(() => {
    updatePrescriptionStatus(prescription, "list");
    closePopover();
  }, [prescription, closePopover, updatePrescriptionStatus]);

  return (
    <WideContainer tw="px-[76px] py-[36px]">
      <DuplicatedDrugFamilyWarning
        itemsCount={drugFamilyPrescriptions.length}
        excludePmsPrescriptions={excludePmsPrescriptions}
      />

      <div tw="mb-[17px] mt-[8px]">
        {tableItems.map((item) => {
          const {
            id,
            drug,
            buyByText,
            manufacturer,
            packQuantity,
            prescription,
            quantityDescription,
            prescriptionStatus,
          } = item;

          return (
            <Row key={id}>
              <Col tw="flex-1">
                <FillDateCol prescription={prescription} />
              </Col>
              <Col tw="flex-[2]">
                <DispensedItem drug={drug} />
              </Col>
              <Col tw="flex-1">
                <Text block weight="bold">
                  {quantityDescription}
                </Text>
                <Text block>{manufacturer}</Text>
              </Col>
              <Col tw="flex-1">
                <RxDetailsCol prescription={prescription} />
              </Col>
              <Col tw="flex-1">
                <ShoppingListColumnDispensedQuantity
                  prescription={prescription}
                  hideDispensedQuantity
                />
              </Col>
              <Col tw="flex-1">{packQuantity}</Col>
              <Col tw="flex-1">
                <div tw="flex flex-col items-center">
                  <div tw="flex space-x-1">
                    <IconCheck />
                    <Text block weight="bold">
                      {prescriptionStatus}
                    </Text>
                  </div>
                  <Text block color="grey-1">
                    {buyByText}
                  </Text>
                </div>
              </Col>
            </Row>
          );
        })}
      </div>

      <div tw="flex justify-center items-center space-x-[16px]">
        <Button variant="secondary" onClick={closePopover}>
          Cancel
        </Button>
        <Button
          onClick={setListPrescriptionStatus}
        >{`It's OK, Add to List`}</Button>
      </div>
    </WideContainer>
  );
}
