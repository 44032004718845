import React, { useMemo } from "react";
import "twin.macro";
import { Modal, Table, Tooltip } from "antd";
import TableOutlined from "@ant-design/icons/TableOutlined";
import { useModal } from "../../../../../../components/rxLibrary/modals";
import { IconButton } from "../../../../../../components/rxLibrary/buttons";
import { Title } from "../../../../../../components/rxLibrary/typography";
import { UseSupplierMappingCustomerAccountIds } from "./useSupplierMappingCustomerAccountIds";

export function CustomerAccountIdInputLabel({
  supplierName,
  supplierMappingCustomerAccountIdsResponseData,
}: {
  supplierMappingCustomerAccountIdsResponseData: UseSupplierMappingCustomerAccountIds;
  supplierName?: string;
}) {
  const { data, isLoading, error } =
    supplierMappingCustomerAccountIdsResponseData;

  const { isModalOpen, openModal, closeModal } = useModal();

  const tooltipTitle = useMemo(() => {
    if (!supplierName) {
      return `Select a supplier to enable preview of customer account ids of the same supplier`;
    }
    return `Open customer account Ids for ${supplierName}`;
  }, [supplierName]);

  return (
    <>
      <div tw="flex space-x-1 items-center">
        <span>Customer Account ID</span>

        <div>
          <Tooltip title={tooltipTitle}>
            <IconButton disabled={!supplierName} onClick={openModal}>
              <TableOutlined rev={undefined} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {supplierName && isModalOpen && (
        <Modal open onCancel={closeModal} footer={false} width={820}>
          <Title tw="mb-2" block>
            Account Ids for {supplierName}
          </Title>

          {error ? (
            "Failed to get customer account ids data"
          ) : (
            <Table
              sticky
              pagination={false}
              loading={isLoading}
              dataSource={data}
              scroll={{ y: 600 }}
              columns={[
                { title: "Pharmacy", dataIndex: "pharmacyName", width: 160 },
                {
                  title: "Customer Account ID",
                  dataIndex: "customerAccountId",
                  width: 140,
                },
                {
                  title: "Customer Account ID Length",
                  dataIndex: "customerAccountId",
                  width: 120,
                  render: (value: string) => value.length,
                },
              ]}
            />
          )}
        </Modal>
      )}
    </>
  );
}
