import { IDropDownItem } from "../../components/DropDownListQty";
import type {
  DispensesStats,
  DispensesStatsData,
  DrugInfo,
  DrugWithStats,
} from "../types";

const DESCRIBED_UNITS = ["gram", "milliliter"];

export const getDispensesStatsGroup = (
  data: DrugWithStats,
  type: string
): DispensesStatsData => {
  const states: DispensesStats | undefined = data.dispensesStats;
  const obj =
    type === "last30Days"
      ? states?.last30Days
      : type === "last90Days"
      ? states?.last90Days
      : states?.last180Days;

  return obj || { numberOfPrescriptions: 0, numberOfUnits: 0 };
};

export const getQuantity = (drugInfo: DrugInfo) => {
  let quantity = 0;
  if (drugInfo.uom === "Each") {
    if (drugInfo.unitQuantity === 1) {
      if (drugInfo.unitSize === 1) {
        quantity = 1;
      } else {
        quantity = drugInfo.unitSize;
      }
    } else {
      quantity = drugInfo.unitQuantity;
    }
  } else {
    quantity = drugInfo.unitQuantity;
  }

  return quantity.toString();
};

export const getPackSizeList = (
  data: DrugWithStats[],
  manufacturer?: string
): IDropDownItem[] => {
  const items = manufacturer
    ? data.filter(
        (item) => item.drug.manufacturer === manufacturer
      )
    : data;
  const arr = items.map((item) => {
    const quantity = getQuantity(item.drug);

    return {
      label: quantity,
      value: quantity,
    };
  });

  const uniqueNames: string[] = [];
  return arr
    .filter((item: IDropDownItem) => {
      const isDuplicate = uniqueNames.includes(item.label);
      if (!isDuplicate) {
        uniqueNames.push(item.label);
        return true;
      }
      return false;
    })
    .sort((a: IDropDownItem, b: IDropDownItem) => {
      const firstLabel = parseInt(a.label);
      const secondLabel = parseInt(b.label);

      if (firstLabel < secondLabel) {
        return -1;
      }
      if (firstLabel > secondLabel) {
        return 1;
      }

      return 0;
    });
};

export function drugInfoHasValidUnits(drug?: DrugInfo): boolean {
  if (!drug) return false

  const { uom, unitSize } = drug;
  const isValid = !!uom && !!unitSize && DESCRIBED_UNITS.includes(uom.toLocaleLowerCase())

  return isValid
}

export function getUnitSizeFromDrugInfo(drug?: DrugInfo): string | void {
  if (drugInfoHasValidUnits(drug)) {
    return drug?.unitSize?.toString();
  }
}
