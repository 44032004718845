import React, { ReactNode, useCallback, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { Affix } from "antd";

export const SHOPPING_LIST_TABLE_NAV_BAR_STICKY_HEIGHT = 72;

export function ShoppingListTableNavBarSticky({
  children,
  offsetTop,
  stickyBorderTop,
}: {
  children: ReactNode;
  offsetTop: number;
  stickyBorderTop?: boolean;
}) {
  const [isSticky, setIsSticky] = useState(false);

  const onChange = useCallback((affixed?: boolean) => {
    setIsSticky(!!affixed);
  }, []);

  return (
    <>
      <Affix offsetTop={offsetTop} onChange={onChange}>
        <div />
      </Affix>
      <Wrapper
        isSticky={isSticky}
        offsetTop={offsetTop}
        stickyBorderTop={stickyBorderTop}
      >
        {children}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div(
  ({
    isSticky,
    offsetTop,
    stickyBorderTop,
  }: {
    isSticky: boolean;
    offsetTop: number;
    stickyBorderTop?: boolean;
  }) => [
    tw`flex items-center justify-between px-[10px] bg-[#dee0ed] sticky z-20`,
    !isSticky && tw`rounded-t-md`,
    isSticky && stickyBorderTop && tw`border-t-[5px] border-white`,
    css`
      top: ${offsetTop}px;
      height: ${SHOPPING_LIST_TABLE_NAV_BAR_STICKY_HEIGHT}px;
    `,
  ]
);
