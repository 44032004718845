import { useEffect, useState } from "react";
import type { ManufacturerStoreItem } from "../Catalog.constants";
import { SUPPLIER_ID_MAYNE } from "utilities/config";

export function useHasFoamItems(
  supplierId: number,
  cartItems: ManufacturerStoreItem[]
) {
  const [hasFoamItems, setHasFoamItems] = useState(false);

  useEffect(() => {
    if (supplierId !== SUPPLIER_ID_MAYNE) return;

    const newHasFoamItems = cartItems.some((item) => item.drug.form === "Foam");
    setHasFoamItems(newHasFoamItems);
  }, [cartItems]);

  return hasFoamItems;
}
