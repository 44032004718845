// eslint-disable-next-line  @typescript-eslint/no-unused-vars
import React from "react";

// Make sure to only include the library in development
if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const whyDidYouRender = require("@welldone-software/why-did-you-render");
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true
  // });
  console.log("development");
}
