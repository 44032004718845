import React from "react";
import { RowClassRules, SideBarDef } from "ag-grid-community";

export const DEFAULT_AG_GRID_SIDEBAR: SideBarDef = {
  position: "right",
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
      width: 300,
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
      width: 300,
    },
  ],
};

export const DEFAULT_COL_DEF = {
  resizable: true,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: true,
  wrapHeaderText: true,
  autoHeaderHeight: true,
  sortable: true,
  filter: true,
};

export const ROW_CLASS_RULES: RowClassRules = {
  "reversed-claim": (params) => {
    return params.api.getValue("Was Claim Reversed", params.node);
  },

  "total-row": ({ data }) => {
    return data?.outcome === "Total"
  }
};