import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export function useReplaceQueryParams() {
  const history = useHistory();

  const replaceQueryParams = useCallback(
    (queryParams: Record<string, string>) => {
      const newQueryParams = new URLSearchParams(queryParams);
      history.replace({
        pathname: window.location.pathname,
        search: newQueryParams.toString(),
      });
    },
    []
  );

  return replaceQueryParams;
}
