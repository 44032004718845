import type { DrugInfo, SearchResult } from "../../utilities/types";

export type IHandleDrugClick = (options: {
  drug: DrugInfo;
  isSizeOption?: boolean;
}) => Promise<void> | void;

export type IDrugSearchTableData = DrugInfo[][];

export type IHandleSearchResults = (options: {
  searchResults: SearchResult[];
}) => Promise<boolean | void> | boolean | void;

export type IHandleSearchNdc = (
  ndc: string
) => Promise<boolean | void> | boolean | void;

export const DRUG_SEARCH_QUERY_PARAMS_KEYS = [
  "search",
  "brand",
  "strength",
  "form",
  "administration",
] as const;

type DrugSearchKeysType = typeof DRUG_SEARCH_QUERY_PARAMS_KEYS[number];

export type IDrugSearchQueryParams = Partial<
  Record<DrugSearchKeysType, string>
>;

export type IDrugSearchValues = Partial<Record<DrugSearchKeysType, string>> & {
  brand: string;
};

export const DRUG_SEARCH_BRAND_OPTIONS = [
  { value: "gb", label: "Generic/Brand" },
  { value: "g", label: "Generic" },
  { value: "b", label: "Brand" },
];

export const DEFAULT_DRUG_SEARCH_BRAND_VALUE =
  DRUG_SEARCH_BRAND_OPTIONS[0].value;
