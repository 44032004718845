import dayjs from "dayjs";
import isDate from "lodash/isDate";
import isString from "lodash/isString";

export function formatDateToDayWithTime(value?: string | Date): string | void {
  let date: Date | undefined;
  if (value && isString(value)) {
    date = new Date(Date.parse(value));
  } else if (isDate(value)) {
    date = value;
  }

  if (!!date && dayjs(date).isValid()) {
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return formattedDate;
  }
}
