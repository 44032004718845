import "twin.macro";
import { IconCheck, IconX } from "../../rxLibrary/icons";

export function renderBoolean(value: boolean | null) {
  if (value === null) return <div tw="flex justify-center">None</div>;
  return (
    <div tw="flex justify-center">
      {value ? <IconCheck color="green-1" /> : <IconX color="red-1" />}
    </div>
  );
}
