import React, { useCallback, useMemo } from "react";
import "twin.macro";
import { Text } from "../../../../../../../../components/rxLibrary/typography";
import { Warning } from "../../../../../../../../components/rxLibrary/warnings";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../../../../components/rxLibrary/popover";

export function OtherSuppliersPopover({
  suppliers,
  openModal,
}: {
  suppliers: string[];
  openModal: () => void;
}) {
  const { isPopoverOpen, closePopover, handleOpenPopoverChange } =
    useIsPopoverOpen();

  const msg = useMemo(() => {
    const otherSuppliersCount = suppliers.length;
    const suppliersMsg =
      otherSuppliersCount > 1
        ? ` ${otherSuppliersCount} suppliers`
        : " supplier";
    return `Also at other ${suppliersMsg}`;
  }, [suppliers]);

  const handleClick = useCallback(() => {
    closePopover();
    openModal();
  }, [openModal, closePopover]);

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={handleOpenPopoverChange}
      content={() => (
        <div tw="p-4">
          <Text block>Available at:</Text>
          <Text block>{suppliers.join(", ")}.</Text>
          <button tw="underline" onClick={handleClick}>
            See details
          </button>
        </div>
      )}
    >
      <button tw="underline">
        <Warning>{msg}</Warning>
      </button>
    </Popover>
  );
}
