import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  manufacturerReportPercentFormatter,
  expandHereValueGetter,
} from "../utils/manufacturerReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Fill Details",
    valueGetter: expandHereValueGetter,
    cellRenderer: "agGroupCellRenderer",
    pinned: "left",
  },
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" },
      { field: "Pharmacy NPI", pinned: "left", hide: true },
    ],
  },
  {
    headerName: "Pharmacy Summary",
    children: [
      { field: "dispensed_fills", headerName: "Dispensed Fills", sort: "desc" },
      {
        field: "covered_pct_of_dispensed",
        headerName: "Coverage Rate (of Dispensed Fills)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "dispensed_fill_prior_auth_count",
        headerName: "# Fills with PA Required",
        hide: true,
      },
      {
        field: "dispensed_fill_prior_auth_to_coupon_count",
        headerName: "# Fills with PA Required, Coupon Applied",
        hide: true,
      },
      {
        field: "dispensed_fill_fast_prior_auth_to_coupon_count",
        headerName:
          "# Fills with PA Required, Coupon Applied within 10 Mins of Rejection",
        hide: true,
      },
      {
        field: "dispensed_fill_pa_to_coupon_pct",
        headerName: "% Dispensed Fills w PA Required, Coupon Applied",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "dispensed_fill_fast_pa_to_coupon_pct",
        headerName:
          "% Dispensed Fills w PA Required, Coupon Applied within 10 Mins of Rejection",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "dispensed_nrx_prior_auth_count",
        headerName: "# NRx with PA Required",
        hide: true,
      },
      {
        field: "dispensed_nrx_prior_auth_to_coupon_count",
        headerName: "# NRx with PA Required, Coupon Applied",
        hide: true,
      },
      {
        field: "dispensed_nrx_fast_prior_auth_to_coupon_count",
        headerName:
          "# NRx with PA Required, Coupon Applied within 10 Mins of Rejection",
        hide: true,
      },
      {
        field: "dispensed_nrx_pa_to_coupon_pct",
        headerName: "% Dispensed NRx w PA Required, Coupon Applied",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        hide: true,
      },
      {
        field: "dispensed_nrx_fast_pa_to_coupon_pct",
        headerName:
          "% Dispensed NRx w PA Required, Coupon Applied within 10 Mins of Rejection",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        hide: true,
      },
      {
        field: "dispensed_refill_prior_auth_count",
        headerName: "# Refills with PA Required",
        hide: true,
      },
      {
        field: "dispensed_refill_prior_auth_to_coupon_count",
        headerName: "# Refills with PA Required, Coupon Applied",
        hide: true,
      },
      {
        field: "dispensed_refill_fast_prior_auth_to_coupon_count",
        headerName:
          "# Refills with PA Required, Coupon Applied within 10 Mins of Rejection",
        hide: true,
      },
      {
        field: "dispensed_refill_pa_to_coupon_pct",
        headerName: "% Dispensed Refills w PA Required, Coupon Applied",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        hide: true,
      },
      {
        field: "dispensed_refill_fast_pa_to_coupon_pct",
        headerName:
          "% Dispensed Refills w PA Required, Coupon Applied within 10 Mins of Rejection",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        hide: true,
      },
      { field: "fill_opportunities", headerName: "Fill Opportunities" },
      { field: "unattempted_fills", headerName: "Unattempted Fills" },
      {
        field: "attempted_pct_of_opp",
        headerName: "% Attempted of Opportunity",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      { field: "attempted_fills", headerName: "Attempted Fills" },
      { field: "new_rxs_attempted", headerName: "New Rxs with Attempted Fill" },
      {
        field: "percent_new_of_attempted",
        hide: true,
        headerName: "% New Rx / Total Distinct Rxs (among Attempted Fills)",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      { field: "abandoned_fills", hide: true, headerName: "Abandoned Fills" },
      {
        field: "transferred_fills",
        hide: true,
        headerName: "Transferred Fills",
      },
      {
        field: "dispensed_fills_w_resolved_pbm_rejection",
        headerName: "Dispensed Fills with Resolved PBM Rejections",
      },
      {
        field: "dispensed_pbm_resolution_pct",
        headerName: "% Dispensed with Resolved PBM Rejection",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        hide: true,
      },
      {
        field: "dispensed_pct_of_opp",
        headerName: "% Dispensed of Opportunity",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      { field: "unique_patients", headerName: "Unique Patients" },
      {
        field: "fills_w_resolved_pbm_rejection",
        headerName: "Fills with Resolved PBM Rejections",
      },
    ],
  },
];
