import { notification } from "antd";
import {tryWriteClipboard} from "./clipboard";

export function copyContent(txt?: string, label?: string) {
  if (!txt) return;

  tryWriteClipboard(txt);
  notification.open({
    message: `${label ?? txt} copied`,
    duration: 5,
    placement: "bottomLeft",
  });
}
