import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  manufacturerReportCurrencyFormatter,
  manufacturerReportPercentFormatter,
} from "../../utils/manufacturerReportFormatters";
import { getDetailCellRendererParams } from "../../utils/getDetailCellRendererParams";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  { field: "Dispensed Drug Name", rowGroup: true, hide: true },
  { field: "Dispensed Quantity", rowGroup: true, hide: true },
  { field: "Dispense Status", rowGroup: true, hide: true },
  { field: "Primary Plan Type", rowGroup: true, hide: true },
  { field: "Primary Payer Name", rowGroup: true, hide: true },
  { field: "Primary BIN", rowGroup: true, hide: true },
  { field: "Primary PCN", rowGroup: true, hide: true },
  { field: "Primary Group ID", rowGroup: true, hide: true },
  { field: "Claim Stream Detail", cellRenderer: "agGroupCellRenderer" },
  { field: "Fill Count", aggFunc: "sum", hide: true },
  {
    headerName:
      "Unapplied Claim Info - Third-Party Primary Payer Data for Abandoned & Transferred Fills",
    children: [
      {
        field: "Third Party Contribution for Abandoned & Transferred",
        headerName: "3rd-Party Contribution",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Covered w/ >$0 Contribution for Abandoned & Transferred",
        headerName: "3rd-Party Contribution when >$0",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
        headerName: "Coverage Rate w/ Contribution > $0",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
      },
      {
        field: "Patient Pay - Before Coupon for Abandoned & Transferred",
        headerName: "Patient Pay Before Coupon, for Covered Fills",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Pharmacy Gross Revenue for Abandoned and Transferred",
        headerName: "Pharmacy Gross Revenue",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Coupon Use",
    children: [
      { field: "Fills w/ Attempted Coupon", aggFunc: "sum", hide: true },
      { field: "Fills w/ Approved Coupon", aggFunc: "sum", hide: true },
      { field: "Fills w/ Used Coupon", aggFunc: "sum", hide: true },
    ],
  },
  {
    headerName: "Prescription",
    children: [
      { field: "Rx Number", hide: true },
      { field: "Refill Number", hide: true },
      { field: "Total Refills Prescribed", hide: true },
      { field: "Refills Remaining", hide: true },
      { field: "Days Supply", hide: true },
      { field: "Refill Due Date", hide: true },
      { field: "Rx Written Date", hide: true },
      { field: "Prescribed Drug Name", hide: true },
      { field: "Prescribed NDC", hide: true },
      { field: "DAW Code", hide: true },
      { field: "Sale Date", hide: true },
      { field: "Fill Adjudication Date", hide: true },
    ],
  },
  {
    headerName: "Dispense Info",
    children: [
      { field: "Dispense Status", hide: true },
      { field: "Dispensed Quantity", hide: true },
      { field: "Last OCC", headerName: "OCC", hide: true },
      { field: "Auto Refill On", hide: true },
      {
        field: "fill_has_prior_auth_required",
        headerName: "Rejected due to PA Required",
        hide: true,
      },
      {
        field: "prior_auth_to_coupon",
        headerName: "Rejected due to PA Required; Coupon Applied",
        hide: true,
      },
      {
        field: "fast_prior_auth_to_coupon",
        headerName:
          "Rejected due to PA Required; Coupon Applied within 10 mins of Rejection",
        hide: true,
      },
      {
        field: "was_pbm_rejection_resolved",
        headerName: "Was PBM Rejection Resolved",
        hide: true,
      },
      {
        field: "third_party_primary_payer_pbm_rejection_values",
        headerName: "3rd-Party Primary Payer PBM Rejections",
        hide: true,
        tooltipField: "third_party_primary_payer_pbm_rejection_values",
      },
      { field: "Dispensed NDC", hide: true },
      { field: "Dispensed Same as Prescribed Drug", hide: true },
    ],
  },
  {
    headerName: "Prescriber",
    children: [
      { field: "Prescriber Name", hide: true },
      { field: "Prescriber NPI", hide: true },
      { field: "Prescriber Street Address", hide: true },
      { field: "Prescriber City", hide: true },
      { field: "Prescriber State", hide: true },
      { field: "Prescriber Zip", hide: true },
    ],
  },
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", hide: true },
      { field: "Pharmacy NPI", hide: true },
    ],
  },
  {
    headerName: "Primary Payer",
    children: [
      { field: "Primary BIN", hide: true },
      { field: "Primary PCN", hide: true },
      { field: "Primary Group ID", hide: true },
      { field: "Primary Plan Type", hide: true },
      { field: "Primary Payer Processor", hide: true },
      { field: "Primary Payer Name", hide: true },
      { field: "Primary Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Primary Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Primary Payer Plan ID", hide: true },
      { field: "Primary Payer Network Reimbursement ID", hide: true },
    ],
  },
  {
    headerName: "Secondary Payer",
    children: [
      { field: "Secondary BIN", hide: true },
      { field: "Secondary PCN", hide: true },
      { field: "Secondary Group ID", hide: true },
      { field: "Secondary Plan Type", hide: true },
      { field: "Secondary Payer Processor", hide: true },
      { field: "Secondary Payer Name", hide: true },
      { field: "Secondary Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Secondary Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Secondary Payer Plan ID", hide: true },
      { field: "Secondary Payer Network Reimbursement ID", hide: true },
    ],
  },
  {
    headerName: "Tertiary Payer",
    children: [
      { field: "Tertiary BIN", hide: true },
      { field: "Tertiary PCN", hide: true },
      { field: "Tertiary Group ID", hide: true },
      { field: "Tertiary Plan Type", hide: true },
      { field: "Tertiary Payer Processor", hide: true },
      { field: "Tertiary Payer Name", hide: true },
      { field: "Tertiary Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Tertiary Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Tertiary Payer Plan ID", hide: true },
      { field: "Tertiary Payer Network Reimbursement ID", hide: true },
    ],
  },
  { field: "Fill ID (PharmacyNPI-RxNumber-Refill)", hide: true },
  { field: "Patient ID", hide: true },
];

export const DETAIL_CELL_RENDERER_PARAMS = getDetailCellRendererParams({
  detailGridOptions: {
    groupDisplayType: "multipleColumns",
    columnDefs: [
      { field: "Billed Drug Name" },
      { field: "Billed NDC" },
      { field: "Transmission Time" },
      { field: "Claim Adjudication Date" },
      { field: "Claim Number (By Billing Order)" },
      { field: "Claim Number (All Billing Orders)", sort: "asc" },
      { field: "Was Claim Reversed" },
      { field: "Payer Billing Order" },
      { field: "BIN" },
      { field: "PCN" },
      { field: "Group ID" },
      { field: "Plan Type" },
      { field: "Processor", hide: true },
      { field: "Payer Name" },
      { field: "Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Plan ID", hide: true },
      { field: "Network Reimbursement ID", hide: true },
      { field: "OCC Code", headerName: "OCC (Claim Level)" },
      { field: "Response" },
      { field: "Rejection Codes", tooltipField: "Rejection Codes" },
      { field: "Approval Codes", tooltipField: "Approval Codes" },
      {
        field: "Response Message",
        tooltipField: "Response Message",
      },
      {
        field: "Additional Claim Response Info",
        tooltipField: "Additional Claim Response Info",
      },
      {
        field: "Payer Suggested Alternatives",
        tooltipField: "Payer Suggested Alternatives",
      },
      {
        field: "Patient Pay Amount (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Patient Copay",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Coinsurance Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Deductible Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Coverage Gap Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Other Payer Recognized Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Pharmacy Copay Buydown",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Total Remittance (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Manufacturer Contribution (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Third Party Contribution (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      { field: "Claim ID" },
    ],
  },
});

export const EXPORT_CSV_HEADERS = [
  "Dispensed Drug Name",
  "Dispense Status",
  "Dispensed Quantity",
  "Dispensed NDC",
  "Dispensed Same as Prescribed Drug",
  "Fill Adjudication Date",
  "OCC",
  "Rx Number",
  "Refill Number",
  "Rx Written Date",
  "Prescribed Drug Name",
  "Prescribed NDC",
  "DAW Code",
  "Covered by Third-Party Payer",
  "Prescriber Name",
  "Prescriber NPI",
  "Pharmacy Name",
  "Pharmacy NPI",
  "Primary BIN",
  "Primary PCN",
  "Primary Group ID",
  "Primary Plan Type (Pharmacy-Defined)",
  "Primary Payer Name (Pharmacy-Defined)",
  "Secondary BIN",
  "Secondary PCN",
  "Secondary Group ID",
  "Secondary Plan Type (Pharmacy-Defined)",
  "Secondary Payer Name (Pharmacy-Defined)",
  "Tertiary BIN",
  "Tertiary PCN",
  "Tertiary Group ID",
  "Tertiary Plan Type (Pharmacy-Defined)",
  "Tertiary Payer Name (Pharmacy-Defined)",
  "Fill Count",
  "3rd-Party Contribution when >$0 for Dispensed & In Progress",
  "Coverage Rate w/ Contribution > $0 for Dispensed & In Progress",
  "3rd-Party Contribution for Dispensed & In Progress",
  "Manuf. Contribution for Dispensed & In Progress",
  "Patient Pay Before Coupon, Dispensed & In Progress, Covered",
  "Patient Pay After Coupon for Dispensed & In Progress",
  "Pharmacy Gross Revenue for Dispensed & In Progress",
  "Acq Cost for Dispensed and In Progress",
  "Pharmacy Profit for Dispensed and In Progress",
  "3rd-Party Contribution when >$0 for Abandoned & Transferred",
  "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
  "3rd-Party Contribution for Abandoned & Transferred",
  "Patient Pay Before Coupon, Abandoned & Transferred, Covered",
  "Pharmacy Gross Revenue for Abandoned & Transferred",
  "Fills w/ Attempted Coupon",
  "Fills w/ Approved Coupon",
  "Fills w/ Used Coupon",
  "Rx ID",
  "Rx Transaction ID",
  "Patient ID",
  "Fill ID (PharmacyNPI-RxNumber-Refill)",
];

export const EXPORT_ALL_CSV_HEADERS = [
  "Dispensed Drug Name",
  "Dispense Status",
  "Dispensed Quantity",
  "Dispensed NDC",
  "Dispensed Same as Prescribed Drug",
  "Fill Adjudication Date",
  "OCC",
  "Rx Number",
  "Refill Number",
  "Rx Written Date",
  "Prescribed Drug Name",
  "Prescribed NDC",
  "DAW Code",
  "Covered by Third-Party Payer",
  "Prescriber Name",
  "Prescriber NPI",
  "Pharmacy Name",
  "Pharmacy NPI",
  "Primary BIN",
  "Primary PCN",
  "Primary Group ID",
  "Primary Plan Type (Pharmacy-Defined)",
  "Primary Payer Name (Pharmacy-Defined)",
  "Secondary BIN",
  "Secondary PCN",
  "Secondary Group ID",
  "Secondary Plan Type (Pharmacy-Defined)",
  "Secondary Payer Name (Pharmacy-Defined)",
  "Tertiary BIN",
  "Tertiary PCN",
  "Tertiary Group ID",
  "Tertiary Plan Type (Pharmacy-Defined)",
  "Tertiary Payer Name (Pharmacy-Defined)",
  "Fill Count",
  "3rd-Party Contribution when >$0 for Dispensed & In Progress",
  "Coverage Rate w/ Contribution > $0 for Dispensed & In Progress",
  "3rd-Party Contribution for Dispensed & In Progress",
  "Manuf. Contribution for Dispensed & In Progress",
  "Patient Pay Before Coupon, Dispensed & In Progress, Covered",
  "Patient Pay After Coupon for Dispensed & In Progress",
  "Pharmacy Gross Revenue for Dispensed & In Progress",
  "Acq Cost for Dispensed and In Progress",
  "Pharmacy Profit for Dispensed and In Progress",
  "3rd-Party Contribution when >$0 for Abandoned & Transferred",
  "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
  "3rd-Party Contribution for Abandoned & Transferred",
  "Patient Pay Before Coupon, Abandoned & Transferred, Covered",
  "Pharmacy Gross Revenue for Abandoned & Transferred",
  "Fills w/ Attempted Coupon",
  "Fills w/ Approved Coupon",
  "Fills w/ Used Coupon",
  "Billed Drug Name",
  "Billed NDC",
  "Transmission Time",
  "Claim Adjudication Date",
  "Claim Number (By Billing Order)",
  "Claim Number (All Billing Orders)",
  "Was Claim Reversed",
  "Payer Billing Order",
  "OCC Code",
  "Response",
  "Rejection Codes",
  "Approval Codes",
  "Additional Claim Response Info",
  "Payer Suggested Alternatives",
  "Patient Pay Amount (Claim Level)",
  "Patient Copay",
  "Coinsurance Amount",
  "Deductible Amount",
  "Pharmacy Copay Buydown",
  "Total Remittance (Claim Level)",
  "Manufacturer Contribution (Claim Level)",
  "Third Party Contribution (Claim Level)",
  "Rx ID",
  "Rx Transaction ID",
  "Patient ID",
  "Fill ID (PharmacyNPI-RxNumber-Refill)",
  "Claim ID",
];
