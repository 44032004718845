import { useCallback, useMemo, useState } from "react";
import type { Dayjs } from "dayjs";
import type { ItemInCart } from "../../../../utilities/types";
import { useShoppingUpdater } from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { normalizePackQuantity } from "../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { Box } from "../../../../components/rxLibrary/box";
import { QtySelect } from "../../../../components/rxLibrary/selects/QtySelect";
import { usePrescriptionNote } from "../../../../components/PrescriptionNote/usePrescriptionNote";
import { ShoppingRxItemPanel } from "./components/ShoppingRxItemPanel/ShoppingRxItemPanel";
import { ShoppingRxItemHeader } from "./components/ShoppingRxItemHeader/ShoppingRxItemHeader";
import { ShoppingRxItem } from "./components/ShoppingRxItem";

function InventoryQtySelect({ item }: { item: ItemInCart }) {
  const { updatePrescriptionPackQuantity } = useShoppingUpdater();
  const directDeal = item.directDeals?.[0];

  const quantitiesConfig = useMemo(() => {
    return {
      bundleSize: directDeal?.bundleSize,
      directDealLabel: true,
      includeZero: true,
      zeroLabel: "Delete",
    };
  }, [directDeal]);

  const packQuantity = useMemo(() => {
    return normalizePackQuantity(item.packQuantity);
  }, [item.packQuantity]);

  const updatePackQuantity = useCallback(
    (value: number) => {
      updatePrescriptionPackQuantity(item, value);
    },
    [item, updatePrescriptionPackQuantity]
  );

  return (
    <QtySelect
      tw="h-[60px]"
      value={packQuantity}
      quantitiesConfig={quantitiesConfig}
      onChange={updatePackQuantity}
    />
  );
}

export function ShoppingRxInventoryItem({ item }: { item: ItemInCart }) {
  const {
    removePrescription,
    updatePrescriptionNote,
    updatePrescriptionPackSize,
    updatePrescriptionPurchaseBy,
    updatePrescriptionManufacturer,
  } = useShoppingUpdater();

  const {
    hasPrescriptionNote: hasItemNote,
    prescriptionNotePopover: itemNotePopover,
    openPrescriptionNotePopover: openItemNotePopover,
  } = usePrescriptionNote({
    prescription: item,
    updatePrescriptionNote,
  });

  const [isRemoving, setIsRemoving] = useState(false);
  const id = useMemo(() => getPrescriptionId(item), [item]);

  const updateItemPackSize = useCallback(
    (value: boolean) => {
      updatePrescriptionPackSize(item, value);
    },
    [item]
  );

  const updateItemManufacturer = useCallback(
    (value: boolean) => {
      updatePrescriptionManufacturer(item, value);
    },
    [item, updatePrescriptionManufacturer]
  );

  const removeItem = useCallback(() => {
    setIsRemoving(true);
    setTimeout(() => removePrescription(item), 500);
  }, [item, removePrescription]);

  const updateItemPurchaseBy = useCallback(
    (value?: Dayjs) => {
      updatePrescriptionPurchaseBy(item, value);
    },
    [item, updatePrescriptionPurchaseBy]
  );

  return (
    <ShoppingRxItem isInventory id={id} isRemoving={isRemoving}>
      <ShoppingRxItemHeader
        isInCart
        isInventory
        item={item}
        hasItemNote={hasItemNote}
        removeItem={removeItem}
        openItemNotePopover={openItemNotePopover}
        updateItemPurchaseBy={updateItemPurchaseBy}
      />

      <ShoppingRxItemPanel
        isInventory
        item={item}
        itemNotePopover={itemNotePopover}
        updateItemPackSize={updateItemPackSize}
        updateItemManufacturer={updateItemManufacturer}
      >
        <Box tw="flex justify-center border-r w-[134px]" borderColor="grey-2">
          <InventoryQtySelect item={item} />
        </Box>
      </ShoppingRxItemPanel>
    </ShoppingRxItem>
  );
}
