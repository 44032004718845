import React, { memo, useMemo } from "react";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { PmsEnum } from "../../../../utilities/pms";
import { getPrescriptionId } from "../../../../utilities/prescriptions/getPrescriptionId";
import { IconPlus } from "../../../../components/rxLibrary/icons";
import { Text } from "../../../../components/rxLibrary/typography";
import { Button } from "../../../../components/rxLibrary/buttons";
import { Col, Row } from "../../../../components/rxLibrary/grid";
import { ShoppingListColumnPackageInfo } from "../../../../components/shopping/table/columns/ShoppingListColumnPackageInfo";
import { useModal } from "../../../../components/rxLibrary/modals";
import { InventoryModal } from "../../../../components/shopping/InventoryModal/InventoryModal";
import { FillDateCol } from "../../../../components/shopping/table/columns/FillDateCol";
import { ShoppingListColumnDispensedItem } from "../shoppingListTable/columns/ShoppingListColumnDispensedItem";
import { ShoppingListTableHeaderItem } from "../shoppingListTable/ShoppingListTableHeaderItem";
import { ShoppingListTableWrapper } from "../shoppingListTable/ShoppingListTableWrapper";
import { ShoppingListTableOptimizedSwitchFilter } from "../shoppingListTable/ShoppingListTableOptimizedSwitchFilter";
import { ShoppingListColumnActions } from "../shoppingListTable/columns/ShoppingListColumnActions/ShoppingListColumnActions";
import {
  SHOPPING_LIST_TABLE_NAV_BAR_STICKY_HEIGHT,
  ShoppingListTableNavBarSticky,
} from "../shoppingListTable/ShoppingListTableNavBarSticky";
import { ShoppingListColumnDispensedQuantity } from "../shoppingListTable/columns/ShoppingListColumnDispensedQuantity";
import { ShoppingListColumnPrescriptionSupplierNames } from "../shoppingListTable/columns/ShoppingListColumnPrescriptionSupplierNames";
import { ShoppingListTableHeaderSticky } from "../shoppingListTable/ShoppingListTableHeaderSticky";
import { ShoppingListCol } from "../shoppingListTable/columns/ShoppingListCol";
import { ShoppingListRow } from "../shoppingListTable/ShoppingListRow";
import { ItemInCartWithVisibility } from "../useShoppingListItems/useShoppingListItems.constants";
import { useDuplicatedDrugFamilyPopover } from "../shoppingListTable/duplicatedDrugFamily/useDuplicatedDrugFamilyPopover";
import { SubstitutionsCol } from "../shoppingListTable/columns/SubstitutionsCol/SubstitutionsCol";
import { ShoppingListTableInventoryHeader } from "./ShoppingListTableInventoryHeader";
import { PackQuantityDropdown } from "./PackQuantityDropdown";

const OFFSET_TOP_EMPTY_CART = 96;
const OFFSET_TOP_WITH_CART_ITEMS = 140;

function ShoppingListTableInventoryComponent({
  loading,
  hasCartItemsFilters,
  hideOptimizedInventoryItems,
  inventoryItemsWithVisibility,
  setHideOptimizedInventoryItems,
}: {
  loading: boolean;
  hasCartItemsFilters: boolean;
  inventoryItemsWithVisibility: ItemInCartWithVisibility[];
  hideOptimizedInventoryItems: boolean;
  setHideOptimizedInventoryItems: (isHide: boolean) => void;
}) {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();
  const { pms, keepsInventory: pharmacyKeepsInventory } = pharmacy ?? {};
  const isLiberty = pms === PmsEnum.Liberty;

  const offsetTopNavBar = hasCartItemsFilters
    ? OFFSET_TOP_WITH_CART_ITEMS
    : OFFSET_TOP_EMPTY_CART;
  const inventoryItemsInCartCount = inventoryItemsWithVisibility.length;

  const offsetTopTableHeader = useMemo(() => {
    return offsetTopNavBar + SHOPPING_LIST_TABLE_NAV_BAR_STICKY_HEIGHT;
  }, [offsetTopNavBar]);

  const visibleInventoryItems = useMemo(() => {
    return inventoryItemsWithVisibility.filter((item) => item.visible);
  }, [inventoryItemsWithVisibility]);

  const visibleInventoryItemsCount = visibleInventoryItems.length;

  const {
    isModalOpen: isInventoryModalOpen,
    openModal: openInventoryModal,
    closeModal: closeInventoryModal,
  } = useModal();

  const {
    renderDuplicatedDrugFamilyPopover,
    updatePrescriptionStatusWithDrugFamilyValidation,
  } = useDuplicatedDrugFamilyPopover();

  return (
    <>
      <ShoppingListTableNavBarSticky offsetTop={offsetTopNavBar}>
        <ShoppingListTableHeaderItem>
          <ShoppingListTableInventoryHeader
            inventoryItemsInCartCount={inventoryItemsInCartCount}
            visibleInventoryItemsCount={visibleInventoryItemsCount}
          />

          <Button
            icon={<IconPlus />}
            variant="secondary"
            onClick={openInventoryModal}
          >
            Add Items
          </Button>
        </ShoppingListTableHeaderItem>

        <ShoppingListTableHeaderItem>
          {!loading && (
            <ShoppingListTableOptimizedSwitchFilter
              items={inventoryItemsWithVisibility}
              hideOptimizedItems={hideOptimizedInventoryItems}
              setHideOptimizedItems={setHideOptimizedInventoryItems}
            />
          )}
        </ShoppingListTableHeaderItem>
      </ShoppingListTableNavBarSticky>

      <ShoppingListTableWrapper
        loading={loading}
        itemsCount={inventoryItemsInCartCount}
        visibleItemsCount={visibleInventoryItemsCount}
        emptyText="There are currently no inventory items, Use the '+ Add' button above to build your list."
      >
        <ShoppingListTableHeaderSticky offsetTop={offsetTopTableHeader}>
          <Row header>
            <Col tw="w-[115px]">
              <Text weight="bold">Added</Text>
            </Col>

            <Col tw="flex-1 min-w-[246px]">
              <Text weight="bold">Item</Text>
            </Col>

            <Col tw="flex-1 min-w-[133px]">
              <Text weight="bold">Pack Size / Manufacturer</Text>
            </Col>

            {pharmacyKeepsInventory && (
              <Col tw="flex-1">
                <Text weight="bold">{isLiberty ? "BOH" : "BOH / EOH"}</Text>
              </Col>
            )}

            <Col tw="w-[170px]">
              <Text weight="bold">Packs</Text>
            </Col>
            <Col tw="w-[133px]">
              <Text weight="bold">Allow Substitutions</Text>
            </Col>

            <Col tw="w-[167px]" />
          </Row>
        </ShoppingListTableHeaderSticky>

        {visibleInventoryItems.map((item) => {
          const { prescription, filtered, visible } = item;
          const id = getPrescriptionId(prescription);
          const { drug, noManufacturerPreference } = prescription;

          return (
            <ShoppingListRow
              key={id}
              filtered={filtered}
              visible={visible}
              prescriptionId={id}
              renderWrapper={renderDuplicatedDrugFamilyPopover}
            >
              <ShoppingListCol tw="w-[115px]">
                <FillDateCol prescription={prescription} />
              </ShoppingListCol>

              <ShoppingListCol tw="flex-1 min-w-[246px]">
                <ShoppingListColumnDispensedItem
                  drug={drug}
                  displayNdc={!noManufacturerPreference}
                />
              </ShoppingListCol>

              <ShoppingListCol tw="flex-1 min-w-[133px]">
                <ShoppingListColumnPackageInfo
                  drug={drug}
                  noManufacturerPreference={noManufacturerPreference}
                />
              </ShoppingListCol>

              {pharmacyKeepsInventory && (
                <ShoppingListCol tw="flex-1">
                  <ShoppingListColumnDispensedQuantity
                    prescription={prescription}
                    hideDispensedQuantity
                  />
                </ShoppingListCol>
              )}

              <ShoppingListCol tw="w-[170px]">
                <PackQuantityDropdown prescription={prescription} />
              </ShoppingListCol>

              <ShoppingListCol tw="w-[133px]">
                <SubstitutionsCol prescription={prescription} />
              </ShoppingListCol>

              <ShoppingListCol tw="w-[167px]">
                <ShoppingListColumnActions
                  prescription={prescription}
                  updatePrescriptionStatus={
                    updatePrescriptionStatusWithDrugFamilyValidation
                  }
                />
                <ShoppingListColumnPrescriptionSupplierNames
                  prescription={prescription}
                />
              </ShoppingListCol>
            </ShoppingListRow>
          );
        })}
      </ShoppingListTableWrapper>

      {isInventoryModalOpen && <InventoryModal close={closeInventoryModal} />}
    </>
  );
}

export const ShoppingListTableInventory = memo(
  ShoppingListTableInventoryComponent
);
