import "twin.macro";
import { ErrorMessage } from "./ErrorMessage";

export function UnexpectedError({ className }: { className?: string}) {
  return (
    <ErrorMessage className={className}>
      Uh oh. This is us, not you! Please use the orange chat button below to let
      us know you are having trouble. Or{" "}
      <a href="/" tw="text-blue-600">
        click here
      </a>{" "}
      to go to the homepage.
    </ErrorMessage>
  );
}
