import React, { useMemo } from "react";
import "twin.macro";
import { DrugInfo, CatalogInfo } from "../../../../utilities/types";
import { getDrugMeasureDisplay } from "../../../../utilities/drugInfo/getDrugMeasureDisplay";
import { Text } from "../../../rxLibrary/typography";
import { currencyWithFractionOfPenny } from "../../../../utilities/numbers/currency";

export function DrugPriceCol({
  drug,
  className,
  catalogInfo,
}: {
  drug: DrugInfo;
  catalogInfo: CatalogInfo;
  className?: string;
}) {
  const { unitSize, unitQuantity } = drug;
  const { price } = catalogInfo;

  const displayPrice = useMemo(() => {
    const costPerDose = price / (unitSize * unitQuantity);
    return currencyWithFractionOfPenny(costPerDose);
  }, [price, unitSize, unitQuantity]);

  const drugMeasureDisplay = useMemo(() => {
    return getDrugMeasureDisplay(drug);
  }, [drug]);

  return (
    <div className={className} tw="w-[80px] border-r px-[12px]">
      <Text block weight="bold" size="large">
        {displayPrice}
      </Text>
      <Text block>Per {drugMeasureDisplay}</Text>
    </div>
  );
}
