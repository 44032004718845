import React from "react";
import "twin.macro";
import type { SearchResult } from "../../utilities/types";
import { Container } from "../containers/Container";
import { DrugSearchResults } from "./DrugSearchResults/DrugSearchResults";
import { IHandleDrugClick, IDrugSearchValues } from "./DrugSearch.constants";
import { DrugSearchBar } from "./DrugSearchBar";
import { useFormattedDrugSearch } from "./useFormattedDrugSearch/useFormattedDrugSearch";
import { IDrugSearchRequestState } from "./useDrugSearch/useDrugSearch.constants";

export type {
  IHandleSearchResults,
  IHandleDrugClick,
  IHandleSearchNdc,
} from "./DrugSearch.constants";

export {
  useDrugSearch,
  useUpdateDrugSearchValues,
} from "./useDrugSearch/useDrugSearch";

export function DrugSearch({
  drugSearchValues,
  drugSearchResults,
  drugSearchResultsState,
  handleDrugClick,
}: {
  drugSearchValues: IDrugSearchValues;
  drugSearchResults: SearchResult[];
  drugSearchResultsState: IDrugSearchRequestState;
  handleDrugClick: IHandleDrugClick;
}) {
  const { drugSearchFilters, drugTableData } = useFormattedDrugSearch({
    drugSearchResults,
    drugSearchValues,
  });

  return (
    <>
      <DrugSearchBar
        drugSearchFilters={drugSearchFilters}
        drugSearchValues={drugSearchValues}
      />
      <Container tw="pt-[40px]">
        <DrugSearchResults
          searchResults={drugSearchResults}
          drugSearchResultsState={drugSearchResultsState}
          drugSearchTableData={drugTableData}
          searchValue={drugSearchValues.search}
          brandValue={drugSearchValues.brand === "g" ? "generics" : "brands"}
          handleDrugClick={handleDrugClick}
        />
      </Container>
    </>
  );
}
