import React, { FC, RefObject, useContext, useMemo } from "react";
import "twin.macro";
import { AgGridReact } from "ag-grid-react";
import { SideBarDef } from "ag-grid-community";
import AppContext from "../../../../../../contexts/AppContext";
import { DEFAULT_AG_GRID_SIDEBAR } from "../reportsConstants";
import { Button } from "../../../../../../components/rxLibrary/buttons";

export type UseReportSideBarParams = {
  gridRef: RefObject<AgGridReact>;
  sideBarDef?: SideBarDef;
  ExportToolPanel?: FC<{
    handleExport: () => void;
    exportToolPanelProps: any;
  }>;
  exportToolPanelProps?: any;
};

function DefaultExportToolPanel({
  handleExport,
}: {
  handleExport: () => void;
}) {
  return (
    <Button block fullWidth variant="secondary" onClick={handleExport}>
      Download CSV
    </Button>
  );
}

export function useReportSideBar({
  gridRef,
  sideBarDef = DEFAULT_AG_GRID_SIDEBAR,
  ExportToolPanel = DefaultExportToolPanel,
  exportToolPanelProps = {},
}: UseReportSideBarParams) {
  const { user } = useContext(AppContext);
  const isTrialUser = user?.is_trial;

  const newSideBarDef: SideBarDef = useMemo(() => {
    if (isTrialUser) return sideBarDef;
    return {
      ...sideBarDef,
      toolPanels: [
        ...(sideBarDef.toolPanels ?? []),
        {
          id: "export",
          labelDefault: "Export",
          labelKey: "export",
          iconKey: "save",
          width: 320,
          toolPanel: function renderExportToolPanel() {
            function handleExport() {
              if (gridRef.current) {
                gridRef.current.api.exportDataAsCsv();
              }
            }

            return (
              <div tw="p-2 flex flex-col space-y-2 mx-auto">
                <ExportToolPanel
                  handleExport={handleExport}
                  exportToolPanelProps={exportToolPanelProps}
                />
              </div>
            );
          },
        },
      ],
    };
  }, [gridRef, sideBarDef, exportToolPanelProps, ExportToolPanel, isTrialUser]);

  return newSideBarDef;
}
