import type { CatalogItemsResponse } from "../../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "../request/requestClient";

export async function getCatalogItems(
  client: RequestClient,
  {
    supplierId,
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    pharmacyId: number;
    supplierId: number;
  }>
) {
  return client<{ catalogItems: CatalogItemsResponse }>({
    ...config,
    url: `/v2/catalog-items`,
    params: { pharmacy_id: pharmacyId, supplier_id: supplierId },
  });
}
