import { useEffect, useState } from "react";
import type { PrescriptionDetail } from "../../../../../utilities/types";
import { cleanUpPurchaseByString } from "../../../../../utilities/prescriptions/purchaseBy/cleanUpPurchaseByString";
import { useShoppingState } from "../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { Unavailable_All } from "../../../../../services/utils";

export type UnavailableItem = Omit<Unavailable_All, "purchaseBy"> & {
  id: string;
  purchaseBy?: string;
  prescriptionDetails: PrescriptionDetail[];
};

export function useUnavailableItems() {
  const [unavailableItems, setUnavailableItems] = useState<UnavailableItem[]>(
    []
  );
  const { optimizeCartResponse, getPrescriptionsWithDetails } =
    useShoppingState();
  const unavailable = optimizeCartResponse?.data?.selections.unavailable;

  useEffect(() => {
    if (!unavailable) {
      setUnavailableItems([]);
      return;
    }

    const newUnavailableItems = unavailable.map<UnavailableItem>((item) => {
      const { rxNumbers, purchaseBy } = item;
      const id = rxNumbers.join("_");
      const newPurchaseBy = cleanUpPurchaseByString(
        purchaseBy ? purchaseBy : undefined
      );
      const prescriptionDetails = getPrescriptionsWithDetails(item.rxNumbers);

      return { ...item, id, prescriptionDetails, purchaseBy: newPurchaseBy };
    });

    setUnavailableItems(newUnavailableItems);
  }, [unavailable, getPrescriptionsWithDetails]);

  return unavailableItems;
}
