import React, { useMemo } from "react";
import "twin.macro";
import { Form } from "antd";
import dayjs from "dayjs";
import isString from "lodash/isString";
import isNil from "lodash/isNil";
import { FormCategory } from "../../../../../../../components/antd/form/FormCategory";
import { ShippingConfig } from "../../../../../../../utilities/types";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../../../utilities/numbers/currency";

function Currency({ value }: { value?: number | null }) {
  const currency = useMemo(() => {
    if (isNil(value)) return "None";
    return currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(value);
  }, [value]);

  return <Text block>{currency}</Text>;
}

export function DefaultShippingConfig({
  timezoneWarning,
  defaultShippingConfig,
}: {
  timezoneWarning?: string;
  defaultShippingConfig?: ShippingConfig;
}) {
  return (
    <FormCategory title="Default Supplier Shipping Config">
      <Form.Item label="Default Shipping Fee">
        <Currency value={defaultShippingConfig?.fee} />
      </Form.Item>
      <Form.Item label="Default Shipping Min Order">
        <Currency value={defaultShippingConfig?.minOrder} />
      </Form.Item>
      <Form.Item label="Default Shipping Min Generic Order">
        <Currency value={defaultShippingConfig?.minGenericOrder} />
      </Form.Item>
      <Form.Item label="Default Shipping Min Brand Rx Order">
        <Currency value={defaultShippingConfig?.minBrandRxOrder} />
      </Form.Item>
      <Form.Item label="Default Shipping Min Generic Rx Order">
        <Currency value={defaultShippingConfig?.minGenericRxOrder} />
      </Form.Item>
      <Form.Item label="Default Shipping Cutoff Times">
        {defaultShippingConfig?.cutoffTimes && (
          <>
            {!!timezoneWarning && (
              <Text tw="mb-2" color="yellow-1" block>
                {timezoneWarning}
              </Text>
            )}

            {isString(defaultShippingConfig?.cutoffTimes) && (
              <Text block>{defaultShippingConfig?.cutoffTimes}</Text>
            )}

            {Array.isArray(defaultShippingConfig?.cutoffTimes) && (
              <div tw="grid grid-cols-2 gap-x-8">
                {defaultShippingConfig?.cutoffTimes.map(
                  (cutoffTimes, index) => (
                    <div key={index} tw="flex">
                      <Text weight="bold" tw="w-[80px]">
                        {dayjs().day(index).format("dddd")}
                      </Text>

                      <div tw="flex space-x-6">
                        {cutoffTimes?.map((time, index) => (
                          <Text key={index}>{time}</Text>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
          </>
        )}
      </Form.Item>
    </FormCategory>
  );
}
