import React from "react";
import "twin.macro";
import { Form, Tooltip } from "antd";
import { IconButton } from "../../../../../components/rxLibrary/buttons";
import { IconEye } from "../../../../../components/rxLibrary/icons";

export function PharmacyLabel() {
  const form = Form.useFormInstance();
  const pharmacyId = Form.useWatch("pharmacyId", form);

  if (!pharmacyId) return <>Pharmacy</>;
  return (
    <div tw="flex space-x-1 items-center">
      <span>Pharmacy</span>

      <div>
        <Tooltip title="Open pharmacy page in a new tab">
          <a
            href={`/admin/pharmacy/${pharmacyId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton>
              <IconEye />
            </IconButton>
          </a>
        </Tooltip>
      </div>
    </div>
  );
}
