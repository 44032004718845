import { useCallback, useMemo } from "react";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../../../components/rxLibrary/popover";
import { Dropdown } from "../../../../../../../components/rxLibrary/dropdown";
import {
  Button,
  IconButton,
} from "../../../../../../../components/rxLibrary/buttons";
import { Box } from "../../../../../../../components/rxLibrary/box";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import {
  IconNote,
  Icon3Dots,
  IconTrash,
} from "../../../../../../../components/rxLibrary/icons";

enum MenuKeyEnum {
  BuyByDate = "buyByDate",
  AddNote = "addNote",
  Remove = "remove",
}

function RemoveItemPopover({
  close,
  onRemoveItem,
}: {
  close: () => void;
  onRemoveItem: () => void;
}) {
  return (
    <div tw="flex items-center justify-center w-[300px] py-[47px]">
      <div>
        <Text weight="bold" size="medium" center block>
          Remove Item?
        </Text>
        <Box bgColor="grey-2" tw="mx-auto h-[1px] w-[136px] my-[10px]" />
        <div tw="flex items-center space-x-2">
          <Button variant="text-1" onClick={close}>
            Cancel
          </Button>
          <Button variant="tertiary" onClick={onRemoveItem}>
            Remove
          </Button>
        </div>
      </div>
    </div>
  );
}

export function ShoppingItemHeaderActionsMenu({
  hasItemNote,
  removeItem,
  openItemNotePopover,
  openByByDateDatePicker,
}: {
  hasItemNote?: boolean;
  removeItem: () => void;
  openItemNotePopover?: () => void;
  openByByDateDatePicker?: () => void;
}) {
  const {
    isPopoverOpen: isRemovePopoverOpen,
    openPopover: openRemovePopover,
    closePopover: closeRemovePopover,
    handleOpenPopoverChange: handleOpenRemovePopoverChange,
  } = useIsPopoverOpen();

  const hasNoteEnabled = !!openItemNotePopover;
  const hasBuyByDateEnabled = !!openByByDateDatePicker;
  const items = useMemo(() => {
    return [
      {
        key: MenuKeyEnum.BuyByDate,
        label: "Buy at a later date",
        disabled: !hasBuyByDateEnabled,
      },
      {
        key: MenuKeyEnum.AddNote,
        label: hasItemNote ? "Edit a note" : "Add a note",
        icon: <IconNote tw="opacity-65" />,
        disabled: !hasNoteEnabled,
      },
      {
        key: MenuKeyEnum.Remove,
        label: "Remove item",
        icon: <IconTrash tw="opacity-65" />,
      },
    ].filter(({ disabled }) => disabled !== true);
  }, [hasItemNote, hasNoteEnabled, hasBuyByDateEnabled]);

  const onClick = useCallback(
    ({ key }: { key: string }) => {
      switch (key) {
        case MenuKeyEnum.BuyByDate:
          openByByDateDatePicker?.();
          return;
        case MenuKeyEnum.AddNote:
          openItemNotePopover?.();
          return;
        case MenuKeyEnum.Remove:
          openRemovePopover();
          return;
      }
    },
    [openRemovePopover, openByByDateDatePicker, openItemNotePopover]
  );

  return (
    <Popover
      open={isRemovePopoverOpen}
      content={() => (
        <RemoveItemPopover
          close={closeRemovePopover}
          onRemoveItem={removeItem}
        />
      )}
      onOpenChange={handleOpenRemovePopoverChange}
      trigger="contextMenu"
      destroyTooltipOnHide
    >
      <Dropdown menu={{ items, onClick }}>
        <IconButton>
          <Icon3Dots />
        </IconButton>
      </Dropdown>
    </Popover>
  );
}
