import { useEffect, useState } from "react";
import "twin.macro";
import type {
  CatalogItemsSearchBarItem,
  ManufacturerStoreItem,
} from "../../Catalog.constants";
import { formatDrugNameWithDetails } from "../../../../../../utilities/drugInfo/formatDrugNameWithDetails";
import { stringToStringNumbersOrUndefined } from "../../../../../../utilities/numbers/stringToStringNumbersOrUndefined";
import { formatNdc } from "../../../../../../utilities/ndc/formatNdc";

type CatalogItemsSearchBarFilterObj = {
  filter: string;
  numericFilter?: string;
};

function formatSearchBarFilter(value: string) {
  const filter = value.trim();
  if (filter.length < 1) return;

  const numericFilter = stringToStringNumbersOrUndefined(filter, {
    removeSeparators: true,
  });
  const searchBarFilter: CatalogItemsSearchBarFilterObj = {
    filter: filter.toLowerCase(),
    numericFilter,
  };
  return searchBarFilter;
}

function shouldFilterSearchBarItem(
  item: CatalogItemsSearchBarItem,
  searchBarFilterObj: CatalogItemsSearchBarFilterObj
) {
  const { drugName, ndc } = item;
  const { filter, numericFilter } = searchBarFilterObj;

  if (numericFilter !== undefined) {
    if (!!ndc && ndc.includes(numericFilter)) return true;
  }
  if (drugName.toLowerCase().includes(filter)) return true;

  return false;
}

export function useCatalogItemsSearchBar(
  shoppingListItems: ManufacturerStoreItem[]
) {
  const [filter, setFilter] = useState("");
  const [allItems, setAllItems] = useState<CatalogItemsSearchBarItem[]>();
  const [searchBarItems, setSearchBarItems] =
    useState<CatalogItemsSearchBarItem[]>();
  const [isSearchBarOpen, setIsSearchBarOpen] = useState(false);

  useEffect(() => {
    const newSearchBarItems = shoppingListItems.map<CatalogItemsSearchBarItem>(
      (item) => {
        const { drug, ndc } = item;
        const { imageURI, manufacturer } = drug;
        const drugName =
          formatDrugNameWithDetails(drug) +
          ` (${formatNdc(ndc)}) | ${manufacturer}`;
        return { item, ndc, drugName, imageURI };
      }
    );
    setAllItems(newSearchBarItems);
  }, [shoppingListItems]);

  useEffect(() => {
    if (!allItems) return;

    const searchBarFilter = formatSearchBarFilter(filter);
    if (!searchBarFilter) {
      setSearchBarItems(undefined);
      return;
    }

    const filteredItems = allItems.filter((item) => {
      return shouldFilterSearchBarItem(item, searchBarFilter);
    });

    const newSearchBarItems = filteredItems.length ? filteredItems : undefined;
    setSearchBarItems(newSearchBarItems);
  }, [filter, allItems]);

  return {
    filter,
    searchBarItems,
    isSearchBarOpen,
    setFilter,
    setIsSearchBarOpen,
  };
}
