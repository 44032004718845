import React from "react";
import "twin.macro";
import { useCreateFormLegacy } from "../../../../../components/antd/form/useCreateForm";
import { createAdminPharmacyEmailSubscription } from "../../../../../services/legacy/pharmacyEmailSubscriptions";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { AdminPharmacyEmailSubscriptionForm } from "./AdminPharmacyEmailSubscriptionForm/AdminPharmacyEmailSubscriptionForm";

export function CreateAdminPharmacyEmailSubscription() {
  const onSubmit = useCreateFormLegacy({
    redirectTo: "/admin/pharmacy/email/subscription",
    create: createAdminPharmacyEmailSubscription,
  });

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyEmailSubscriptionForm onSubmit={onSubmit} />
    </WideContainer>
  );
}
