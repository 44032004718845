import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { styled } from "twin.macro";
import "core-js/actual/set-immediate";
import { Form } from "antd";
import TextArea, { TextAreaRef } from "antd/es/input/TextArea";
import { ItemInCart, ItemNote } from "../../utilities/types";
import { formatDateToDayWithTime } from "../../utilities/dates/formatDateToDayWithTime";
import { Button, IconButton } from "../rxLibrary/buttons";
import { Text } from "../rxLibrary/typography";
import { IconTrash } from "../rxLibrary/icons";
import { Box } from "../rxLibrary/box";

const Wrapper = styled.div`
  width: 310px;
  position: relative;

  .ant-form-item-explain {
    margin-left: 11px;

    &:first-letter {
      text-transform: capitalize;
    }
  }
`;

function useTextAreaAutoFocus() {
  const textAreaRef = useRef<TextAreaRef>(null);

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      setImmediate(() => {
        // focus at the end of the text
        const end = textArea?.resizableTextArea?.textArea?.value?.length ?? 0;
        textArea?.resizableTextArea?.textArea?.setSelectionRange(end, end);
        textArea?.resizableTextArea?.textArea?.focus();
      });
    }
  }, []);

  return textAreaRef;
}

export function PrescriptionNotePopover({
  prescription,
  close,
  updatePrescriptionNote,
}: {
  prescription: ItemInCart;
  close: () => void;
  updatePrescriptionNote: (prescription: ItemInCart, note?: ItemNote) => void;
}) {
  const {
    message,
    createdAt = new Date(),
    updatedAt,
  } = prescription.note ?? {};
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);
  const textAreaRef = useTextAreaAutoFocus();
  const hasMessage = !!message;
  const createdAtStr = formatDateToDayWithTime(createdAt);
  const updatedAtStr = formatDateToDayWithTime(updatedAt);

  function openOverlay(e: MouseEvent) {
    e.preventDefault();
    setIsOverlayOpen(true);
  }

  function closeOverlay(e: MouseEvent) {
    e.stopPropagation();
    setIsOverlayOpen(false);
  }

  function deleteNote() {
    updatePrescriptionNote(prescription);
    close();
  }

  function updateNote({ message }: { message: string }) {
    const note: ItemNote = {
      message,
      createdAt: createdAt ?? new Date(),
      updatedAt: new Date(),
    };
    updatePrescriptionNote(prescription, note);
    close();
  }

  return (
    <Wrapper>
      <Form
        onFinish={updateNote}
        requiredMark={false}
        initialValues={{ message }}
      >
        <Box tw="py-[12px] px-[17px]" bgColor="grey-4">
          <Text>
            {!!createdAtStr && `Added: ${createdAtStr}`}
            {!!updatedAtStr &&
              createdAtStr !== updatedAtStr &&
              `, Updated: ${updatedAtStr}`}
          </Text>
        </Box>

        <Form.Item
          name="message"
          rules={[{ required: true, whitespace: true }]}
        >
          <TextArea
            ref={textAreaRef}
            bordered={false}
            autoSize={{ minRows: 3 }}
          />
        </Form.Item>

        <Box
          tw="flex justify-between items-center border-t py-[6px] px-[14px]"
          borderColor="grey-3"
        >
          <div>
            {hasMessage && (
              <IconButton onClick={openOverlay}>
                <IconTrash />
              </IconButton>
            )}
          </div>

          <div tw="space-x-2">
            <Button variant="text-1" onClick={close}>
              Cancel
            </Button>
            <Button variant="tertiary" type="submit">
              {hasMessage ? "Update" : "Apply"}
            </Button>
          </div>
        </Box>
      </Form>

      {isOverlayOpen && (
        <Box
          tw="absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center opacity-90"
          bgColor="white-1"
        >
          <Text weight="bold" size="medium" block>
            Delete note?
          </Text>
          <Box
            tw="flex items-center space-x-2 border-t mt-[13px] pt-[13px]"
            borderColor="grey-3"
          >
            <Button variant="text-1" onClick={closeOverlay}>
              Cancel
            </Button>
            <Button variant="tertiary" onClick={deleteNote}>
              Delete
            </Button>
          </Box>
        </Box>
      )}
    </Wrapper>
  );
}
