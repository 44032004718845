import { ComponentProps } from "react";
import { styled } from "twin.macro";
import { Switch as BaseSwitch } from "antd";
import CheckOutlined from "@ant-design/icons/CheckOutlined";
import { GREEN_1, GREY_1, GREY_2 } from "./colors";

const SwitchStyled = styled(BaseSwitch)`
  &.ant-switch.ant-switch-small {
    height: 14px;
    min-width: 22px;
    background-color: ${GREY_2};

    &:hover,
    &:active {
      background-color: ${GREY_1};
    }

    &.ant-switch-checked {
      background-color: ${GREEN_1};

      &:hover {
        background-color: ${GREEN_1}CC;
      }

      &:active {
        background-color: ${GREY_1};
      }

      .anticon.anticon-check {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        margin-right: 3px;
        margin-top: 4px;
        font-size: 7px;
        color: ${GREEN_1};
      }

      .ant-switch-handle {
        inset-inline-start: calc(100% - 12px);
      }
    }

    .anticon.anticon-check {
      display: none;
    }

    .ant-switch-inner {
      padding-inline-start: 5px;
      padding-inline-end: 17px;
    }

    .ant-switch-handle {
      height: 10px;
      width: 10px;
    }

    .ant-switch-handle {
      top: 2px;
    }
  }
`;

type ISwitchProps = Omit<
  ComponentProps<typeof BaseSwitch>,
  "size" | "checkedChildren"
>;

export function Switch(props: ISwitchProps) {
  return (
    <SwitchStyled
      {...props}
      checkedChildren={
        <CheckOutlined
          rev={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        />
      }
      size="small"
    />
  );
}
