import { dayjsWithTimezone } from "./dayjsWithTimezone";

/**
 * Get a date from an hour with time zone converted to local timezone
 * Example: give me 3pm in New York in my local timezone
 */
export function getHourWithTimezoneInLocalTime(hour: number, timezone: string) {
  const date = dayjsWithTimezone().hour(hour).tz(timezone, true).tz();
  return date;
}
