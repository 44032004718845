import { styled } from "twin.macro";
import { GREY_2 } from "../../../../../components/rxLibrary/colors";

export const CatalogItemsSection = styled.div`
  margin-top: 15px;
  padding-bottom: 65px;
  border-bottom: 1px solid ${GREY_2};

  :first-of-type {
    margin-top: 0;
  }

  :last-of-type {
    padding-bottom: 0;
    border-bottom: none;
  }
`;
