import React from "react";
import "twin.macro";
import type { TaggedItem } from "../../../utilities/types";

export function ItemDispensation({ item }: { item: TaggedItem }) {
  const numberPrescriptions =
    item.historicalDispensationInfo?.numberOfPrescriptionsLast30Days;

  return (
    <p>
      {numberPrescriptions.toLocaleString()}{" "}
      {numberPrescriptions === 1 ? "Patient" : "Patients"} Eligible (Est.)
    </p>
  );
}
