import isEmpty from "lodash/isEmpty";
import isInteger from "lodash/isInteger";
import { ensureArray } from "../../../../../utilities/arrays/ensureArray";
import { ManufacturerStoreFeaturedItem } from "../Catalog.constants";
import { getQueryParams } from "../../../../../utilities/queryParams/getQueryParams";

export function getQueryParamsFeaturedItems() {
  const { ndc, qty } = getQueryParams();

  let ndcs: (string | undefined)[] = [];
  if (ndc) {
    const ndcArr = ensureArray(ndc);
    ndcs = ndcArr.map((value) => {
      const newValue = isEmpty(value) ? undefined : value;
      return newValue;
    }, []);
  }

  if (ndcs.length === 0) return [];

  let numPackagesList: (number | undefined)[] = [];
  if (qty) {
    const quantitiesArr = ensureArray(qty);
    numPackagesList = quantitiesArr.map((value) => {
      const quantity = Number(value);
      const newValue = isInteger(quantity) ? quantity : undefined;
      return newValue;
    });
  }
  const featuredItems = ndcs.reduce<ManufacturerStoreFeaturedItem[]>(
    (acc, ndc, i) => {
      if (ndc !== undefined) {
        const numPackages = numPackagesList[i];
        acc.push({ ndc, numPackages });
      }

      return acc;
    },
    []
  );

  return featuredItems;
}
