import tw from "twin.macro";
import { formatNdc } from "../../utilities/ndc/formatNdc";
import { Container } from "../../components/containers/Container";
import { SimpleLink } from "../../components/rxLibrary/Link";
import { QuoteErrorDetailEnum } from "./useCompare/useCompare";

const Wrapper = tw(Container)`text-center text-lg`;

export function QuoteStateError({
  ndc,
  errorDetail,
}: {
  ndc: string;
  errorDetail?: QuoteErrorDetailEnum;
}) {
  if (errorDetail === QuoteErrorDetailEnum.NOT_FOUND) {
    return (
      <Wrapper>
        <span tw="font-medium">{formatNdc(ndc)}</span> is not a common NDC.
        Please search for the drug name instead
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      Please{" "}
      <SimpleLink href="javascript:window.location.reload(true)">
        try that search again
      </SimpleLink>
      . If you continue to have problems, please contact us via the chat below.
    </Wrapper>
  );
}
