import type { ReactNode } from "react";
import { Box } from "../../../components/rxLibrary/box";

export function Card({ children }: { children: ReactNode }) {
  return (
    <Box
      tw="border w-[374px] h-[402px] p-[26px] rounded-[9px] shadow-[0_0_10px_0_#0000001e]"
      bgColor="white-1"
      borderColor="grey-3"
    >
      {children}
    </Box>
  );
}
