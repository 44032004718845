import * as Sentry from "@sentry/react";
import dayjs from "dayjs";

export function formatDrugExpirationDate(info?: { expirationDate?: string }) {
  const { expirationDate } = info ?? {};
  if (!expirationDate) return;

  const currentTime = dayjs();
  const expirationDateObj = dayjs(expirationDate);
  if(!expirationDateObj.isValid()) {
    Sentry.captureException(new Error(`Invalid expiration date: ${expirationDate}`));
    return;
  }
  const timeBetween = expirationDateObj.diff(currentTime, "days");

  if (timeBetween > 365) return;
  const result =
    expirationDateObj.format("M") + "/" + expirationDateObj.format("YY");

  return result;
}
