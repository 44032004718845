import type { ShippingConfig, Supplier } from "../types";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../numbers/currency";

export function getMinShipOrderAmount(shippingConfig?: ShippingConfig): string {
  if (!shippingConfig) return "None";

  let minValue;
  const { fee, minOrder, minGenericOrder, minBrandRxOrder, minGenericRxOrder } =
    shippingConfig;

  if (minBrandRxOrder && minGenericRxOrder) {
    minValue =
      currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minGenericRxOrder) +
      " generics Rx or " +
      currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minBrandRxOrder) +
      " brand Rx";
  } else {
    if (minOrder) {
      minValue =
        currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minOrder);
    }
    if (minGenericOrder) {
      minValue = minValue ? `${minValue} or ` : "";
      minValue +=
        currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(minGenericOrder);
    }
  }

  if (fee) {
    const formattedFee =
      currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(fee);
    minValue = minValue ? `${minValue} or ` : "";
    minValue += `${formattedFee} fee`;
  }

  return minValue ?? "None";
}

export function getMinShipDescription(
  supplier: Supplier,
  opts?: { capitalize?: boolean; abbrev?: boolean; ship?: boolean }
): string {
  const { ship, abbrev, capitalize } = opts ?? {};

  if (supplier.shippingConfig?.minGenericOrder) {
    if (capitalize) {
      if (abbrev) return ship ? "Gen. Ship Min." : "Gen. Min.";
      return ship ? "Generics Shipping Minimum" : "Generics Minimum";
    }

    if (abbrev) return ship ? "gen. ship min." : "gen. min.";
    return ship ? "generics shipping minimum" : "generics minimum";
  }

  if (capitalize) {
    if (abbrev) return ship ? "Ship Min." : "Min.";
    return ship ? "Shipping Minimum" : "Minimum";
  }

  if (abbrev) return ship ? "ship min." : "min.";
  return ship ? "shipping minimum" : "minimum";
}
