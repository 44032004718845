import React, { useMemo } from "react";
import "twin.macro";
import { ItemInCart } from "../../../../../utilities/types";
import { formatPrescriberName } from "../../../../../utilities/prescriptions/formatPrescriberName";
import { DATE_WITH_YEAR } from "../../../../../utilities/dates/dates.constants";
import { formatDateToFormat } from "../../../../../utilities/dates/formatDateToFormat";

export function PrescriberData({ prescription }: { prescription: ItemInCart }) {
  const { prescriber, fillDate } = prescription;

  const fillDateFormatted = useMemo(() => {
    return formatDateToFormat(fillDate, DATE_WITH_YEAR);
  }, [fillDate]);

  const prescriberFormatted = useMemo(() => {
    return formatPrescriberName(prescriber);
  }, [prescriber]);

  return (
    <>
      {!!fillDateFormatted && <p tw="font-medium">{fillDateFormatted}</p>}
      {!!prescriberFormatted && <p>{prescriberFormatted}</p>}
    </>
  );
}
