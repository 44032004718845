import React, { useContext } from "react";
import "twin.macro";
import Select, { SingleValue } from "react-select";
import type { OptionData, Pharmacy } from "../utilities/types";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import PharmaciesContext from "../contexts/PharmaciesContext";
import { toSortedOptionData } from "../utilities/helpers";
import { groupById } from "../utilities/arrays/groupById";

export default function BuyingPharmacySelector({
  label,
  selectCallBack,
}: {
  label?: string | undefined;
  selectCallBack?(open: boolean): void;
}) {
  let pharmacySelection: OptionData[] = [];
  const { pharmacies } = useContext(PharmaciesContext);
  const { currentBuyingPharmacyId, setCurrentBuyingPharmacyId } = useContext(
    BuyingPharmacyContext
  );

  const options = toSortedOptionData(pharmacies);

  pharmacySelection = [];
  if (currentBuyingPharmacyId !== null) {
    const pharmaciesById: Record<number, Pharmacy> = groupById(pharmacies);
    if (pharmaciesById[currentBuyingPharmacyId]) {
      pharmacySelection = [
        {
          value: currentBuyingPharmacyId,
          label: pharmaciesById[currentBuyingPharmacyId].name,
        },
      ];
    }
  }

  const menuCallBack = (open: boolean) => {
    selectCallBack && selectCallBack(open);
  };

  return (
    <div>
      <div tw="pb-[8px]">
        <span tw="text-[#202020] font-light normal-case text-base">
          {label || "Pharmacy"}:
        </span>
      </div>
      <Select
        closeMenuOnSelect
        onMenuOpen={() => menuCallBack(true)}
        onMenuClose={() => {
          menuCallBack(false);
        }}
        css={{ minWidth: "218px" }}
        isLoading={currentBuyingPharmacyId === null}
        isMulti={false}
        value={pharmacySelection}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(selection: SingleValue<OptionData>) => {
          if (selection !== null) {
            setCurrentBuyingPharmacyId(selection.value);
          }
        }}
        tw="inline-block text-black normal-case text-base"
        options={options}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      />
    </div>
  );
}
