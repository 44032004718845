import { createContext, useContext } from "react";
import type {
  ManufacturerStoreAddToCart,
  ManufacturerStoreItemsByType,
  ManufacturerStoreRemoveFromCart,
  ManufacturerStoreUpdateItemQuantity,
} from "./Catalog.constants";
import { Pharmacy, Supplier } from "../../../../utilities/types";

const CatalogContext = createContext<
  | {
      pharmacy: Pharmacy;
      supplier: Supplier;
      itemsByType: ManufacturerStoreItemsByType;
      hasFoamItems: boolean;
      isFFFSupplier: boolean;
      isMayneSupplier: boolean;
      isJourneySupplier: boolean;
      addToCart: ManufacturerStoreAddToCart;
      removeFromCart: ManufacturerStoreRemoveFromCart;
      updateItemQuantity: ManufacturerStoreUpdateItemQuantity;
    }
  | undefined
>(undefined);

export const CatalogProvider = CatalogContext.Provider;

export function useCatalog() {
  const data = useContext(CatalogContext);

  if (!data) {
    throw new Error("useCatalog must be used within a CatalogProvider");
  }

  return data;
}
