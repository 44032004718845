import React from 'react';
function TimeUntil({when}:{when: number}) : JSX.Element {
  const [timeRemaining, setTimeRemaining] = React.useState<string>("");
  React.useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const delta = when - now;
      if(delta < 0) {
        setTimeRemaining("0:00");
        return;
      }
      const minutes = Math.floor((delta / 1000) / 60);
      const seconds = Math.floor((delta / 1000) % 60);
      setTimeRemaining(`${minutes}:${seconds.toString().padStart(2, "0")}`);
    }, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [when]);
  return <>{timeRemaining}</>;
}

export default React.memo(TimeUntil);
