import { useCallback, useMemo } from "react";
import "twin.macro";
import { Col, Row } from "antd";
import type { ManufacturerStoreItem } from "../Catalog.constants";
import { QtySelect } from "../../../../../components/rxLibrary/selects/QtySelect";
import { DrugDetailsCol } from "../../../../../components/shopping/table/columns/DrugDetailsCol";
import { QuantityDescriptionCol } from "../../../../../components/shopping/table/columns/QuantityDescriptionCol";
import { useCatalog } from "../useCatalog";

export function CatalogCartItem({ item }: { item: ManufacturerStoreItem }) {
  const { isFFFSupplier, isMayneSupplier, updateItemQuantity } = useCatalog();

  const { ndc, drug, catalogInfo, numPackages, refrigerated } = item;
  const { directDeal } = catalogInfo;

  const quantitiesConfig = useMemo(() => {
    return {
      maxQty: 999,
      bundleSize: directDeal?.bundleSize,
      multiplesOfBundleSize: true,
      includeZero: true,
      zeroLabel: "Delete",
    };
  }, [directDeal]);

  const handleUpdateQuantity = useCallback(
    (newNumPackages: number) => {
      updateItemQuantity(ndc, newNumPackages);
    },
    [ndc, updateItemQuantity]
  );

  return (
    <Row key={ndc}>
      <DrugDetailsCol
        tw="min-w-[0] px-0 flex-1"
        drug={drug}
        catalogInfo={catalogInfo}
        refrigerated={refrigerated}
        isMayneSupplier={isMayneSupplier}
        hideManufacturer={!isFFFSupplier}
        v2
      />

      <Col tw="min-w-[104px] text-center border-r">
        <QuantityDescriptionCol drug={drug} catalogInfo={catalogInfo} />
      </Col>

      <Col tw="flex justify-center rounded w-[88px] h-[68px]">
        <QtySelect
          value={numPackages}
          quantitiesConfig={quantitiesConfig}
          onChange={handleUpdateQuantity}
          vertical
        />
      </Col>
    </Row>
  );
}
