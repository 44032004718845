import React from "react";
import "twin.macro";
import { BuyLaterTableModalRow } from "./BuyLaterTableModalRow";
import { TableModalTable } from "../../table/tableModal/TableModalTable";
import { TableModal } from "../../table/tableModal/TableModal";
import { BuyLaterItem } from "../useBuyLaterTableItems";
import { ShoppingItem } from "../../../useShoppingList";
import { useBuyLaterTableModal } from "./useBuyLaterTableModal";

export function BuyLaterTableModal({
  item,
  shoppingItems,
  closeModal,
}: {
  item: BuyLaterItem;
  shoppingItems: ShoppingItem[];
  closeModal: () => void;
}) {
  const { shoppingItemsAlternatives, notShoppingItemsAlternatives } =
    useBuyLaterTableModal(item, shoppingItems);

  return (
    <TableModal open title="Select Option to Buy Today" onClose={closeModal}>
      {shoppingItemsAlternatives.length > 0 && (
        <TableModalTable title="Options">
          {shoppingItemsAlternatives.map((data) => (
            <BuyLaterTableModalRow
              key={data.supplier.id}
              item={item}
              alternativeWithSupplierData={data}
              close={closeModal}
            />
          ))}
        </TableModalTable>
      )}

      {notShoppingItemsAlternatives.length > 0 && (
        <TableModalTable title="Yet More Options">
          {notShoppingItemsAlternatives.map((data) => (
            <BuyLaterTableModalRow
              key={data.supplier.id}
              item={item}
              alternativeWithSupplierData={data}
              close={closeModal}
            />
          ))}
        </TableModalTable>
      )}
    </TableModal>
  );
}
