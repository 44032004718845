import React, { useCallback, useContext, useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import Modal from "@mui/material/Modal";
import { DrugInfo, DrugWithStats, ItemInCart } from "../../../utilities/types";
import { cleanNdc } from "../../../utilities/ndc/cleanNdc";
import {
  getDispensesStatsGroup,
  getUnitSizeFromDrugInfo,
} from "../../../utilities/drugInfo/drugInfo";
import { formatBundleSizeDropdownItems } from "../../../utilities/prescriptions/formatDirectDealDropdownItems.deprecated";
import { useAuth0 } from "../../../contexts/AuthenticationContext";
import { useShoppingUpdater } from "../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { useStorageUpdater } from "../../../contexts/shoppingContexts/StorageContext";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { getSinglePrescription } from "../../../services/prescriptions";
import { getSearch_deprecated } from "../../../services/searches";
import { ShoppingListColumnPackageInfo } from "../table/columns/ShoppingListColumnPackageInfo";
import { DropDownListQty } from "../../DropDownListQty";
import { useUpdateDrugSearchValues } from "../../DrugSearch/DrugSearch";
import { Col, Row } from "../../rxLibrary/grid";
import { Text } from "../../rxLibrary/typography";
import {
  NotCommonNdcSearchError,
  TextSearchError,
} from "../../DrugSearch/useDrugSearch/useDrugSearch.errors";
import { ManufacturerDirectDeal } from "../ManufacturerDirectDeal";
import {
  ModalContent,
  DispensesStatsRow,
  DispensesStatsTitle,
} from "./InventoryModal.utils";
import { PopoverSwitch } from "./PopoverSwitch";
import { InventoryModalPrescriptionAlternatives } from "./InventoryModalPrescriptionAlternatives/InventoryModalPrescriptionAlternatives";
import { QuantityKeyTypes } from "./InventoryModal.constants";
import { InventoryModalDrugSearchScreen } from "./InventoryModalDrugSearchScreen";
import { InventoryModalHeader } from "./InventoryModalHeader";
import { DrugDetailsCol } from "./DrugDetailsCol";

export function InventoryModal({
  close,
  customAddInventoryItem,
}: {
  close: () => void;
  customAddInventoryItem?: (item: ItemInCart) => void;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);
  const updateDrugSearchValues = useUpdateDrugSearchValues();
  const { addInventoryItem } = useShoppingUpdater();
  const { setWaitButtonMode } = useStorageUpdater();

  const [screen, setScreen] = useState(1);
  const [equivalentsLink, setEquivalentsLink] = useState(false);
  const [mainData, setMainData] = useState<DrugWithStats[]>([]);
  const [diffOpt, setDiffOpt] = useState(0);
  const [manufacturerSel, setManufacturerSel] = useState("");
  const [packSizeSelect, setPackSizeSelect] = useState("");
  const [optOneFlow, setOptOneFlow] = useState(0);
  const [optTwoFlow, setOptTwoFlow] = useState(0);
  const [prescriptionData, setPrescriptionData] = useState<DrugWithStats[]>();
  const [showTbl, setShowTbl] = useState(true);
  const [dispensesStatsType, setDispensesStatsType] = useState("");
  const [packQuantity, setPackQuantity] = useState<QuantityKeyTypes[]>([]);

  const closeModal = useCallback(() => {
    updateDrugSearchValues({ search: "" });
    close();
  }, [close, updateDrugSearchValues]);

  const updateQuantityKeys = useCallback(
    (qObj: QuantityKeyTypes) => {
      const newPackQuantity = cloneDeep(packQuantity);

      const currentItems = newPackQuantity.filter((item) => {
        return item.id === qObj.id;
      });
      if (currentItems.length > 0) {
        newPackQuantity.map((obj) => {
          if (obj.id === qObj.id) {
            obj.value = qObj.value;
          }
        });
      } else {
        newPackQuantity.push(qObj);
      }

      setPackQuantity(newPackQuantity);
    },
    [packQuantity]
  );

  const getQuantityKeyValue = useCallback(
    (id: string): number => {
      const obj = packQuantity.find((item) => item.id === id);
      return obj?.value ?? 1;
    },
    [packQuantity]
  );

  const resetScreen = useCallback(() => {
    if (screen === 2) {
      updateDrugSearchValues({ search: "" });
    }
    setPrescriptionData(undefined);
    setScreen(1);
  }, [updateDrugSearchValues, screen]);

  const addNewDrug = useCallback(
    (item: ItemInCart) => {
      if (customAddInventoryItem) {
        customAddInventoryItem(item);
      } else {
        addInventoryItem(item);
        setWaitButtonMode(false);
      }

      closeModal();
    },
    [closeModal, addInventoryItem, setWaitButtonMode, customAddInventoryItem]
  );

  const sortPrescriptionData = useCallback(
    (data: DrugWithStats[], type: string) => {
      setDispensesStatsType(type);

      const sorted = [...data].sort((a, b) => {
        const aGroup = getDispensesStatsGroup(a, type);
        const bGroup = getDispensesStatsGroup(b, type);

        const firstRx = aGroup?.numberOfPrescriptions ?? 0;
        const secondRx = bGroup?.numberOfPrescriptions ?? 0;
        if (firstRx > secondRx) return -1;
        if (firstRx < secondRx) return 1;

        const firstUnits = aGroup?.numberOfUnits ?? 0;
        const secondUnits = bGroup?.numberOfUnits ?? 0;
        if (firstUnits > secondUnits) return -1;
        if (firstUnits < secondUnits) return 1;

        const firstName = a.drug.manufacturer.toLowerCase();
        const secondName = b.drug.manufacturer.toLowerCase();
        if (firstName < secondName) return -1;
        if (firstName > secondName) return 1;
        return 0;
      });

      setPrescriptionData(sorted);
    },
    []
  );

  async function getEquivalentsLink() {
    const prescription = prescriptionData?.[0];

    if (prescription) {
      updateDrugSearchValues({ search: prescription.drug.name });
      await getData({ screen: 3, drug: prescription.drug });
    }
  }

  async function getData({
    ndc,
    screen = 2,
    drug,
  }: {
    ndc?: string;
    screen?: number;
    drug?: DrugInfo;
  }) {
    setEquivalentsLink(false);
    setDiffOpt(0);
    setOptOneFlow(0);
    setOptTwoFlow(0);
    setPrescriptionData(undefined);

    if (!currentBuyingPharmacyId) {
      return;
    }

    const drugName = drug?.name;
    const genericProductIdentifier = drug?.genericProductIdentifier;
    const unitSize = getUnitSizeFromDrugInfo(drug) ?? "";
    const isValidNdc = !!ndc && !!cleanNdc(ndc);

    if (isValidNdc) {
      let newNDC = ndc;
      const token = await getAccessTokenSilently();
      const { data } = await getSearch_deprecated(
        ndc,
        currentBuyingPharmacyId,
        token
      );

      // check for UPCs
      if (data?.searchResults && data?.searchResults.length === 1) {
        newNDC = data?.searchResults[0].representativeDrugInfo.ndc;
      }

      const prescriptionData = await getSinglePrescription(
        currentBuyingPharmacyId,
        token,
        newNDC,
        drugName,
        genericProductIdentifier,
        unitSize
      );

      if (!prescriptionData?.drugs || prescriptionData.drugs.length === 0) {
        throw new NotCommonNdcSearchError(`${ndc} is not a common NDC`);
      }

      setScreen(screen);
      setPrescriptionData(prescriptionData.drugs);
      setShowTbl(true);
      if (prescriptionData.drugs.length === 1) {
        setEquivalentsLink(true);
      }
    } else {
      const token = await getAccessTokenSilently();
      const data = await getSinglePrescription(
        currentBuyingPharmacyId,
        token,
        null,
        drugName,
        genericProductIdentifier,
        unitSize
      );

      if (!data?.drugs || data.drugs.length === 0) {
        throw new TextSearchError();
      }

      setScreen(screen);
      const { drugs } = data;

      setMainData(drugs);
      setDispensesStatsType("");
      setShowTbl(true);

      // if 4 or less, show all
      if (drugs.length <= 4) {
        setPrescriptionData(drugs);
      } else {
        // 5 or more, based on dispensesStats
        // any which have non-zero dispense history for 30, 90 or 180
        const last30Days = drugs.filter((item) => {
          return (
            getDispensesStatsGroup(item, "last30Days").numberOfPrescriptions > 0
          );
        });
        const last90Days = drugs.filter((item) => {
          return (
            getDispensesStatsGroup(item, "last90Days").numberOfPrescriptions > 0
          );
        });
        const last180Days = drugs.filter((item) => {
          return (
            getDispensesStatsGroup(item, "last180Days").numberOfPrescriptions >
            0
          );
        });

        if (last30Days.length > 0) {
          sortPrescriptionData(last30Days, "last30Days");
        } else if (last90Days.length > 0) {
          sortPrescriptionData(last90Days, "last90Days");
        } else if (last180Days.length > 0) {
          sortPrescriptionData(last180Days, "last180Days");
        } else {
          setShowTbl(false);
          setPrescriptionData(drugs);
        }
      }
    }
  }

  useEffect(() => {
    updateDrugSearchValues({ search: "" });
  }, []);

  return (
    <Modal open onClose={closeModal}>
      <ModalContent screen={screen}>
        {screen === 1 && (
          <InventoryModalDrugSearchScreen
            onCancel={closeModal}
            getData={getData}
          />
        )}

        {screen >= 2 && prescriptionData && (
          <>
            <InventoryModalHeader
              prescriptionData={prescriptionData}
              dispensesStatsType={dispensesStatsType}
              closeModal={closeModal}
              resetScreen={resetScreen}
            />

            {showTbl && (
              <div className="mt-24 ml-96 mr-96">
                <Text block size="large" weight="bold" tw="mb-[14px]">
                  {dispensesStatsType !== "" && "Recently dispensed NDCs"}
                  {dispensesStatsType === "" &&
                    prescriptionData.length <= 4 &&
                    `Available NDC${prescriptionData.length > 1 ? "s" : ""}`}
                </Text>

                {prescriptionData.map((prescription) => {
                  const { drug, directDeals } = prescription;
                  const directDeal = directDeals?.[0];
                  const { ndc } = drug;
                  const dropdownItems = formatBundleSizeDropdownItems({
                    bundleSize: directDeal?.bundleSize,
                    directDealLabel: true,
                  });
                  const packQuantity = getQuantityKeyValue(ndc);

                  return (
                    <Row key={ndc}>
                      <Col tw="flex-[2]">
                        <DrugDetailsCol drug={drug} />
                      </Col>

                      <Col tw="flex-1">
                        <ShoppingListColumnPackageInfo drug={drug} />
                      </Col>

                      {dispensesStatsType !== "" ? (
                        mainData.length > 4 && (
                          <Col tw="flex-1">
                            <DispensesStatsTitle
                              items={[prescription]}
                              type={dispensesStatsType}
                              tbl
                            />
                          </Col>
                        )
                      ) : (
                        <Col tw="flex-1">
                          <DispensesStatsRow prescription={prescription} />
                        </Col>
                      )}

                      <Col tw="flex-1">
                        <div tw="space-y-[12px]">
                          <div tw="min-w-[178px]">
                            <DropDownListQty
                              value={packQuantity.toString()}
                              label="Qty"
                              dropDownItems={dropdownItems}
                              callBack={(v: string) => {
                                const obj = {
                                  id: ndc,
                                  value: parseInt(v),
                                };
                                updateQuantityKeys(obj);
                              }}
                            />
                          </div>

                          {!!directDeal && (
                            <ManufacturerDirectDeal
                              directDeal={directDeal}
                              packQuantity={packQuantity}
                              descriptive
                            />
                          )}
                        </div>
                      </Col>

                      <Col>
                        <PopoverSwitch
                          prescriptions={prescriptionData}
                          data={mainData}
                          prescription={prescription}
                          packQuantity={packQuantity}
                          callBack={addNewDrug}
                          type={dispensesStatsType}
                        />
                      </Col>
                    </Row>
                  );
                })}

                {equivalentsLink && (
                  <Text block className="mt-48">
                    <a
                      href="#"
                      onClick={getEquivalentsLink}
                      className="underline"
                    >
                      Search all equivalents
                    </a>
                  </Text>
                )}
              </div>
            )}
          </>
        )}

        {screen === 3 && !!prescriptionData && (
          <InventoryModalPrescriptionAlternatives
            diffOpt={diffOpt}
            mainData={mainData}
            optOneFlow={optOneFlow}
            optTwoFlow={optTwoFlow}
            packSizeSelect={packSizeSelect}
            manufacturerSel={manufacturerSel}
            prescriptionData={prescriptionData}
            dispensesStatsType={dispensesStatsType}
            addNewDrug={addNewDrug}
            setDiffOpt={setDiffOpt}
            setOptOneFlow={setOptOneFlow}
            setOptTwoFlow={setOptTwoFlow}
            setPackSizeSelect={setPackSizeSelect}
            setManufacturerSel={setManufacturerSel}
            updateQuantityKeys={updateQuantityKeys}
            getQuantityKeyValue={getQuantityKeyValue}
          />
        )}
      </ModalContent>
    </Modal>
  );
}
