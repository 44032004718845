import React from "react";
import type { PerformanceReportComponent } from "./Performance.constants";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { PerformanceReportError } from "./PerformanceReportError";
import { useReportRequest } from "./useReportRequest";
import { ReportWrapper } from "./ReportWrapper";

export function PerformanceReportChart({
  Report,
}: {
  Report: PerformanceReportComponent;
}) {
  const { isLoading, error, data } = useReportRequest();

  return (
    <>
      {!!data && (
        <ReportWrapper>
          <Report data={data} />
        </ReportWrapper>
      )}
      {!!error && <PerformanceReportError />}
      {isLoading && <FullPageLoader />}
    </>
  );
}
