import { useCallback, useMemo } from "react";
import "twin.macro";
import type { ManufacturerStoreItem } from "../../Catalog.constants";
import {
  Col,
  Row,
  InnerRow,
} from "../../../../../../components/rxLibrary/grid";
import { QtySelect } from "../../../../../../components/rxLibrary/selects/QtySelect";
import { DrugDetailsCol } from "../../../../../../components/shopping/table/columns/DrugDetailsCol";
import { QuantityDescriptionCol } from "../../../../../../components/shopping/table/columns/QuantityDescriptionCol";
import { CatalogItemButton } from "./CatalogItemButton";
import { useCatalog } from "../../useCatalog";

export function CatalogItem({
  item,
  originalInvoiceDate,
}: {
  item: ManufacturerStoreItem;
  originalInvoiceDate?: string;
}) {
  const {
    isFFFSupplier,
    isMayneSupplier,
    addToCart,
    removeFromCart,
    updateItemQuantity,
  } = useCatalog();

  const { ndc, isC2, drug, status, catalogInfo, numPackages, refrigerated } =
    item;
  const { directDeal } = catalogInfo;
  const isInCart = status === "list";

  const quantitiesConfig = useMemo(() => {
    return {
      maxQty: 999,
      bundleSize: directDeal?.bundleSize,
      multiplesOfBundleSize: true,
      includeZero: isInCart,
      zeroLabel: "Delete",
    };
  }, [directDeal, isInCart]);

  const handleUpdateQuantity = useCallback(
    (newNumPackages: number) => {
      updateItemQuantity(ndc, newNumPackages);
    },
    [ndc, updateItemQuantity]
  );

  return (
    <Row>
      <DrugDetailsCol
        tw="border-r-0 flex-1 w-full"
        drug={drug}
        catalogInfo={catalogInfo}
        refrigerated={refrigerated}
        isMayneSupplier={isMayneSupplier}
        hideManufacturer={!isFFFSupplier}
        originalInvoiceDate={originalInvoiceDate}
        v2
      />

      <InnerRow>
        <Col tw="w-[113px] text-center">
          <QuantityDescriptionCol drug={drug} catalogInfo={catalogInfo} />
        </Col>

        <Col tw="w-[107px]">
          <QtySelect
            value={numPackages}
            disabled={isC2}
            quantitiesConfig={quantitiesConfig}
            onChange={handleUpdateQuantity}
            vertical
          />
        </Col>

        <Col tw="w-[124px]">
          <div tw="flex justify-center items-center h-full">
            <CatalogItemButton
              ndc={ndc}
              disabled={isC2}
              isInCart={isInCart}
              addToCart={addToCart}
              removeFromCart={removeFromCart}
            />
          </div>
        </Col>
      </InnerRow>
    </Row>
  );
}
