import "twin.macro";
import type { ItemInCart, TableCellPropsType } from "../../../../utilities/types";
import { uomDescription } from "../../../../utilities/helpers";

function IdentifierCell({ cell }: TableCellPropsType<ItemInCart>) {
  const item = cell.value;
  
  return (
    <div tw="flex font-bold">
      <p>
        {item?.rxNumber
          ? `${item.rxNumber},`
          : item?.noManufacturerPreference
          ? "Any Manufacturer"
          : "Inventory"}
      </p>
      &nbsp;&nbsp;
      <p>{item?.patient?.shortName}</p>
    </div>
  );
}

function DispensedQuantityCell({ cell }: TableCellPropsType<ItemInCart>) {
  const item = cell.value;
  
  return (
    <div>
      {item?.dispensedQuantity
        ? uomDescription(
            Number(item?.dispensedQuantity),
            item.drug
          )
        : item?.packQuantity
        ? uomDescription(
            item?.packQuantity *
              item?.drug?.unitQuantity *
              item?.drug?.unitSize,
            item.drug
          )
        : null}
    </div>
  );
}

function PurchaseByCell({ cell }: TableCellPropsType<string>) {
  return (
    <div>
      <strong>{cell.value}</strong>
    </div>
  );
}

export const COLUMNS = [
  {
    Header: "Needed By",
    accessor: "purchaseBy",
    Cell: PurchaseByCell,
    maxWidth: 100,
  },
  {
    Header: "Dispensed Quantity",
    accessor: "dispensedQty",
    Cell: DispensedQuantityCell,
  },
  {
    Header: "Rx # , Patient",
    accessor: "identifier",
    Cell: IdentifierCell,
    maxWidth: 130,
  },
  {
    Header: "Date Filled",
    accessor: "dateFilled",
  },
  {
    Header: "Prescriber",
    accessor: "prescriber",
  }
];
