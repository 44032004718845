import type { PrescriberInfo } from "../types";
import { forcedTitleCase } from "../strings/forcedTitleCase";

export function formatPrescriberName(info?: PrescriberInfo) {
  const { firstName, lastName, suffix, prefix } = info ?? {};

  if (firstName && lastName) {
    const text = [
      prefix && forcedTitleCase(prefix),
      forcedTitleCase(firstName),
      forcedTitleCase(lastName),
      suffix,
    ]
      .filter(Boolean)
      .join(" ");
    return text;
  }

  return "-";
}
