import React from "react";
import "twin.macro";
import { InvoiceHistoryHistoryItem } from "../../../utilities/types";
import { Box } from "../../../components/rxLibrary/box";
import { Text } from "../../../components/rxLibrary/typography";
import { SimpleTable } from "../../../components/rxLibrary/tables";
import { formatNdc } from "../../../utilities/ndc/formatNdc";

const TBody = Box.withComponent("tbody");

export function InvoiceTableRow({
  item: { items },
}: {
  item: { items: InvoiceHistoryHistoryItem[] };
}) {
  return (
    <SimpleTable tw="w-full">
      <thead>
        <tr>
          <th tw="w-[300px]">Item Name</th>
          <th tw="w-[100px]">NDC</th>
          <th tw="w-[100px]">UPC</th>
          <th tw="w-[64px]">Quantity</th>
          <th tw="w-[80px]">Unit Price</th>
          <th tw="w-[98px]">Total ֿPrice</th>
          <th tw="w-[190px]">PO Number</th>
          <th tw="w-[98px]">Invoice #</th>
        </tr>
      </thead>

      <TBody bgColor="white-1">
        {items.map((item, i) => {
          const {
            itemName,
            ndc,
            upc,
            quantity,
            unitPrice,
            totalPrice,
            purchaseOrderNumber,
            invoiceNumber,
          } = item;

          const formattedNdc = !!ndc && formatNdc(ndc);

          return (
            <tr key={i}>
              <td>
                {!!itemName && (
                  <Text weight="bold" block>
                    {itemName}
                  </Text>
                )}
              </td>

              <td>{formattedNdc}</td>

              <td>{upc}</td>

              <td tw="text-right">{quantity}</td>

              <td tw="text-right">
                {!!unitPrice && <Text weight="bold">${unitPrice}</Text>}
              </td>

              <td tw="text-right">
                <Text weight="bold">${totalPrice}</Text>
              </td>

              <td>
                {!!purchaseOrderNumber && (
                  <Text weight="bold">{purchaseOrderNumber}</Text>
                )}
              </td>

              <td>
                <Text weight="bold" block>
                  {invoiceNumber}
                </Text>
              </td>
            </tr>
          );
        })}
      </TBody>
    </SimpleTable>
  );
}
