import React from "react";
import "twin.macro";
import type { SupplierAccountsSummaryView } from "../EnhancedSupplierAccount.constants";
import { Text } from "../../../components/rxLibrary/typography";

export function EnhancedSupplierAccountSummaryOfflineSuppliers({
  suppliers,
}: {
  suppliers: SupplierAccountsSummaryView[];
}) {
  if (suppliers.length === 0) return null;
  return (
    <div>
      <div tw="space-x-4 border-b py-[27px]">
        <Text size="x-large" weight="bold">
          Your Offline Suppliers
        </Text>
        <Text size="large">
          (these are suppliers you don&apos;t shop online with)
        </Text>
      </div>

      {suppliers.map((row) => (
        <div key={row.supplier} tw="border-b py-[20px] px-[25px]">
          <Text weight="bold" color="grey-1">
            {row.supplierDisplay}
          </Text>
        </div>
      ))}
    </div>
  );
}
