import React, { FormEvent, useCallback, useState } from "react";
import "twin.macro";
import { Input } from "antd";
import SaveOutlined from "@ant-design/icons/SaveOutlined";
import { Button } from "../../../components/rxLibrary/buttons";
import {
  AuthSaveCredentialsPayload,
  SupplierAccountRecordView,
} from "../EnhancedSupplierAccount.constants";
import { dispatch } from "../EnhancedSupplierAccount.utils";
import { EnhancedSupplierAccountUsername } from "../EnhancedSupplierAccountUsername";

export function EnhancedSupplierAccountSettingsEditAccount({
  account,
  setViewMode,
}: {
  account: SupplierAccountRecordView;
  setViewMode: () => void;
}) {
  const [password, setPassword] = useState(account.password);

  const handleUpdatePassword = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const newPassword = event.currentTarget.value;
      setPassword(newPassword);
    },
    []
  );

  const save = useCallback(() => {
    if (!password || !account.userName) return;

    const payload: AuthSaveCredentialsPayload = {
      supplier: account.supplier,
      username: account.userName,
      password,
    };
    console.log("saving payload", payload);

    dispatch("requestSaveSupplierCredentials", payload);
    setViewMode();
  }, [account, password, setViewMode]);

  return (
    <tr>
      <td tw="p-2">
        <EnhancedSupplierAccountUsername account={account} />
      </td>

      <td tw="p-2">
        <Input
          type="text"
          placeholder="Password"
          id="password"
          value={password}
          onInput={handleUpdatePassword}
        />
      </td>

      <td tw="p-2">
        <div tw="flex justify-start items-center space-x-4">
          <Button
            disabled={!password}
            icon={<SaveOutlined rev={undefined} />}
            onClick={save}
          >
            Save
          </Button>

          <Button variant="secondary" onClick={setViewMode}>
            Cancel
          </Button>
        </div>
      </td>
    </tr>
  );
}
