import React from "react";
import { styled } from "twin.macro";
import { Link, useRouteMatch } from "react-router-dom";
import cn from "classnames";
import { useUserHomeUrl } from "../../utilities/routing/useUserHomeUrl";
import { useNavBarRoutes } from "../../utilities/routing/useNavBarRoutes";
import { ORANGE_1, WHITE_1 } from "../rxLibrary/colors";
import { IconHome } from "../rxLibrary/icons";

const LinkStyled = styled(Link)`
  font-family: "Archivo Narrow Variable", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${WHITE_1};
  padding: 6px 6px 3px;
  border-bottom: 3px solid transparent;
  display: flex;
  align-items: baseline;
  column-gap: 2px;

  &:hover {
    opacity: 0.8;
    color: ${WHITE_1};
  }

  &.match {
    font-weight: bold;
    border-color: ${ORANGE_1};
  }
`;

function NavBarLink({
  to,
  title,
  userHomeUrl,
}: {
  to: string;
  title: string;
  userHomeUrl?: string;
}) {
  const isHome = !!userHomeUrl && to === "/";
  // trick to highligh the nav link in the different home pages
  const pathToMatch = isHome ? userHomeUrl : to;
  const match = useRouteMatch(pathToMatch);

  return (
    <LinkStyled to={to} className={cn({ match })}>
      {isHome && <IconHome />}
      {title}
    </LinkStyled>
  );
}

export function NavBarLinks() {
  const userHomeUrl = useUserHomeUrl();
  const navBarRoutes = useNavBarRoutes();

  return (
    <>
      {navBarRoutes?.map(({ to, title }) => (
        <NavBarLink key={to} to={to} title={title} userHomeUrl={userHomeUrl} />
      ))}
    </>
  );
}
