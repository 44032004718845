import { KeyboardEvent } from "react";

export function runOnEnterKeyPressed(
  callback: (event: KeyboardEvent<HTMLInputElement>) => void
) {
  function onPressEnter(event: KeyboardEvent<HTMLInputElement>) {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      callback(event);
    }
  }

  return onPressEnter;
}
