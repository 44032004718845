import React from "react";
import "twin.macro";
import { AgGridReact } from "ag-grid-react";
import {
  COLUMN_DEFS,
  DETAIL_CELL_RENDERER_PARAMS,
  EXPORT_ALL_CSV_HEADERS,
  EXPORT_CSV_HEADERS,
} from "./AllFillsByPayerQuantityStatusDispensedInProgress.constants";
import { ReportWrapper } from "../../utils/ReportWrapper";
import { ROW_CLASS_RULES } from "../../utils/reportsConstants";
import { ExportToolPanelLarge } from "../../utils/ExportToolPanelLarge";
import { useReport } from "../../utils/useReport/useReport";

export function AllFillsByPayerQuantityStatusDispensedInProgress({
  data,
  exportCsvData,
  exportAllCsvData,
}: {
  data: Array<never>;
  exportCsvData: Array<never>;
  exportAllCsvData: Array<never>;
}) {
  const report = useReport({
    ExportToolPanel: ExportToolPanelLarge,
    detailCellRendererParams: DETAIL_CELL_RENDERER_PARAMS,
    columnDefs: COLUMN_DEFS,
    exportToolPanelProps: {
      exportCsvHeaders: EXPORT_CSV_HEADERS,
      exportAllCsvHeaders: EXPORT_ALL_CSV_HEADERS,
      exportCsvData,
      exportAllCsvData,
    },
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        autoGroupColumnDef={{ minWidth: 150 }}
        rowGroupPanelShow="always"
        groupDisplayType="multipleColumns"
        domLayout="autoHeight"
      />
    </ReportWrapper>
  );
}
