import { useEffect, useMemo, useState } from "react";
import "twin.macro";
import { OptionLabelWithWarning } from "components/antd/select/OptionLabelWithWarning";
import { SelectOption } from "../../../../../../components/rxLibrary/selects/select";
import { useRequest } from "../../../../../../services/request/useRequest";
import { getAdminSupplierMappingAndEdiOptionsBySupplier } from "../../../../../../services/supplierMappingAndEdi";
import { useAdminSupplierMappingAndEditOptions } from "../useAdminSupplierMappingAndEditOptions";

function useDisableAlreadyCreatedPharmaciesInPharmacyOptions(
  supplierId: number,
  pharmacyOptions: ReturnType<
    typeof useAdminSupplierMappingAndEditOptions
  >["pharmacyOptions"]
) {
  const [pharmacyWithDisabledOptions, setPharmacyWithDisabledOptions] =
    useState<typeof pharmacyOptions>();

  const config = useMemo(() => {
    if (!supplierId) return;
    return { supplierId };
  }, [supplierId]);

  const { data: supplierMappingAndEdiOptionsBySupplier } = useRequest({
    start: !!config,
    config,
    dataKey: "supplierMappingAndEdiOptionsBySupplier",
    request: getAdminSupplierMappingAndEdiOptionsBySupplier,
  });

  const alreadyCreatedPharmacyIds = supplierMappingAndEdiOptionsBySupplier?.all;

  useEffect(() => {
    if (!pharmacyOptions || !alreadyCreatedPharmacyIds?.length) {
      setPharmacyWithDisabledOptions(undefined);
      return;
    }

    const alreadyCreatedPharmacyIdsSet = new Set(alreadyCreatedPharmacyIds);
    const newPharmacyOptions = pharmacyOptions.map((option) => {
      if (!alreadyCreatedPharmacyIdsSet.has(option.value)) return option;
      return {
        ...option,
        label: (
          <OptionLabelWithWarning
            label={option.label}
            warning="Already created"
          />
        ),
        disabled: true,
      };
    });
    setPharmacyWithDisabledOptions(newPharmacyOptions);
  }, [pharmacyOptions, alreadyCreatedPharmacyIds]);

  return pharmacyWithDisabledOptions ?? pharmacyOptions;
}

export function useAdminSupplierMappingAndEdiFormOptions({
  supplierId,
  pharmacyId,
}: {
  supplierId: number;
  pharmacyId?: number;
}) {
  const [selectedSupplier, setSelectedSupplier] = useState<SelectOption>();
  const { supplierOptions, pharmacyOptions } =
    useAdminSupplierMappingAndEditOptions();
  const pharmacyWithDisabledOptions =
    useDisableAlreadyCreatedPharmaciesInPharmacyOptions(
      supplierId,
      pharmacyOptions
    );

  const [data = {}, setData] = useState<{
    pharmacy?: SelectOption;
    billToPharmacyIdOptions?: SelectOption[];
  }>();
  const { pharmacy, billToPharmacyIdOptions } = data;

  useEffect(() => {
    if (!pharmacyId || !pharmacyOptions) {
      setData(undefined);
      return;
    }

    const newPharmacy = pharmacyOptions.find((v) => v.value === pharmacyId);
    if (!newPharmacy) {
      setData(undefined);
      return;
    }

    const pharmacyOrganizationId = newPharmacy.organizationId;
    const newBillToPharmacyIdOptions = pharmacyOptions.filter(
      (v) => v.organizationId === pharmacyOrganizationId
    );

    setData({
      pharmacy: newPharmacy,
      billToPharmacyIdOptions: newBillToPharmacyIdOptions,
    });
  }, [pharmacyId, pharmacyOptions]);

  useEffect(() => {
    const supplier = supplierOptions?.find((v) => v.value === supplierId);
    setSelectedSupplier(supplier);
  }, [supplierId, supplierOptions]);


  return {
    pharmacy,
    supplierOptions,
    pharmacyOptions: pharmacyWithDisabledOptions,
    billToPharmacyIdOptions,
    selectedSupplier
  };
}
