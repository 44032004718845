import { SortDirectionEnum } from "../../../../components/rxLibrary/selects/SortBySelect";
import { ItemInCart } from "../../../../utilities/types";

export const CART_ITEM_FILTERS_OPTIONS = [
  { label: "In List", value: "list" },
  { label: "Not In List", value: "add" },
  { label: "In Stock", value: "stock" },
];

export enum CartItemsSortEnum {
  DISPENSED = "dispensed",
  STATUS = "status",
  RX = "rx",
  FILL_DATE = "fill_date",
  TIME_ADDED = "time_added",
  OPTIMIZED_DATE = "optimized_date",
  OPTIMIZED_SUPPLIER = "optimized_supplier",
  BOH = "boh",
  EOH = "eoh",
  PURCHASE_BY = "purchase_by",
  PATIENT = "patient",
}

export type ISetCartItemsFilters = (filters: string[]) => void;

export type ISetCartItemsSearch = (search: string) => void;

export type ISetCartItemsSort = (sort: CartItemsSortEnum) => void;

export type ISetCartItemsSortOrder = (sortOrder: SortDirectionEnum) => void;

export const CART_ITEMS_SORT_OPTIONS = [
  {
    label: "Item Name",
    value: CartItemsSortEnum.DISPENSED,
  },
  {
    label: "Fill Date",
    value: CartItemsSortEnum.FILL_DATE,
  },
  {
    label: "List Status",
    value: CartItemsSortEnum.STATUS,
  },
  {
    label: "Rx#",
    value: CartItemsSortEnum.RX,
  },
  {
    label: "Date/Time Added",
    value: CartItemsSortEnum.TIME_ADDED,
  },
  {
    label: "Optimized Date",
    value: CartItemsSortEnum.OPTIMIZED_DATE,
  },
  {
    label: "Optimized Supplier",
    value: CartItemsSortEnum.OPTIMIZED_SUPPLIER,
  },
  {
    label: "BOH Inventory",
    value: CartItemsSortEnum.BOH,
  },
  {
    label: "EOH Inventory",
    value: CartItemsSortEnum.EOH,
  },
  {
    label: '"Purchase By" Date',
    value: CartItemsSortEnum.PURCHASE_BY,
  },
  {
    label: "Patient",
    value: CartItemsSortEnum.PATIENT,
  },
];

export type ItemInCartWithVisibility = {
  visible: boolean;
  filtered: boolean;
  prescription: ItemInCart;
  filteredBySearchBarFilter: boolean;
};

export const HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS = 600;
