import React from "react";
import { styled } from "twin.macro";

// Wrapper for style and height of ag-grid
export function ReportWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Wrapper className="ag-theme-alpine">
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100%;

  &.ag-theme-alpine {
    --ag-font-size: 12px;
  }

  .reversed-claim {
    color: #c3c3c3 !important;
  }

  .total-row {
    font-weight: bold;
    background: rgb(241, 242, 245);
  }
`;
