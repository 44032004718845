import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  manufacturerReportCurrencyFormatter,
  manufacturerReportPercentFormatter,
  expandHereValueGetter,
} from "../utils/manufacturerReportFormatters";
import { getDetailCellRendererParams } from "../utils/getDetailCellRendererParams";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Pharmacy",
    children: [
      { field: "Pharmacy Name", pinned: "left" },
      { field: "Pharmacy NPI", pinned: "left", hide: true },
    ],
  },
  {
    field: "Claim Stream Detail",
    valueGetter: expandHereValueGetter,
    cellRenderer: "agGroupCellRenderer",
    pinned: "left",
  },
  {
    headerName: "Prescription",
    children: [
      { field: "Rx Number" },
      { field: "Dispense Status" },
      { field: "Prescribed Drug Name", hide: true },
      { field: "Prescribed NDC", hide: true },
      { field: "DAW Code", hide: true },
      { field: "Dispensed Drug Name" },
      { field: "Dispensed NDC", hide: true },
      { field: "Dispensed Same as Prescribed Drug", hide: true },
      { field: "Dispensed Quantity" },
      { field: "Is Rx New or Refill" },
      { field: "Rx Written Date", hide: true },
      { field: "Refill Number" },
      { field: "Fill Adjudication Date" },
      { field: "Days Supply", hide: true },
      { field: "Total Refills Prescribed", hide: true },
      { field: "Refills Remaining", hide: true },
      { field: "Auto Refill On", hide: true },
    ],
  },
  {
    headerName: "Prescriber",
    children: [
      { field: "Prescriber Name" },
      { field: "Prescriber NPI", hide: true },
      { field: "Prescriber Street Address", hide: true },
      { field: "Prescriber City", hide: true },
      { field: "Prescriber State", hide: true },
      { field: "Prescriber Zip", hide: true },
    ],
  },
  {
    headerName: "Unapplied Claim Info - Third-Party Primary Payer Data",
    children: [
      { field: "Was Coupon Used" },
      { field: "Last OCC", headerName: "OCC", hide: true },
      {
        field: "fill_has_prior_auth_required",
        headerName: "Rejected due to PA Required",
        hide: true,
      },
      {
        field: "prior_auth_to_coupon",
        headerName: "Rejected due to PA Required; Coupon Applied",
        hide: true,
      },
      {
        field: "fast_prior_auth_to_coupon",
        headerName:
          "Rejected due to PA Required; Coupon Applied within 10 mins of Rejection",
        hide: true,
      },
      {
        field: "was_pbm_rejection_resolved",
        headerName: "Was PBM Rejection Resolved",
        hide: true,
      },
      {
        field: "third_party_primary_payer_pbm_rejection_values",
        headerName: "3rd-Party Primary Payer PBM Rejections",
        hide: true,
        tooltipField: "third_party_primary_payer_pbm_rejection_values",
      },
      {
        field: "Final Rejection Codes",
        headerName: "3rd Party Primary Payer Final Rejections",
        tooltipField: "Final Rejection Codes",
        hide: true,
      },
      {
        field: "Pharmacy Gross Revenue for Abandoned & Transferred",
        headerName: "Pharmacy Gross Revenue",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Patient Pay - Before Coupon for Abandoned & Transferred",
        headerName: "Patient Pay Before Coupon, for Covered Fills",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Third Party Contribution for Abandoned & Transferred",
        headerName: "3rd-Party Contribution",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
      },
      {
        field: "Third Party Contribution > $0 for Abandoned & Transferred",
        headerName: "3rd-Party Contribution when >$0",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "Coverage Rate w/ Contribution > $0 for Abandoned & Transferred",
        headerName: "Coverage Rate w/ Contribution > $0",
        aggFunc: "avg",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        filter: "agNumberColumnFilter",
        hide: true,
      },
    ],
  },
  {
    headerName: "Payer",
    children: [
      { field: "Primary Plan Type" },
      { field: "Primary Payer Processor", hide: true },
      { field: "Primary Payer Name", hide: true },
      { field: "Primary Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Primary Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Primary BIN" },
      { field: "Primary PCN", hide: true },
      { field: "Primary Group ID", hide: true },
      { field: "Primary Payer Plan ID", hide: true },
      { field: "Primary Payer Network Reimbursement ID", hide: true },
      { field: "Secondary Plan Type", hide: true },
      { field: "Secondary Payer Processor", hide: true },
      { field: "Secondary Payer Name", hide: true },
      { field: "Secondary Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Secondary Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Secondary BIN" },
      { field: "Secondary PCN", hide: true },
      { field: "Secondary Group ID", hide: true },
      { field: "Secondary Payer Plan ID", hide: true },
      { field: "Secondary Payer Network Reimbursement ID", hide: true },
      { field: "Tertiary Plan Type", hide: true },
      { field: "Secondary Payer Processor", hide: true },
      { field: "Tertiary Payer Name", hide: true },
      { field: "Tertiary Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Tertiary Payer Name (Pharmacy-Defined)", hide: true },
      { field: "Tertiary BIN", hide: true },
      { field: "Tertiary PCN", hide: true },
      { field: "Tertiary Group ID", hide: true },
      { field: "Secondary Payer Plan ID", hide: true },
      { field: "Secondary Payer Network Reimbursement ID", hide: true },
    ],
  },
  { field: "Fill Count", aggFunc: "sum", hide: true },
  { field: "Fill ID (PharmacyNPI-RxNumber-Refill)", hide: true },
  { field: "Patient ID", hide: true },
];

export const DETAIL_CELL_RENDERER_PARAMS = getDetailCellRendererParams({
  detailGridOptions: {
    columnDefs: [
      { field: "Billed Drug Name" },
      { field: "Billed NDC" },
      { field: "Transmission Time", sort: "asc" },
      { field: "Claim Adjudication Date" },
      { field: "Claim Number (By Billing Order)" },
      { field: "Claim Number (All Billing Orders)", sort: "asc" },
      { field: "Was Claim Reversed" },
      { field: "Payer Billing Order" },
      { field: "Plan Type" },
      { field: "Processor", hide: true },
      { field: "Payer Name", hide: true },
      { field: "Plan Type (Pharmacy-Defined)", hide: true },
      { field: "Payer Name (Pharmacy-Defined)", hide: true },
      { field: "BIN" },
      { field: "PCN" },
      { field: "Group ID" },
      { field: "Plan ID", hide: true },
      { field: "Network Reimbursement ID", hide: true },
      { field: "OCC Code", headerName: "OCC (Claim Level)" },
      { field: "Response" },
      { field: "Rejection Codes", tooltipField: "Rejection Codes" },
      { field: "Approval Codes", tooltipField: "Approval Codes" },
      {
        field: "Response Message",
        tooltipField: "Response Message",
      },
      {
        field: "Additional Claim Response Info",
        tooltipField: "Additional Claim Response Info",
      },
      {
        field: "Payer Suggested Alternatives",
        tooltipField: "Payer Suggested Alternatives",
      },
      {
        field: "Patient Pay Amount (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Patient Copay",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Coinsurance Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Deductible Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Coverage Gap Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Other Payer Recognized Amount",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Pharmacy Copay Buydown",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Total Remittance (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Manufacturer Contribution (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      {
        field: "Third Party Contribution (Claim Level)",
        valueFormatter: (params) =>
          manufacturerReportCurrencyFormatter(params.value),
      },
      { field: "Claim ID" },
    ],
  },
});

export const CSV_HEADERS = [
  "Rx Written Date",
  "Prescribed NDC",
  "Prescribed Drug Name",
  "Prescriber Name",
  "Prescriber NPI",
  "Prescriber Zip",
  "Pharmacy Name",
  "Pharmacy NPI",
  "Dispensed Quantity",
  "DAW Code",
  "BIN",
  "PCN",
  "Group ID",
  "Plan Type (Pharmacy-Defined)",
  "Payer Name (Pharmacy-Defined)",
  "Claim Adjudication Date",
  "Fill Adjudication Date",
  "Rx ID",
  "Fill ID (PharmacyNPI-RxNumber-Refill)",
  "Claim ID",
  "Patient ID",
  "Billed NDC",
  "Billed Drug Name",
  "Transmission Time",
  "Payer Billing Order",
  "Claim Number (By Billing Order)",
  "Claim Number (All Billing Orders)",
  "Refill Number",
  "Response",
  "Was Claim Reversed",
  "Dispense Status",
  "OCC Code",
  "Rejection Codes",
  "Approval Codes",
  "Additional Claim Response Info",
  "Payer Suggested Alternatives",
  "Patient Pay Amount",
  "Patient Copay",
  "Coinsurance Amount",
  "Deductible Amount",
  "Pharmacy Copay Buydown",
  "Total Remittance",
  "Manufacturer Contribution",
  "Third Party Contribution",
  "Final Patient Pay",
  "Fill Total Remittance",
  "Fill Manufacturer Contribution",
  "Fill Third Party Contribution",
];
