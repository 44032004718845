import type { Supplier } from "../../../../../../../utilities/types";
import type { ShippingFeeData } from "../../../../../../../utilities/shipping/shipping.constants";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../../../utilities/numbers/currency";
import { getMinShipDescription } from "../../../../../../../utilities/shipping/shipping";

export function buildSupplierColShippingWarning({
  supplier,
  shippingFeeSimulation,
}: {
  supplier: Supplier;
  shippingFeeSimulation?: {
    shippingFeeDataSimulation: ShippingFeeData;
    initialShippingInfo?: ShippingFeeData;
  };
}) {
  if (!shippingFeeSimulation) return;

  const { initialShippingInfo, shippingFeeDataSimulation } =
    shippingFeeSimulation;
  const { shippable, shippingFee } = shippingFeeDataSimulation;

  if (shippable) {
    if (!shippingFee) return;
    if (initialShippingInfo?.shippingFee) return;

    const shippingFeeToDisplay =
      currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(shippingFee);
    return `Note: This change will add a ${shippingFeeToDisplay} shipping fee`;
  }

  const minShipDescription = getMinShipDescription(supplier, {
    ship: true,
    abbrev: true,
  });
  return `Caution: will not reach ${supplier.displayName}'s ${minShipDescription}`;
}
