import { useCallback, useState } from "react";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import type { PaginationConfig, UpdatePagination } from "./pagination.utils";

export function usePagination({
  initialPagination,
}: {
  initialPagination?: PaginationConfig;
} = {}) {
  const [pagination, setPagination] = useState(initialPagination);

  const updatePagination: UpdatePagination = useCallback(
    (value: Partial<PaginationConfig>) => {
      setPagination((prevPagination) => {
        const newPagination = {
          ...prevPagination,
          ...pick(value, [
            "pageSize",
            "totalRows",
            "currentPage",
            "totalPageCount",
          ]),
        } as PaginationConfig;

        if (isEqual(newPagination, prevPagination)) return prevPagination;
        return newPagination;
      });
    },
    []
  );

  const resetPaginationPage = useCallback(() => {
    updatePagination({ currentPage: 1 });
  }, [updatePagination]);

  return { pagination, updatePagination, resetPaginationPage };
}
