import isObject from "lodash/isObject";

export async function jsonValidator(rule: any, value: any) {
  if (typeof value === "string") {
    try {
      JSON.parse(value);
    } catch (e) {
      throw new Error("${label} should be a valid JSON");
    }
  }

  if (!isObject(value)) {
    throw new Error("${label} should be a valid JSON");
  }
}
