import { dayjsWithIsBetween } from "utilities/dates/dayjsWithIsBetween";
import { getHourWithTimezoneInLocalTime } from "utilities/dates/getHourWithTimezoneInLocalTime";

function getFFFOvernightShippingStartHour() {
  return getHourWithTimezoneInLocalTime(15, "America/New_York").startOf("hour");
}

function getFFFOvernightShippingEndHour() {
  return getHourWithTimezoneInLocalTime(18, "America/New_York").startOf("hour");
}

export function getFFFOvernightShippingEndHourStr() {
  const endHour = getFFFOvernightShippingEndHour();
  const hour = endHour.format("h A");
  return hour;
}

export function shouldDisplayFFFOvernightShippingMsg() {
  const startHour = getFFFOvernightShippingStartHour();
  const endHour = getFFFOvernightShippingEndHour();
  const now = dayjsWithIsBetween();
  const displayMsg = now.isBetween(startHour, endHour, "hour", "[]");
  return displayMsg;
}
