import qs from "qs";

/**
 * Our BE don't support query params arrays with the [] syntax, so we need to serialize them manually
 * Example: {arr: [1,2], q: 3} => arr=1&arr=2&q=3
 * Example axios default no supported: {arr: [1,2], q: 3} => arr[]=1&arr[]=2&q=3
 */
export function paramsSerializer(params: Record<string, any>) {
  return qs.stringify(params, { indices: false });
}
