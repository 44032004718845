import type { Quotes } from "../utilities/types";
import { backendFetch } from "./legacy/config/backend-api";
import { RequestClient, RequestClientConfig } from "./request/requestClient";

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getQuotes_deprecated(
  ndc: string,
  pharmacyId: number,
  genericProductIdentifier: string | null,
  drugName: string | null,
  token: string,
  unitSize?: string | null
) {
  const params: {
    ndc: string;
    pharmacy_id: string;
    generic_product_identifier?: string;
    drug_name?: string;
    unit_size?: string;
  } = {
    ndc: ndc.replace(/\D+/g, ""),
    pharmacy_id: pharmacyId.toString(),
  };
  if (genericProductIdentifier) {
    params.generic_product_identifier = genericProductIdentifier;
  }
  if (drugName) {
    params.drug_name = drugName;
  }
  if (unitSize) {
    params.unit_size = unitSize;
  }

  return await backendFetch<Quotes>(`/quotes?${new URLSearchParams(params)}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

export async function getQuotes(
  client: RequestClient,
  {
    ndc,
    drugName,
    unitSize,
    pharmacyId,
    genericProductIdentifier,
    ...config
  }: RequestClientConfig<{
    ndc: string;
    pharmacyId: number;
    drugName?: string;
    unitSize?: string;
    genericProductIdentifier?: string;
  }>
) {
  return client<Quotes>({
    ...config,
    url: "/quotes",
    params: {
      ndc: ndc.replace(/\D+/g, ""),
      pharmacyId,
      genericProductIdentifier,
      drugName,
      unitSize,
    },
  });
}
