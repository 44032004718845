import React, { useContext, useEffect, useState } from "react";
import "twin.macro";
import dayjs from "dayjs";
import Barcode from "react-jsbarcode";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import { Container } from "../../components/containers/Container";
import { Title, Text } from "../../components/rxLibrary/typography";
import { SimpleTable } from "../../components/rxLibrary/tables";
import { formatNdc } from "../../utilities/ndc/formatNdc";
import { isInventoryId } from "../../utilities/prescriptions/isInventoryId";
import { LONG_DATE_FORMAT } from "../../utilities/dates/dates.constants";
import { formatDrugNameWithDetails } from "../../utilities/drugInfo/formatDrugNameWithDetails";
import { PRINTABLE_CART_KEY } from "../../utilities/localStorage/constants";
import { getLocalStoragePrintableCart } from "../../utilities/localStorage/printableCart/getLocalStoragePrintableCart";
import { formatDrugDispenseQuantity } from "../../utilities/drugInfo/formatDrugDispenseQuantity";
import { currencyWithCentsAtAllTimes } from "../../utilities/numbers/currency";
import {CatalogInfo, DrugInfo} from "../../utilities/types";

const BARCODE_OPTIONS = {
  format: "CODE128",
  height: 50,
  displayValue: true,
  fontSize: 14,
  textAlign: "left",
  margin: 0,
};

type TableItem = {
  rxNumbers: string[];
  referenceData: {
    drugInfo: DrugInfo;
    catalogInfo?: CatalogInfo;
  };
  numPackages?: number;
}

function TableItems({ items }: { items: TableItem[] }) {
  return (
    <SimpleTable>
      <thead>
        <tr>
          <th>Item</th>
          <th tw="w-[130px]">Buying for</th>
          <th tw="w-[333px]">Item Number</th>
          <th tw="w-[99px]">NDC</th>
          <th tw="w-[65px]">Quantity</th>
          <th tw="w-[130px]">Price</th>
        </tr>
      </thead>

      <tbody>
        {items.map((item, index) => {
          const { rxNumbers, referenceData, numPackages } = item;
          // Temporary fix for missing referenceData during transition
          if (!referenceData) {
            return <tr key={`missing-ref-data-${index}`}>
              <td colSpan={6} tw="text-center">Data temporarily unavailable, please retry printing or contact DaylightRx support if this persists.</td>
            </tr>;
          }
          const { supplierItemNumber, price } = referenceData.catalogInfo ?? {};
          const { ndc, manufacturer } = referenceData.drugInfo;

          return (
            <tr key={`${manufacturer}-${ndc}-${numPackages}`}>
              <td>
                <Text block>{formatDrugNameWithDetails(referenceData.drugInfo)}</Text>
                <Text block>{formatDrugDispenseQuantity(referenceData.drugInfo)}</Text>
                <Text block>{manufacturer}</Text>
              </td>

              <td>
                {rxNumbers.map((rxNumber) => (
                  <Text key={rxNumber} block>
                    {isInventoryId(rxNumber) ? "Inventory" : `Rx ${rxNumber}`}
                  </Text>
                ))}
              </td>

              <td>
                {!!supplierItemNumber && (
                  <Barcode
                    value={supplierItemNumber}
                    options={BARCODE_OPTIONS}
                  />
                )}
              </td>

              <td>{formatNdc(ndc)}</td>

              <td>{numPackages}</td>

              <td>{!!price && currencyWithCentsAtAllTimes(price)}</td>
            </tr>
          );
        })}
      </tbody>
    </SimpleTable>
  );
}

export function ShoppingListPrint() {
  const { currentBuyingPharmacy, getSupplierById } = useContext(
    BuyingPharmacyContext
  );
  const [printableCart, setPrintableCart] = useState(
    getLocalStoragePrintableCart
  );
  const { supplierOrders, unavailable } = printableCart ?? {};

  useEffect(() => {
    function updateData(event: StorageEvent) {
      if (event.key !== PRINTABLE_CART_KEY) return;
      const newPrintableCart = getLocalStoragePrintableCart();
      setPrintableCart(newPrintableCart);
    }

    addEventListener("storage", updateData);

    return () => {
      removeEventListener("storage", updateData);
    };
  }, []);

  if (!currentBuyingPharmacy) return null;
  if (!supplierOrders) {
    return (
      <Container tw="py-16">
        <Title block center>
          Error generating printable page
        </Title>
      </Container>
    );
  }

  return (
    <Container tw="py-16">
      <Text block tw="mb-16" size="x-large" color="grey-1">
        Shopping List - {dayjs().format(LONG_DATE_FORMAT)}
      </Text>

      <div tw="space-y-4">
        {supplierOrders.map((supplierOrder) => {
          const { supplierId, items } = supplierOrder;
          const supplier = getSupplierById(supplierId);

          if (!supplier) return null;
          return (
            <div key={supplierId}>
              <Title block tw="mb-4">
                {supplier.displayName}
              </Title>

              <TableItems items={items} />
            </div>
          );
        })}

        {!!unavailable && (
          <div>
            <Title block tw="mb-4">
              Unavailable Items
            </Title>

            <TableItems items={unavailable} />
          </div>
        )}
      </div>
    </Container>
  );
}
