function formatCurrency(
  value: number,
  options?: {
    roundValue?: boolean;
    keepZeroCents?: boolean;
    omitDollarSign?: boolean;
    fractionOfPenny?: boolean;
  }
): string {
  const { roundValue, keepZeroCents, omitDollarSign, fractionOfPenny } =
    options ?? {};

  const baseValue = roundValue ? Math.round(value) : value;
  const fractionDigits = fractionOfPenny ? 3 : 2;

  let result = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(baseValue);

  if (!keepZeroCents) {
    const zeros = fractionOfPenny ? ".000" : ".00";
    result = result.replace(zeros, "");
  }

  if (omitDollarSign) {
    result = result.replace("$", "");
  }

  return result;
}

/**
 *  Format example:
 *    234.49 => $235.49
 *    234.5 => $235.50
 *    234 => $234
 *    234.99 => $234.99
 *    12000 => $12,000
 *    12000.1 => $12,000.10
 **/
export function currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(
  value: number,
  omitDollarSign = false
) {
  const currency = formatCurrency(value, {
    omitDollarSign,
    roundValue: false,
    keepZeroCents: false,
    fractionOfPenny: false,
  });

  return currency;
}

/**
 *  Format example:
 *    234.50 => $235
 *    234.01 => $234
 *    234.99 => $235
 *    234.49 => $234
 *    12000.01 => $12,000
 **/
export function currencyRoundedToNearestDollar(
  value: number,
  omitDollarSign = false
) {
  const newValue = formatCurrency(value, {
    omitDollarSign,
    roundValue: true,
    keepZeroCents: false,
    fractionOfPenny: false,
  });
  return newValue;
}

/**
 *  Format example:
 *    234.49 => $235.49
 *    234.5 => $235.50
 *    234 => $234.00
 *    234.99 => $234.99
 *    12000 => $12,000.00
 *    12000.1 => $12,000.10
 **/
export function currencyWithCentsAtAllTimes(
  value: number,
  omitDollarSign = false
) {
  const newValue = formatCurrency(value, {
    omitDollarSign,
    roundValue: false,
    keepZeroCents: true,
    fractionOfPenny: false,
  });
  return newValue;
}

/**
 *  Format example:
 *    234.491 => $235.491
 *    234.5 => $235.500
 *    234 => $234.000
 *    234.99 => $234.990
 *    12000 => $12,000
 *    12000.1 => $12,000
 **/
export function currencyWithFractionOfPenny(
  value: number,
  omitDollarSign = false
) {
  const isMoreThanThousand = value >= 1000;
  const roundValue = isMoreThanThousand;
  const keepZeroCents = !isMoreThanThousand;

  const newValue = formatCurrency(value, {
    roundValue,
    keepZeroCents,
    omitDollarSign,
    fractionOfPenny: true,
  });
  return newValue;
}

/**
 *  Format example:
 *    234.49 => $235.49
 *    234.5 => $235.50
 *    234 => $234.00
 *    234.99 => $234.99
 *    12000 => $12,000
 *    12000.1 => $12,000
 **/
export function currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(
  value: number,
  omitDollarSign = false
) {
  const isMoreThanThousand = value >= 1000;
  const roundValue = isMoreThanThousand;
  const keepZeroCents = !isMoreThanThousand;

  const newValue = formatCurrency(value, {
    roundValue,
    keepZeroCents,
    omitDollarSign,
    fractionOfPenny: false,
  });
  return newValue;
}
