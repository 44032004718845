import {
  BackendResponse,
  backendFetch,
  backendPost,
} from "./config/backend-api";
import {
  AdminPharmacyOrganization,
  UpsertAdminPharmacyOrganization,
} from "../../utilities/types";

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacyOrganization(
  token: string,
  pharmacyOrganizationId: string
) {
  const response = await backendFetch<{
    pharmacyOrganization: AdminPharmacyOrganization;
  }>(`/admin/pharmacy-organization/${pharmacyOrganizationId}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin pharmacy organization", response);
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminPharmacyOrganizations(token: string) {
  const response = await backendFetch<{
    pharmacyOrganizations: AdminPharmacyOrganization[];
  }>("/admin/pharmacy-organization", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin pharmacy organizations", response);
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function createAdminPharmacyOrganization(
  token: string,
  data: UpsertAdminPharmacyOrganization
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    "/admin/pharmacy-organization",
    payload,
    {
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    pharmacyOrganization: AdminPharmacyOrganization;
  }>;

  if (response.error) {
    console.error(
      "Error creating admin pharmacy organization",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/** 
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateAdminPharmacyOrganization(
  token: string,
  pharmacyOrganizationId: string,
  data: UpsertAdminPharmacyOrganization
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/pharmacy-organization/${pharmacyOrganizationId}`,
    payload,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    pharmacyOrganization: AdminPharmacyOrganization;
  }>;

  if (response.error) {
    console.error(
      "Error updating admin pharmacy organization",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}
