import React, { useMemo } from "react";
import "twin.macro";
import { Button } from "../../../../../../components/rxLibrary/buttons";
import { CatalogInfo } from "../../../../../../utilities/types";
import { normalizePackQuantity } from "../../../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";

export function ButtonCol({
  text,
  quantity,
  catalogInfo,
  onClick,
}: {
  text: string;
  quantity: number;
  catalogInfo?: CatalogInfo;
  onClick: () => void;
}) {
  const { bundleSize } = catalogInfo?.directDeal ?? {};

  const numPackages = useMemo(() => {
    return normalizePackQuantity(quantity);
  }, [quantity]);

  const disabledByBundleSizeQuantity =
    !!bundleSize && numPackages % bundleSize !== 0;

  return (
    <div tw="w-[150px] px-[12px]">
      <Button
        fullWidth
        disabled={disabledByBundleSizeQuantity}
        onClick={onClick}
      >
        {text}
      </Button>
    </div>
  );
}
