import React, { useMemo } from "react";
import type { ItemInCart } from "../../../../utilities/types";
import { formatFillDate } from "../../../../utilities/prescriptions/formatFillDate";
import { Text } from "../../../rxLibrary/typography";

export function FillDateCol({ prescription }: { prescription: ItemInCart }) {
  const fillDate = useMemo(() => formatFillDate(prescription), [prescription]);

  return (
    <Text weight="bold" block>
      {fillDate || ""}
    </Text>
  );
}
