import React from "react";
import "twin.macro";
import { Modal } from "antd";
import { Text } from "../../components/rxLibrary/typography";

export function displayErrorModal({
  title = "There was a problem",
  email = "support@daylightrx.com",
  content,
}: {
  title?: string;
  email?: string;
  content?: React.ReactNode;
}) {
  Modal.error({
    title,
    width: 450,
    content: content || (
      <Text>
        Please try again or contact us via the orange chat widget below or at{" "}
        <Text tw="underline" weight="bold" color="blue-1">
          <a href={`mailto:${email}`}>{email}</a>
        </Text>
      </Text>
    ),
  });
}
