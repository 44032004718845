import dayjs from "dayjs";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";
import type {
  AdminRefillMapping,
  AdminRefillMappingOptions,
} from "../utilities/types";

export async function getAdminRefillMappings(
  client: RequestClient,
  config: RequestClientConfig
) {
  return client<{ refillMappings: AdminRefillMapping[] }>({
    ...config,
    url: "/admin/refill-mapping",
  });
}

export async function getAdminRefillMappingOptions(
  client: RequestClient,
  config: RequestClientConfig
) {
  return client<{
    refillMappingOptions: AdminRefillMappingOptions;
  }>({
    ...config,
    url: "/admin/refill-mapping/options",
  });
}

export async function createAdminRefillMapping(
  client: RequestClient,
  {
    pharmacyId,
    manufacturerId,
    reportStartDate: reportStartDateRaw,
    ...config
  }: RequestClientConfig<{
    pharmacyId: number;
    manufacturerId: number;
    reportStartDate?: string | null;
  }>
) {
  let reportStartDate: string | null = null;
  if (reportStartDateRaw) {
    const date = dayjs(reportStartDateRaw);
    if (date.isValid()) reportStartDate = date.format("YYYY-MM-DD");
  }

  return client<{ data: void }>({
    ...config,
    url: "/admin/refill-mapping",
    method: "POST",
    data: { pharmacyId, manufacturerId, reportStartDate },
  });
}

export async function deleteAdminRefillMapping(
  client: RequestClient,
  {
    pharmacyId,
    manufacturerId,
    ...config
  }: RequestClientConfig<{
    pharmacyId: number;
    manufacturerId: number;
  }>
) {
  return client<{ data: void }>({
    ...config,
    url: "/admin/refill-mapping",
    method: "DELETE",
    data: { pharmacyId, manufacturerId },
  });
}
