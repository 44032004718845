import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useReport } from "../../../../Manufacturer/ManufacturerReports/reports/utils/useReport/useReport";
import { Text } from "../../../../../components/rxLibrary/typography";
import { TableWrapper } from "../../TableWrapper";
import type { PharmacyPaymentsTable } from "../PharmacyPayments.types";
import { COLUMN_DEFS } from "./PharmacyPaymentsTable.constants";

export function PharmacyPaymentsTable({
  data,
}: {
  data?: PharmacyPaymentsTable[];
}) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
  });

  return (
    <>
      <Text size="x-large" block tw="pt-4 pl-4 pb-6">
        Pharmacy Payments
      </Text>
      <TableWrapper>
        <AgGridReact
          {...report}
          pagination
          rowData={data ?? []}
          rowSelection="multiple"
          multiSortKey="ctrl"
          alwaysMultiSort={true}
        />
      </TableWrapper>
    </>
  );
}
