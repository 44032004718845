import type { Supplier } from "../../../../utilities/types";
import type { ShippingFeeData } from "../../../../utilities/shipping/shipping.constants";
import { getMinShipDescription } from "../../../../utilities/shipping/shipping";

export function buildSuppliersModalShippingWarning({
  supplier,
  shippingFeeSimulation,
}: {
  supplier: Supplier;
  shippingFeeSimulation?: {
    shippingFeeDataSimulation: ShippingFeeData;
    initialShippingInfo?: ShippingFeeData;
  };
}) {
  if (!shippingFeeSimulation) return;

  const { initialShippingInfo, shippingFeeDataSimulation } =
    shippingFeeSimulation;
  const { shippable } = shippingFeeDataSimulation;

  if (shippable) return;
  if (!!initialShippingInfo && !initialShippingInfo?.shippable) return;

  const { displayName } = supplier;
  const minShipDescription = getMinShipDescription(supplier, { ship: true });
  return `Caution: By selecting another supplier you will miss ${displayName}'s ${minShipDescription}`;
}
