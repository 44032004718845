import React, { useCallback, useState } from "react";
import "twin.macro";
import { Button } from "antd";
import FullscreenExitOutlined from "@ant-design/icons/FullscreenExitOutlined";
import FullscreenOutlined from "@ant-design/icons/FullscreenOutlined";

export function useFullScreenButton() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = useCallback(() => {
    setIsFullScreen((prevIsFullScreen) => !prevIsFullScreen);
  }, []);

  const fullScreenButton = (
    <Button
      onClick={toggleFullScreen}
      icon={isFullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
      shape="round"
      size="large"
      tw="flex items-center"
    >
      {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
    </Button>
  );

  return { isFullScreen, fullScreenButton };
}
