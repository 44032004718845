import React, { useCallback } from "react";
import { Modal } from "antd";
import { useHistory } from "react-router-dom";
import FileTextOutlined from "@ant-design/icons/FileTextOutlined";
import PrinterOutlined from "@ant-design/icons/PrinterOutlined";
import { useStorageState } from "../../../../contexts/shoppingContexts/StorageContext";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../../../../contexts/shoppingContexts/ShoppingCartServerContext/ShoppingCartServerContext";
import { Box } from "../../../../components/rxLibrary/box";
import { Button } from "../../../../components/rxLibrary/buttons";
import { useModal } from "../../../../components/rxLibrary/modals";
import { Title } from "../../../../components/rxLibrary/typography";
import { useShoppingListPrintBtnProps } from "../useShoppingListPrintBtnProps";

export function ShoppingBarDoneModalBtn() {
  const history = useHistory();
  const { waitButtonMode } = useStorageState();
  const { initialShoppingPath } = useShoppingCartServerState();
  const { pushBlob } = useShoppingCartServerUpdater();
  const printBtnProps = useShoppingListPrintBtnProps();
  const { isModalOpen, openModal, closeModal } = useModal();

  const handleComplete = useCallback(async () => {
    await pushBlob({
      force: true,
      cb: () => {
        history.push(initialShoppingPath);
      },
    });
  }, [initialShoppingPath, pushBlob]);

  return (
    <>
      <Button disabled={waitButtonMode} onClick={openModal}>
        Done Purchasing
      </Button>

      {isModalOpen && (
        <Modal open footer={null} onCancel={closeModal}>
          <div tw="flex flex-col justify-center items-center py-8">
            <Title tw="mb-10" block>
              Done Purchasing?
            </Title>

            <Box
              tw="flex flex-col items-center mb-2 py-6 px-10 rounded-lg w-[360px]"
              bgColor="grey-4"
            >
              <FileTextOutlined
                tw="mb-2"
                style={{ fontSize: 100 }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              />

              <Button
                className="print-shopping-list"
                {...printBtnProps}
                fullWidth
                icon={
                  <PrinterOutlined
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                }
              >
                Print Shopping List for your records
              </Button>
            </Box>

            <Box
              tw="flex justify-center mb-2 py-6 px-10 rounded-lg w-[360px]"
              bgColor="grey-4"
            >
              <Button fullWidth variant="secondary" onClick={handleComplete}>
                Go to shopping list main page
              </Button>
            </Box>

            <Button variant="text-2" onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
