import React, {useEffect, useState} from "react";

export function ProgressDots() : JSX.Element | null {
  const [dotCount, setDotCount] = useState<number>(0);

  useEffect(() => {
    const dotInterval = setInterval(() => {
      setDotCount((prev) =>(prev + 1) % 3);
    }, 1500);
    return () => {
      clearInterval(dotInterval);
    }
  }, []);

  return <>
    .{".".repeat(dotCount)}<span className="color-white">{([...Array(2-dotCount)].map((_v, i) => <React.Fragment key={i}>.</React.Fragment>))}</span>
  </>
}
