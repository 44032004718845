import { FullPageLoader } from "../loaders/FullPageLoader";
import { useBuyingPharmacy } from "../../contexts/BuyingPharmacyContext";
import { WideContainer } from "../containers/WideContainer";
import { useModal } from "../rxLibrary/modals";
import { RefillOpportunitiesTable } from "./RefillOpportunitiesTable/RefillOpportunitiesTable";
import { RefillOpportunitiesHeader } from "./RefillOpportunitiesHeader/RefillOpportunitiesHeader";
import { RefillOpportunitiesAcquisitionCost } from "./RefillOpportunitiesAcquisitionCost/RefillOpportunitiesAcquisitionCost";
import { RefillOpportunitiesProvider } from "./useRefillOpportunities";

function RefillOpportunitiesContent() {
  const { isModalOpen, openModal, closeModal } = useModal();

  return (
    <WideContainer tw="pt-[68px] pb-[200px]">
      <RefillOpportunitiesHeader openModal={openModal} />

      <RefillOpportunitiesTable />

      {isModalOpen && <RefillOpportunitiesAcquisitionCost close={closeModal} />}
    </WideContainer>
  );
}

export function RefillOpportunities() {
  const { currentBuyingPharmacy: pharmacy } = useBuyingPharmacy();

  if (!pharmacy) return <FullPageLoader />;
  return (
    <RefillOpportunitiesProvider pharmacy={pharmacy}>
      <RefillOpportunitiesContent />
    </RefillOpportunitiesProvider>
  );
}
