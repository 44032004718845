import React, { useMemo } from "react";
import { css, styled } from "twin.macro";
import { DirectDeal } from "../../utilities/types";
import { GREY_1, RED_1, WHITE_1 } from "../rxLibrary/colors";
import { Text, getTextCss } from "../rxLibrary/typography";
import { roundDecimalsDigits } from "../../utilities/numbers/roundDecimalsDigits";

const DirectDealTag = styled.div(
  ({ missingDeal }: { missingDeal: boolean }) => css`
    ${getTextCss({ weight: "bold" })};
    display: inline-block;
    font-size: 12px;
    padding: 1px 6px;
    border-radius: 3px;
    color: ${WHITE_1};
    background-color: ${missingDeal ? RED_1 : GREY_1};
  `
);

export function ManufacturerDirectDeal({
  directDeal,
  packQuantity,
  descriptive = false,
}: {
  directDeal: DirectDeal;
  packQuantity: number;
  descriptive?: boolean;
}) {
  const { discountPercent, bundleSize, notes } = directDeal;
  const missingDeal = packQuantity < bundleSize;

  const notice = useMemo(() => {
    if (packQuantity < bundleSize) {
      return `Buy in bundles of ${bundleSize}`;
    }

    if (packQuantity % bundleSize === 0) {
      if (!descriptive) return;
      return `For bundles of ${bundleSize}`;
    }

    if (descriptive) {
      return `Quantity of ${bundleSize} qualifies. The rest of your order will be directed to other suppliers`;
    }

    return `For ${bundleSize} packs of ${packQuantity}`;
  }, [bundleSize, descriptive, packQuantity]);

  const tag = useMemo(() => {
    const formattedDiscountPercent = roundDecimalsDigits(discountPercent, 1);
    if (missingDeal) {
      return descriptive
        ? `Don't miss WAC -${formattedDiscountPercent}% Manuf Deal`
        : `Don't miss WAC -${formattedDiscountPercent}% Deal`;
    }

    return `WAC -${formattedDiscountPercent}% Manuf Deal`;
  }, [missingDeal, discountPercent]);

  return (
    <div tw="space-y-[3px]">
      <DirectDealTag missingDeal={missingDeal}>{tag}</DirectDealTag>
      {!!notice && <Text block>{notice}</Text>}
      {descriptive && !!notes && <Text block>({notes})</Text>}
    </div>
  );
}
