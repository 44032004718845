import { useEffect, useState } from "react";
import p from "pluralize";
import { Box } from "../../../components/rxLibrary/box";
import { Text } from "../../../components/rxLibrary/typography";
import { useShoppingRx } from "./useShoppingRx/useShoppingRx";
import {
  ADD_RX_FADE_IN_ANIMATION_CSS,
  ADD_RX_FADE_OUT_ANIMATION_CSS,
  sequenceActions,
} from "./ShoppingRx.utils";

export function ShoppingRxItemsAddedBanner() {
  const { itemsAddedBanner } = useShoppingRx();
  const [message, setMessage] = useState<string>();
  const [fadeOutAnimation, setFadeOutAnimation] = useState(false);

  useEffect(() => {
    if (!itemsAddedBanner) return;

    const [type, counter] = itemsAddedBanner;
    const description =
      type === "prescription" ? "prescription" : "inventory item";
    const descriptionStr = p(description, counter);

    const newMessage = `${counter} ${descriptionStr} added`;
    setFadeOutAnimation(false);
    setMessage(newMessage);
  }, [itemsAddedBanner]);

  useEffect(() => {
    if (itemsAddedBanner) return;

    const cancelSequence = sequenceActions([
      [() => setFadeOutAnimation(true)],
      [() => setMessage(undefined), 500],
    ]);

    return cancelSequence;
  }, [itemsAddedBanner]);

  if (!message) return null;
  return (
    <Box
      tw="rounded-md flex items-center space-x-[6px] py-[10px] px-[38px]"
      bgColor="grey-4"
      css={
        fadeOutAnimation
          ? ADD_RX_FADE_OUT_ANIMATION_CSS
          : ADD_RX_FADE_IN_ANIMATION_CSS
      }
    >
      <img src="/assets/shopping/megaphone.svg" alt="megaphone" />

      <Text size="large">{message}</Text>
    </Box>
  );
}
