import { useRef, useMemo, useEffect, useState } from "react";
import throttle from "lodash/throttle";
import type { ManufacturerStoreItem } from "../Catalog.constants";

export function useStickyShoppingCart(cartItems: ManufacturerStoreItem[]) {
  const cartRef = useRef<HTMLDivElement>(null);
  const itemsWrapperRef = useRef<HTMLDivElement>(null);
  const [hasScrollOverlay, setHasScrollOverlay] = useState(false);

  const updateHasScrollOverlay = useMemo(() => {
    return throttle(() => {
      const newHasScrollOverlay = displayScrollOverlay(itemsWrapperRef.current);
      setHasScrollOverlay(newHasScrollOverlay);
    }, 100);

    function displayScrollOverlay(itemsWrapper?: HTMLElement | null) {
      if (!itemsWrapper) return false;

      const isScrollable =
        itemsWrapper.scrollHeight > itemsWrapper.clientHeight;
      if (!isScrollable) return false;

      const isAtBottom =
        Math.abs(
          itemsWrapper.scrollHeight -
            itemsWrapper.scrollTop -
            itemsWrapper.clientHeight
        ) < 1;

      return !isAtBottom;
    }
  }, []);

  const updateMaxCartHeight = useMemo(() => {
    return throttle(() => {
      const cartEl = cartRef.current;
      const cartParentEl = cartRef.current?.parentElement;
      if (!cartEl || !cartParentEl) return;

      const windowHeight = window.innerHeight;
      const cartPositionTop = cartEl.getBoundingClientRect().top;

      if (cartPositionTop <= 0) {
        const parentPositionBottom =
          cartParentEl.getBoundingClientRect().bottom;
        const maxHeight =
          Math.min(
            windowHeight - (windowHeight - parentPositionBottom),
            windowHeight
          ) - 20;
        cartEl.style.maxHeight = `${maxHeight}px`;
      } else {
        const maxHeight = windowHeight - cartPositionTop - 20;
        cartEl.style.maxHeight = `${maxHeight}px`;
      }
    }, 200);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", updateMaxCartHeight);
    window.addEventListener("resize", updateMaxCartHeight);
    window.addEventListener("resize", updateHasScrollOverlay);

    return () => {
      window.removeEventListener("scroll", updateMaxCartHeight);
      window.removeEventListener("resize", updateMaxCartHeight);
      window.removeEventListener("resize", updateHasScrollOverlay);
    };
  }, []);

  useEffect(() => {
    updateMaxCartHeight();
    updateHasScrollOverlay();
  }, [cartItems]);

  return { cartRef, itemsWrapperRef, hasScrollOverlay, updateHasScrollOverlay };
}
