import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import cn from "classnames";
import "../scss/base.scss";
import "../scss/dropdown.scss";

/** @deprecated use rxLibrary QtySelect */
export type IDropDownItem = {
  label: string;
  value?: string | number;
  hidden?: boolean;
  disabled?: boolean;
  warning?: string;
};

/** @deprecated use rxLibrary QtySelect */
export const DROPDOWN_QUANTITY_LIST_ITEMS = getQtyList();

/** @deprecated use rxLibrary QtySelect */
export function getQtyList(
  zero?: boolean,
  zeroLabel?: string
): IDropDownItem[] {
  const arr = [...Array(120)].map((_, index) => {
    return {
      label: (index + 1).toString(),
      value: (index + 1).toString(),
    };
  });

  if (zero) {
    arr.unshift({ label: zeroLabel || "0", value: "0" });
  }

  return arr;
}

/** @deprecated use rxLibrary QtySelect */
export function DropDownListQty({
  label,
  className,
  name,
  value,
  dropDownItems,
  disabled = false,
  standOutValue,
  callBack,
}: {
  value: string;
  name?: string;
  label?: string;
  className?: string;
  dropDownItems: IDropDownItem[];
  disabled?: boolean;
  standOutValue?: string;
  callBack: (value: string) => void;
}) {
  return (
    <div className={cn("mui-dropdown", className)}>
      {label && (
        <span
          className={cn(disabled && "disabled")}
          style={{ maxWidth: "60px", lineHeight: "16px" }}
        >
          {label}
        </span>
      )}

      <div>
        <Select
          disabled={disabled}
          name={name}
          value={value}
          sx={{ color: value === standOutValue ? "red" : "black" }}
        >
          {dropDownItems?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => {
                callBack && callBack(option.value as string);
              }}
            >
              {option.label ?? option.value}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}
