import React, { ComponentProps } from "react";
import { Dropdown as BaseDropdown, MenuProps } from "antd";
export type { MenuProps } from "antd";
import { Global, css } from "@emotion/react";
import { DEFAULT_TEXT_CSS } from "./typography";
import { GREY_2, GREY_3 } from "./colors";

const RX_DROPDOWN_OVERLAY_CLASS_NAME = "rx-dropdown-overlay";

export type DropdownMenuOptions = NonNullable<
  NonNullable<MenuProps["items"]>[number]
>[];

export function Dropdown(
  props: Omit<ComponentProps<typeof BaseDropdown>, "overlayClassName">
) {
  return (
    <>
      <BaseDropdown
        trigger={["click"]}
        overlayClassName={RX_DROPDOWN_OVERLAY_CLASS_NAME}
        destroyPopupOnHide
        tw="rounded-lg"
        {...props}
      />
      <Global styles={GLOBAL_CSS} />
    </>
  );
}

const GLOBAL_CSS = css`
  .${RX_DROPDOWN_OVERLAY_CLASS_NAME} {
    span {
      ${DEFAULT_TEXT_CSS}
    }

    .ant-dropdown-menu-item {
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: ${GREY_2};
      }

      &:last-child {
        &:after {
          display: none;
        }
      }

      &:hover {
        &.ant-dropdown-menu-item-active {
          background-color: ${GREY_3};
        }
      }
    }
  }
`;
