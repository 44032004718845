export function formatNdc(ndc: string): string {
  if (ndc.length !== 11) return ndc;
  
  const formattedNdc =
    ndc.substring(0, 5) +
    "-" +
    ndc.substring(5, 9) +
    "-" +
    ndc.substring(9, 11);
  return formattedNdc;
}
