import React from "react";
import type { ControlledDrugsDataType } from "./controlledDrugs.types";
import { DispensesByDeaCodeTypeTable } from "./DispensesByDeaCodeTypeTable/DispensesByDeaCodeTypeTable";
import { SupplierControlledDrugsPurchasesTable } from "./SupplierControlledDrugsPurchasesTable/SupplierControlledDrugsPurchasesTable";

export function ControlledDrugsReport({
  data,
}: {
  data?: ControlledDrugsDataType;
}) {
  if (!data) return;

  const {
    dispensesByDeaCode: { data: dispensesByDeaCode },
    supplierControlledDrugsPurchases: {
      data: supplierControlledDrugsPurchases,
    },
  } = data;

  return (
    <>
      <div tw="flex-1 h-[500px] mt-4 bg-white">
        <DispensesByDeaCodeTypeTable data={dispensesByDeaCode} />
      </div>
      <div tw="flex-1 h-[500px] mt-4 bg-white">
        <SupplierControlledDrugsPurchasesTable data={supplierControlledDrugsPurchases} />
      </div>
    </>
  );
}
