import { useContext } from "react";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { EnhancedCompare } from "./EnhancedCompare";
import { WebCompare } from "./WebCompare";

export function Compare() {
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);

  if (enhancedClientActive) return <EnhancedCompare />;
  return <WebCompare />;
}
