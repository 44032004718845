import React, { useMemo } from "react";
import "twin.macro";
import { DrugInfo, CatalogInfo } from "../../../../utilities/types";
import { Text } from "../../../rxLibrary/typography";
import { currencyWithCentsUnlessGreaterThanOrEqualToOneThousand } from "../../../../utilities/numbers/currency";
import { formatDrugDispenseQuantity } from "../../../../utilities/drugInfo/formatDrugDispenseQuantity";

export function QuantityDescriptionCol({
  drug,
  catalogInfo,
}: {
  drug: DrugInfo;
  catalogInfo?: CatalogInfo;
}) {
  const { price, isRebatedItem } = catalogInfo ?? {};

  const displayPrice = useMemo(() => {
    return (
      !!price && currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(price)
    );
  }, [price]);

  const quantityDescription = useMemo(() => {
    return formatDrugDispenseQuantity(drug);
  }, [drug]);

  return (
    <>
      {displayPrice && (
        <Text block weight="bold" size="large">
          {displayPrice}
        </Text>
      )}

      {isRebatedItem && (
        <Text block color="green-1">
          Rebated
        </Text>
      )}

      <Text block>{quantityDescription}</Text>
    </>
  );
}
