import React, {useContext, } from "react";
import EnhancedOverlayScreen from "./EnhancedOverlayScreen";
import EnhancedSettingsModal from "./EnhancedSettingsModal";
import EnhancedReportProblemModal from "./EnhancedReportProblemModal";
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";
import EnhancedOfflineSupplierModal from "./EnhancedOfflineSupplierModal";

export default function EnhancedApp(): JSX.Element | null {
    const {enhancedClientActive} = useContext(EnhancedClientConfigContext);

    if (!enhancedClientActive) {
        return null;
    }

    return <>
        <EnhancedOverlayScreen/>
        <EnhancedSettingsModal/>
        <EnhancedReportProblemModal />
        <EnhancedOfflineSupplierModal />
    </>
}
