import React, { RefObject } from "react";
import "twin.macro";
import "react-virtualized/styles.css";
import List from "react-virtualized/dist/commonjs/List";
import WindowScroller from "react-virtualized/dist/commonjs/WindowScroller";

export function ShoppingListTablePmsVirtualizedList({
  children,
  rowCount,
  windowScrollerRef,
}: {
  children: ({ index }: { index: number }) => JSX.Element;
  rowCount: number;
  windowScrollerRef: RefObject<WindowScroller>;
}) {
  return (
    <WindowScroller ref={windowScrollerRef}>
      {({ height, isScrolling, onChildScroll, scrollTop }) => (
        <List
          autoHeight
          height={height}
          isScrolling={isScrolling}
          onScroll={onChildScroll}
          scrollTop={scrollTop}
          width={1160}
          rowCount={rowCount}
          rowHeight={108}
          rowRenderer={({ key, index, style }) => (
            <div style={style} key={key}>
              {children({ index })}
            </div>
          )}
        />
      )}
    </WindowScroller>
  );
}
