import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import "twin.macro";
import p from "pluralize";
import isEmpty from "lodash/isEmpty";
import "../../../../scss/base.scss";
import "../../../../scss/buttons.scss";
import "../../../../scss/shoppingTables.scss";
import "../../../../scss/loader.scss";
import { currencyWithCentsAtAllTimes } from "../../../../utilities/numbers/currency";
import { useShoppingState } from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { useStorageState } from "../../../../contexts/shoppingContexts/StorageContext";
import { SelectSupplierModal } from "../../../../components/shopping/SelectSupplierModal/SelectSupplierModal";
import AppContext from "../../../../contexts/AppContext";
import { Text } from "../../../../components/rxLibrary/typography";
import { SimpleLink } from "../../../../components/rxLibrary/Link";
import { TableHeaderDescription } from "../tables/table/tableHeader/TableHeaderDescription";
import { TableHeaderCurrency } from "../tables/table/tableHeader/TableHeaderCurrency";
import { Savings } from "./Savings/Savings";

export function BuyingOptions() {
  const { optimizeCartResponse, orgSuppliersList } = useShoppingState();
  const { prescriptionData, offeredDrugs } = useStorageState();
  const { user } = useContext(AppContext);
  const [supplierModalActive, setSupplierModalActive] = useState(false);

  const { supplierOrders, unavailable, unfilledFuturePrescriptionGroups } =
    optimizeCartResponse.data?.selections ?? {};
  const hasUnavailableItems = !isEmpty(unavailable);
  const hasBuyLaterItems = !isEmpty(unfilledFuturePrescriptionGroups);
  const suppliersOrdersCount = supplierOrders?.length ?? 0;
  const hasSupplierOrders = !isEmpty(supplierOrders);
  const isAllUnavailableItems = !hasSupplierOrders && hasBuyLaterItems;
  const isLoading = !optimizeCartResponse;
  const hasError = !!optimizeCartResponse.error;

  const errorMessage = useMemo(() => {
    if (hasError) {
      return "There were no available options. Try adding more suppliers";
    }
    if (hasSupplierOrders) return;
    if (hasBuyLaterItems && !hasUnavailableItems) {
      return "Nothing recommended to purchase today.";
    }
    return "Items not available at selected suppliers.";
  }, [hasError, hasSupplierOrders, hasBuyLaterItems, hasUnavailableItems]);

  const totalItemsCount = useMemo(() => {
    if (!supplierOrders) return 0;
    return supplierOrders.reduce((acc, { items }) => acc + items.length, 0);
  }, [supplierOrders]);

  const allPrice = useMemo(() => {
    if (!supplierOrders) return 0;
    return supplierOrders.reduce((acc, s) => {
      return acc + s.buyingCost + s.shippingCost;
    }, 0);
  }, [supplierOrders]);

  const total = useMemo(() => {
    return currencyWithCentsAtAllTimes(allPrice, true);
  }, [allPrice]);

  const openModal = useCallback(() => {
    setSupplierModalActive(true);
  }, []);

  useEffect(() => {
    setSupplierModalActive(false);
  }, [optimizeCartResponse]);

  if (isLoading) {
    return <div className="loader-light" />;
  }

  if (errorMessage) {
    return <p className="text-center text-lg">{errorMessage}</p>;
  }

  return (
    <>
      <div className="supplier-block" tw="w-full">
        <div className="supplier-block__left justify-around flex flex-col">
          <Text tw="mb-[7px]" size={21} block>
            We recommend buying your list from {suppliersOrdersCount}{" "}
            {p("supplier", suppliersOrdersCount)} *
          </Text>

          {!!user?.is_admin && (
            <SimpleLink href="#" onClick={openModal} size="medium">
              See Suppliers List
            </SimpleLink>
          )}
        </div>

        {isAllUnavailableItems ? (
          <>
            <div tw="space-y-[6px]">
              <Text weight="bold" size={20} block center>
                {unavailable?.length}
              </Text>
              <TableHeaderDescription>
                {p("ITEM", unavailable?.length)}
              </TableHeaderDescription>
            </div>

            <div>
              <Text weight="bold" size={20} block center>
                These items will remain in your shopping list
              </Text>
            </div>
          </>
        ) : (
          <>
            <div tw="space-y-[6px]">
              <Text weight="bold" size={20} block center>
                {totalItemsCount}
              </Text>
              <TableHeaderDescription>
                {p("ITEM", totalItemsCount)}
              </TableHeaderDescription>
            </div>

            <div tw="space-y-[6px]">
              <TableHeaderCurrency value={total} />
              <TableHeaderDescription>TOTAL</TableHeaderDescription>
            </div>

            <div className="savings-wrapper">
              <Savings />
            </div>
          </>
        )}
      </div>

      <SelectSupplierModal
        reLoad
        prescriptionData={prescriptionData}
        offeredDrugs={offeredDrugs}
        modalActive={supplierModalActive}
        selectedSuppliers={orgSuppliersList}
        onClose={(mode) => {
          setSupplierModalActive(mode);
        }}
      />
    </>
  );
}
