import React from "react";
import "twin.macro";
import { Skeleton } from "antd";
import { FullPageLoader } from "../../loaders/FullPageLoader";

export function FormSkeleton({ text }: { text?: string }) {
  return (
    <>
      <Skeleton title active paragraph={{ rows: 15 }} />
      <Skeleton.Button shape="round" size="large" active />
      <FullPageLoader text={text} />
    </>
  );
}
