import "twin.macro";
import { ManufacturerChatButton } from "./ManufacturerChatButton";
import { FullWidthContainer } from "../../components/containers/FullWidthContainer";
import { NavBar } from "../../components/NavBar/NavBar";
import { ManufacturerReportContextProvider } from "./useManufacturerReport/useManufacturerReport";
import { ManufacturerReportSelector } from "./ManufacturerReportSelector";
import { ManufacturerDateRangePicker } from "./ManufacturerDateRangePicker";
import { ManufacturerReports } from "./ManufacturerReports/ManufacturerReports";
import { useFullScreenButton } from "./Manufacturer.useFullScreenButton";

export function Manufacturer() {
  const { isFullScreen, fullScreenButton } = useFullScreenButton();

  return (
    <div tw="flex flex-col h-[100dvh]">
      {!isFullScreen && <NavBar hideUserLinks fullWidth />}

      <ManufacturerReportContextProvider>
        <FullWidthContainer tw="py-2 bg-accent border-t border-gray-400 filter drop-shadow flex space-x-2 justify-between items-center">
          <div tw="flex space-x-2">
            <ManufacturerReportSelector />
            <ManufacturerDateRangePicker />
          </div>
          <div tw="flex space-x-2">
            {fullScreenButton}
            <ManufacturerChatButton />
          </div>
        </FullWidthContainer>

        <div tw="flex-1 min-h-[500px]">
          <ManufacturerReports />
        </div>
      </ManufacturerReportContextProvider>
    </div>
  );
}
