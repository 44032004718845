import React from "react";
import "twin.macro";
import { DrugDetailsCol } from "../../../../../components/shopping/table/columns/DrugDetailsCol";
import { QuantityDescriptionCol } from "../../../../../components/shopping/table/columns/QuantityDescriptionCol";
import { ManufacturerCol } from "../table/columns/ManufacturerCol";
import { BuyingForCol } from "../table/columns/BuyingForCol";
import { UnavailableItem } from "./useUnavailableItems";

export function UnavailableTableRow({
  item: unavailableItem,
}: {
  item: UnavailableItem;
}) {
  const { prescriptionDetails } = unavailableItem;
  const { drugInfo: drug } = unavailableItem.referenceData;
  return (
    <>
      <DrugDetailsCol drug={drug} />

      <div tw="px-[10px] border-r w-[205px]">
        <ManufacturerCol drug={drug} />
      </div>

      <div tw="px-[10px] border-r w-[130px]">
        <BuyingForCol prescriptionDetails={prescriptionDetails} />
      </div>

      <div tw="px-[10px] flex-1">
        <QuantityDescriptionCol drug={drug} />
      </div>
    </>
  );
}
