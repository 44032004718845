import React, { useMemo } from "react";
import "twin.macro";
import p from "pluralize";
import { Supplier } from "../../../utilities/types";
import { joinReadableSentences } from "../../../utilities/arrays/joinReadableSentences";
import { Text } from "../../../components/rxLibrary/typography";

export function UnusedSuppliers({ suppliers }: { suppliers: Supplier[] }) {
  const [title, text] = useMemo(() => {
    const count = suppliers.length;
    if (count === 0) return [];

    const supplierNameList = suppliers.map((supplier) => supplier.displayName);
    const supplierNames = joinReadableSentences(supplierNameList);

    return [
      `${supplierNames} ${p("were", count)} searched for best pricing`,

      `${p("These", count)} ${p("suppliers", count)}` +
        " did not did not offer the best overall purchase options, and " +
        `${p("are", count)}` +
        " not included in your shopping recommendations today",
    ];
  }, [suppliers]);

  if (!title) return null;
  return (
    <div tw="flex space-x-2 px-2">
      <div>
        <Text size="x-large" block>
          *
        </Text>
      </div>

      <div>
        <Text size="medium" block>
          {title}
        </Text>
        <Text size="medium" block>
          {text}
        </Text>
      </div>
    </div>
  );
}
