import React, { useCallback } from "react";
import "twin.macro";
import { Tooltip } from "antd";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import { Text } from "../../components/rxLibrary/typography";
import { PrintWrapper } from "../../components/rxLibrary/print";
import { IconButton } from "../../components/rxLibrary/buttons";
import { copyContent } from "../../utilities/copyContent";
import { SupplierAccountRecordView } from "./EnhancedSupplierAccount.constants";

export function EnhancedSupplierAccountUsername({
  account,
}: {
  account: SupplierAccountRecordView;
}) {
  const { userName } = account;

  const copyUsername = useCallback(() => {
    copyContent(userName, "Username");
  }, [userName]);

  return (
    <div key={userName} tw="flex justify-between items-center h-[35px]">
      <Text tw="flex-1">{userName}</Text>

      <PrintWrapper>
        <Tooltip title="Copy Username">
          <IconButton onClick={copyUsername}>
            <CopyOutlined rev={undefined} />
          </IconButton>
        </Tooltip>
      </PrintWrapper>
    </div>
  );
}
