import { useState, useEffect } from "react";
import { DrugInfo, ItemInCart } from "../../types";
import { useGetDrugFamilyPrescriptionsInShoppingList } from "./useGetDrugFamilyPrescriptionsInShoppingList";

export function useDrugFamilyPrescriptionsInShoppingList({
  drug,
  prescriptionToExclude,
  excludePmsPrescriptions,
}: {
  drug?: DrugInfo;
  prescriptionToExclude?: ItemInCart;
  excludePmsPrescriptions?: boolean;
} = {}) {
  const [drugFamilyPrescriptions, setDrugFamilyPrescriptions] = useState<
    ItemInCart[]
  >([]);

  const getDrugFamilyPrescriptionsInShoppingList =
    useGetDrugFamilyPrescriptionsInShoppingList();

  useEffect(() => {
    if (!drug) {
      setDrugFamilyPrescriptions([]);
      return;
    }

    const newDrugFamilyPrescriptions = getDrugFamilyPrescriptionsInShoppingList(
      { drug, excludePmsPrescriptions }
    );
    setDrugFamilyPrescriptions(newDrugFamilyPrescriptions);
  }, [drug, prescriptionToExclude, getDrugFamilyPrescriptionsInShoppingList]);

  return drugFamilyPrescriptions;
}
