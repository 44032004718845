import React, { useMemo } from "react";
import type { DrugInfo } from "../../../../utilities/types";
import { formatManufacturerInfo } from "../../../../utilities/prescriptions/formatManufacturerInfo";
import { formatDrugDispenseQuantity } from "../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { Text } from "../../../rxLibrary/typography";

export function ShoppingListColumnPackageInfo({
  drug,
  noManufacturerPreference,
}: {
  drug: DrugInfo;
  noManufacturerPreference?: boolean;
}) {
  const quantityDescription = useMemo(() => {
    return formatDrugDispenseQuantity(drug);
  }, [drug]);

  const manufacturerInfo = useMemo(() => {
    return formatManufacturerInfo(drug, noManufacturerPreference);
  }, [drug, noManufacturerPreference]);

  return (
    <>
      <Text weight="bold" block>
        {quantityDescription}
      </Text>

      <Text block>{manufacturerInfo}</Text>
    </>
  );
}
