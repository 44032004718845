import { styled } from "twin.macro";
import React, { useCallback } from "react";
import { NavBar } from "../../components/NavBar/NavBar";
import { WideContainer } from "../../components/containers/WideContainer";
import { Text } from "../../components/rxLibrary/typography";
import { Box } from "../../components/rxLibrary/box";
import { Select } from "../../components/rxLibrary/selects/select";
import { SortBySelect } from "../../components/rxLibrary/selects/SortBySelect";
import { Col, Row } from "../../components/rxLibrary/grid";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { EmailLink } from "../../components/rxLibrary/Link";
import { EnhancedShowSupplierButton } from "../../components/rxLibrary/buttons";
import { Pagination } from "../../components/rxLibrary/pagination/Pagination";
import { useInvoiceHistory } from "./useInvoiceHistory";
import { Invoice } from "./Invoice/Invoice";

const RowHeader = styled(Row)`
  position: relative;
  z-index: 1;
  margin-bottom: 0;
  box-shadow: 0 3px 6px 0 #00000028;
`;

export function InvoiceHistory() {
  const {
    sort,
    days,
    supplier,
    isLoading,
    histories,
    pagination,
    supplierId,
    sortOptions,
    daysOptions,
    sortDirection,
    footnoteWarning1,
    footnoteWarning2,
    footnoteWarning3,
    suppliersOptions,
    emptyPageWarning1Web,
    emptyPageWarning1WIP,
    emptyPageWarning2,
    setDays,
    setSort,
    setSupplierId,
    updatePagination,
    setSortDirection,
  } = useInvoiceHistory();

  const handleUpdateDays = useCallback(
    (value: any) => {
      setDays(value);
    },
    [setDays]
  );

  const handleUpdateSupplier = useCallback(
    (value: any) => {
      setSupplierId(value);
    },
    [setSupplierId]
  );

  return (
    <>
      <NavBar />

      <WideContainer tw="pt-[74px] pb-[200px]">
        <Text tw="mb-[25px]" size={32} block>
          Invoice History
        </Text>

        <Box tw="flex justify-between py-[28px] px-[35px]" bgColor="grey-4">
          <div tw="flex space-x-[36px]">
            <div tw="flex items-center space-x-[5px]">
              <Text size="medium">Supplier</Text>
              <Select
                tw="min-w-[210px]"
                size="large"
                value={supplierId}
                options={suppliersOptions}
                onChange={handleUpdateSupplier}
              />
            </div>

            <div tw="flex items-center space-x-[5px]">
              <Text size="medium">Invoice Dates</Text>
              <Select
                tw="min-w-[124px]"
                size="large"
                value={days}
                options={daysOptions}
                onChange={handleUpdateDays}
              />
            </div>
          </div>

          <SortBySelect
            value={sort}
            options={sortOptions}
            sortOrder={sortDirection}
            selectMinWidth={184}
            onChange={setSort}
            onSortOrderChange={setSortDirection}
          />
        </Box>

        {!!histories?.length && (
          <>
            <RowHeader>
              <Col tw="w-[150px]">
                <Text weight="bold" block>
                  Invoice Date
                </Text>
              </Col>
              <Col tw="w-[180px]">
                <Text weight="bold" block>
                  Invoice #
                </Text>
              </Col>
              <Col tw="w-[180px]">
                <Text weight="bold" block>
                  Supplier
                </Text>
              </Col>
              <Col tw="w-[257px]">
                <Text weight="bold" block>
                  Total # of Items
                </Text>
              </Col>
              <Col tw="w-[111px]">
                <Text weight="bold" block>
                  Total
                </Text>
              </Col>
              <Col tw="flex-1" noBorder></Col>
            </RowHeader>

            <div tw="space-y-[8px] mb-4">
              {histories.map((invoice, i) => (
                <Invoice key={i} id={i.toString()} invoice={invoice} />
              ))}
            </div>
          </>
        )}

        <div tw="my-2 space-y-2">
          {footnoteWarning1 && (
            <Text block>
              If you are not seeing expected invoices, please email{" "}
              <EmailLink email="support@daylightrx.com" />.
            </Text>
          )}

          {footnoteWarning2 && (
            <Text block>
              * This supplier does not yet make invoices available to
              DaylightRx. We are working on adding this.
            </Text>
          )}

          {footnoteWarning3 && (
            <Text block>
              ** To set up invoices for this supplier please contact{" "}
              <EmailLink email="support@daylightrx.com" />.
            </Text>
          )}

          {emptyPageWarning1Web && (
            <Text block>
              * {supplier?.displayName} does not yet make invoices available to
              DaylightRx. Please see the{" "}
              <EnhancedShowSupplierButton supplierId={supplierId}>
                supplier website
              </EnhancedShowSupplierButton>{" "}
              for invoices.
            </Text>
          )}

          {emptyPageWarning1WIP && (
            <Text block>
              * {supplier?.displayName} does not yet make invoices available to
              DaylightRx. We are working on adding this.
            </Text>
          )}

          {emptyPageWarning2 && (
            <Text block>
              ** To set up invoices for {supplier?.displayName} please contact{" "}
              <EmailLink email="support@daylightrx.com" />.
            </Text>
          )}
        </div>

        {pagination && (
          <Pagination
            pagination={pagination}
            updatePagination={updatePagination}
            sizeOptions={null}
          />
        )}
      </WideContainer>

      {isLoading && <FullPageLoader />}
    </>
  );
}
