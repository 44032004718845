import tw, { css, styled } from "twin.macro";
import { RxColor, getColorCss } from "../colors";

type TextWeight = "light" | "bold";
type TextFontSize = "small" | "medium" | "large" | "x-large" | number;

export type TextProps = {
  weight?: TextWeight;
  size?: TextFontSize;
  color?: RxColor;
  block?: boolean;
  center?: boolean;
  ellipsis?: boolean;
};

export const TEXT_ELLIPSIS_TW = tw`overflow-hidden whitespace-nowrap overflow-ellipsis`;

export const Text = styled.span((props: TextProps) => getTextCss(props));

function getFontWeight(value?: TextWeight) {
  const fontWeight =
    value === "bold"
      ? css`
          font-weight: bold;
        `
      : css`
          font-weight: normal;
        `;
  return fontWeight;
}

function getFontSize(value?: TextFontSize) {
  let fontSize = 14;

  if (typeof value === "number") {
    fontSize = value;
  } else {
    if (value === "medium") fontSize = 16;
    else if (value === "large") fontSize = 18;
    else if (value === "x-large") fontSize = 24;
  }

  return css`
    font-size: ${fontSize}px;
  `;
}

export function getTextCss({
  weight: variant,
  size,
  color,
  block = false,
  center = false,
  ellipsis = false,
}: TextProps = {}) {
  return [
    css`
      font-family: "Archivo Narrow Variable", sans-serif;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    `,
    getFontWeight(variant),
    getFontSize(size),
    getColorCss(color),
    // if color is grey: font-weight: 500;
    color === "grey-1" && tw`font-medium`,
    !!block && tw`block`,
    !!center && tw`text-center`,
    !!ellipsis && TEXT_ELLIPSIS_TW,
  ];
}

export const DEFAULT_TEXT_CSS = getTextCss();
