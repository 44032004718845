import { Box } from "../../../components/rxLibrary/box";
import { WideContainer } from "../../../components/containers/WideContainer";
import { PerformanceHeaderCSVExportLinks } from "./PerformanceHeaderCSVExportLinks";
import { PerformanceHeaderDateRangePicker } from "./PerformanceHeaderDateRangePicker";
import { PerformanceHeaderReportSelector } from "./PerformanceHeaderReportSelector";
import { PerformanceHeaderPharmaciesSelect } from "./PerformanceHeaderPharmaciesSelect";

export function PerformanceHeader() {
  return (
    <Box bgColor="grey-5" tw="py-8">
      <WideContainer>
        <div tw="flex justify-between space-x-2">
          <PerformanceHeaderPharmaciesSelect />
          <PerformanceHeaderReportSelector />
          <PerformanceHeaderDateRangePicker />
        </div>

        <PerformanceHeaderCSVExportLinks />
      </WideContainer>
    </Box>
  );
}
