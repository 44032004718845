import React from "react";
import "./DotLoader.css";
import cn from "classnames";

export default function DotLoader({ className }: { className?: string }): JSX.Element {
  return (
    <div className={cn("dot-container", className)}>
      <div className="dot-flashing"></div>
    </div>
  );
}
