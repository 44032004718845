import React, { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";
import semver from "semver";
import {Supplier} from "../utilities/types";
import {SupplierItemChangeOperation} from "../utilities/shoppingListTypes";
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";
import {NDCSearchParam} from "./types";
import {
  OptimizedCartResponse_All,
  SupplierOrder_All
} from "../services/utils";

interface PokeSupplierFunc {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (name: string, data?: any): void
}

type EnhancedNotificationRequest = {
    title: string,
    body: string,
    soundEffect?: "chime" | "none",
}

export type EnhancedCartUpdate = {
  supplierName: string,
  status: "new" | "waiting" | "complete",
  currState?: OptimizedCartResponse_All,
  operations: SupplierItemChangeOperation[]
}

type EnhancedClientCommandContext = {
    awakenApp: () => void,
    restartApp: () => void,
    showMain: () => void,
    showSupplier: PokeSupplierFunc,
    showSupplierWithOptions: (name: string, options: { isEcommerce?: boolean, eCommerceSiteName?: string, eCommerceSiteLink?: string, preventActivation?: boolean}) => void,
    showSupplierForUseCase: (name: string, useCase: string) => void,
    reloadSupplier: PokeSupplierFunc,
    resetSupplier: PokeSupplierFunc,
    havingTroubleWithSupplier: PokeSupplierFunc,
    showSupplierForShopping: (suppliersListItem: {supplier: Supplier, supplierOrder: SupplierOrder_All}, visitedItems: string[], optimizeCartResponse: OptimizedCartResponse_All, isEcommerce: boolean) => void,
    addEnhancedShoppingCartChangeListener: (cb: (supplier: string, operations: SupplierItemChangeOperation[]) => void) => void,
    removeEnhancedShoppingCartChangeListener: (cb: (supplier: string, operations: SupplierItemChangeOperation[]) => void) => void,
    addEnhancedShoppingListSwitchListener: (cb: (supplier: string) => void) => void,
    removeEnhancedShoppingListSwitchListener: (cb: (supplier: string) => void) => void,
    pushCompletedShoppingCartChange: (update: EnhancedCartUpdate) => void,
    createEnhancedNotification: (request: EnhancedNotificationRequest) => void,
    ndcSearch: (param: NDCSearchParam) => void,
}

const EnhancedClientCommandContext = createContext<EnhancedClientCommandContext>({
    awakenApp: () => {},
    restartApp: () => {},
    showMain: () => {},
    reloadSupplier: () => {},
    resetSupplier: () => {},
    havingTroubleWithSupplier: () => {},
    showSupplier: () => {},
    showSupplierWithOptions: () => {},
    showSupplierForUseCase: () => {},
    showSupplierForShopping: () => {},
    addEnhancedShoppingCartChangeListener: () => {},
    removeEnhancedShoppingCartChangeListener: () => {},
    addEnhancedShoppingListSwitchListener: () => {},
    removeEnhancedShoppingListSwitchListener: () => {},
    pushCompletedShoppingCartChange: () => {},
    createEnhancedNotification: () => {},
    ndcSearch: () => {},
});

export default EnhancedClientCommandContext;

type EnhancedClientCommandContextProviderProps = {
    children?: ReactNode;
};


export function EnhancedClientCommandContextProvider({
                                                  children,
                                              }: EnhancedClientCommandContextProviderProps): JSX.Element {
    const [shoppingCartChangeListeners, setShoppingCartChangeListeners] = useState<((supplier: string, operations: SupplierItemChangeOperation[]) => void)[]>([]);
    const [shoppingListSwitchListeners, setShoppingListSwitchListeners] = useState<((supplier: string) => void)[]>([]);
    const {enhancedClientVersion, enhancedClientActive} = useContext(EnhancedClientConfigContext);
    const addEnhancedShoppingCartChangeListener = (cb: (supplier: string, operations: SupplierItemChangeOperation[]) => void) => {
      setShoppingCartChangeListeners((prevState) => {
        return [...prevState, cb]
      });
    }

    const removeEnhancedShoppingCartChangeListener = (cb: (supplier: string, operations: SupplierItemChangeOperation[]) => void) => {
      setShoppingCartChangeListeners((prevState) => {
        return prevState.filter((listener) => listener !== cb)
      });
    }
    const addEnhancedShoppingListSwitchListener = (cb: (supplier: string) => void) => {
      setShoppingListSwitchListeners((prevState) => {
        return [...prevState, cb]
      });
    }

    const removeEnhancedShoppingListSwitchListener = (cb: (supplier: string) => void) => {
      setShoppingListSwitchListeners((prevState) => {
        return prevState.filter((listener) => listener !== cb)
      });
    }

    const pushCompletedShoppingCartChange = (update: EnhancedCartUpdate) => {
      console.log("pushCompletedShoppingCartChange", update);
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "pushCompletedShoppingCartChange",
        payload: update,
      });
    }

    useEffect(() => {
      const messageListenerCallback = async (event: MessageEvent) => {
        if (event.source !== window) {
          return;
        }
        if (event.data.channel === "daylightrxenhanced") {
          if (event.data.msgType === 'shoppingCartChange') {
            console.log("shoppingCartChange", event.data.payload);
            let listeners = shoppingCartChangeListeners;
            setShoppingCartChangeListeners((state) => { listeners = state; return state;});
            listeners.forEach((listener) => {
              listener(event.data.payload.supplier, event.data.payload.operations);
            });
          } else if (event.data.msgType === 'shoppingListSwitch') {
            console.log("shoppingListSwitch", event.data.payload);
            let listeners = shoppingListSwitchListeners;
            setShoppingListSwitchListeners((state) => { listeners = state; return state;});
            listeners.forEach((listener) => {
              listener(event.data.payload.supplier);
            });
          }
        }
      }

      window.addEventListener("message", messageListenerCallback);
      return () => {
        window.removeEventListener("message", messageListenerCallback);
      }
    }, []);

    const showSupplier: PokeSupplierFunc = (name) => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "showSupplier",
            payload: name,
        })
    }

    const showSupplierWithOptions: (name: string, options: { preventActivation?: boolean}) => void = (name, options) => {
      if (!enhancedClientVersion || semver.lte(enhancedClientVersion, "0.2.172")) {
        console.log("showSupplierWithOptions not supported in this version of Enhanced Client");
      } else {
        window.postMessage({
          channel: "daylightrxenhanced",
          sender: "web",
          msgType: "showSupplierWithOptions",
          payload: {
            supplier: name,
            options: options,
          },
        });
      }
    }

    const showSupplierForUseCase = (name: string, useCase: string) => {
      if (semver.lte(enhancedClientVersion, "0.2.151")) {
        showSupplier(name);
      } else {
        window.postMessage({
          channel: "daylightrxenhanced",
          sender: "web",
          msgType: "showSupplierForUseCase",
          payload: {
            supplier: name,
            useCase: useCase,
          },
        });
      }
    }

  function ecommerceifyOrder(supplierOrder: SupplierOrder_All) : SupplierOrder_All {
    const allItemNdcs: {ndc: string, qty: number}[] = [];
    supplierOrder.items.forEach((item) => {
      allItemNdcs.push({ndc: item.referenceData.drugInfo.ndc, qty: item.numPackages});
    });
    const ndcQueryStringParameters = allItemNdcs.map((item) => `ndc=${item.ndc}&qty=${item.qty}`).join("&");
    return {
      ...supplierOrder,
      items: supplierOrder.items.map((item) => {
        return {
          ...item,
          referenceData: {
            ...item.referenceData,
            catalogInfo: {
              ...item.referenceData.catalogInfo,
              link: `${window.location.origin}/manufacturer-store/${item.referenceData.catalogInfo.supplierId}?${ndcQueryStringParameters}`,
            },
          }
        }
      })
    }
  }

  const showSupplierForShopping = useCallback(
      (
        payload: { supplier: Supplier; supplierOrder: SupplierOrder_All },
        visited: string[],
        optimizeCartResponse: OptimizedCartResponse_All,
        isEcommerce: boolean,
      ) => {
        const messagePayload = {
          isEcommerce,
          supplier: payload.supplier,
          supplierOrder: isEcommerce ? ecommerceifyOrder(payload.supplierOrder) : payload.supplierOrder,
          visited,
          optimizeCartResponse,
        };
        console.log("showSupplierForShopping", messagePayload);
        window.postMessage({
          channel: "daylightrxenhanced",
          sender: "web",
          msgType: "showSupplierForShopping",
          payload: messagePayload,
        });
        if (!enhancedClientActive && payload.supplierOrder.items.length > 0) {
          window.open(payload.supplierOrder.items[0].referenceData.catalogInfo.link);
        }
      },
      [enhancedClientActive]
    );

    const createEnhancedNotification = (request: EnhancedNotificationRequest) => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "createEnhancedNotification",
            payload: request,
        })
    }

    const awakenApp = () => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "awakenApp",
        });
    }

    const restartApp = () => {
        window.postMessage({
            channel: "daylightrxenhanced",
            sender: "web",
            msgType: "restartApp",
        });
    }

    const ndcSearch = (param: NDCSearchParam) => {
      console.log("ndcSearch", param);
      window.postMessage({
          channel: "daylightrxenhanced",
          sender: "web",
          msgType: "ndcSearch",
          payload: param,
      });
    }

    const showMain = () => {
      window.postMessage({
        channel: "daylightrxenhanced",
        sender: "web",
        msgType: "showMain",
      });
    }
  const reloadSupplier: PokeSupplierFunc = (name) => {
    window.postMessage({
      channel: "daylightrxenhanced",
      sender: "web",
      msgType: "reloadSupplier",
      payload: name,
    })
  }

  const resetSupplier: PokeSupplierFunc = (name) => {
    window.postMessage({
      channel: "daylightrxenhanced",
      sender: "web",
      msgType: "resetSupplier",
      payload: name,
    })
  }
  const havingTroubleWithSupplier: PokeSupplierFunc = (name) => {
    window.postMessage({
      channel: "daylightrxenhanced",
      sender: "web",
      msgType: "havingTroubleWithSupplier",
      payload: name,
    })
  }

    return (
        <EnhancedClientCommandContext.Provider value={{
            showMain,
            reloadSupplier,
            resetSupplier,
            havingTroubleWithSupplier,
            showSupplier,
            showSupplierWithOptions,
            showSupplierForUseCase,
            showSupplierForShopping,
            addEnhancedShoppingCartChangeListener,
            removeEnhancedShoppingCartChangeListener,
            addEnhancedShoppingListSwitchListener,
            removeEnhancedShoppingListSwitchListener,
            pushCompletedShoppingCartChange,
            createEnhancedNotification,
            awakenApp,
            restartApp,
            ndcSearch,
        }}>{children}</EnhancedClientCommandContext.Provider>
    );
}
