import React, {createContext, useEffect, useState} from "react";

type EnhancedClientOfflinePurchaseContext = {
  enhancedOfflineSupplierModalShown: boolean,
  setEnhancedOfflineSupplierModalShown: React.Dispatch<React.SetStateAction<boolean>>,
  enhancedOfflineSupplierName: string | null,
  setEnhancedOfflineSupplierName: React.Dispatch<React.SetStateAction<string|null>>,
};

const EnhancedClientOfflinePurchaseContext = createContext<EnhancedClientOfflinePurchaseContext>({
  enhancedOfflineSupplierModalShown: false,
  setEnhancedOfflineSupplierModalShown: () => {},
  enhancedOfflineSupplierName: null,
  setEnhancedOfflineSupplierName: () => {},
});

export default EnhancedClientOfflinePurchaseContext;

type EnhancedClientOfflinePurchaseContextProviderProps = {
    children?: React.ReactNode;
};

export function EnhancedClientOfflinePurchaseContextProvider({
                                       children,
                                   }: EnhancedClientOfflinePurchaseContextProviderProps): JSX.Element {
    const [offlineSupplierModalShown, setOfflineSupplierModalShown] = useState<boolean>(false);
    const [offlineSupplierName, setOfflineSupplierName] = useState<string|null>(null);

    useEffect(() => {
            const messageListenerCallback = async (event: MessageEvent) => {
                if (event.source !== window) {
                    return;
                }
                if (event.data.channel === "daylightrxenhanced") {
                    if (event.data.msgType === "handleOfflinePurchase") {
                        console.log("enhancedClientOfflinePurchaseContext:handleOfflinePurchase", event.data);
                        setOfflineSupplierModalShown(true);
                        setOfflineSupplierName(event.data.payload?.supplier);
                    }
                }
            };
            window.addEventListener("message", messageListenerCallback);

            return () => {
                window.removeEventListener("message", messageListenerCallback);
            }
    }, [])

    return (
        <EnhancedClientOfflinePurchaseContext.Provider
            value={{
                enhancedOfflineSupplierModalShown: offlineSupplierModalShown,
                setEnhancedOfflineSupplierModalShown: setOfflineSupplierModalShown,
                enhancedOfflineSupplierName: offlineSupplierName,
                setEnhancedOfflineSupplierName: setOfflineSupplierName,
            }}
        >
            {children}
        </EnhancedClientOfflinePurchaseContext.Provider>
    );
}
