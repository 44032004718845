import React, { useCallback } from "react";
import "twin.macro";
import { useHistory } from "react-router-dom";
import { Button } from "../../../../components/rxLibrary/buttons";
import { useStorageState } from "../../../../contexts/shoppingContexts/StorageContext";

export function ShoppingBarCloseModalBtn() {
  const history = useHistory();
  const { waitButtonMode } = useStorageState();

  const handleClose = useCallback(async () => {
    window.location.href = "/previousShoppingLists";
  }, [history]);

  return (
    <>
      <Button variant="text-2" disabled={waitButtonMode} onClick={handleClose}>
        Close
      </Button>
    </>
  );
}
