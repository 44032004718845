import type { AgChartTheme } from "ag-charts-community";

export const rxChartTheme: AgChartTheme = {
  overrides: {
    common: {
      title: {
        fontFamily: '"Archivo Narrow Variable", sans-serif',
      },
      subtitle: {
        fontFamily: '"Archivo Narrow Variable", sans-serif',
      },
      footnote: {
        fontFamily: '"Archivo Narrow Variable", sans-serif',
      },
      legend: {
        item: {
          label: {
            fontFamily: '"Archivo Narrow Variable", sans-serif',
          },
        },
      },
      tooltip: {
        class: "chart-tooltip",
      },
      axes: {
        category: {
          label: {
            fontFamily: '"Archivo Narrow Variable", sans-serif',
          },
        },
        time: {
          label: {
            fontFamily: '"Archivo Narrow Variable", sans-serif',
          },
        },
        number: {
          label: {
            fontFamily: '"Archivo Narrow Variable", sans-serif',
          },
        },
      },
      overlays: {
        noData: {
          renderer: () => '<p class="chart-overlays">No data to display</p>',
        },
        loading: {
          renderer: () => '<p class="chart-overlays">Loading data...</p>',
        },
        noVisibleSeries: {
          renderer: () => '<p class="chart-overlays">No visible series</p>'
        }
      },
    },
    bar: {
      series: {
        label: {
          fontFamily: '"Archivo Narrow Variable", sans-serif',
        },
      }
    },
    line: {
      series: {
        label: {
          fontFamily: '"Archivo Narrow Variable", sans-serif',
        }
      }
    },
    pie: {
      series: {
        calloutLabel: {
          fontFamily: '"Archivo Narrow Variable", sans-serif',
        },
        sectorLabel: {
          fontFamily: '"Archivo Narrow Variable", sans-serif',
        }
      }
    }
  },
};
