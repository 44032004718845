import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import isFinite from "lodash/isFinite";
import type {
  ManufacturerReport,
  ManufacturerReportItem,
} from "../Manufacturer.constants";
import { getQueryParams } from "../../../utilities/queryParams/getQueryParams";
import { getLocalStorageValue } from "../../../utilities/localStorage/getLocalStorageValue";
import { LAST_MANUFACTURER_REPORT_ID_KEY } from "../../../utilities/localStorage/constants";

function getLocalStorageReportId() {
  const reportIdLocalStorage = getLocalStorageValue(
    LAST_MANUFACTURER_REPORT_ID_KEY,
    ""
  );
  if (!reportIdLocalStorage) return;

  const initialReportId = Number(reportIdLocalStorage);
  if (isFinite(initialReportId)) return initialReportId;
}

function getQueryParamReportId() {
  const queryParams = getQueryParams();
  const reportIdQueryParam = queryParams.reportId;

  if (isString(reportIdQueryParam) && !isEmpty(reportIdQueryParam)) {
    const reportIdNum = Number(reportIdQueryParam);
    if (isFinite(reportIdNum)) return reportIdNum;
  }
}

function findManufacturerReportById(
  reportId: number,
  reports: ManufacturerReport[]
): ManufacturerReportItem | undefined {
  for (let i = 0; i < reports.length; ++i) {
    const report = reports[i];

    if ("children" in report) {
      const childReport = findManufacturerReportById(reportId, report.children);
      if (childReport) return childReport;
    } else if (report.reportId === reportId) {
      return report;
    }
  }
}

function getFirstManufacturerReportItem(
  report: ManufacturerReport
): ManufacturerReportItem {
  if ("children" in report) {
    return getFirstManufacturerReportItem(report.children[0]);
  }
  return report;
}

export function getInitialManufacturerReport(reports: ManufacturerReport[]) {
  let initialReportId = getQueryParamReportId();
  if (!initialReportId) {
    initialReportId = getLocalStorageReportId();
  }

  let report: ManufacturerReport | undefined;

  if (initialReportId) {
    report = findManufacturerReportById(initialReportId, reports);
  }
  if (!report) report = reports[0];
  if (!report) return;

  let reportItem: ManufacturerReportItem;
  if ("children" in report) {
    reportItem = getFirstManufacturerReportItem(report);
  } else {
    reportItem = report;
  }

  return reportItem;
}
