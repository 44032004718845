import React from "react";
import { styled } from "twin.macro";
import { GREY_1 } from "../../../../../../components/rxLibrary/colors";

export const TableHeaderDescription = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  line-height: normal;
  color: ${GREY_1};
`;

