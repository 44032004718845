import { useMemo } from "react";
import type { SelectOption } from "../../rxLibrary/selects/select";
import { currencyRoundedToNearestDollar } from "../../../utilities/numbers/currency";
import { Box } from "../../rxLibrary/box";
import { Button } from "../../rxLibrary/buttons";
import { IconGear } from "../../rxLibrary/icons";
import { Text } from "../../rxLibrary/typography";
import { SortBySelect } from "../../rxLibrary/selects/SortBySelect";
import { useRefillOpportunities } from "../useRefillOpportunities";
import { RefillOpportunitiesHeaderDownloadCsvButton } from "./RefillOpportunitiesHeaderDownloadCsvButton";
import { RefillOpportunitiesHeaderPaymentsReportLink } from "./RefillOpportunitiesHeaderPaymentsReportLink";
import { RefillOpportunitiesHeaderSyncButton } from "./RefillOpportunitiesHeaderSyncButton";

const REFILL_OPPORTUNITIES_SORT_OPTIONS: SelectOption[] = [
  {
    label: "Last Dispensed Drug Name",
    value: "last_dispensed_drug_name",
  },
  {
    label: "Last Dispensed Manufacturer",
    value: "last_dispensed_manufacturer",
  },
  {
    label: "Rx #",
    value: "rx_number",
  },
  {
    label: "Patient",
    value: "patient",
  },
  {
    label: "Est. Profit",
    value: "estimated_profit",
  },
  {
    label: "Est. Revenue",
    value: "estimated_revenue",
  },
  {
    label: "Est. Cost",
    value: "estimated_cost",
  },
  {
    label: "Refill Due Date",
    value: "refill_due_date",
  },
  {
    label: "Primary Payer",
    value: "primary_payer",
  },
];

export function RefillOpportunitiesHeader({
  openModal,
}: {
  openModal: () => void;
}) {
  const {
    pharmacyId,
    sortColumn,
    sortDirection,
    refillMappings,
    showOnlyCoveredFills,
    reportRefillOpportunity,
    setSortColumn,
    setSortDirection,
  } = useRefillOpportunities();

  const profitDetails = useMemo(() => {
    if (!reportRefillOpportunity) return "";

    const {
      records,
      totalEstimatedProfit,
      coveredTotalEstimatedProfit,
      totalOutreachOpportunityAmount,
      pharmacyIsInPaidOutreachProgram,
    } = reportRefillOpportunity;
    if (!records.length) return "No refills to show";
    if (totalEstimatedProfit <= 0) return "No profitable refills";

    const total = showOnlyCoveredFills
      ? coveredTotalEstimatedProfit
      : totalEstimatedProfit;
    const totalProfit = currencyRoundedToNearestDollar(total);
    if (pharmacyIsInPaidOutreachProgram) {
      const totalOutreach = currencyRoundedToNearestDollar(
        totalOutreachOpportunityAmount
      );
      return `${totalOutreach} - Outreach profit | ${totalProfit} - Est. refill profits (Excludes in-progress refills)`;
    }
    return `${totalProfit} - Est. refill profits (Excludes in-progress refills)`;
  }, [showOnlyCoveredFills, reportRefillOpportunity]);

  return (
    <div tw="flex mb-[10px] space-x-[10px]">
      <div>
        <img
          tw="h-[80px]"
          src="/assets/pages/refill-opportunities/savings-opportunity.png"
        />
      </div>

      <div tw="flex-1">
        <Box
          tw="flex items-center justify-between border-b py-[10px]"
          borderColor="grey-2"
        >
          <Text size={30} block>
            Non-Adherent Refill Outreach Opportunities
          </Text>

          <SortBySelect
            value={sortColumn}
            selectMinWidth={170}
            options={REFILL_OPPORTUNITIES_SORT_OPTIONS}
            sortOrder={sortDirection}
            onSortOrderChange={setSortDirection}
            onChange={setSortColumn}
          />
        </Box>

        <div tw="flex items-center justify-between pt-[10px]">
          <Text weight="bold">{profitDetails}</Text>

          <div tw="flex items-center space-x-[6px]">
            <RefillOpportunitiesHeaderSyncButton />

            <RefillOpportunitiesHeaderPaymentsReportLink />

            {!!refillMappings?.length && (
              <Button
                variant="text-1"
                icon={<IconGear />}
                disabled={!pharmacyId}
                onClick={openModal}
              >
                Acquisition Cost Setup
              </Button>
            )}

            <RefillOpportunitiesHeaderDownloadCsvButton />
          </div>
        </div>
      </div>
    </div>
  );
}
