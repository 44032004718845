import React, {useContext, useEffect} from "react";
import {useLocation} from "react-router-dom";
import AppContext from "../contexts/AppContext";

export function DemoMode(): JSX.Element | null {
  const location = useLocation();
  const { enableDemoMode } = useContext(AppContext);
  useEffect(() => {
    if (location.pathname === "/demo") {
      enableDemoMode();
    }
  }, [location]);
  return <>Demo Mode Enabled</>;
}
