import React from "react";
import type { SummaryType } from "./summary.types";
import { FillsByOutcomeBarChart } from "./FillsByOutcomeBarChart";
import { CoveredRatioPieChart } from "./CoveredRatioPieChart";
import { FillsByOccCodeBarChart } from "./FillsByOccCodeBarChart";
import { formatOrUndefined } from "../../../../Performance/reports/utils/performanceReportFormatters";
import {
  manufacturerReportCurrencyFormatter,
  manufacturerReportNumberFormatter,
  manufacturerReportPercentFormatter,
} from "../utils/manufacturerReportFormatters";
import { BigNumber } from "../../../../Performance/reports/BigNumber";
import { Box } from "../../../../../components/rxLibrary/box";
import { AvgPaymentsTable } from "./AvgPaymentsTable/AvgPaymentsTable";

export function Summary({ data }: { data: SummaryType }) {
  if (!data) return;

  const {
    fillsByOutcomeBarChart: { data: fillsByOutcomeBarChartData },
    coveredRatioPieChart: { data: coveredRatioPieChartData },
    fillsByOccCodeBarChart: { data: fillsByOccCodeBarChartData },
    bigNumbers: { data: bigNumbersData },
    avgPaymentsTable: { data: avgPaymentsTableData }
  } = data;

  const fillOpportunityCount = formatOrUndefined(
    bigNumbersData?.fillOpportunityCount,
    manufacturerReportNumberFormatter
  );
  const unattemptedFillCount = formatOrUndefined(
    bigNumbersData?.unattemptedFillCount,
    manufacturerReportNumberFormatter
  );
  const attemptedFillCount = formatOrUndefined(
    bigNumbersData?.attemptedFillCount,
    manufacturerReportNumberFormatter
  );
  const percentAttemptedOfOpportunity = formatOrUndefined(
    bigNumbersData?.percentAttemptedOfOpportunity,
    manufacturerReportPercentFormatter
  );
  const dispensedFillCount = formatOrUndefined(
    bigNumbersData?.dispensedFillCount,
    manufacturerReportNumberFormatter
  );
  const abandonedFillCount = formatOrUndefined(
    bigNumbersData?.abandonedFillCount,
    manufacturerReportNumberFormatter
  );
  const transferredFillCount = formatOrUndefined(
    bigNumbersData?.transferredFillCount,
    manufacturerReportNumberFormatter
  );
  const inProgressFillCount = formatOrUndefined(
    bigNumbersData?.inProgressFillCount,
    manufacturerReportNumberFormatter
  );
  const percentDispensedOfOpportunity = formatOrUndefined(
    bigNumbersData?.percentDispensedOfOpportunity,
    manufacturerReportPercentFormatter
  );
  const newRxFillCount = formatOrUndefined(
    bigNumbersData?.newRxFillCount,
    manufacturerReportNumberFormatter
  );
  const percentCovered = formatOrUndefined(
    bigNumbersData?.percentCovered,
    manufacturerReportPercentFormatter
  );
  const thirdPartyPayerCoveredFillCount = formatOrUndefined(
    bigNumbersData?.thirdPartyPayerCoveredFillCount,
    manufacturerReportNumberFormatter
  );
  const manufacturerCoveredFillCount = formatOrUndefined(
    bigNumbersData?.manufacturerCoveredFillCount,
    manufacturerReportNumberFormatter
  );
  const avgThirdPartyPayerContribution = formatOrUndefined(
    bigNumbersData?.avgThirdPartyPayerContribution,
    manufacturerReportCurrencyFormatter
  );

  return (
    <Box bgColor="grey-4">
      <div tw="flex-1 mt-4 px-4">
        <FillsByOutcomeBarChart data={fillsByOutcomeBarChartData} />
      </div>
      <div tw="grid grid-cols-2 gap-[15px] h-[400px] px-4 mt-4">
        <CoveredRatioPieChart data={coveredRatioPieChartData} />
        <FillsByOccCodeBarChart data={fillsByOccCodeBarChartData} />
      </div>
      <div tw="grid grid-cols-4 gap-[15px] px-4">
        <BigNumber
          title="Fill Opportunity Count"
          variant="small"
          value={fillOpportunityCount}
        />
        <BigNumber
          title="Unattempted Fill Count"
          subtitle="DUE FOR REFILL (AND NOT IN PROGRESS)"
          variant="small"
          value={unattemptedFillCount}
        />
        <BigNumber
          title="Attempted Fill Count"
          variant="small"
          value={attemptedFillCount}
        />
        <BigNumber
          title="% Attempted of Opportunity"
          variant="small"
          value={percentAttemptedOfOpportunity}
        />
      </div>
      <div tw="grid grid-cols-4 gap-[15px] px-4">
        <BigNumber
          title="Dispensed Fill Count"
          variant="small"
          value={dispensedFillCount}
        />
        <BigNumber
          title="Abandoned Fill Count"
          variant="small"
          value={abandonedFillCount}
        />
        <BigNumber
          title="Transferred Fill Count"
          variant="small"
          value={transferredFillCount}
        />
        <BigNumber
          title="In Progress Fill Count"
          variant="small"
          value={inProgressFillCount}
        />
      </div>
      <div tw="grid grid-cols-2 gap-[15px] px-4">
        <BigNumber
          title="% Dispensed of Opportunity"
          variant="small"
          value={percentDispensedOfOpportunity}
        />
        <BigNumber
          title="New Rx Count"
          subtitle="with an attempted fill"
          variant="small"
          value={newRxFillCount}
        />
      </div>
      <div tw="grid grid-cols-4 gap-[15px] px-4">
        <BigNumber
          title="% Covered"
          subtitle="OF DISPENSED FILLS"
          variant="small"
          value={percentCovered}
        />
        <BigNumber
          title="Third-Party-Payer-Covered"
          subtitle="OF DISPENSED FILLS"
          variant="small"
          value={thirdPartyPayerCoveredFillCount}
        />
        <BigNumber
          title="Manufacturer-Covered"
          subtitle="OF DISPENSED FILLS"
          variant="small"
          value={manufacturerCoveredFillCount}
        />
        <BigNumber
          title="Avg. Third-Party Payer Contribution"
          subtitle="OF DISPENSED FILLS"
          variant="small"
          value={avgThirdPartyPayerContribution}
        />
      </div>
      <div tw="flex-1 h-[500px] mt-4 px-4 bg-white">
        <AvgPaymentsTable data={avgPaymentsTableData} />
      </div>
    </Box>
  );
}
