import { useCallback } from "react";
import type { Dayjs } from "dayjs";
import type { ItemInCart } from "../../../../utilities/types";
import { usePrescriptionNote } from "../../../../components/PrescriptionNote/usePrescriptionNote";
import { useShoppingUpdater } from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { ShoppingRxPrescriptionItem } from "./components/ShoppingRxPrescriptionItem";

export function ShoppingRxCartPrescriptionItem({ item }: { item: ItemInCart }) {
  const {
    removePrescription,
    updatePrescriptionNote,
    updatePrescriptionPackSize,
    updatePrescriptionPurchaseBy,
    updatePrescriptionManufacturer,
  } = useShoppingUpdater();

  const {
    hasPrescriptionNote: hasItemNote,
    prescriptionNotePopover: itemNotePopover,
    openPrescriptionNotePopover: openItemNotePopover,
  } = usePrescriptionNote({
    prescription: item,
    updatePrescriptionNote,
  });

  const updateItemPackSize = useCallback(
    (value: boolean) => {
      updatePrescriptionPackSize(item, value);
    },
    [item, updatePrescriptionPackSize]
  );

  const updateItemManufacturer = useCallback(
    (value: boolean) => {
      updatePrescriptionManufacturer(item, value);
    },
    [item, updatePrescriptionManufacturer]
  );

  const removeItem = useCallback(() => {
    removePrescription(item);
  }, [item, removePrescription]);

  const updateItemPurchaseBy = useCallback(
    (value?: Dayjs) => {
      updatePrescriptionPurchaseBy(item, value);
    },
    [item, updatePrescriptionPurchaseBy]
  );

  return (
    <ShoppingRxPrescriptionItem
      isInCart
      item={item}
      hasItemNote={hasItemNote}
      itemNotePopover={itemNotePopover}
      removeItem={removeItem}
      updateItemPackSize={updateItemPackSize}
      openItemNotePopover={openItemNotePopover}
      updateItemPurchaseBy={updateItemPurchaseBy}
      updateItemManufacturer={updateItemManufacturer}
    />
  );
}
