import React, { useCallback, useMemo, useState } from "react";
import "twin.macro";
import { Checkbox } from "antd";
import type { NdcResults } from "./useNdcResults/useNdcResults";
import { ListCategoryEnum } from "../../../utilities/types";
import { Container } from "../../../components/containers/Container";
import NDCResultCard from "./NDCResultCard/NDCResultCard";

export function NdcResultsCards({
  ndc,
  ndcResults,
}: {
  ndc: string;
  ndcResults: NdcResults;
}) {
  const { topCards, bottomCards, origMatch, totalCardCount, mainNdcOffering } =
    ndcResults;

  const [showDiffQuantities, setShowDiffQuantities] = useState(true);
  const [showDiffManufacturers, setShowDiffManufacturers] = useState(true);

  const visibleBottomCards = useMemo(() => {
    return bottomCards.filter(({ category }) => {
      switch (category) {
        case ListCategoryEnum.DIFFERENT_QUANTITIES:
          return showDiffQuantities;
        case ListCategoryEnum.DIFFERENT_MANUFACTURERS:
          return showDiffManufacturers;
        case ListCategoryEnum.DIFFERENT_MANUFACTURER_AND_QUANTITY:
          return showDiffManufacturers && showDiffQuantities;
      }
      return true;
    });
  }, [bottomCards, showDiffQuantities, showDiffManufacturers]);

  const toggleShowDiffQuantities = useCallback(() => {
    setShowDiffQuantities((prev) => !prev);
  }, []);

  const toggleShowDiffManufacturers = useCallback(() => {
    setShowDiffManufacturers((prev) => !prev);
  }, []);

  return (
    <>
      {(topCards.length === 0 ||
        (origMatch.category !== ListCategoryEnum.NDC_MATCH &&
          origMatch.category !== ListCategoryEnum.SUGGESTED_NDC)) && (
        <Container tw="text-center pb-6 text-lg">
          {ndc} not found in any supplier catalogs. Alternatives below.
        </Container>
      )}

      <Container
        style={{ marginBottom: bottomCards.length ? undefined : "192px" }}
      >
        <div>
          <ul>
            {topCards.map((ndcOfferings) => (
              <li tw="mb-6" key={ndcOfferings.ndc}>
                <NDCResultCard
                  ndcOfferings={ndcOfferings}
                  totalCardCount={totalCardCount}
                  mainNdcOffering={mainNdcOffering}
                />
              </li>
            ))}
          </ul>
        </div>
      </Container>

      {!!bottomCards.length && (
        <div tw="bg-altbg mt-[93px] py-[82px] pb-[25px]">
          <Container>
            <div tw="flex justify-between pb-8 items-center">
              <div tw="font-medium text-2xl ml-4">Higher Priced Items</div>

              <div tw="font-medium text-sm">
                <Checkbox
                  checked={showDiffQuantities}
                  onClick={toggleShowDiffQuantities}
                />{" "}
                Show different quantities
              </div>

              <div tw="font-medium text-sm mr-4">
                <Checkbox
                  checked={showDiffManufacturers}
                  onClick={toggleShowDiffManufacturers}
                />{" "}
                Show other manufacturer options
              </div>
            </div>

            <div>
              <ul>
                {visibleBottomCards.map((ndcOfferings) => (
                  <li tw="mb-6" key={ndcOfferings.ndc}>
                    <NDCResultCard
                      isBelowTheFold
                      ndcOfferings={ndcOfferings}
                      totalCardCount={totalCardCount}
                      mainNdcOffering={mainNdcOffering}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
