import React, { useMemo } from "react";
import "twin.macro";
import pluralize from "pluralize";
import { Title } from "../../../../components/rxLibrary/typography";

export function ShoppingListTableInventoryHeader({
  visibleInventoryItemsCount,
  inventoryItemsInCartCount,
}: {
  visibleInventoryItemsCount: number;
  inventoryItemsInCartCount: number;
}) {
  const message = useMemo(() => {
    const filteredInventoryItemsCount =
      inventoryItemsInCartCount - visibleInventoryItemsCount;
    const itemsPlural = pluralize("Item", visibleInventoryItemsCount);

    let newMessage = `Inventory ${itemsPlural}: ${visibleInventoryItemsCount}`;
    if (filteredInventoryItemsCount > 0) {
      newMessage += ` (+${filteredInventoryItemsCount} filtered)`;
    }

    return newMessage;
  }, [
    visibleInventoryItemsCount,
    inventoryItemsInCartCount,
  ]);

  return <Title variant="h2">{message}</Title>;
}
