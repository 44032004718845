import { IconButton } from "@mui/material";
import { ShoppingItemHeaderActionsMenu } from "./ShoppingRxItemHeaderActionsMenu";
import { IconTrash } from "../../../../../../../components/rxLibrary/icons";

export function ShoppingItemHeaderActions({
  hasItemNote,
  removeItem,
  openItemNotePopover,
  openByByDateDatePicker,
}: {
  hasItemNote?: boolean;
  removeItem: () => void;
  openItemNotePopover?: () => void;
  openByByDateDatePicker?: () => void;
}) {
  const isOnlyRemoveButton = !openItemNotePopover && !openByByDateDatePicker;

  if (isOnlyRemoveButton) {
    return (
      <IconButton onClick={removeItem}>
        <IconTrash />
      </IconButton>
    );
  }

  return (
    <ShoppingItemHeaderActionsMenu
      hasItemNote={hasItemNote}
      removeItem={removeItem}
      openItemNotePopover={openItemNotePopover}
      openByByDateDatePicker={openByByDateDatePicker}
    />
  );
}
