import React, { useCallback, useContext } from "react";
import { css, styled } from "twin.macro";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../../../contexts/AuthenticationContext";
import AppContext from "../../../../contexts/AppContext";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import { clearJSONBlob } from "../../../../services/prescriptions";
import { DEFAULT_TEXT_CSS, Text } from "../../../rxLibrary/typography";
import { BLUE_1 } from "../../../rxLibrary/colors";
import EnhancedClientConfigContext from "../../../../enhanced/EnhancedClientConfigContext";
import EnhancedClientContext from "../../../../enhanced/EnhancedClientContext";

const NAV_ITEM_CSS = css`
  ${DEFAULT_TEXT_CSS};
  padding: 4px 0;
  align-self: flex-start;
  text-decoration: underline;

  &:hover {
    color: ${BLUE_1};
  }
`;

const NavButton = styled.button`
  ${NAV_ITEM_CSS}
`;

const NavLink = styled(Link)`
  ${NAV_ITEM_CSS}
`;

export function AccountMenuItems() {
  const { user } = useContext(AppContext);
  const { currentBuyingPharmacyId: pharmacyId } = useBuyingPharmacy();
  const { enhancedClientActive, enhancedClientDevMode, enhancedClientVersion } =
    useContext(EnhancedClientConfigContext);
  const { requestRestart } = useContext(EnhancedClientContext);
  const { logout, getAccessTokenSilently } = useAuth0();

  const clearCart = useCallback(async () => {
    if (!pharmacyId) return;
    const token = await getAccessTokenSilently();
    await clearJSONBlob(token, pharmacyId);
    window.location.reload();
  }, [pharmacyId, getAccessTokenSilently]);

  return (
    <div tw="flex flex-col items-start space-y-1">
      {!!user?.is_admin && (
        <>
          <NavLink to="/admin">Admin Page</NavLink>

          <NavLink to="/admin/rx-library">Rx Library</NavLink>

          <NavButton disabled={!pharmacyId} onClick={clearCart}>
            Clear Cart
          </NavButton>
        </>
      )}

      {enhancedClientActive && enhancedClientDevMode && (
        <NavButton onClick={requestRestart}>Restart App</NavButton>
      )}

      <div tw="flex items-center justify-between w-full">
        <NavButton onClick={logout}>SIGN OUT</NavButton>

        {!!enhancedClientVersion && (
          <Text color="grey-1">Version: {enhancedClientVersion}</Text>
        )}
      </div>
    </div>
  );
}
