import React, {
  useEffect,
  useContext,
  useState,
  createContext,
  useCallback,
  useMemo,
  ReactNode,
} from "react";
import keyBy from "lodash/keyBy";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import type { Dayjs } from "dayjs";
import type {
  ItemNote,
  Supplier,
  ItemInCart,
  PrescriptionDetail,
  ItemPurchaseDetails,
  ShoppingSupplierCheck,
  PurchaseQuantityMethodEnum,
  PrescriptionGroupAdjustment,
} from "../../../utilities/types";
import { isInventoryId } from "../../../utilities/prescriptions/isInventoryId";
import { getPrescriptionId } from "../../../utilities/prescriptions/getPrescriptionId";
import { formatItemPurchaseDetails } from "../../../utilities/prescriptions/formatItem";
import {
  getShippingInfo,
  buildShippingFeeSimulation,
} from "../../../utilities/shipping/shippingInfo";
import { getQueryParams } from "../../../utilities/queryParams/getQueryParams";
import { useFetchPrescriptions } from "../../../utilities/prescriptions/useFetchPrescriptions";
import { getQueryParamFirstValue } from "../../../utilities/queryParams/getQueryParamFirstValue";
import { formatPurchaseByToString } from "../../../utilities/prescriptions/purchaseBy/formatPurchaseByToString";
import {
  CatalogDrug_All,
  SupplierOrder_All,
  SupplierOrderItemRecommendation_All,
  OptimizedCartResponse_All,
  uniqueCatalogDrugID,
} from "../../../services/utils";
import { getOptimizedCart } from "../../../services/legacy/optimizations";
import { useBuyingPharmacy } from "../../BuyingPharmacyContext";
import { useAuth0 } from "../../AuthenticationContext";
import { useStorageUpdater } from "../StorageContext";
import {
  useShoppingCartServerState,
  useShoppingCartServerUpdater,
} from "../ShoppingCartServerContext/ShoppingCartServerContext";
import { mergePrescriptionsAndCartData } from "../mergePrescriptionsAndCartData";
import {
  getOptimizedCartWithUpdatedBuyLaterItemPurchaseBy,
  getOptimizedCartWithMoveBuyLaterItemToShoppingList,
  getOptimizedCartWithMovedShoppingListItemToBuyLater,
} from "./ShoppingContext.optimizedCartItemOperations";

const ShoppingStateContext = createContext<
  ReturnType<typeof useShoppingContextProvider>["shoppingState"] | undefined
>(undefined);

const ShoppingUpdaterContext = createContext<
  ReturnType<typeof useShoppingContextProvider>["shoppingUpdater"] | undefined
>(undefined);

function supplierOrderItemsAreEqual(
  first: CatalogDrug_All,
  second?: CatalogDrug_All
): boolean {
  return (
    !!second &&
    first.catalogInfo.id === second.catalogInfo.id &&
    first.drug.id === second.drug.id
  );
}

function useShoppingContextProvider() {
  const useCase = useMemo(() => {
    if (getQueryParamFirstValue(getQueryParams()["from"]) === "list") {
      return "previouslyCreated";
    }
    return "newlyCreated";
  }, []);

  const { getAccessTokenSilently } = useAuth0();
  const { getPrescriptionsByRxNumber } = useFetchPrescriptions();
  const { cart, inventory, prescriptionGroupAdjustments } =
    useShoppingCartServerState();
  const { submitShoppingCartMutation, submitOptimizationUpdate } =
    useShoppingCartServerUpdater();
  const { setWaitButtonMode } = useStorageUpdater();
  const { currentBuyingPharmacyId: pharmacyId, getSupplierById } =
    useBuyingPharmacy();

  const [hasPricesChanged, setHasPricesChanged] = useState(false);
  const [lookupOptimizedCartOp, setLookupOptimizedCartOp] = useState<{
    cartId: number;
    loaded?: boolean;
    processed?: boolean;
  } | null>(null);
  const [loadedOptimization, setLoadedOptimization] =
    useState<OptimizedCartResponse_All | null>(null);
  const [cartReconstructedOptimization, setCartReconstructedOptimization] =
    useState<OptimizedCartResponse_All | null>(null);
  const [optimizeCartResponse, setOptimizeCartResponse] =
    useState<OptimizedCartResponse_All>({});

  const optimizationPrescriptionGroupAdjustments = useMemo(() => {
    return (
      optimizeCartResponse?.data?.cartSnapshot.prescriptionGroupAdjustments ||
      []
    );
  }, [optimizeCartResponse]);

  const optimizationItemPurchaseDetails = useMemo(() => {
    return optimizeCartResponse?.data?.cartSnapshot.cart || [];
  }, [optimizeCartResponse]);

  const [orgSuppliersList, setOrgSuppliersList] = useState<
    ShoppingSupplierCheck[]
  >([]);
  const [prescriptionItemsInCart, setPrescriptionItemsInCart] = useState<
    ItemInCart[]
  >([]);
  const [prescriptionsById, setPrescriptionsById] = useState<
    Record<string, ItemInCart>
  >({});

  const itemPurchaseDetailsInCart = useMemo(() => {
    return [
      ...prescriptionItemsInCart
        .filter((item) => item.status === "list")
        .map((item) => formatItemPurchaseDetails(item)),
      ...inventory
        .filter((item) => item.status === "list")
        .map((item) => formatItemPurchaseDetails(item)),
    ];
  }, [inventory, prescriptionItemsInCart]);

  const updateOptimizeCartResponseAll = useCallback(
    (arg: React.SetStateAction<OptimizedCartResponse_All>) => {
      setOptimizeCartResponse((prev) => {
        const newOptimizeCart = typeof arg === "function" ? arg(prev) : arg;
        if (newOptimizeCart.data) {
          newOptimizeCart.data.editVersion++;
        }
        submitOptimizationUpdate(JSON.parse(JSON.stringify(newOptimizeCart)));
        return newOptimizeCart;
      });
    },
    [setOptimizeCartResponse]
  );

  const addOrgSuppliersList = useCallback((items: ShoppingSupplierCheck[]) => {
    const newItems = items.filter((item) => item.check);
    setOrgSuppliersList(newItems);
  }, []);

  /**
   * Simulate if we will have a shipping cost after add/remove items
   */
  const getShippingFeeSimulation = useCallback(
    (
      supplier: Supplier,
      {
        itemToAdd,
        itemToRemove,
      }: { itemToAdd?: CatalogDrug_All; itemToRemove?: CatalogDrug_All }
    ) => {
      const supplierOrders =
        optimizeCartResponse.data?.selections.supplierOrders;
      const shippingFeeSimulation = buildShippingFeeSimulation({
        supplier,
        itemToAdd,
        itemToRemove,
        supplierOrders,
      });
      return shippingFeeSimulation;
    },
    [optimizeCartResponse.data?.selections.supplierOrders]
  );

  const addSupplierToOptimizeCart = useCallback(
    (supplierObj?: Supplier | null, newSupplierOrder?: SupplierOrder_All) => {
      const supplierId = supplierObj?.id;
      if (!supplierId || !newSupplierOrder) return;

      updateOptimizeCartResponseAll((prevOptimizeCart) => {
        if (!prevOptimizeCart) return prevOptimizeCart;

        const optimizeCart = cloneDeep(prevOptimizeCart);
        const supplierOrders =
          optimizeCart?.data?.selections.supplierOrders ?? [];
        const supplierCheck = supplierOrders.filter(
          (s) => s.supplierId === supplierId
        );

        if (supplierCheck.length !== 0) {
          return prevOptimizeCart;
        }

        // supplier is not in the list
        const finalCart = JSON.parse(JSON.stringify(optimizeCart));
        if (finalCart.data) {
          finalCart.data.selections.supplierOrders = [
            ...supplierOrders,
            newSupplierOrder,
          ];
        }
        return finalCart;
      });
    },
    []
  );

  const addInventoryItem = useCallback(
    (item: ItemInCart) => {
      submitShoppingCartMutation({
        name: "addInventoryItem",
        params: { item },
      });
    },
    [submitShoppingCartMutation]
  );

  const updateInventoryItem = useCallback(
    <K extends keyof ItemInCart>(
      key: string,
      field: K,
      value: ItemInCart[K]
    ) => {
      submitShoppingCartMutation({
        name: "updateInventoryItem",
        params: { key, field, value },
      });
    },
    [submitShoppingCartMutation]
  );

  const addItemPurchaseDetailsList = useCallback(
    (items: ItemPurchaseDetails[]) => {
      submitShoppingCartMutation({
        name: "addItemPurchaseDetailsList",
        params: { items },
      });
    },
    [submitShoppingCartMutation]
  );

  // secId is to cover similar NDC on P3
  // has to be removed once the whole flow is in place
  const updateItemPurchaseDetailValue = useCallback(
    <K extends keyof ItemPurchaseDetails>(
      id: string,
      key: K,
      value: ItemPurchaseDetails[K]
    ) => {
      submitShoppingCartMutation({
        name: "updateItemPurchaseDetailValue",
        params: { id, key, value },
      });
    },
    [submitShoppingCartMutation]
  );

  const updatePrescriptionItemValue = useCallback(
    <K extends keyof Omit<ItemInCart, "key" | "rxNumber">>(
      item: ItemInCart,
      field: K,
      value: ItemInCart[K]
    ) => {
      const id = getPrescriptionId(item);
      const isInventory = isInventoryId(id);
      if (isInventory) updateInventoryItem(id, field, value);
      else {
        const newItem: ItemInCart = { ...item, [field]: value };
        submitShoppingCartMutation({
          name: "addOrUpdateItemInPurchaseDetail",
          params: { item: newItem },
        });
      }
    },
    [updateInventoryItem, submitShoppingCartMutation]
  );

  const removePrescription = useCallback(
    (value: string | string[] | ItemInCart) => {
      let ids: string[];
      if (Array.isArray(value)) ids = value;
      else if (typeof value === "string") ids = [value];
      else ids = [getPrescriptionId(value)];

      if (ids.length === 0) return;
      submitShoppingCartMutation({ name: "removeFromCart", params: { ids } });
    },
    [submitShoppingCartMutation]
  );

  const updatePrescriptionGroupPurchaseQuantity = useCallback(
    (
      rxNumbers: string[],
      purchaseQuantityMethod: PurchaseQuantityMethodEnum,
      num?: string
    ) => {
      submitShoppingCartMutation({
        name: "updatePrescriptionGroupPurchaseQuantity",
        params: { num, rxNumbers, purchaseQuantityMethod },
      });
    },
    [submitShoppingCartMutation]
  );

  const updatePrescriptionGroupChosenSubstitution = useCallback(
    (
      rxNumbers: string[],
      allowPackSizeSubstitution: boolean,
      allowManufacturerSubstitution: boolean
    ) => {
      submitShoppingCartMutation({
        name: "updatePrescriptionGroupChosenSubstitution",
        params: {
          rxNumbers,
          allowPackSizeSubstitution,
          allowManufacturerSubstitution,
        },
      });
    },
    [submitShoppingCartMutation]
  );

  const deletePrescriptionGroupAdjustments = useCallback(
    (adjustments: PrescriptionGroupAdjustment[]) => {
      submitShoppingCartMutation({
        name: "deletePrescriptionGroupAdjustments",
        params: { adjustments },
      });
    },
    [submitShoppingCartMutation]
  );

  const deletePrescriptionGroupAdjustmentsByRxNumbers_undoable = useCallback(
    (rxNumbers: string[]) => {
      let adjustmentsToDelete = optimizationPrescriptionGroupAdjustments.filter(
        (adjustment) => {
          return rxNumbers.some((rn) => adjustment.rxNumbers.includes(rn));
        }
      );
      if (adjustmentsToDelete.length === 0) {
        adjustmentsToDelete = prescriptionGroupAdjustments.filter(
          (adjustment) => {
            return rxNumbers.some((rn) => adjustment.rxNumbers.includes(rn));
          }
        );
      }
      if (adjustmentsToDelete.length > 0) {
        submitShoppingCartMutation({
          name: "deletePrescriptionGroupAdjustments",
          params: { adjustments: adjustmentsToDelete },
          options: { undo: { adjustments: adjustmentsToDelete } },
        });
      }
    },
    [
      submitShoppingCartMutation,
      optimizationPrescriptionGroupAdjustments,
      prescriptionGroupAdjustments,
    ]
  );

  const updateItemValue_undoable = useCallback(
    <K extends "status" | "supplierId" | "packQuantity">(
      id: string,
      field: K,
      value: (ItemInCart | ItemPurchaseDetails)[K]
    ) => {
      const isInventory = isInventoryId(id);
      const existingItem = optimizationItemPurchaseDetails.find((item) => {
        return item.id === id;
      });
      let oldValue: typeof value = existingItem?.[field];

      if (isInventory) {
        if (!existingItem) {
          const inventoryItem = inventory.find((item) => {
            return getPrescriptionId(item) === id;
          });
          if (!inventoryItem) return;
          oldValue = inventoryItem[field];
        }

        submitShoppingCartMutation({
          name: "updateInventoryItem",
          params: { key: id, field, value },
          options: { undo: { key: id, field, value: oldValue } },
        });
      } else {
        if (!existingItem) {
          const prescriptionDetails = cart.find((item) => item.id === id);
          if (!prescriptionDetails) return;
          oldValue = prescriptionDetails[field];
        }

        submitShoppingCartMutation({
          name: "updateItemPurchaseDetailValue",
          params: { id, key: field, value },
          options: { undo: { id, key: field, value: oldValue } },
        });
      }
    },
    [cart, optimizationItemPurchaseDetails, submitShoppingCartMutation]
  );

  const addOptimizeCartResponse = useCallback(
    (optimizedCart: OptimizedCartResponse_All) => {
      setOptimizeCartResponse(optimizedCart);

      // TODO: This could be improved to be more efficient
      const allRxNumbers: string[] = [];
      optimizedCart.data?.selections.supplierOrders.forEach(
        ({ items, supplierId }) => {
          items.forEach((item) => {
            item.rxNumbers.forEach((id) => {
              updateItemValue_undoable(id, "status", "processed");
              updateItemValue_undoable(id, "supplierId", supplierId);
              allRxNumbers.push(id);
            });
          });
        }
      );
      deletePrescriptionGroupAdjustmentsByRxNumbers_undoable(allRxNumbers);
    },
    [
      updateItemValue_undoable,
      deletePrescriptionGroupAdjustmentsByRxNumbers_undoable,
    ]
  );

  const updateOptimizeCartResponse = useCallback(
    (
      oldSupplierObj?: Supplier | null,
      currentItem?: SupplierOrderItemRecommendation_All,
      newSupplierObj?: Supplier | null,
      newItem?: CatalogDrug_All
    ) => {
      const oldSupplierId = oldSupplierObj?.id;
      const newSupplierId = newSupplierObj?.id;
      let referenceItem: SupplierOrderItemRecommendation_All | undefined =
        undefined;

      updateOptimizeCartResponseAll((prevState: OptimizedCartResponse_All) => {
        const theOptimizeCart: OptimizedCartResponse_All = cloneDeep(prevState);

        const supplierOrders: SupplierOrder_All[] | undefined =
          theOptimizeCart.data?.selections.supplierOrders;

        // get number of suppliers
        const supplierCount = supplierOrders?.length;

        // suppliers minus current and new
        let finalSupplier: SupplierOrder_All[] | undefined =
          supplierOrders?.filter(
            (s) =>
              s.supplierId !== oldSupplierId && s.supplierId !== newSupplierId
          );

        // get supplier old object
        const oldSupplier: SupplierOrder_All | undefined = supplierOrders?.find(
          (s) => s.supplierId === oldSupplierId
        );

        if (oldSupplier) {
          referenceItem =
            oldSupplier.items.find((item) =>
              supplierOrderItemsAreEqual(item, currentItem)
            ) || currentItem;
          const otherItems = oldSupplier.items.filter(
            (item) => !supplierOrderItemsAreEqual(item, currentItem)
          );
          // all items except the old one
          oldSupplier.items = otherItems;

          // Old Supplier has been updated
          finalSupplier?.push(oldSupplier);
        }

        // new supplier
        if (newItem) {
          // get new supplier
          const tmpSupplier =
            theOptimizeCart.data?.selections.supplierOrders.find(
              (s) => s.supplierId === newSupplierId
            );

          if (tmpSupplier) {
            const newSupplier = Object.assign({}, tmpSupplier);
            // Alternatives
            const selectedAlternative =
              referenceItem?.alternatives.find((alt: CatalogDrug_All) =>
                supplierOrderItemsAreEqual(alt, newItem)
              ) || newItem;

            const newAlternatives = referenceItem?.alternatives.filter(
              (alt) => !supplierOrderItemsAreEqual(alt, newItem)
            );

            const newItems = [...newSupplier.items];

            if (
              referenceItem?.referenceData.catalogInfo &&
              referenceItem?.referenceData.drugInfo
            ) {
              const newAlt: CatalogDrug_All = {
                drug: { id: referenceItem.drug.id },
                catalogInfo: { id: referenceItem.catalogInfo.id },
                referenceData: {
                  catalogInfo: referenceItem?.referenceData.catalogInfo,
                  drugInfo: referenceItem?.referenceData.drugInfo,
                },
                numPackages: referenceItem?.numPackages || 0,
              };
              newAlternatives?.push(newAlt);
            }

            const theItem: SupplierOrderItemRecommendation_All = {
              alternatives: newAlternatives || [],
              drug: { id: selectedAlternative.drug.id },
              catalogInfo: { id: selectedAlternative.catalogInfo.id },
              numPackages: selectedAlternative.numPackages,
              originalNumPackages: referenceItem?.originalNumPackages,
              supplierId: newSupplierId,
              manufacturerChanged: referenceItem?.manufacturerChanged,
              packSizeChanged: referenceItem?.packSizeChanged,
              rxNumbers: referenceItem?.rxNumbers || [],
              referenceData: {
                drugInfo: selectedAlternative.referenceData.drugInfo,
                catalogInfo: selectedAlternative.referenceData.catalogInfo,
              },
            };

            newItems.push(theItem);
            // add new
            newSupplier.items = newItems;

            // old and new can be the same
            if (oldSupplierId === newSupplierId) {
              finalSupplier =
                theOptimizeCart.data?.selections.supplierOrders.filter(
                  (s: SupplierOrder_All) => s.supplierId !== oldSupplierId
                );
            }
            finalSupplier?.push(newSupplier);
          }
        }

        if (finalSupplier && finalSupplier.length === supplierCount) {
          const finalCart = JSON.parse(JSON.stringify(theOptimizeCart));
          if (finalCart.data) {
            finalCart.data.selections.supplierOrders = finalSupplier;
          }
          return finalCart;
        } else {
          return prevState;
        }
      });
    },
    [updateOptimizeCartResponseAll]
  );

  const updateOptimizeCartQty = useCallback(
    (
      newQty: number,
      currentItem: SupplierOrderItemRecommendation_All,
      supplierObj?: Supplier | null
    ) => {
      const supplierId = supplierObj?.id;
      if (!supplierId) return;

      updateOptimizeCartResponseAll((prevState) => {
        const oldOptimizeCart = cloneDeep(prevState);
        const supplierOrders = oldOptimizeCart.data?.selections.supplierOrders;
        if (!supplierOrders) {
          return prevState;
        }

        const supplier = supplierOrders.find((s) => {
          return s.supplierId === supplierId;
        });
        if (!supplier) {
          return prevState;
        }

        const supplierItemIndex = supplier.items.findIndex((item) => {
          return supplierOrderItemsAreEqual(item, currentItem);
        });

        if (supplierItemIndex === -1) {
          return prevState;
        }

        supplier.items[supplierItemIndex].numPackages = newQty;
        const newSupplierOrders = supplierOrders.filter(
          (s) => s.supplierId !== supplierId
        );

        const newOptimizedCart = {
          ...oldOptimizeCart,
          data: {
            ...oldOptimizeCart.data,
            supplierOrders: [...newSupplierOrders, supplier],
          },
        } as OptimizedCartResponse_All;

        return newOptimizedCart;
      });

      setTimeout(() => {
        setWaitButtonMode(false);
      }, 0);
    },
    [updateOptimizeCartResponseAll, setWaitButtonMode]
  );

  const removeVisitedItem = useCallback(
    (item: SupplierOrderItemRecommendation_All) => {
      const itemId = uniqueCatalogDrugID(item);
      updateOptimizeCartResponseAll((prevState) => {
        if (!prevState.data) {
          return prevState;
        }
        const newVisitedItemsIds = {
          ...prevState.data.selections.visitedItemsIds,
        };
        delete newVisitedItemsIds[itemId];
        return {
          ...prevState,
          data: {
            ...prevState.data,
            selections: {
              ...prevState.data.selections,
              visitedItemsIds: newVisitedItemsIds,
            },
          },
        };
      });
    },
    [updateOptimizeCartResponseAll]
  );

  const addVisitedItems = useCallback(
    (items: SupplierOrderItemRecommendation_All[]) => {
      const itemIds = items.map((item) => uniqueCatalogDrugID(item));
      updateOptimizeCartResponseAll((prevState) => {
        if (!prevState.data) {
          return prevState;
        }
        const newVisitedItemsIds = {
          ...prevState.data.selections.visitedItemsIds,
        };
        itemIds.forEach((id) => {
          newVisitedItemsIds[id] = true;
        });
        return {
          ...prevState,
          data: {
            ...prevState.data,
            selections: {
              ...prevState.data.selections,
              visitedItemsIds: newVisitedItemsIds,
            },
          },
        };
      });
    },
    [updateOptimizeCartResponseAll]
  );

  const isVisitedItem = useCallback(
    (item: SupplierOrderItemRecommendation_All) => {
      const itemId = uniqueCatalogDrugID(item);
      return (
        optimizeCartResponse.data?.selections.visitedItemsIds[itemId] || false
      );
    },
    [optimizeCartResponse]
  );

  const getVisitedItemsCount = useCallback(
    (items: SupplierOrderItemRecommendation_All[]) => {
      return items.reduce((acc, item) => {
        return acc + (isVisitedItem(item) ? 1 : 0);
      }, 0);
    },
    [isVisitedItem]
  );

  const updateOptimizeCartQtyAlt = useCallback(
    (
      newQty: number,
      currentItem: SupplierOrderItemRecommendation_All,
      altItem: CatalogDrug_All,
      supplierObj?: Supplier | null
    ) => {
      const supplierId = supplierObj?.id;
      if (!supplierId) return;

      updateOptimizeCartResponseAll((prevState) => {
        const oldOptimizeCart = cloneDeep(prevState);
        const supplierOrders = prevState.data?.selections.supplierOrders;
        if (!supplierOrders) {
          return prevState;
        }

        const supplier = supplierOrders.find((s) => {
          return s.supplierId === supplierId;
        });
        if (!supplier) {
          return prevState;
        }

        const theItem = supplier.items.find((item) =>
          supplierOrderItemsAreEqual(item, currentItem)
        );
        if (!theItem) {
          return prevState;
        }

        // If we have the item, look for it's alternatives
        const newAltItem = theItem.alternatives.find((item) =>
          supplierOrderItemsAreEqual(item, altItem)
        );
        if (!newAltItem) {
          return prevState;
        }

        newAltItem.numPackages = newQty;
        const newSupplierOrders = supplierOrders.filter(
          (s) => s.supplierId !== supplierId
        );

        const newOptimizedCart = {
          ...oldOptimizeCart,
          data: {
            ...oldOptimizeCart?.data,
            supplierOrders: [...newSupplierOrders, supplier],
          },
        } as OptimizedCartResponse_All;

        return newOptimizedCart;
      });
    },
    [updateOptimizeCartResponseAll]
  );

  const updatePrescriptionStatus = useCallback(
    (item: ItemInCart, status: string) => {
      updatePrescriptionItemValue(item, "status", status);
    },
    [updatePrescriptionItemValue]
  );

  const updatePrescriptionNote = useCallback(
    (item: ItemInCart, note?: ItemNote) => {
      updatePrescriptionItemValue(item, "note", note);
    },
    [updatePrescriptionItemValue]
  );

  const updatePrescriptionPackQuantity = useCallback(
    (item: ItemInCart, packQuantity: number) => {
      if (packQuantity === 0) removePrescription(item);
      else updatePrescriptionItemValue(item, "packQuantity", packQuantity);
    },
    [removePrescription, updatePrescriptionItemValue]
  );

  const updatePrescriptionPurchaseBy = useCallback(
    (item: ItemInCart, date?: Date | Dayjs) => {
      const purchaseBy = formatPurchaseByToString(date);
      updatePrescriptionItemValue(item, "purchaseBy", purchaseBy);
    },
    [updatePrescriptionItemValue]
  );

  const updatePrescriptionPackSize = useCallback(
    (item: ItemInCart, packSize: boolean) => {
      updatePrescriptionItemValue(item, "packSize", packSize);
    },
    [updatePrescriptionItemValue]
  );

  const updatePrescriptionManufacturer = useCallback(
    (item: ItemInCart, manufactutrer: boolean) => {
      updatePrescriptionItemValue(item, "manufactutrer", manufactutrer);
    },
    [updatePrescriptionItemValue]
  );

  const updatePrescriptionPurchaseByById = useCallback(
    (id: string, date?: Date | Dayjs) => {
      const isInventory = isInventoryId(id);
      const purchaseBy = formatPurchaseByToString(date);

      if (isInventory) updateInventoryItem(id, "purchaseBy", purchaseBy);
      else updateItemPurchaseDetailValue(id, "purchaseBy", purchaseBy);
    },
    [updateInventoryItem, updateItemPurchaseDetailValue]
  );

  const updatePrescriptionsPurchaseBy = useCallback(
    (rxNumbers: string[], date?: Date | Dayjs) => {
      rxNumbers.forEach((id) => updatePrescriptionPurchaseByById(id, date));
    },
    [updatePrescriptionPurchaseByById]
  );

  const updateBuyLaterItemPurchaseBy = useCallback(
    (rxNumbers: string[], date: Date | Dayjs) => {
      updatePrescriptionsPurchaseBy(rxNumbers, date);

      const newOptimizeCartResponseAll =
        getOptimizedCartWithUpdatedBuyLaterItemPurchaseBy({
          date,
          rxNumbers,
          optimizeCartResponseAll: optimizeCartResponse,
        });

      if (newOptimizeCartResponseAll) {
        updateOptimizeCartResponseAll(newOptimizeCartResponseAll);
      }
    },
    [optimizeCartResponse, updatePrescriptionsPurchaseBy]
  );

  const moveShoppingListItemToBuyLater = useCallback(
    ({
      date,
      item,
      supplierId,
    }: {
      date: Date | Dayjs;
      item: SupplierOrderItemRecommendation_All;
      supplierId: number;
    }) => {
      updatePrescriptionsPurchaseBy(item.rxNumbers, date);

      const newOptimizeCartResponseAll =
        getOptimizedCartWithMovedShoppingListItemToBuyLater({
          date,
          supplierId,
          itemToBuyLater: item,
          optimizeCartResponseAll: optimizeCartResponse,
        });

      if (newOptimizeCartResponseAll) {
        updateOptimizeCartResponseAll(newOptimizeCartResponseAll);
      }
    },
    [optimizeCartResponse, updatePrescriptionsPurchaseBy]
  );

  const moveBuyLaterItemToShoppingList = useCallback(
    ({
      newItem,
      supplierId,
    }: {
      newItem: SupplierOrderItemRecommendation_All;
      supplierId: number;
    }) => {
      updatePrescriptionsPurchaseBy(newItem.rxNumbers);

      const newOptimizeCartResponseAll =
        getOptimizedCartWithMoveBuyLaterItemToShoppingList({
          newItem,
          supplierId,
          optimizeCartResponseAll: optimizeCartResponse,
        });

      if (newOptimizeCartResponseAll) {
        updateOptimizeCartResponseAll(newOptimizeCartResponseAll);
      }
    },
    [optimizeCartResponse, updatePrescriptionsPurchaseBy]
  );

  const getPrescriptionsWithDetails = useCallback(
    (rxNumbers: string[]): PrescriptionDetail[] => {
      const prescriptionDetails = rxNumbers.reduce<PrescriptionDetail[]>(
        (acc, id) => {
          const prescription = prescriptionsById[id];
          if (prescription) {
            const isInventory = isInventoryId(id);
            acc.push({ id, isInventory, prescription });
          }
          return acc;
        },
        []
      );
      return prescriptionDetails;
    },
    [prescriptionsById]
  );

  useEffect(() => {
    if (lookupOptimizedCartOp) {
      return;
    }
    if (optimizeCartResponse && Object.keys(optimizeCartResponse).length > 0) {
      setLookupOptimizedCartOp({
        cartId: optimizeCartResponse.data?.id || -1,
        loaded: true,
        processed: true,
      });
      return;
    }
    if (!pharmacyId) return;

    const cartIdStr = getQueryParamFirstValue(
      getQueryParams()["optimizedCartId"]
    );
    if (!cartIdStr) return;
    const cartId = parseInt(cartIdStr, 10);
    if (cartId) {
      setLookupOptimizedCartOp({ cartId });
    } else {
      setLookupOptimizedCartOp({ cartId: 0, loaded: true, processed: true });
    }
  }, [
    pharmacyId,
    optimizeCartResponse,
    lookupOptimizedCartOp,
    setLookupOptimizedCartOp,
    getAccessTokenSilently,
  ]);

  useEffect(() => {
    (async () => {
      if (
        !pharmacyId ||
        !lookupOptimizedCartOp ||
        lookupOptimizedCartOp.loaded
      ) {
        return;
      }
      setLookupOptimizedCartOp({ ...lookupOptimizedCartOp, loaded: true });
      try {
        const token = await getAccessTokenSilently();
        const response = await getOptimizedCart(
          lookupOptimizedCartOp.cartId,
          token
        );
        if (!response.data) return;

        const { cart } = response.data.cartSnapshot;
        const rxNumbers = cart.map((item) => item.id);
        response.data.prescriptions = await getPrescriptionsByRxNumber(
          pharmacyId,
          rxNumbers
        );
        setLoadedOptimization(response);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [
    pharmacyId,
    lookupOptimizedCartOp,
    setLoadedOptimization,
    getAccessTokenSilently,
    getPrescriptionsByRxNumber,
  ]);

  useEffect(() => {
    if (loadedOptimization && loadedOptimization.data) {
      const newPrescriptionsById: Record<string, ItemInCart> = {};

      const merged = mergePrescriptionsAndCartData(
        loadedOptimization.data.prescriptions,
        loadedOptimization.data.cartSnapshot.cart,
        loadedOptimization.data.referenceData.drugs
      );
      merged.forEach((item) => {
        if (item.rxNumber) {
          newPrescriptionsById[item.rxNumber] = item;
        }
      });

      loadedOptimization.data.cartSnapshot.inventory.forEach((item) => {
        if (item.key) {
          newPrescriptionsById[item.key] = item;
        }
      });
      setPrescriptionItemsInCart(merged.filter((item) => item.rxNumber));
      setPrescriptionsById(newPrescriptionsById);
      setCartReconstructedOptimization(loadedOptimization);
    }
  }, [loadedOptimization, setPrescriptionsById]);

  useEffect(() => {
    if (lookupOptimizedCartOp?.processed) {
      return;
    }
    if (cartReconstructedOptimization && lookupOptimizedCartOp?.cartId) {
      setOptimizeCartResponse(cartReconstructedOptimization);
      setLookupOptimizedCartOp({ ...lookupOptimizedCartOp, processed: true });
    }
  }, [cartReconstructedOptimization, lookupOptimizedCartOp]);

  useEffect(() => {
    const newPrescriptionsById = {
      ...keyBy(inventory, "key"),
      ...keyBy(prescriptionItemsInCart, "rxNumber"),
    };
    setPrescriptionsById(newPrescriptionsById);
  }, [inventory, prescriptionItemsInCart]);

  useEffect(() => {
    if (!optimizeCartResponse?.data) return;

    const { supplierOrders } = optimizeCartResponse.data.selections;
    const newSupplierOrders = supplierOrders.map((supplierOrder) => {
      const { supplierId, items } = supplierOrder;
      const supplier = getSupplierById(supplierId);
      const shippingInfo = getShippingInfo(items, supplier);
      const { shippingFee, itemsTotals } = shippingInfo;

      const newSupplierOrder: SupplierOrder_All = {
        ...supplierOrder,
        shippingInfo,
        shippingCost: shippingFee,
        buyingCost: itemsTotals.total,
      };
      return newSupplierOrder;
    });

    if (isEqual(supplierOrders, newSupplierOrders)) return;

    const newOptimizedCart: OptimizedCartResponse_All = {
      ...optimizeCartResponse,
      data: {
        ...optimizeCartResponse.data,
        selections: {
          ...optimizeCartResponse.data.selections,
          supplierOrders: newSupplierOrders,
        },
      },
    };
    setOptimizeCartResponse(newOptimizedCart);
  }, [getSupplierById, optimizeCartResponse]);

  return {
    shoppingState: {
      useCase,
      hasPricesChanged,
      orgSuppliersList,
      optimizeCartResponse,
      lookupOptimizedCartOp,
      prescriptionItemsInCart,
      itemPurchaseDetailsInCart,
      isVisitedItem,
      getVisitedItemsCount,
      addOptimizeCartResponse,
      updateItemValue_undoable,
      getShippingFeeSimulation,
      getPrescriptionsWithDetails,
      deletePrescriptionGroupAdjustmentsByRxNumbers_undoable,
    },
    shoppingUpdater: {
      addVisitedItems,
      addInventoryItem,
      removeVisitedItem,
      removePrescription,
      setHasPricesChanged,
      addOrgSuppliersList,
      updateOptimizeCartQty,
      updatePrescriptionNote,
      updateOptimizeCartQtyAlt,
      updatePrescriptionStatus,
      addSupplierToOptimizeCart,
      addItemPurchaseDetailsList,
      updatePrescriptionPackSize,
      setPrescriptionItemsInCart,
      updateOptimizeCartResponse,
      updateBuyLaterItemPurchaseBy,
      updatePrescriptionPurchaseBy,
      updatePrescriptionsPurchaseBy,
      updatePrescriptionPackQuantity,
      updatePrescriptionManufacturer,
      moveShoppingListItemToBuyLater,
      moveBuyLaterItemToShoppingList,
      deletePrescriptionGroupAdjustments,
      updatePrescriptionGroupPurchaseQuantity,
      updatePrescriptionGroupChosenSubstitution,
    },
  };
}

export function ShoppingContextProvider({ children }: { children: ReactNode }) {
  const { shoppingState, shoppingUpdater } = useShoppingContextProvider();
  return (
    <ShoppingStateContext.Provider value={shoppingState}>
      <ShoppingUpdaterContext.Provider value={shoppingUpdater}>
        {children}
      </ShoppingUpdaterContext.Provider>
    </ShoppingStateContext.Provider>
  );
}

export function useShoppingState() {
  const context = useContext(ShoppingStateContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingState must be used within a ShoppingContextProvider"
    );
  }
  return context;
}

export function useShoppingUpdater() {
  const context = useContext(ShoppingUpdaterContext);
  if (context === undefined) {
    throw new Error(
      "useShoppingUpdater must be used within a ShoppingContextProvider"
    );
  }
  return context;
}
