import React from "react";
import "twin.macro";
import type {
  SupplierAccountsSummaryView,
  SupplierAccountRecordView,
} from "../EnhancedSupplierAccount.constants";
import { WideContainer } from "../../../components/containers/WideContainer";
import { EnhancedSupplierAccountSummaryHeader } from "./EnhancedSupplierAccountSummaryHeader";
import { EnhancedSupplierAccountSummaryEcommerceSuppliers } from "./EnhancedSupplierAccountSummaryEcommerceSuppliers";
import { EnhancedSupplierAccountSummaryOfflineSuppliers } from "./EnhancedSupplierAccountSummaryOfflineSuppliers";
import { EnhancedSupplierAccountSummaryTable } from "./EnhancedSupplierAccountSummaryTable";

export function EnhancedSupplierAccountSummary({
  tableData,
  workstationName,
  offlineSuppliers,
  ecommerceSuppliers,
  shouldShowAllPasswords,
  togglePassword,
  showAllPasswords,
  hideAllPasswords,
  setCurrentEditedSupplierName,
}: {
  tableData: SupplierAccountsSummaryView[];
  offlineSuppliers: SupplierAccountsSummaryView[];
  ecommerceSuppliers: SupplierAccountsSummaryView[];
  shouldShowAllPasswords: boolean;
  workstationName?: string;
  togglePassword: (account: SupplierAccountRecordView) => void;
  showAllPasswords: () => void;
  hideAllPasswords: () => void;
  setCurrentEditedSupplierName: (supplierName: string) => void;
}) {
  return (
    <div tw="mb-[120px]">
      <EnhancedSupplierAccountSummaryHeader
        workstationName={workstationName}
        shouldShowAllPasswords={shouldShowAllPasswords}
        showAllPasswords={showAllPasswords}
        hideAllPasswords={hideAllPasswords}
      />

      <WideContainer>
        <EnhancedSupplierAccountSummaryTable
          tableData={tableData}
          togglePassword={togglePassword}
          setCurrentEditedSupplierName={setCurrentEditedSupplierName}
        />

        <EnhancedSupplierAccountSummaryEcommerceSuppliers
          suppliers={ecommerceSuppliers}
        />

        <EnhancedSupplierAccountSummaryOfflineSuppliers
          suppliers={offlineSuppliers}
        />
      </WideContainer>
    </div>
  );
}
