import type { AgChartOptions, AgPieSeriesTooltipRendererParams } from "ag-charts-community";
import type { CoveredRatioType } from "./summary.types";
import { rxChartTheme } from "../../../../Performance/reports/rxChart.theme";
import { AgCharts } from "ag-charts-react";
import { manufacturerReportPercentFormatter } from "../utils/manufacturerReportFormatters";

function renderTooltip({
  datum,
  calloutLabelKey = 'coverage'
}: AgPieSeriesTooltipRendererParams<any>) {
  return {
    title: datum[calloutLabelKey],
    content: `<p>Count: ${datum["fillCount"]}</p>
              <p>Percent: ${manufacturerReportPercentFormatter(datum["percent"], 2)}</p>`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Covered Ratio",
    textAlign: "left"
  },
  subtitle: {
    text: "Of Dispensed Fills",
    textAlign: "left"
  },
  series: [
    {
      type: "pie",
      calloutLabelKey: "coverage",
      sectorLabelKey: "percent",
      angleKey: "percent",
      sectorLabel: {
        enabled: true,
        color: "#fff",
        fontSize: 10,
        formatter: ({ value }) => {
          return manufacturerReportPercentFormatter(value, 1);
        }
      },
      tooltip: {
        renderer: renderTooltip
      }
    }
  ]
}

export function CoveredRatioPieChart({ data }: { data?: CoveredRatioType[] }) {
  return <AgCharts options={{ ...OPTIONS, data }} />;
}
