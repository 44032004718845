import React from "react";
import { styled } from "twin.macro";
import { DEFAULT_TEXT_CSS, getTextCss } from "../typography";
import { GREY_2, GREY_3 } from "../colors";

export const SimpleTable = styled.table`
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  border: 1px solid ${GREY_3};

  th,
  td {
    padding: 9px 10px;
    vertical-align: top;
  }

  tr {
    border: 1px solid ${GREY_3};

    @media print {
      border-color: black;
    }
  }

  td {
    ${DEFAULT_TEXT_CSS};
    border: 1px solid ${GREY_3};

    @media print {
      border-color: black;
    }
  }

  th {
    ${getTextCss({ weight: "bold" })};
    text-align: left;
    background-color: ${GREY_3};
    border: 1px solid ${GREY_3};
    border-left-color: ${GREY_2};
    border-right-color: ${GREY_2};

    &:first-of-type {
      border-left-color: ${GREY_3};

      @media print {
        border-left-color: black;
      }
    }

    &:last-of-type {
      border-right-color: ${GREY_3};

      @media print {
        border-right-color: black;
      }
    }

    @media print {
      background-color: initial;
      border-color: black;
    }
  }

  @media print {
    border-color: black;
  }
`;
