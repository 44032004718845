import styled from "@emotion/styled/macro";
import { css } from "twin.macro";
import {
  RxColor,
  getColorCss,
  RxColorOpacity,
  getBorderColorCss,
  getOutlineColorCss,
  getBackgroundColorCss,
} from "./colors";

const BOX_TRANSITIONS_CSS = css`
  transition: border 500ms ease-out, background-color 500ms ease-out, color 500ms ease-out, outline-color 500ms ease-out;
`;

export const Box = styled.div(
  ({
    color,
    bgColor,
    borderColor,
    outlineColor,
    bgColorOpacity,
  }: {
    color?: RxColor;
    bgColor?: RxColor;
    borderColor?: RxColor;
    outlineColor?: RxColor;
    bgColorOpacity?: RxColorOpacity;
  }) => [
    getColorCss(color),
    getBorderColorCss(borderColor),
    getOutlineColorCss(outlineColor),
    getBackgroundColorCss(bgColor, bgColorOpacity),
    BOX_TRANSITIONS_CSS,
  ]
);
