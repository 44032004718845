import type {
  AgChartOptions,
  AgDonutSeriesTooltipRendererParams,
} from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import { rxChartTheme } from "../rxChart.theme";
import { MissedOpportunityCostInefficientSupplierPurchasesType } from "./MissedOpportunities.types";
import { currencyFormatter, numberFormatter } from "../utils/performanceReportFormatters";

function renderTooltip({
  datum,
  calloutLabelKey = "coverage",
}: AgDonutSeriesTooltipRendererParams<any>) {
  return {
    title: datum[calloutLabelKey],
    content: `<p>Count: ${datum["count"]}</p>
              <p>Total: ${currencyFormatter(datum["total"])}</p>`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  container: document.getElementById("costInefficientSupplierPurchasesChart"),
  title: {
    text: "Suppliers with Highest Number of Cost Inefficient Drug Purchases",
    textAlign: "left",
  },
  height: 416,
  series: [
    {
      type: "donut",
      calloutLabelKey: "purchasedSupplier",
      angleKey: "count",
      sectorLabelKey: "count",
      innerRadiusRatio: 0.7,
      calloutLabel: {
        enabled: false,
      },
      sectorLabel: {
        formatter: ({ value }) => {
          return numberFormatter(value);
        },
      },
      tooltip: {
        renderer: renderTooltip,
      },
      sectorSpacing: 3,
    },
  ],
  legend: {
    position: "left",
  },
};

export function CostInefficientSupplierPurchasesChart({
  data,
}: {
  data?: MissedOpportunityCostInefficientSupplierPurchasesType[];
}) {
  return <AgCharts options={{ ...OPTIONS, data }} />;
}
