import { useEffect, useState } from "react";
import "twin.macro";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import type { ManufacturerStoreItem } from "../../Catalog.constants";
import type { CatalogItemsTopSellingItem } from "../../../../../../utilities/types";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { Box } from "../../../../../../components/rxLibrary/box";
import { CatalogItem } from "../CatalogItem/CatalogItem";

type TopSellingItem = {
  item: ManufacturerStoreItem;
  displaySequenceNumber: number;
};

export function CatalogItemsTopSellingItems({
  topSellingItems,
  shoppingListItems,
}: {
  shoppingListItems: ManufacturerStoreItem[];
  topSellingItems?: CatalogItemsTopSellingItem[];
}) {
  const [items, setItems] = useState<TopSellingItem[]>();

  useEffect(() => {
    if (!topSellingItems?.length || !shoppingListItems?.length) {
      setItems(undefined);
      return;
    }

    const itemsBySupplierItemNumber = keyBy(
      shoppingListItems,
      "catalogInfo.supplierItemNumber"
    );
    const items = topSellingItems.map<TopSellingItem>((topSelling) => {
      const { supplierItemNumber, displaySequenceNumber } = topSelling;
      const item = itemsBySupplierItemNumber[supplierItemNumber];
      return { item, displaySequenceNumber };
    });
    const sortedItems = sortBy(items, "displaySequenceNumber");
    setItems(sortedItems);
  }, [shoppingListItems, topSellingItems]);

  if (!items?.length) return null;
  return (
    <Box bgColor="white-1" tw="rounded-lg px-[26px] pt-[33px] pb-[21px]">
      <Text tw="mb-[17px]" size={21} block>
        Top Selling Items
      </Text>

      {items?.map(({ item }) => (
        <CatalogItem key={item.ndc} item={item} />
      ))}
    </Box>
  );
}
