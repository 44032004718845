import type { Supplier } from "../../../../../../utilities/types";
import type { ShippingFeeData } from "../../../../../../utilities/shipping/shipping.constants";
import { getMinShipDescription } from "../../../../../../utilities/shipping/shipping";
import { currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits } from "../../../../../../utilities/numbers/currency";

export function buildShoppingListTableRowShippingWarning({
  supplier,
  shippingFeeSimulation,
}: {
  supplier: Supplier;
  shippingFeeSimulation?: {
    shippingFeeDataSimulation: ShippingFeeData;
    initialShippingInfo?: ShippingFeeData;
  };
}) {
  if (!shippingFeeSimulation) return;

  const { initialShippingInfo, shippingFeeDataSimulation } =
    shippingFeeSimulation;
  const { shippable, shippingFee } = shippingFeeDataSimulation;

  if (shippable) {
    if (!shippingFee) return;
    if (!!initialShippingInfo && initialShippingInfo?.shippingFee) return;
    const formattedShippingFee =
      currencyNoCentsUnlessCentsPresentThenShowTwoSigDigits(shippingFee);
    return `Note: Changing suppliers will add ${formattedShippingFee} shipping fee`;
  }

  if (!!initialShippingInfo && !initialShippingInfo?.shippable) return;
  const minShipDescription = getMinShipDescription(supplier, {
    ship: true,
    abbrev: true,
    capitalize: true,
  });
  return `Note: ${minShipDescription} will not be met`;
}
