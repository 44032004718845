import omit from "lodash/omit";

export type PaginationConfig = {
  currentPage: number;
  pageSize?: number;
  totalRows?: number;
  totalPageCount?: number;
};

export type UpdatePagination = (newPagination: Partial<PaginationConfig>) => void;

export type ApiPaginationRequest = {
  pageSize?: number;
  pageNumber?: number;
}

export function removePaginationFields<T extends Record<string, any>>(obj: T) {
  const newObj = omit(obj, [
    "pageSize",
    "totalRows",
    "currentPage",
    "totalPageCount",
  ]);
  return newObj;
}
