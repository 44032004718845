import React from "react";
/**
* ==============================================
* Shopping Switch, @mui/material/Swicth
* Shopping Modal, @mui/material/Modal
* Shopping Radio button, @mui/material/Radio
* ==============================================
*/

export const modalSmallStyle = {
  position: "absolute",
  width: "800px",
  top: "40px",
  left: "calc(50% - 400px)",
  right: "40px",
  border: "1px solid #ccc",
  background: "#F5F5F5",
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  borderRadius: "9px",
  outline: "none",
  padding: "64px 48px",
};
