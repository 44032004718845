import React from "react";
import "twin.macro";
import EditOutlined from "@ant-design/icons/EditOutlined";
import { Link } from "react-router-dom";
import {
  AdminPharmacyEmailSubscription,
  AdminPharmacyEmailSubscriptionOptions,
} from "../../../../../utilities/types";
import {
  getAdminPharmaciesEmailSubscriptions,
  getAdminPharmacyEmailSubscriptionOptions,
} from "../../../../../services/legacy/pharmacyEmailSubscriptions";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { getDateSorter } from "../../../../../components/antd/table/getDateSorter";
import { renderDate } from "../../../../../components/antd/table/renderDate";
import { useListTable } from "../../../../../components/antd/table/useListTable";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { IconPlus } from "../../../../../components/rxLibrary/icons";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { renderAdminPharmacy } from "../../../../../components/antd/table/renderAdminPharmacy";
import { getColumnSearchProps } from "../../../../../components/antd/table/getColumnSearchProps";
import { useColumnsWithQueryParamsFilters } from "../../../../../components/antd/table/useColumnsWithQueryParamsFilters";
import { useTableColumnsWithOptions } from "../../../../../components/antd/table/useTableColumnsWithOptions";

const BREADCRUMBS = [{ title: "Pharmacy Email Subscription" }];

const COLUMNS: TableColumns<AdminPharmacyEmailSubscription> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 50,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "pharmacyId",
    title: "Pharmacy ID",
    width: 80,
    fixed: "left",
    sorter: getNumberSorter("pharmacyId"),
  },
  {
    dataIndex: "pharmacy",
    title: "Pharmacy",
    width: 150,
    fixed: "left",
    sorter: getTextSorter("pharmacy.name"),
    render: renderAdminPharmacy,
  },
  {
    dataIndex: "email",
    title: "Email",
    width: 150,
    ...getColumnSearchProps("email"),
    sorter: getTextSorter("email"),
  },
  {
    dataIndex: "name",
    title: "Name",
    width: 70,
    fixed: "left",
    sorter: getTextSorter("name"),
  },
  {
    dataIndex: "createdAt",
    title: "Created At",
    width: 90,
    sorter: getDateSorter("createdAt"),
    render: renderDate,
  },
  {
    dataIndex: "updatedAt",
    title: "Updated At",
    width: 90,
    sorter: getDateSorter("updateAt"),
    render: renderDate,
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 80,
    fixed: "right",
    render: (id) => (
      <div>
        <Link to={`/admin/pharmacy/email/subscription/${id}`}>
          <IconButton>
            <EditOutlined rev={undefined} />
          </IconButton>
        </Link>
      </div>
    ),
  },
];

function formatColumn(
  dataIndex: string,
  options: AdminPharmacyEmailSubscriptionOptions
) {
  if (dataIndex === "pharmacy") {
    const filters = options[dataIndex].map((v) => ({
      text: `${v.name} - ${v.id}`,
      value: v.id,
    }));
    const onFilter = (value: any, record: any) => {
      return record[dataIndex]?.id.toString() === value.toString();
    };
    return { filters, filterSearch: true, onFilter };
  }
}

export function AdminPharmacyEmailSubscriptions() {
  const { list, isLoading } = useListTable({
    name: "emailSubscriptions",
    fetchList: getAdminPharmaciesEmailSubscriptions,
  });

  const columns = useTableColumnsWithOptions({
    name: "emailSubscriptionOptions",
    columns: COLUMNS,
    fetchOptions: getAdminPharmacyEmailSubscriptionOptions,
    formatColumn,
  });

  const { columnsWithFilters, onTableChange } =
    useColumnsWithQueryParamsFilters(columns);

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/pharmacy/email/subscription/new">
          <Button icon={<IconPlus />}>Add Pharmacy Email Subscription</Button>
        </Link>
      </AdminManagementHeader>

      <AdminManagementTable
        columns={columnsWithFilters}
        loading={isLoading}
        dataSource={list}
        onChange={onTableChange}
      />
    </>
  );
}
