import { IDetailCellRendererParams, GridReadyEvent } from "ag-grid-community";
import cloneDeep from "lodash/cloneDeep";
import { formatColumnDefs } from "./formatColumnDefs";

export function formatDetailCellRendererParams(
  detailCellRendererParams?: Partial<IDetailCellRendererParams>
) {
  const newDetailCellRendererParams = cloneDeep(detailCellRendererParams);
  if (!newDetailCellRendererParams?.detailGridOptions) {
    return detailCellRendererParams;
  }

  const { columnDefs, onGridReady } =
    newDetailCellRendererParams.detailGridOptions;

  newDetailCellRendererParams.detailGridOptions.onGridReady = (
    event: GridReadyEvent
  ) => {
    onGridReady && onGridReady(event);
  };

  if (!columnDefs) {
    return newDetailCellRendererParams;
  }

  const newColumnDefs = formatColumnDefs(columnDefs);
  newDetailCellRendererParams.detailGridOptions.columnDefs = newColumnDefs;

  return newDetailCellRendererParams;
}
