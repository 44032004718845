import type { DrugInfo } from "../types";

export function getDrugMeasureDisplay(drug: DrugInfo): string {
  const uom = drug.uom;

  if (uom === "Each") {
    return "Unit";
  }
  if (uom === "Milliliter") {
    return "ml";
  }
  if (uom === "Gram") {
    return "Gram";
  }

  return "";
}
