import React from "react";
import "twin.macro";
import { DrugInfo } from "../../../../../../utilities/types";

export function ManufacturerCol({ drug }: { drug: DrugInfo }) {
  return (
    <>
      <p>
        <strong>{drug.manufacturer}</strong>
      </p>
      <p>Manufacturer</p>
    </>
  );
}
