import { AgCharts } from "ag-charts-react";
import type { FillsByOccCodeType } from "./summary.types";
import type { AgCartesianSeriesTooltipRendererParams, AgChartOptions } from "ag-charts-community";
import { manufacturerReportPercentFormatter } from "../utils/manufacturerReportFormatters";
import { rxChartTheme } from "../../../../Performance/reports/rxChart.theme";

function renderTooltip({
  datum,
  xKey,
}: AgCartesianSeriesTooltipRendererParams) {
  return {
    title: datum[xKey],
    content: `<p>% of Fills: ${manufacturerReportPercentFormatter(datum["percent"], 2)}</p>
              <p>Fill Count: ${datum["fillCount"]}</p>`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Fills by OCC Code ",
    textAlign: "left"
  },
  subtitle: {
    text: "Of Dispensed Fills",
    textAlign: "left"
  },
  series: [
    {
      type: "bar",
      xKey: "occCode",
      yKey: "percent",
      yName: "Percent",
      direction: "horizontal",
      cornerRadius: 10,
      fill: "rgb(55, 176, 103)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
      label: {
        enabled: true,
        placement: "inside",
        color: "#fff",
        fontSize: 10,
        formatter: ({ value }) => {
          return ` ${manufacturerReportPercentFormatter(value, 1)}`;
        }
      }
    },
  ],
  axes: [
    {
      type: "category",
      position: "left",
    },
    {
      type: "number",
      position: "bottom",
      label: {
        formatter: function({ value }) {
          return manufacturerReportPercentFormatter(value, 1);
        }
      },
    },
  ],
};

export function FillsByOccCodeBarChart({
  data,
}: {
  data?: FillsByOccCodeType[];
}) {
  return <AgCharts options={{ ...OPTIONS, data }} />;
}