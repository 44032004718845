import React, { useCallback } from "react";
import { styled } from "twin.macro";
import type { DrugInfo } from "../../../utilities/types";
import {
  DrugSearch,
  IHandleDrugClick,
  IHandleSearchNdc,
  IHandleSearchResults,
  useDrugSearch,
} from "../../DrugSearch/DrugSearch";
import { Box } from "../../rxLibrary/box";
import { Container } from "../../containers/Container";

export function InventoryModalDrugSearchScreen({
  onCancel,
  getData,
}: {
  onCancel: () => void;
  getData: (data: {
    ndc?: string;
    screen?: number;
    drug?: DrugInfo;
  }) => Promise<void>;
}) {
  const handleSearchResults: IHandleSearchResults = useCallback(
    async ({ searchResults }) => {
      if (searchResults.length === 1) {
        const drug = searchResults[0].representativeDrugInfo;
        await getData({ ndc: drug.ndc, drug });
        return true;
      }
    },
    [getData]
  );

  const handleSearchNdc: IHandleSearchNdc = useCallback(
    async (ndc: string) => {
      await getData({ ndc });
      return true;
    },
    [getData]
  );

  const handleDrugClick: IHandleDrugClick = useCallback(
    async ({ drug }) => {
      await getData({ drug, screen: 3 });
    },
    [getData]
  );

  const drugSearch = useDrugSearch({ handleSearchResults, handleSearchNdc });
  const { drugSearchValues, drugSearchResults, drugSearchResultsState } =
    drugSearch;

  return (
    <>
      <Box bgColor="grey-4">
        <Container tw="flex items-center justify-between h-[72px]">
          <Title>Add Inventory Item</Title>
          <CancelBtn onClick={onCancel}>Cancel</CancelBtn>
        </Container>
      </Box>

      <DrugSearch
        drugSearchValues={drugSearchValues}
        drugSearchResults={drugSearchResults}
        drugSearchResultsState={drugSearchResultsState}
        handleDrugClick={handleDrugClick}
      />
    </>
  );
}

const Title = styled.span`
  font-family: Roboto;
  font-size: 21px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1d1d1d;
`;

const CancelBtn = styled.button`
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: #1d1d1d;
  text-decoration: underline;
`;
