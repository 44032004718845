import "twin.macro";
import { Tooltip } from "antd";
import { Box } from "../../rxLibrary/box";

export function TitleWithTooltip({
  title,
  tooltip,
}: {
  title: string;
  tooltip: string;
}) {
  return (
    <Tooltip title={tooltip}>
      <div tw="flex items-center gap-x-1">
        <p tw="flex-1">{title}</p>

        <Box
          tw="flex items-center justify-center rounded-full text-sm w-[18px] h-[18px]"
          bgColor="blue-1"
          color="white-1"
        >
          ?
        </Box>
      </div>
    </Tooltip>
  );
}
