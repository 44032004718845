import { useCallback, useContext, useMemo } from "react";
import "twin.macro";
import { Text } from "../../../rxLibrary/typography";
import { Select } from "../../../rxLibrary/selects/select";
import { toSortedOptionData } from "../../../../utilities/helpers";
import ManufacturerContext from "../../../../contexts/ManufacturersContext";

export function AccountMenuManufacturerSelector({
  className,
}: {
  className?: string;
}) {
  const { manufacturers, manufacturer, setManufacturerId } =
    useContext(ManufacturerContext);

  const options = useMemo(() => {
    return toSortedOptionData(manufacturers ?? []);
  }, [manufacturers]);

  const value = useMemo(() => {
    return options.find((option) => option.value === manufacturer?.id);
  }, [options, manufacturer?.id]);

  const handleChange = useCallback(
    (newManufacturerId: any) => {
      setManufacturerId(newManufacturerId);
    },
    [setManufacturerId]
  );

  if (!manufacturers?.length || manufacturers?.length < 2) return null;
  return (
    <div className={className}>
      <Text tw="mb-[8px]" block>
        Manufacturer:
      </Text>
      <Select
        tw="w-full h-[38px]"
        value={value}
        options={options}
        onChange={handleChange}
      />
    </div>
  );
}
