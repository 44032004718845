"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logIn = logIn;
exports.requestPasswordlessEmail = requestPasswordlessEmail;
exports.requestPasswordlessEmailError = requestPasswordlessEmailError;
exports.requestPasswordlessEmailSuccess = requestPasswordlessEmailSuccess;
exports.resendEmail = resendEmail;
exports.restart = restart;
exports.sendSMS = sendSMS;
exports.toggleTermsAcceptance = toggleTermsAcceptance;
var _index = require("../../store/index");
var _actions = require("../../core/actions");
var _web_api = _interopRequireDefault(require("../../core/web_api"));
var c = _interopRequireWildcard(require("../../field/index"));
var l = _interopRequireWildcard(require("../../core/index"));
var _index4 = require("./index");
var _phone_number = require("../../field/phone_number");
var i18n = _interopRequireWildcard(require("../../i18n"));
var _captcha = require("../captcha");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function getErrorMessage(m, id, error) {
  var key = error.error;
  if (error.error === 'sms_provider_error' && (error.description || '').indexOf('(Code: 21211)') > -1) {
    key = 'bad.phone_number';
  }
  if (error.code === 'invalid_captcha') {
    var captchaConfig = l.passwordlessCaptcha(m);
    key = captchaConfig.get('provider') === 'recaptcha_v2' || captchaConfig.get('provider') === 'recaptcha_enterprise' ? 'invalid_recaptcha' : 'invalid_captcha';
  }
  return i18n.html(m, ['error', 'passwordless', key]) || i18n.html(m, ['error', 'passwordless', 'lock.fallback']);
}
function swapCaptchaAfterError(id, error) {
  var wasCaptchaInvalid = error && error.code === 'invalid_captcha';
  (0, _captcha.swapCaptcha)(id, true, wasCaptchaInvalid);
}
function requestPasswordlessEmail(id) {
  (0, _actions.validateAndSubmit)(id, ['email'], function (m) {
    sendEmail(m, id, requestPasswordlessEmailSuccess, requestPasswordlessEmailError);
  });
}
function requestPasswordlessEmailSuccess(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false);
    return (0, _index4.setPasswordlessStarted)(m, true);
  });
}
function requestPasswordlessEmailError(id, error) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var errorMessage = getErrorMessage(m, id, error);
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
  swapCaptchaAfterError(id, error);
}
function resendEmail(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.resend);
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  sendEmail(m, id, resendEmailSuccess, resendEmailError);
}
function resendEmailSuccess(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setResendSuccess);
}
function resendEmailError(id, error) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.setResendFailed);
}
function getPasswordlessConnectionName(m, defaultPasswordlessConnection) {
  var connections = l.connections(m, 'passwordless', defaultPasswordlessConnection);
  return connections.size > 0 && l.useCustomPasswordlessConnection(m) ? connections.first().get('name') : defaultPasswordlessConnection;
}
function sendEmail(m, id, successFn, errorFn) {
  var params = {
    connection: getPasswordlessConnectionName(m, 'email'),
    email: c.getFieldValue(m, 'email'),
    send: (0, _index4.send)(m)
  };
  if ((0, _index4.isSendLink)(m) && !l.auth.params(m).isEmpty()) {
    params.authParams = l.auth.params(m).toJS();
  }
  var isCaptchaValid = (0, _captcha.setCaptchaParams)(m, params, true, []);
  if (!isCaptchaValid) {
    return (0, _captcha.showMissingCaptcha)(m, id, true);
  }
  _web_api.default.startPasswordless(l.id(m), params, function (error) {
    if (error) {
      setTimeout(function () {
        return errorFn(l.id(m), error);
      }, 250);
    } else {
      successFn(l.id(m));
    }
  });
}
function sendSMS(id) {
  (0, _actions.validateAndSubmit)(id, ['phoneNumber'], function (m) {
    var params = {
      connection: getPasswordlessConnectionName(m, 'sms'),
      phoneNumber: (0, _phone_number.phoneNumberWithDiallingCode)(m),
      send: (0, _index4.send)(m)
    };
    var isCaptchaValid = (0, _captcha.setCaptchaParams)(m, params, true, []);
    if (!isCaptchaValid) {
      return (0, _captcha.showMissingCaptcha)(m, id, true);
    }
    _web_api.default.startPasswordless(id, params, function (error) {
      if (error) {
        setTimeout(function () {
          return sendSMSError(id, error);
        }, 250);
      } else {
        sendSMSSuccess(id);
      }
    });
  });
}
function sendSMSSuccess(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, function (m) {
    m = l.setSubmitting(m, false);
    m = (0, _index4.setPasswordlessStarted)(m, true);
    return m;
  });
}
function sendSMSError(id, error) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var errorMessage = getErrorMessage(m, id, error);
  l.emitAuthorizationErrorEvent(m, error);
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
  swapCaptchaAfterError(id, error);
}
function logIn(id) {
  var m = (0, _index.read)(_index.getEntity, 'lock', id);
  var authParams = l.auth.params(m).toJS();
  var params = _objectSpread({
    verificationCode: c.getFieldValue(m, 'vcode')
  }, authParams);
  if ((0, _index4.isEmail)(m)) {
    params.connection = getPasswordlessConnectionName(m, 'email');
    params.email = c.getFieldValue(m, 'email');
  } else {
    params.connection = getPasswordlessConnectionName(m, 'sms');
    params.phoneNumber = (0, _phone_number.phoneNumberWithDiallingCode)(m);
  }
  (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, true);
  _web_api.default.passwordlessVerify(id, params, function (error, result) {
    var errorMessage;
    if (error) {
      var _m = (0, _index.read)(_index.getEntity, 'lock', id);
      errorMessage = getErrorMessage(_m, id, error);
      if (error.logToConsole) {
        console.error(error.description);
      }
      l.emitAuthorizationErrorEvent(_m, error);
      return (0, _index.swap)(_index.updateEntity, 'lock', id, l.setSubmitting, false, errorMessage);
    } else {
      return (0, _actions.logInSuccess)(id, result);
    }
  });
}
function restart(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.restartPasswordless);
  (0, _captcha.swapCaptcha)(id, true, false);
}
function toggleTermsAcceptance(id) {
  (0, _index.swap)(_index.updateEntity, 'lock', id, _index4.toggleTermsAcceptance);
}
