import React from "react";
import "twin.macro";
import type { Dayjs } from "dayjs";
import { Text } from "../typography";
import { HumanizedTimeAgo } from "../../dates/HumanizedTimeAgo";
import { SyncButton } from ".";

export function SyncButtonAndText({
  text,
  loading,
  syncDate,
  className,
  loadingText,
  syncDatePrefix,
  onClick,
}: {
  text?: string;
  syncDate?: Dayjs;
  loading?: boolean;
  className?: string;
  loadingText?: string;
  syncDatePrefix?: string;
  onClick?: () => void | Promise<void>;
}) {
  return (
    <div
      tw="flex justify-start items-center space-x-[2px]"
      className={className}
    >
      <SyncButton
        text={text}
        loadingText={loadingText}
        loading={loading}
        disabled={loading}
        onClick={onClick}
      />

      <Text size={12} color="grey-1">
        {!loading && !!syncDate && (
          <>
            {!!syncDatePrefix && `${syncDatePrefix} - `}
            <HumanizedTimeAgo date={syncDate} capitalize />
          </>
        )}
      </Text>
    </div>
  );
}
