import { useContext, useMemo } from "react";
import AppContext from "../../contexts/AppContext";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import EnhancedClientConfigContext from "../../enhanced/EnhancedClientConfigContext";
import { UserBusinessRoles } from "../types";

const ROUTES = [
  { to: "/", title: "Home", enhancedClientOnly: false },
  { to: "/shoppingList", title: "Purchase", enhancedClientOnly: false },
  { to: "/selectDrug", title: "Price Check", enhancedClientOnly: true },
] as const;

/**
 * Redirection rules:
 * https://docs.google.com/spreadsheets/d/1JbtwLVwFTb4CXkSVaataCOf5GmRtDWRmA1V8RXaun_o/edit#gid=0
 */
export function useNavBarRoutes() {
  const { userHasRole } = useContext(AppContext);
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);

  const navBarLinks = useMemo(() => {
    if (!currentBuyingPharmacy || !userHasRole) return;

    if (userHasRole(UserBusinessRoles.MANUFACTURING)) return;

    const { supplierScope, refillMappingBasedBuyingUsersSeeRefillReport } =
      currentBuyingPharmacy;
    if (supplierScope === "ManufacturerOnly") return;

    if (!userHasRole(UserBusinessRoles.BUYING)) return;

    let links = ROUTES.filter((route) => {
      return !route.enhancedClientOnly || enhancedClientActive;
    });

    if (
      !enhancedClientActive &&
      !userHasRole(UserBusinessRoles.REPORTING) &&
      !refillMappingBasedBuyingUsersSeeRefillReport.length
    ) {
      links = links.filter((route) => {
        return !["/", "/shoppingList"].includes(route.to);
      });
    }

    return links;
  }, [enhancedClientActive, currentBuyingPharmacy, userHasRole]);

  return navBarLinks;
}
