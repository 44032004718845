import { useContext, useMemo } from "react";

import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { stringifyQueryParams } from "../../../utilities/queryParams/stringifyQueryParams";

export function useManufacturerStoreHeaderRefillLink({
  supplierId,
  manufacturerStoreUrl,
}: {
  supplierId: number;
  manufacturerStoreUrl: string;
}) {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);

  const { refillMappingBasedBuyingUsersSeeRefillReport } =
    currentBuyingPharmacy || {};

  const manufacturerStoreHeaderRefillLink = useMemo(() => {
    const manufacturerIds = refillMappingBasedBuyingUsersSeeRefillReport
      ?.filter((refillMapping) => refillMapping.supplierId === supplierId)
      .map((refillMapping) => refillMapping.manufacturerId);
    if (!manufacturerIds?.length) return;

    const manufacturerIdsQP = stringifyQueryParams({
      manufacturer: manufacturerIds,
    });
    return `${manufacturerStoreUrl}/refill-opportunities?${manufacturerIdsQP}`;
  }, [
    supplierId,
    manufacturerStoreUrl,
    refillMappingBasedBuyingUsersSeeRefillReport,
  ]);

  return manufacturerStoreHeaderRefillLink;
}
