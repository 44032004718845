export function getLocalStorageValue<T>(key: string, defaultValue: T): T {
  const valueStr = localStorage.getItem(key);
  
  if (valueStr) {
    try {
      return JSON.parse(valueStr) as T;
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return defaultValue;
}
