import {
  BackendResponse,
  backendFetch,
  backendPost,
} from "./legacy/config/backend-api";
import type {
  AdminSupplierMapping,
  UpsertAdminSupplierMapping,
  AdminSupplierMappingOptions,
  SupplierMappingIsaCodesBySupplier,
  SupplierMappingIsaCodesByPharmacy,
  SupplierMappingCustomerAccountId,
  AdminSupplierMappingBillPharmacyInfo,
} from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";

export function getSupplierMappingIsaCodesByPharmacyId(
  client: RequestClient,
  {
    pharmacyId,
    ...config
  }: RequestClientConfig<{
    pharmacyId: number;
  }>
) {
  return client<{
    supplierMappingIsaCodesByPharmacyId: SupplierMappingIsaCodesByPharmacy[];
  }>({
    ...config,
    url: `/admin/supplier-mapping/isa-code/pharmacy/${pharmacyId}`,
  });
}

export function getSupplierMappingIsaCodesBySupplierId(
  client: RequestClient,
  {
    supplierId,
    ...config
  }: RequestClientConfig<{
    supplierId: number;
  }>
) {
  return client<{
    supplierMappingIsaCodesBySupplierId: SupplierMappingIsaCodesBySupplier[];
  }>({
    ...config,
    url: `/admin/supplier-mapping/isa-code/supplier/${supplierId}`,
  });
}

export function getSupplierMappingCustomerAccountIdsBySupplierId(
  client: RequestClient,
  {
    supplierId,
    ...config
  }: RequestClientConfig<{
    supplierId: number;
  }>
) {
  return client<{
    supplierMappingCustomerAccountIds: SupplierMappingCustomerAccountId[];
  }>({
    ...config,
    url: `/admin/supplier-mapping/customer-account-id/supplier/${supplierId}`,
  });
}

export function getSupplierMappingBillPharmacyInfo(
  client: RequestClient,
  {
    supplierId,
    pharmacyOrganizationId,
    ...config
  }: RequestClientConfig<{
    supplierId: number;
    pharmacyOrganizationId: number;
  }>
) {
  return client<{
    billPharmaciesInfo: AdminSupplierMappingBillPharmacyInfo[];
  }>({
    ...config,
    url: "/admin/supplier-mapping/bill-pharmacy-info",
    params: { supplierId, pharmacyOrganizationId },
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminSupplierMappingOptions(token: string) {
  const response = await backendFetch<{
    supplierMappingOptions: AdminSupplierMappingOptions;
  }>("/admin/supplier-mapping/options", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin supplier mapping options", response);
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminSupplierMappingSuppliersByPharmacyId(
  token: string,
  pharmacyId: number
) {
  const response = await backendFetch<{
    supplierMappingSupplierIds: number[];
  }>(`/admin/supplier-mapping/pharmacy/${pharmacyId}/supplier/id`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error(
      `Error fetching admin supplier mapping supplier ids by pharmacy id ${pharmacyId}`,
      response
    );
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminSupplierMapping(
  token: string,
  supplierMappingId: string
) {
  const response = await backendFetch<{
    supplierMapping: AdminSupplierMapping;
  }>(`/admin/supplier-mapping/${supplierMappingId}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin supplier mapping", response);
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getAdminSupplierMappings(token: string) {
  const response = await backendFetch<{
    supplierMappings: AdminSupplierMapping[];
  }>("/admin/supplier-mapping", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });

  if (response.error) {
    console.error("Error fetching admin supplier mappings", response);
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function createAdminSupplierMapping(
  token: string,
  data: UpsertAdminSupplierMapping
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost("/admin/supplier-mapping", payload, {
    headers: { Authorization: "Bearer " + token },
  });
  const response = (await responseRaw.json()) as BackendResponse<{
    supplierMapping: AdminSupplierMapping;
  }>;

  if (response.error) {
    console.error(
      "Error creating admin supplier mapping",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateAdminSupplierMapping(
  token: string,
  supplierMappingId: string,
  data: UpsertAdminSupplierMapping
) {
  const payload = JSON.stringify({ data });
  const responseRaw = await backendPost(
    `/admin/supplier-mapping/${supplierMappingId}`,
    payload,
    {
      method: "PUT",
      headers: { Authorization: "Bearer " + token },
    }
  );
  const response = (await responseRaw.json()) as BackendResponse<{
    supplierMapping: AdminSupplierMapping;
  }>;

  if (response.error) {
    console.error(
      "Error updating admin supplier mapping",
      responseRaw,
      response,
      payload
    );
  }

  return response;
}
