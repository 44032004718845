import React, { useMemo } from "react";
import IconCombinedSvg from "../../../../../../assets/icon-combined.svg";
import { formatDrugDispenseQuantity } from "../../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { SupplierOrderItemRecommendation_All } from "../../../../../../services/utils";
import { Warning } from "../../../../../../components/rxLibrary/warnings";
import { styleSvgIcon } from "../../../../../../components/rxLibrary/icons";

const IconCombined = styleSvgIcon(IconCombinedSvg);

export function OriginalValueWarning({
  type,
  item,
}: {
  type: "packSize" | "manufacturer";
  item: SupplierOrderItemRecommendation_All;
}) {
  const msg = useMemo(() => {
    const { packSizeChanged, manufacturerChanged, originalDrugs } = item;

    if (!originalDrugs) return;
    const originalDrugsKeys = Object.keys(originalDrugs);

    if (originalDrugsKeys.length === 0) return;
    const key = originalDrugsKeys[0];
    const drug = originalDrugs[key];

    let value;
    if (type === "manufacturer" && manufacturerChanged) {
      value = drug.referenceData.drugInfo.manufacturer;
    } else if (type === "packSize" && packSizeChanged) {
      value = formatDrugDispenseQuantity(drug.referenceData.drugInfo, {
        omitUnits: true,
      });
    }

    if (value !== undefined) return `Was ${value}`;
  }, [item]);

  return <Warning Icon={IconCombined}>{msg}</Warning>;
}
