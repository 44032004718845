import React, { useMemo, useState, useEffect, useCallback } from "react";
import "twin.macro";
import { Supplier } from "../../utilities/types";
import { Text } from "../rxLibrary/typography";

export function SupplierImage({
  height = 60,
  supplier,
  className,
}: {
  supplier: Supplier;
  height?: number;
  className?: string;
}) {
  const { id: supplierId, displayName } = supplier;
  const style = useMemo(() => ({ height: `${height}px` }), [height]);

  const [imgUrl, setImgUrl] = useState<string>();

  const handleImageError = useCallback(() => setImgUrl(undefined), []);

  useEffect(() => {
    setImgUrl(`/assets/suppliers/${supplierId}.png`);
  }, [supplierId]);

  return (
    <div tw="flex items-center" style={style} className={className}>
      {imgUrl ? (
        <img
          src={imgUrl}
          alt={displayName}
          onError={handleImageError}
          tw="h-full object-contain"
        />
      ) : (
        <Text size="x-large" weight="bold" block>
          {displayName}
        </Text>
      )}
    </div>
  );
}
