import isNil from "lodash/isNil";
import isObject from "lodash/isObject";

export type JsonToCsvParams = {
  data: Record<string, any>[];
  columns: {
    key: string;
    label?: string;
  }[];
};

/**
 * Format values in csv format.
 * Ref: https://datatracker.ietf.org/doc/html/rfc4180#section-2
 *  6. Fields containing line breaks (CRLF), double quotes, and commas should be enclosed in double-quotes.
 *  7. If double-quotes are used to enclose fields, then a double-quote appearing inside a field must be escaped by preceding it with another double quote.
 */
function formatValue(value: any): string {
  let newValue = isNil(value) ? "" : value;
  newValue = isObject(newValue)
    ? JSON.stringify(newValue)
    : newValue.toString();
  newValue = newValue.replace('"', '""');
  return `"${newValue}"`;
}

export function jsonToCsv({ data, columns }: JsonToCsvParams) {
  const headers = columns
    .map(({ key, label }) => {
      const value = label ?? key;
      const header = formatValue(value);
      return header;
    })
    .join(",");

  const rows = data.map((obj) => {
    const row = columns.map(({ key }) => formatValue(obj[key])).join(",");
    return row;
  });

  rows.unshift(headers);
  const rowsStr = rows.join("\n");

  return rowsStr;
}
