import { ItemInCart, Supplier } from "../../../../utilities/types";
import { CartItemsSortEnum } from "./useShoppingListItems.constants";

export function sortItems(
  sorts: CartItemsSortEnum[],
  a: ItemInCart,
  b: ItemInCart,
  getSupplierById: (id?: number) => Supplier | undefined
) {
  const [sort, ...otherSorts] = sorts;
  if (!sort) return 0;

  if (sort === CartItemsSortEnum.PATIENT) {
    const patientA = a.patient?.shortName ?? "";
    const patientB = b.patient?.shortName ?? "";
    if (patientA < patientB) return -1;
    if (patientA > patientB) return 1;
    return sortItems(otherSorts, a, b, getSupplierById);
  }

  if (sort === CartItemsSortEnum.PURCHASE_BY) {
    const aValue = a.purchaseBy ? Date.parse(a.purchaseBy) : new Date();
    const bValue = b.purchaseBy ? Date.parse(b.purchaseBy) : new Date();
    const result = Number(bValue) - Number(aValue);
    if (result === 0) sortItems(otherSorts, a, b, getSupplierById);
    return result;
  }

  if (
    sort === CartItemsSortEnum.FILL_DATE ||
    sort === CartItemsSortEnum.TIME_ADDED
  ) {
    const aValue = Date.parse(a.fillDate);
    const bValue = Date.parse(b.fillDate);
    const result = Number(bValue) - Number(aValue);
    if (result === 0) sortItems(otherSorts, a, b, getSupplierById);
    return result;
  }

  if (sort === CartItemsSortEnum.STATUS) {
    const firstStatus =
      a.status === "list"
        ? "a"
        : a.status === "add"
        ? "b"
        : a.status === "save"
        ? "c"
        : "x";

    const secondStatus =
      b.status === "list"
        ? "a"
        : b.status === "add"
        ? "b"
        : b.status === "save"
        ? "c"
        : "x";
    if (firstStatus < secondStatus) return -1;
    if (firstStatus > secondStatus) return 1;
    return sortItems(otherSorts, a, b, getSupplierById);
  }

  if (sort === CartItemsSortEnum.OPTIMIZED_DATE) {
    const aStatus =
      a.status === "list"
        ? 1
        : a.status === "add"
        ? 2
        : a.status === "processed"
        ? 3
        : a.status === "save"
        ? 4
        : 5;

    const bStatus =
      b.status === "list"
        ? 1
        : b.status === "add"
        ? 2
        : b.status === "processed"
        ? 3
        : b.status === "save"
        ? 4
        : 5;
    if (aStatus < bStatus) return -1;
    if (aStatus > bStatus) return 1;

    const aDate = a.processedAt
      ? Date.parse(a.processedAt)
      : Date.parse("12/30/9999");
    const bDate = b.processedAt
      ? Date.parse(b.processedAt)
      : Date.parse("12/30/9999");

    const result = Number(aDate) - Number(bDate);
    if (result === 0) sortItems(otherSorts, a, b, getSupplierById);
    return result;
  }

  if (sort === CartItemsSortEnum.OPTIMIZED_SUPPLIER) {
    const aStatus =
      a.status === "processed"
        ? 1
        : a.status === "list"
        ? 2
        : a.status === "add"
        ? 3
        : a.status === "save"
        ? 4
        : 5;
    const bStatus =
      b.status === "processed"
        ? 1
        : b.status === "list"
        ? 2
        : b.status === "add"
        ? 3
        : b.status === "save"
        ? 4
        : 5;
    if (aStatus < bStatus) return -1;
    if (aStatus > bStatus) return 1;

    const aSupplier = getSupplierById(a.supplierId)?.displayName;
    const bSupplier = getSupplierById(b.supplierId)?.displayName;

    if (!aSupplier && !bSupplier) {
      return sortItems(otherSorts, a, b, getSupplierById);
    }
    if (!aSupplier || !bSupplier) return aSupplier ? 1 : -1;

    if (aSupplier < bSupplier) return -1;
    if (aSupplier > bSupplier) return 1;
    return sortItems(otherSorts, a, b, getSupplierById);
  }

  if (sort === CartItemsSortEnum.RX) {
    const aValue = Number(a.rxNumber);
    const bValue = Number(b.rxNumber);

    if (!isNaN(aValue) && !isNaN(bValue)) {
      if (aValue < bValue) return -1;
      if (aValue > bValue) return 1;
      return sortItems(otherSorts, a, b, getSupplierById);
    }

    const sortNumber = (a.rxNumber ?? "").localeCompare(b.rxNumber ?? "");
    if (sortNumber === 0) return sortItems(otherSorts, a, b, getSupplierById);
    return sortNumber;
  }

  if (sort === CartItemsSortEnum.BOH) {
    const aValue = a.boh ? a.boh : 0;
    const bValue = b.boh ? b.boh : 0;
    if (aValue > bValue) return 1;
    if (aValue < bValue) return -1;
    return sortItems(otherSorts, a, b, getSupplierById);
  }

  if (sort === CartItemsSortEnum.EOH) {
    const aValue = a.eoh ? a.eoh : 0;
    const bValue = b.eoh ? b.eoh : 0;
    if (aValue > bValue) return 1;
    if (aValue < bValue) return -1;
    return sortItems(otherSorts, a, b, getSupplierById);
  }

  // dispensed item, same as SelectDrug
  const aDrug = a.drug;
  const bDrug = b.drug;

  // ------ Name
  const firstName = aDrug.name.toLowerCase();
  const secondName = bDrug.name.toLowerCase();
  if (firstName < secondName) {
    return -1;
  }
  if (firstName > secondName) {
    return 1;
  }

  // ------ Form
  // tablet
  // capsule
  // other
  const firstForm = aDrug.form
    ? aDrug.form.toLocaleLowerCase() === "tablet"
      ? "a"
      : aDrug.form.toLocaleLowerCase() === "capsule"
      ? "b"
      : aDrug.form.toLocaleLowerCase()
    : "x";
  const secondForm = bDrug.form
    ? bDrug.form.toLocaleLowerCase() === "tablet"
      ? "a"
      : bDrug.form.toLocaleLowerCase() === "capsule"
      ? "b"
      : bDrug.form.toLocaleLowerCase()
    : "x";
  if (firstForm < secondForm) {
    return -1;
  }
  if (firstForm > secondForm) {
    return 1;
  }

  // ------ Strength Unit
  const firstStrengthUni = aDrug.strength
    ? aDrug.strengthUnit
      ? aDrug.strengthUnit
      : "x"
    : "y";
  const secondStrengthUni = bDrug.strength
    ? bDrug.strengthUnit
      ? bDrug.strengthUnit
      : "x"
    : "y";
  if (firstStrengthUni < secondStrengthUni) {
    return -1;
  }
  if (firstStrengthUni > secondStrengthUni) {
    return 1;
  }

  // ------ Strength Unit
  const firstStrength = aDrug.strength ? aDrug.strength : "x";
  const secondStrength = bDrug.strength ? bDrug.strength : "x";

  if (isNaN(Number(firstStrength)) || isNaN(Number(secondStrength))) {
    const aa = firstStrength.split(/(\d+)/);
    const bb = secondStrength.split(/(\d+)/);

    for (let i = 0; i < Math.max(aa.length, bb.length); i++) {
      if (aa[i] != bb[i]) {
        const cmp1 = isNaN(parseInt(aa[i], 10)) ? aa[i] : parseInt(aa[i], 10);
        const cmp2 = isNaN(parseInt(bb[i], 10)) ? bb[i] : parseInt(bb[i], 10);
        if (cmp1 == undefined || cmp2 == undefined) {
          return aa.length - bb.length;
        } else {
          return cmp1 < cmp2 ? -1 : 1;
        }
      }
    }
  } else {
    if (Number(firstStrength) < Number(secondStrength)) {
      return -1;
    }
    if (Number(firstStrength) > Number(secondStrength)) {
      return 1;
    }
  }

  return sortItems(otherSorts, a, b, getSupplierById);
}

export function sortShoppingListItems(
  items: ItemInCart[],
  sorts: CartItemsSortEnum[],
  getSupplierById: (id?: number) => Supplier | undefined
) {
  return [...items].sort((a, b) => {
    return sortItems(sorts, a, b, getSupplierById);
  });
}
