import { useEffect, useState } from "react";
import { camelizeObj } from "../../../utilities/objects/camelizeObj";
import { paramsSerializer } from "../../../utilities/queryParams/paramsSerializer";

const { REACT_APP_DATA_PIPELINE_API_URL: root } = process.env;
const { REACT_APP_BACKEND_EDI_AUTH: authToken } = process.env;

export function useReportRequest({
  reportId,
  startDate,
  endDate,
  manufacturerId,
}: {
  reportId: number;
  startDate: string;
  endDate: string;
  manufacturerId: number;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const controller = new AbortController();
    init(controller);

    return () => {
      if (controller) {
        controller.abort();
      }
    };

    async function init(controller: AbortController) {
      setError(undefined);
      setData(undefined);
      setIsLoading(true);
      const params = {
        start_date: startDate,
        end_date: endDate,
      };
      const url = `${root}/report/${reportId}/manufacturer/${manufacturerId}/ag-charts?${paramsSerializer(params)}`;
      let response: Response;
      try {
        response = await fetch(url, {
          method: "GET",
          mode: "cors",
          headers: { Authorization: `Basic ${authToken}` },
          signal: controller.signal,
        });
      } catch (error) {
        if (error instanceof DOMException && error.name === "AbortError") {
          return;
        }

        setIsLoading(false);
        setError("Failed to load report data");
        console.error(error); // Log detailed

        return;
      }

      const responseData = await response.json();
      setData(camelizeObj(responseData));
      setIsLoading(false);
    }
  }, [reportId, startDate, endDate, manufacturerId]);

  return {
    isLoading,
    error,
    data,
  };
}
