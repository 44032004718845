import type { ItemPurchaseDetails, ItemInCart } from "../types";
import { cleanUpPurchaseByString } from "./purchaseBy/cleanUpPurchaseByString";
import { getItemType } from "./itemType/getItemType";
import { getPrescriptionId } from "./getPrescriptionId";

function formatItem<T extends ItemInCart | ItemPurchaseDetails>(item: T) {
  const { status = "added", addedAt, purchaseBy, processedAt } = item;
  const itemType = getItemType(item);

  const newAddedAt =
    status === "list" ? addedAt ?? new Date().toLocaleString("en-US") : addedAt;

  const newProcessedAt =
    status === "processed"
      ? processedAt ?? new Date().toLocaleString("en-US")
      : processedAt;

  const newPurchaseBy = cleanUpPurchaseByString(purchaseBy);

  const newItem = {
    ...item,
    status,
    itemType,
    addedAt: newAddedAt,
    purchaseBy: newPurchaseBy,
    processedAt: newProcessedAt,
  };
  return newItem;
}

export function formatItemPurchaseDetails(
  item: ItemInCart | ItemPurchaseDetails
): ItemPurchaseDetails {
  const ndc = "drug" in item ? item.drug.ndc : item.ndc;
  const id = "id" in item ? item.id : getPrescriptionId(item);

  const formattedItem = formatItem(item);
  const newItem: ItemPurchaseDetails = {
    id,
    ndc,
    status: formattedItem.status,
    itemType: formattedItem.itemType,
    manufactutrer: formattedItem.manufactutrer,
    packSize: formattedItem.packSize,
    currentFillId: formattedItem.currentFillId,
    packQuantity: formattedItem.packQuantity,
    processedAt: formattedItem.processedAt,
    note: formattedItem.note,
    purchaseBy: formattedItem.purchaseBy,
    addedAt: formattedItem.addedAt,
    supplierId: formattedItem.supplierId,
    noManufacturerPreference: formattedItem.noManufacturerPreference,
  };

  return newItem;
}

export function formatItemInCart(item: ItemInCart): ItemInCart {
  const newItem = formatItem(item);
  return newItem;
}
