import { useMemo } from "react";
import { useRequest } from "../../../../../../services/request/useRequest";
import { getSupplierMappingCustomerAccountIdsBySupplierId } from "../../../../../../services/supplierMappings";

export type UseSupplierMappingCustomerAccountIds = ReturnType<typeof useSupplierMappingCustomerAccountIds>;

export function useSupplierMappingCustomerAccountIds(supplierId?: number) {
  const config = useMemo(() => {
    if (supplierId) return { supplierId };
  }, [supplierId]);

  const requestData = useRequest({
    start: !!config,
    config,
    dataKey: "supplierMappingCustomerAccountIds",
    request: getSupplierMappingCustomerAccountIdsBySupplierId,
  });

  return requestData;
}
