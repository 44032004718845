import { useShoppingState } from "../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import { WideContainer } from "../../../../components/containers/WideContainer";
import {
  SortBySelect,
  SortDirectionEnum,
} from "../../../../components/rxLibrary/selects/SortBySelect";
import { GoToP2Button } from "../../../../components/shopping/GoToP2Button";
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxesSelectedTags,
} from "../../../../components/rxLibrary/selects/MultiSelectCheckboxes";
import { Box } from "../../../../components/rxLibrary/box";
import {
  CART_ITEMS_SORT_OPTIONS,
  CART_ITEM_FILTERS_OPTIONS,
  CartItemsSortEnum,
  ISetCartItemsSort,
  ISetCartItemsSearch,
  ISetCartItemsFilters,
  ISetCartItemsSortOrder,
} from "../useShoppingListItems/useShoppingListItems.constants";
import { SearchBarItem } from "../searchBar";
import { ShoppingListSearchBar } from "./ShoppingListSearchBar";

export function ShoppingListBar({
  searchBarItems,
  cartItemsSort,
  cartItemsFilters,
  cartItemsSortOrder,
  setCartItemsSort,
  setSearchBarFilter,
  setCartItemsFilters,
  setCartItemsSortOrder,
}: {
  searchBarItems: SearchBarItem[];
  cartItemsSort: CartItemsSortEnum;
  cartItemsFilters: string[];
  cartItemsSortOrder: SortDirectionEnum;
  setCartItemsSort: ISetCartItemsSort;
  setSearchBarFilter: ISetCartItemsSearch;
  setCartItemsFilters: ISetCartItemsFilters;
  setCartItemsSortOrder: ISetCartItemsSortOrder;
}) {
  const { itemPurchaseDetailsInCart } = useShoppingState();
  const shoppingListCount = itemPurchaseDetailsInCart.length;
  const isGoToP2Disabled = itemPurchaseDetailsInCart.length === 0;

  return (
    <Box tw="sticky z-20 top-0" bgColor="white-1">
      <WideContainer>
        <div tw="flex justify-between items-center pt-[21px]">
          <div tw="flex justify-start gap-x-2">
            <MultiSelectCheckboxes
              title="Select Filters to Apply"
              filters={cartItemsFilters}
              setFilters={setCartItemsFilters}
              options={CART_ITEM_FILTERS_OPTIONS}
            />

            <ShoppingListSearchBar
              searchBarItems={searchBarItems}
              setSearchBarFilter={setSearchBarFilter}
            />
          </div>

          <div tw="flex justify-end gap-x-[27px]">
            <SortBySelect
              selectMinWidth={130}
              value={cartItemsSort}
              options={CART_ITEMS_SORT_OPTIONS}
              sortOrder={cartItemsSortOrder}
              onChange={setCartItemsSort}
              onSortOrderChange={setCartItemsSortOrder}
            />

            <GoToP2Button disabled={isGoToP2Disabled}>
              Shopping List ({shoppingListCount})
            </GoToP2Button>
          </div>
        </div>

        <div tw="min-h-[21px] pt-[10px] pb-[27px]">
          <MultiSelectCheckboxesSelectedTags
            filters={cartItemsFilters}
            setFilters={setCartItemsFilters}
            options={CART_ITEM_FILTERS_OPTIONS}
          />
        </div>
      </WideContainer>
    </Box>
  );
}
