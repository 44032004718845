import React, {useEffect} from "react";
import "twin.macro";
import { ErrorMessage } from "../components/errors/ErrorMessage";
import {downloadClientApp} from "../services/downloads";
import {useRequestClient} from "../services/request/requestClient";

export function DownloadClientApp() {
  const requestClient = useRequestClient();
  useEffect(() => {
    if (requestClient) {
      downloadClientApp(requestClient);
    }
  }, [requestClient]);
  return (
    <>
      <main tw="pt-20 pb-[200px]">
        <ErrorMessage>
          Downloading DaylightRx App...
        </ErrorMessage>
      </main>
    </>
  );
}
