import React from "react";
import "twin.macro";
import dayjs from "dayjs";
import { Button } from "../../rxLibrary/buttons";
import { Text } from "../../rxLibrary/typography";
import { SHORT_DATE_FORMAT } from "../../../utilities/dates/dates.constants";

export function PurchaseByButtonConfirmationPopover({
  date,
  onChange,
  closePopover,
  openDatePicker,
}: {
  date?: Date;
  onChange: (date?: Date) => void;
  closePopover: () => void;
  openDatePicker: () => void;
}) {
  const dateStr = date ? dayjs(date).format(SHORT_DATE_FORMAT) : "today";

  function handleConfirmation() {
    onChange(date);
    closePopover();
  }

  function handleCancel() {
    closePopover();
    openDatePicker();
  }

  return (
    <div tw="flex flex-col justify-center w-[288px] py-[58px] px-[64px]">
      <Text weight="bold" block center tw="mb-[7px]">
        {dateStr} buying date
      </Text>

      <p tw="mb-[31px]">
        <Text block center>
          Move item to the
        </Text>
        <Text block center>
          {'"Buy by a later date" list'}
        </Text>
      </p>

      <Button block tw="mb-2" onClick={handleConfirmation}>
        Yes, move item
      </Button>
      <Button block variant="text-1" onClick={handleCancel}>
        Cancel, Buy today
      </Button>
    </div>
  );
}
