import React from "react";
import { styled } from "twin.macro";
import { ReportWrapper } from "./ReportWrapper";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5% 2.5%;
  padding: 0.5rem;
  --ag-font-size: 10px;

  > div {
    width: 48.75%;
  }

  & .ag-header-cell-label {
    font-size: 10px;
  }
`;

/**
 * Flex row wrapper for when multiple AG Grid components are on the same page
 */
export function MultiReportRowWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ReportWrapper>
      <Wrapper>{children}</Wrapper>
    </ReportWrapper>
  );
}
