import React, { useMemo } from "react";
import "twin.macro";
import {
  getAdminPharmacyEmailSubscription,
  updateAdminPharmacyEmailSubscription,
} from "../../../../../services/legacy/pharmacyEmailSubscriptions";
import { useEditFormLegacy } from "../../../../../components/antd/form/useEditForm";
import { WideContainer } from "../../../../../components/containers/WideContainer";
import { AdminPharmacyEmailSubscriptionForm } from "./AdminPharmacyEmailSubscriptionForm/AdminPharmacyEmailSubscriptionForm";

export function EditAdminPharmacyEmailSubscription() {
  const { values, onSubmit } = useEditFormLegacy({
    name: "emailSubscription",
    update: updateAdminPharmacyEmailSubscription,
    fetchValues: getAdminPharmacyEmailSubscription,
  });

  const initialValues = useMemo(() => {
    if (values) return { email: values.email, pharmacyId: values.pharmacyId };
  }, [values]);

  return (
    <WideContainer tw="pb-[200px]">
      <AdminPharmacyEmailSubscriptionForm
        isUpdateForm
        initialValues={initialValues}
        onSubmit={onSubmit}
      />
    </WideContainer>
  );
}
