import { useCallback, useMemo } from "react";
import { Form, Select } from "antd";
import sortBy from "lodash/sortBy";
import type { AdminSupplierMappingBillPharmacyInfo } from "utilities/types";
import type { SelectOption } from "components/rxLibrary/selects/select";
import { OptionLabelWithWarning } from "components/antd/select/OptionLabelWithWarning";
import { useRequest } from "services/request/useRequest";
import { getSupplierMappingBillPharmacyInfo } from "services/supplierMappings";
import { AdminSupplierMappingPharmacyOption } from "./AdminSupplierMappingForm.utils";

export function BillToPharmacyInput({
  selectedPharmacy,
  initialBillToPharmacyId,
}: {
  selectedPharmacy?: AdminSupplierMappingPharmacyOption;
  initialBillToPharmacyId?: number;
}) {
  const pharmacyOrganizationId = selectedPharmacy?.pharmacyOrganizationId;
  const form = Form.useFormInstance();
  const supplierId = Form.useWatch<number | undefined>("supplierId", form);

  const config = useMemo(() => {
    if (!supplierId || !pharmacyOrganizationId) return;
    return { supplierId, pharmacyOrganizationId };
  }, [supplierId, pharmacyOrganizationId]);

  const billToPharmacyOptionsFormatter = useCallback(
    (data: AdminSupplierMappingBillPharmacyInfo[]): SelectOption[] => {
      let options = data;

      const currentPharmacyId = selectedPharmacy?.value;
      if (currentPharmacyId) {
        const shouldAddCurrentPharmacy = !data.some((item) => {
          return item.pharmacyId === currentPharmacyId;
        });

        if (shouldAddCurrentPharmacy) {
          options = sortBy(
            [
              ...data,
              {
                pharmacyId: currentPharmacyId,
                pharmacyName: selectedPharmacy.label,
                customerAccountId: null,
              },
            ],
            "pharmacyName"
          );
        }
      }

      return options.map((item) => {
        const isCurrentPharmacy = item.pharmacyId === currentPharmacyId;
        const hasCustomerAccountId = !!item.customerAccountId;
        const isInitialBillToPharmacy = item.pharmacyId === initialBillToPharmacyId;

        let warning;
        let disabled = false;
        if (isInitialBillToPharmacy) {
          warning = "Initial Bill To Pharmacy";
        } else if (isCurrentPharmacy) {
          warning = "Current Pharmacy";
        } else if (!hasCustomerAccountId) {
          warning = "No Account Number";
          disabled = true;
        }

        return {
          value: item.pharmacyId,
          label: (
            <OptionLabelWithWarning
              warning={warning}
              label={item.pharmacyName}
            />
          ),
          disabled,
        };
      });
    },
    [selectedPharmacy, initialBillToPharmacyId]
  );

  const { formattedData: billToPharmacyOptions } = useRequest({
    start: !!config,
    config,
    dataKey: "billPharmaciesInfo",
    request: getSupplierMappingBillPharmacyInfo,
    formatter: billToPharmacyOptionsFormatter,
  });

  const isDisabled =
    !supplierId || !pharmacyOrganizationId || !billToPharmacyOptions?.length;

  const help = useMemo(() => {
    if (!supplierId || !pharmacyOrganizationId) {
      return "Select a Supplier and Pharmacy to get Bill To Pharmacy options";
    }
    if (!billToPharmacyOptions?.length) {
      return "No Bill To Pharmacy options available";
    }
  }, [supplierId, pharmacyOrganizationId, billToPharmacyOptions]);

  return (
    <Form.Item name="billToPharmacyId" label="Bill To Pharmacy" help={help}>
      <Select
        optionFilterProp="label"
        disabled={isDisabled}
        options={billToPharmacyOptions}
        showSearch
        allowClear
      />
    </Form.Item>
  );
}
