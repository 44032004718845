import type {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import { rxChartTheme } from "../rxChart.theme";
import type { TotalPricePaidBreakdownType } from "./overviewReport.types";
import { currencyFormatter } from "../utils/performanceReportFormatters";

function renderTooltip({
  datum,
  xKey,
  yKey,
  yName,
}: AgCartesianSeriesTooltipRendererParams) {
  return {
    title: datum[xKey],
    content: `${yName}: ${currencyFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Breakdown - Total Price Paid",
  },
  series: [
    {
      type: "bar",
      xKey: "pharmacyName",
      yKey: "totalPatientPaidAmount",
      yName: "Patient Paid Amount",
      stacked: true,
      direction: "horizontal",
      cornerRadius: 10,
      fill: "rgb(98, 150, 188)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
    {
      type: "bar",
      xKey: "pharmacyName",
      yKey: "totalInsuranceRevenue",
      yName: "Insurance Revenue",
      stacked: true,
      direction: "horizontal",
      cornerRadius: 10,
      fill: "rgb(55, 176, 103)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "left",
    },
    {
      type: "number",
      position: "bottom",
      label: {
        formatter: (params) => {
          const value = params.value / 1_000;
          return "$" + Math.floor(value) + "k";
        },
      },
    },
  ],
};

export function TotalPricePaidBreakdowmBarChart({ data }: { data?: TotalPricePaidBreakdownType[] }) {
  return <AgCharts options={{...OPTIONS, data}} />;
}
