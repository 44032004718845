import React from "react";
import "twin.macro";
import { Tooltip } from "antd";
import { Link } from "react-router-dom";
import { AdminPharmacyOrganization } from "../../../utilities/types";

export function renderAdminPharmacyOrganization(
  value: AdminPharmacyOrganization | null
) {
  if (!value) return null;
  return (
    <Tooltip title="Go to pharmacy organization page">
      <Link tw="underline" to={`/admin/pharmacy-organization/${value.id}`}>
        {value.name}
      </Link>
    </Tooltip>
  );
}
