import "twin.macro";
import { Divider } from "antd";
import { Link } from "react-router-dom";
import { Text } from "../../../components/rxLibrary/typography/Text";
import { WideContainer } from "../../../components/containers/WideContainer";

function Category({
  title,
  children,
}: {
  title: string;
  children: JSX.Element | JSX.Element[];
}) {
  return (
    <div>
      <Text tw="mb-4" size="large" weight="bold" block>
        {title}
      </Text>

      <div tw="flex flex-col space-y-2 pl-4">{children}</div>
    </div>
  );
}

export function AdminPage() {
  return (
    <WideContainer tw="pb-[200px]">
      <Category title="Admin Management">
        <Link to="/admin/account">Accounts</Link>
        <Link to="/admin/pharmacy">Pharmacies</Link>
        <Link to="/admin/pharmacy/email/subscription">
          Pharmacy Invoice Email Subscriptions
        </Link>
        <Link to="/admin/pharmacy-organization">Pharmacy Organizations</Link>
        <Link to="/admin/refill-mapping">Refill Mappings</Link>
        <Link to="/admin/supplier-mapping">Supplier Mapping</Link>
        <Link to="/admin/supplier-mapping-and-edi">
          Supplier Mapping And EDI
        </Link>
      </Category>

      <Divider />

      <Category title="Developers">
        <a
          href="https://drive.google.com/drive/folders/1-k6yXKCHEp27gU6lo6LYBimmQD2x9fi4"
          target="_blank"
          rel="noreferrer noopener"
        >
          Intranet
        </a>

        <Link to="/admin/rx-library">Rx Library</Link>

        <Link to="/admin/edi-doc">EDI OpenAPI Doc</Link>

        <a
          href="https://github.com/streamlinerx/heroku-backend/blob/main/openapi.yml"
          target="_blank"
          rel="noreferrer noopener"
        >
          Heroku Back-End OpenAPI Doc
        </a>

        <a
          href="https://drive.google.com/drive/folders/1RPn4mUiJBIWX5jrtyKodtrlNpGWo7eCc"
          target="_blank"
          rel="noreferrer noopener"
        >
          Tech Documentation
        </a>
      </Category>

      <Divider />
      <Category title="Others">
        <Link to="/demo">Demo Mode</Link>
      </Category>
    </WideContainer>
  );
}
