import { useEffect, useState, useMemo } from "react";
import capitalize from "lodash/capitalize";
import { dayjsWithDuration } from "../../../../../../utilities/dates/dayjsWithDuration";
import { formatDateToStringHour } from "../../../../../../utilities/dates/formatDateToStringHour";
import { formatStringHourToDate } from "../../../../../../utilities/dates/formatStringHourToDate";

function getNextCutoffTime(cutoffTimes: string[]) {
  const nextCutoffTime = cutoffTimes.find((cutoffTime) => {
    const cutoffTimeDate = formatStringHourToDate(cutoffTime);
    if (!cutoffTimeDate) return false;
    const isAfter = cutoffTimeDate.isAfter(new Date());
    return isAfter;
  });
  return nextCutoffTime;
}

function getTodayCutoffTimes(cutoffTimes: string | string[][]) {
  const day = new Date().getDay();
  // we don't ship refrigerated items on Friday
  if (day === 5) return [];
  if (!Array.isArray(cutoffTimes)) return [cutoffTimes];

  // the cutoffTimes array starts on monday
  const cutoffTimesDay = day === 0 ? 6 : day - 1;
  return cutoffTimes[cutoffTimesDay];
}

// TODO: Check if remove when we implement the BE foam items
export function useFoamCutOffTime({
  cutoffTimes,
  hasFoamItems,
}: {
  hasFoamItems: boolean;
  cutoffTimes?: string | string[][];
}) {
  const [nextCutoffTime, setNextCutoffTime] = useState<string>();

  const todayFoamCutoffTimes = useMemo(() => {
    if (!hasFoamItems || !cutoffTimes) return;

    const todayCutoffTimes = getTodayCutoffTimes(cutoffTimes);

    return todayCutoffTimes.map((cutoffTime) => {
      const cutoffTimeDate = formatStringHourToDate(cutoffTime);
      if (!cutoffTimeDate) return cutoffTime;

      const newCutoffTime = cutoffTimeDate.subtract(4, "hours").format("HH:mm");
      return newCutoffTime;
    });
  }, [cutoffTimes, hasFoamItems]);

  const text = useMemo(() => {
    if (!todayFoamCutoffTimes) return;

    const nextCutoffTimeDate = formatStringHourToDate(nextCutoffTime);
    const lastTodayCutoffTime =
      todayFoamCutoffTimes[todayFoamCutoffTimes.length - 1];
    const lastTodayCutoffTimeDate = formatStringHourToDate(lastTodayCutoffTime);

    if (nextCutoffTimeDate) {
      const timeUntilNextCutoffTime = capitalize(
        dayjsWithDuration
          .duration(nextCutoffTimeDate.diff(new Date()))
          .humanize()
      );

      const nextCutoffTimeHour = formatDateToStringHour(nextCutoffTimeDate);
      return `${timeUntilNextCutoffTime} to foam can cutoff time (${nextCutoffTimeHour})`;
    }

    if (!lastTodayCutoffTimeDate) {
      return "Foam can items will be shipped Monday";
    }

    const cutoffTimeHour = formatDateToStringHour(lastTodayCutoffTimeDate);
    return `Missed today's foam can cut-off of ${cutoffTimeHour}`;
  }, [nextCutoffTime]);

  useEffect(() => {
    if (!todayFoamCutoffTimes) {
      setNextCutoffTime(undefined);
      return;
    }

    const shouldStop = updateNextCutoffTime(todayFoamCutoffTimes);
    if (shouldStop) return;

    let interval: NodeJS.Timeout | undefined = setInterval(() => {
      const stopUpdates = !updateNextCutoffTime(todayFoamCutoffTimes);

      if (stopUpdates && interval) {
        clearInterval(interval);
        interval = undefined;
      }
    }, 1000);

    return () => {
      interval && clearInterval(interval);
    };

    function updateNextCutoffTime(todayCutoffTimes: string[]) {
      const newNextCutoffTime = getNextCutoffTime(todayCutoffTimes);
      setNextCutoffTime((oldNextCutoffTime) => {
        if (!newNextCutoffTime) return;
        if (oldNextCutoffTime === newNextCutoffTime) return oldNextCutoffTime;
        return newNextCutoffTime;
      });
      const shouldStop = !newNextCutoffTime;
      return shouldStop;
    }
  }, [todayFoamCutoffTimes]);

  return text;
}
