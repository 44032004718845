import isEmpty from "lodash/isEmpty";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import type {
  ShippingConfig,
  UpsertAdminSupplierMapping,
} from "../../../../../../utilities/types";
import { removeNilValuesFromObject } from "../../../../../../utilities/objects/removeNilValuesFromObject";
import { ensureArray } from "../../../../../../utilities/arrays/ensureArray";

export type AdminSupplierMappingPharmacyOption = {
  value: number;
  label: string;
  timezone: string;
  pharmacyOrganizationId?: number;
};

export type FormShippingConfig = Omit<ShippingConfig, "cutoffTimes"> & {
  cutoffTimes: (null | Dayjs)[][];
};

export type AdminSupplierMappingForm = Omit<
  UpsertAdminSupplierMapping,
  "shippingConfig"
> & {
  shippingConfig: FormShippingConfig;
};

export const CLEAN_FROM_SHIPPING_CONFIG: FormShippingConfig = {
  cutoffTimes: Array(7).fill([null]) as null[][],
};

function nonDate(t: Dayjs | null): t is Dayjs {
  return t !== null && t?.isValid();
}

export function formatSubmitCutoffTimes(
  cutoffTimes: (null | Dayjs)[][]
): undefined | string[][] {
  const filteredCutoffTimes = cutoffTimes.reduce<string[][]>((acc, times) => {
    if (!times?.length) return acc;

    const filteredTimes = times
      .filter(nonDate)
      .map((date) => date.format("HH:mm"));
    if (filteredTimes.length === 0) return acc;
    return [...acc, filteredTimes];
  }, []);

  if (!filteredCutoffTimes.length) return;
  return filteredCutoffTimes;
}

export async function cutoffTimesValidator(
  _: any,
  cutoffTimes?: (null | Dayjs)[][]
) {
  if (!cutoffTimes?.length) return;

  const errorMsg =
    "Cutoff times must be empty or have at least one hour for the all days of the week";
  if (cutoffTimes.length !== 7) throw new Error(errorMsg);

  const formattedCutoffTimes = formatSubmitCutoffTimes(cutoffTimes);

  if (!formattedCutoffTimes) return;
  if (formattedCutoffTimes.length !== 7) throw new Error(errorMsg);
}

export function isInitialSupplier({
  pharmacyId,
  supplierId,
  initialPharmacyId,
  initialSupplierId,
}: {
  pharmacyId?: number;
  supplierId?: number;
  initialPharmacyId?: number;
  initialSupplierId?: number;
}) {
  if (!pharmacyId || !supplierId || !initialPharmacyId || !initialSupplierId) {
    return false;
  }

  const isInitial =
    initialPharmacyId === pharmacyId && initialSupplierId === supplierId;

  return isInitial;
}

export function formatShippingConfig(value?: FormShippingConfig) {
  if (!value || isEmpty(value)) return;

  const { fee, minOrder, minGenericOrder, minBrandRxOrder, minGenericRxOrder } =
    value;

  const data = {
    fee,
    minOrder,
    minGenericOrder,
    minBrandRxOrder,
    minGenericRxOrder,
    notes: isEmpty(value.notes) ? null : value.notes,
    cutoffTimes: formatSubmitCutoffTimes(value.cutoffTimes),
  };
  const shippingConfig: ShippingConfig = removeNilValuesFromObject(data);
  if (isEmpty(shippingConfig)) return;

  shippingConfig.version = 1;
  return shippingConfig;
}

export function formatFormShippingConfig(shippingConfig: ShippingConfig) {
  const {
    fee,
    minOrder,
    minGenericOrder,
    minBrandRxOrder,
    minGenericRxOrder,
    notes,
    cutoffTimes,
  } = shippingConfig;

  const newNotes = typeof notes === "string" ? ensureArray(notes) : notes;

  let newCutoffTimes: (Dayjs | null)[][] = Array(7).fill([null]);
  if (typeof cutoffTimes === "string") {
    const time = dayjs(cutoffTimes, "HH:mm");
    newCutoffTimes = Array(7).fill([time]);
  } else if (Array.isArray(cutoffTimes)) {
    newCutoffTimes = cutoffTimes.map((l: any) => {
      return l.map((v: any) => (v ? dayjs(v, "HH:mm") : null));
    });
  }

  return {
    fee,
    minOrder,
    minGenericOrder,
    minBrandRxOrder,
    minGenericRxOrder,
    notes: newNotes,
    cutoffTimes: newCutoffTimes,
  };
}

export function formatAdminSupplierMappingFormSubmitValues(
  values: AdminSupplierMappingForm
): UpsertAdminSupplierMapping {
  const { shippingConfig, ...data } = values;
  const newShippingConfig = formatShippingConfig(shippingConfig) ?? {};
  return { ...data, shippingConfig: newShippingConfig };
}
