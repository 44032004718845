import React from "react";
import { AgGridReact } from "ag-grid-react";
import { useReport } from "../../../../Manufacturer/ManufacturerReports/reports/utils/useReport/useReport";
import { Text } from "../../../../../components/rxLibrary/typography";
import { TableWrapper } from "../../TableWrapper";
import type { DispensesByDeaCodeType } from "../controlledDrugs.types";
import { COLUMN_DEFS } from "./DispensesByDeaCodeTypeTable.constants";

export function DispensesByDeaCodeTypeTable({
  data,
}: {
  data?: DispensesByDeaCodeType[];
}) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
  });

  return (
    <>
      <Text size="x-large" block tw="pt-4 pl-4 pb-6">
        Controlled Item Dispense Stats
      </Text>
      <TableWrapper>
        <AgGridReact
          {...report}
          pagination
          rowData={data ?? []}
          rowSelection="multiple"
        />
      </TableWrapper>
    </>
  );
}
