import { formatDateToDayWithTime } from "../dates/formatDateToDayWithTime";
import { ItemInCart } from "../types";
import { getPrescriptionId } from "./getPrescriptionId";
import { isInventoryId } from "./isInventoryId";

export function formatFillDate(prescription: ItemInCart) {
  const id = getPrescriptionId(prescription);
  const isInventory = isInventoryId(id);
  if (!isInventory) return "-";

  const { fillDate } = prescription;
  const fillDateStr = formatDateToDayWithTime(fillDate);
  return fillDateStr;
}
