import React, { useEffect, useState } from "react";
import { styled } from "twin.macro";
import cn from "classnames";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import "../../scss/base.scss";
import "../../scss/enhNav.scss";
import { trunc } from "../util";
import { formatNdc } from "../../utilities/ndc/formatNdc";
import DispensedItem from "../../components/DispensedItem";
import {
  SupplierOrderItemRecommendation_All
} from "../../services/utils";

export type NavBarDropdownItem = {
  item: SupplierOrderItemRecommendation_All;
  visited: boolean;
  value?: string | number;
  hidden?: boolean;
  disabled?: boolean;
  warning?: string;
};

export const NavBarDropdown = ({
  label,
  className,
  placeholder,
  name,
  defaultValue,
  dropDownItems,
  disabled = false,
  callBack,
  onOpen,
  onClose,
  openTimeMs,
}: {
  name?: string;
  label?: string;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  dropDownItems: NavBarDropdownItem[];
  disabled?: boolean;
  callBack: (value: string, idx: number) => void;
  onOpen?: () => void;
  onClose?: () => void;
  openTimeMs?: number;
}): JSX.Element => {
  const [sValue, setSValue] = useState<string>(defaultValue || "");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // reset when disabled
  useEffect(() => {
    if (disabled) {
      setSValue("");
    } else {
      setSValue(defaultValue || "");
    }
  }, [defaultValue, disabled]);

  useEffect(() => {
    dropDownItems?.map((option: NavBarDropdownItem) => {
      if (option.disabled && option.value === sValue) {
        setSValue("");
        callBack && callBack("", -1);
      }
    });
  }, [dropDownItems]);

  return (
    <Wrapper className={cn("mui-dropdown", className, disabled && "disabled")}>
      {label && <span style={{ lineHeight: "16px" }}>{label}</span>}

      <Select
        disabled={disabled}
        name={name}
        value={placeholder ? "placeholder" : sValue}
        onChange={(e: SelectChangeEvent<string>) => {
          setSValue(e.target.value);
        }}
        onOpen={() => {
          setIsOpen(true);
          onOpen && onOpen();
        }}
        onClose={() => {
          setIsOpen(false);
          onClose && onClose();
        }}
        MenuProps={{
          transitionDuration: openTimeMs,
        }}
      >
        {placeholder && (
          <MenuItem disabled value="placeholder">
            <em>{placeholder}</em>
          </MenuItem>
        )}
        {dropDownItems?.map((option, index) => {
          const { value, disabled, visited, item } = option;
          return (
            <MenuItem
              key={index}
              value={value}
              disabled={disabled}
              onClick={() => {
                callBack && callBack(value as string, index);
              }}
            >
              <div tw="py-1 px-0.5 flex text-sm w-full">
                <div tw="flex-1 font-bold flex">
                  <DispensedItem
                    ndc={false}
                    drug={item.referenceData.drugInfo}
                    catalogInfo={item.referenceData.catalogInfo}
                    showQuantityDescription
                  />
                </div>
                <div tw="w-[160px]">
                  | {trunc(item.referenceData.drugInfo.manufacturer, 20)}
                </div>
                <div tw="w-[110px]">| {formatNdc(item.referenceData.drugInfo.ndc)}</div>
                <div tw="w-[54px] font-bold">
                  | <span>Qty: {item.numPackages}</span>
                </div>
                <div tw="w-[45px]">
                  {isOpen && visited && (
                    <span tw="bg-[#e5e5e5]">Visited</span>
                  )}
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &.mui-dropdown {
    padding: 0;
  }

  .MuiSelect-select {
    flex: 1;
    max-width: none;
  }
`;
