import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
const listIcon = new URL('svgurl:../assets/icon-list.svg', import.meta.url).toString();
import {modalSmallStyle} from "../scss/material";
import "../scss/base.scss";
import "../scss/enhNav.scss";
import { SupplierItemChangeOperation } from "../utilities/shoppingListTypes";
import { drugInfoToDrugNameUOMSizeStr } from "../utilities/drugInfo/drugInfoToDrugNameUOMSizeStr";
import { CatalogDrug_All } from "../services/utils";

const SUMMARIZABLE_OPERATION_TYPE = "changeSupplierItem";

export const hasSummarizableOperations = (operations: SupplierItemChangeOperation[] | null): boolean => {
  return !!(operations && operations.length > 0 && operations.some(operation => {
    return SUMMARIZABLE_OPERATION_TYPE === operation.type;
  }));
}

function possessive(supplierDisplayName: string) : string {
  return supplierDisplayName + (supplierDisplayName.endsWith("s") ? "'" : "'s");
}

export default function EnhancedCartOperationsSummaryModal({
  activateModal,
  operations,
  onClose,
  onChangeList,
} : {
  activateModal: boolean;
  operations: SupplierItemChangeOperation[];
  onClose: () => void;
  onChangeList: (supplierName: string) => void;
}): JSX.Element | null {
  const summaryOperations = operations.filter(operation => {
    return SUMMARIZABLE_OPERATION_TYPE === operation.type;
  });

  if (summaryOperations.length === 0) return null;

  const primaryOperation = summaryOperations[0];
  let primaryItem: CatalogDrug_All | null = null;
  let summaryMsg = "";
  let listName = "";
  let opt1: React.ReactNode = null;
  let opt2: React.ReactNode = null;
  if (primaryOperation.type === "changeSupplierItem") {
    if (primaryOperation.oldItem.referenceData.catalogInfo.supplier === primaryOperation.newItem.referenceData.catalogInfo.supplier) {
      primaryItem = primaryOperation.newItem;
      summaryMsg = `Updated ${possessive(primaryOperation.oldItem.referenceData.catalogInfo.supplierDisplayName)} shopping list`;
      listName = primaryOperation.oldItem.referenceData.catalogInfo.supplierDisplayName;
      opt1 = <button className="btn btn-white-huge" onClick={onClose}><span>Continue shopping</span></button>;
    } else {
      primaryItem = primaryOperation.newItem;
      summaryMsg = `Item is now in ${possessive(primaryOperation.newItem.referenceData.catalogInfo.supplierDisplayName)} shopping list`;
      listName = primaryOperation.newItem.referenceData.catalogInfo.supplierDisplayName;
      opt1 = <button className="btn btn-white-huge" onClick={onClose}><span>Continue shopping on {primaryOperation.oldItem.referenceData.catalogInfo.supplierDisplayName}</span></button>;
      opt2 = <button className="btn btn-white-huge" onClick={() => onChangeList(primaryOperation.newItem.referenceData.catalogInfo.supplier)}><span>Go to {primaryOperation.newItem.referenceData.catalogInfo.supplierDisplayName} shopping list</span></button>;
    }
  } else {
    return null;
  }

  return <Modal
    open={activateModal}
    id="cartOpsSummaryModal"
    onClose={() => {onClose()}}
    >
    <Box sx={modalSmallStyle}>
      {primaryOperation.type === "changeSupplierItem" && (
        <>
          <div className="flex justify-center pb-8">
            <img src={listIcon} alt="list icon" className="w-24 h-24" />
          </div>
          <div className="flex justify-center pb-32">
            <h2 className="listName text-lg">{listName}</h2>
          </div>
        </>
      )}
      <div className="flex justify-center pb-8">
        <h1 className="summaryMsg text-24">{summaryMsg}</h1>
      </div>
      <div className="flex justify-center pb-32">
        <h2 className="text-lg font-bold">{drugInfoToDrugNameUOMSizeStr(primaryItem.referenceData.drugInfo)}</h2>
      </div>
      <div className="flex justify-center pb-16">
        {opt1}
      </div>
      {opt2 && <div className="flex justify-center">
        {opt2}
      </div>}
    </Box>
  </Modal>
}
