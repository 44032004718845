import React from "react";
import "twin.macro";
import { DrugInfo, NDCTaggedItemList } from "../../../utilities/types";
import { formatNdc } from "../../../utilities/ndc/formatNdc";
import { Container } from "../../../components/containers/Container";
import { FullPageLoader } from "../../../components/loaders/FullPageLoader";
import { useNdcResults } from "./useNdcResults/useNdcResults";
import { NdcResultsCards } from "./NdcResultsCards";

export function NDCResults({
  ndc,
  itemsByNDC,
  suggestedDrug,
  relatedDrugsByNDC,
  enhancementExcludedSuppliers,
}: {
  ndc: string;
  itemsByNDC: NDCTaggedItemList[];
  suggestedDrug?: DrugInfo;
  relatedDrugsByNDC?: Record<string, DrugInfo>;
  enhancementExcludedSuppliers?: string[];
}) {
  const { isEmpty, ndcResults } = useNdcResults({
    itemsByNDC,
    suggestedDrug,
    relatedDrugsByNDC,
    enhancementExcludedSuppliers,
  });

  if (isEmpty) {
    return (
      <Container tw="text-center text-lg">
        Unfortunately none of your catalogs currently carry{" "}
        <span tw="font-medium">{formatNdc(ndc)}</span>
      </Container>
    );
  }

  if (!ndcResults) return <FullPageLoader />;

  return <NdcResultsCards ndc={ndc} ndcResults={ndcResults} />;
}
