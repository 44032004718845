import React from "react";
import "twin.macro";
import { Tooltip } from "antd";
import { dayjsWithDuration } from "../../../utilities/dates/dayjsWithDuration";

export function renderDate(value: any) {
  if (!value) return null;

  const date = dayjsWithDuration
    .duration(dayjsWithDuration(value).diff(new Date()))
    .humanize(true);

  return <Tooltip title={value}>{date}</Tooltip>;
}
