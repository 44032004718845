import isNil from "lodash/isNil";
import querystring, { ParsedUrlQueryInput } from "querystring";

export function stringifyQueryParams(
  values: ParsedUrlQueryInput,
  { omitNilValues = false } = {}
) {
  let formattedValues = values;

  if (omitNilValues) {
    formattedValues = Object.entries(formattedValues)
      .filter((v) => !isNil(v[1]))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  }

  const queryParams = querystring.stringify(formattedValues);
  return queryParams;
}
