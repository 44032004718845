import { useEffect, useState } from "react";
import { useAuth0 } from "../../../contexts/AuthenticationContext";
import { BackendResponse } from "../../../services/legacy/config/backend-api";
import { TableColumns } from "./table.constants";

export function useTableColumnsWithOptions<
  Col extends Record<string, any>,
  Options extends Record<string, any>,
  Name extends string
>({
  name,
  columns,
  fetchOptions,
  formatColumn,
}: {
  name: Name;
  columns: TableColumns<Col>;
  fetchOptions: (
    token: string
  ) => Promise<BackendResponse<Record<Name, Options>>>;
  formatColumn: (
    dataIndex: string,
    options: Options
  ) => Record<string, any> | undefined;
}) {
  const { getAccessTokenSilently } = useAuth0();
  const [columnsWithOptions, setColumnsWithOptions] = useState(columns);

  useEffect(() => {
    setColumnsWithOptions(columns);
    init();

    async function init() {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetchOptions(token);
        const options = response.data?.[name];
        if (!options) {
          throw new Error("Error no page column options");
        }

        const newColumns = columns.map((column) => {
          if (!("dataIndex" in column)) return column;

          const { dataIndex } = column;
          if (!dataIndex || typeof dataIndex !== "string") return column;

          const extraFields = formatColumn(dataIndex, options);
          return extraFields ? { ...column, ...extraFields } : column;
        });

        setColumnsWithOptions(newColumns);
      } catch (error) {
        console.error("Error formatting column options", error);
      }
    }
  }, [columns, fetchOptions, formatColumn]);

  return columnsWithOptions;
}
