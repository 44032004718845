import React from "react";
import type { PharmacyPaymentsData } from "./PharmacyPayments.types";
import { PharmacyPaymentsTable } from "./PharmacyPaymentsTable/PharmacyPaymentsTable";

export function PharmacyPaymentsReport({
  data,
}: {
  data?: PharmacyPaymentsData;
}) {
  if (!data) return;

  const { data: pharmacyPaymentsData } = data;

  return (
    <>
      <div tw="flex-1 h-[calc(100% - 50px)] bg-white">
        <PharmacyPaymentsTable data={pharmacyPaymentsData} />
      </div>
    </>
  );
}
