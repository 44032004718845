import React from "react";
import type { PrescriberDataType } from "./PrescribersReport.types";
import { PrescribersTable } from "./PrescribersTable/PrescribersTable";

export function PrescribersReport({ data }: { data?: PrescriberDataType }) {
  if(!data) return;

  const { prescribersTable: { data: prescribersTableData } } = data;

  return (
    <>
      <div tw="flex-1 h-[calc(100% - 50px)] bg-white">
        <PrescribersTable data={prescribersTableData} />
      </div>
    </>
  );
}
