import React from "react";
import "twin.macro";
import p from "pluralize";
import type { Icon } from "../../../../../../components/rxLibrary/icons";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { TableHeaderDescription } from "./TableHeaderDescription";

export function TableHeader({
  title,
  Icon,
  text,
  itemsCount,
}: {
  title: string;
  Icon?: Icon;
  text: string;
  itemsCount: number;
}) {
  return (
    <div tw="flex py-[20px] px-[10px]">
      <div tw="w-[371px] space-y-[6px] pl-[6px] mr-[16px]">
        <Text block size={21}>
          {title}
        </Text>

        <Text tw="flex items-center pl-[8px]">
          {Icon && <Icon color="red-1" tw="mr-2" />}
          {text}
        </Text>
      </div>

      <div tw="text-center w-[39px] space-y-[6px]">
        <Text weight="bold" size={20} block center>
          {itemsCount}
        </Text>
        <TableHeaderDescription>{p("ITEM", itemsCount)}</TableHeaderDescription>
      </div>
    </div>
  );
}
