import React, { useCallback, useState } from "react";
import { Input } from "antd";

function stringifyValue(value: any) {
  if (!value) return value;
  if (typeof value === "string") return value;
  return JSON.stringify(value);
}

export function InputJson({ value, onChange, ...props }: any) {
  const [valueStr, setValueStr] = useState(() => stringifyValue(value));

  const handleOnChange = useCallback(
    (event: any) => {
      const newValue = event.target.value;
      setValueStr(newValue);

      try {
        const valueJson = JSON.parse(newValue);
        onChange(valueJson);
      } catch (error) {
        onChange(null);
      }
    },
    [onChange]
  );

  return <Input {...props} value={valueStr} onChange={handleOnChange} />;
}
