import type { QuoteState } from "./useCompare";
import {
  Quotes,
  DrugInfo,
  RequestState,
  TaggedItemList,
  ListCategoryEnum,
} from "../../../utilities/types";
import {
  groupItemsByNdc,
  tagAndGroupQuoteItems,
} from "../../../utilities/search";
import { supplierIsDisabledInEnhancedClient } from "../../../enhanced/util";

function maybeAppendSyntheticKinrayItem(
  itemListArray: TaggedItemList[]
): TaggedItemList[] {
  //NOTE: Special for Enhanced Only...add Kinray if missing
  let kinrayFound = false;
  itemListArray.forEach((il) => {
    il.items.forEach((i) => {
      if (i.catalogInfo.supplier === "KINRAY") {
        kinrayFound = true;
      }
    });
  });
  if (kinrayFound) {
    return itemListArray;
  }

  const resultsClone = itemListArray.map<TaggedItemList>((list) => {
    return {
      category: list.category,
      items: list.items.map((i) => i),
    };
  });
  const ndcMatches = resultsClone.find(
    (il) => il.category === ListCategoryEnum.NDC_MATCH
  );
  if (ndcMatches) {
    console.log("Appending synthetic Kinray Item");
    ndcMatches.items.push({
      tag: ndcMatches.items[0].tag,
      alternatePackaging: ndcMatches.items[0].alternatePackaging,
      buyingScore: ndcMatches.items[0].buyingScore,
      suggestedReason: ndcMatches.items[0].suggestedReason,
      drugInfo: ndcMatches.items[0].drugInfo,
      historicalDispensationInfo:
        ndcMatches.items[0].historicalDispensationInfo,
      historicalOrderingInfo: ndcMatches.items[0].historicalOrderingInfo,
      catalogInfo: {
        id: "syntheticKinray",
        supplierId: 3,
        supplier: "KINRAY",
        supplierDisplayName: "Kinray",
        price: 0.0,
        isRebatedItem: false,
        directDeal: null,
      },
    });
  }
  return resultsClone;
}

function filterClientDisabledSuppliers(
  lists: TaggedItemList[],
  enhancedClientVersion: string
): TaggedItemList[] {
  if (!enhancedClientVersion) {
    return lists;
  }

  return lists.map<TaggedItemList>((list) => {
    return {
      category: list.category,
      items: list.items.filter((i) => {
        return !supplierIsDisabledInEnhancedClient(
          i.catalogInfo.supplier,
          enhancedClientVersion
        );
      }),
    };
  });
}

export function enhancedFormatter({
  ndc,
  quotes,
  enhancedClientVersion,
}: {
  ndc: string;
  quotes: Quotes;
  enhancedClientVersion: string;
}): Partial<QuoteState> {
  const { items, relatedDrugs, suggestedNDC } = quotes;
  const taggedItemsGroupedByTag = tagAndGroupQuoteItems(
    ndc,
    items,
    suggestedNDC
  );
  const itemListsByNDC = groupItemsByNdc(
    // Enhanced only
    maybeAppendSyntheticKinrayItem(
      // Enhanced only
      filterClientDisabledSuppliers(
        taggedItemsGroupedByTag,
        enhancedClientVersion
      )
    )
  );
  const suggestedDrug = relatedDrugs.find((d) => d.ndc === suggestedNDC.ndc);

  // Enhanced only
  const relatedDrugsByNDC = relatedDrugs.reduce(
    (acc: Record<string, DrugInfo>, curr: DrugInfo) => {
      acc[curr.ndc] = curr;
      return acc;
    },
    {}
  );

  // Synthetic Kinray Item for Enhanced Client only
  return {
    suggestedNDC,
    suggestedDrug,
    itemListsByNDC,
    quoteRequestState: RequestState.COMPLETED,
    // Enhanced only
    relatedDrugsByNDC,
  };
}
