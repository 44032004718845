import React from "react";
import "twin.macro";
import { Form, Input, TimePicker, InputNumber } from "antd";
import dayjs from "dayjs";
import MinusOutlined from "@ant-design/icons/MinusOutlined";
import { FormCategory } from "../../../../../../../components/antd/form/FormCategory";
import {
  Button,
  IconButton,
} from "../../../../../../../components/rxLibrary/buttons";
import { IconPlus } from "../../../../../../../components/rxLibrary/icons";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import { cutoffTimesValidator } from "../AdminSupplierMappingForm.utils";

export function ShippingConfigFormCategory({
  timezoneWarning,
}: {
  timezoneWarning?: string;
}) {
  return (
    <FormCategory title="Shipping Config Override">
      <Form.Item
        name={["shippingConfig", "fee"]}
        label="Shipping Fee"
        rules={[{ min: 0, type: "number" }]}
      >
        <InputNumber tw="w-full" min={0} />
      </Form.Item>
      <Form.Item
        name={["shippingConfig", "minOrder"]}
        label="Shipping Min Order"
        rules={[{ min: 0, type: "number" }]}
      >
        <InputNumber tw="w-full" min={0} />
      </Form.Item>
      <Form.Item
        name={["shippingConfig", "minGenericOrder"]}
        label="Shipping Min Generic Order"
        rules={[{ min: 0, type: "number" }]}
      >
        <InputNumber tw="w-full" min={0} />
      </Form.Item>
      <Form.Item
        name={["shippingConfig", "minBrandRxOrder"]}
        label="Shipping Min Brand Rx Order"
        rules={[{ min: 0, type: "number" }]}
      >
        <InputNumber tw="w-full" min={0} />
      </Form.Item>
      <Form.Item
        name={["shippingConfig", "minGenericRxOrder"]}
        label="Shipping Min Generic Rx Order"
        rules={[{ min: 0, type: "number" }]}
      >
        <InputNumber tw="w-full" min={0} />
      </Form.Item>

      <div tw="col-span-2">
        <Form.List name={["shippingConfig", "notes"]}>
          {(fields, { add, remove }) => (
            <Form.Item label="Shipping Notes">
              <div tw="grid grid-cols-2 gap-x-8">
                {fields.map((field) => (
                  <div tw="flex space-x-2" key={field.key}>
                    <Form.Item
                      {...field}
                      tw="flex-1"
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Note can't be empty",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <IconButton tw="mt-1" onClick={() => remove(field.name)}>
                      <MinusOutlined
                        rev={undefined}
                        onPointerEnterCapture={undefined}
                        onPointerLeaveCapture={undefined}
                      />
                    </IconButton>
                  </div>
                ))}
              </div>

              <Button
                variant="tertiary"
                icon={<IconPlus />}
                onClick={() => add()}
              >
                Add Shipping Note
              </Button>
            </Form.Item>
          )}
        </Form.List>
      </div>

      <div tw="col-span-2">
        <Form.Item
          label="Shipping Cutoff Times"
          rules={[{ validator: cutoffTimesValidator }]}
        >
          {!!timezoneWarning && (
            <Text tw="mb-2" color="yellow-1" block>
              {timezoneWarning}
            </Text>
          )}

          <div tw="grid grid-cols-2 gap-x-8">
            {Array.from({ length: 7 }).map((_, index) => (
              <div key={index} tw="flex space-x-2">
                <Form.List
                  key={index}
                  name={["shippingConfig", "cutoffTimes", index]}
                >
                  {(fields, { add, remove }) => (
                    <>
                      <Text tw="mt-1 w-[60px]">
                        {dayjs().day(index).format("dddd")}
                      </Text>

                      {fields.map((field) => (
                        <Form.Item {...field} key={field.key}>
                          <TimePicker
                            tw="w-[100px]"
                            format="HH:mm"
                            showNow={false}
                            allowClear
                          />
                        </Form.Item>
                      ))}

                      {fields.length > 1 ? (
                        <IconButton
                          onClick={() => remove(fields[fields.length - 1].name)}
                        >
                          <MinusOutlined
                            rev={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                          />
                        </IconButton>
                      ) : (
                        <div>
                          <div tw="flex items-center space-x-1">
                            <IconButton onClick={() => add()}>
                              <IconPlus color="grey-1" />
                            </IconButton>

                            <Text color="grey-2" block>
                              Add here if two deliveries/day
                            </Text>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </Form.List>
              </div>
            ))}
          </div>
        </Form.Item>
      </div>
    </FormCategory>
  );
}
