import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import "twin.macro";
import ProgressBar from "@ramonak/react-progress-bar";
import "../scss/base.scss";
import "../scss/buttons.scss";
import "../scss/shoppingTables.scss";
import "../scss/dropdown.scss";
import "../scss/loader.scss";
import type { Supplier } from "../utilities/types";
import EnhancedClientContext, {
    RequestEnhanceItemParam,
    SupplierItemDataType
} from "./EnhancedClientContext";
import EnhancedClientConfigContext from "./EnhancedClientConfigContext";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import { ProgressDots } from "../components/loaders/ProgressDots";
import { Container } from "../components/containers/Container";
import { disableV2EnhancementsForSupplier } from "../utilities/search";
import EnhancedSupplierLoginInterstitial from "./EnhancedSupplierLoginInterstitial";
import EnhancedClientCommandContext from "./EnhancedClientCommandContext";

const MAX_REQUESTS_PER_SUPPLIER = 1000;

type SupplierSearchDisplay = {
    supplierId?: number,
    supplierName: string,
    supplierDisplayName: string,
    supplierCursor: number,
    supplierAbortPoint: number,
}

function suppliersFrom(shoppingList: RequestEnhanceItemParam[]): string[] {
    const suppliers = new Set<string>();
    shoppingList.forEach(item => {
        if (item.supplier) {
            suppliers.add(item.supplier);
        }
    });
    return Array.from(suppliers);
}

export default function
  EnhancedShoppingListSearch({traceId, shoppingList, onComplete, onAbort, onCancelAfterCompleted}: {
    traceId: string,
    shoppingList: RequestEnhanceItemParam[],
    onComplete: (items: SupplierItemDataType[], removedSuppliers: string[], traceId: string) => void,
    onAbort: () => void,
    onCancelAfterCompleted: (traceId: string) => void,
}): JSX.Element | null {
    const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
    const { enhancedShoppingListActive } = useContext(EnhancedClientConfigContext);
    const { createEnhancedNotification, showMain } = useContext(EnhancedClientCommandContext);
    const { enhancedSupplierConnectionStatuses, requestEnhanceShoppingList, currentShoppingListContext, currentEnhancementState, deactivateShoppingListSupplier, reactivateShoppingListSupplier, abortShoppingListSearch} = useContext(EnhancedClientContext);
    const { showSupplierWithOptions, showSupplierForUseCase } = useContext(EnhancedClientCommandContext);
    const [ loggedInToAllSuppliers, setLoggedInToAllSuppliers ] = useState<boolean>(false);
    const [removedSuppliers, setRemovedSuppliers] = useState<string[]>([]);
    const [aborting, setAborting] = useState<boolean>(false);
    const [problematicSuppliersChecked, setProblematicSuppliersChecked] = useState<Record<string, boolean>>({});
    const [setShowSupplierNamesAsLinks, setSetShowSupplierNamesAsLinks] = useState<boolean>(false);

    const limitedTo = useMemo(() => {
      return suppliersFrom(shoppingList);
    }, [shoppingList]);

    useEffect(() => {
      console.log("Enhanced Shopping List Search Trace ID: " + traceId, "Rendering");
    }, [traceId]);

    useEffect(() => {
      const t = setTimeout(() => {
        setSetShowSupplierNamesAsLinks(true);
      }, 15000);
      return () => clearTimeout(t);
    }, []);

    const onCancelClicked = useCallback((e: {preventDefault: () => void}) => {
      e.preventDefault();
      setAborting(true);
      abortShoppingListSearch();
    }, [setAborting, abortShoppingListSearch]);


    useEffect(() => {
      if(!enhancedShoppingListActive) {
          console.log("Enhanced Shopping List Search Trace ID: " + traceId, "Enhanced Client Not Active, calling onComplete");
          console.log("ShoppingList", shoppingList);
          setTimeout(() => {
              onComplete([], [], traceId);
          }, 2000);
      }
    }, [enhancedShoppingListActive, traceId])

    if (!enhancedShoppingListActive) {
        console.log("Enhanced Shopping List Search Trace ID: " + traceId, "Enhanced Client Not Active, returning null");
        console.log("ShoppingList", shoppingList);
        return (
            <>
                <Container>
                  <div className="flex justify-between">
                    <h2 tw="font-bold" className="text-lg text-left">
                      Optimizing<ProgressDots/>
                    </h2>
                  </div>
                </Container>
            </>
        );
    }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (!enhancedShoppingListActive) {
      return;
    }
    if (!loggedInToAllSuppliers) {
      return;
    }
    const executeSearch = (treatAsFirstSearch: boolean) => {
      console.log("Evaluating trace ID: " + traceId + " vs currentShoppingListContext " + currentShoppingListContext?.request.traceId);
            if (!currentShoppingListContext || currentShoppingListContext.request.traceId !== traceId) {
                console.log("Requesting enhancement for trace ID: " + traceId, shoppingList);
                const countsPerSupplier: Record<string, number> = {};
                const filteredShoppingList = shoppingList.filter(item => {
                    countsPerSupplier[item.supplier] = (countsPerSupplier[item.supplier] || 0) + 1;
                    return !!enhancedSupplierConnectionStatuses[item.supplier]
                      && !enhancedSupplierConnectionStatuses[item.supplier].enhancementsDisabled
                      && countsPerSupplier[item.supplier] <= MAX_REQUESTS_PER_SUPPLIER
                      && !disableV2EnhancementsForSupplier(item.supplier)
                      ;
                })
                console.log("Filtered Shopping List: ", filteredShoppingList);
                if (filteredShoppingList.length > 0) {
                    requestEnhanceShoppingList(traceId, filteredShoppingList, treatAsFirstSearch);
                } else {
                    console.log("Enhanced Shopping List Search Trace ID: " + traceId, "No items to search, calling onComplete");
                    onComplete([], [], traceId);
                }
            }
        }
        if (currentEnhancementState.isFirstSearch && (currentEnhancementState.traceId === null || currentEnhancementState.traceId === traceId)) {
            executeSearch(true);
        } else {
            executeSearch(false);
        }
    }, [currentShoppingListContext, enhancedShoppingListActive, loggedInToAllSuppliers, traceId, enhancedSupplierConnectionStatuses]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (!enhancedShoppingListActive) {
            return;
        }
        if (!currentShoppingListContext) {
            return;
        }
        console.log("Change to currentShoppingList", currentShoppingListContext);
        if (currentShoppingListContext.request.traceId !== traceId) {
            return;
        }
        if (currentShoppingListContext.state === "completed") {
            console.log("Enhancement complete for trace ID: " + traceId);
            createEnhancedNotification({
                title: "Optimization Complete",
                body: "Your Optimized Purchase List is Ready.",
                soundEffect: "chime",
            });
            onComplete(currentShoppingListContext.completions.map(c => c.results).flat(), removedSuppliers, traceId);
        }
        if (currentShoppingListContext.state === "aborted") {
            console.log("Enhancement aborted for trace ID: " + traceId);
            onAbort();
        }
    }, [enhancedShoppingListActive, currentShoppingListContext, traceId]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if(aborting && (currentShoppingListContext?.state === "completed" || currentShoppingListContext?.state === undefined)) {
        console.log("Abort status changed to: " + aborting + "; currentShoppingListContext state: " + currentShoppingListContext?.state);
        onCancelAfterCompleted(traceId);
      }
    }, [currentShoppingListContext, aborting, traceId])

  if (!loggedInToAllSuppliers) {
        return (
            <>
                <Container>
                    <h2 tw="font-bold"className="text-lg text-left">
                        Confirming Live Price and Availability Among Some Suppliers
                    </h2>
                    <EnhancedSupplierLoginInterstitial
                        key={traceId}
                        searchId={traceId}
                        limitedTo={limitedTo}
                        disableSearchingDisplay={true}
                        onLoginsFinished={() => {
                            setLoggedInToAllSuppliers(true);
                            showMain();
                        }}
                        onComplete={() => {
                            console.log("intersitial onComplete");
                        }}
                        onTimeout={() => {}}
                        onCancelSupplierLogin={(supplier: string) => {
                            console.log("intersitial onCancel", supplier);
                            onAbort();
                        }}
                    />
                </Container>
            </>
        );
    } else {
        const sortSuppliers = (items: Supplier[]) => {
            return items.sort((a: Supplier, b: Supplier) => a.displayName.localeCompare(b.displayName))
        }

        const problematicSuppliers = sortSuppliers(currentBuyingPharmacy?.suppliers || []).filter(s => {
            return currentShoppingListContext?.supplierStatus[s.name] === "problematic";
        });
        const supplierSearchDisplays: SupplierSearchDisplay[] = [];
        currentShoppingListContext?.supplierStatus && Object.keys(currentShoppingListContext.supplierStatus).forEach(supplierName => {
            supplierSearchDisplays.push({
                supplierName: supplierName,
                supplierDisplayName: currentBuyingPharmacy?.suppliers?.find(s => s.name === supplierName)?.displayName || supplierName,
                supplierId: currentBuyingPharmacy?.suppliers?.find(s => s.name === supplierName)?.id || undefined,
                supplierCursor: currentShoppingListContext.supplierRequestCursors[supplierName],
                supplierAbortPoint: currentShoppingListContext.supplierRequestAbortPoints[supplierName],
            });
        });
        return (
            <>
                <Container>
                    <div className="flex justify-between">
                        <h2 tw="font-bold" className="text-lg text-left">
                            Confirming Live Price and Availability Among Some Suppliers
                        </h2>
                        <h2 tw="font-bold" className="text-lg text-right">
                            {(aborting ? "(Cancelling)" :
                                <a href="#" className="mr-32 underline" onClick={onCancelClicked}>
                                    Cancel
                                </a>
                            )}
                        </h2>
                    </div>
                    <table cellPadding={0} cellSpacing={0} className="suppliers-list">
                        <tbody>
                        {(supplierSearchDisplays.map(s => {
                            const isProblematic = problematicSuppliers.find(p => p.name === s.supplierName);
                            return (
                              <React.Fragment key={s.supplierName}>
                                <tr>
                                  <td width="40%">
                                            <span
                                              className={`name cursor-pointer ${(setShowSupplierNamesAsLinks ? "linklike" : "")}`}
                                              style={isProblematic ? {color: '#C72F00'} : {}}
                                              onClick={() => {
                                                showSupplierWithOptions(s.supplierName, {preventActivation: true})
                                              }}>
                                                {s.supplierDisplayName}
                                            </span>
                                  </td>
                                  <td width="60%">
                                    <ProgressBar
                                      bgColor={isProblematic ? "#C72F00" : "#324dc7"}
                                      completed={Math.round(((Math.max(0, s.supplierCursor) / (s.supplierAbortPoint + 1)) + Number.EPSILON) * 100)}
                                      customLabel={Math.max(0, s.supplierCursor) === 0 && currentShoppingListContext?.state === "requested" ? "Connecting" : undefined}
                                      customLabelStyles={{color: "black"}}
                                      labelAlignment="outside"
                                    />
                                  </td>
                                </tr>
                                {(isProblematic && (
                                  <>
                                    <tr className="inline-addition">
                                      <td colSpan={2}>
                                                  <span>
                                                      Difficulty searching {s.supplierDisplayName}.
                                                  </span>
                                      </td>
                                    </tr>
                                    <tr className="inline-addition">
                                      <td colSpan={2}>
                                        <div className="flex justify-between">
                                          <button style={{width: "235px", justifyContent: "space-around"}} className="btn btn-primayBlue" onClick={() => {
                                            setProblematicSuppliersChecked((prev) => {
                                              return {
                                                ...prev,
                                                [s.supplierName]: true,
                                              }
                                            });
                                            showSupplierForUseCase(s.supplierName, "problematic-supplier");
                                          }}>Open Site &amp; Resolve Issues</button>
                                          {(problematicSuppliersChecked[s.supplierName] ? (
                                            <>
                                              <button style={{width: "225px", justifyContent: "space-around"}} className="btn btn-white" onClick={() => {
                                                deactivateShoppingListSupplier(s.supplierName);
                                                setRemovedSuppliers([...removedSuppliers, s.supplierName]);
                                              }}>Stop Searching Supplier</button>
                                              <button style={{width: "225px", justifyContent: "space-around"}} className="btn btn-white" onClick={() => {
                                                reactivateShoppingListSupplier(s.supplierName);
                                              }}>Retry Supplier</button>
                                            </>
                                          ) : null)}
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                ))}
                              </React.Fragment>
                            )
                        }))}
                        </tbody>
                    </table>
                </Container>
            </>
        );
    }
}
