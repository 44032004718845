import React, { useCallback, useMemo } from "react";
import "twin.macro";
import { useShoppingState } from "../../../../../../contexts/shoppingContexts/ShoppingContext/ShoppingContext";
import {
  ShoppingItem,
  ShoppingItemSupplierOrderItem,
} from "../../../useShoppingList";
import { QtySelect } from "../../../../../../components/rxLibrary/selects/QtySelect";
import { Text } from "../../../../../../components/rxLibrary/typography";
import { BuyingForCol } from "../../table/columns/BuyingForCol";
import { SplitNdcWarning } from "../../table/warnings/SplitNdcWarning";
import { OriginalValueWarning } from "../../table/warnings/OriginalValueWarning";
import { Supplier, DrugInfoReference } from "../../../../../../utilities/types";
import { normalizePackQuantity } from "../../../../../../utilities/prescriptions/packQuantity/normalizePackQuantity";
import { currencyWithCentsUnlessGreaterThanOrEqualToOneThousand } from "../../../../../../utilities/numbers/currency";
import { formatDrugDispenseQuantity } from "../../../../../../utilities/drugInfo/formatDrugDispenseQuantity";
import { SupplierItemChangeOperation } from "../../../../../../utilities/shoppingListTypes";
import { BrandAtOtherSuppliersWarning } from "./warnings/BrandAtOtherSuppliersWarning/BrandAtOtherSuppliersWarning";
import { ShippingMinimumWarning } from "./warnings/ShippingMinimumWarning";
import { PurchaseByDrugWarning } from "./warnings/PurchaseByDrugWarning";
import { ShoppingListTableRowPurchaseByButton } from "./ShoppingListTableRowPurchaseByButton";
import { ShoppingListTableRowDropdownActions } from "./ShoppingListTableRowDropdownActions";
import { ShoppingListTableRowDrugDetailsCol } from "./ShoppingListTableRowDrugDetailsCol";
import { buildShoppingListTableRowShippingWarning } from "./buildShoppingListTableRowShippingWarning";
import {
  SupplierOrderItemRecommendation_All
} from "../../../../../../services/utils";

export function ShoppingListTableRow({
  item,
  itemProps,
}: {
  item: ShoppingItemSupplierOrderItem;
  itemProps?: {
    supplier: Supplier;
    shoppingItems: ShoppingItem[];
    drugsWithAvailabilityLimitations?: DrugInfoReference[];
    setModalData: (v: {
      displayName: string;
      item: SupplierOrderItemRecommendation_All;
    }) => void;
    isVisitedItem: (item: SupplierOrderItemRecommendation_All) => boolean;
    processCartChangeOperations: (
      operations: SupplierItemChangeOperation[]
    ) => void;
  };
}) {
  const { getShippingFeeSimulation } = useShoppingState();
  const {
    supplier,
    shoppingItems,
    drugsWithAvailabilityLimitations,
    setModalData,
    isVisitedItem,
    processCartChangeOperations,
  } = itemProps as NonNullable<typeof itemProps>;
  const { referenceData, numPackages, alternatives, prescriptionDetails } =
    item;
  const { drugInfo, catalogInfo } = referenceData;
  const { id: supplierId, displayName } = supplier;
  const { directDeal, price } = catalogInfo;
  const { bundleSize } = directDeal ?? {};
  const hasItemAlternatives = alternatives.length >= 1;
  const { ndc } = drugInfo;

  const itemQuantity = useMemo(() => {
    return normalizePackQuantity(numPackages);
  }, [numPackages]);

  const isVisited = useMemo(() => {
    return isVisitedItem(item);
  }, [item, isVisitedItem]);

  const priceFormatted = useMemo(() => {
    return currencyWithCentsUnlessGreaterThanOrEqualToOneThousand(price);
  }, [price]);

  const dispenseQuantity = useMemo(() => {
    return formatDrugDispenseQuantity(drugInfo);
  }, [drugInfo]);

  const quantitiesConfig = useMemo(() => {
    return { bundleSize, multiplesOfBundleSize: true };
  }, [bundleSize]);

  const shippingWarning = useMemo(() => {
    if (!hasItemAlternatives) return;

    const shippingFeeSimulation = getShippingFeeSimulation(supplier, {
      itemToRemove: item,
    });
    return buildShoppingListTableRowShippingWarning({
      supplier,
      shippingFeeSimulation,
    });
  }, [item, supplier, hasItemAlternatives, getShippingFeeSimulation]);

  const openModal = useCallback(() => {
    setModalData({ item, displayName });
  }, [item, displayName]);

  const updateItemQty = useCallback(
    (quantity: number) => {
      processCartChangeOperations([
        { type: "changeQty", quantity, oldItem: item },
      ]);
    },
    [item, processCartChangeOperations]
  );

  return (
    <div tw="flex flex-1 justify-between">
      <div tw="border-r space-y-1 px-[10px] w-[365px]">
        <ShoppingListTableRowDrugDetailsCol
          drug={drugInfo}
          catalogInfo={catalogInfo}
        />

        <BrandAtOtherSuppliersWarning
          item={item}
          supplier={supplier}
          openModal={openModal}
        />

        <ShippingMinimumWarning
          drug={drugInfo}
          drugsWithAvailabilityLimitations={drugsWithAvailabilityLimitations}
        />

        <PurchaseByDrugWarning prescriptionDetails={prescriptionDetails} />

        <SplitNdcWarning ndc={ndc} shoppingItems={shoppingItems} />
      </div>

      <div tw="border-r space-y-1 px-[10px] w-[205px]">
        <Text block weight="bold">
          {drugInfo.manufacturer}
        </Text>

        <OriginalValueWarning type="manufacturer" item={item} />
      </div>

      <div tw="border-r space-y-1 px-[10px] w-[130px]">
        <BuyingForCol prescriptionDetails={prescriptionDetails} />
      </div>

      <div tw="border-r space-y-1 pl-[10px] px-[10px] flex-1">
        <div tw="flex space-x-[10px]">
          <div tw="flex flex-1 flex-col text-center items-center space-y-1 min-w-[98px]">
            <Text weight="bold">{priceFormatted}</Text>

            {catalogInfo.isRebatedItem && (
              <Text block center color="green-4">
                Rebated
              </Text>
            )}

            <Text>{dispenseQuantity}</Text>

            <OriginalValueWarning type="packSize" item={item} />
          </div>

          <div>
            <QtySelect
              value={itemQuantity}
              quantitiesConfig={quantitiesConfig}
              onChange={updateItemQty}
            />
          </div>
        </div>
      </div>

      <div tw="space-y-1 px-[10px] w-[170px]">
        <div tw="flex items-center">
          <div tw="flex flex-col flex-1 space-y-1">
            {!!isVisited && (
              <Text block center weight="bold">
                Visited
              </Text>
            )}

            <ShoppingListTableRowPurchaseByButton
              item={item}
              supplierId={supplierId}
              hasMinShipWarning={!!shippingWarning}
            />
          </div>

          <ShoppingListTableRowDropdownActions
            minShipWarning={shippingWarning}
            hasItemAlternatives={hasItemAlternatives}
            openModal={openModal}
          />
        </div>
      </div>
    </div>
  );
}
