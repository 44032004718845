import { Warning } from "../rxLibrary/warnings";

export function DuplicatedDrugFamilyWarning({
  itemsCount,
  excludePmsPrescriptions,
}: {
  itemsCount: number;
  excludePmsPrescriptions?: boolean;
}) {
  const itemType = excludePmsPrescriptions ? "inventory" : "";

  return (
    <Warning type="error">
      {itemsCount === 1
        ? `Note: There is a similar ${itemType} item in your shopping list already:`
        : `Note: There are similar ${itemType} items in your shopping list already:`}
    </Warning>
  );
}
