import type { Manufacturer } from "../utilities/types";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";

export async function getManufacturers(
  client: RequestClient,
  config: RequestClientConfig
) {
  return client<{ manufacturers: Manufacturer[] }>({
    ...config,
    url: "/manufacturers",
  });
}
