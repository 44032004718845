"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clientConnections = clientConnections;
exports.connection = connection;
exports.hasFreeSubscription = hasFreeSubscription;
exports.initClient = initClient;
var _immutable = _interopRequireWildcard(require("immutable"));
var _auth0PasswordPolicies = _interopRequireDefault(require("auth0-password-policies"));
var _data_utils = require("../../utils/data_utils");
var _index = require("../../connection/social/index");
var _enterprise = require("../../connection/enterprise");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var _dataFns = (0, _data_utils.dataFns)(['client']),
  initNS = _dataFns.initNS,
  get = _dataFns.get;
var DEFAULT_CONNECTION_VALIDATION = {
  username: {
    min: 1,
    max: 15
  }
};
function hasFreeSubscription(m) {
  return ['free', 'dev'].indexOf(get(m, ['tenant', 'subscription'])) > -1;
}
function connection(m, strategyName, name) {
  // TODO: this function should take a client, not a map with a client
  // key.
  var connections = strategy(m, strategyName).get('connections', (0, _immutable.List)());
  return connections.find(withName(name)) || (0, _immutable.Map)();
}
function strategy(m, name) {
  // TODO: this function should take a client, not a map with a client
  // key.
  return m.getIn(['client', 'strategies'], (0, _immutable.List)()).find(withName(name)) || (0, _immutable.Map)();
}
function withName(name) {
  return function (x) {
    return x.get('name') === name;
  };
}
function strategyNameToConnectionType(str) {
  if (str === 'auth0') {
    return 'database';
  } else if (str === 'email' || str === 'sms') {
    return 'passwordless';
  } else if (_index.STRATEGIES[str]) {
    return 'social';
  } else if (_enterprise.STRATEGIES[str]) {
    return 'enterprise';
  } else if (['oauth1', 'oauth2'].indexOf(str) !== -1) {
    return 'social';
  } else {
    return 'unknown';
  }
}
function formatConnectionValidation() {
  var connectionValidation = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (connectionValidation.username == null) {
    return null;
  }
  var validation = _objectSpread(_objectSpread({}, DEFAULT_CONNECTION_VALIDATION), connectionValidation);
  var defaultMin = DEFAULT_CONNECTION_VALIDATION.username.min;
  var defaultMax = DEFAULT_CONNECTION_VALIDATION.username.max;
  validation.username.min = parseInt(validation.username.min, 10) || defaultMin;
  validation.username.max = parseInt(validation.username.max, 10) || defaultMax;
  if (validation.username.min > validation.username.max) {
    validation.username.min = defaultMin;
    validation.username.max = defaultMax;
  }
  return validation;
}
var emptyConnections = _immutable.default.fromJS({
  database: [],
  enterprise: [],
  passwordless: [],
  social: [],
  unknown: [] // TODO: should be oauth2
});

function initClient(m, client) {
  return initNS(m, formatClient(client));
}
function formatClient(o) {
  return new _immutable.default.fromJS({
    id: o.id,
    tenant: {
      name: o.tenant,
      subscription: o.subscription
    },
    connections: formatClientConnections(o)
  });
}
function formatClientConnections(o) {
  var result = emptyConnections.toJS();
  var _loop = function _loop() {
    var _result$connectionTyp;
    var strategy = o.strategies[i];
    var connectionType = strategyNameToConnectionType(strategy.name);
    var connections = strategy.connections.map(function (connection) {
      return formatClientConnection(connectionType, strategy.name, connection);
    });
    (_result$connectionTyp = result[connectionType]).push.apply(_result$connectionTyp, _toConsumableArray(connections));
  };
  for (var i = 0; i < (o.strategies || []).length; i++) {
    _loop();
  }
  return result;
}
function formatClientConnection(connectionType, strategyName, connection) {
  var result = {
    name: connection.name,
    strategy: strategyName,
    type: connectionType,
    displayName: connection.display_name
  };
  if (connectionType === 'database') {
    result.passwordPolicy = _auth0PasswordPolicies.default[connection.passwordPolicy || 'none'];
    if (connection.password_complexity_options && connection.password_complexity_options.min_length) {
      result.passwordPolicy.length.minLength = connection.password_complexity_options.min_length;
    }
    result.allowSignup = typeof connection.showSignup === 'boolean' ? connection.showSignup : true;
    result.allowForgot = typeof connection.showForgot === 'boolean' ? connection.showForgot : true;
    result.requireUsername = typeof connection.requires_username === 'boolean' ? connection.requires_username : false;
    result.validation = formatConnectionValidation(connection.validation);
  }
  if (connectionType === 'enterprise') {
    var domains = connection.domain_aliases || [];
    if (connection.domain) {
      domains.unshift(connection.domain);
    }
    result.domains = domains;
  }
  return result;
}
function clientConnections(m) {
  return get(m, 'connections', emptyConnections);
}
