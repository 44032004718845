import React from "react";
import { styled } from "twin.macro";
import { Text } from "../../../../../../components/rxLibrary/typography";

const CurrencyWrapper = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  letter-spacing: normal;
  margin-right: 2.4px;
`;

export function TableHeaderCurrency({ value }: { value: string | number }) {
  return (
    <Text weight="bold" tw="flex items-center justify-center" size={20}>
      <CurrencyWrapper>$</CurrencyWrapper>
      <span>{value}</span>
    </Text>
  );
}
