import React, { ReactNode, useEffect, useRef, useState } from "react";
import tw from "twin.macro";
import type { Dayjs } from "dayjs";
import {
  Button,
  SyncButtonAndText,
} from "../../../../components/rxLibrary/buttons";
import { Text } from "../../../../components/rxLibrary/typography";
import { IconPlus } from "../../../../components/rxLibrary/icons";
import { Box } from "../../../../components/rxLibrary/box";
import {
  ScrollOverlay,
  useScrollOverlay,
} from "../../../../components/ScrollOverlay";
import { ADD_RX_MOVE_DOWN_ANIMATION_CSS } from "../ShoppingRx.utils";
import { ShoppingRxPanelCounter } from "./ShoppingRxPanelCounter";

export function ShoppingRxPanel({
  counter,
  syncDate,
  children,
  inventory,
  isLoading,
  highlight,
  onSync,
  openItemsModal,
}: {
  counter: number;
  children: ReactNode;
  isLoading: boolean;
  syncDate?: Dayjs;
  inventory?: boolean;
  highlight?: boolean;
  onSync: () => void;
  openItemsModal: () => void;
}) {
  const { ref, hasScrollOverlay } = useScrollOverlay();
  const oldCounterRef = useRef(counter);
  const [animation, setAnimation] = useState(false);
  const isEmpty = counter === 0;
  const imageAlt = inventory ? "Inventory" : "Prescriptions";
  const itemsLabel = inventory ? "Inventory Items" : "Prescriptions";
  const itemsBtnLabel = inventory ? "' + Add NDC / Item'" : "' + Add Rx'";
  const buttonLabel = inventory ? "Add NDC / Item" : "Add Rx";
  const imageSrc = inventory
    ? "/assets/shopping/inventory-icon.png"
    : "/assets/shopping/prescription-icon.png";
  const title = inventory
    ? "Inventory Items to Buy"
    : "Prescriptions Items to Buy";

  useEffect(() => {
    if (oldCounterRef.current < counter) setAnimation(true);
    oldCounterRef.current = counter;
  }, [counter]);

  useEffect(() => {
    if (!animation) return;
    const timeoutId = setTimeout(() => setAnimation(false), 500);
    return () => clearTimeout(timeoutId);
  }, [animation]);

  return (
    <Box
      tw="flex-1 rounded-lg border overflow-y-hidden flex flex-col"
      bgColor="white-1"
      borderColor={highlight ? "blue-1" : "grey-2"}
      css={highlight ? tw`border-2` : tw`p-[1px] border`}
    >
      <Box tw="relative z-10 pt-[4px] px-[4px]" bgColor="white-1">
        <Box
          tw="flex justify-between items-center rounded-t-md p-[14px]"
          bgColor={inventory ? "yellow-2" : "blue-4"}
        >
          <div tw="flex space-x-[12px]">
            <img src={imageSrc} alt={imageAlt} tw="w-[48px]" />

            <div>
              <Text tw="ml-[3px]" size={23} block>
                {title}
                {!isEmpty && <ShoppingRxPanelCounter counter={counter} />}
              </Text>

              <SyncButtonAndText
                syncDatePrefix="Last Synced with PMS"
                loading={isLoading}
                syncDate={syncDate}
                onClick={onSync}
              />
            </div>
          </div>

          <Button
            variant="secondary"
            icon={<IconPlus />}
            onClick={openItemsModal}
          >
            {buttonLabel}
          </Button>
        </Box>

        <div tw="flex relative shadow-[0_4px_3px_0_#00000028] content-stretch h-[38px] py-[8px]">
          <Box tw="flex-1 border-r px-[8px]" borderColor="grey-2">
            {!isEmpty && (
              <Text weight="bold">
                {inventory ? "Inventory Item" : "Rx Details"}
              </Text>
            )}
          </Box>
          <Box
            tw="border-r px-[8px]"
            css={inventory ? tw`w-[137px]` : tw`w-[160px]`}
            borderColor="grey-2"
          >
            {!isEmpty && (
              <Text weight="bold">
                {inventory ? "Quantity" : "Prescriber + Quantity"}
              </Text>
            )}
          </Box>
          <div tw="w-[119px] px-[8px]">
            {!isEmpty && <Text weight="bold">Substitutions</Text>}
          </div>
        </div>
      </Box>

      <div
        tw="relative h-full overflow-y-auto flex-1 pt-[13px] pb-[12px] px-[9px] space-y-[10px]"
        ref={ref}
        css={!!animation && ADD_RX_MOVE_DOWN_ANIMATION_CSS}
      >
        {isEmpty ? (
          <div tw="flex justify-center py-[39px]">
            <Text size="medium" color="grey-1" center>
              There are no {itemsLabel} is your list.
              <br />
              Use the {itemsBtnLabel} button above to build your list
            </Text>
          </div>
        ) : (
          <>
            {children}
            {isLoading && (
              <Box
                bgColor="white-1"
                bgColorOpacity={80}
                tw="absolute top-0 left-0 h-full w-full"
              />
            )}
          </>
        )}
      </div>

      {hasScrollOverlay && <ScrollOverlay />}
    </Box>
  );
}
