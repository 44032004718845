import React from "react";
import merge from "lodash/merge";
import { IDetailCellRendererParams } from "ag-grid-community";
import { DEFAULT_COL_DEF, ROW_CLASS_RULES } from "./reportsConstants";
import { getMainMenuItems } from "./getMainMenuItems";

type PartialDetailCellRendererParams = Partial<IDetailCellRendererParams>;

export function getDetailCellRendererParams(
  detailCellRendererParamsOverride?: PartialDetailCellRendererParams
): PartialDetailCellRendererParams {
  const baseDetailCellRendererParams: PartialDetailCellRendererParams = {
    refreshStrategy: "everything",
    detailGridOptions: {
      getMainMenuItems,
      pagination: true,
      paginationPageSize: 20,
      rowClassRules: ROW_CLASS_RULES,
      rowGroupPanelShow: "always",
      defaultColDef: {
        ...DEFAULT_COL_DEF,
        flex: 1,
        minWidth: 150,
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
            },
          },
        ],
      },
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.claim);
    },
  };

  const newDetailCellRendererParams = merge(
    baseDetailCellRendererParams,
    detailCellRendererParamsOverride
  );

  return newDetailCellRendererParams;
}
