import React, { useContext } from "react";
import "twin.macro";
import { Box } from "../../../components/rxLibrary/box";
import { SortBySelect } from "../../../components/rxLibrary/selects/SortBySelect";
import {
  MultiSelectCheckboxes,
  MultiSelectCheckboxesSelectedTags,
} from "../../../components/rxLibrary/selects/MultiSelectCheckboxes";
import { SORT_INVOICE_OPTIONS, InvoiceHeaderContext } from "./Invoice";

export function InvoiceRowWrapper({ children }: { children: JSX.Element }) {
  const invoiceHeader = useContext(InvoiceHeaderContext);

  return (
    <Box tw="px-[32px] py-[24px]" bgColor="grey-4">
      {!!invoiceHeader && (
        <>
          <div tw="flex space-x-4 mb-6">
            {!!invoiceHeader.filterOptions && (
              <MultiSelectCheckboxes
                title="Select Purchase Order to Show"
                filters={invoiceHeader.filters}
                setFilters={invoiceHeader.setFilters}
                options={invoiceHeader.filterOptions}
              />
            )}

            <SortBySelect
              label="Sort Items By"
              selectMinWidth={120}
              options={SORT_INVOICE_OPTIONS}
              value={invoiceHeader.sort}
              sortOrder={invoiceHeader.sortDirection}
              onSortOrderChange={invoiceHeader.setSortDirection}
              onChange={invoiceHeader.setSort}
            />
          </div>

          {!!invoiceHeader.filterOptions && (
            <div tw="mt-2 mb-6">
              <MultiSelectCheckboxesSelectedTags
                filters={invoiceHeader.filters}
                setFilters={invoiceHeader.setFilters}
                options={invoiceHeader.filterOptions}
              />
            </div>
          )}
        </>
      )}

      {children}
    </Box>
  );
}
