import { useContext, useEffect, useState } from "react";
import {useAuth0} from "../../../contexts/AuthenticationContext";
import { useDrugSearchValues } from "./drugSearchValues";
import BuyingPharmacyContext from "../../../contexts/BuyingPharmacyContext";
import { getSearch_deprecated } from "../../../services/searches";
import {
  IHandleSearchNdc,
  IHandleSearchResults,
} from "../DrugSearch.constants";
import { cleanNdc } from "../../../utilities/ndc/cleanNdc";
import { NotCommonNdcSearchError } from "./useDrugSearch.errors";
import { RequestState, SearchResult } from "../../../utilities/types";
import { IDrugSearchRequestState } from "./useDrugSearch.constants";

export { useUpdateDrugSearchValues } from "./drugSearchValues";

export function useDrugSearch({
  handleSearchResults,
  handleSearchNdc,
}: {
  handleSearchResults: IHandleSearchResults;
  handleSearchNdc?: IHandleSearchNdc;
}) {
  const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);
  const { getAccessTokenSilently } = useAuth0();
  const drugSearchValues = useDrugSearchValues();
  const [drugSearchResults, setDrugSearchResults] = useState<SearchResult[]>(
    []
  );
  const [
    drugSearchResultsState,
    setDrugSearchResultsState,
  ] = useState<IDrugSearchRequestState>([RequestState.UNINITIATED]);
  const { search } = drugSearchValues;

  useEffect(() => {
    searchDrug();

    async function searchDrug() {
      if (!search || !currentBuyingPharmacyId) {
        setDrugSearchResultsState([RequestState.UNINITIATED]);
        setDrugSearchResults([]);
        return;
      }

      setDrugSearchResultsState([RequestState.PENDING]);

      try {
        if (handleSearchNdc) {
          const ndc = cleanNdc(search);

          if (ndc) {
            const shouldStop = await handleSearchNdc(ndc);
            if (!shouldStop) {
              setDrugSearchResultsState([RequestState.COMPLETED]);
              setDrugSearchResults([]);
            }

            return;
          }
        }

        const token = await getAccessTokenSilently();
        const { data } = await getSearch_deprecated(
          search,
          currentBuyingPharmacyId,
          token
        );
        const searchResults = data?.searchResults ?? [];
        const shouldStop = await handleSearchResults({ searchResults });
        if (!shouldStop) {
          setDrugSearchResultsState([RequestState.COMPLETED]);
          setDrugSearchResults(searchResults);
        }
      } catch (error) {
        if (error instanceof NotCommonNdcSearchError) {
          setDrugSearchResultsState([RequestState.ERROR, 'NDC']);
        } else {
          setDrugSearchResultsState([RequestState.ERROR, 'SEARCH']);
        }
        setDrugSearchResults([]);
      }
    }
  }, [search, currentBuyingPharmacyId]);

  return {
    drugSearchValues,
    drugSearchResults,
    drugSearchResultsState,
  };
}
