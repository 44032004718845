import React from "react";
// import "./AccordionPanel.css";
import "../scss/accordionPanel.scss"


export default function AccordionPanel({
  open = false,
  children,
}: {
  open: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <div className={`accordion-panel ${open && `open`}`}>
      <div className="accordion-panel-content">{children}</div>
    </div>
  );
}
