import { ColDef, ColGroupDef } from "ag-grid-community";
import { manufacturerReportPercentFormatter } from "../utils/manufacturerReportFormatters";

export const TOP_COVERAGE_RATE_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Top Pharmacies by Coverage Rate of Dispensed Fills",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "covered_pct_of_dispensed",
        headerName: "Coverage Rate of Dispensed Fills",
        sort: "desc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 175,
      },
      {
        field: "covered_dispensed_fills",
        headerName: "Covered Dispensed Fills",
        sort: "desc",
        maxWidth: 175,
      },
    ],
  },
];

export const BOTTOM_COVERAGE_RATE_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Bottom Pharmacies by Coverage Rate of Dispensed Fills",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "covered_pct_of_dispensed",
        headerName: "Coverage Rate of Dispensed Fills",
        sort: "asc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 175,
      },
      {
        field: "covered_dispensed_fills",
        headerName: "Covered Dispensed Fills",
        sort: "desc",
        maxWidth: 175,
      },
    ],
  },
];

export const TOP_PBM_REJECTION_RESOLUTION_COLUMN_DEFS: Array<
  ColDef | ColGroupDef
> = [
  {
    headerName: "Top Pharmacies by PBM Rejection Resolution Rate",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "dispensed_pct_of_resolved_pbm",
        headerName: "Resolution Rate",
        sort: "desc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "fills_w_resolved_pbm_rejection",
        headerName: "Rejections",
        sort: "desc",
        maxWidth: 150,
      },
      {
        field: "dispensed_fills_w_resolved_pbm_rejection",
        headerName: "Dispensed, Resolved Rejections",
        maxWidth: 150,
      },
    ],
  },
];

export const BOTTOM_PBM_REJECTION_RESOLUTION_COLUMN_DEFS: Array<
  ColDef | ColGroupDef
> = [
  {
    headerName: "Bottom Pharmacies by PBM Rejection Resolution Rate",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "dispensed_pct_of_resolved_pbm",
        headerName: "Resolution Rate",
        sort: "asc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "fills_w_resolved_pbm_rejection",
        headerName: "Rejections",
        sort: "desc",
        maxWidth: 150,
      },
      {
        field: "dispensed_fills_w_resolved_pbm_rejection",
        headerName: "Dispensed, Resolved Rejections",
        maxWidth: 150,
      },
    ],
  },
];

export const TOP_FILL_OPPORTUNITIES_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName: "Top Pharmacies by % Attempted of Fill Opportunities",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "attempted_pct_of_opp",
        headerName: "% Attempted",
        sort: "desc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "attempted_fills",
        headerName: "Attempted Fills",
        maxWidth: 150,
        sort: "desc",
      },
      {
        field: "fill_opportunities",
        headerName: "Fill Opportunities",
        maxWidth: 150,
      },
    ],
  },
];

export const BOTTOM_FILL_OPPORTUNITIES_COLUMN_DEFS: Array<
  ColDef | ColGroupDef
> = [
  {
    headerName: "Bottom Pharmacies by % Attempted of Fill Opportunities",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "attempted_pct_of_opp",
        headerName: "% Attempted",
        sort: "asc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "attempted_fills",
        headerName: "Attempted Fills",
        sort: "desc",
        maxWidth: 150,
      },
      {
        field: "fill_opportunities",
        headerName: "Fill Opportunities",
        maxWidth: 150,
      },
    ],
  },
];

export const TOP_PRIOR_AUTH_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName:
      "Top Pharmacies by Rate of Prior Auth Rejection to Coupon Usage (Dispensed Fills)",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "dispensed_refill_pa_to_coupon_pct",
        headerName: "% Coupon Applied",
        sort: "desc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "dispensed_refill_fast_pa_to_coupon_pct",
        headerName: "% Coupon Applied in 10 Min",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "dispensed_nrx_prior_auth_count",
        headerName: "Fills with PA Required",
        maxWidth: 150,
        sort: "desc",
      },
    ],
  },
];

export const BOTTOM_PRIOR_AUTH_COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    headerName:
      "Bottom Pharmacies by Rate of Prior Auth Rejection to Coupon Usage (Dispensed Fills)",
    children: [
      {
        field: "Pharmacy Name",
        headerName: "Pharmacy Name",
        flex: 1,
        minWidth: 175,
      },
      { field: "Pharmacy NPI", headerName: "Pharmacy NPI", maxWidth: 125 },
      {
        field: "dispensed_refill_pa_to_coupon_pct",
        headerName: "% Coupon Applied",
        sort: "asc",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "dispensed_refill_fast_pa_to_coupon_pct",
        headerName: "% Coupon Applied in 10 Min",
        valueFormatter: (params) =>
          manufacturerReportPercentFormatter(params.value, 0),
        maxWidth: 150,
      },
      {
        field: "dispensed_nrx_prior_auth_count",
        headerName: "Fills with PA Required",
        maxWidth: 150,
        sort: "desc",
      },
    ],
  },
];
