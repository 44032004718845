import React, { forwardRef, useCallback, useContext } from "react";
import "twin.macro";
import "core-js/actual/set-immediate";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import RadioButtonsGroup from "../../../RadioButtonsGroup";
import EnhancedClientContext from "../../../../enhanced/EnhancedClientContext";
import AppContext from "../../../../contexts/AppContext";
import { UserBusinessRoles } from "../../../../utilities/types";
import { AccountMenuManufacturerSelector } from "./AccountMenuManufacturerSelector";
import { AccountMenuPharmacySelector } from "./AccountMenuPharmacySelector";
import { AccountMenuItems } from "./AccountMenuItems";
import { AccountMenuLinks } from "./AccountMenuLinks";

const ROLE_OPTIONS = [
  {
    value: UserBusinessRoles.MANUFACTURING,
    label: "Manufacturer",
  },
  {
    value: UserBusinessRoles.BUYING,
    label: "Pharmacy",
  },
];

const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    placement="bottom-end"
    PopperProps={{
      disablePortal: true,
      modifiers: [{ name: "offset", options: { offset: [5, 0] } }],
    }}
    disableFocusListener
    disableHoverListener
    disableTouchListener
    {...props}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #4b5563",
    },
  },
  [`& .${tooltipClasses.tooltipArrow}`]: {
    // override Mui font with previously inherited font
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    //fixed dimensions and solid colors from palette
    backgroundColor: theme.palette.common.white,
    color: "#1e1e1e",
    width: 284,
    padding: "2rem",
    // border-gray-400 equivalent
    border: "1px solid rgba(156, 163, 175, var(--tw-border-opacity))",
    // shadow-md equivalent
    boxShadow:
      "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  },
  ".MuiTooltip-tooltip": {
    width: 300,
  },
}));

export const AccountMenu = forwardRef<
  HTMLDivElement,
  Omit<TooltipProps, "title">
>(function AccountDropdown(
  // eslint-disable-next-line react/prop-types
  { children, open, ...tooltipProps },
  ref
) {
  const history = useHistory();
  const { user, adminSelectedRole, userHasRole, setAdminSelectedRole } =
    useContext(AppContext);
  const { enhancedReportProblemModalShown } = useContext(EnhancedClientContext);

  const handleSelectRole = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAdminSelectedRole(e.target.value as UserBusinessRoles);
      // redirect after the role has been updated
      setImmediate(() => history.push("/"));
    },
    [setAdminSelectedRole]
  );

  if (!user || !userHasRole) return null;
  return (
    <TooltipStyled
      {...tooltipProps}
      open={open && !enhancedReportProblemModalShown}
      title={
        <div ref={ref}>
          {user.is_admin && !!adminSelectedRole && (
            <RadioButtonsGroup
              isVertical
              options={ROLE_OPTIONS}
              selectedValue={adminSelectedRole}
              onChange={handleSelectRole}
            />
          )}

          {userHasRole(UserBusinessRoles.MANUFACTURING) && (
            <AccountMenuManufacturerSelector tw="mb-[16px]" />
          )}

          {(adminSelectedRole === UserBusinessRoles.BUYING ||
            (!user.is_admin && userHasRole(UserBusinessRoles.BUYING))) && (
            <AccountMenuPharmacySelector tw="mb-[16px]" />
          )}

          <AccountMenuLinks />

          <AccountMenuItems />
        </div>
      }
    >
      <div>{children}</div>
    </TooltipStyled>
  );
});
