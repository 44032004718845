import React, {createContext, useContext} from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";
import EditOutlined from "@ant-design/icons/EditOutlined";
import type { AdminAccount } from "../../../../../utilities/types";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { renderBoolean } from "../../../../../components/antd/table/renderBoolean";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import {
  YES_NO_BOOLEAN_FILTERS,
  getBooleanFilter,
} from "../../../../../components/antd/table/getBooleanFilter";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus } from "../../../../../components/rxLibrary/icons";
import { getColumnSearchProps } from "../../../../../components/antd/table/getColumnSearchProps";
import { useRequest } from "../../../../../services/request/useRequest";
import { getAdminAccounts } from "../../../../../services/accounts";
import { renderAdminPharmacies } from "../../../../../components/antd/table/renderAdminPharmacies";
import { renderList } from "../../../../../components/antd/table/renderList";
import { getDateSorter } from "../../../../../components/antd/table/getDateSorter";
import { renderDate } from "../../../../../components/antd/table/renderDate";
import { SendWelcomeEmailButton } from "./SendWelcomeEmailButton";

const AdminAccountsContext = createContext<
  { refetchAccounts: () => void } | undefined
>(undefined);

const BREADCRUMBS = [{ title: "Accounts" }];

const COLUMNS: TableColumns<AdminAccount> = [
  {
    dataIndex: "id",
    title: "ID",
    width: 60,
    fixed: "left",
    sorter: getNumberSorter("id"),
  },
  {
    dataIndex: "email",
    title: "Email",
    width: 200,
    ...getColumnSearchProps("email"),
    sorter: getTextSorter("email"),
  },
  {
    dataIndex: "fullName",
    title: "Full Name",
    width: 150,
    fixed: "left",
    ...getColumnSearchProps("fullName"),
    sorter: getTextSorter("fullName"),
  },
  {
    dataIndex: "isAdmin",
    title: "Is Admin",
    align: "center",
    width: 180,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("isAdmin"),
    sorter: getNumberSorter("isAdmin"),
    render: renderBoolean,
  },
  {
    dataIndex: "businessRoles",
    title: "Business Roles",
    width: 200,
    render: renderList,
  },
  {
    dataIndex: "isTrialUser",
    title: "Is Trial User",
    align: "center",
    width: 180,
    filters: YES_NO_BOOLEAN_FILTERS,
    onFilter: getBooleanFilter("isTrialUser"),
    sorter: getNumberSorter("isTrialUser"),
    render: renderBoolean,
  },
  {
    dataIndex: "supportContextMenu",
    width: 160,
    title: function renderSupportContextMenuTitle() {
      return (
        <Tooltip title="Allow debugging mode in Electron with Inspect Element">
          Support Context Menu
        </Tooltip>
      );
    },
    render: renderBoolean,
  },
  {
    dataIndex: "defaultBuyingPharmacyId",
    title: "Default Buying Pharmacy Id",
    width: 150,
  },
  {
    dataIndex: "welcomeEmailSentAt",
    title: "Welcome Email Last Sent At",
    width: 150,
    sorter: getDateSorter("welcomeEmailSentAt"),
    render: renderDate,
  },
  {
    dataIndex: "pharmacies",
    title: "Pharmacies",
    width: 600,
    render: renderAdminPharmacies,
  },
  {
    key: "actions",
    dataIndex: "id",
    title: "Actions",
    width: 100,
    fixed: "right",
    render: (id, account) => {
      const { welcomeEmailSentAt } = account;

      return (
        <div tw="flex items-center">
          <Link to={`/admin/account/${id}`}>
            <IconButton>
              <EditOutlined rev={undefined} />
            </IconButton>
          </Link>

          {!welcomeEmailSentAt && <SendWelcomeEmailButtonProxy userId={id} />}
        </div>
      );
    },
  },
];

function SendWelcomeEmailButtonProxy({ userId }: { userId: number }) {
  const { refetchAccounts } = useContext(AdminAccountsContext) ?? {};
  return (
    <SendWelcomeEmailButton
      smallWithConfirmation
      userId={userId}
      onSuccess={refetchAccounts}
    />
  );
}

export function AdminAccounts() {
  const { data, error, isLoading, refetch } = useRequest({
    dataKey: "accounts",
    request: getAdminAccounts,
  });

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        <Link to="/admin/account/new">
          <Button icon={<IconPlus />}>Add Account</Button>
        </Link>
      </AdminManagementHeader>

      <AdminAccountsContext.Provider value={{ refetchAccounts: refetch }}>
        <AdminManagementTable
          columns={COLUMNS}
          dataSource={data}
          loading={isLoading}
          hasError={!!error}
        />
      </AdminAccountsContext.Provider>
    </>
  );
}
