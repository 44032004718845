import { useMemo, useCallback } from "react";
import Select from "react-select";
import { usePerformance } from "../usePerformance";

export function PerformanceHeaderReportSelector() {
  const { reports, reportId, setReportId } = usePerformance();

  const options = useMemo(() => {
    return reports.map((r) => ({ value: r.id, label: r.title }));
  }, [reports]);

  const selectedReport = useMemo(() => {
    return options.find((report) => report.value == reportId);
  }, [options, reportId]);

  const handleSelection = useCallback(
    (selection: typeof options[number] | null) => {
      setReportId(selection?.value);
    },
    [setReportId]
  );

  return (
    <div tw="flex space-x-2 items-center">
      <span tw="font-light">Report:</span>

      <Select
        tw="w-[192px]"
        value={selectedReport ?? null}
        options={options}
        isMulti={false}
        onChange={handleSelection}
      />
    </div>
  );
}
