import type {
  AdminSupplierMappingAndEdi,
  SupplierMappingAndEdiOptions,
  UpsertAdminSupplierMappingAndEdi,
  SupplierMappingAndEdiOptionsBySupplier,
} from "../utilities/types";
import { backendFetch } from "./legacy/config/backend-api";
import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";

export async function createAdminSupplierMappingAndEdi(
  client: RequestClient,
  {
    supplierId,
    pharmacyId,
    pharmacyIsaCode,
    accountId,
    isSupplierMappingActive,
    billToPharmacyId,
    ...config
  }: RequestClientConfig<UpsertAdminSupplierMappingAndEdi>
) {
  return client<{ supplierMappingAndEdi: AdminSupplierMappingAndEdi }>({
    ...config,
    url: "/admin/supplier-mapping-and-edi",
    method: "POST",
    data: {
      supplierId,
      pharmacyId,
      accountId,
      pharmacyIsaCode,
      billToPharmacyId,
      isSupplierMappingActive,
    },
  });
}

export function getAdminSupplierMappingAndEdiOptionsBySupplier(
  client: RequestClient,
  { supplierId, ...config }: RequestClientConfig<{ supplierId: number }>
) {
  return client<{
    supplierMappingAndEdiOptionsBySupplier: SupplierMappingAndEdiOptionsBySupplier;
  }>({
    ...config,
    url: `/admin/supplier-mapping-and-edi/options/${supplierId}`,
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export function getAdminSupplierMappingAndEdiOptions(token: string) {
  return backendFetch<{
    supplierMappingAndEdiOptions: SupplierMappingAndEdiOptions;
  }>("/admin/supplier-mapping-and-edi/options", {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export function getAdminSupplierMappingAndEdiBySupplierId(
  token: string,
  supplierId: number
) {
  return backendFetch<{
    supplierMappingAndEdi: {
      supplierId: number;
      items: {
        accountId: string;
        billToPharmacyId: number;
        billToPharmacyName: string;
        pharmacyId: number;
        pharmacyIsaCode: string;
        pharmacyName: string;
        supplierMappingStatus: true;
        supplierName: string;
      }[];
    }[];
  }>(`/admin/supplier-mapping-and-edi/supplier/${supplierId}`, {
    method: "GET",
    headers: { Authorization: "Bearer " + token },
  });
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export function getAdminSupplierMappingAndEdiByPharmacyId(
  token: string,
  pharmacyId: number
) {
  return backendFetch(
    `/admin/supplier-mapping-and-edi/pharmacy/${pharmacyId}`,
    {
      method: "GET",
      headers: { Authorization: "Bearer " + token },
    }
  );
}
