import React from "react";
import "twin.macro";
import { Badge, Tooltip } from "antd";
import {
  Button,
  IconButton,
} from "../../../../../components/rxLibrary/buttons";
import { IconNote } from "../../../../../components/rxLibrary/icons";
import {
  Popover,
  useIsPopoverOpen,
} from "../../../../../components/rxLibrary/popover";
import { Title, Text } from "../../../../../components/rxLibrary/typography";
import { BLUE_1 } from "../../../../../components/rxLibrary/colors";

function ChangeLogNotesPopover({
  small,
  changeLogNotes,
}: {
  changeLogNotes: string[];
  small?: boolean;
}) {
  const { isPopoverOpen, handleOpenPopoverChange } = useIsPopoverOpen();
  const changeLogNotesCount = changeLogNotes.length;

  return (
    <Popover
      open={isPopoverOpen}
      onOpenChange={handleOpenPopoverChange}
      content={() => (
        <div tw="flex flex-col space-y-2 p-4">
          <Title block>Change Log Notes (Timestamp Coming Soon)</Title>

          <ul tw="space-y-2">
            {changeLogNotes.map((v) => (
              <li key={v}>
                <Text block>- {v}</Text>
              </li>
            ))}
          </ul>
        </div>
      )}
    >
      <div>
        <Tooltip title="View change log notes">
            {small ? (
              <Badge size="small" color={BLUE_1} count={changeLogNotes.length}>
                <IconButton>
                  <IconNote />
                </IconButton>
              </Badge>
            ) : (
              <Button variant="secondary" icon={<IconNote />}>
                Change Log Notes ({changeLogNotesCount})
              </Button>
            )}
        </Tooltip>
      </div>
    </Popover>
  );
}

export function ViewChangeLogNotes({
  small,
  changeLogNotes,
}: {
  small?: boolean;
  changeLogNotes?: string[];
}) {
  if (!changeLogNotes?.length) return null;
  return (
    <ChangeLogNotesPopover small={small} changeLogNotes={changeLogNotes} />
  );
}
