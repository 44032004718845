import { useState, useEffect } from "react";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import { formatDrugNameWithDetails } from "../../../utilities/drugInfo/formatDrugNameWithDetails";
import { stringToStringNumbersOrUndefined } from "../../../utilities/numbers/stringToStringNumbersOrUndefined";
import { getPrescriptionNdc } from "../../../utilities/prescriptions/getPrescriptionNdc";
import { formatNdc } from "../../../utilities/ndc/formatNdc";
import { ItemInCart } from "../../../utilities/types";
import { PmsEnum } from "../../../utilities/pms";
import { ItemInCartWithVisibility } from "./useShoppingListItems/useShoppingListItems.constants";

type SearchBarFilterObj = {
  filter: string;
  numericFilter?: string;
  scanBarcodeFilter?: string;
};

export type SearchBarItem = {
  rxNumbers: string[];
  drugName: string;
  ndc?: string;
};

function formatSearchBarDrugName(prescription: ItemInCart) {
  const { drug, rxNumber } = prescription;
  const drugName = formatDrugNameWithDetails(drug);
  const ndc = getPrescriptionNdc(prescription);
  const ndcData = ndc ? formatNdc(drug.ndc) : "Any NDC";
  const rxNumberData = rxNumber ? ` Rx ${rxNumber}` : "";
  const drugNameWithNdc = drugName + ` (${ndcData})` + rxNumberData;

  return drugNameWithNdc;
}

function shouldFilterSearchBarItem(
  item: SearchBarItem,
  searchBarFilterObj: SearchBarFilterObj
) {
  const { filter, numericFilter, scanBarcodeFilter } = searchBarFilterObj;
  const { ndc, rxNumbers, drugName } = item;

  if (scanBarcodeFilter !== undefined) {
    if (rxNumbers.some((s) => s === scanBarcodeFilter)) return true;
  }

  if (numericFilter !== undefined) {
    if (!!ndc && ndc.includes(numericFilter)) return true;
    if (rxNumbers.some((s) => s.includes(numericFilter))) return true;
  }

  if (drugName.toLowerCase().includes(filter)) return true;

  return false;
}

/**
 * Ref: https://app.shortcut.com/streamlinerx/story/20081/explore-allowing-the-user-to-search-on-p1-by-scanning-a-barcode-of-a-label
 */
function formatScanBarCodeFilter(
  filter: string,
  pms?: PmsEnum
): string | undefined {
  if (!pms) return;

  if (pms === PmsEnum.PioneerRx) {
    const result = /^X0?(\d{6,7})\d{2}$/.exec(filter);
    return result?.[1];
  }

  if (pms === PmsEnum.Liberty) {
    if (filter.length !== 11) return;

    const result = /^RX(\d{6})-\d{2}$/.exec(filter);
    return result?.[1];
  }
}

export function formatSearchBarFilterObj(value: string, pms?: PmsEnum) {
  const filter = value.trim();
  if (filter.length <= 2) return;

  const numericFilter = stringToStringNumbersOrUndefined(filter, {
    removeSeparators: true,
  });
  const scanBarcodeFilter = formatScanBarCodeFilter(filter, pms);
  const searchBarFilterObj: SearchBarFilterObj = {
    filter: filter.toLowerCase(),
    numericFilter,
    scanBarcodeFilter,
  };

  return searchBarFilterObj;
}

export function filterSearchBarItems(
  searchBarFilterObj: SearchBarFilterObj,
  items: SearchBarItem[]
) {
  const newItems = items.filter((item) => {
    const shouldFilter = shouldFilterSearchBarItem(item, searchBarFilterObj);
    return shouldFilter;
  });

  return newItems;
}

export function shouldFilterPrescriptionBySearchBarFilter(
  prescription: ItemInCart,
  searchBarFilterObj?: SearchBarFilterObj
) {
  if (!searchBarFilterObj) return false;

  const { rxNumber } = prescription;
  const ndc = getPrescriptionNdc(prescription);
  const drugName = formatSearchBarDrugName(prescription);
  const item = { ndc, drugName, rxNumbers: rxNumber ? [rxNumber] : [] };
  const shouldFilter = !shouldFilterSearchBarItem(item, searchBarFilterObj);
  return shouldFilter;
}

export function useSearchBarItems(
  prescriptionsA: ItemInCartWithVisibility[],
  prescriptionsB: ItemInCartWithVisibility[]
) {
  const [searchBarItems, setSearchBarItems] = useState<SearchBarItem[]>([]);

  useEffect(() => {
    const items = [...prescriptionsA, ...prescriptionsB]
      .filter(({ filtered, filteredBySearchBarFilter }) => {
        return filtered ? filteredBySearchBarFilter : true;
      })
      .map(({ prescription }) => {
        const { rxNumber } = prescription;
        const drugName = formatSearchBarDrugName(prescription);
        const ndc = getPrescriptionNdc(prescription);
        return { ndc, rxNumber, drugName };
      });

    const newSearchBarItems = map(groupBy(items, "drugName"), (items) => {
      const { ndc, drugName } = items[0];
      const rxNumbers = items
        .map((i) => i.rxNumber)
        .filter(Boolean) as string[];

      return { ndc, drugName, rxNumbers };
    });

    // the data needs to be sorted for the isEqual to work
    const newSearchBarItemsSorted = sortBy(newSearchBarItems, "drugName");
    // performance optimization to reduce the heavy re render calculations
    if (isEqual(searchBarItems, newSearchBarItemsSorted)) return;

    setSearchBarItems(newSearchBarItemsSorted);
  }, [prescriptionsA, prescriptionsB]);

  return searchBarItems;
}
