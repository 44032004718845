import React, { useContext, useEffect, useState } from "react";
import "twin.macro";
import { ItemInCart } from "../../../../../utilities/types";
import BuyingPharmacyContext from "../../../../../contexts/BuyingPharmacyContext";
import { formatSupplierDisplayNames } from "../../../../../utilities/suppliers/formatSupplierNames";

export function ShoppingListColumnPrescriptionSupplierNames({
  prescription,
}: {
  prescription: ItemInCart;
}) {
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const [supplierNames, setSupplierNames] = useState<string>();
  const { suppliers = [] } = currentBuyingPharmacy || {};
  const { status: prescriptionStatus, suppliersIdList = [] } = prescription;

  useEffect(() => {
    if (prescriptionStatus === "processed") {
      const names = suppliersIdList
        .map((supplierId) => {
          const match = suppliers.find((s) => s.id === supplierId);
          if (match) {
            return match.displayName;
          }
        })
        .filter(Boolean) as string[];
      const newSupplierNames = formatSupplierDisplayNames(names);

      if (supplierNames) {
        setSupplierNames(newSupplierNames);
        return;
      }
    }

    setSupplierNames(undefined);
  }, [suppliers, prescriptionStatus, suppliersIdList]);

  if (!supplierNames) return null;
  return <p className="color-gray2 text-xs mt-8">Was on {supplierNames}</p>;
}
