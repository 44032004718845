import { useMemo } from "react";
import { useParams } from "react-router-dom";

export function useSupplierMappingAndEdi() {
  const { supplierId: supplierIdRaw } = useParams<{ supplierId?: string }>();

  const supplierId = useMemo(() => {
    if (!supplierIdRaw) return;

    const value = parseInt(supplierIdRaw);
    if (!value || isNaN(value)) return;

    return value;
  }, [supplierIdRaw]);

  const supplierMappingAndEdiPath = useMemo(() => {
    return supplierId
      ? `/admin/supplier-mapping-and-edi/${supplierId}`
      : "/admin/supplier-mapping-and-edi";
  }, [supplierId]);

  return { supplierId, supplierMappingAndEdiPath };
}
