import React from "react";
import "twin.macro";
import { CSVLink } from "react-csv";
import { Button } from "../../../../../components/rxLibrary/buttons";

export function ExportToolPanelLarge({
  exportToolPanelProps: {
    exportCsvHeaders,
    exportAllCsvHeaders,
    exportCsvData,
    exportAllCsvData,
  },
}: {
  exportToolPanelProps: {
    exportCsvHeaders: string[];
    exportAllCsvHeaders: string[];
    exportCsvData: Array<never>;
    exportAllCsvData: Array<never>;
  };
}) {
  return (
    <>
      <CSVLink
        data={exportCsvData}
        headers={exportCsvHeaders}
        filename={"export.csv"}
      >
        <Button block fullWidth variant="secondary">
          Download CSV
        </Button>
      </CSVLink>

      <CSVLink
        data={exportAllCsvData}
        headers={exportAllCsvHeaders}
        filename={"export-all.csv"}
      >
        <Button block fullWidth variant="secondary">
          Download CSV (All Fields)
        </Button>
      </CSVLink>
    </>
  );
}
