import { useMemo } from "react";
import type {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { AgCharts } from "ag-charts-react";
import dayjs from "dayjs";
import { DATE_WITH_YEAR } from "../../../../utilities/dates/dates.constants";
import { rxChartTheme } from "../rxChart.theme";
import type { DailyRevenueTransactionsType } from "./overviewReport.types";
import { currencyFormatter } from "../utils/performanceReportFormatters";

function renderTooltip({
  datum,
  xKey,
  yKey,
  yName,
}: AgCartesianSeriesTooltipRendererParams) {
  const title = dayjs(datum[xKey]).format(DATE_WITH_YEAR);
  return {
    title: title,
    content: `${yName}: ${currencyFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Daily Revenue",
  },
  series: [
    {
      type: "bar",
      xKey: "date",
      yKey: "patientPaidAmount",
      yName: "Patient Paid Amount",
      stacked: true,
      fill: "rgb(98, 150, 188)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
    {
      type: "bar",
      xKey: "date",
      yKey: "insuranceRevenue",
      yName: "Insurance Revenue",
      stacked: true,
      fill: "rgb(55, 176, 103)",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "time",
      position: "bottom",
      label: {
        format: "%b%e %Y",
        rotation: -45,
      },
    },
    {
      type: "number",
      position: "left",
      label: {
        formatter: (params) => {
          const value = params.value / 1_000;
          return "$" + Math.floor(value) + "k";
        },
      },
    },
  ],
};

export function DailyRevenueBarChart({
  data,
}: {
  data?: DailyRevenueTransactionsType[];
}) {
  const reportData = useMemo(() => (data?.map((v) => {
    const parsedDate = dayjs(v["date"], "YYYY-MM-DD").toDate();
    return { ...v, date: parsedDate };
  })), [data]);

  return <AgCharts options={{ data: reportData, ...OPTIONS }} />;
}
