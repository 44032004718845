import { useMemo, useContext, useCallback } from "react";
import Select from "react-select";
import keyBy from "lodash/keyBy";
import PharmaciesContext from "../../../contexts/PharmaciesContext";
import { usePerformance } from "../usePerformance";

type Option = { value: number | null; label: string };

function LoadingMessage() {
  return "Loading...";
}

export function PerformanceHeaderPharmaciesSelect() {
  const { pharmacies } = useContext(PharmaciesContext);
  const { selectedPharmacies, setSelectedPharmacies } = usePerformance();
  const pharmaciesCount = pharmacies.length;

  const pharmacyOptions = useMemo(() => {
    if (!pharmacies.length) return [];
    return [
      { value: null, label: "-- All Pharmacies --" },
      ...pharmacies.map((p) => ({ value: p.id, label: p.name })),
    ];
  }, [pharmacies]);

  const pharmacySelections = useMemo(() => {
    if (!selectedPharmacies.length || !pharmacyOptions.length) return [];

    const pharmacyOptionsById = keyBy(pharmacyOptions, "value");
    return selectedPharmacies.reduce<Option[]>((acc, id) => {
      const option = pharmacyOptionsById[id];
      if (option) acc.push(option);
      return acc;
    }, []);
  }, [selectedPharmacies, pharmacyOptions]);

  const handleChange = useCallback(
    (selectedOptions: readonly Option[]) => {
      const useAllPharmacies =
        !selectedOptions.length ||
        selectedOptions.length >= pharmaciesCount ||
        selectedOptions.some((s) => s.value === null);
      const pharmacyIds = useAllPharmacies
        ? []
        : selectedOptions.map((s) => s.value as number);
      setSelectedPharmacies(pharmacyIds);
    },
    [pharmaciesCount, setSelectedPharmacies]
  );

  return (
    <div tw="flex items-center space-x-2">
      <span tw="font-light">Pharmacy:</span>

      <Select
        isMulti
        tw="min-w-[200px]"
        value={pharmacySelections}
        options={pharmacyOptions}
        isLoading={!pharmacyOptions.length}
        placeholder="All Pharmacies"
        onChange={handleChange}
        loadingMessage={LoadingMessage}
      />
    </div>
  );
}
