import { useEffect, useState } from "react";
import keyBy from "lodash/keyBy";
import isEmpty from "lodash/isEmpty";
import { ItemInCart } from "../../../../utilities/types";
import { SortDirectionEnum } from "../../../../components/rxLibrary/selects/SortBySelect";
import { useBuyingPharmacy } from "../../../../contexts/BuyingPharmacyContext";
import {
  formatSearchBarFilterObj,
  shouldFilterPrescriptionBySearchBarFilter,
} from "../searchBar";
import { sortShoppingListItems } from "./sortShoppingListItems";
import {
  HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS,
  CartItemsSortEnum,
  ItemInCartWithVisibility,
} from "./useShoppingListItems.constants";

function getStatusFilters(filters: string[], hideOptimizedItems: boolean) {
  if (isEmpty(filters)) return;

  const statusFilters = [...filters];
  if (!hideOptimizedItems) {
    statusFilters.push("processed");
  }

  return statusFilters;
}

export function useVisibleShoppingListItems({
  sorts,
  filters,
  sortOrder,
  isInventory,
  prescriptions,
  searchBarFilter,
  hideOptimizedItems,
}: {
  sorts: CartItemsSortEnum[];
  filters: string[];
  sortOrder: SortDirectionEnum;
  isInventory: boolean;
  prescriptions: ItemInCart[];
  searchBarFilter: string;
  hideOptimizedItems: boolean;
}) {
  const { getSupplierById } = useBuyingPharmacy();
  const [itemsWithVisibility, setItemsWithVisibility] = useState<
    ItemInCartWithVisibility[]
  >([]);

  useEffect(() => {
    const prescriptionUniqueKey = isInventory ? "key" : "rxNumber";
    const sortedItems = sortShoppingListItems(
      prescriptions,
      sorts,
      getSupplierById
    );
    if (sortOrder === SortDirectionEnum.DESC) {
      sortedItems.reverse();
    }

    const oldItemsWithVisibilityById = keyBy(
      itemsWithVisibility,
      `prescription.${prescriptionUniqueKey}`
    );
    const statusFilters = getStatusFilters(filters, hideOptimizedItems);
    const searchBarFilterObj = formatSearchBarFilterObj(searchBarFilter);

    const newItemsWithVisibility = sortedItems.map((prescription) => {
      const prescriptionKey = prescription?.[prescriptionUniqueKey] as string;
      const oldItemWithVisibility = oldItemsWithVisibilityById[prescriptionKey];
      let filtered = false;

      if (hideOptimizedItems) {
        filtered = prescription.status === "processed";
      }
      if (statusFilters) {
        filtered = !statusFilters.includes(prescription.status ?? "");
      }

      const filteredBySearchBarFilter =
        shouldFilterPrescriptionBySearchBarFilter(
          prescription,
          searchBarFilterObj
        );
      if (filteredBySearchBarFilter) filtered = true;

      const visible = filtered ? oldItemWithVisibility?.visible ?? true : true;
      return { prescription, filtered, visible, filteredBySearchBarFilter };
    });

    setItemsWithVisibility(newItemsWithVisibility);

    const ref = setTimeout(() => {
      const itemsWithRemovedUpdated = newItemsWithVisibility.map((item) => {
        const { filtered, visible } = item;
        if (filtered && visible) return { ...item, visible: false };
        return item;
      });

      setItemsWithVisibility(itemsWithRemovedUpdated);
    }, HIDE_SHOPPING_LIST_ROW_ANIMATION_TIME_MS);

    return () => {
      clearTimeout(ref);
    };
  }, [
    sorts,
    filters,
    sortOrder,
    prescriptions,
    searchBarFilter,
    hideOptimizedItems,
    getSupplierById,
  ]);

  return itemsWithVisibility;
}
