import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import "twin.macro";
import type {
  SupplierAccountsSummaryView,
  SupplierAccountManagementData,
  SupplierAccountRecordView,
  AuthSaveCredentialsReply,
  AuthDeleteCredentialsReply,
  SupplierAccountAuthenticationDetail,
} from "./EnhancedSupplierAccount.constants";
import AppContext from "../../contexts/AppContext";
import EnhancedClientConfigContext from "../EnhancedClientConfigContext";
import BuyingPharmacyContext from "../../contexts/BuyingPharmacyContext";
import EnhancedClientContext from "../EnhancedClientContext";
import { EnhancedSupplierAccountSettings } from "./EnhancedSupplierAccountSettings/EnhancedSupplierAccountSettings";
import { EnhancedSupplierAccountSummary } from "./EnhancedSupplierAccountSummary/EnhancedSupplierAccountSummary";
import { dispatch } from "./EnhancedSupplierAccount.utils";

function sortSuppliers(suppliers: SupplierAccountsSummaryView[]) {
  return suppliers.sort((a, b) => {
    if (a.supplierDisplay < b.supplierDisplay) return -1;
    if (a.supplierDisplay > b.supplierDisplay) return 1;
    return 0;
  });
}

export function EnhancedSupplierAccount() {
  const { user } = useContext(AppContext);
  const { currentBuyingPharmacy } = useContext(BuyingPharmacyContext);
  const { enhancedClientActive } = useContext(EnhancedClientConfigContext);
  const { enhancedSupplierConnectionStatuses } = useContext(
    EnhancedClientContext
  );

  const [accountManagementData, setAccountManagementData] =
    useState<SupplierAccountManagementData | null>(null);
  const [{ tableData, offlineSuppliers, ecommerceSuppliers }, setData] =
    useState<{
      tableData: SupplierAccountsSummaryView[];
      offlineSuppliers: SupplierAccountsSummaryView[];
      ecommerceSuppliers: SupplierAccountsSummaryView[];
    }>({
      tableData: [],
      offlineSuppliers: [],
      ecommerceSuppliers: [],
    });
  const [shouldShowAllPasswords, setShouldShowAllPasswords] = useState(false);
  const [shownPasswords, setShownPasswords] = useState<
    Record<string, boolean | undefined>
  >({});
  const [currentEditedSupplierName, setCurrentEditedSupplierName] = useState<
    string | null
  >(null);
  const workstationName = accountManagementData?.workstationName;

  const currentEditedSupplier = useMemo(() => {
    return tableData.find((entry) => {
      return entry.supplier === currentEditedSupplierName;
    });
  }, [currentEditedSupplierName, tableData]);

  const showAllPasswords = useCallback(() => {
    setShouldShowAllPasswords(true);
  }, []);

  const hideAllPasswords = useCallback(() => {
    setShouldShowAllPasswords(false);
  }, []);

  const togglePassword = useCallback((row: SupplierAccountRecordView) => {
    setShownPasswords((prev) => ({
      ...prev,
      [row.id]: !prev[row.id],
    }));
  }, []);

  const returnToSummary = useCallback(() => {
    setCurrentEditedSupplierName(null);
    setShownPasswords({});
  }, []);

  useEffect(() => {
    const messageListenerCallback = async (event: MessageEvent) => {
      if (event.source !== window) {
        return;
      }
      if (event.data.channel === "daylightrxenhanced") {
        if (event.data.msgType === "deliverSupplierAccountDetails") {
          const payload = event.data.payload as SupplierAccountManagementData;
          console.log("Received supplier account details", payload);
          setAccountManagementData(payload);
        } else if (event.data.msgType === "credentialsSaved") {
          const payload = event.data.payload as AuthSaveCredentialsReply;
          console.log("Received credentials saved reply", payload);
          setAccountManagementData(payload.data);
        } else if (event.data.msgType === "credentialsDeleted") {
          const payload = event.data.payload as AuthDeleteCredentialsReply;
          console.log("Received credentials deleted reply", payload);
          setAccountManagementData(payload.data);
        }
      }
    };
    window.addEventListener("message", messageListenerCallback);
    return () => {
      window.removeEventListener("message", messageListenerCallback);
    };
  }, []);

  useEffect(() => {
    if (!currentBuyingPharmacy) return;
    if (enhancedClientActive && user) {
      dispatch("requestSupplierAccountDetails", {});
    } else if (user) {
      setAccountManagementData({
        workstationName: "12345",
        pharmacyId: currentBuyingPharmacy.id,
        keyFormatString:
          "db2::user_email::pharmacy_id::supplier::supplier_username",
        authenticationDetails: [
          {
            id: `db2:${user.email}::12345::KINRAY::test@example.com`,
            supplier: "KINRAY",
            supplierDisplay: "Kinray",
            userName: "test@example.com",
            password: "password",
          },
          {
            id: `db2:${user.email}::12345::SMARTSOURCE::test@example.com`,
            supplier: "SMARTSOURCE",
            supplierDisplay: "ABC Smartsource",
            userName: "test@example.com",
            password: "password2",
          },
          {
            id: `db2:${user.email}::12345::SMARTSOURCE::test-second@example.com`,
            supplier: "SMARTSOURCE",
            supplierDisplay: "ABC Smartsource",
            userName: "test-second@example.com",
            password: "passwordSecond",
          },
        ],
      });
    }
  }, [currentBuyingPharmacy, enhancedClientActive, user]);

  useEffect(() => {
    if (!currentBuyingPharmacy || !accountManagementData || !user) {
      return;
    }

    const detailsBySupplier: Record<
      string,
      SupplierAccountAuthenticationDetail[]
    > = {};
    accountManagementData.authenticationDetails.forEach((detail) => {
      if (!detailsBySupplier[detail.supplier]) {
        detailsBySupplier[detail.supplier] = [];
      }
      detailsBySupplier[detail.supplier].push(detail);
    });

    let tableData: SupplierAccountsSummaryView[] = [];
    let offlineSuppliers: SupplierAccountsSummaryView[] = [];
    let ecommerceSuppliers: SupplierAccountsSummaryView[] = [];
    currentBuyingPharmacy.suppliers.forEach((supplier) => {
      const details = detailsBySupplier[supplier.name];
      let entry: SupplierAccountsSummaryView;
      if (details) {
        entry = {
          supplierId: supplier.id,
          supplier: supplier.name,
          isEcommerceEnabled: supplier.isEcommerceEnabled,
          supplierDisplay: supplier.displayName,
          autologinDisabled: details.length > 1,
          loggedIn:
            enhancedSupplierConnectionStatuses[supplier.name]?.loggedIn ||
            false,
          supplierOfflinePurchasing: false,
          accounts: [],
        };
        details.forEach((detail) => {
          entry.accounts.push({
            ...detail,
            supplierDisplay: supplier.displayName,
            passwordVisible:
              !!shownPasswords[detail.id] || shouldShowAllPasswords,
          });
        });
        entry.accounts.sort((a, b) => {
          if (!a.userName) return 1;
          if (!b.userName) return -1;
          if (a.userName < b.userName) return -1;
          if (a.userName > b.userName) return 1;
          return 0;
        });
      } else {
        entry = {
          supplierId: supplier.id,
          supplier: supplier.name,
          isEcommerceEnabled: supplier.isEcommerceEnabled,
          supplierDisplay: supplier.displayName,
          supplierOfflinePurchasing: supplier.offlinePurchasing || false,
          autologinDisabled: false,
          loggedIn:
            enhancedSupplierConnectionStatuses[supplier.name]?.loggedIn ||
            false,
          accounts: [],
        };
      }

      if (entry.isEcommerceEnabled) {
        ecommerceSuppliers.push(entry);
      } else if (entry.supplierOfflinePurchasing) {
        offlineSuppliers.push(entry);
      } else {
        tableData.push(entry);
      }
    });

    tableData = sortSuppliers(tableData);
    offlineSuppliers = sortSuppliers(offlineSuppliers);
    ecommerceSuppliers = sortSuppliers(ecommerceSuppliers);

    setData({ tableData, offlineSuppliers, ecommerceSuppliers });
  }, [
    user,
    shownPasswords,
    shouldShowAllPasswords,
    currentBuyingPharmacy,
    accountManagementData,
    enhancedSupplierConnectionStatuses,
  ]);

  if (currentEditedSupplier) {
    return (
      <EnhancedSupplierAccountSettings
        workstationName={workstationName}
        supplierAccount={currentEditedSupplier}
        togglePassword={togglePassword}
        returnToSummary={returnToSummary}
      />
    );
  }

  return (
    <EnhancedSupplierAccountSummary
      tableData={tableData}
      workstationName={workstationName}
      offlineSuppliers={offlineSuppliers}
      ecommerceSuppliers={ecommerceSuppliers}
      shouldShowAllPasswords={shouldShowAllPasswords}
      togglePassword={togglePassword}
      showAllPasswords={showAllPasswords}
      hideAllPasswords={hideAllPasswords}
      setCurrentEditedSupplierName={setCurrentEditedSupplierName}
    />
  );
}
