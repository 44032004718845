import { css } from "twin.macro";

export const ADD_RX_MOVE_DOWN_ANIMATION_CSS = css`
  animation: add-rx-move-down-animation 500ms ease-out 1;
  @keyframes add-rx-move-down-animation {
    0% {
      transform: translateY(-138px);
      opacity: 0.1;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const ADD_RX_FADE_IN_ANIMATION_CSS = css`
  animation: add-rx-fade-in-animation 500ms ease-out 1;
  @keyframes add-rx-fade-in-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ADD_RX_FADE_OUT_ANIMATION_CSS = css`
  animation: add-rx-fade-out-animation 500ms ease-out 1;
  @keyframes add-rx-fade-out-animation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export function sequenceActions(
  actions: ([() => void, number] | [() => void])[]
) {
  let timeoutId: NodeJS.Timeout;
  execute();

  function execute(index = 0) {
    if (index >= actions.length) return;

    const [action, delay = 0] = actions[index];
    timeoutId = setTimeout(() => {
      action();
      execute(index + 1);
    }, delay);
  }

  return () => clearTimeout(timeoutId);
}
