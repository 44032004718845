import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
  percentFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  {
    field: "prescriber",
    headerName: "Prescriber Name",
    pinned: "left",
    flex: 1,
    minWidth: 125,
  },
  {
    field: "totalPatients",
    headerName: "Total Patients",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 110,
  },
  {
    field: "previousPeriodNewTransactions",
    headerName: "Prev Period - New Txns.",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 110,
    hide: true,
  },
  {
    field: "transactions",
    headerName: "Total Txns.",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 110,
  },
  {
    field: "newTransactions",
    headerName: "New Txns.",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 110,
  },
  {
    field: "changeInTransactions",
    headerName: "# Change Txns.",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 110,
  },
  {
    field: "percentChangeInTransactions",
    headerName: "% Change Txns.",
    valueFormatter: (params) => percentFormatter(params.value, 1),
    flex: 1,
    minWidth: 110,
  },
  {
    field: "previousTotalPricePaid",
    headerName: "Prev Period - Total Price Paid",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 125,
    hide: true,
  },
  {
    field: "totalPricePaid",
    headerName: "Total Price Paid",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "changeInTotalPricePaid",
    headerName: "$ Change Total Price Paid",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "percentChangeInTotalPricePaid",
    headerName: "% Change Total Price Paid",
    valueFormatter: (params) => percentFormatter(params.value, 1),
    flex: 1,
    minWidth: 125,
  },
];
