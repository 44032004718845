import React, { useCallback, useMemo, useState } from "react";
import tw, { styled, css } from "twin.macro";
import cn from "classnames";
const minus = new URL('svgurl:../../../assets/icon-minus.svg', import.meta.url).toString();
const plus = new URL('svgurl:../../../assets/icon-plus.svg', import.meta.url).toString();
import { DrugInfo } from "../../../utilities/types";
import {
  IHandleDrugClick,
  IDrugSearchTableData,
} from "../DrugSearch.constants";

export function DrugSearchResultsTable({
  drugSearchTableData,
  handleDrugClick,
}: {
  drugSearchTableData: IDrugSearchTableData;
  handleDrugClick: IHandleDrugClick;
}) {
  return (
    <div tw="bg-white border-[#e0e0e0] border-b my-2">
      {drugSearchTableData.map((drugs, index) => (
        <DrugSearchResultsTableRow
          key={`drug-${drugs[0].name}-${index}`}
          drugs={drugs}
          handleDrugClick={handleDrugClick}
        />
      ))}
    </div>
  );
}

function DrugSearchResultsTableRow({
  drugs,
  handleDrugClick,
}: {
  drugs: DrugInfo[];
  handleDrugClick: IHandleDrugClick;
}) {
  const { expanded, expandedControl, togglePanel } = useToggleControl();
  const drugName = drugs[0].name;
  const rxsLast90Days = useMemo(() => sumRxsLast90Days(drugs), [drugs]);

  return (
    <>
      <div
        tw="flex w-full border-l border-r border-[#e0e0e0] border-t cursor-pointer h-[72px]"
        className={cn({ "border-b": expanded })}
        css={ROW_HOVER_OUTLINE_CSS}
        onClick={togglePanel}
      >
        {expandedControl}

        <DrugDetail tw="flex items-center justify-between">
          <Title>{drugName}</Title>
        </DrugDetail>

        <DrugDetail />
        <DrugDetail />
        <DrugDetailRx>
          {!!rxsLast90Days && (
            <>
              <TitleRX>
                {rxsLast90Days} {rxsLast90Days === 1 ? "Rx" : "Rxs"}
              </TitleRX>
              <Subtitle>DISPENSED LAST 90 days</Subtitle>
            </>
          )}
        </DrugDetailRx>
      </div>

      {expanded &&
        drugs.map((drug, i) => (
          <DrugSearchResultsTableRow2
            key={`${drug.name}-${i}`}
            drug={drug}
            handleDrugClick={handleDrugClick}
            isLastItem={i === drugs.length - 1}
          />
        ))}
    </>
  );
}

function DrugSearchResultsTableRow2({
  drug,
  isLastItem,
  handleDrugClick,
}: {
  drug: DrugInfo;
  isLastItem: boolean;
  handleDrugClick: IHandleDrugClick;
}) {
  const {
    name: drugName,
    sizeOptions,
    strength,
    strengthUnit,
    form,
    routeOfAdministration,
  } = drug;
  const rxsLast90Days = useMemo(() => sumRxsLast90Days([drug]), [drug]);
  const isExpandable = !!(sizeOptions && sizeOptions.length > 1);
  const { expanded, expandedControl, togglePanel } =
    useToggleControl(isExpandable);

  const onDrugClick = useCallback(() => {
    if (isExpandable) {
      togglePanel();
    } else {
      handleDrugClick({ drug });
    }
  }, [drug, isExpandable, togglePanel, handleDrugClick]);

  return (
    <>
      <div tw="flex w-full border-l border-r border-[#e0e0e0] h-[72px]">
        <ColSpacing />
        <div
          tw="flex w-full border-[#e0e0e0] cursor-pointer"
          className={cn({ "border-b": expanded || !isLastItem })}
          css={isExpandable ? ROW_HOVER_OUTLINE_CSS : ROW_HOVER_CLICKABLE_CSS}
          onClick={onDrugClick}
        >
          {expandedControl}

          <DrugDetail style={{ minWidth: SECOND_LEVEL_MIN_WIDTH }}>
            <Title>{drugName}</Title>
          </DrugDetail>

          <DrugDetail>
            <Title>
              {strength ? `${strength} ${strengthUnit || ""}` : "N/A"}
            </Title>
            <Subtitle>STRENGTH</Subtitle>
          </DrugDetail>

          <DrugDetail>
            <Title>{form || "N/A"}</Title>
            <Subtitle>FORM</Subtitle>
          </DrugDetail>

          <DrugDetail>
            <Title>{routeOfAdministration || "N/A"}</Title>
            <Subtitle>RoA</Subtitle>
          </DrugDetail>

          <DrugDetail />

          <DrugDetailRx>
            {!!rxsLast90Days && (
              <>
                <TitleRX>
                  {rxsLast90Days} {rxsLast90Days === 1 ? "Rx" : "Rxs"}
                </TitleRX>
                <Subtitle>DISPENSED LAST 90 days</Subtitle>
              </>
            )}
          </DrugDetailRx>
        </div>
      </div>

      {expanded &&
        sizeOptions?.map((sizeOption, i) => (
          <DrugSearchResultsTableRow3
            key={`${drugName}-${i}`}
            drug={sizeOption}
            isLastItem={i === sizeOptions.length - 1}
            handleDrugClick={handleDrugClick}
          />
        ))}
    </>
  );
}

function DrugSearchResultsTableRow3({
  drug,
  isLastItem,
  handleDrugClick,
}: {
  drug: DrugInfo;
  isLastItem: boolean;
  handleDrugClick: IHandleDrugClick;
}) {
  const {
    name: drugName,
    strength,
    strengthUnit,
    form,
    routeOfAdministration,
    unitSize,
    uom,
    dispensedLast90Days,
  } = drug;

  const onClick = useCallback(() => {
    handleDrugClick({ drug, isSizeOption: true });
  }, [drug, handleDrugClick]);

  return (
    <div tw="flex w-full border-l border-r border-[#e0e0e0] h-[72px]">
      <ColSpacing />
      <div
        tw="flex w-full border-[#e0e0e0]"
        className={cn({ "border-b": isLastItem })}
      >
        <ColSpacing />

        <div
          tw="flex w-full cursor-pointer border-[#e0e0e0]"
          className={cn({ "border-b": !isLastItem })}
          css={ROW_HOVER_CLICKABLE_CSS}
          onClick={onClick}
        >
          <ColSpacing tw="bg-[#f1f4fb]" />

          <DrugDetail style={{ minWidth: THIRD_LEVEL_MIN_WIDTH }}>
            <Title>{drugName}</Title>
          </DrugDetail>

          <DrugDetail>
            <Title>
              {strength ? `${strength} ${strengthUnit || ""}` : "N/A"}
            </Title>
            <Subtitle>STRENGTH</Subtitle>
          </DrugDetail>

          <DrugDetail>
            <Title>{form || "N/A"}</Title>
            <Subtitle>FORM </Subtitle>
          </DrugDetail>

          <DrugDetail>
            <Title>{routeOfAdministration || "N/A"}</Title>
            <Subtitle>RoA</Subtitle>
          </DrugDetail>

          <DrugDetail>
            <Title>{unitSize ? `${unitSize} ${uom}` : "N/A"}</Title>
            <Subtitle>SIZE</Subtitle>
          </DrugDetail>

          <DrugDetailRx>
            {!!dispensedLast90Days && (
              <>
                <TitleRX>
                  {dispensedLast90Days}{" "}
                  {dispensedLast90Days === 1 ? "Rx" : "Rxs"}
                </TitleRX>
                <Subtitle>DISPENSED LAST 90 days</Subtitle>
              </>
            )}
          </DrugDetailRx>
        </div>
      </div>
    </div>
  );
}

function useToggleControl(isExpandable = true) {
  const [expanded, setExpanded] = useState(false);

  const togglePanel = useCallback(() => {
    if (isExpandable) {
      setExpanded((isExpanded) => !isExpanded);
    }
  }, [isExpandable]);

  const expandedControl = isExpandable ? (
    <ColSpacing tw="flex justify-center items-center cursor-pointer bg-[#f1f4fb]">
      <img
        tw="w-[12px] h-[12px]"
        src={expanded ? minus : plus}
        alt="Toggle icon"
      />
    </ColSpacing>
  ) : (
    <ColSpacing tw="bg-[#f1f4fb]" />
  );

  return { expanded, expandedControl, togglePanel };
}

function sumRxsLast90Days(drugs: DrugInfo[]): number {
  const totalRxs = drugs.reduce<number>((acc, drug) => {
    const { sizeOptions, dispensedLast90Days = 0 } = drug;
    const value = sizeOptions?.length
      ? sumRxsLast90Days(sizeOptions)
      : dispensedLast90Days;

    return acc + value;
  }, 0);

  return totalRxs;
}

const COL_SPACING_WIDTH = 28;
const THIRD_LEVEL_MIN_WIDTH = 250;
const SECOND_LEVEL_MIN_WIDTH = THIRD_LEVEL_MIN_WIDTH + COL_SPACING_WIDTH;

const Title = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: condensed;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #202020;
`;

const TitleRX = styled(Title)`
  font-size: 14px;
  line-height: 1.5;
  color: #426bb4;
`;

const Subtitle = styled.p`
  font-family: Roboto;
  font-size: 11px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

const ColSpacing = styled.div`
  width: ${COL_SPACING_WIDTH}px;
  border-right: 1px solid #e0e0e0;
  box-sizing: border-box;
`;

const DrugDetailBase = tw.div`px-2.5 py-5`;
const DrugDetail = tw(DrugDetailBase)`flex-1`;
const DrugDetailRx = tw(DrugDetailBase)`w-[170px]`;

const ROW_HOVER_OUTLINE_CSS = css`
  position: relative;

  &:hover {
    z-index: 1;
    outline: 1px solid #324dc7;
  }
`;

const ROW_HOVER_CLICKABLE_CSS = css`
  ${ROW_HOVER_OUTLINE_CSS}

  &:hover {
    background-color: #324dc70f;
  }
`;
