import React from "react";
import tw, { TwStyle, css, styled } from "twin.macro";
import { SerializedStyles } from "@emotion/react";
import { Link } from "react-router-dom";
import { Supplier } from "../../../../utilities/types";
import { SupplierImage } from "../../../../components/suppliers/SupplierImage";
import { BUTTON_SHADOW_CSS } from "../../../../components/rxLibrary/buttons";
import { Text } from "../../../../components/rxLibrary/typography";
import { GREY_4 } from "../../../../components/rxLibrary/colors";

const IMG_HEIGHTS: Record<number, number | undefined> = {
  2: 104,
  3: 90,
  4: 79,
  5: 72,
  6: 72,
};

const STYLES: Record<
  number,
  (SerializedStyles | TwStyle)[] | TwStyle | undefined
> = {
  2: tw`gap-y-[39px] px-[59px]`,
  3: tw`gap-y-[28px] px-[36px]`,
  4: tw`gap-y-[7px] px-[75px]`,
  5: [
    tw`col-span-2 gap-y-[15px] px-[34px]`,
    css`
      &:nth-child(4n) {
        grid-column-start: 2;
      }
    `,
  ],
  6: tw`gap-y-[15px] px-[34px]`,
  7: [
    tw`col-span-2 gap-y-[18px] px-[22px]`,
    css`
      &:nth-child(5n) {
        grid-column-start: 2;
      }
    `,
  ],
  8: tw`gap-y-[18px] px-[22px]`,
};

const Wrapper = styled(Link)`
  ${BUTTON_SHADOW_CSS}
  background-color: ${GREY_4};
  border-radius: 9px;
  box-shadow: 0 0 10px 0 #0000001e;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export function SupplierCard({
  supplier,
  cardsCounter,
}: {
  supplier: Supplier;
  cardsCounter: number;
}) {
  const { id: supplierId, displayName } = supplier;
  const imgHeight = IMG_HEIGHTS[cardsCounter] ?? 60;
  const style = STYLES[cardsCounter];

  return (
    <Wrapper to={`/manufacturer-store/${supplierId}`} css={style}>
      <SupplierImage height={imgHeight} supplier={supplier} />
      <Text center color="grey-1">
        Buy {displayName} products
      </Text>
    </Wrapper>
  );
}
