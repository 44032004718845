import dayjs from "dayjs";
import type { ItemInCart } from "../../types";

export function sorterByDate(
  item: ItemInCart,
  field: "addedAt" | "purchaseBy" | "processedAt"
) {
  const date = item[field];
  return date ? dayjs(date).unix() : -1;
}
