
import { styled } from "twin.macro";

// Wrapper for style and height of ag-grid
export function TableWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Wrapper className="ag-theme-alpine">
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: calc(100% - 53px);

  &.ag-theme-alpine {
    --ag-font-size: 12px;
  }

  .reversed-claim {
    color: #c3c3c3 !important;
  }
`;
