import React, { Fragment } from 'react';
import {
  useTable,
  Column,
  useExpanded,
  Row,
  HeaderGroup,
  Cell,
} from 'react-table';
import cn from 'classnames';
import '../scss/table.scss';
import DotLoader from './loaders/DotLoader/DotLoader';

interface ITableProps {
  columns: Column<Record<string, unknown>>[];
  data: Record<string, unknown>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderRowSubComponent?: any;
  variant?: 'primary' | 'custom';
  classNames?: string;
  initialState?: Record<string, unknown>;
  showFooter?: boolean;
  hasError?: boolean;
  loading?: boolean;
  noDataComponent?: string | JSX.Element;
  errorComponent?: string | JSX.Element;
  isStickyHeader?: boolean;
  alert?: string;
  footer?: JSX.Element;
}

export function Table({
    columns,
    data,
    renderRowSubComponent,
    variant = 'primary',
    classNames = '',
    initialState,
    showFooter = false,
    hasError = false,
    loading = false,
    noDataComponent = '',
    errorComponent = '',
    isStickyHeader = true,
    alert = '',
    footer,
  }: ITableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useExpanded
  );

  function renderFooter() {
    if (!showFooter) return null;
    if (footer) return <tfoot>{footer}</tfoot>;
    return (
      <tfoot>
        {footerGroups.map((group, i) => (
          <tr {...group.getFooterGroupProps()} key={`footer-row_${i}`}>
            {group.headers.map((column, j) => (
              <td {...column.getFooterProps()} key={`footer-cell_${j}`}>
                {column.render("Footer")}
              </td>
            ))}
          </tr>
        ))}
      </tfoot>
    );
  }

  return (
    <table
      {...getTableProps()}
      className={cn(`table table--${variant}`, classNames, {
        exception:
          loading || hasError || (data.length === 0 && noDataComponent !== ""),
      })}
    >
      <thead className={cn("thead", { "sticky-header": isStickyHeader })}>
        {headerGroups.map((headerGroup: HeaderGroup, i: number) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`header-row_${i}`}>
            {headerGroup.headers.map((column, j) => (
              <th
                {...column.getHeaderProps({
                  style: {
                    width: column.width,
                    minWidth: column.minWidth,
                    maxWidth: column.maxWidth,
                  },
                })}
                key={`header-column_${j}`}
              >
                <div className={cn("th-cell", column.headerClassNames)}>
                  {column.render("Header")}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()} className="tbody">
        {alert && (
          <tr className="tr-exception">
            <td className="td-exception" colSpan={visibleColumns.length}>
              <p className="color-orange text-xl text-center py-14">{alert}</p>
            </td>
          </tr>
        )}
        {loading ? (
          <tr className="tr-exception">
            <td className="td-exception" colSpan={visibleColumns.length}>
              <DotLoader />
            </td>
          </tr>
        ) : hasError ? (
          <tr className="tr-exception">
            <td className="td-exception" colSpan={visibleColumns.length}>
              {errorComponent}
            </td>
          </tr>
        ) : rows.length > 0 ? (
          rows.map((row: Row, i: number) => {
            prepareRow(row);
            return (
              <Fragment key={`body-row_${i}`}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell, j: number) => (
                    <td
                      {...cell.getCellProps({
                        style: {
                          minWidth: cell.column.minWidth,
                          width: cell.column.width,
                          maxWidth: cell.column.maxWidth,
                        },
                        className: cell.column.classNames,
                      })}
                      key={`body-td_${j}`}
                    >
                      <div className="td-cell" key={`body-cell_${j}`}>
                        {cell.render("Cell")}
                      </div>
                    </td>
                  ))}
                </tr>
                <tr className="separator"></tr>
                {row.isExpanded ? (
                  <tr className="tr-sub">
                    <td colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })
        ) : (
          <tr className="tr-exception">
            <td className="td-exception" colSpan={columns.length}>
              {noDataComponent}
            </td>
          </tr>
        )}
      </tbody>
      {renderFooter()}
    </table>
  );
}
