import React, { useEffect, useRef, useState } from "react";
import "twin.macro";
import { Table, TableProps } from "antd";
import { UnexpectedError } from "../../../../../components/errors/UnexpectedError";

export function AdminManagementTable<T extends Record<string, any>>({
  rowKey = "id",
  pagination = false,
  sticky = true,
  virtual = true,
  scroll: scrollOverride,
  hasError = false,
  ...props
}: TableProps<T> & { hasError?: boolean }) {
  const ref = useRef<HTMLDivElement>(null);
  const tableRef = useRef<any>(null);
  const screenHeight = window.innerHeight;

  const [scroll, setScroll] = useState<TableProps["scroll"]>({x: 1, y: 0});
  useEffect(() => {
    if (scrollOverride) return;

    let newScroll;
    const tableEl = tableRef.current?.nativeElement;
    if (tableEl) {
      const offsetTop = ref.current?.offsetTop ?? 0;
      const headerHeight = tableEl.querySelector("thead").clientHeight;
      newScroll = { y: screenHeight - headerHeight - offsetTop, x: 1 };
    }
    setScroll(newScroll);
  }, [screenHeight, scrollOverride]);

  if (hasError) return <UnexpectedError />;
  return (
    <>
      <div ref={ref} />

      <Table
        ref={tableRef}
        rowKey={rowKey}
        pagination={pagination}
        sticky={sticky}
        virtual={virtual}
        scroll={scrollOverride ?? scroll}
        {...props}
      />
    </>
  );
}
