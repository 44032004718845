import "twin.macro";
import type { Pharmacy, Supplier } from "../../../../utilities/types";
import {
  SUPPLIER_ID_FFF,
  SUPPLIER_ID_MAYNE,
  SUPPLIER_ID_JOURNEY,
} from "../../../../utilities/config";
import { ExtraWideContainer } from "../../../../components/containers/ExtraWideContainer";
import { FullPageLoader } from "../../../../components/loaders/FullPageLoader";
import { OrderNotSentToSupplierAdminWarning } from "./OrderNotSentToSupplierAdminWarning";
import { useManufacturerStoreItems } from "./useManufacturerStoreItems/useManufacturerStoreItems";
import { CatalogItemsWithCategories } from "./catalogItems/CatalogItemsWithCategories";
import { CatalogItemsWithSearch } from "./catalogItems/CatalogItemsWithSearch/CatalogItemsWithSearch";
import { CatalogCart } from "./CatalogCart/CatalogCart";
import { CatalogProvider } from "./useCatalog";
import { UnexpectedError } from "../../../../components/errors/UnexpectedError";
import { ManufacturerStoreWrapper } from "../../ManufacturerStoreWrapper";

export function Catalog({
  supplier,
  pharmacy,
}: {
  supplier: Supplier;
  pharmacy: Pharmacy;
}) {
  const supplierId = supplier.id;
  const pharmacyId = pharmacy.id;

  const {
    cartItems,
    itemsByType,
    shippingInfo,
    hasFoamItems,
    topSellingItems,
    shoppingListItems,
    mostRecentPurchases,
    addToCart,
    submitOrder,
    removeFromCart,
    updateItemQuantity,
    removeLocalStorageCartCatalog,
    error,
  } = useManufacturerStoreItems({ supplier, pharmacyId });

  const isFFFSupplier = supplierId === SUPPLIER_ID_FFF;
  const isMayneSupplier = supplierId === SUPPLIER_ID_MAYNE;
  const isJourneySupplier = supplierId === SUPPLIER_ID_JOURNEY;
  const isLoading = !shoppingListItems.length;
  const hasSearch = shoppingListItems.length >= 100;

  if (error) return <UnexpectedError tw="mt-[100px]" />;

  return (
    <ManufacturerStoreWrapper>
      <ExtraWideContainer tw="flex justify-between space-x-[14px]">
        <CatalogProvider
          value={{
            pharmacy,
            supplier,
            itemsByType,
            hasFoamItems,
            isFFFSupplier,
            isMayneSupplier,
            isJourneySupplier,
            addToCart,
            removeFromCart,
            updateItemQuantity,
          }}
        >
          <div tw="min-w-[766px] w-[60.2%]">
            {isLoading ? (
              <FullPageLoader />
            ) : hasSearch ? (
              <CatalogItemsWithSearch
                topSellingItems={topSellingItems}
                shoppingListItems={shoppingListItems}
                mostRecentPurchases={mostRecentPurchases}
              />
            ) : (
              <CatalogItemsWithCategories />
            )}
          </div>

          <CatalogCart
            cartItems={cartItems}
            shippingInfo={shippingInfo}
            submitOrder={submitOrder}
            removeLocalStorageCartCatalog={removeLocalStorageCartCatalog}
          />
        </CatalogProvider>

        <OrderNotSentToSupplierAdminWarning />
      </ExtraWideContainer>
    </ManufacturerStoreWrapper>
  );
}
