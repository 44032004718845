import React, { useContext } from "react";
import { styled } from "twin.macro";
import { Link } from "react-router-dom";
import AppContext from "../../contexts/AppContext";
import { FullWidthContainer } from "../containers/FullWidthContainer";
import { WideContainer } from "../containers/WideContainer";
import { PrintWrapper } from "../rxLibrary/print";
import { WHITE_1 } from "../rxLibrary/colors";
import { Box } from "../rxLibrary/box";
import { NavBarAccountMenu } from "./NavBarAccountMenu/NavBarAccountMenu";
import { NavBarUserName } from "./NavBarUserName";
import { NavBarLinks } from "./NavBarLinks";

const Header = PrintWrapper.withComponent("header");

const Nav = Box.withComponent("nav");

const Separator = styled.div`
  width: 1px;
  height: 100%;
  opacity: 0.6;
  margin: 0 8px;
  background-color: ${WHITE_1};
`;

export function NavBar({
  hideUserLinks = false,
  fullWidth = false,
}: {
  hideUserLinks?: boolean;
  fullWidth?: boolean;
}) {
  const { isDemoMode } = useContext(AppContext);
  const NavbarContainer = fullWidth ? FullWidthContainer : WideContainer;

  return (
    <Header tw="relative z-30">
      <Nav bgColor="blue-2">
        <NavbarContainer tw="flex items-center justify-between h-[96px]">
          <div tw="flex items-center flex-1 justify-start space-x-[15px]">
            {!hideUserLinks && <NavBarLinks />}
          </div>

          <div tw="mx-[98px]">
            {!isDemoMode && (
              <Link to="/">
                <img
                  src="/assets/components/daylight-rx-logo.svg"
                  alt="DaylightRx"
                  tw="h-[36px]"
                />
              </Link>
            )}
          </div>

          <div tw="flex-1 flex justify-end">
            <div tw="flex flex-1 justify-end max-w-[416px]">
              <NavBarUserName />
              <Separator />
              <NavBarAccountMenu />
            </div>
          </div>
        </NavbarContainer>
      </Nav>
    </Header>
  );
}
