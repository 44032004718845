import type {
  RequestClient,
  RequestClientConfig,
} from "./request/requestClient";
import type {
  AccountOptions,
  AdminAccount,
  CreateAdminAccount,
  UpsertAdminAccount,
} from "../utilities/types";

export async function getAdminAccounts(
  client: RequestClient,
  config: RequestClientConfig<Record<string, never>>
) {
  return client<{ accounts: AdminAccount[] }>({
    ...config,
    url: "/admin/account",
  });
}

export async function getAdminAccount(
  client: RequestClient,
  {
    id,
    ...config
  }: RequestClientConfig<{
    id: number | string;
  }>
) {
  return client<{ account: AdminAccount }>({
    ...config,
    url: `/admin/account/${id}`,
  });
}

export async function getAdminAccountOptions(
  client: RequestClient,
  config: RequestClientConfig<Record<string, never>>
) {
  return client<{ accountOptions: AccountOptions }>({
    ...config,
    url: "/admin/account/options",
  });
}

export async function createAdminAccount(
  client: RequestClient,
  {
    email,
    isAdmin,
    fullName,
    pharmacies,
    isTrialUser,
    businessRoles,
    sendWelcomeEmail,
    supportContextMenu,
    defaultBuyingPharmacyId,
    ...config
  }: RequestClientConfig<CreateAdminAccount>
) {
  return client<{ account: AdminAccount }>({
    ...config,
    method: "POST",
    url: "/admin/account",
    params: { sendWelcomeEmail },
    data: {
      email,
      isAdmin,
      fullName,
      pharmacies,
      isTrialUser,
      businessRoles,
      supportContextMenu,
      defaultBuyingPharmacyId,
    },
  });
}

export async function updateAdminAccount(
  client: RequestClient,
  {
    id,
    email,
    isAdmin,
    fullName,
    pharmacies,
    isTrialUser,
    businessRoles,
    supportContextMenu,
    defaultBuyingPharmacyId,
    ...config
  }: RequestClientConfig<UpsertAdminAccount>
) {
  return client<{ account: AdminAccount }>({
    ...config,
    method: "PUT",
    url: `/admin/account/${id}`,
    data: {
      email,
      isAdmin,
      fullName,
      pharmacies,
      isTrialUser,
      businessRoles,
      supportContextMenu,
      defaultBuyingPharmacyId,
    },
  });
}

export async function adminAccountSendResetPasswordEmail(
  client: RequestClient,
  {
    id,
    ...config
  }: RequestClientConfig<{
    id: number;
  }>
) {
  return client({
    ...config,
    method: "POST",
    url: `/admin/account/${id}/email/reset-password`,
  });
}

export async function adminAccountSendWelcomeEmail(
  client: RequestClient,
  {
    id,
    ...config
  }: RequestClientConfig<{
    id: number;
  }>
) {
  return client({
    ...config,
    method: "POST",
    url: `/admin/account/${id}/email/welcome`,
  });
}

export async function adminAccountHasAuthUser(
  client: RequestClient,
  {
    id,
    ...config
  }: RequestClientConfig<{
    id: number;
  }>
) {
  return client<{ hasAuthUser: boolean }>({
    ...config,
    url: `/admin/account/${id}/has-auth-user`,
  });
}
