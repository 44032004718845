import { useMemo } from "react";
import "twin.macro";
import p from "pluralize";
import { Text } from "../../../../../components/rxLibrary/typography";
import { useCatalog } from "../useCatalog";
import { CatalogItem } from "./CatalogItem/CatalogItem";
import { CatalogItemsSection } from "./CatalogItemsSection";

export function CatalogItemsSectionFeaturedItems() {
  const { itemsByType } = useCatalog();
  const { featuredItems } = itemsByType;

  const featuredItemsTitle = useMemo(() => {
    return `${p("Item", featuredItems.length)} on your shopping list`;
  }, [featuredItems.length]);

  if (!featuredItems.length) return null;
  return (
    <CatalogItemsSection>
      <Text tw="mb-[15px]" size={21} block>
        {featuredItemsTitle}
      </Text>

      {featuredItems.map((item) => (
        <CatalogItem key={item.ndc} item={item} />
      ))}
    </CatalogItemsSection>
  );
}
