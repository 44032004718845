import { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import type { SearchResult } from "../../../utilities/types";
import {
  IDrugSearchTableData,
  IDrugSearchValues,
} from "../DrugSearch.constants";
import { formatDrugTableData } from "./formatDrugTableData";

export function useFormattedDrugSearch({
  drugSearchResults,
  drugSearchValues,
}: {
  drugSearchResults: SearchResult[];
  drugSearchValues: IDrugSearchValues;
}) {
  const [formattedDrugSearch, setFormattedDrugSearch] = useState<{
    drugTableData: IDrugSearchTableData;
    drugSearchFilters?: {
      strengths: string[];
      forms: string[];
      administrations: string[];
    };
  }>({ drugTableData: [] });

  useEffect(() => {
    const {
      strength: strengthFilter = null,
      form: formFilter = null,
      administration: administrationFilter = null,
      brand: genericBrandFilter,
    } = drugSearchValues;

    const newStrengths = buildStrengthOptions(
      applyFilters(
        drugSearchResults,
        genericBrandFilter,
        null,
        formFilter,
        administrationFilter
      )
    );
    const newForms = uniqArr(
      applyFilters(
        drugSearchResults,
        genericBrandFilter,
        strengthFilter,
        null,
        administrationFilter
      ).map((v) => v.representativeDrugInfo.form)
    ).sort();

    const newAdministrations = uniqArr(
      applyFilters(
        drugSearchResults,
        genericBrandFilter,
        strengthFilter,
        formFilter,
        null
      ).map((v) => v.representativeDrugInfo.routeOfAdministration)
    ).sort();

    const drugsData = applyFilters(
      drugSearchResults,
      genericBrandFilter,
      strengthFilter,
      formFilter,
      administrationFilter
    );
    const newDrugTableData = formatDrugTableData(drugsData);

    if (isEmpty(newDrugTableData)) {
      setFormattedDrugSearch({ drugTableData: [] });
    } else {
      setFormattedDrugSearch({
        drugTableData: newDrugTableData,
        drugSearchFilters: {
          strengths: newStrengths,
          forms: newForms,
          administrations: newAdministrations,
        },
      });
    }
  }, [drugSearchResults, drugSearchValues]);

  return formattedDrugSearch;
}

function uniqArr(list: (string | null)[]): string[] {
  const stripped = list.map((n) => n || "N/A") as string[];
  const set = new Set(stripped);
  const result: string[] = [];
  set.forEach((s) => {
    result.push(s);
  });
  return result;
}

function buildStrengthOptions(searchResults: SearchResult[]): string[] {
  return uniqArr(
    searchResults
      .sort((a, b) => {
        if (!a.representativeDrugInfo || !a.representativeDrugInfo.strength) {
          if (!b.representativeDrugInfo || !b.representativeDrugInfo.strength) {
            return 0;
          }
          return 1;
        }
        if (!b.representativeDrugInfo || !b.representativeDrugInfo.strength) {
          return -1;
        }
        if (
          a.representativeDrugInfo.strengthUnit <
          b.representativeDrugInfo.strengthUnit
        ) {
          return -1;
        }
        if (
          a.representativeDrugInfo.strengthUnit >
          b.representativeDrugInfo.strengthUnit
        ) {
          return 1;
        }
        if (
          a.representativeDrugInfo.strength < b.representativeDrugInfo.strength
        ) {
          return -1;
        }
        if (
          a.representativeDrugInfo.strength > b.representativeDrugInfo.strength
        ) {
          return 1;
        }
        return 0;
      })
      .map((v) => {
        if (!v.representativeDrugInfo || !v.representativeDrugInfo.strength) {
          return "N/A";
        }
        return `${v.representativeDrugInfo.strength} ${
          v.representativeDrugInfo.strengthUnit || ""
        }`;
      })
  );
}

function applyFilters(
  results: SearchResult[],
  genericBrandFilter: string,
  strengthFilter: string | null,
  formFilter: string | null,
  administrationFilter: string | null
): SearchResult[] {
  return results.filter((r) => {
    if (genericBrandFilter === "g" && r.isBrand) return false;
    if (genericBrandFilter === "b" && !r.isBrand) return false;

    if (strengthFilter === "N/A" && r.representativeDrugInfo.strength)
      return false;
    if (
      strengthFilter !== null &&
      strengthFilter !== "N/A" &&
      strengthFilter !==
        `${r.representativeDrugInfo.strength} ${
          r.representativeDrugInfo.strengthUnit || ""
        }`
    )
      return false;

    if (formFilter === "N/A" && r.representativeDrugInfo.form) return false;
    if (
      formFilter !== null &&
      formFilter !== "N/A" &&
      formFilter !== r.representativeDrugInfo.form
    )
      return false;

    if (
      administrationFilter === "N/A" &&
      r.representativeDrugInfo.routeOfAdministration
    )
      return false;
    if (
      administrationFilter !== null &&
      administrationFilter !== "N/A" &&
      administrationFilter !== r.representativeDrugInfo.routeOfAdministration
    )
      return false;
    return true;
  });
}
