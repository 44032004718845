import React from "react";
import "twin.macro";
import dayjs from "dayjs";
import { DateRangePicker } from "../../components/rxLibrary/dateRangePicker";
import { useManufacturerReport } from "./useManufacturerReport/useManufacturerReport";

export function ManufacturerDateRangePicker() {
  const { reportDates, setReportDates } = useManufacturerReport();

  return (
    <DateRangePicker
      tw="w-[281px]"
      label="Dates:"
      minDate={dayjs("01/01/2024", "MM/DD/YYYY")}
      defaultValue={reportDates}
      presets={[
        {
          label: "Last 90 Days",
          value: [dayjs().subtract(91, "day"), dayjs().subtract(1, "day")],
        },
        {
          label: "Last 30 Days",
          value: [dayjs().subtract(31, "day"), dayjs().subtract(1, "day")],
        },
        {
          label: "Last 7 Days",
          value: [dayjs().subtract(8, "day"), dayjs().subtract(1, "day")],
        },
      ]}
      onChange={setReportDates}
    />
  );
}
