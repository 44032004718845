import React, { ReactNode } from "react";
import tw, { styled } from "twin.macro";
import { ORANGE_2 } from "./colors";
import { Icon, IconBell } from "./icons";
import { Text } from "./typography";

type WarningTypes = "info" | "error";

const Wrapper = tw.div`flex space-x-1`;

const ErrorWrapper = styled(Wrapper)`
  background-color: ${ORANGE_2}3D;
  padding: 7px 9px;
`;

function ErrorWarning({ Icon, children }: { Icon: Icon; children: ReactNode }) {
  return (
    <ErrorWrapper>
      <div>
        <Icon color="red-1" />
      </div>

      <Text block>{children}</Text>
    </ErrorWrapper>
  );
}

function InfoWarning({ Icon, children }: { Icon: Icon; children: ReactNode }) {
  return (
    <Wrapper>
      <div>
        <Icon color="green-5" />
      </div>

      <Text block color="green-4">
        {children}
      </Text>
    </Wrapper>
  );
}

export function Warning({
  type = "info",
  Icon = IconBell,
  children,
}: {
  type?: WarningTypes;
  Icon?: Icon;
  children?: ReactNode;
}) {
  if (!children) return null;

  const Msg = type === "error" ? ErrorWarning : InfoWarning;
  return <Msg Icon={Icon}>{children}</Msg>;
}
