import React, { useMemo } from "react";
import "twin.macro";
import {
  DrugInfo,
  DrugInfoReference
} from "../../../../../../../utilities/types";
import { Warning } from "../../../../../../../components/rxLibrary/warnings";

export function ShippingMinimumWarning({
  drug,
  drugsWithAvailabilityLimitations,
}: {
  drug: DrugInfo;
  drugsWithAvailabilityLimitations?: DrugInfoReference[];
}) {
  const displayWarning = useMemo(() => {
    return !!drugsWithAvailabilityLimitations?.some((d) => {
      return d.referenceData.drugInfo.ndc === drug.ndc;
    });
  }, [drug, drugsWithAvailabilityLimitations]);

  if (!displayWarning) return null;
  return (
    <Warning>Item is only available at suppliers w/ shipping minimum</Warning>
  );
}
