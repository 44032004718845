import { ColDef, ColGroupDef } from "ag-grid-community";
import {
  currencyFormatter,
  numberFormatter,
  percentFormatter,
} from "../../utils/performanceReportFormatters";

export const COLUMN_DEFS: Array<ColDef | ColGroupDef> = [
  { field: "type", headerName: "Type", pinned: "left", width: 150 },
  { field: "drug", headerName: "Drug", pinned: "left", flex: 1, minWidth: 200 },
  {
    field: "transactions",
    headerName: "Transactions",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 140,
  },
  {
    field: "previousTransactions",
    headerName: "Previous Transactions",
    valueFormatter: (params) => numberFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 140,
  },
  {
    field: "changeInTransactions",
    headerName: "Change in Tnxs",
    valueFormatter: (params) => numberFormatter(params.value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "percentChangeInTransactions",
    headerName: "% Change in Tnxs",
    valueFormatter: (params) => percentFormatter(params.value, 1),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "totalPricePaid",
    headerName: "Total Price Paid",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 130,
  },
  {
    field: "previousTotalPricePaid",
    headerName: "Previous Total Price Paid",
    valueFormatter: (params) => currencyFormatter(params.value),
    hide: true,
    flex: 1,
    minWidth: 130,
  },
  {
    field: "changeInTotalPricePaid",
    headerName: "Change in Total Price Paid",
    valueFormatter: (params) => currencyFormatter(params.value),
    flex: 1,
    minWidth: 125,
  },
  {
    field: "percentChangeInTotalPricePaid",
    headerName: "% Change in Total Price Paid",
    valueFormatter: (params) => percentFormatter(params.value, 1),
    flex: 1,
    minWidth: 125,
  },
];
