function getShippingMethodType(shoppingMethod?: string | null) {
  if (!shoppingMethod) return;
  const newShoppingMethod = shoppingMethod.toLocaleLowerCase();
  if (newShoppingMethod.includes("ups")) return "UPS";
  if (newShoppingMethod.includes("fedex")) return "FEDEX";
}

export function getShippingTrackingUrl({
  shippingMethod,
  trackingNumber,
}: {
  shippingMethod?: string | null;
  trackingNumber?: string | null;
}) {
  if (!trackingNumber) return;

  const shippingMethodType = getShippingMethodType(shippingMethod);
  switch (shippingMethodType) {
    case "UPS":
      return `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/trackdetails`;
    case "FEDEX":
      return `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`;
  }
}
