import { useCallback } from "react";
import type { ItemInModal } from "../../../../utilities/types";
import { ShoppingRxPrescriptionItem } from "./components/ShoppingRxPrescriptionItem";

export function ShoppingRxModalPrescriptionItem({
  item,
  removeModalItem,
  updateModalItem,
}: {
  item: ItemInModal;
  removeModalItem: (rxNumber: string) => void;
  updateModalItem: (newItem: ItemInModal) => void;
}) {
  const removeItem = useCallback(() => {
    removeModalItem(item.rxNumber);
  }, [item.rxNumber, removeModalItem]);

  const updateItemPackSize = useCallback(
    (value: boolean) => {
      const newItem: ItemInModal = { ...item, packSize: !!value };
      updateModalItem(newItem);
    },
    [item, updateModalItem]
  );

  const updateItemManufacturer = useCallback(
    (value: boolean) => {
      const newItem: ItemInModal = { ...item, manufactutrer: !!value };
      updateModalItem(newItem);
    },
    [item, updateModalItem]
  );

  return (
    <ShoppingRxPrescriptionItem
      item={item}
      removeItem={removeItem}
      updateItemPackSize={updateItemPackSize}
      updateItemManufacturer={updateItemManufacturer}
    />
  );
}
