import snakeCase from "lodash/snakeCase";

export function snakeCaseObjectKeys(
  obj?: Record<string, any>
): undefined | Record<string, any> {
  if (!obj) return obj;

  return Object.keys(obj).reduce<Record<string, any>>((acc, key) => {
    const newKey = snakeCase(key);
    acc[newKey] = obj[key];
    return acc;
  }, {});
}
