import React from "react";
import tw from "twin.macro";
import type { Pharmacy, Supplier } from "../../../utilities/types";
import { Text, Title } from "../../../components/rxLibrary/typography";
import { ExtraWideContainer } from "../../../components/containers/ExtraWideContainer";
import { EmailLink } from "../../../components/rxLibrary/Link";

const AccountSettingsRow = tw.div`flex space-x-4 py-8 border-t-2`;

const AccountSettingsCol = tw.div`flex-1 space-y-6`;

function AccountSettingsItem({
  title,
  value,
  wrapWithPre,
}: {
  title: string;
  value?: JSX.Element | string;
  wrapWithPre?: boolean;
}) {
  return (
    <div>
      <Text block weight="bold" tw="mb-1">
        {title}
      </Text>

      {!!value &&
        (wrapWithPre ? (
          <pre>
            <Text block>{value}</Text>
          </pre>
        ) : (
          <Text block>{value}</Text>
        ))}
    </div>
  );
}

export function AccountSettings({
  supplier,
  pharmacy,
}: {
  supplier: Supplier;
  pharmacy: Pharmacy;
}) {
  const {
    logisticsProvider,
    customerAccountId,
    customerServiceEmail,
    pharmacyBillingAddressFormatted,
    pharmacyShippingAddressFormatted,
  } = supplier;
  const { name: pharmacyName } = pharmacy;

  const isSameAsBillingAddress =
    pharmacyBillingAddressFormatted === pharmacyShippingAddressFormatted;

  const shippingAddress = isSameAsBillingAddress
    ? "Same as Billing Address"
    : pharmacyShippingAddressFormatted;

  return (
    <ExtraWideContainer tw="bg-white px-24 pt-[40px] pb-[98px]">
      <div tw="pb-[40px]">
        <Title>Account Settings - {pharmacyName}</Title>
      </div>

      <AccountSettingsRow>
        <AccountSettingsCol>
          <AccountSettingsItem
            wrapWithPre
            title="BILLING ADDRESS"
            value={pharmacyBillingAddressFormatted}
          />
        </AccountSettingsCol>

        <AccountSettingsCol>
          <AccountSettingsItem
            title="SHIPPING ADDRESS"
            value={shippingAddress}
            wrapWithPre={!isSameAsBillingAddress}
          />
        </AccountSettingsCol>

        <AccountSettingsCol>
          <AccountSettingsItem
            title="E-COMMERCE & INVOICE CUSTOMER SERVICE"
            value={<EmailLink email="ecommerce-support@daylightrx.com" />}
          />
        </AccountSettingsCol>
      </AccountSettingsRow>

      <AccountSettingsRow>
        <AccountSettingsCol>
          <AccountSettingsItem
            title="DISTRIBUTOR (3PL)"
            value={logisticsProvider}
          />
        </AccountSettingsCol>

        <AccountSettingsCol>
          <AccountSettingsItem
            title="3PL CUSTOMER SERVICE"
            value={<EmailLink email={customerServiceEmail} />}
          />
        </AccountSettingsCol>

        <AccountSettingsCol>
          <AccountSettingsItem
            title="ACCOUNT ID"
            value={customerAccountId || ""}
          />
        </AccountSettingsCol>
      </AccountSettingsRow>
    </ExtraWideContainer>
  );
}
