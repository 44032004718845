import "twin.macro";
import React, { ReactNode } from "react";
import { Breadcrumb, BreadcrumbProps } from "antd";
import { Link } from "react-router-dom";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
import { WideContainer } from "../../../../../components/containers/WideContainer";

export function AdminManagementHeader({
  children,
  breadcrumbs = [],
}: {
  children?: ReactNode;
  breadcrumbs: BreadcrumbProps["items"];
}) {
  return (
    <WideContainer tw="flex justify-between mb-6">
      <Breadcrumb
        items={[
          {
            title: (
              <Link to="/">
                <HomeOutlined rev={undefined} />
              </Link>
            ),
          },
          { title: <Link to="/admin">Admin</Link> },
          ...breadcrumbs,
        ]}
      />

      <div tw="flex items-center space-x-2">{children}</div>
    </WideContainer>
  );
}
