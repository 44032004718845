import React, { useMemo } from "react";
import "twin.macro";
import { Button, Form, Modal, Table, Tooltip } from "antd";
import TableOutlined from "@ant-design/icons/TableOutlined";
import { getSupplierMappingIsaCodesByPharmacyId } from "../../../../../../../services/supplierMappings";
import { IconButton } from "../../../../../../../components/rxLibrary/buttons";
import { useModal } from "../../../../../../../components/rxLibrary/modals";
import { useRequest } from "../../../../../../../services/request/useRequest";
import { Title } from "../../../../../../../components/rxLibrary/typography";

function AdminSupplierMappingFormPharmacyIsaCodeLabelModal({
  pharmacyId,
  pharmacyName,
  closeModal,
}: {
  pharmacyId: number;
  pharmacyName?: string;
  closeModal: () => void;
}) {
  const form = Form.useFormInstance();
  const config = useMemo(() => ({ pharmacyId }), [pharmacyId]);

  const { data, error, isLoading } = useRequest({
    config,
    dataKey: "supplierMappingIsaCodesByPharmacyId",
    request: getSupplierMappingIsaCodesByPharmacyId,
  });

  return (
    <Modal open onCancel={closeModal} footer={false} width={820}>
      <Title tw="mb-2" block>
        ISA Codes for {pharmacyName ?? "pharmacy"}
      </Title>

      {error ? (
        "Failed to get ISA codes data"
      ) : (
        <Table
          sticky
          pagination={false}
          loading={isLoading}
          dataSource={data}
          scroll={{ y: 600 }}
          columns={[
            { title: "Supplier", dataIndex: "supplierName", width: 160 },
            {
              title: "Customer Account ID",
              dataIndex: "customerAccountId",
              width: 160,
            },
            {
              title: "Supplier ISA",
              dataIndex: "supplierIsaCode",
              width: 160,
            },
            {
              title: "Pharmacy ISA",
              dataIndex: "pharmacyIsaCode",
              width: 250,
              render: function render(pharmacyIsaCode) {
                if (!pharmacyIsaCode) return null;
                return (
                  <div tw="flex items-center justify-between space-x-1">
                    <span>{pharmacyIsaCode}</span>

                    <Button
                      onClick={() => {
                        form.setFieldValue("pharmacyIsaCode", pharmacyIsaCode);
                        closeModal();
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                );
              },
            },
          ]}
        />
      )}
    </Modal>
  );
}

export function AdminSupplierMappingFormPharmacyIsaCodeLabel({
  pharmacyId,
  pharmacyName,
}: {
  pharmacyId?: number;
  pharmacyName?: string;
}) {
  const { isModalOpen, openModal, closeModal } = useModal();

  const tooltipTitle = useMemo(() => {
    if (!pharmacyName) {
      return "Select a pharmacy to enable preview of pharmacy ISA codes of the same pharmacy";
    }
    return `Open pharmacy ISA codes for ${pharmacyName}`;
  }, [pharmacyName]);

  return (
    <>
      <div tw="flex space-x-1 items-center">
        <span>Pharmacy ISA Code</span>

        <div>
          <Tooltip title={tooltipTitle}>
            <IconButton disabled={!pharmacyId} onClick={openModal}>
              <TableOutlined rev={undefined} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {pharmacyId && isModalOpen && (
        <AdminSupplierMappingFormPharmacyIsaCodeLabelModal
          pharmacyId={pharmacyId}
          pharmacyName={pharmacyName}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
