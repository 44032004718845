import React from "react";
import { Route, Switch } from "react-router-dom";
import { RedirectHomePage } from "../../../../../components/RedirectHomePage";
import { AdminPharmacyOrganizations } from "./AdminPharmacyOrganizations";
import { CreateAdminPharmacyOrganization } from "./CreateAdminPharmacyOrganization";
import { EditAdminPharmacyOrganization } from "./EditAdminPharmacyOrganization";

const PATH = "/admin/pharmacy-organization";
const PATH_UPDATE = `${PATH}/:id`;
const PATH_CREATE = `${PATH}/new`;

export function PharmacyOrganizationsRoutes() {
  return (
    <Switch>
      <Route path={PATH} component={AdminPharmacyOrganizations} exact />
      <Route
        path={PATH_CREATE}
        component={CreateAdminPharmacyOrganization}
        exact
      />
      <Route
        path={PATH_UPDATE}
        component={EditAdminPharmacyOrganization}
        exact
      />
      <Route component={RedirectHomePage} />
    </Switch>
  );
}
