import React, { ComponentProps, HTMLAttributes } from "react";
import tw, { css, styled } from "twin.macro";
import { Col as AntdCol, Row as AntdRow } from "antd";
import { GREY_2, GREY_5, WHITE_1 } from "./colors";

const RowWrapper = styled.div(({ header }: { header?: boolean }) => [
  css`
    background-color: ${WHITE_1};
  `,
  header
    ? css`
        padding: 11px 0;
        margin-bottom: 3px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      `
    : css`
        padding: 14px 0;
        margin-bottom: 8px;
        border-radius: 3px;
        box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.08);
      `,
]);

const ColContent = styled.div(({ noBorder }: { noBorder?: boolean }) => [
  css`
    height: 100%;
    padding: 4px 10px;
  `,
  !noBorder &&
    css`
      border-right: solid 1px ${GREY_2};
    `,
]);

const RowStyled = styled(AntdRow)`
  > .ant-col:last-child > div {
    border-right-color: transparent;
  }
`;

export function Row({
  children,
  rowProps,
  ...props
}: {
  header?: boolean;
  rowProps?: Omit<ComponentProps<typeof AntdRow>, "children">;
} & HTMLAttributes<HTMLDivElement>) {
  return (
    <RowWrapper {...props}>
      <RowStyled {...rowProps}>{children}</RowStyled>
    </RowWrapper>
  );
}

export function Col({
  children,
  noBorder,
  ...props
}: ComponentProps<typeof AntdCol> & { noBorder?: boolean }) {
  return (
    <AntdCol {...props}>
      <ColContent noBorder={noBorder}>{children}</ColContent>
    </AntdCol>
  );
}

export const InnerRow = styled(AntdRow)(() => [
  tw`rounded py-[11px] my-[-11px] mr-[4px]`,
  css`
    background-color: ${GREY_5};

    > .ant-col:last-child > div {
      border-right: 0;
    }
  `,
]);
