import dayjs from "dayjs";

export function formatDateStrToNumericDate(dateStr?: string): string | void {
  const date = !!dateStr && new Date(Date.parse(dateStr));

  if (date && dayjs(date).isValid()) {
    const formattedDate = date
      .toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .join("-");

    return formattedDate;
  }
}
