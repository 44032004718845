import React from "react";
import dayjs from "dayjs";
import { openChat } from "../../../../utilities/chat/openChat";
import {
  currencyFormatter,
  formatOrUndefined,
  numberFormatter,
} from "../utils/performanceReportFormatters";
import { DATE_WITH_YEAR } from "../../../../utilities/dates/dates.constants";
import { MissedOpportunitiesDataType } from "./MissedOpportunities.types";
import { BigNumber } from "../BigNumber";
import { CostInefficientSupplierPurchasesChart } from "./CostInefficientSupplierPurchasesChart";
import { MissedOpportunityTable } from "./MissedOpportunityTable/MissedOpportunityTable";
import { PackSizeChangeBarChart } from "./PackSizeChangeBarChart";
import { NDCSubstitutionBarChart } from "./NDCSubstitutionBarChart";
import { InformativeText } from "./InformativeText";
import { SupplierIncreaseBarChart } from "./SupplierIncreaseBarChart";
import { SupplierDecreaseBarChart } from "./SupplierDecreaseBarChart";
import { OpportunityInvoiceDateBarChart } from "./OpportunityInvoiceDateBarChart";

function getSummaryTexts({ totalSavings }: { totalSavings: number }) {
  if (totalSavings > 0) {
    return (
      <span>
        Double check the table below to see your purchases vs. our suggestions.
        Purchasing is complicated.{" "}
        <a onClick={openChat} tw="font-bold cursor-pointer text-[#324DC7]">
          Set up a free coaching session.
        </a>
      </span>
    );
  }

  return <span>Your orders in this time period were cost efficient.</span>;
}

function getSavingMoneyText({
  invoiceDate,
  savingTotal,
}: {
  invoiceDate?: string;
  savingTotal?: number;
}) {
  let savingTotalMessage = "";
  if (invoiceDate) {
    savingTotalMessage = `On ${dayjs(invoiceDate).format(
      DATE_WITH_YEAR
    )} you missed the opportunity to save ${currencyFormatter(savingTotal)}.`;
  }

  return `${savingTotalMessage} See the chart below for a day-by-day look at your purchasing cost efficiency.`;
}

export function MissedOpportunities({
  data,
}: {
  data?: MissedOpportunitiesDataType;
}) {
  if (!data) return;

  const {
    missedOpportunityTotalSavings: { data: missedOpportunityTotalSavingsData },
    missedOpportunityCostInefficientNdCs: {
      data: missedOpportunityCostInefficientNdCsData,
    },
    missedOpportunityCostInefficientSupplierPurchases: {
      data: missedOpportunityCostInefficientSupplierPurchasesData,
    },
    missedOpportunityTable: { data: missedOpportunityTableData },
    missedOpportunityNdcSubst: { data: missedOpportunityNdcSubstData },
    missedOpportunityPackSizeChange: {
      data: missedOpportunityPackSizeChangeData,
    },
    missedOpportunitySupplierIncrease: {
      data: missedOpportunitySupplierIncreaseData,
    },
    missedOpportunitySupplierDecrease: {
      data: missedOpportunitySupplierDecreaseData,
    },
    missedOpportunityInvoiceDate: { data: missedOpportunityInvoiceDateData },
    missedOpportunityMaxInvoice: { data: missedOpportunityMaxInvoiceData },
  } = data;

  const totalSavings = missedOpportunityTotalSavingsData?.total ?? 0;
  const formattedTotalSavings = formatOrUndefined(
    missedOpportunityTotalSavingsData?.total,
    currencyFormatter
  );
  const formattedCostInefficientNdCs = formatOrUndefined(
    missedOpportunityCostInefficientNdCsData?.count,
    numberFormatter
  );

  return (
    <div tw="pb-[15px]">
      <div tw="grid grid-cols-3 gap-[15px]">
        <div tw="flex flex-col">
          <BigNumber
            value={formattedTotalSavings}
            title="Estimated Total Savings after Purchasing More Cost Efficient Options"
            variant="small"
            textPosition="bottom"
          />
          <BigNumber
            value={formattedCostInefficientNdCs}
            title="Total Number of NDCs with More Cost Efficient Options at Time of Purchase"
            variant="small"
            textPosition="bottom"
          />
        </div>
        <div
          tw="mt-[15px] col-span-2"
          id="costInefficientSupplierPurchasesChart"
        >
          <CostInefficientSupplierPurchasesChart
            data={missedOpportunityCostInefficientSupplierPurchasesData}
          />
        </div>
      </div>
      <InformativeText>{getSummaryTexts({ totalSavings })}</InformativeText>
      {totalSavings > 0 && (
        <>
          <div tw="flex-1 h-[500px] mt-4 bg-white">
            <MissedOpportunityTable data={missedOpportunityTableData} />
          </div>
          <InformativeText>
            Because different suppliers carry varying pack sizes and may require
            common drug substitutions, the charts below highlight the necessary
            alterations to your orders to achieve the maximum estimated total
            savings in this report.
          </InformativeText>
          <div tw="flex-1 mt-4 bg-white">
            <PackSizeChangeBarChart
              data={missedOpportunityPackSizeChangeData}
            />
          </div>
          <div tw="flex-1 mt-4 bg-white">
            <NDCSubstitutionBarChart data={missedOpportunityNdcSubstData} />
          </div>
          <InformativeText>
            If you were to have used our &quot;Daily Purchase&quot;
            optimization, your total spending per supplier would increase or
            decrease to maximize your estimated total savings. Here&apos;s a
            look into the details.
          </InformativeText>
          <div tw="grid grid-cols-2 gap-[15px] mt-4 h-[500px]">
            <SupplierIncreaseBarChart
              data={missedOpportunitySupplierIncreaseData}
            />
            <SupplierDecreaseBarChart
              data={missedOpportunitySupplierDecreaseData}
            />
          </div>
          {missedOpportunityMaxInvoiceData && (
            <InformativeText>
              {getSavingMoneyText({
                invoiceDate: missedOpportunityMaxInvoiceData[0]?.invoiceDate,
                savingTotal: missedOpportunityMaxInvoiceData[0]?.total,
              })}
            </InformativeText>
          )}
          <div tw="flex-1 mt-4 bg-white">
            <OpportunityInvoiceDateBarChart
              data={missedOpportunityInvoiceDateData}
            />
          </div>
        </>
      )}
    </div>
  );
}
