export function dispatch(
  msgType:
    | "requestSupplierAccountDetails"
    | "requestSaveSupplierCredentials"
    | "requestDeleteSupplierCredentials",
  payload: any
) {
  console.log("Sending message", msgType, payload);
  window.postMessage({
    channel: "daylightrxenhanced",
    msgType,
    sender: "web",
    payload,
  });
}
