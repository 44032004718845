import React from "react";
import "twin.macro";
import { Link } from "react-router-dom";
import { Form, Input, Popconfirm } from "antd";
import {
  AdminPharmacyOrganization,
  UpsertAdminPharmacyOrganization,
} from "../../../../../utilities/types";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { Text } from "../../../../../components/rxLibrary/typography";
import {
  OnSubmit,
  useHandleSubmit,
} from "../../../../../components/antd/form/useHandleSubmit";
import { FormSkeleton } from "../../../../../components/antd/form/FormSkeleton";
import { AdminManagementHeader } from "../components/AdminManagementHeader";

export function AdminPharmacyOrganizationForm({
  pharmacies,
  isUpdateForm,
  initialValues,
  onSubmit,
}: {
  pharmacies?: AdminPharmacyOrganization["pharmacies"];
  isUpdateForm?: boolean;
  initialValues?: Partial<UpsertAdminPharmacyOrganization>;
  onSubmit: OnSubmit<UpsertAdminPharmacyOrganization>;
}) {
  const { form, isSubmitting, handleSubmit } = useHandleSubmit({
    onSubmit,
  });

  const title = isUpdateForm
    ? `Edit Pharmacy Organization ${initialValues?.name}`
    : "Create Pharmacy Organization";
  const submitBtn = isUpdateForm
    ? "Update Pharmacy Organization"
    : "Create Pharmacy Organization";

  const loading = isUpdateForm ? !initialValues || isSubmitting : false;
  if (loading) {
    return <FormSkeleton text="Loading Pharmacy Organization" />;
  }

  return (
    <>
      <AdminManagementHeader
        breadcrumbs={[
          {
            title: (
              <Link to="/admin/pharmacy-organization">
                Pharmacy Organizations
              </Link>
            ),
          },
          { title },
        ]}
      />

      <Form
        form={form}
        autoComplete="off"
        layout="vertical"
        initialValues={initialValues}
        onFinish={handleSubmit}
        disabled={isSubmitting}
        scrollToFirstError
      >
        <div tw="grid grid-cols-2 gap-x-8">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          {!!pharmacies && (
            <div>
              <Text tw="mb-2" block>
                Pharmacies
              </Text>

              <div tw="space-x-2">
                {pharmacies.map((pharmacy) => (
                  <Link
                    tw="underline"
                    to={`/admin/pharmacy/${pharmacy.id}`}
                    key={pharmacy.id}
                  >
                    {pharmacy.name}
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>

        <Popconfirm title="Sure to continue?" onConfirm={form.submit}>
          <Button disabled={isSubmitting}>{submitBtn}</Button>
        </Popconfirm>
      </Form>
    </>
  );
}
