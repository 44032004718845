import React, { useMemo } from "react";
import "twin.macro";
import { useRequest } from "../../../../../../../services/request/useRequest";
import { adminAccountHasAuthUser } from "../../../../../../../services/accounts";
import { Text } from "../../../../../../../components/rxLibrary/typography";
import { Box } from "../../../../../../../components/rxLibrary/box";
import { RxColor } from "../../../../../../../components/rxLibrary/colors";
import { SendWelcomeEmailButton } from "../../SendWelcomeEmailButton";
import { SendResetPasswordEmailButton } from "./SendResetPasswordEmailButton";

enum AuthUserStatusEnum {
  Yes = "yes",
  No = "no",
  Error = "error",
}

function useAuthUserStatus(userId?: number) {
  const config = useMemo(() => {
    if (userId) return { id: userId };
  }, [userId]);

  const { data: hasAuthUser, error } = useRequest({
    dataKey: "hasAuthUser",
    start: !!config,
    config,
    request: adminAccountHasAuthUser,
  });

  if (error) return AuthUserStatusEnum.Error;
  if (hasAuthUser === undefined) return;

  const result = hasAuthUser ? AuthUserStatusEnum.Yes : AuthUserStatusEnum.No;
  return result;
}

function Status({ text, bgColor }: { text: string; bgColor: RxColor }) {
  return (
    <Text tw="flex items-center space-x-1">
      <Box tw="rounded-full h-[10px] w-[10px]" bgColor={bgColor} />
      <span>{text}</span>
    </Text>
  );
}

function AuthUserStatus({ status }: { status: AuthUserStatusEnum }) {
  if (status === AuthUserStatusEnum.Yes) return null;

  if (status === AuthUserStatusEnum.No) {
    return <Status text="No Auth0 User" bgColor="grey-1" />;
  }

  return <Status text="Error In Auth0 User" bgColor="red-1" />;
}

export function AuthUserActions({ userId }: { userId: number }) {
  const authUserStatus = useAuthUserStatus(userId);

  if (!authUserStatus) return null;
  return (
    <>
      <AuthUserStatus status={authUserStatus} />
      <SendResetPasswordEmailButton userId={userId} />
      <SendWelcomeEmailButton userId={userId} />
    </>
  );
}
