import { v4 as uuidv4 } from "uuid";

type Environment = "development" | "staging" | "production";

const PRODUCTION_ORIGIN_URLS = [
  "https://streamlinerx.netlify.app",
  "https://app.daylightrx.com",
];

export const ENVIRONMENT: Environment =
  process.env.NODE_ENV === "development"
    ? "development"
    : PRODUCTION_ORIGIN_URLS.includes(window.location.origin)
    ? "production"
    : "staging";

export const IS_DEVELOPMENT = ENVIRONMENT === "development";
export const IS_PRODUCTION = ENVIRONMENT === "production";

export const IS_DEV_MODE = process.env.REACT_APP_DEV_MODE === "1";

export const DOWNLOAD_APP_INSTALLER_URL =
  "https://daylightrx-app-updates-cwekdubhioqloph.s3.amazonaws.com/DaylightRx+Setup+Latest.exe";

// Supplier Ids
export const SUPPLIER_ID_FFF = 22;
export const SUPPLIER_ID_JOURNEY = 31;
export const SUPPLIER_ID_MAYNE = 35;

export const MAYNE_MIN_FOAM_ITEMS_QTY = 6;

// Pharmacy Ids
export const TEST_PHARMACY_ID = 7;

// Prime Rx Pharmacy Ids
export const LOFT_CHEMIST_PHARMACY_ID = 184;
export const FRANKS_SPECIALTY_PHARMACY_ID = 67;

export const IS_ELECTRON = navigator.userAgent.indexOf("daylightrx") !== -1;

export const X_CLIENT_SESSION_ID = uuidv4();
