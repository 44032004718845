import "twin.macro";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Select, Tooltip } from "antd";
import { Button } from "../../../../../components/rxLibrary/buttons";
import { AdminManagementHeader } from "../components/AdminManagementHeader";
import { IconPlus } from "../../../../../components/rxLibrary/icons";
import { Text, Title } from "../../../../../components/rxLibrary/typography";
import { useSupplierMappingAndEdi } from "./useSupplierMappingAndEdi";
import { useListTable } from "../../../../../components/antd/table/useListTable";
import { getAdminSupplierMappingAndEdiBySupplierId } from "../../../../../services/supplierMappingAndEdi";
import { AdminManagementTable } from "../components/AdminManagementTable";
import { TableColumns } from "../../../../../components/antd/table/table.constants";
import { getNumberSorter } from "../../../../../components/antd/table/getNumberSorter";
import { getTextSorter } from "../../../../../components/antd/table/getTextSorter";
import { getBooleanFilter } from "../../../../../components/antd/table/getBooleanFilter";
import { renderBoolean } from "../../../../../components/antd/table/renderBoolean";
import { useAdminSupplierMappingAndEditOptions } from "./useAdminSupplierMappingAndEditOptions";
import { getColumnSearchProps } from "../../../../../components/antd/table/getColumnSearchProps";

type SupplierMappingEDI = {
  supplierId: number;
  supplierName: string;
  pharmacyId: number;
  pharmacyName: string;
  accountId: string;
  billToPharmacyId: number;
  billToPharmacyName: string;
  pharmacyIsaCode: string;
  supplierMappingStatus: true;
};

const Label = Text.withComponent("label");

const BREADCRUMBS = [{ title: "Supplier Mapping & EDI" }];

const COLUMNS: TableColumns<SupplierMappingEDI> = [
  {
    dataIndex: "supplierId",
    title: "SupplierId ID",
    width: 100,
    sorter: getNumberSorter("supplierId"),
  },
  {
    dataIndex: "supplierName",
    title: "Supplier",
    width: 100,
    sorter: getTextSorter("supplierName"),
  },
  {
    dataIndex: "pharmacyId",
    title: "Pharmacy ID",
    width: 100,
    sorter: getNumberSorter("pharmacyId"),
  },
  {
    dataIndex: "pharmacyName",
    title: "Pharmacy",
    width: 100,
    sorter: getTextSorter("pharmacyName"),
    ...getColumnSearchProps("pharmacyName"),
  },
  {
    dataIndex: "accountId",
    title: "Customer Account ID",
    width: 140,
    sorter: getTextSorter("accountId"),
  },
  {
    dataIndex: "billToPharmacyId",
    title: "Bill To Pharmacy ID",
    width: 120,
  },
  {
    dataIndex: "billToPharmacyName",
    title: "Bill To Pharmacy Name",
    width: 130,
  },
  {
    dataIndex: "pharmacyIsaCode",
    title: "Pharmacy ISA Code",
    width: 120,
  },
  {
    dataIndex: "supplierMappingStatus",
    title: "Supplier Mapping Status",
    width: 150,
    onFilter: getBooleanFilter("supplierMappingStatus"),
    sorter: getNumberSorter("supplierMappingStatus"),
    render: renderBoolean,
  },
];

export function AdminSupplierMappingAndEdi() {
  const history = useHistory();
  const [dataSource, setDataSource] = useState<SupplierMappingEDI[]>();
  const { supplierOptions } = useAdminSupplierMappingAndEditOptions();
  const { supplierId, supplierMappingAndEdiPath } = useSupplierMappingAndEdi();

  const fetchList = useCallback(
    async (token: string) => {
      if (!supplierId) return { data: { supplierMappingAndEdi: [] } };
      return await getAdminSupplierMappingAndEdiBySupplierId(token, supplierId);
    },
    [supplierId]
  );

  const { list, isLoading } = useListTable({
    name: "supplierMappingAndEdi",
    fetchList,
  });

  const onSupplierChange = useCallback((newSupplierId) => {
    history.push(`/admin/supplier-mapping-and-edi/${newSupplierId}`);
  }, []);

  useEffect(() => {
    if (!list?.length) {
      setDataSource(undefined);
      return;
    }

    const data = list.reduce<SupplierMappingEDI[]>((acc, v) => {
      const { items, supplierId } = v;
      const newItems = items.map((item) => ({ supplierId, ...item }));
      return [...acc, ...newItems];
    }, []);

    setDataSource(data);
  }, [list]);

  return (
    <>
      <AdminManagementHeader breadcrumbs={BREADCRUMBS}>
        {!!supplierId && (
          <div tw="flex items-center space-x-2">
            <Label htmlFor="supplier">Supplier</Label>
            <Select
              tw="w-[300px]"
              id="supplier"
              onChange={onSupplierChange}
              options={supplierOptions}
              value={supplierId}
              optionFilterProp="label"
              placeholder="Select supplier"
              showSearch
            />
          </div>
        )}

        <Tooltip title={!supplierId && "Select a supplier to enable creation"}>
          <Link to={`${supplierMappingAndEdiPath}/new`}>
            <Button icon={<IconPlus />} disabled={!supplierId}>
              Add Supplier Mapping & EDI
            </Button>
          </Link>
        </Tooltip>
      </AdminManagementHeader>

      {supplierId ? (
        <AdminManagementTable
          columns={COLUMNS}
          dataSource={dataSource}
          loading={isLoading}
        />
      ) : (
        <div tw="flex flex-col items-center">
          <Title tw="mb-4" block>
            Select Supplier
          </Title>

          <Select
            tw="w-[300px]"
            id="supplier"
            onChange={onSupplierChange}
            options={supplierOptions}
            optionFilterProp="label"
            placeholder="Select supplier"
            showSearch
          />
        </div>
      )}
    </>
  );
}
