import { ensureArray } from "./ensureArray";

/**
 * @param arr 
 * @param value 
 * @param start - same behavior as `start` of js `splice` https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/splice
 * @returns 
 */
export function insertArray<T>(arr: T[], value: T | T[], start = -1): T[] {
  const newArr = [...arr];
  const newItems = ensureArray(value);
  newArr.splice(start, 0, ...newItems);
  return newArr;
}
