import React from "react";
import tw, { styled, css } from "twin.macro";

export const ShoppingListTableHeaderSticky = styled.div(
  ({ offsetTop }: { offsetTop: number }) => [
    tw`sticky top-[168px] z-10`,
    css`
      top: ${offsetTop}px;
    `,
  ]
);
