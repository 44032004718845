import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import omitBy from "lodash/omitBy";
import isEmpty from "lodash/isEmpty";
import { useSearchParams } from "../../../utilities/url";
import {
  DEFAULT_DRUG_SEARCH_BRAND_VALUE,
  DRUG_SEARCH_QUERY_PARAMS_KEYS,
  IDrugSearchQueryParams,
  IDrugSearchValues,
} from "../DrugSearch.constants";

/**
 * Format the drug search query params in the query params format:
 * - remove empty values
 * - remove invalid keys
 * - remove the default brand value if brand is the default value so it isn't display in the url
 */
function formatDrugSearchQueryParams(values: IDrugSearchQueryParams) {
  const drugSearchValues: IDrugSearchQueryParams = omitBy(
    values,
    (value, key) => {
      if (isEmpty(value)) {
        return true;
      }
      if (key === "brand" && value === DEFAULT_DRUG_SEARCH_BRAND_VALUE) {
        return true;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!DRUG_SEARCH_QUERY_PARAMS_KEYS.includes(key as any)) {
        return true;
      }

      return false;
    }
  );

  if (drugSearchValues.search) {
    drugSearchValues.search = drugSearchValues.search.trim();
  }

  return drugSearchValues;
}

/**
 * Format the drug search values from the URL query params:
 * - remove empty values
 * - remove invalid keys
 * - set default brand value if brand is empty
 */
function formatDrugSearchValues(values: IDrugSearchQueryParams) {
  const drugSearchValues = formatDrugSearchQueryParams(values);

  const newDrugSearchQueryParams = { ...drugSearchValues };
  if (!newDrugSearchQueryParams.brand) {
    newDrugSearchQueryParams.brand = DEFAULT_DRUG_SEARCH_BRAND_VALUE
  }
  if (newDrugSearchQueryParams.search) {
    newDrugSearchQueryParams.search = newDrugSearchQueryParams.search.trim()
  }

  return newDrugSearchQueryParams as IDrugSearchValues;
}

/**
 * Hook to get the formatted drug search values from the URL query params
 */
export function useDrugSearchValues() {
  // TODO: useSearchParams could be improved
  const drugSearchQueryParams = useSearchParams(
    ...DRUG_SEARCH_QUERY_PARAMS_KEYS
  );
  const drugSearchValues = useMemo(() => {
    return formatDrugSearchValues(drugSearchQueryParams) as IDrugSearchValues;
  }, [drugSearchQueryParams]);

  return drugSearchValues;
}

/**
 * Hook to get a function to update the drug search values in the URL query params
 */
export function useUpdateDrugSearchValues() {
  const history = useHistory();

  const updateDrugSearchValues = useCallback(
    (values: IDrugSearchQueryParams) => {
      const newDrugSearchQueryParams = formatDrugSearchQueryParams(values);
      history.push({
        search: new URLSearchParams(newDrugSearchQueryParams).toString(),
      });
    },
    []
  );

  return updateDrugSearchValues;
}
