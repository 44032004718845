import React, {createContext, useEffect, useState} from "react";

type EnhancedClientEcommerceContext = {
  willNavigateToNewEcommercePage: boolean,
};

const EnhancedClientEcommerceContext = createContext<EnhancedClientEcommerceContext>({
  willNavigateToNewEcommercePage: false,
});

export default EnhancedClientEcommerceContext;

type EnhancedClientEcommerceContextProviderProps = {
    children?: React.ReactNode;
};

export function EnhancedClientEcommerceContextProvider({
                                       children,
                                   }: EnhancedClientEcommerceContextProviderProps): JSX.Element {
    const [willNavigateToNewEcommercePage, setwillNavigateToNewEcommercePage] = useState<boolean>(false);

    useEffect(() => {
            const messageListenerCallback = async (event: MessageEvent) => {
                if (event.source !== window) {
                    return;
                }
                if (event.data.channel === "daylightrxenhanced") {
                    if (event.data.msgType === "willNavigateToNewEcommercePage") {
                        console.log("enhancedClientOfflinePurchaseContext:handleWillNavigateToNewEcommercePage", event.data);
                        setwillNavigateToNewEcommercePage(true);
                    }
                }
            };
            window.addEventListener("message", messageListenerCallback);

            return () => {
                window.removeEventListener("message", messageListenerCallback);
            }
    }, [])

    return (
        <EnhancedClientEcommerceContext.Provider
            value={{
                willNavigateToNewEcommercePage,
            }}
        >
            {children}
        </EnhancedClientEcommerceContext.Provider>
    );
}
