import type { FC } from "react";
import keyBy from "lodash/keyBy";
import { OverViewReport } from "./reports/Overview/OverViewReport";
import { AllMedicationsDetailed } from "./reports/AllMedicationsDetailed/AllMedicationsDetailed";
import { AllMedications } from "./reports/AllMedications/AllMedications";
import { ControlledDrugsReport } from "./reports/ControlledDrugs/ControlledDrugsReport";
import { PharmacyPaymentsReport } from "./reports/PharmacyPayments/PharmacyPaymentsReport";
import { PrescribersReport } from "./reports/Prescribers/PrescribersReport";
import { MissedOpportunities } from "./reports/MissedOpportunities/MissedOpportunities";

export enum PerformanceReportType {
  AG_CHART = "AG_CHART",
  MODE = "MODE",
}

export type PerformanceReportComponent =
  | FC<{ data: any }>
  | FC<{
      data: any;
      exportCsvData?: never[];
      exportAllCsvData?: never[];
    }>;

export type PerformanceReport = {
  key: string;
  label: string;
  reportId: number;
  reportType: PerformanceReportType;
  Report?: PerformanceReportComponent;
  iframeProps?: Record<string, any>;
};

export type BasePerformanceReport = Omit<PerformanceReport, "key" | "label">;

export const PERFORMANCE_REPORTS = keyBy([
  {
    reportId: 2, // Overview
    reportType: PerformanceReportType.AG_CHART,
    Report: OverViewReport,
  },
  {
    reportId: 4, // Trends
    reportType: PerformanceReportType.MODE,
  },
  {
    reportId: 5, // All Medications
    reportType: PerformanceReportType.AG_CHART,
    Report: AllMedications
  },
  {
    reportId: 6, // Prescribers
    reportType: PerformanceReportType.AG_CHART,
    Report: PrescribersReport
  },
  {
    reportId: 7, // All Medications (Detailed)
    reportType: PerformanceReportType.AG_CHART,
    Report: AllMedicationsDetailed
  },
  {
    reportId: 8, // Missed Opportunities
    reportType: PerformanceReportType.AG_CHART,
    Report: MissedOpportunities
  },
  {
    reportId: 30, // Controlled Drugs
    reportType: PerformanceReportType.AG_CHART,
    Report: ControlledDrugsReport
  },
  {
    reportId: 41, // Pharmacy Payments
    reportType: PerformanceReportType.AG_CHART,
    Report: PharmacyPaymentsReport
  }
], "reportId");