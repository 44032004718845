import { useEffect, useRef, useState } from "react";
import { css } from "twin.macro";

type Counters = [number, number | undefined] | [number];

const ANIMATION_DURATION_MS = 500;

const ANIMATIONS_UP = [
  css`
    animation: add-rx-tmp-counter-up-animation ${ANIMATION_DURATION_MS}ms
      ease-out 1;
    @keyframes add-rx-tmp-counter-up-animation {
      0% {
        transform: translateY(0%);
        opacity: 1;
      }
      100% {
        transform: translateY(-100%);
        opacity: 0;
      }
    }
  `,
  css`
    animation: add-rx-new-counter-up-animation ${ANIMATION_DURATION_MS}ms
      ease-out 1;
    @keyframes add-rx-new-counter-up-animation {
      0% {
        transform: translateY(0);
        opacity: 0;
      }
      100% {
        transform: translateY(-100%);
        opacity: 1;
      }
    }
  `,
];

const ANIMATIONS_DOWN = [
  css`
    animation: add-rx-tmp-counter-down-animation ${ANIMATION_DURATION_MS}ms
      ease-out 1;
    @keyframes add-rx-tmp-counter-down-animation {
      0% {
        transform: translateY(0);
        opacity: 1;
      }
      100% {
        transform: translateY(100%);
        opacity: 0;
      }
    }
  `,
  css`
    animation: add-rx-new-counter-down-animation ${ANIMATION_DURATION_MS}ms
      ease-out 1;
    @keyframes add-rx-new-counter-down-animation {
      0% {
        transform: translateY(-200%);
        opacity: 0;
      }
      100% {
        transform: translateY(-100%);
        opacity: 1;
      }
    }
  `,
];

export function ShoppingRxPanelCounter({ counter }: { counter: number }) {
  const countersRef = useRef<Counters>([counter]);
  const [animation, setAnimation] = useState<"up" | "down">();
  const animations = animation === "up" ? ANIMATIONS_UP : ANIMATIONS_DOWN;

  useEffect(() => {
    const [oldCounter] = countersRef.current;
    if (counter > oldCounter) setAnimation("down");
    else if (counter < oldCounter) setAnimation("up");
    else setAnimation(undefined);
    countersRef.current = [counter, oldCounter];
  }, [counter]);

  useEffect(() => {
    if (!animation) return;
    const timeoutId = setTimeout(() => {
      setAnimation(undefined);
    }, ANIMATION_DURATION_MS);
    return () => clearTimeout(timeoutId);
  }, [animation]);

  return (
    <span tw="text-center">
      {" ("}
      {animation ? (
        <span tw="inline-flex flex-col h-[31px]">
          <span css={animations[0]}>{countersRef.current[1]}</span>
          <span css={animations[1]}>{counter}</span>
        </span>
      ) : (
        counter
      )}
      {")"}
    </span>
  );
}
