import React from "react";
import "twin.macro";
import { NavBar } from "../components/NavBar/NavBar";
import { ErrorMessage } from "../components/errors/ErrorMessage";

export function UnauthorizedPage() {

  return (
    <>
      <NavBar />

      <main tw="pt-20 pb-[200px]">
        <ErrorMessage>
          Unable to access {location?.pathname}. Please contact your admin for
          permission.
        </ErrorMessage>
      </main>
    </>
  );
}
