import { useMemo } from "react";
import { orderBy } from "lodash";
import { AgCharts } from "ag-charts-react";
import {
  AgCartesianSeriesTooltipRendererParams,
  AgChartOptions,
} from "ag-charts-community";
import { rxChartTheme } from "../rxChart.theme";
import { currencyFormatter } from "../utils/performanceReportFormatters";
import { MissedOpportunitySupplierDecreaseType } from "./MissedOpportunities.types";

function renderTooltip({
  datum,
  xKey,
  yKey,
}: AgCartesianSeriesTooltipRendererParams) {
  return {
    title: datum[xKey],
    content: `Total: ${currencyFormatter(datum[yKey])}`,
  };
}

const OPTIONS: AgChartOptions = {
  theme: rxChartTheme,
  title: {
    text: "Decrease in Supplier Spend with Daily Purchase Optimization",
  },
  series: [
    {
      type: "bar",
      xKey: "supplier",
      yKey: "total",
      yName: "Supplier",
      direction: "horizontal",
      fill: "#f9d45c",
      strokeWidth: 1,
      stroke: "transparent",
      tooltip: {
        renderer: renderTooltip,
      },
    },
  ],
  axes: [
    {
      type: "category",
      position: "left",
    },
    {
      type: "number",
      position: "bottom",
      label: {
        formatter: ({ value }) => {
          return currencyFormatter(value);
        },
      },
      title: {
        text: "Decrease in Order Costs",
        enabled: true,
      },
    },
  ],
};

export function SupplierDecreaseBarChart({
  data,
}: {
  data?: MissedOpportunitySupplierDecreaseType[];
}) {
  const reportData = useMemo(() => {
    return orderBy(data, "total", "desc");
  }, [data]);

  return <AgCharts options={{ ...OPTIONS, data: reportData }} />;
}
