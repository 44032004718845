import { useState, useEffect, useCallback } from "react";
import { sequenceActions } from "../ShoppingRx.utils";

export function useSelectedItemId() {
  const [selectedItemId, setSelectedItemId] = useState<string>();

  const selectItemId = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      document.getElementById(id)?.scrollIntoView();
    },
    [setSelectedItemId]
  );

  useEffect(() => {
    if (!selectedItemId) return;

    const cancelSequence = sequenceActions([
      [() => setSelectedItemId(undefined), 5000],
    ]);
    return cancelSequence;
  }, [selectedItemId]);

  return { selectedItemId, selectItemId };
}
