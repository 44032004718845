import React from "react";
import "twin.macro";
import { Switch } from "../../../components/rxLibrary/switch";
import { Text } from "../../../components/rxLibrary/typography";
import { Tag } from "../../../components/rxLibrary/tags";
import { PrescriptionQuantityItem } from "../../../utilities/types";

const getFalseForUndefined = (value?: boolean): boolean => {
  // default is false if it's undefined
  if (value === undefined) return false;
  return value as boolean;
};

export function ChosenSubstitutions({
  prescription,
  isShowInventory,
  isShowFutureRefills,
  updateChosenSubstitution,
}: {
  prescription: PrescriptionQuantityItem;
  isShowInventory: boolean;
  isShowFutureRefills: boolean;
  updateChosenSubstitution: (
    rxNumbers: string[],
    allowPackSizeSubstitution: boolean,
    allowManufacturerSubstitution: boolean
  ) => void;
}) {
  const {
    drug,
    rxNumbers,
    allowPackSizeSubstitution,
    noManufacturerPreference,
    allowManufacturerSubstitution,
  } = prescription;

  // TODO: Refactor
  const displayData = !getFalseForUndefined(noManufacturerPreference);

  return (
    <div tw="space-y-1">
      <label tw="flex items-center">
        <Switch
          checked={allowPackSizeSubstitution}
          onChange={(checked) => {
            updateChosenSubstitution(
              rxNumbers,
              checked,
              allowManufacturerSubstitution
            );
          }}
        />
        <Text tw="ml-1" weight="bold">
          Pack size
        </Text>
      </label>

      {!noManufacturerPreference && (
        <label tw="flex items-center">
          <Switch
            checked={allowManufacturerSubstitution}
            onChange={(checked) => {
              updateChosenSubstitution(
                rxNumbers,
                allowPackSizeSubstitution,
                checked
              );
            }}
          />
          <Text tw="ml-1" weight="bold">
            Manufacturer
          </Text>
        </label>
      )}

      {displayData &&
        (drug.isNarrowTherapeuticIndex && drug.isBrand ? (
          drug.isRx ? (
            <Tag>
              Brand {(!isShowFutureRefills || !isShowInventory) && `Rx`} &
              Narrow Thrp
            </Tag>
          ) : (
            <Tag>Brand & Narrow Thrp</Tag>
          )
        ) : drug.isBrand ? (
          drug.isRx ? (
            <Tag>Brand Rx item</Tag>
          ) : drug.isNarrowTherapeuticIndex ? (
            <Tag>Brand item</Tag>
          ) : (
            <Tag>Brand OTC Item</Tag>
          )
        ) : drug.isNarrowTherapeuticIndex ? (
          <Tag>Narrow Therap Index</Tag>
        ) : null)}
    </div>
  );
}
