import React, { useMemo } from "react";
import { getModeUrlReport } from "../../services/report";
import { useRequest } from "../../services/request/useRequest";
import { FullPageLoader } from "../../components/loaders/FullPageLoader";
import { PerformanceReportError } from "./PerformanceReportError";
import { usePerformance } from "./usePerformance";
import { ReportWrapper } from "./ReportWrapper";

export function PerformanceReportModal({
  iframeProps,
}: {
  iframeProps?: Record<string, any>;
}) {
  const { toDate, fromDate, reportId, selectedPharmacies } = usePerformance();

  const config = useMemo(() => {
    return {
      fromDate: fromDate.format("YYYY-MM-DD"),
      toDate: toDate.format("YYYY-MM-DD"),
      reportId,
      pharmacyId: selectedPharmacies,
    };
  }, [toDate, fromDate, reportId, selectedPharmacies]);

  const {
    data: reportUrl,
    error,
    isLoading,
  } = useRequest({ config, dataKey: "signedUrl", request: getModeUrlReport });

  return (
    <>
      {!!reportUrl && (
        <ReportWrapper>
          <iframe {...iframeProps} src={reportUrl} tw="w-full h-full" />
        </ReportWrapper>
      )}
      {!!error && <PerformanceReportError />}
      {isLoading && <FullPageLoader text="Loading Analytics" />}
    </>
  );
}
