import React from "react";
import "twin.macro";
import { AgGridReact } from "ag-grid-react";
import { COLUMN_DEFS } from "./PharmacyReport.constants";
import { ROW_CLASS_RULES } from "../utils/reportsConstants";
import { ReportWrapper } from "../utils/ReportWrapper";
import { useReport } from "../utils/useReport/useReport";
import { PharmacyReportDetailsCellRendered } from "./PharmacyReportDetailsCellRendered/PharmacyReportDetailsCellRendered";

export function PharmacyReport({ data }: { data: never[] }) {
  const report = useReport({
    columnDefs: COLUMN_DEFS,
  });

  return (
    <ReportWrapper>
      <AgGridReact
        {...report}
        masterDetail
        detailRowAutoHeight
        pagination
        rowData={data}
        rowSelection="multiple"
        rowClassRules={ROW_CLASS_RULES}
        detailRowHeight={400}
        detailCellRenderer={PharmacyReportDetailsCellRendered}
        rowGroupPanelShow="always"
      />
    </ReportWrapper>
  );
}
