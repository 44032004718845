import isString from "lodash/isString";

export function cleanNdc(value?: string): string | void {
  if (!value || !isString(value)) {
    return;
  }

  const ndc = value.replaceAll(/-/gi, "");
  const isValidNdc = ndc.length === 11 && !isNaN(parseInt(ndc));

  if (isValidNdc) {
    return ndc;
  }
}
