import React, { useContext } from 'react';
import 'twin.macro';
import Modal from "react-modal";
import EnhancedClientContext from "./EnhancedClientContext";
import AppContext from "../contexts/AppContext";
import PharmaciesContext from "../contexts/PharmaciesContext";
import BuyingPharmacyContext from "../contexts/BuyingPharmacyContext";
import BuyingPharmacySelector from "../components/BuyingPharmacySelector";
import EnhancedClientCommandContext from "./EnhancedClientCommandContext";

if (Modal.defaultStyles.overlay) {
    Modal.defaultStyles.overlay.backgroundColor = 'rgba(0.5, 0.5, 0.5, 0.75)';
}

export default function EnhancedSettingsModal():JSX.Element {
    const {
        enhancedSettingsModalShown,
        enhancedOverlayShown,
        setEnhancedSettingsModalShown,
        enhancedSupplierConnectionStatuses,
        showLogin,
        clearEnhancementCache,
        enhancementTimeout,
        setEnhancementTimeout,
    } = useContext(EnhancedClientContext)
    const { showSupplier, reloadSupplier, resetSupplier } = useContext(EnhancedClientCommandContext);
    const { user } = useContext(AppContext);
    const { pharmacies } = useContext(PharmaciesContext);
    const { currentBuyingPharmacyId } = useContext(BuyingPharmacyContext);

    const currentPharmacy = pharmacies.find(p => p.id === currentBuyingPharmacyId);

    return <Modal
        isOpen={false}
        contentLabel="Example Modal"
        onRequestClose={() => setEnhancedSettingsModalShown(false)}
        appElement={document.getElementsByName("body"[0])}
    >
        {(enhancedSettingsModalShown && !enhancedOverlayShown) ? (
            <>
                <div>User: {JSON.stringify(user)}</div>
                <div>Pharmacies: {JSON.stringify(pharmacies)}</div>
                <div>Current Pharmacy: {JSON.stringify(currentBuyingPharmacyId)}</div>
                <div>Supplier Connection Statuses: {JSON.stringify(enhancedSupplierConnectionStatuses)}</div>
                <BuyingPharmacySelector />
            {currentPharmacy && currentPharmacy.suppliers.map(s =>
                <div tw="cursor-pointer" key={s.id}>{s.displayName}&nbsp;
                <span tw="cursor-pointer underline text-blue-500" onClick={() => showSupplier(s.name)} >Show</span>&nbsp;
                <span tw="cursor-pointer underline text-blue-500" onClick={() => reloadSupplier(s.name)}>Reload</span>&nbsp;
                <span tw="cursor-pointer underline text-blue-500" onClick={() => resetSupplier(s.name)}>Reset</span>&nbsp;
            {enhancedSupplierConnectionStatuses[s.name] && !enhancedSupplierConnectionStatuses[s.name].loggedIn && (
                <span tw="cursor-pointer underline text-blue-500" onClick={() => showLogin(s.name, {x: 500, y: 400})}>Login</span>
                )}
                </div>
                )}
                <div>Cache&nbsp;<span tw="cursor-pointer underline text-blue-500" onClick={() => clearEnhancementCache()}>Clear</span></div>
                <div>Search Timeout:&nbsp;{enhancementTimeout}&nbsp;
                <span tw="cursor-pointer underline text-blue-500" onClick={() => setEnhancementTimeout(Math.max(1000, enhancementTimeout - 500))}>Lower</span>&nbsp;
                <span tw="cursor-pointer underline text-blue-500" onClick={() => setEnhancementTimeout(Math.min(12000, enhancementTimeout + 500))}>Raise</span>&nbsp;
                </div>
            </>
        ) : null}
    </Modal>;
}
