import React, { useState } from "react";
import { css, styled } from "twin.macro";
import { Affix, Form, Input, Radio, Select } from "antd";
import isEmpty from "lodash/isEmpty";
import { Container } from "../containers/Container";
import { runOnEnterKeyPressed } from "../../utilities/runOnEnterKeyPressed";
import {
  DEFAULT_DRUG_SEARCH_BRAND_VALUE,
  DRUG_SEARCH_BRAND_OPTIONS,
  IDrugSearchValues,
} from "./DrugSearch.constants";
import { useUpdateDrugSearchValues } from "./DrugSearch";

export function DrugSearchBar({
  drugSearchValues,
  drugSearchFilters,
}: {
  drugSearchValues: IDrugSearchValues;
  drugSearchFilters?: {
    strengths: string[];
    forms: string[];
    administrations: string[];
  };
}) {
  const [formCtrl] = Form.useForm<IDrugSearchValues>();
  const updateDrugSearchValues = useUpdateDrugSearchValues();
  const [isSticky, setIsSticky] = useState(false);

  function updateAllSearchValues() {
    const formValues = formCtrl.getFieldsValue();
    updateDrugSearchValues(formValues);
  }

  const updateSearchOnEnter = runOnEnterKeyPressed(() => {
    const formValues = formCtrl.getFieldsValue();
    const newValues = {
      search: formValues.search,
      brand: DEFAULT_DRUG_SEARCH_BRAND_VALUE,
      strength: undefined,
      form: undefined,
      administration: undefined,
    };
    updateDrugSearchValues(newValues);
    formCtrl.setFieldsValue(newValues);
  });

  return (
    <Affix onChange={(affixed) => setIsSticky(!!affixed)}>
      <Wrapper sticky={isSticky}>
        <Container>
          <Form
            size="large"
            layout="inline"
            form={formCtrl}
            initialValues={drugSearchValues}
          >
            <Form.Item name="search" className="drug-search-bar_search">
              <Input
                allowClear
                type="text"
                placeholder="Name / NDC / UPC"
                onKeyDownCapture={updateSearchOnEnter}
                autoFocus
              />
            </Form.Item>

            {!!drugSearchFilters && (
              <>
                <Form.Item name="brand">
                  <Radio.Group
                    options={DRUG_SEARCH_BRAND_OPTIONS}
                    onChange={updateAllSearchValues}
                    optionType="button"
                    buttonStyle="solid"
                  />
                </Form.Item>

                <Form.Item name="strength" className="drug-search-bar_select">
                  <Select
                    allowClear
                    placeholder="Strength"
                    options={formatOptions(drugSearchFilters.strengths)}
                    onChange={updateAllSearchValues}
                    disabled={isEmpty(drugSearchFilters.strengths)}
                  />
                </Form.Item>

                <Form.Item name="form" className="drug-search-bar_select">
                  <Select
                    allowClear
                    placeholder="Form"
                    options={formatOptions(drugSearchFilters.forms)}
                    onChange={updateAllSearchValues}
                    disabled={isEmpty(drugSearchFilters.forms)}
                  />
                </Form.Item>

                <Form.Item
                  name="administration"
                  className="drug-search-bar_select"
                >
                  <Select
                    allowClear
                    placeholder="Administration"
                    options={formatOptions(drugSearchFilters.administrations)}
                    onChange={updateAllSearchValues}
                    disabled={isEmpty(drugSearchFilters.administrations)}
                  />
                </Form.Item>
              </>
            )}
          </Form>
        </Container>
      </Wrapper>
    </Affix>
  );
}

function formatOptions(options: string[] = []) {
  return options.map((option) => ({
    value: option,
    label: option,
  }));
}

const Wrapper = styled.div(({ sticky }: { sticky: boolean }) => [
  sticky &&
    css`
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.24);
    `,
  css`
    padding: 25px;
    background-color: #f5f5f5;
    z-index: 10;

    .ant-form {
      justify-content: center;

      .ant-input,
      .ant-form-item {
        font-family: Roboto;
        font-stretch: condensed;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.07px;
        color: #1e1e1e;
      }

      .ant-input {
        font-size: 18px;
        font-weight: bold;

        &::placeholder {
          color: #1d1d1d59;
        }
      }

      .ant-input-affix-wrapper {
        border: solid 1px #979797;
        border-radius: 500px;
        padding-left: 25px;
        padding-right: 25px;
      }

      .ant-select {
        .ant-select-selector {
          border-radius: 500px;
          border: solid 1px #979797;
        }

        &:not(.ant-select-disabled) {
          .ant-select-arrow,
          .ant-select-selection-placeholder {
            color: #1e1e1e;
          }
        }
      }

      .ant-form-item {
        font-size: 16px;
        font-weight: normal;

        &.drug-search-bar_select {
          width: 110px;

          &:last-of-type {
            width: 148px;
          }
        }

        &.drug-search-bar_search {
          flex: 1;
          max-width: 600px;
        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      .ant-radio-button-wrapper {
        border: solid 1px #979797;

        &:first-of-type {
          border-radius: 500px 0 0 500px;
        }

        &:last-of-type {
          border-radius: 0 500px 500px 0;
        }

        &.ant-radio-button-wrapper-checked {
          background-color: #324dc7;

          &:hover {
            color: #fff;
          }
        }

        &:hover {
          color: #1e1e1e;
          border: 1px solid #324dc7;
          outline: 1px solid #324dc7;
          z-index: 1;
        }

        &:pressed {
          color: #fff;
        }
      }
    }
  `,
]);
