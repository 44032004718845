import { useContext, ReactNode, FC } from "react";
import { Redirect, Route } from "react-router-dom";
import AppContext from "../contexts/AppContext";
import { UserBusinessRoles } from "../utilities/types";
import { FullPageLoader } from "./loaders/FullPageLoader";

export function RoleProtectedRoute({
  path,
  role,
  ...props
}: {
  path: string;
  role: UserBusinessRoles;
} & ({ children: ReactNode } | { component: FC })) {
  const { userHasRole } = useContext(AppContext);

  if (!userHasRole) return <FullPageLoader />;
  if (!userHasRole(role)) return <Redirect to="/" />;

  return <Route {...props} path={path} />;
}
