import React from "react";
import tw from "twin.macro";
import { IconBell } from "../../rxLibrary/icons";
import { Text } from "../../rxLibrary/typography";

const Wrapper = tw.div`p-[8px]`;

export function CalendarFooterWarning({
  text,
  hide = false,
}: {
  text: string;
  hide?: boolean;
}) {
  if (hide) return null;
  return (
    <Wrapper>
      <Text color="red-1" tw="flex justify-center items-center">
        <IconBell tw="mr-[5px]" />
        <span>{text}</span>
      </Text>
    </Wrapper>
  );
}

export function CalendarFooterNotInStockToday({
  hide = false,
}: {
  hide?: boolean;
}) {
  return <CalendarFooterWarning hide={hide} text="Not in Stock Today" />;
}
