import type {
  CatalogOverride, DrugInfo, OfferedDrug,
  PrescriptionGroupForOptimization
} from "../../utilities/types";
import {backendFetch, backendPost} from "./config/backend-api";
import {sleepMillis} from "../../utilities/helpers";
import {
  AsyncCartOptimizationRequestResponse,
  CartOptimizationRequest,
  OptimizationSelections,
  OptimizeCartCustomerStatus,
  RecentOptimizationsRequest,
  SupplierOrderItemRecommendation,
  SupplierOrder,
  Unavailable,
  UnfilledFuturePrescriptionGroup,
  OptimizedCart_All,
  OptimizedCart,
  SupplierOrder_All,
  OptimizationReferenceData,
  SupplierOrderItemRecommendation_All,
  CatalogDrug,
  CatalogDrug_All,
  Unavailable_All,
  UnfilledFuturePrescriptionGroup_All,
  OptimizationSelections_All,
  OptimizedCartResponse_All,
  RecentOptimization
} from "../utils";

const massageOptimizeCartResponse = (optimization: RecentOptimization | OptimizedCart | OptimizedCart_All) => {
  optimization.selections?.supplierOrders.forEach((supplierOrder) => {
    supplierOrder.items.forEach((item) => {
      if (item.rxNumbers.length === 0) {
        item.rxNumbers.push(`c${item.drug.id}`);
      }
      if (item.numPackagesIncreased) {
        item.numPackagesIncreasedTo = item.numPackages;
      }
    });
  });
};

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getRecentOptimizations(token: string, pharmacyId: number): Promise<RecentOptimizationsRequest> {
  // massageOptimizeCartResponse(cart);
  const response = await backendFetch<RecentOptimizationsRequest>(
    `/cart-optimizations?pharmacy_id=${pharmacyId}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (response.data?.cartOptimizations) {
    response.data.cartOptimizations.forEach((cart) => {
      massageOptimizeCartResponse(cart);
    });
    return response.data;
  }
  return {
    cartOptimizations: [],
  };
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateOptimizationCartCustomerStatus(id: number, customerStatus: OptimizeCartCustomerStatus, token: string): Promise<void> {
  const createdResponse = await backendPost(
    `/cart-optimization/${id}`,
    JSON.stringify({
      data: {
        customerStatus: customerStatus,
      }
    }),
    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  console.log("updateOptimizedCart", createdResponse);
}

function denormalizeDrugAlternative(alt: CatalogDrug, referenceData: OptimizationReferenceData): CatalogDrug_All {
    return {
      ...alt,
      referenceData: {
        drugInfo: referenceData.drugs[alt.drug.id],
        catalogInfo: referenceData.catalogItems[alt.catalogInfo.id],
      }
    }
}

function denormalizeSupplierOrderItem(item: SupplierOrderItemRecommendation, referenceData: OptimizationReferenceData): SupplierOrderItemRecommendation_All {
    let newOriginals: SupplierOrderItemRecommendation_All['originalDrugs'] = undefined;
    if (item.originalDrugs) {
      const originalDrugs = item.originalDrugs;
      newOriginals = Object.keys(originalDrugs).reduce((acc, cur) => {
        acc[cur] = {
          id: originalDrugs[cur].id,
          referenceData: {
            drugInfo: referenceData.drugs[originalDrugs[cur].id],
          }
        };
        return acc;
      }, {} as Record<string, { id: string, referenceData: { drugInfo: DrugInfo } }>);
    }
    return {
      ...item,
      referenceData: {
        drugInfo: referenceData.drugs[item.drug.id],
        catalogInfo: referenceData.catalogItems[item.catalogInfo.id],
      },
      alternatives: item.alternatives.map((alt) => { return denormalizeDrugAlternative(alt, referenceData); }),
      originalDrugs: newOriginals,
      comparison: item.comparison ? {
        ...item.comparison,
      } : undefined,
    }
}

function denormalizeSupplierOrder(supplierOrder: SupplierOrder, referenceData: OptimizationReferenceData): SupplierOrder_All {
    let basketWarnings: SupplierOrder_All['increasedBasketWarnings'] = undefined;
    if (supplierOrder.increasedBasketWarnings) {
      basketWarnings = {
        drugsWithAvailabilityLimitations: supplierOrder.increasedBasketWarnings.drugsWithAvailabilityLimitations.map((d) => {
          return {
            id: d.id,
            referenceData: {
              drugInfo: referenceData.drugs[d.id],
            }
          }
        }),
      };
    }
  return {
    ...supplierOrder,
    items: supplierOrder.items.map((item) => { return denormalizeSupplierOrderItem(item, referenceData); }),
    increasedBasketWarnings: basketWarnings,
  }
}

function denormalizeUnavailable(unavail: Unavailable, referenceData: OptimizationReferenceData): Unavailable_All {
  return {
    ...unavail,
    referenceData: {
      drugInfo: referenceData.drugs[unavail.drug.id],
    }
  };
}

function denormalizeUnfilledFuturePrescriptionGroup(u: UnfilledFuturePrescriptionGroup, referenceData: OptimizationReferenceData): UnfilledFuturePrescriptionGroup_All {
    return {
      ...u,
      alternatives: u.alternatives.map((alt) => { return denormalizeDrugAlternative(alt, referenceData); }),
      referenceData: {
        drugInfo: referenceData.drugs[u.drug.id],
      },
    }
}

function denormalizeOptimizationResponse(cart: OptimizedCart): OptimizedCart_All {
  return {
    id: cart.id,
    editVersion: 0,
    schemaVersion: cart.schemaVersionNumber,
    createdBy: cart.createdBy,
    createdAt: cart.createdAt,
    updatedAt: cart.updatedAt,
    status: cart.status,
    customerStatus: cart.customerStatus,
    cartSnapshot: cart.cartSnapshot,
    referenceData: cart.referenceData,
    selections: {
      comparisonSummary: cart.selections.comparisonSummary,
      supplierOrders: cart.selections.supplierOrders.map((supplierOrder) => {
        return denormalizeSupplierOrder(supplierOrder, cart.referenceData);
      }),
      unavailable: cart.selections.unavailable.map((u) => {
        return denormalizeUnavailable(u, cart.referenceData);
      }),
      unfilledFuturePrescriptionGroups: cart.selections.unfilledFuturePrescriptionGroups.map((u) => {
        return denormalizeUnfilledFuturePrescriptionGroup(u, cart.referenceData);
      }),
      visitedItemsIds: cart.selections.visitedItemsIds ? (
        cart.selections.visitedItemsIds.reduce((acc, cur) => {
          acc[cur] = true;
          return acc;
        }, {} as Record<string, boolean>)
      ) : {}
    },
    requestData: cart.requestData,
    prescriptions: [],
  }
}

function removeAllReferenceData(obj: any): any {
  if (obj === null || obj === undefined) {
    return obj;
  }
  Object.keys(obj).forEach((key) => {
    if (key === "referenceData") {
      delete obj[key];
    } else if (Array.isArray(obj[key])) {
      obj[key].forEach((item: any) => {
        removeAllReferenceData(item);
      });
    } else if (typeof obj[key] === "object") {
      removeAllReferenceData(obj[key]);
    }
  });
  return obj;
}

function convertToNormalizedSelections(selections: OptimizationSelections_All): OptimizationSelections {
  const normalized = removeAllReferenceData(JSON.parse(JSON.stringify(selections)));
  delete normalized.visitedItemsIds;
  if (selections.visitedItemsIds) {
    normalized.visitedItemsIds = Object.keys(selections.visitedItemsIds).filter((key) => {
      return selections.visitedItemsIds[key];
    });
  }
  return normalized as OptimizationSelections;
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function updateOptimizedCartSelections(
  id: number,
  token: string,
  selections: OptimizationSelections_All): Promise<void> {
  const normalizedSelections = convertToNormalizedSelections(selections);
  const createdResponse = await backendPost(
    `/cart-optimization/${id}`,
    JSON.stringify({
      data: {
        selections: normalizedSelections,
      }
    }),
    {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  console.log("updateOptimizedCartSelections response", createdResponse);
}

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getOptimizedCart(
  id: number,
  token: string,
): Promise<OptimizedCartResponse_All> {
    for (let i = 0; i < 20; i++) {
      const response = await backendFetch<CartOptimizationRequest>(
        `/cart-optimization/${id}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      if (response.data?.cartOptimization.status === "complete") {
        const denormalizedResp = denormalizeOptimizationResponse(response.data.cartOptimization as OptimizedCart);
        massageOptimizeCartResponse(denormalizedResp);
        return {
          data: denormalizedResp,
        };
      } else {
        const sleepTime = 250 * Math.pow(1.3, i);
        await sleepMillis(sleepTime);
      }
    }
    throw new Error("Cart optimization request timed out");
  }

/**
 * @deprecated please migrate to useRequestClient request
 **/
  export async function optimizeCart(
    pharmacyId: number | null,
    token: string,
    prescriptionGroups: PrescriptionGroupForOptimization[],
    allowedSupplierIds: number[],
    primarySuppliersOnly: boolean,
    catalogOverrides: CatalogOverride[],
    offeredDrugs: OfferedDrug[],
  ): Promise<OptimizedCartResponse_All> {
    // filter out any prescription groups with bad ndcs
    const prescriptionGroupForOptimizations = prescriptionGroups.filter((pg) => {
      return pg.ndc && pg.ndc.length === 11;
    });

    type CatalogVerificationTable = Record<string, {byNdc: Record<string, OfferedDrug>, bySupplierItemNumber:Record<string, OfferedDrug>}>;
    const catalogVerificationTable: CatalogVerificationTable = {};
    offeredDrugs.forEach((od) => {
      if (!catalogVerificationTable[od.supplierId]) {
        catalogVerificationTable[od.supplierId] = {byNdc: {}, bySupplierItemNumber: {}};
      }
      catalogVerificationTable[od.supplierId].byNdc[od.drug.ndc] = od;
      od.supplierItemNumbers.forEach((sin) => {
        catalogVerificationTable[od.supplierId].bySupplierItemNumber[sin] = od;
      });
    });

    catalogOverrides.forEach((override) => {
      if (!catalogVerificationTable[override.supplierId]) return;

      const supplierTable = catalogVerificationTable[override.supplierId];
      if (supplierTable.byNdc[override.ndc]) {
        return;
      }
      if (supplierTable.bySupplierItemNumber[override.supplierItemNumber]) {
        console.log("updating override ndc", override.supplierItemNumber, override.ndc, supplierTable.bySupplierItemNumber[override.supplierItemNumber].drug.ndc);
        override.ndc = supplierTable.bySupplierItemNumber[override.supplierItemNumber].drug.ndc;
      }
    })

    // console.log(catalogOverrides)
    const catalogOverridesForOptimizations = catalogOverrides.filter((co) => {
      return (
        co.ndc &&
        co.ndc.length === 11 &&
        (co.price > 0 || !co.isAvailable) &&
        co.supplierId
      );
    });

    const dataPayload = {
      data: {
        prescriptionGroups: prescriptionGroupForOptimizations,
        allowedSupplierIds: allowedSupplierIds,
        primarySuppliersOnly: primarySuppliersOnly,
        catalogOverrides: catalogOverridesForOptimizations,
      },
    };
    const payload = JSON.stringify(dataPayload);

    const createdResponse = await backendPost(
      `/cart-optimization?pharmacy_id=${pharmacyId}`,
      payload,
      {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const cartOptimizationRequestResponse: AsyncCartOptimizationRequestResponse =
      await createdResponse.json();
    await sleepMillis(2000); // start off at 2000 and then exponentially back off
    return await getOptimizedCart(cartOptimizationRequestResponse.data.cartOptimization.id, token);
  }
