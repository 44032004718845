import {backendFetch} from "./config/backend-api";
import {User} from "../../utilities/types";
import {LOGGED_IN_EMAIL_STORAGE_KEY} from "../../utilities/localStorage/constants";

/**
 * @deprecated please migrate to useRequestClient request
 **/
export async function getUser(token: string) {
  const result = await backendFetch<{ user: User; }>("/user", {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (result.data && result.data.user) {
    window.localStorage.setItem(LOGGED_IN_EMAIL_STORAGE_KEY, result.data.user.email);
  }
  return result;
}
